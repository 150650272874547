import { isUndefined, isArray, isObject } from '@icc/helpers';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price';
import { AppConfigFactory, APP_CONFIG, EventBusService } from '@icc/common';
import { Inject, Injectable } from '@angular/core';



@Injectable()
export class LocksService {

    private configurators       = ['door'];
    private allLocks         = [];
    private loadedData = false;
    locks = [];

    constructor (
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'door'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator', 'loadedConfiguratorsData'], () => {
            this.init();
        });
    }

    /**
     * Funkcja inicjalizacyjna
     */
    private init() {
        this.allLocks      = this.configuratorsDataService.data.locks || [];
        this.locks = this.configuratorsDataService.data.locks || [];

        if (this.configurators.indexOf(this.currentConfiguratorService.conf) !== -1) {
            this.findLocksBySystem();
        }

        this.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne zamki
     */
    private setDefaultLock(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (isUndefined(conf)) {
            return;
        }
        if (isArray(this.locks) && this.locks.length > 0) {
            conf.Lock = this.locks[0];
            defaultConf.Lock = this.locks[0];
        } else {
            conf.Lock = {};
            defaultConf.Lock = {};
        }
        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        this.priceService.count();
        this.parametersService.count(conf);
    }

    /**
     * Funkcja wybierajaca zamki
     * @param  {object} lock Zamki
     */
    selectLock(lock) {
        this.configurationsService.conf.Current.Lock = lock;
        this.configurationsService.conf.Default.Lock = lock;
        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        this.priceService.count();
        this.parametersService.count(this.configurationsService.conf.Current);
    }

    /**
     * Funkcja znajdujaca zamki do systemu
     * @param  {object} system System
     */
    findLocksBySystem(
        system?,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (isUndefined(system) || isUndefined(system.id)) {
            system = conf.System;
        }

        if (isUndefined(system) || isUndefined(system.id)) {
            return;
        }

        const sashTypesIds = conf.Sashes.map(sash => Number(sash.type.id));
        if (sashTypesIds.length === 0) {
            return;
        }
        this.locks = [];
        this.allLocks.forEach(lock => {
            var systemIds = lock.window_lines_ids;
            if (
                isObject(system)
                && systemIds.indexOf(system.id) > -1
                && lock.sash_types_ids.some(
                    sashTypeId => sashTypesIds.map(Number).indexOf(Number(sashTypeId)) > -1)
            ) {
                if (
                    !this.config().IccConfig.Configurators.assignLockToHandles
                    || (this.config().IccConfig.Configurators.assignLockToHandles
                        && this.checkIfLockMatchHandles(lock, conf))
                ) {
                    this.locks.push(lock);
                }
            }
        });
        if (this.locks.map(lock => lock.id).indexOf(conf.Lock.id) === -1) {
            this.setDefaultLock(conf, defaultConf);
        }
    }

    /**
     * Funkcja sprawdzająca, czy zamek jest dostępny dla wybranej klamki
     * @param  {object} lock Zamek
     * @return {bool}      Informacja czy zamek pasuje do klamki
     */
    checkIfLockMatchHandles(lock, conf = this.configurationsService.conf.Current) {
        var handleType = conf.HandleType;
        var handle = conf.Handle;
        if (!handle || !handle.id) {
            const doorSash = conf.Sashes.find(sash => sash.type.type === 'DRA' || sash.type.type === 'DOA');
            if (doorSash) {
                handle = doorSash.handle;
            }
        }
        if (handleType == 'DoubleLever' && lock.lock_assign.indexOf(handle.handle_type) > -1) {
            return true;
        } else if (handleType == 'DoublePull' && handle.handle_type == 'pull' && lock.lock_assign.indexOf('doublePull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleLever' && lock.lock_assign.indexOf('leverPull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleKnob' && lock.lock_assign.indexOf('knobPull') > -1) {
            return true;
        } else if (lock.type == 'multipoint_lock') {
            return true;
        }
        return false;
    }
}
