import { Injectable, Inject } from '@angular/core';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { SplitFrameService } from './split-frame.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { FramesService } from '@icc/common/layout/frames.service';
import { Alignment, Frame } from '@icc/window';
import { core } from '@icc/common/helpers';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { EventBusService } from '@icc/common/event-bus.service';
import { AlignmentsService } from './alignments.service';
import { ProfilesService, TranslateService } from '@icc/common';

@Injectable()
export class FixSashThresholdService {
    constructor(
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private translateService: TranslateService,
        private splitFrameService: SplitFrameService,
        private alignmentsService: AlignmentsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private profilesService: ProfilesService
    ) {
        this.eventBusService.subscribe<any>(
            ['setLowThreshold', 'unsetLowThreshold', 'setSystem', 'changedSashes'],
            data => {
                this.validateThresholdAlignmentsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.action === 'joinedFrame'
                );
            }
        );
    }

    validateThresholdAlignmentsAndFixIssues(
        conf: WindowActiveConfiguration,
        notSplitFrames = false
    ) {
        if (this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            const alignmentsToRemove = conf.Alignments.filter(alignment => {
                const alignmentProfile = this.profilesService.getProfile(alignment.profileId);
                const frame = FramesService.getFrameById(alignment.frameId, conf);
                return (
                    alignment.side === 'bottom'
                    && alignment.parallelAlignments.bottom.length === 0
                    && alignment.perpendicularAlignments.left.length === 0
                    && alignment.perpendicularAlignments.right.length === 0
                    && alignmentProfile.options.indexOf('alignment_threshold') > -1
                    && (!frame.lowThreshold
                        || conf.Sashes.some(
                            sash =>
                                sash.frameId === frame.id
                                && alignment.adjacentSashes.top.includes(sash.id)
                                && sash.type.type !== 'F'
                        )
                        || !alignmentProfile.systems.includes(Number(conf.System.id)))
                );
            });
            alignmentsToRemove.forEach(alignment =>
                this.removeBottomThresholdAlignment(alignment, conf)
            );
            this.setKickerWhenApplicable(conf);
            if (!notSplitFrames) {
                this.splitFrameService.splitLowThresholdFrameContainingFixAndSash(conf);
            }
            this.validKickerAvailablity(conf);
        }
    }

    private removeBottomThresholdAlignment(alignmentToRemove, conf: WindowActiveConfiguration) {
        const alignments: Alignment[] = conf.Alignments;
        const mullions = conf.Mullions;
        const sashes = conf.Sashes;

        if (alignmentToRemove.adjacentSashes.top.length > 0) {
            alignmentToRemove.adjacentSashes.top.forEach(sashId => {
                const nearSash = core.fIdO<any>(sashes, sashId);
                nearSash.nearAlignments.bottom = -1;
            });
        }
        if (alignmentToRemove.parallelAlignments.top.length > 0) {
            alignmentToRemove.parallelAlignments.top
                .map(nearAlignmentId => core.fIdO<Alignment>(alignments, nearAlignmentId))
                .forEach(nearAlignment => {
                    nearAlignment.parallelAlignments.bottom = nearAlignment.parallelAlignments.bottom.filter(
                        el => el !== alignmentToRemove.id
                    );
                });
        }

        if (alignmentToRemove.perpendicularMullions.left.length > 0) {
            alignmentToRemove.perpendicularMullions.left
                .map(nearMullionId => core.fIdO<any>(mullions, nearMullionId))
                .forEach(nearMullion => {
                    nearMullion.perpendicularAlignments.right = nearMullion.perpendicularAlignments.right.filter(
                        el => el !== alignmentToRemove.id
                    );
                });
        }
        if (alignmentToRemove.perpendicularMullions.right.length > 0) {
            alignmentToRemove.perpendicularMullions.right
                .map(nearMullionId => core.fIdO<any>(mullions, nearMullionId))
                .forEach(nearMullion => {
                    nearMullion.perpendicularAlignments.left = nearMullion.perpendicularAlignments.left.filter(
                        el => el !== alignmentToRemove.id
                    );
                });
        }

        const oldAlignmentIndex = alignments.findIndex(
            alignment => alignment.id === alignmentToRemove.id
        );

        alignments.splice(oldAlignmentIndex, 1);
        conf.Layout.changed = true;
        this.eventBusService.post({
            key: 'removedAlignment',
            value: {
                side: 'bottom',
            },
            conf
        });
    }

    private validKickerAvailablity(conf, showmodal = true) {
        const availKickers = this.alignmentsService.getThresholdAlignments(conf).length > 0;

        for (const frame of conf.Frames.filter(f => f.lowThreshold)) {
            const sashesFixOnLowThreshold = conf.Sashes.filter(
                sash =>
                    sash.type.type === 'F'
                    && sash.nearMullions.bottom === -1
                    && frame.id === sash.frameId
            );
            const sashesforKickers =
                sashesFixOnLowThreshold.length > 0
                && conf.Sashes.some(
                    sash =>
                        ['F', 'FF', 'OFF', 'K'].indexOf(sash.type.type) < 0
                        && sash.nearMullions.bottom === -1
                        && frame.id === sash.frameId
                );
            const noHasAlignmentThreshold = this.hasNotThresholdAlignment(
                conf,
                frame,
                sashesFixOnLowThreshold
            );

            if (
                (!availKickers || !conf.System.kicker || noHasAlignmentThreshold)
                && sashesforKickers
                && this.config().IccConfig.Configurators.kickerAvaliable
                && this.config().IccConfig.Drawing.kickerLowThreshold
                && frame.lowThreshold
            ) {
                this.issuesService.simpleRegister(
                    'no-kicker',
                    'Niski próg nie może być zamontowany pod stałym szkleniem. Konstrukcję należy skonfigurować w dwóch ramach lub należy wyłączyć niski próg.',
                    this.translateService.instant(
                        'WINDOW|Niski próg nie może być zamontowany pod stałym szkleniem. Konstrukcję należy skonfigurować w dwóch ramach lub należy wyłączyć niski próg.'
                    ),
                    conf,
                    {
                        showMessage: showmodal,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }
        this.issuesService.unregister('no-kicker', conf);
        return true;
    }

    private setKickerWhenApplicable(conf: WindowActiveConfiguration) {
        if (!this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            return;
        }

        conf.Kicker = false;

        const thresholdKickers = this.alignmentsService.getThresholdAlignments(conf);
        conf.Frames.filter(f => f.lowThreshold).forEach(frame => {
            const sashesFixOnLowThreshold = conf.Sashes.filter(
                sash =>
                    sash.type.type === 'F'
                    && sash.nearMullions.bottom === -1
                    && frame.id === sash.frameId
            );
            const sashesforKickers =
                sashesFixOnLowThreshold.length > 0
                && conf.Sashes.some(
                    sash =>
                        ['F', 'FF', 'OFF', 'K'].indexOf(sash.type.type) < 0
                        && sash.nearMullions.bottom === -1
                        && frame.id === sash.frameId
                );

            const noHasAlignmentThreshold = this.hasNotThresholdAlignment(
                conf,
                frame,
                sashesFixOnLowThreshold
            );

            const hasLowThreshold = frame.lowThreshold;

            if (this.config().IccConfig.Drawing.kickerLowThreshold) {
                conf.Kicker =
                    conf.Kicker
                    || (this.config().IccConfig.Configurators.kickerAvaliable
                        && conf.System.kicker
                        && hasLowThreshold
                        && thresholdKickers.length > 0
                        && sashesforKickers);

                if (conf.Kicker && noHasAlignmentThreshold) {
                    sashesFixOnLowThreshold.forEach(sash => {
                        if (
                            sash.nearAlignments.bottom === -1
                            && sash.nearAlignments.left === -1
                            && sash.nearAlignments.right === -1
                        ) {
                            const selectedProfile = thresholdKickers[0];
                            this.alignmentsService.putAlignmentInField(
                                selectedProfile,
                                sash,
                                conf,
                                'bottom'
                            );
                        }
                    });
                }
            } else {
                conf.Kicker =
                    conf.Kicker
                    || (this.config().IccConfig.Configurators.kickerAvaliable
                        && conf.System.kicker
                        && hasLowThreshold
                        && sashesforKickers);
            }
        });
    }

    private hasNotThresholdAlignment(
        conf: WindowActiveConfiguration,
        frame: Frame,
        sashesFixOnLowThreshold: ActiveSash[]
    ) {
        return (
            conf.Alignments == null
            || conf.Alignments.length === 0
            || !conf.Alignments.some(alignment => {
                const alignmentProfile = this.profilesService.getProfile(alignment.profileId);
                return (
                    alignment.side === 'bottom'
                    && alignment.frameId === frame.id
                    && alignment.perpendicularAlignments.left.length === 0
                    && alignment.perpendicularAlignments.right.length === 0
                    && sashesFixOnLowThreshold.some(
                        sash => sash.nearAlignments.bottom === alignment.id
                    )
                    && alignmentProfile.options.indexOf('alignment_threshold') > -1
                );
            })
        );
    }
}
