import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ParserService } from '@icc/common/ParserService';
import { PositionsDetailsService } from '@icc/common/PositionsDetailsService';
import { MatTreeNestedDataSource, MatTree } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { StateService } from '@icc/common/state.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { TranslateService } from '@icc/common/translate.service';
import { UserService } from '@icc/common/user.service';

@Component({
    selector: 'icc-details',
    template: require('./details.component.html'),
    styles: [require('./details.component.scss')],
})
export class DetailsComponent implements OnInit {
    @Input() children: any;
    @ViewChild('tree') tree!: MatTree<any>;

    details: any = {};
    priceElementsVisible = false;
    showParametersModal = false;
    showPriceDetails = false;
    showSystemsComparision = false;
    treeControl = new NestedTreeControl<any>(node => node.children);
    dataSource = new MatTreeNestedDataSource<any>();

    detailsTree: any = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private stateService: StateService,
        private configurationsService: ConfigurationsService,
        private translateService: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit() {
        const user = this.userService.get();
        this.loadDetails(user);
    }

    loadDetails(user) {
        const positions: any = new PositionsDetailsService(
            this.config().IccConfig,
            [
                {
                    configuration: this.configurationsService.conf.Current,
                    details: this.configurationsService.createSimpleConfiguration(
                        this.configurationsService.conf.Current
                    ),
                    summary: {
                        dealer: {
                            components: {},
                        },
                        client: {
                            components: {},
                        },
                    },
                    group_discounts: {},
                    confType: this.configurationsService.conf.Current.type
                },
            ],
            this.stateService.getCurrentOffer(),
            user.access,
            true,
            user.access,
            this.config().CurLang,
            this.translateService.instant.bind(this.translateService)
        );
        positions[0].detailsForView.allWidth = true;
        this.detailsTree = positions[0].detailsTree;
        const data = this.convertToTreeData(JSON.parse(JSON.stringify(this.detailsTree)));
        this.dataSource.data = data;
        this.treeControl.dataNodes = data;
        this.treeControl.expandAll();
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    private convertToTreeData(tree: any) {
        if (tree.$$children) {
            tree.children = tree.$$children;
            tree.children.forEach(node => {
                this.convertToTreeData(node);
            });
            delete tree.$$children;
        }
        return tree.children;
    }
}
