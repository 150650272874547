import { TemplateRef } from '@angular/core';

export interface iccListItem {
    id: number | string;
    title?: string;
    description?: string;
    imageUrl?: string;
    color?: string;
    tabs?: (number | string)[];
    showInfo?: boolean;
    editGlazing?: boolean;
    disabled?: boolean;
};

export interface iccListTab {
    id: number | string;
    name: string;
    icon?: string;
    active?: boolean;
}

export interface iccSelect {
    id: number;
    name: string;
}

export type iccListItemTemplate =
    | 'itemOnlyImg'
    | 'itemColor'
    | 'itemImgTitleDescription'
    | 'mountingMethod'
    | 'itemImgTitle'
    | 'itemOnlyTitle';
export type iccListItemWidth = 'full' | 'wide' | 'medium' | 'narrow' | 'verynarrow';
export type iccListItemImgHeight = 'low' | 'medium' | 'high';

export type TemplateMap = {
    [key in iccListItemTemplate]: {
        template: TemplateRef<any>;
        width: iccListItemWidth;
        imgHeight: iccListItemImgHeight;
    }
};

export function itemTemplateIsType(
    template: TemplateRef<any> | iccListItemTemplate | undefined,
    map: TemplateMap
): template is iccListItemTemplate {
    return typeof template === 'string' && Object.keys(map).includes(template);
}
