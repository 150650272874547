import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {IccDrawMathService} from '@icc/draw';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';


@Injectable()
export class PriceMuntinsService {
    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory) {}

    /**
     * Funkcja zwracajca typ kolory dla koloru szprosów
     * @param  {Object} color     Kolor
     * @param  {Object} colorOut  Kolor zewnętrzny
     * @param  {Object} colorCore Kolor bazowy
     * @return {String}           Typ koloru
     */
    getSideForColors(color, colorOut) {
        let side: 'single-inner' | 'single-outer' | 'double' | 'bicolor' = 'bicolor';
        if (Common.isDefined(colorOut) && Common.isDefined(color)) {
            if (Number(color.id) === Number(colorOut.id) && color.RAL === colorOut.RAL) {
                side = 'double';
            } else if (colorOut.type === 'white') {
                side = 'single-inner';
            } else if (color.type === 'white') {
                side = 'single-outer';
            }
        }
        return side;
    }

    /**
     * Funkcja obliczajca cenę przy belkach w krzydle
     * @param  {Object} PriceElems   Cena elementów
     * @param  {Object} sash         Skrzydło
     * @param  {Object} muntinsData  Dane do szprosów
     * @param  {Object} muntinPrices Ceny szprosów
     * @return {Number}              Cena
     */
    priceBarsInSash(drawData, PriceElems, sash, muntinsData, colors): PriceSegment {
        if (!muntinsData || !muntinsData.type || !drawData) {
            return;
        }

        const sashData = drawData.sash.find(o => o.sashId === sash.id);
        const glazingData = drawData.filling.find(o => o.sashId === sash.id);
        const glazingBeadData = drawData.glazingBead.find(o => o.sashId === sash.id);

        if (!sashData || !glazingData || !glazingBeadData) {
            return;
        }

        let priceMuntin = NaN;
        let priceDuplex = NaN;
        let priceMuntinOut = NaN;
        let priceDuplexOut = NaN;
        let hasDuplex   = muntinsData.duplex;

        if (Common.isDefined(sash.canHaveDuplex)) {
            hasDuplex = sash.canHaveDuplex && muntinsData.duplex;
        }

        const muntinsH = sash.muntins.filter(muntin => muntin.start.y === muntin.end.y);
        const muntinsV = sash.muntins.filter(muntin => muntin.start.x === muntin.end.x);

        let fieldsCount = 0;

        if (sash.muntins && sash.muntins.length) {
            const lines = [];

            if (muntinsH && muntinsH.length) {
                muntinsH.forEach((muntinH, index) => {
                    let line = core.copy(muntinH);
                    line.start.y = line.start.y + 2;
                    line.end.y = line.end.y + 2;
                    lines.push(line);
                    if (index === 0) {
                        line = core.copy(muntinH);
                        line.start.y = line.start.y - 2;
                        line.end.y = line.end.y - 2;
                        lines.push(line);
                    }
                });
            } else {
                lines.push({
                    start: {
                        x: 0,
                        y: sashData.inner.rect.height / 2
                    },
                    end: {
                        x: sashData.outer.rect.width,
                        y: sashData.inner.rect.height / 2
                    }
                });
            }


            fieldsCount = muntinsV.reduce((prev, muntinV) => {
                lines.forEach(line => {
                    if (core.calcIsIntersected(line.start, line.end, muntinV.start, muntinV.end) === 2) {
                        prev++;
                    }
                });
                return prev;

            }, muntinsH.length + 1);
        }

        const glazingCount = Number(sash.glazing.glazing_count) || 0;

        const muntinPoly = core.copy(muntinsData.type.type === 'internal' ? glazingData.poly : glazingBeadData.inner.poly);

        muntinPoly.map(point => {
            point.x -= sashData.outer.rect.x;
            point.y -= sashData.outer.rect.y;
        });

        let muntinLength = sash.muntins.reduce((length, muntin) => {
            const mLine = this.config().IccConfig.Configurators.price.muntinsLength === 'outer_sash'
                ? [muntin.start, muntin.end]
                : IccDrawMathService.intersectLineWithPoly([muntin.start, muntin.end], muntinPoly);
            const mLength = IccDrawMathService.getLineInfo(mLine).length / 1e3;
            return length + mLength;
        }, 0);

        if (this.config().IccConfig.Configurators.price.muntinsLength === 'real') {
            muntinLength -= muntinsH.length * muntinsV.length * muntinsData.rWidth / 1e3;
        }

        const muntinPrice = this.getPrice(muntinsData, muntinsData.type.prices);
        let price = 0;

        if (Common.isObject(muntinPrice)) {
            priceMuntin = muntinPrice.price_muntin !== null ? muntinPrice.price_muntin : NaN;
            priceDuplex = muntinPrice.price_duplex !== null ? muntinPrice.price_duplex : NaN;
        }

        if (this.config().IccConfig.Configurators.price.doubleStickedMuntinsLength && muntinsData.type.type === 'sticked') {
            muntinLength = muntinLength * 2;
        }
        if (muntinsData.type.unit === 'field') {
            if (hasDuplex && glazingCount > 1) {
                price = fieldsCount * priceMuntin + fieldsCount * priceDuplex * (glazingCount - 1);
            } else {
                price = fieldsCount * priceMuntin;
            }
        } else {
            if (!hasDuplex) {
                price = muntinLength * priceMuntin;
            } else {
                price = fieldsCount * priceDuplex * (glazingCount - 1) + muntinLength * priceMuntin;
            }
            if (muntinsData.type.price_stick) {
                price += fieldsCount * muntinsData.type.price_stick * 2;
            }
        }

        return {
            type: 'muntins',
            baseValue: price,
            value: price,
            valueType: 'value',
            data: {
                sashId       : sash.id,
                fields       : fieldsCount,
                type         : muntinsData.type.id,
                typeCode     : muntinsData.type.type,
                typeName     : muntinsData.type.name,
                length       : muntinLength,
                size         : muntinsData.sizeId,
                width        : muntinsData.rWidth,
                duplex       : hasDuplex,
                color        : muntinsData.color,
                colorOut     : muntinsData.colorOut
            }
        };
    }

    /**
     * Dopłata belki
     * @param  {Number} price        Cena
     * @param  {Object} PriceElems   Cena elementó
     * @param  {Array} sashes        Skrzydła
     * @param  {Object} muntinsData  Dane szprosów
     * @param  {Object} muntinPrices Ceny szprosów
     * @return {Number}              Cena
     */
    @PriceFunc({
        shortName: 'muntins',
        data: {
            drawData: 'conf.drawData',
            sashes: 'conf.Sashes',
            muntinsData: 'conf.MuntinsData',
            hasMuntins: 'conf.HasMuntins',
            colors: 'conf.Colors'
        }
    })
    suppBars({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {drawData, sashes, muntinsData, hasMuntins, colors}): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (!hasMuntins) {
            return [];
        }
        sashes.forEach(sash => {
            if (Common.isUndefined(sash.intSashes) || sash.intSashes.length === 0) {
                priceSegments.push(this.priceBarsInSash(drawData, PriceElems, sash, muntinsData, colors));
            } else {
                sash.intSashes.forEach(intSash => {
                    priceSegments.push(this.priceBarsInSash(drawData, PriceElems, intSash, muntinsData, colors));
                });
            }
        });

        return priceSegments;
    }

    getPrice(muntinsData, prices) {
        if (!prices
            || !muntinsData.color || !muntinsData.color.groups || !muntinsData.colorOut || !muntinsData.colorOut.groups
            || muntinsData.sizeId == null
        ) {
            return null;
        }

        let colorSide = this.getSideForColors(muntinsData.color, muntinsData.colorOut);
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';
        switch (colorSide) {
            case 'double':
                colorGroups = muntinsData.color.groups.map(Number);
                break;
            case 'single-outer':
                colorGroups = muntinsData.colorOut.groups.map(Number);
                side = 'single';
                break;
            case 'single-inner':
                colorGroups = muntinsData.color.groups.map(Number);
                side = 'single';
                break;
            case 'bicolor' :
                colorGroups = muntinsData.color.groups.map(Number);
                colorGroupsOut = muntinsData.colorOut.groups.map(Number);
                side = 'bicolor';
                break;
        }

        let muntinsPrice = prices.map((p, index) => {
            p.id = index;
            return p;
        }).filter(p => colorGroups.indexOf(Number(p.colorGroup)) > -1
            && (side !== 'bicolor' || side === 'bicolor' && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1)
            && p.side === side && Number(p.size) === Number(muntinsData.sizeId)
        )[0];
        if (muntinsPrice) {
            return muntinsPrice;
        }
        return null;
    }

}
