import { ActiveConfiguration } from './ActiveConfiguration';
import { CoupledWindowConfiguration } from './CoupledWindowConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { WindowConfiguration } from './WindowConfiguration';
import { Coupling, SideProfile, Profile, WindowParameters } from '@icc/window';
import { WindowInCoupling, RollerShutterInCoupling } from './parts/coupled_window';
import { Accessory } from './parts/common';
import { RollerShutter } from './parts/roller_shutter';
import { Sill } from './parts/complementary_goods';

export class CoupledWindowActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is CoupledWindowActiveConfiguration {
        return (
            configuration instanceof CoupledWindowActiveConfiguration
            || (!configuration.$version && configuration.type === 'coupled_window')
        );
    }
    _id?: string = null;
    type: 'coupled_window' = 'coupled_window';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    PriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;
    timestamp: Date | number = undefined;
    parameters: WindowParameters = {
        weight: null,
    };

    height: number = 1500;
    width: number = 1500;
    windows: WindowInCoupling[] = [];
    couplings: Coupling[] = [];
    sideProfiles: SideProfile[] = [];
    sideAccessories: Accessory[] = [];
    rollerShutters: RollerShutterInCoupling[] = [];
    windowSills: Sill[] = [];

    usedProfiles: Profile[] = [];

    detachedPositions: string[] = [];
    removedPositions: string[] = [];

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (CoupledWindowActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (CoupledWindowConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.attributes = configuration.attributes;

                    this.parameters = configuration.parameters;

                    this.height = configuration.height;
                    this.width = configuration.width;
                    this.couplings = configuration.couplings;
                    this.usedProfiles = configuration.usedProfiles;
                    this.windows = configuration.windows;
                    this.rollerShutters = configuration.rollerShutters;
                    this.sideProfiles = configuration.sideProfiles;
                    this.windowSills = configuration.windowSills;
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
