import { Pipe, PipeTransform, Inject } from '@angular/core';
import { unitMMFormatter } from '@icc/helpers';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service';

@Pipe({
    name: 'unitmm',
})
export class UnitmmPipe implements PipeTransform {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private userService: UserService
    ) {}

    transform(value: any, hasunit = 0, inmm = 0, dimension_unit = '', round = null): any {
        return unitMMFormatter(
            value,
            this.config().IccConfig,
            hasunit,
            inmm,
            dimension_unit || this.userService.get().dimension_unit || this.config().dimensionUnit,
            round
        );
    }
}
