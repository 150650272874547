import angular from 'angular';

export default function UpdateCtrl(
    $scope,
    $rootScope,
    $http,
    $location,
    $cookies,
    ResourceService,
    SynchronizeService,
    OffersFactory,
    DatabaseManager,
    UserService,
    IccConfig,
    StateService,
    EventBusService,
) {
    'ngInject';

    const vm = this;
    vm.progressBar = SynchronizeService.progressBar;
    $scope.slides = [];
    $scope.postCodesSynced = localStorage.getItem('post_codes_synced') || null;
    downloadFiles();
    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('progress', () => vm.progressBar = SynchronizeService.progressBar));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    ResourceService.getSynchronizeElements().then(res => {
        $scope.slides = res.data;
    });
    async function downloadFiles(repeat = false) {
        try {
            await SynchronizeService.update(repeat);
            $location.path('/app/sync');
        } catch (err) {
            return downloadFiles(true);
        }
    }
}
