import { Common, ConfigurationsService, ConfiguratorsDataService, EventBusService, core, WindowActiveConfiguration } from '@icc/common';
import { Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { StepsService } from '@icc/helpers';
import { AccessoriesService } from '../window/accessories/accessories.service';
import { MontagesService } from './montages.service';
import { ColorsService } from './colors.service';
import { TypesService } from './types.service';
import { ActiveSash } from '@icc/common/layout/active-sash';

@Injectable()
export class SystemsService {

    systems = [];
    loadedData = false;
    constructor(
        private configurationsService: ConfigurationsService<'mosquito' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private stepsService: StepsService,
        private accessoriesService: AccessoriesService,
        private typesService: TypesService,
        private montagesService: MontagesService,
        private colorsService: ColorsService,
    ) {


        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => this.init());
    }

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    private init() {
        if (this.currentConfiguratorService.conf === 'mosquito') {
            this.systems = this.getSystems();
            if (Common.isUndefined(this.configurationsService.conf.Current.System.id)) {
                this.setDefaultValues();
            }

            if (
                Common.isArray(
                    this.configuratorsDataService.data.mosquitoTypes[
                        this.configurationsService.conf.Current.System.id
                    ]
                )
                && this.configuratorsDataService.data.mosquitoTypes[
                    this.configurationsService.conf.Current.System.id
                ].length
            ) {
                this.stepsService.enable('types');
            } else {
                this.stepsService.disable('types');
            }

            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie systemow garazowych
     */
    getSystems() {
        return this.configuratorsDataService.data.mosquitoLines;
    }

    /**
     * Wybor systemu
     * @param  {object}  system   Wybrany system
     * @param  {boolean} nextStep Czy przejsc do nastepnego kroku
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    selectSystem(system, nextStep?, price?) {
        if(this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.System = system;
        this.configurationsService.conf.Default.System = system;

        this.configurationsService.conf.Current.Name = system.name;
        this.configurationsService.conf.Default.Name = system.name;

        this.typesService.setDefaultValues();
        this.accessoriesService.findAccessories();
        this.montagesService.setDefaultValues();
        this.colorsService.loadColorsBySystem(
            system.id,
            this.configurationsService.conf.Current.Type.id
        );

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }

        if (
            Common.isArray(
                this.configuratorsDataService.data.mosquitoTypes[
                    this.configurationsService.conf.Current.System.id
                ]
            )
            && this.configuratorsDataService.data.mosquitoTypes[
                this.configurationsService.conf.Current.System.id
            ].length
        ) {
            this.stepsService.enable('types');
        } else {
            this.stepsService.disable('types');
        }

        if (
            this.configuratorsDataService.data.mosquitoWindowLines[
                this.configurationsService.conf.Current.System.id
            ]
            || (this.configurationsService.conf.Current.Type
                && this.configurationsService.conf.Current.Type.id
                && (this.configuratorsDataService.data.mosquitoMontages.some(
                    el => this.configurationsService.conf.Current.type === 'mosquito' && el.types.indexOf(this.configurationsService.conf.Current.Type.id) > -1
                )
                    || this.configurationsService.conf.Current.Type.profile_position
                    || this.configurationsService.conf.Current.Type.muntin_position))
        ) {
            this.stepsService.enable('montages');
        } else {
            this.stepsService.disable('montages');
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    setDefaultValues() {
        this.selectSystem(this.systems[0]);
    }

    /**
     * Funkcja obliczająca wymiary siatki dla wybranej kwatery
     * @param  {object} sash  Skrzydło
     */
    getMosquitoDimensions(sash: ActiveSash, conf: WindowActiveConfiguration) {
        const overlaps = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        };

        for (const i in sash.nearMullions) {
            if (sash.nearMullions[i] === -1) {
                const frame = conf.Frames.find(
                    f => f.id === sash.frameId
                );
                const profile = frame && core.fIdO(
                    conf.UsedProfiles,
                    frame.frame[0].profileId
                );
                overlaps[i] = profile ? profile.width : 0;
            } else {
                const mullion = core.fIdO(
                    conf.Mullions,
                    sash.nearMullions[i]
                );
                const profile = core.fIdO(
                    conf.UsedProfiles,
                    mullion.profileId
                );
                overlaps[i] = profile ? profile.width / 2 : 0;
            }
        }

        return {
            // dodatkowo plus 12 mm na kazda strone
            width: Math.floor(sash.shape.width - overlaps.left - overlaps.right + 24),
            height: Math.floor(sash.shape.height - overlaps.top - overlaps.bottom + 24),
        };
    }

    valid(conf: WindowActiveConfiguration) {
        let available = false;
        for (const key in this.configuratorsDataService.data.mosquitoWindowLines) {
            if (
                this.configuratorsDataService.data.mosquitoWindowLines[key].some(
                    el => el.window_line_id === conf.System.id
                )
            ) {
                available = true;
                break;
            }
        }

        if (available) {
            this.stepsService.enable('mosquito');
        } else {
            this.stepsService.disable('mosquito');
            conf.Sashes.forEach(sash => (sash.mosquito = null));
        }
    }
}
