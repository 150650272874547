import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-glazing-bead-colors-page',
  template: require('./glazing-bead-colors-page.component.html'),
  styles: [require('./glazing-bead-colors-page.component.scss')]
})
export class GlazingBeadColorsPageComponent extends PageComponent implements OnInit {
  public title = _('WINDOW|Wybierz kolor');
  public options = [];

  colors: iccListItem[] = [];
  selectedId: number | null = null;

  constructor(@Inject(ICC_PAGE_DATA) private pageData: any, private sharedFacade: SharedFacade) {
    super();
  }

  ngOnInit() {
    const allColors =  [...this.pageData.colors];
    if (this.pageData.hasRal) {
      allColors.push(...this.pageData.colorsRAL);
    }
    this.colors = allColors.map(color => ({
        id: String(color.id) + '.' + Number(Boolean(color.RAL)),
        title: color.name,
        color: '#' + color.color,
        imageUrl: `/files/glazingbeadcolor/${color.color_img}`,
        description: `${color.name}`,
    }));
    if (this.pageData.selColor) {
        this.selectedId = Number(this.pageData.selColor.id);
    }
  }

  select(item: iccListItem) {
    const [id, RAL] = String(item.id).split('.');
    const color = [...this.pageData.colors, ...this.pageData.colorsRAL].find(f => Number(f.id) === Number(id) && f.RAL === Boolean(Number(RAL)));
    this.sharedFacade.closePage(color);
  }

}
