import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';


@Injectable()
export class PriceGlazingBeadService {
    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru listwy przyszybowej.
     *
     * @param {number} price            Wejsciowa cena
     * @param {object} PriceElems       Składowe wyceny
     * @param {array}  NoPriceCauses    Powody braku wyceny
     * @param {array}  constrElems      Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} glazingBeadColor Kolor listwy przyszybowej
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'glazingBeadColor',
        data: {
            glazingBeadColor: 'conf.GlazingBeadColor',
            system          : 'conf.System',
            customPrice     : 'price.GlazingBeadColor',
            colors          : 'data.glazingBeadsColors'
        }
    })
    suppGlazingBeadColor({}: PriceElemsData,
        {glazingBeadColor, system, customPrice, colors}: {glazingBeadColor, system, customPrice: CustomPricesRecords, colors}
    ): PriceSegment[] {
        let factors = 1;

        if (!this.config().IccConfig.Configurators.glazingBeadsColorSelect) {
            return [];
        }
        if (Common.isObject(glazingBeadColor)) {
            let glazingBeadColorPrice;
            if (glazingBeadColor.RAL) {
                glazingBeadColorPrice = this.getSpecialColorPrice('RAL', colors, customPrice);
            } else if (Common.isObject(customPrice) && customPrice[glazingBeadColor.id]) {
                glazingBeadColorPrice = customPrice[glazingBeadColor.id].getPrice('price');
            } else {
                glazingBeadColorPrice = parseFloat(glazingBeadColor.price);
            }

            factors *= (100 + glazingBeadColorPrice) / 100;
        }

        return <PriceSegment[]>[{
            type: 'glazingBeadColor',
            baseValue: factors,
            value: factors,
            valueType: 'percent',
            data: {
                id  : glazingBeadColor.id,
                name: glazingBeadColor.name,
            }
        }];
    }

    /**
     * Funkcja pobierania typu ID
     * @param  {object} muntinsData Dane szprosów
     * @param  {object} type        Typ
     */
    getSpecialColorPrice(type, colors, customPrices) {
        const filteredColors = colors.filter(color => color.type === type);
        if (filteredColors.length > 0) {
            let specialColorPrice;
            if (Common.isObject(customPrices) && customPrices[filteredColors[0].id]) {
                specialColorPrice = customPrices[filteredColors[0].id].getPrice('price');
            } else {
                specialColorPrice = parseFloat(filteredColors[0].price);
            }
            return specialColorPrice;
        }
    }
}
