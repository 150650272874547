import { unitMFormatter } from '@icc/helpers';

export default function UnitMFilter($filter, UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {number} mb             Metr bieżący - 1 lub 2 - cena za metr bieżący?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, mb = 0, dimension_unit = '') => {
        const dimensionUnit = dimension_unit || UserService.get().dimension_unit;
        const mbUnit = $filter('translate')('DIMENSION|mb');
        return unitMFormatter(data, IccConfig, hasunit, inmm, mb, dimensionUnit, mbUnit);
    };
}
