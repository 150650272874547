import { Component, OnInit, Type, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { ShapesComponent } from '../shapes/shapes.component';
import { MeasurementsPageComponent } from '../measurements-page/measurements-page.component';
import { TranslateService } from '@ngx-translate/core';
import { _, StepComponent, ConfiguratorOptions } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { Shape, getDefaultShapeDimensions, expandShape, Profile } from '@icc/window';
import { tap, filter, map, delay, withLatestFrom, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNotNullOrUndefined, StepsService, unitMMFormatter } from '@icc/helpers';
import { of } from 'rxjs';
import { DimensionsService } from '@icc/legacy/configurator/steps/window/dimensions/dimensions.service';
import {
    EventBusService,
    ConfigurationsService,
    ParametersService,
    APP_CONFIG,
    AppConfigFactory,
    Common,
    core,
    ShapeService,
    WindowActiveConfiguration,
} from '@icc/common';
import { ResizeService } from '@icc/legacy/configurator/layout/resize.service';
import { FillingValidationService } from '@icc/legacy/configurator/steps/window/glazings/filling-validation.service';
import { PriceService } from '@icc/price';
import { LayoutService } from '@icc/legacy/configurator/layout/layout.service';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';
import { UnitConverterService } from '@icc/configurator/ui';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';

@Component({
    selector: 'icc-dimensions',
    template: require('./dimensions.component.html'),
    styles: [require('./dimensions.component.scss')],
})
export class DimensionsComponent extends StepComponent implements OnInit, OnDestroy, AfterViewInit {
    static stepName = _('DIMENSION|Rozmiar');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public configurator = 'window';
    public stepId = 'dimensions';
    public title =
        this.configurationsService.conf.Current.type !== 'door'
            ? _('WINDOW|Uzupełnij wymiary okna')
            : _('WINDOW|Uzupełnij wymiary drzwi');
    public options = [
        {
            title: _('WINDOW|Kształt konstrukcji'),
            component: ShapesComponent,
            icon: {
                ligature: 'category',
            },
            show: () => {
                const conf = this.configurationsService.conf.Current;
                const availableShapes = this.shapeService.getAvailableShapes(conf.System.id);

                return (
                    conf.type !== 'door'
                    && conf.type !== 'sliding_door'
                    && availableShapes
                    && availableShapes.length > 1
                );
            },
            componentData: {
                setShape: () => this.setShape.bind(this),
                selectedShape: () => this.shape,
            },
        },
        {
            title: _('WINDOW|Uzupełnij pomiary z budowy'),
            component: MeasurementsPageComponent,
            icon: {
                ligature: 'square_foot',
            },
            show: () => {
                const conf = this.configurationsService.conf.Current;
                return (
                    this.config().IccConfig.Configurators.measurements
                    && conf.type !== 'door'
                    && conf.type !== 'sliding_door'
                );
            },
            componentData: {
                show: () => {
                    const conf = this.configurationsService.conf.Current;
                    return conf.Measurements && Common.isArray(conf.Measurements) ? true : false;
                },
                setDimensions: () => (shape: any) => {
                    this.dimensionsForm.setValue(shape);
                }
            },
        },
    ];
    private subscriptions: any[] = [];

    public shape$ = this.windowFacade.shape$;

    public dimensionsForm: FormGroup = this.fb.group({});
    public foundationForm: FormGroup = this.fb.group({
        isFoundationProfile: false,
        foundationProfile: null,
        foundationProfileHeight: this.fb.control(0,{updateOn: "blur"}),
    });
    public shape: Partial<Shape> | null = null;

    public fields: {
        type: string;
        options: Array<any>;
        name: string;
        field?: keyof Shape;
        highlight?: keyof Shape;
        value?: number;
    }[] = [];

    private dimensionsFields: {
        [key in Shape['shape']]: {
            type: string;
            name: string;
            field?: keyof Shape;
            highlight?: keyof Shape;
            options: Array<any>;
            show: (shape?: Partial<Shape>) => boolean;
            value?: (shape?: Partial<Shape>) => number;
            focus?: boolean;
        }[]
    } = {
        rect: [
            {
                type: 'number',
                name: 'width',
                field: 'width',
                options: [],
                show: () => true,
                focus: true,
            },
            {
                type: 'number',
                name: 'height',
                field: 'height',
                options: [],
                show: () => true,
            },
            {
                type: 'select',
                options: [],
                name: 'shortening',
                field: 'shortening',
                show: () => this.configurationsService.conf.Current.type === 'door',
            },
        ],
        triangle: [
            {
                type: 'number',
                name: 'h1',
                field: 'height',
                highlight: 'h1',
                options: [],
                show: () => true,
                focus: true,
            },
            {
                type: 'number',
                name: 's1',
                field: 's1',
                options: [],
                show: (shape: Shape) =>
                    (shape.type && ['F', 'L'].indexOf(shape.type) >= 0) || Number(shape.s1) > 0,
                focus: true,
            },
            {
                type: 'number',
                name: 's2',
                field: 's3',
                options: [],
                show: (shape: Shape) =>
                    (shape.type && ['F', 'R'].indexOf(shape.type) >= 0) || Number(shape.s3) > 0,
                focus: true,
            },
        ],
        circle: [
            {
                type: 'number',
                name: 'diameter',
                field: 'd',
                options: [],
                show: () => true,
                focus: true,
            },
            {
                type: 'number',
                name: 'perimeter',
                value: (shape: Shape) => Math.round(Number(shape.d) * 3.14 * 100) / 100,
                options: [],
                show: () => true,
            },
        ],
        poligon: [
            {
                type: 'number',
                name: 's1',
                field: 's1',
                options: [],
                show: (shape: Shape) =>
                    (shape.type
                        && ['SLT', 'SLS', 'SLC', 'DLT', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s1) > 0,
                focus: true,
            },
            {
                type: 'number',
                name: 's2',
                field: 's2',
                options: [],
                show: (shape: Shape) =>
                    (shape.type
                        && ['SLS', 'SRS', 'SLC', 'SRC', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s2) > 0,
            },
            {
                type: 'number',
                name: 's3',
                field: 's3',
                options: [],
                show: (shape: Shape) =>
                    (shape.type
                        && ['SRT', 'SRS', 'SRC', 'DLT', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s3) > 0,
            },
            {
                type: 'number',
                name: 'h1',
                field: 'h1',
                options: [],
                show: () => true,
            },
            {
                type: 'number',
                name: 'h2',
                field: 'h2',
                options: [],
                show: (shape: Shape) =>
                    (shape.type && ['SRT', 'SRC', 'DLT', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.h2) > 0,
            },
            {
                type: 'number',
                name: 'h3',
                field: 'h3',
                options: [],
                show: (shape: Shape) =>
                    (shape.type && ['SLT', 'SLC', 'DLT', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.h3) > 0,
            },
        ],
        arc: [
            {
                type: 'number',
                name: 'h1',
                field: 'h1',
                options: [],
                show: () => true,
                focus: true,
            },
            {
                type: 'number',
                name: 'h2',
                field: 'h2',
                options: [],
                show: () => true,
            },
            {
                type: 'number',
                name: 'width',
                field: 'width',
                options: [],
                show: () => true,
            },
        ],
    };

    dimensionUnit = this.unitConverterService.getUnit();
    foundationProfiles: Profile[] = [];

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.type !== 'door' || (!conf.System || !Boolean(conf.System.door_type));
    foundationActualProfile: Profile | undefined;

    constructor(
        private windowFacade: WindowFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private fb: FormBuilder,
        private dimensionsService: DimensionsService,
        private eventBusService: EventBusService,
        private stepsService: StepsService,
        private resizeService: ResizeService,
        private fillingValidationService: FillingValidationService,
        private configurationsService: ConfigurationsService<'window'>,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private layoutService: LayoutService,
        private schemasService: SchemasService,
        private unitConverterService: UnitConverterService,
        private measurementsService: MeasurementsService,
        private extensionsService: ExtensionsService,
        private shapeService: ShapeService
    ) {
        super();

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.subscriptions.push(
            this.eventBusService.subscribe<any>('startedChangingStep', data => {
                if (
                    data.value.nextStep.code !== 'dimensions'
                    && data.value.nextStep.code !== data.value.prevStep.code
                    && data.value.prevStep.code === 'dimensions'
                ) {
                    if (this.dimensionsService.valid(this.shape)) {
                        this.resizeLayout();
                        this.fillingValidationService.valid();
                    } else {
                        data.value.cancel();
                    }
                }
            })
        );

        this.subscriptions.push(
            this.eventBusService.subscribe<any>('loadedProfiles', data => {
                this.foundationProfiles = this.extensionsService.getFoundationExtensions(
                    this.configurationsService.conf.Current
                );
            })
        );
    }
    
    private formatUnit(value: number) {
        return unitMMFormatter(value, this.config().IccConfig, 1, 0, this.dimensionUnit.unit);
    }

    private init() {
        this.subscriptions.push(
            this.shape$.pipe(filter(isNotNullOrUndefined)).subscribe(shape => {
                this.shape = shape;
                const group = this.fb.group({});
                const fields = this.dimensionsFields[shape.shape]
                    .filter(f => f.show(shape))
                    .map(f => ({
                        type: f.type,
                        options:
                            f.field === 'shortening' ? this.buildShorteningOptions() : f.options,
                        name: f.name,
                        field: f.field,
                        highlight: f.highlight || f.field,
                    }));

                fields.forEach(field => {
                    if (field.field) {
                        group.addControl(
                            field.field,
                            this.fb.control(shape[field.field], {
                                updateOn: 'blur',
                            })
                        );
                    }
                });
                this.dimensionsForm = group;
                this.dimensionsForm.valueChanges.subscribe(value => {
                    const newShape: Shape = {
                        ...shape,
                        ...value,
                    };
                    this.setTempShapeDimensions(newShape);
                });
                this.fields = fields;
            })
        );
        this.foundationProfiles = this.extensionsService.getFoundationExtensions(
            this.configurationsService.conf.Current
        );
        const foundationProfile = this.extensionsService.getFoundationProfile(
            this.configurationsService.conf.Current
        );
        this.foundationForm.setValue({
            isFoundationProfile: this.configurationsService.conf.Current.foundationProfile ?? false,
            foundationProfile: foundationProfile ? foundationProfile.profileId : null,
            foundationProfileHeight: foundationProfile ? foundationProfile.width : 0,
        });
        this.subscriptions.push(
            this.foundationForm.controls.isFoundationProfile.valueChanges.subscribe(
                isFoundationProfile => {
                    if (isFoundationProfile && this.foundationProfiles.length > 0) {
                        this.foundationForm.controls.foundationProfile.setValue(
                            this.foundationProfiles[0].id
                        );
                        this.foundationForm.controls.foundationProfileHeight.setValue(
                            this.foundationProfiles[0].minHeight
                        );
                    } else {
                        this.foundationForm.controls.foundationProfile.setValue(null);
                        this.foundationForm.controls.foundationProfileHeight.setValue(0);
                    }
                }
            )
        );
        this.subscriptions.push(
            this.foundationForm.controls.foundationProfile.valueChanges.subscribe(
                foundationProfileId => {
                    this.setActualFoundationProfile(foundationProfileId);
                }
            )
        );
        this.subscriptions.push(
            this.foundationForm.controls.foundationProfileHeight.valueChanges.subscribe(
                value => {
                    const maxHeight = this.foundationProfiles.find(p => p.id === this.foundationForm.controls.foundationProfile?.value)?.maxHeight || 0;
                    const minHeight = this.foundationProfiles.find(p => p.id === this.foundationForm.controls.foundationProfile?.value)?.minHeight || 0;
                    if (maxHeight && maxHeight < value){
                        this.foundationForm.controls.foundationProfileHeight.setValue(
                            maxHeight
                        );
                    }
                    else if (minHeight && minHeight > value){
                        this.foundationForm.controls.foundationProfileHeight.setValue(
                            minHeight
                        );
                    }
                }
            )
        );
    }
    setActualFoundationProfile(foundationProfileId: number){
        this.foundationActualProfile = this.foundationProfiles.find(p => p.id === foundationProfileId);
                    if (this.foundationActualProfile) {
                        this.foundationForm.controls.foundationProfileHeight.setValidators([
                            ...(this.foundationActualProfile.minHeight ? [Validators.min(this.foundationActualProfile.minHeight)] : []),
                            ...(this.foundationActualProfile.maxHeight ? [Validators.max(this.foundationActualProfile.maxHeight)] : []),
                        ]);
                        if (
                            this.foundationActualProfile.minHeight
                            && this.foundationForm.controls.foundationProfileHeight.value
                                < this.foundationActualProfile?.minHeight
                        ) {
                            this.foundationForm.controls.foundationProfileHeight.setValue(
                                this.foundationActualProfile.minHeight
                            );
                        }
                        if (
                            this.foundationActualProfile.maxHeight
                            && this.foundationForm.controls.foundationProfileHeight.value
                                > this.foundationActualProfile?.maxHeight
                        ) {
                            this.foundationForm.controls.foundationProfileHeight.setValue(
                                this.foundationActualProfile.maxHeight
                            );
                        }
                    }
    }
    resizeLayout() {
        const conf = this.configurationsService.conf.Current;
        const oldWidth = conf.Width;
        const oldHeight = conf.Height;

        this.dimensionsService.setDimensions(this.shape);
        this.resizeService.resizeLayout(conf.Width - oldWidth, conf.Height - oldHeight, conf);
        this.dimensionsService.validDoorModelDimensions(this.shape);
        if (!this.config().IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
            this.schemasService.setShuttersBySchema({
                width: this.shape.width,
                height: this.shape.height,
                nextStep: false,
            });
        }

        // RollerDimensionsFactory.loadBoxHeights(this.shape.height);
        // RollerDimensionsFactory.updateDimensions();
        // vm.boxSizes = RollerDimensionsFactory.boxSizes;
        // MosquitoDimensionsService.validateAndUpdateDimensions();
        this.extensionsService.setFoundationProfile(conf, this.foundationForm.value);

        conf.ChangedDimensions = false;
        this.eventBusService.post({
            key: 'icc-redraw',
            value: null,
        });
    }

    ngAfterViewInit() {
        const conf = this.configurationsService.conf.Current;
        if (conf.ChangedDimensions) {
            this.dimensionsForm.setValue(this.measurementsService.shapeData);
        }
        this.setActualFoundationProfile(this.foundationForm.controls.foundationProfile.value)
    }

    setTempShapeDimensions(newShape: Shape) {
        this.shape = newShape;
        if (this.shape.shape === 'arc' && Number(this.shape.h2) * 2 > Number(this.shape.width)) {
            this.shape.h2 = Math.floor(Number(this.shape.width) * 0.5);
        }
        if (this.shape.shape === 'circle') {
            this.shape.radius = Number(this.shape.d) / 2;
        }

        this.dimensionsService.changedDimensions();
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    buildShorteningOptions() {
        let range: Number[] = [];
        (this.configurationsService.conf.Current.System.shortening || []).forEach(
            (s: { min: number; max: number; step: number }) => {
                let min = Number(s.min);
                const max = Number(s.max);
                const step = Number(s.step);
                if (max !== 0) {
                    while (step > 0 ? max >= min : max <= min) {
                        if (!range.includes(min)) {
                            range.push(min);
                        }
                        min += step;
                    }

                    if (range.indexOf(0) === -1) {
                        range.unshift(0);
                    }
                }
            }
        );
        return range.map(i => ({
            name: i,
            value: i,
        }));
    }

    /**
     * Funkcja ustawiajaca kształt
     * @param {object} shape Kształt
     */
    setShape(shape: Shape['shape'], variant?: Shape['type']) {
        this.setTempShapeDimensions(
            expandShape(
                getDefaultShapeDimensions(
                    shape,
                    this.configurationsService.conf.Current.Width,
                    this.configurationsService.conf.Current.Height,
                    variant
                )
            )
        );
        if (this.shape) {
            if (variant) {
                this.shape.type = variant;
            }
            this.configurationsService.conf.Current.Shape = this.shape as Shape;
            if (shape !== 'rect') {
                this.configurationsService.conf.Current.hasRoller = false;
            }
            this.priceService.count();
            this.parametersService.count(this.configurationsService.conf.Current);
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
            this.layoutService.resetLayout();
        }
    }
    
    isFoundationProfileAvailable() {
        return (
            this.foundationProfiles.length > 0
            && this.config().IccConfig.Configurators.foundationProfile
        );
    }
    // /**
    //  * Funkcja dodawania pliku
    //  */
    // addFile() {
    //     if (ConfigurationsService.conf.Current.Attachments.length && !vm.attachment.length) {
    //         $rootScope.showInfo(
    //             $filter('translate')('CONFIGURATOR|Załączniki zostały usunięte'),
    //             null
    //         );
    //     } else if (!ConfigurationsService.conf.Current.Attachments.length) {
    //         $rootScope.showInfo(
    //             $filter('translate')('CONFIGURATOR|Załączniki zostały dodane'),
    //             null
    //         );
    //     } else {
    //         $rootScope.showInfo(
    //             $filter('translate')('CONFIGURATOR|Załączniki zostały zmienione'),
    //             null
    //         );
    //     }
    //     ConfigurationsService.conf.Current.Attachments = vm.attachment;
    // }
}
