import { Injectable, Inject } from '@angular/core';
import { Common } from '@icc/common/Common';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { core } from '@icc/common/helpers';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { EventBusService } from '@icc/common/event-bus.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price';
import { TranslateService } from '@icc/common/translate.service';
import { BrowserShapeService } from './shape.service';
import { MullionsLayoutService } from './mullions-layout.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { SashesLayoutService } from './sashes-layout.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';
import { ProfilesService } from '@icc/common/profiles.service';

@Injectable()
export class SplitSashService {
    minWidth: number = this.config().IccConfig.Configurators.minWidth;
    minWidthGlass: number = this.config().IccConfig.Configurators.minWidthGlass;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private warrantyService: WarrantyService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private translateService: TranslateService,
        private shapeService: BrowserShapeService,
        private mullionsLayoutService: MullionsLayoutService,
        private constructionLimitationService: ConstructionLimitationService,
        private sashesLayoutService: SashesLayoutService,
        private profilesService: ProfilesService
    ) {}

    /**
     * Dzieli pole w skrzdyle
     *
     * @param {Sash}                      sash      Skrzydło
     * @param {Sash}                      intSash   Pole w skrzydle
     * @param {"horizontal" | "vertical"} direction Czy podział poziomy czy pionowy
     */
    splitInternalSash(
        sash: ActiveSash,
        intSash: ActiveSash,
        direction: 'horizontal' | 'vertical',
        conf: WindowActiveConfiguration
    ) {
        // brak skrzydła, bład powodowany za szybkim klikaniem
        // (kliknięciem w podział i kliknięciem coś innego przed zrobieniem tego)
        if (Common.isUndefined(sash) || sash === null) {
            return;
        }

        const sashes = sash.intSashes;
        const edgeSashes = sash.intEdgeSashes;
        const alignments = sash.intAlignments;
        const dividers = sash.intMullions;
        const count = this.sashesLayoutService.getIdForNewSash(conf);
        const divsCount = this.mullionsLayoutService.getIdForNew(conf);
        let newSash: ActiveSash;
        let newDivider: ActiveMullion;
        let minWidthReal: number;
        let minWidthPrevSash: number;
        let minWidthNextSash: number;

        if (direction === 'horizontal') {
            if (conf.drawData) {
                const glazingBeadData = conf.drawData.glazingBead.find(
                    gb => gb.sashId === intSash.id
                );
                const sashData = conf.drawData.sash.find(s => s.sashId === intSash.id);
                const defaultProfileId = this.profilesService.getMullionProfileIdBetweenFields(
                    conf,
                    [intSash],
                    [intSash],
                    direction
                );
                const profile = this.profilesService.getProfile(defaultProfileId);
                if (glazingBeadData && sashData && profile) {
                    minWidthPrevSash =
                        glazingBeadData.inner.rect.y
                        - sashData.outer.rect.y
                        + profile.width / 2
                        + (glazingBeadData.inner.rect.y - glazingBeadData.outer.rect.y)
                        + this.minWidthGlass;
                    minWidthNextSash =
                        sashData.outer.rect.y
                        + sashData.outer.rect.height
                        - (glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height)
                        + profile.width / 2
                        + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                        + this.minWidthGlass;
                    minWidthReal = minWidthPrevSash + minWidthNextSash;
                }
            }

            if (intSash.rHeight < minWidthReal) {
                alert(this.translateService.instant('INTERFACE|Nie można podzielić skrzydła'));
                return;
            }

            let adjustRHeight: number;
            let adjustRHeight2: number;
            adjustRHeight = adjustRHeight2 = Math.floor(intSash.rHeight / 2);
            if (adjustRHeight2 !== intSash.rHeight / 2) {
                adjustRHeight++;
            }
            if (minWidthNextSash && adjustRHeight2 < minWidthNextSash) {
                adjustRHeight2 = minWidthNextSash;
                adjustRHeight = intSash.rHeight - adjustRHeight2;
            }
            if (minWidthPrevSash && adjustRHeight < minWidthPrevSash) {
                adjustRHeight = minWidthPrevSash;
                adjustRHeight2 = intSash.rHeight - adjustRHeight;
            }

            newSash = new ActiveSash(count, {
                parentId: intSash.parentId,
                rx: intSash.rx,
                ry: intSash.ry + adjustRHeight,
                rWidth: intSash.rWidth,
                rHeight: adjustRHeight2,
                type: null,
                divs: {
                    top: divsCount,
                    left: intSash.nearMullions.left,
                    right: intSash.nearMullions.right,
                    bottom: intSash.nearMullions.bottom,
                },
                nearAlignments: {
                    top: -1,
                    left: intSash.nearAlignments.left,
                    right: intSash.nearAlignments.right,
                    bottom: intSash.nearAlignments.bottom,
                },
                bondedGlazing: intSash.bondedGlazing,
            });

            if (newSash.nearMullions.bottom === -1 && newSash.nearAlignments.bottom === -1) {
                edgeSashes.bottom.push(newSash.id);
            }
            if (newSash.nearMullions.top === -1 && newSash.nearAlignments.top === -1) {
                edgeSashes.top.push(newSash.id);
            }
            if (newSash.nearMullions.left === -1 && newSash.nearAlignments.left === -1) {
                edgeSashes.left.push(newSash.id);
            }
            if (newSash.nearMullions.right === -1 && newSash.nearAlignments.right === -1) {
                edgeSashes.right.push(newSash.id);
            }

            newDivider = new ActiveMullion(divsCount, {
                rx: intSash.rx,
                ry: intSash.ry + adjustRHeight,
                rWidth: intSash.rWidth,
                rHeight: 1,
                multiAlignTop: [intSash],
                multiAlignBottom: [newSash],
                direction,
                parentSash: intSash.parentId,
            });

            intSash.rHeight = adjustRHeight;

            if (intSash.nearMullions.bottom !== -1) {
                const divBottom = core.fId(dividers, intSash.nearMullions.bottom);
                dividers[divBottom].multiAlignTop = dividers[divBottom].multiAlignTop.filter(
                    el => el.id !== intSash.id
                );
                dividers[divBottom].multiAlignTop.push(newSash);
            }
            if (intSash.nearMullions.left !== -1) {
                const divLeft = core.fId(dividers, intSash.nearMullions.left);
                dividers[divLeft].multiAlignRight.push(newSash);
                dividers[divLeft].multiAlignRightDiv.push(newDivider);
            }
            if (intSash.nearMullions.right !== -1) {
                const divRight = core.fId(dividers, intSash.nearMullions.right);
                dividers[divRight].multiAlignLeft.push(newSash);
                dividers[divRight].multiAlignLeftDiv.push(newDivider);
            }

            if (intSash.nearAlignments.bottom !== -1) {
                const divBottom = core.fId(alignments, intSash.nearAlignments.bottom);
                alignments[divBottom].adjacentSashes.top = alignments[
                    divBottom
                ].adjacentSashes.top.filter(el => el !== intSash.id);
                alignments[divBottom].adjacentSashes.top.push(newSash.id);
            }
            if (intSash.nearAlignments.left !== -1) {
                const divLeft = core.fId(alignments, intSash.nearAlignments.left);
                alignments[divLeft].adjacentSashes.right.push(newSash.id);
                alignments[divLeft].perpendicularMullions.right.push(newDivider.id);
            }
            if (intSash.nearAlignments.right !== -1) {
                const divRight = core.fId(alignments, intSash.nearAlignments.right);
                alignments[divRight].adjacentSashes.left.push(newSash.id);
                alignments[divRight].perpendicularMullions.left.push(newDivider.id);
            }

            intSash.nearMullions.bottom = newDivider.id;
            intSash.nearAlignments.bottom = -1;
            if (intSash.nearMullions.bottom !== -1) {
                edgeSashes.bottom = edgeSashes.bottom.filter(s => s !== intSash.id);
            }
        } else if (direction === 'vertical') {
            if (conf.drawData) {
                const glazingBeadData = conf.drawData.glazingBead.find(
                    gb => gb.sashId === intSash.id
                );
                const sashData = conf.drawData.sash.find(s => s.sashId === intSash.id);
                const defaultProfileId = this.profilesService.getMullionProfileIdBetweenFields(
                    conf,
                    [intSash],
                    [intSash],
                    direction
                );
                const profile = this.profilesService.getProfile(defaultProfileId);
                if (glazingBeadData && sashData && profile) {
                    minWidthPrevSash =
                        glazingBeadData.inner.rect.x
                        - sashData.outer.rect.x
                        + profile.width / 2
                        + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                        + this.minWidthGlass;
                    minWidthNextSash =
                        sashData.outer.rect.x
                        + sashData.outer.rect.width
                        - (glazingBeadData.inner.rect.x + glazingBeadData.inner.rect.width)
                        + profile.width / 2
                        + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                        + this.minWidthGlass;
                    minWidthReal = minWidthPrevSash + minWidthNextSash;
                }
            }

            if (intSash.rWidth < minWidthReal) {
                alert(this.translateService.instant('INTERFACE|Nie można podzielić skrzydła'));
                return;
            }
            let adjustRWidth: number;
            let adjustRWidth2: number;
            adjustRWidth = adjustRWidth2 = Math.floor(intSash.rWidth / 2);
            if (adjustRWidth2 !== intSash.rWidth / 2) {
                adjustRWidth2++;
            }
            if (minWidthNextSash && adjustRWidth2 < minWidthNextSash) {
                adjustRWidth2 = minWidthNextSash;
                adjustRWidth = intSash.rWidth - adjustRWidth2;
            }
            if (minWidthPrevSash && adjustRWidth < minWidthPrevSash) {
                adjustRWidth = minWidthPrevSash;
                adjustRWidth2 = intSash.rWidth - adjustRWidth;
            }

            newSash = new ActiveSash(count, {
                parentId: intSash.parentId,
                rx: intSash.rx + adjustRWidth,
                ry: intSash.ry,
                rWidth: adjustRWidth2,
                rHeight: intSash.rHeight,
                type: null,
                divs: {
                    left: divsCount,
                    top: intSash.nearMullions.top,
                    bottom: intSash.nearMullions.bottom,
                    right: intSash.nearMullions.right,
                },
                nearAlignments: {
                    left: -1,
                    top: intSash.nearAlignments.top,
                    bottom: intSash.nearAlignments.bottom,
                    right: intSash.nearAlignments.right,
                },
                bondedGlazing: intSash.bondedGlazing,
            });

            if (newSash.nearMullions.bottom === -1 && newSash.nearAlignments.bottom === -1) {
                edgeSashes.bottom.push(newSash.id);
            }
            if (newSash.nearMullions.top === -1 && newSash.nearAlignments.top === -1) {
                edgeSashes.top.push(newSash.id);
            }
            if (newSash.nearMullions.left === -1 && newSash.nearAlignments.left === -1) {
                edgeSashes.left.push(newSash.id);
            }
            if (newSash.nearMullions.right === -1 && newSash.nearAlignments.right === -1) {
                edgeSashes.right.push(newSash.id);
            }

            newDivider = new ActiveMullion(divsCount, {
                rx: intSash.rx + adjustRWidth,
                ry: intSash.ry,
                rWidth: 1,
                rHeight: intSash.rHeight,
                multiAlignLeft: [intSash],
                multiAlignRight: [newSash],
                direction,
                parentSash: intSash.parentId,
            });

            intSash.rWidth = adjustRWidth;

            if (intSash.nearMullions.right !== -1) {
                const divRight = core.fId(dividers, intSash.nearMullions.right);
                dividers[divRight].multiAlignLeft = dividers[divRight].multiAlignLeft.filter(
                    el => el.id !== intSash.id
                );
                dividers[divRight].multiAlignLeft.push(newSash);
            }
            if (intSash.nearMullions.top !== -1) {
                const divTop = core.fId(dividers, intSash.nearMullions.top);
                dividers[divTop].multiAlignBottom.push(newSash);
                dividers[divTop].multiAlignBottomDiv.push(newDivider);
            }
            if (intSash.nearMullions.bottom !== -1) {
                const divBottom = core.fId(dividers, intSash.nearMullions.bottom);
                dividers[divBottom].multiAlignTop.push(newSash);
                dividers[divBottom].multiAlignTopDiv.push(newDivider);
            }

            if (intSash.nearAlignments.right !== -1) {
                const divRight = core.fId(alignments, intSash.nearAlignments.right);
                alignments[divRight].adjacentSashes.left = alignments[
                    divRight
                ].adjacentSashes.left.filter(el => el !== intSash.id);
                alignments[divRight].adjacentSashes.left.push(newSash.id);
            }
            if (intSash.nearAlignments.top !== -1) {
                const divTop = core.fId(alignments, intSash.nearAlignments.top);
                alignments[divTop].adjacentSashes.bottom.push(newSash.id);
                alignments[divTop].perpendicularMullions.bottom.push(newDivider.id);
            }
            if (intSash.nearAlignments.bottom !== -1) {
                const divBottom = core.fId(alignments, intSash.nearAlignments.bottom);
                alignments[divBottom].adjacentSashes.top.push(newSash.id);
                alignments[divBottom].perpendicularMullions.top.push(newDivider.id);
            }

            intSash.nearMullions.right = newDivider.id;
            intSash.nearAlignments.right = -1;
            if (intSash.nearMullions.right !== -1) {
                edgeSashes.right = edgeSashes.right.filter(s => s !== intSash.id);
            }
        }

        sashes.splice(sash.id + 100, 0, newSash);
        dividers.push(newDivider);
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.shapeService.setShapes(conf);
        this.constructionLimitationService.findReinforcement(conf);
        this.sashesLayoutService.setIndex(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Dzieli skrzydło/kwaterę
     *
     * @param {Sash}                       sash      Skrzydło/kwatera
     * @param {"horizontal" | "vertical"}  direction Czy podział poziomy czy pionowy
     */
    splitSash(
        sash: ActiveSash,
        direction: 'horizontal' | 'vertical',
        conf: WindowActiveConfiguration
    ) {
        // brak skrzydła, bład powodowany za szybkim klikaniem
        // (kliknięciem w podział i kliknięciem coś innego przed zrobieniem tego)
        if (Common.isUndefined(sash) || sash === null) {
            return;
        }

        sash = core.fIdO(conf.Sashes, sash.id);
        let minWidthReal;
        let minWidthPrevSash;
        let minWidthNextSash;

        // Jeśli jest stałe szklenie w ramie i dzielimy na dwa różne skrzydła
        if (sash.type.type === 'F') {
            let newSash: ActiveSash;
            let newDivider: ActiveMullion;
            const edgeSashes = conf.EdgeSashes;
            const count = this.sashesLayoutService.getIdForNewSash(conf);
            const divsCount = this.mullionsLayoutService.getIdForNew(conf);

            if (direction === 'horizontal') {
                if (conf.drawData) {
                    const glazingBeadData = conf.drawData.glazingBead.find(
                        gb => gb.sashId === sash.id
                    );
                    const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);
                    const defaultProfileId = this.profilesService.getMullionProfileIdBetweenFields(
                        conf,
                        [sash],
                        [sash],
                        direction
                    );
                    const profile = this.profilesService.getProfile(defaultProfileId);
                    if (glazingBeadData && sashData && profile) {
                        minWidthPrevSash =
                            glazingBeadData.inner.rect.y
                            - sashData.outer.rect.y
                            + profile.width / 2
                            + (glazingBeadData.inner.rect.y - glazingBeadData.outer.rect.y)
                            + this.minWidthGlass;
                        minWidthNextSash =
                            sashData.outer.rect.y
                            + sashData.outer.rect.height
                            - (glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height)
                            + profile.width / 2
                            + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                            + this.minWidthGlass;
                        minWidthReal = minWidthPrevSash + minWidthNextSash;
                    }
                }

                if (sash.rHeight < minWidthReal) {
                    alert(this.translateService.instant('INTERFACE|Nie można podzielić skrzydła'));
                    return;
                }
                let adjustRHeight: number;
                let adjustRHeight2: number;
                adjustRHeight = adjustRHeight2 = Math.floor(sash.rHeight / 2);
                if (adjustRHeight2 !== sash.rHeight / 2) {
                    adjustRHeight2++;
                }
                if (minWidthNextSash && adjustRHeight2 < minWidthNextSash) {
                    adjustRHeight2 = minWidthNextSash;
                    adjustRHeight = sash.rHeight - adjustRHeight2;
                }
                if (minWidthPrevSash && adjustRHeight < minWidthPrevSash) {
                    adjustRHeight = minWidthPrevSash;
                    adjustRHeight2 = sash.rHeight - adjustRHeight;
                }

                newSash = new ActiveSash(count, {
                    rx: sash.rx,
                    ry: sash.ry + adjustRHeight,
                    frameId: sash.frameId,
                    rWidth: sash.rWidth,
                    rHeight: adjustRHeight2,
                    type: sash.type,
                    frame: core.copy(sash.frame),
                    divs: {
                        top: divsCount,
                        left: sash.nearMullions.left,
                        right: sash.nearMullions.right,
                        bottom: sash.nearMullions.bottom,
                    },
                    nearAlignments: {
                        top: -1,
                        left: sash.nearAlignments.left,
                        right: sash.nearAlignments.right,
                        bottom: sash.nearAlignments.bottom,
                    },
                    bondedGlazing: sash.bondedGlazing,
                });

                if (newSash.nearMullions.bottom === -1 && newSash.nearAlignments.bottom === -1) {
                    edgeSashes.bottom.push(newSash.id);
                }
                if (newSash.nearMullions.top === -1 && newSash.nearAlignments.top === -1) {
                    edgeSashes.top.push(newSash.id);
                }
                if (newSash.nearMullions.left === -1 && newSash.nearAlignments.left === -1) {
                    edgeSashes.left.push(newSash.id);
                }
                if (newSash.nearMullions.right === -1 && newSash.nearAlignments.right === -1) {
                    edgeSashes.right.push(newSash.id);
                }

                newDivider = new ActiveMullion(divsCount, {
                    frameId: sash.frameId,
                    rx: sash.rx,
                    ry: sash.ry + adjustRHeight,
                    rWidth: sash.rWidth,
                    rHeight: 1,
                    multiAlignTop: [sash],
                    multiAlignBottom: [newSash],
                    direction,
                });

                sash.rHeight = adjustRHeight;
                if (sash.nearMullions.bottom !== -1) {
                    const divBottom = core.fId(conf.Mullions, sash.nearMullions.bottom);
                    conf.Mullions[divBottom].multiAlignTop = conf.Mullions[
                        divBottom
                    ].multiAlignTop.filter(el => el.id !== sash.id);
                    conf.Mullions[divBottom].multiAlignTop.push(newSash);
                }
                if (sash.nearMullions.left !== -1) {
                    const divLeft = core.fId(conf.Mullions, sash.nearMullions.left);
                    conf.Mullions[divLeft].multiAlignRight.push(newSash);
                    conf.Mullions[divLeft].multiAlignRightDiv.push(newDivider);
                }
                if (sash.nearMullions.right !== -1) {
                    const divRight = core.fId(conf.Mullions, sash.nearMullions.right);
                    conf.Mullions[divRight].multiAlignLeft.push(newSash);
                    conf.Mullions[divRight].multiAlignLeftDiv.push(newDivider);
                }

                if (sash.nearAlignments.bottom !== -1) {
                    const alignment = conf.Alignments.find(
                        el => el.id === sash.nearAlignments.bottom
                    );
                    alignment.adjacentSashes.top = alignment.adjacentSashes.top
                        .filter(el => el !== sash.id)
                        .concat(newSash.id);
                }
                if (sash.nearAlignments.left !== -1) {
                    const alignment = conf.Alignments.find(
                        el => el.id === sash.nearAlignments.left
                    );
                    alignment.adjacentSashes.right.push(newSash.id);
                    alignment.perpendicularMullions.right.push(newDivider.id);
                }
                if (sash.nearAlignments.right !== -1) {
                    const alignment = conf.Alignments.find(
                        el => el.id === sash.nearAlignments.right
                    );
                    alignment.adjacentSashes.left.push(newSash.id);
                    alignment.perpendicularMullions.left.push(newDivider.id);
                }

                sash.nearMullions.bottom = newDivider.id;
                sash.nearAlignments.bottom = -1;
                if (sash.nearMullions.bottom !== -1) {
                    edgeSashes.bottom = edgeSashes.bottom.filter(s => s !== sash.id);
                }
            } else if (direction === 'vertical') {
                if (conf.drawData) {
                    const glazingBeadData = conf.drawData.glazingBead.find(
                        gb => gb.sashId === sash.id
                    );
                    const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);
                    const defaultProfileId = this.profilesService.getMullionProfileIdBetweenFields(
                        conf,
                        [sash],
                        [sash],
                        direction
                    );
                    const profile = this.profilesService.getProfile(defaultProfileId);
                    if (glazingBeadData && sashData && profile) {
                        minWidthPrevSash =
                            glazingBeadData.inner.rect.x
                            - sashData.outer.rect.x
                            + profile.width / 2
                            + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                            + this.minWidthGlass;
                        minWidthNextSash =
                            sashData.outer.rect.x
                            + sashData.outer.rect.width
                            - (glazingBeadData.inner.rect.x + glazingBeadData.inner.rect.width)
                            + profile.width / 2
                            + (glazingBeadData.inner.rect.x - glazingBeadData.outer.rect.x)
                            + this.minWidthGlass;
                        minWidthReal = minWidthPrevSash + minWidthNextSash;
                    }
                }

                if (sash.rWidth < minWidthReal) {
                    alert(this.translateService.instant('INTERFACE|Nie można podzielić skrzydła'));
                    return;
                }
                let adjustRWidth: number;
                let adjustRWidth2: number;
                adjustRWidth = adjustRWidth2 = Math.floor(sash.rWidth / 2);
                if (adjustRWidth !== sash.rWidth / 2) {
                    adjustRWidth2++;
                }
                if (minWidthNextSash && adjustRWidth2 < minWidthNextSash) {
                    adjustRWidth2 = minWidthNextSash;
                    adjustRWidth = sash.rWidth - adjustRWidth2;
                }
                if (minWidthPrevSash && adjustRWidth < minWidthPrevSash) {
                    adjustRWidth = minWidthPrevSash;
                    adjustRWidth2 = sash.rWidth - adjustRWidth;
                }
                newSash = new ActiveSash(count, {
                    rx: sash.rx + adjustRWidth,
                    ry: sash.ry,
                    frameId: sash.frameId,
                    rWidth: adjustRWidth2,
                    rHeight: sash.rHeight,
                    type: sash.type,
                    frame: core.copy(sash.frame),
                    divs: {
                        left: divsCount,
                        top: sash.nearMullions.top,
                        bottom: sash.nearMullions.bottom,
                        right: sash.nearMullions.right,
                    },
                    nearAlignments: {
                        left: -1,
                        top: sash.nearAlignments.top,
                        bottom: sash.nearAlignments.bottom,
                        right: sash.nearAlignments.right,
                    },
                    bondedGlazing: sash.bondedGlazing,
                });
                if (newSash.nearMullions.bottom === -1 && newSash.nearAlignments.bottom === -1) {
                    edgeSashes.bottom.push(newSash.id);
                }
                if (newSash.nearMullions.top === -1 && newSash.nearAlignments.top === -1) {
                    edgeSashes.top.push(newSash.id);
                }
                if (newSash.nearMullions.left === -1 && newSash.nearAlignments.left === -1) {
                    edgeSashes.left.push(newSash.id);
                }
                if (newSash.nearMullions.right === -1 && newSash.nearAlignments.right === -1) {
                    edgeSashes.right.push(newSash.id);
                }

                newDivider = new ActiveMullion(divsCount, {
                    frameId: sash.frameId,
                    rx: sash.rx + adjustRWidth,
                    ry: sash.ry,
                    rWidth: 1,
                    rHeight: sash.rHeight,
                    multiAlignLeft: [sash],
                    multiAlignRight: [newSash],
                    direction,
                });

                sash.rWidth = adjustRWidth;
                if (sash.nearMullions.right !== -1) {
                    const divRight = core.fId(conf.Mullions, sash.nearMullions.right);
                    conf.Mullions[divRight].multiAlignLeft = conf.Mullions[
                        divRight
                    ].multiAlignLeft.filter(el => el.id !== sash.id);
                    conf.Mullions[divRight].multiAlignLeft.push(newSash);
                }
                if (sash.nearMullions.top !== -1) {
                    const divTop = core.fId(conf.Mullions, sash.nearMullions.top);
                    conf.Mullions[divTop].multiAlignBottom.push(newSash);
                    conf.Mullions[divTop].multiAlignBottomDiv.push(newDivider);
                }
                if (sash.nearMullions.bottom !== -1) {
                    const divBottom = core.fId(conf.Mullions, sash.nearMullions.bottom);
                    conf.Mullions[divBottom].multiAlignTop.push(newSash);
                    conf.Mullions[divBottom].multiAlignTopDiv.push(newDivider);
                }

                if (sash.nearAlignments.right !== -1) {
                    const alignment = conf.Alignments.find(
                        el => el.id === sash.nearAlignments.right
                    );
                    alignment.adjacentSashes.left = alignment.adjacentSashes.left
                        .filter(el => el !== sash.id)
                        .concat(newSash.id);
                }
                if (sash.nearAlignments.top !== -1) {
                    const alignment = conf.Alignments.find(el => el.id === sash.nearAlignments.top);
                    alignment.adjacentSashes.bottom.push(newSash.id);
                    alignment.perpendicularMullions.bottom.push(newDivider.id);
                }
                if (sash.nearAlignments.bottom !== -1) {
                    const alignment = conf.Alignments.find(
                        el => el.id === sash.nearAlignments.bottom
                    );
                    alignment.adjacentSashes.top.push(newSash.id);
                    alignment.perpendicularMullions.top.push(newDivider.id);
                }

                sash.nearMullions.right = newDivider.id;
                sash.nearAlignments.right = -1;
                if (sash.nearMullions.right !== -1) {
                    edgeSashes.right = edgeSashes.right.filter(s => s !== sash.id);
                }
            }
            conf.Sashes.splice(sash.id + 100, 0, newSash);
            conf.Mullions.push(newDivider);
        }
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.shapeService.setShapes(conf);
        this.sashesLayoutService.setIndex(conf);

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }
}
