import { WindowFacade } from './../+state/window.facade';
import { StepComponent, SharedFacade, ICC_PAGE_DATA, _ } from '@icc/configurator/shared';
import { Component, OnInit, Inject } from '@angular/core';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-muntins-types-modal',
  template: require('./muntins-types-modal.component.html'),
  styles: [require('./muntins-types-modal.component.scss')]
})
export class MuntinsTypesModalComponent extends StepComponent implements OnInit {



  public configurator = 'window';
  public stepId = 'muntins_types';
  public title = _('WINDOW|Typy szprosów');

  public muntinsType$ = this.windowFacade.selectedMuntinType$;
  muntinTypes: iccListItem[] = [];

  constructor(
    private windowFacade: WindowFacade,
    private sharedFacade: SharedFacade,
    @Inject(ICC_PAGE_DATA) private pageData: {
      muntinTypes: any
    }
  ) { 
    super();
  }

  ngOnInit() {
    this.muntinTypes = this.pageData.muntinTypes.map(t => ({
      id: Number(t.id),
      title: t.name,
      imageUrl: '/files/windowbartype/' + t.img,
    }));
  }

  selectMuntinType(item: iccListItem) {
    const type = this.pageData.muntinTypes.find(t => Number(t.id) === item.id);
    this.sharedFacade.closePage(type);
  }

}
