import {
    GlazingSizes,
    Muntin,
    SashType,
    ShapeType,
    GlazingBeadProfile,
    FrameProfileV2,
} from '.';
import { MosquitoConfiguration } from '@icc/common/configurations/MosquitoConfiguration';
import { Accessory, Filling } from '@icc/common/types';
import { Common } from '@icc/common/Common';
import { MullionV2 } from './MullionV2';
import { AlignmentV2 } from './AlignmentV2';
import { SashFilling } from '@icc/common/configurations/parts/common/Filling';
import { HandleId } from './Handle';

/** Kwatera/pole */
export class SashV2 {
    /** Identyfikator */
    id: number;
    /** Indeks */
    index: number;
    /** Id nadrzędnej kwatery */
    parentId?: null | number = null;
    /** Id ramy */
    frameId?: null | number = null;
    /** Pozycja x [mm] */
    x: number;
    /** Pozycja y [mm] */
    y: number;
    /** Szerokość [mm] */
    width: number;
    /** Wysokość [mm] */
    height: number;
    /** Funkcja kwatery */
    type?: {
        /** Identyfikator typu skrzydła */
        id: SashType['id'],
        /** Pozycja klamki */
        handlePosition: SashType['handlePosition'],
        /** Czy jest skrzydłem biernym */
        passive?: boolean,
        /** Typ skrzydła */
        type?: string
    } | null;
    /** Szprosy w polu */
    muntins: Muntin[];
    /** Pola kwatery */
    intSashes?: SashV2[];
    /** Szprosy konstrukcyjne */
    intMullions?: MullionV2[];
    /** Wyrównania w polu */
    intAlignments?: AlignmentV2[];
    /** Sąsiadujące pola */
    intAdjacentSashes?: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Sąsiadujące słupki/poprzeczki/szprosy konstrukcyjne */
    nearMullions: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    /** Sąsiadujące wyrównania */
    nearAlignments: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    /** Wypełnienie pola */
    filling: SashFilling | null;
    /** Listwa przyszybowa w polu */
    glazingBead: GlazingBeadProfile | null;
    /** Wklejany pakie szybowy */
    bondedGlazing: boolean;
    /** Szklenie panelu dekoracyjnego */
    panelGlazing: SashFilling | null = null;
    /** Wypełnienie pola */
    panelInner: SashFilling | null = null;
    /** Rodzaj panelu */
    panelType?: 'Inset' | 'Outer' | 'Double' | 'Inner' | 'Mixed';
    /** Wymiary wypełnienia */
    fillingSizes: GlazingSizes;
    /** Czy jedno wypełnienie w całej kwaterze */
    oneGlazing?: boolean;
    /** Czy jedna listwa przyszybowa w całej kwaterze */
    oneGlazingBead?: boolean | number;
    mosquito?: MosquitoConfiguration | null;
    shape: ShapeType;
    /** Profile ramy skrzydła */
    frame?: {
        top: FrameProfileV2 | null;
        bottom: FrameProfileV2 | null;
        left: FrameProfileV2 | null;
        right: FrameProfileV2 | null;
    };
    /** Klamka wewnętrzna */
    handleInner?: HandleId | null;
    /** Klamka zewnętrzna */
    handleOuter?: HandleId | null;
    /** Wysokość klamki [mm] */
    handleHeight?: number;
    /** Czy wysokość klamki ustawiona domyślnie. */
    defaultHandleHeight?: boolean;
    /** Rodzaj wysokości klamki */
    handleHeightType?: 'standard' | 'nonstandard' | 'middle';
    /** Dodatko do kwatery/pola (m.in. dodatki okuciowe) */
    hardware: Accessory[] = [];
    /** Masa [kg] */
    weight: number = 0;

    constructor(desc: {
        id: number;
        index: number;
        parentId?: number;
        frameId?: number;
        x: number;
        y: number;
        width: number;
        height: number;
        type?: SashType;
        nearMullions: {
            left: number;
            right: number;
            top: number;
            bottom: number;
        };
        nearAlignments: {
            left: number;
            right: number;
            top: number;
            bottom: number;
        };
    }) {
        this.id = desc.id;
        this.index = desc.index;
        this.parentId = Common.isDefined(desc.parentId) ? desc.parentId : null;
        this.frameId = Common.isDefined(desc.frameId) ? desc.frameId : null;
        this.x = desc.x;
        this.y = desc.y;
        this.width = desc.width;
        this.height = desc.height;
        this.muntins = [];
        this.intSashes = [];
        this.intMullions = [];
        this.intAlignments = [];
        this.intAdjacentSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.nearMullions = {
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
        };
        this.nearAlignments = {
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
        };
        ['left', 'right', 'top', 'bottom'].forEach((side: keyof SashV2['nearMullions']) => {
            if (Common.isDefined(desc.nearMullions[side])) {
                this.nearMullions[side] = desc.nearMullions[side];
            }
            if (Common.isDefined(desc.nearAlignments[side])) {
                this.nearAlignments[side] = desc.nearAlignments[side];
            }
        });
        this.filling = null;
        this.glazingBead = null;
        this.fillingSizes = {
            width: 0,
            height: 0,
            area: 0,
            x: 0,
            y: 0,
        };
        this.oneGlazing = true;
        this.oneGlazingBead = true;
        this.shape = 'rect';
        this.bondedGlazing = false;

        if (Common.isUndefined(desc.parentId)) {
            this.panelGlazing = null;
            this.panelType = 'Inset';
            this.type = desc.type && {
                id: desc.type.id,
                handlePosition: desc.type.handlePosition
            } || null;
            this.mosquito = null;
            this.frame = {
                top: null,
                bottom: null,
                left: null,
                right: null,
            };
            this.handleInner = null;
            this.handleOuter = null;
            this.handleHeight = desc.height / 2;
            this.defaultHandleHeight = true;
            this.handleHeightType = 'standard';
            this.hardware = [];
            this.weight = 0;
        }
    }
}
