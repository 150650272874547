/**
 * Wyjątek liczenia ceny.
 * @param {string} message Opis błędu
 * @param {string} element Element
 */
export class PriceException extends Error {
    element;
    constructor(message, element) {
        super();
        this.name    = 'PriceException';
        this.message = message;
        this.element = element;
    }
}
