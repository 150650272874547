import { ActiveSash } from './active-sash';
import { Reinforcement } from '@icc/window';

/**
 * Słupek/poprzeczka
 */
export class ActiveMullion {
    /**
     * Id słupka/poprzeczki
     */
    id: number;

    frameId: number;
    /**
     * Typ słupka/poprzeczki
     */
    type: string;
    /**
     * Wsp. x słupka/poprzeczki w mm
     * @type {number}
     */
    rx: number;
    /**
     * Wsp. y słupka/poprzeczki w mm
     * @type {number}
     */
    ry: number;
    /**
     * Szerokość słupka/poprzeczki w mm
     * @type {number}
     */
    rWidth: number;
    /**
     * Wysokość słupka/poprzeczki w mm
     * @type {number}
     */
    rHeight: number;
    /**
     * Skrzydła po lewej słupka
     * @type {Sash[]}
     */
    multiAlignLeft: ActiveSash[];
    /**
     * Skrzydła po prawej słupka
     * @type {Sash[]}
     */
    multiAlignRight: ActiveSash[];
    /**
     * Skrzydła u góry poprzeczki
     * @type {Sash[]}
     */
    multiAlignTop: ActiveSash[];
    /**
     * Skrzydła u dołu poprzeczki
     * @type {Sash[]}
     */
    multiAlignBottom: ActiveSash[];
    /**
     * Poprzeczki od lewej strony słupka
     * @type {Divider[]}
     */
    multiAlignLeftDiv: Partial<ActiveMullion>[];
    /**
     * Poprzeczki od prawej strony słupka
     * @type {Divider[]}
     */
    multiAlignRightDiv: Partial<ActiveMullion>[];
    /**
     * Słupki od góry poprzeczki
     * @type {Divider[]}
     */
    multiAlignTopDiv: Partial<ActiveMullion>[];
    /**
     * Słupki u dołu poprzeczki
     * @type {Divider[]}
     */
    multiAlignBottomDiv: Partial<ActiveMullion>[];
    perpendicularAlignments: { top: number[]; bottom: number[]; left: number[]; right: number[] };
    parallelAlignments: { top: number[]; bottom: number[]; left: number[]; right: number[] };
    /**
     * Czy jest to słupek (vertical) czy poprzeczka (horizontal).
     * @type {string}
     */
    direction: 'vertical' | 'horizontal';
    /**
     * Id kwatery w której jest słupek/poprzeczka
     * @type {number|null}
     */
    parentSash?: number | null;
    /**
     * Czy slupek/poprzeczka jest aktywny do przesuwania
     * @type {number}
     */
    active: number;
    /**
     * Opis kształtu słupka
     * @type {Object}
     */
    figure: {};
    valid: boolean;
    cutAngle: number;
    isDefault: boolean;
    profileId: number;
    reinforcement: Reinforcement | null;

    /**
     * Tworzy słupek/poprzeczkę
     *
     * @param {number} id   Id
     * @param {number} param Dane słupka/poprzeczki
     * @param {number} param.rx Wsp. x słupka/poprzeczki w mm
     * @param {number} param.ry Wsp. y słupka/poprzeczki w mm
     * @param {number} param.rWidth Szerokość słupka/poprzeczki w mm
     * @param {number} param.rHeight Wysokość słupka/poprzeczki w mm
     * @param {Sash[]} [param.multiAlignLeft=[]] Skrzydła po lewej słupka
     * @param {Sash[]} [param.multiAlignRight=[]] Skrzydła po prawej słupka
     * @param {Sash[]} [param.multiAlignTop=[]] Skrzydła u góry poprzeczki
     * @param {Sash[]} [param.multiAlignBottom=[]] Skrzydła u dołu poprzeczki
     * @param {Divider[]} [param.multiAlignLeftDiv=[]] Poprzeczki od lewej strony słupka
     * @param {Divider[]} [param.multiAlignRightDiv=[]] Poprzeczki od prawej strony słupka
     * @param {Divider[]} [param.multiAlignTopDiv=[]] Słupki od góry poprzeczki
     * @param {Divider[]} [param.multiAlignBottomDiv=[]] Słupki u dołu poprzeczki
     * @param {string} [param.direction=horizontal] Czy jest to słupek czy poprzeczka
     * @param {number|null} [param.parentSash=null] Id kwatery w której jest słupek/poprzeczka
     *
     * @memberOf Divider
     */
    constructor(id: number, param: any) {
        this.id = id || 0;

        this.frameId = typeof param.frameId === 'undefined' ? null : param.frameId;

        this.type = param.type || 'no_mullion';

        this.rx = param.rx || 0;

        this.ry = param.ry || 0;

        this.rWidth = param.rWidth || 0;

        this.rHeight = param.rHeight || 0;

        this.multiAlignLeft = param.multiAlignLeft || [];

        this.multiAlignRight = param.multiAlignRight || [];

        this.multiAlignTop = param.multiAlignTop || [];

        this.multiAlignBottom = param.multiAlignBottom || [];

        this.multiAlignLeftDiv = param.multiAlignLeftDiv || [];

        this.multiAlignRightDiv = param.multiAlignRightDiv || [];

        this.multiAlignTopDiv = param.multiAlignTopDiv || [];

        this.multiAlignBottomDiv = param.multiAlignBottomDiv || [];
        this.perpendicularAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.parallelAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };

        this.direction = param.direction || 'vertical';

        this.parentSash = typeof param.parentSash === 'undefined' ? null : param.parentSash;

        this.active = 1;

        this.figure = {};
        this.valid = false;
        this.cutAngle = 90;
        this.isDefault = true;
        this.profileId = param.profileId || null;
        this.reinforcement = param.reinforcement || null;
    }
}
