import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { Sill, Cassonetto, SeparateFilling, SeparateProfile } from './parts/complementary_goods';
import { Accessory } from './parts/common';

import { ComplementaryGoodsActiveConfiguration } from './ComplementaryGoodsActiveConfiguration';
import { core } from '../helpers';

export class ComplementaryGoodsConfiguration implements Configuration {
    $version = 5;
    type: 'complementary_goods' = 'complementary_goods';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    windowSills: Sill[] = [];
    cassonettos: Cassonetto[] = [];
    fillings: SeparateFilling[] = [];
    accessories: Accessory[] = [];
    profiles: SeparateProfile[] = [];

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (ComplementaryGoodsConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (ComplementaryGoodsActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.attributes = configuration.attributes;

                this.windowSills = configuration.ComplementaryGoods.windowsill.map<Sill>(
                    this.mapSill.bind(this)
                );
                this.cassonettos = configuration.ComplementaryGoods.cassonetto.map<Cassonetto>(
                    this.mapCassonetto.bind(this)
                );
                this.fillings = configuration.ComplementaryGoods.glass.map<SeparateFilling>(
                    this.mapFilling.bind(this)
                );
                this.accessories = configuration.ComplementaryGoods.accessory.map<Accessory>(
                    accessory => new Accessory(accessory)
                );
                this.profiles = configuration.ComplementaryGoods.profile.map<SeparateProfile>(
                    this.mapProfile.bind(this)
                );
            }
        }
    }

    public static is(configuration): configuration is ComplementaryGoodsConfiguration {
        return configuration instanceof ComplementaryGoodsConfiguration || configuration.$version;
    }

    private mapSill(sill): Sill {
        const newSill: Sill = {
            id: Number(sill.accessory.id),
            name: sill.accessory.name,
            type: sill.accessory.type,
            count: Number(sill.count),
            width: Number(sill.accessory.currentWidth),
            length: Number(sill.accessory.currentLength),
            weight: Number(sill.weight),
            comment: sill.comment,
            addons: sill.accessory.addons_data.map(addon => addon.name),
            price: sill.accessory.price,
            image: sill.accessory.image ? '/files/windowsill/' + sill.accessory.image : null,
        };
        if (typeof sill.accessory.plugs !== 'undefined') {
            newSill.plugs = !!~~sill.accessory.plugs;
        }
        newSill.color = {
            id: Number(sill.accessory.selectedColor),
            name:
                sill.accessory.colors_pricing
                && sill.accessory.colors_pricing[sill.accessory.selectedColor]
                    ? sill.accessory.colors_pricing[sill.accessory.selectedColor].name
                    : sill.colorName,
            isDefault: false,
        };
        return newSill;
    }

    private mapCassonetto(cassonetto): Cassonetto {
        const newCassonetto: Cassonetto = {
            id: Number(cassonetto.accessory.id),
            name: cassonetto.accessory.name,
            count: Number(cassonetto.count),
            width: Number(cassonetto.accessory.currentWidth),
            height: Number(cassonetto.accessory.currentHeight),
            weight: Number(cassonetto.weight),
            image: cassonetto.accessory.image
                ? '/files/cassonetto/' + cassonetto.accessory.image
                : null,
        };
        if (typeof cassonetto.accessory.currentP1 !== 'undefined') {
            newCassonetto.p1 = Number(cassonetto.accessory.currentP1);
        }
        if (typeof cassonetto.accessory.currentP2 !== 'undefined') {
            newCassonetto.p2 = Number(cassonetto.accessory.currentP2);
        }
        if (typeof cassonetto.accessory.currentL1 !== 'undefined') {
            newCassonetto.l1 = Number(cassonetto.accessory.currentL1);
        }
        newCassonetto.color = {
            id: Number(cassonetto.accessory.selectedColor),
            name: cassonetto.accessory.colors.find(
                color => cassonetto.accessory.selectedColor === color.id
            ).name,
            isDefault: false,
        };
        return newCassonetto;
    }

    private mapFilling(filling): SeparateFilling {
        const newFilling: SeparateFilling = {
            filling: {
                id: filling.accessory.id,
                custom: false,
                name: filling.accessory.name,
                producer: filling.accessory.producer,
                type: filling.accessory.type,
                weight: Number(filling.weight),
                availableSecondColor: filling.accessory.available_second_color,
                comment: filling.accessory.comment,
            },
            glazingSpacer: {
                id: Number(filling.accessory.warmEdge),
                name: filling.accessory.warmEdgeName,
            },
            width: Number(filling.accessory.currentWidth),
            height: Number(filling.accessory.currentHeight),
            count: Number(filling.count),
            price: filling.accessory.price ? Number(filling.accessory.price) : null,
            image: filling.accessory.image ? '/files/filling/' + filling.accessory.image : null,
            comment: filling.accessory.comment ? filling.accessory.comment : null,
        };
        return newFilling;
    }

    private mapProfile(profile): SeparateProfile {
        const profileData = core.copy(profile.accessory);
        delete profileData.colorOptions;
        delete profileData.currentLength;
        delete profileData.price;
        delete profileData.priceUnit;
        delete profileData.selectedColor;
        delete profileData.selectedWood;
        const newProfile: SeparateProfile = {
            profile: profileData,
            count: Number(profile.count),
            length: Number(profile.accessory.currentLength),
            comment: profile.comment,
            price: profile.accessory.price,
            image: profile.accessory.img ? '/files/profile/' + profile.accessory.img : null,
            color: {
                profile: {
                    outer: null,
                    inner: null,
                    core: null,
                },
            },
            wood: null,
        };

        newProfile.color = {
            profile: {
                outer: null,
                inner: null,
                core: null,
            },
        };
        ['outer', 'inner', 'core'].forEach(side => {
            if (profile.accessory.selectedColor.frame[side].id) {
                newProfile.color.profile[side] = {
                    id: Number(profile.accessory.selectedColor.frame[side].id),
                    name: profile.accessory.selectedColor.frame[side].name,
                    code: profile.accessory.selectedColor.frame[side].code,
                    RAL: Boolean(profile.accessory.selectedColor.frame[side].RAL),
                    isDefault: profile.accessory.selectedColor.frame[side].isDefault,
                };
            }
        });
        if (
            profile.accessory.material === 'wood'
            && profile.accessory.selectedWood
            && profile.accessory.selectedWood.id
        ) {
            newProfile.wood = {
                id: Number(profile.accessory.selectedWood.id),
                name: profile.accessory.selectedWood.name,
            };
        }
        return newProfile;
    }
}
