import angular from 'angular';
import format from 'date-fns/format';
import { OfferKeyService } from '@icc/common/offers/OfferKeyService';
import { OfferSequenceService } from '@icc/common/offers/OfferSequenceService';
import { OfferTransportCostService } from '@icc/common/offers/OfferTransportCostService';
import { OfferDiscountsService } from '@icc/common/offers/OfferDiscountsService';

// eslint-disable-next-line require-jsdoc
export default function OffersFactory($filter, $injector, $q, $http, Core, $rootScope,
    UserService, DatabaseManager, $location, IccConfig, StateService, SynchronizeService, OffersService, ConfiguratorsDataService,
    DiscountsAndMultipliersService, isNative, OnlineStatusService, EnvConfig, ManyPositionsService
) {
    'ngInject';

    var user = UserService.get();

    var factory = {
        add,
        remove,
        last,
        list,
        exists,
        getSubOffers,
        movePosition,
        changeKey,
        oneClientOffer,
        clientOffer,
        quickOffer,
        addSubOffer,
        elemInOrderNumber,
        elemInOfferNumber,
    };

    return factory;

    /**
     * Konstruktor Oferty
     * @param {object} param          dane oferty
     * @param {object} client         dane klienta
     * @param {object} prices         ceny
     * @param {array}  offerDiscounts Rabaty
     */
    function Offer(param, client, prices, offerDiscounts) {
        user = UserService.get();
        const dealer = UserService.getDealer();
        const dealerh = UserService.getDealerSeller();

        if (angular.isUndefined(client) || client == null) {
            client = {};
        }

        this.id                    = null;
        this.tmp_id                = 'tmp_offer_' + Core.generateUUID();
        this.name                  = param.name || ((user.name || '') + ' ' + (user.surname || ''));
        this.client_id             = client.tmp_id || null;
        if (parseInt(client.company) === 1) {
            this.companyname       = client.name || null;
        } else {
            this.clientname        = client.name || null;
            this.clientsurname     = client.surname || null;
        }

        this.lang                  = param.lang || $rootScope.curLang;
        this.deleted               = 0;
        this.dealer_id             = param.dealer_id || user.dealerid || null;
        this.dealer_seller_id      = param.dealer_seller_id || user.dealersellerid || null;
        this.seller_id             = param.seller_id || user.sellerid || null;
        this.seller_offer          = param.seller_offer || null;
        this.client_offer          = param.client_offer || 0;
        this.shop_order            = param.shop_order || 0;
        this.reference             = param.reference || null;
        this.model                 = 'Offer';
        this.custom_title          = param.custom_title || '';
        this.description           = param.description || '';
        this.offer_name            = param.offer_name || '';
        this.delivery_address      = param.delivery_address || '';
        this.dealer_advance        = param.dealer_advance || '';
        this.assigned              = user.id || null;
        this.number_items          = param.number_items || 0;
        this.status                = param.status ? String(param.status) : '1';
         this.dealer_status         = param.dealer_status !== null && angular.isDefined(param.dealer_status) ? String(param.dealer_status) : '1';
        this.order                 = param.order || 0;
        this.offer                 = param.offer || 0;
        this.synced                = true;
        this.currency              = param.currency && typeof param.currency === 'object'
                                   ? Core.stringJson(param.currency)
                                   : Core.stringJson(prices.currencies[prices.markets[user.market || 'P'].currency]);
        // this.tax_rate_id           = (user.access == 'klient' || !user.access) ? prices.markets[user.market || 'P'].tax_rate_id : 0;
        this.tax_rate_id           = ((user.access == 'klient' || !user.access) && (!IccConfig.Offer.demoOffer || !param.demo_target))
                                   ? (angular.isArray(prices.taxRates) && angular.isDefined(prices.taxRates[0]) ? prices.taxRates[0].id : 0)
                                   : param.tax_rate_id;
        this.market_id             = param.market_id || prices.markets[user.market || 'P'].id || null;
        this.wrong_market_data     = param.wrong_market_data || false;
        this.market_factor         = param.market_factor || prices.markets[user.market || 'P'].factor || 1;
        this.created               = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.original_offer_date   = param.original_offer_date || null;
        this.modified              = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.copied_offer          = param.copied_offer || null;
        this.circuit               = param.circuit || 0;
        this.weight                = param.weight || 0;
        this.weight_positions_quantity = param.weight_positions_quantity || 0;
        this.quantity              = param.quantity || 0;
        this.sequence              = param.sequence && typeof param.sequence === 'object'
                                   ? Core.stringJson(param.sequence)
                                   : (param.sequence ? param.sequence : '[]');
        this.summary               = param.summary && typeof param.summary === 'object'
                                   ? Core.stringJson(param.summary)
                                   : (param.summary ? param.summary : '{}');
        this.positions_groups_data = param.positions_groups_data && typeof param.positions_groups_data === 'object'
                                   ? (param.positions_groups_data)
                                   : Core.parseJson(param.positions_groups_data ? param.positions_groups_data : '{}');
        this.parent_id             = param.parent_id || null;
        this.legal_notices         = param.legal_notices || 0;
        this.hide_offer_details    = param.hide_offer_details||0;
        this.no_price              = (user.access == 'klient' || !user.access) && IccConfig.Settings.noPrice;

        this.positions_groups_data = param.positions_groups_data && typeof param.positions_groups_data === 'object'
                            ? (param.positions_groups_data)
                            : Core.parseJson(param.positions_groups_data ? param.positions_groups_data : '{}');

        this.positions_tax_rates  = param.positions_tax_rates && typeof param.positions_tax_rates === 'object'
                            ? (param.positions_tax_rates)
                            : Core.parseJson(param.positions_tax_rates ? param.positions_tax_rates : '{}');


        this.dimension_unit        = param.dimension_unit || null;
        this.version               = param.version || 0;
        this.number                = param.number || 0;
        this.enova                 = param.enova || '0000';
        this.b2c_offer_source      = param.b2c_offer_source || 0;
        this.custom_key_value      = param.custom_key_value || null;
        const key = OfferKeyService.keyGen({
            order            : this.order,
            user,
            client,
            dealerId         : this.dealer_id,
            number           : param.ord_number,
            version          : this.version,
            enova            : this.enova,
            offerNumber      : param.offers_number,
            offerVersion     : param.offers_version,
            subOffer         : param.subOffer,
            originalOfferDate: this.original_offer_date,
            b2cOfferScheme   : IccConfig.Offer.B2C.differentOfferSchema && this.client_offer == 1,
            def              : {
                O_ALL   : param.offers_key,
                K_DEALER: param.dealer_code,
                K_CLIENT: param.client_code,
            },
            b2cOfferSource: this.b2c_offer_source,
            offlineNumber : isNative && !OnlineStatusService.getStatus(),
            customKeyValue: this.custom_key_value,
        });
        if (!this.number && isNative && !OnlineStatusService.getStatus()) {
            this.number = key.num;
        }
        this.key                   = param.key && typeof param.key === 'object' && !param.version
                                   ? Core.stringJson(param.key)
                                   : Core.stringJson(key.key);

        this.base_discounts        = Core.stringJson(OfferDiscountsService.groupDiscounts(Core.copy(param.group_discounts) || offerDiscounts || [], param.dealer_price_before_discount || 0, param.client_price_before_discount || 0, user, this).discounts);
        this.group_discounts       = this.base_discounts;
        let multipliers = DiscountsAndMultipliersService.getMultipliers();
        multipliers = multipliers[this.dealerId] || multipliers;
        this.multipliers           = param.multipliers || multipliers;

        this.changed_positions     = '{}';
        this.mail_message          = param.mail_message;
        this.history               = param.history && param.history.length > 0 ? param.history : [{
                                        date  : this.created,
                                        user  : user.name + ' ' + user.surname,
                                        note  : '',
                                        changes: [{
                                            type: 'status',
                                            oldStatus: null,
                                            status: this.status,
                                        }]
                                    }];


        this.montages              = param.montages && typeof param.montages === 'object'
                                   ? Core.stringJson(param.montages)
                                   : (param.montages ? param.montages : '{"montage" : false}');
        this.measure_price          = param.measure_price || 0;



        /* suma cen sprzedaży dla klienta */
        this.client_montages_price_before_discount = param.client_montages_price_before_discount || 0;
        this.client_price_before_discount_position = param.client_price_before_discount_position || 0;
        this.client_price_before_discount          = param.client_price_before_discount || 0;
        this.client_price                          = param.client_price || 0;
        this.client_montages_price_before_discount = param.client_montages_price_before_discount || 0;

        /* suma cen sprzedaży dla dealera */
        this.dealer_client_price_before_discount_position = param.dealer_client_price_before_discount_position || 0;
        this.dealer_client_price_before_discount          = param.dealer_client_price_before_discount || 0;
        this.dealer_client_price                          = param.dealer_client_price || 0;

        /* suma cen zakupu dla dealera */
        this.dealer_producer_montages_price_before_discount = param.dealer_producer_montages_price_before_discount || 0;
        this.dealer_montages_price_before_discount = param.dealer_montages_price_before_discount || 0;
        this.dealer_price_before_discount_position = param.dealer_price_before_discount_position || 0;
        this.dealer_price_before_discount          = param.dealer_price_before_discount || 0;
        this.dealer_price                          = param.dealer_price || 0;
        this.dealer_montages_price_before_discount = param.dealer_montages_price_before_discount || 0;
        this.dealer_producer_montages_price_before_discount = param.dealer_producer_montages_price_before_discount || 0;

        this.consulted                             = param.consulted || false;
        this.time_limit                            = param.time_limit;
        this.transport_cost                        = param.transport_cost || 0;
        this.transport_from_producent              = param.transport_from_producent || false;
        this.transport_from_producent_to_client    = param.transport_from_producent_to_client || false;
        this.split_transport_cost                  = param.split_transport_cost || false;
        this.client_transport_cost                 = param.client_transport_cost || 0;
        this.client_split_transport_cost           = param.client_split_transport_cost || false;
        this.transport_cost_type_id                = param.transport_cost_type_id || null;
        this.transport_cost_type                   = param.transport_cost_type || null;
        this.unmatched_oversize_rules              = param.unmatched_oversize_rules || null;
        this.transport_costs                       = param.transport_costs || null;
        this.chance_id = param.chance_id || null;

        this.dealer_currency                       = param.dealer_currency || null;
        this.dealer_currency_exchange_rate         = param.dealer_currency_exchange_rate || null;

        this.country_id = param.country_id || null;
        this.postal_code = param.postal_code || null;

        OfferDiscountsService.setDefaultDiscounts(this, param, user, dealer, dealerh);

    }

    /**
     * Dodawanie nowej oferty
     * @param {object}   param    Dane oferty
     * @param {object}   client   klient
     * @param {Function} callback funkcja po dodaniu
     * @return {object}           Obietnica
     */
    function add(param, client, callback) {
        var deferred = $q.defer();
        var pricesData;
        ConfiguratorsDataService.dataAsync().then(data => {
            pricesData = data;
            return DiscountsAndMultipliersService.getOfferDiscountAsync();
        }).then(offerDiscounts => {
            var newOffer = new Offer(param, client, pricesData, offerDiscounts);

            DatabaseManager.get('Offer').create(newOffer).then(() => {
                OffersService.emitModifiedOffer();
                deferred.resolve(newOffer.tmp_id);
                if (typeof callback === 'function') {
                    callback(newOffer.tmp_id);
                }
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    }

    /**
     * Usuwanie oferty
     * @param  {string} _id    Id oferty
     * @param  {object} object Objekt oferty
     * @return {object}        Obietnica
     */
    function remove(_id, object) {
        return new Promise((resolve, reject) => {
            object = angular.extend(object, {currency: Core.stringJson(object.currency)});
            DatabaseManager.get('Offer').remove(object).then(() => {
                OffersService.emitModifiedOffer();
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Lista ostatnich ofert
     * @param  {number} limit  Liczba rekordów do zwrócenia
     * @return {object}        Obietnica
     */
    function last(limit) {
        var deferred = $q.defer();
        DatabaseManager.get('Offer').getAll('list', {keys: ['0']}).then(res => {
            deferred.resolve(res
                .filter(e => angular.isDefined(e.doc.tmp_id) && ~~e.doc.order === 0)
                .sort((a, b) => new Date(b.doc.modified) - new Date(a.doc.modified))
                .slice(0, limit)
            );
        });
        return deferred.promise;
    }

    /**
     * Lista ofert
     * @param  {string} keys   Statusy ofert
     * @return {object}        Obietnica z lista ofert
     */
    function list(keys) {
        var deferred = $q.defer();
        DatabaseManager.get('Offer').getMany(keys).then(res => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Sprawdzenie konkretnej oferty
     * @param  {string} _id          Id oferty
     * @return {object}              Obietnica z ofertą
     */
    function exists(_id) {
        var deferred = $q.defer();
        DatabaseManager.get('Offer').get(_id).then(function getSuccess() {
            deferred.resolve(true);
        })
        .catch(function getError() {
            if (OnlineStatusService.getStatus()) {
                SynchronizeService.synchronizeOffers(true, 'down',  [_id]).then(data => {
                    if (data.data.offer) {
                        deferred.resolve(true);
                    } else {
                        deferred.resolve(false);
                    }
                })
                .catch(() => {
                    deferred.resolve(false);
                });
            } else {
                deferred.resolve(false);
            }
        });
        return deferred.promise;
    }

    /**
     * Lista podofert
     * @param  {string}  _id    Id oferty
     * @param  {boolean} online Czy zawsze pobieać online
     * @return {object}         Obietnica
     */
    function getSubOffers(_id, online = false) {
        var deferred = $q.defer();

        if (online) {
            $http.get((EnvConfig.remoteHost || window.location.origin) + '/dealer/sellers/history/' + _id + '.json').then(res => {
                deferred.resolve(
                    (res.data.orders || []).filter((e) => e.parent_id).map(e => {
                        e.doc = {order: 1};
                        return e;
                    })
                );
            }).catch(err => {
                deferred.reject(err);
            });
        } else {
            DatabaseManager.get('Offer').getAll('by_dealer_offer_id', {key: _id}).then(res => {
                deferred.resolve(res);
            }, err => {
                deferred.reject(err);
            });
        }

        return deferred.promise;
    }

    /**
     * Aktualizacja klucza oferty
     * @param  {string} scheme schemat klucza oferty
     * @param  {object} key    wartosc klucza oferty
     * @param  {object} values nowe wartosci danych kluczy
     * @return {array}         nowy klucz oferty
     */
    function changeKey(scheme, key, values) {
        key = Core.parseJson(key);
        var elems = OfferKeyService.parseScheme(scheme);
        var i, j;

        for (i = 0; i < elems.length; i++) {
            for (j in values) {
                if (elems[i].type != 'STRING' && elems[i].type == j) {
                    key[i] = values[j];
                }
            }
        }

        return key;
    }

    /**
     * Przesuwanie pozycji w tablicy offer.sequence
     * @param  {object}   offer        Oferta
     * @param  {object}   positionsIds Lista pozycji bez pozycji z konstrukcji złożonej
     * @param  {string}   direction    Kierunek otwierania, up lub down
     * @param  {number}   positionId   Id pozycji
     * @param  {string}   groupCode    identyfikator grupy
     * @param  {number}   groupKey     index grupy w sequence
     * @param  {Function} callback     funkcja po zmianie
     */
    function movePosition(offer, positionsIds, direction, positionId, groupCode, groupKey, callback) {
        const sequence   = angular.copy(offer.sequence);
        const index = positionsIds.findIndex(el => el === positionId);
        const coupledPositions = sequence[groupKey][groupCode].filter(el => !positionsIds.includes(el));
        let newPos;


        if (direction === 'up') {

            newPos = index - 1;

            if (index === -1 || newPos < 0) {
                return false;
            }

            positionsIds.splice(index, 1);
            positionsIds.splice(newPos, 0, positionId);

        } else if (direction === 'down') {

            newPos = index + 1;

            if (index === -1 || newPos >= positionsIds.length) {
                return false;
            }

            positionsIds.splice(index, 1);
            positionsIds.splice(newPos, 0, positionId);

        }
        sequence[groupKey][groupCode] = [...positionsIds, ...coupledPositions];

        OffersService.update(offer._id, angular.extend(offer, {sequence})).then(() => callback());
    }

    /**
     * Dodawanie oferty na szybko w panelu producenta
     */
    function quickOffer() {
        add({seller_offer: 1}, {}).then(id => {
            StateService.setState({
                offer_id: id,
                offers  : null,
                client  : null,
                key     : null,
                offer   : {id}
            });
            $location.url('/app/offers_view/' + id);
        });
    }

    /**
     * Dodawanie oferty w panelu klienta indywidualnego
     */
    function clientOffer() {
        var client = UserService.getClient() || {};
        add({
            status       : 99, // W trakcie tworzenia
            client_offer : 1,
            dealer_status: 2, // U kleinta
            dealer_id    : angular.isDefined(client.dealer_id) ? client.dealer_id : null
        }, client || {}).then(id => {
            StateService.setState({
                offer_id: id,
                offers  : null,
                client  : null,
                key     : null,
                offer   : {id}
            });
            $location.url('/app/offers_view/' + id);
        });
    }

    /**
     * Jeżeli klient jest niezalogowany i nie ma utworzonej oferty
     * system ją automatycznie utwory i przejdzie do niej
     */
    function oneClientOffer() {
        user = UserService.get();
        if (!user.access) {
            if (!StateService.state.offer_id || StateService.state.offers && StateService.state.offers[0] && StateService.state.offers[0].doc.status != 99) {
                clientOffer();
            } else {
                $location.url('/app/offers_view/' + StateService.state.offer_id);
            }
        }
    }

    /**
     * Dodawanie podoferty
     * @param {string}  offerId      Id oferty
     * @param {string} offerType      Rodzaj dokumentu
     * @param {string}  producerName Nazwa producenta
     * @param {boolean} demoOffer    Czy oferta demo
     */
    function addSubOffer(offerId, offerType, producerName, demoOffer, positions) {
        var ClientsFactory             = $injector.get('ClientsFactory');
        var PositionsFactory           = $injector.get('PositionsFactory');
        var OfferAttachmentsFactory    = $injector.get('OfferAttachmentsFactory');
        var PositionAttachmentsFactory = $injector.get('PositionAttachmentsFactory');
        var sequence                   = {};
        var newVersion                 = 1;
        var original, order, relations;
        offerType = offerType || 'offer';

        const EditPosition = DatabaseManager.get('Position');

        $rootScope.loader = true;
        OffersService.pauseEmitModifiedOffer();
        OffersService.get(offerId).then(originalOffer => {
            // oferta źródłowa wszystkich podofert drzewa dokumentów do numeru wersji
            original = originalOffer;

            if (offerType !== 'offer') {
                $rootScope.showInfo($filter('translate')('OFFER|Tworzenie podzamówienia...'), null);
            } else {
                $rootScope.showInfo($filter('translate')('OFFER|Tworzenie podoferty...'), null);
            }
            return getSubOffers(original.parent_id || original.tmp_id, offerType === 'order');

        }).then(subOffersList => {
            // klientcie do oferty

            sequence   = angular.copy(original.sequence);
            const subOffersCount = subOffersList.filter(e => {
                return offerType === 'shop_order' ? ~~e.doc.shop_order == 1 : ~~e.doc.order == ~~(offerType === 'order' ? 1 : 0);
            }).length;
            const maxVersion = subOffersList.filter(e => {
                return offerType === 'shop_order' ? ~~e.doc.shop_order == 1 : ~~e.doc.order == ~~(offerType === 'order' ? 1 : 0);
            })
            .map(offer => {
                return Number(offer.doc.version);
            })
            .reduce((prev, cur) => cur > prev ? cur : prev, 0);

            newVersion = Math.max(subOffersCount, maxVersion) + 1;

            return ClientsFactory.get(original.client_id);

        }).then(client => {
            // dodawanie zamóienia

            const dealerCode = $rootScope.user.access == 'producent'
                ? (~~original.order ? elemInOrderNumber(original.key, 'K_DEALER') : elemInOfferNumber(original.key, 'K_DEALER'))
                : undefined;

            if (!client) {
                client = {
                    tmp_id      : original.client_id,
                    name        : original.companyname || original.clientname,
                    surname     : original.clientsurname,
                    company     : angular.isDefined(original.companyname) ? 1 : 0,
                    reference   : elemInOfferNumber(original.key, 'K_CLIENT') || original.reference,
                };
            }

            return add(angular.extend(original, {
                parent_id          : original.parent_id || original.tmp_id,
                copied_offer       : original.tmp_id,
                version            : newVersion,
                sequence           : null,
                ord_number         : original.number,
                offers_number      : ~~original.order ? elemInOrderNumber(original.key, 'O_NUM') :  original.number,
                offers_key         : ~~original.order ? elemInOrderNumber(original.key, 'O_ALL') : original.key.join(''),
                offers_version     : ~~original.order ? (elemInOrderNumber(original.key, 'O_V_DIGIT') || elemInOrderNumber(original.key, 'O_V_NUMBER').toLowerCase().charCodeAt(0) - 97) : original.version,
                dealer_code        : dealerCode,
                client_code        : ~~original.client_id < 0 ? elemInOfferNumber(original.key, 'K_CLIENT') : undefined,
                status             : offerType !== 'offer' ? '10' : demoOffer ? '99' : '1',
                dealer_status      : '1',
                order              : offerType === 'order' ? 1 : 0,
                shop_order         : offerType === 'shop_order' ? 1 : 0,
                client_offer       : demoOffer ? 1 : 0,
                subOffer           : true,
                original_offer_date: original.original_offer_date ? original.original_offer_date : original.created,
                seller_name        : producerName,
                montage_and_measure_changed: 0,
                history            : []
            }), client);

        }).then(res => {
            order = res;
            return OfferAttachmentsFactory.multiCopy(original.tmp_id, res);

        }).then(() => {
            return PositionsFactory.multiCopy(original, order, sequence, false, false, positions);

        }).then(res => {
            relations = res;
            return PositionAttachmentsFactory.multiCopy(original.tmp_id, order, relations);

        }).then(() => {
            return OffersService.get(order);
        }).then(res => {
            if (res.montages && res.montages.positions && res.montages.groups) {
                res.montages.positions.forEach(el =>
                    el.id = relations[el.id]
                );
                for (const group in res.montages.groups) {
                    res.montages.groups[group].positions.forEach(el =>
                        el.id = relations[el.id]
                    );
                }
            }
            Object.keys(res.positions_tax_rates).forEach(posId => {
                res.positions_tax_rates[relations[posId]] = res.positions_tax_rates[posId];
                delete res.positions_tax_rates[posId];
            });
            if (['logistic-minimum', 'm2-cost', 'weight', 'transport-types'].includes(IccConfig.Offer.transportCostType)
                && (offerType === 'order' && res.transport_from_producent && res.split_transport_cost
                || offerType !== 'order' && res.client_split_transport_cost)
            ) {
                const searched = OfferSequenceService.keysFromSequence(res.sequence);
                return ManyPositionsService.getMany(searched).then(searchedPositions => {
                    const allDiscounts = {
                        buy: DiscountsAndMultipliersService.getBuyDiscounts(),
                        sale: DiscountsAndMultipliersService.getSaleDiscounts(),
                    };
                    const positionsData = searchedPositions.map(p => p.doc);
                    const result = offerType === 'order'
                        ? OfferTransportCostService.joinTransportCost(positionsData, res, IccConfig, UserService.getDealer(), allDiscounts, user)
                        : OfferTransportCostService.joinClientTransportCost(positionsData, res, IccConfig, UserService.getDealer(), allDiscounts);
                    return Promise.all([
                        OffersService.update(result.offer._id, result.offer),
                        EditPosition.createMany(result.positions),
                    ]);
                });
            } else {
                return OffersService.update(res._id, res);
            }
        }).then(() => {
            OffersService.resumeEmitModifiedOffer();
            if (offerType !== 'offer') {
                $rootScope.showInfo($filter('translate')('OFFER|Podzamówienie zostało utworzone'), null);
            } else {
                $rootScope.showInfo($filter('translate')('OFFER|Podoferta została utworzona'), null);
            }
            return isNative && !OnlineStatusService.getStatus() ? true : PositionsFactory.whenAllSynced(order);
        }).then(() => {
            if (!$rootScope.user.access && original.demo_target === 'b2b') {
                // ustawienie id nowej oferty w state aplikacji, aby działało przekierowanie po synchronizacji
                StateService.setKey('offer_id', order); // ustawienie id nowej oferty w state aplikacji, aby działało przekierowanie po synchronizacji
                window.location.pathname = '/app/sync';
            } else {
                $location.url('/app/offers_view/' + order);
            }
        }).catch(() => {
            $rootScope.loader = false;
            OffersService.resumeEmitModifiedOffer();
            if (offerType !== 'offer') {
                $rootScope.showInfo($filter('translate')('OFFER|Nie można utworzyć zamówienia'), null);
            } else {
                $rootScope.showInfo($filter('translate')('OFFER|Nie można utworzyć oferty'), null);
            }
        });
    }

    /**
     * Funkcja szuka numeru oferty w numerze zamówienia
     * @param  {array}  num  Numer zamwienia
     * @param  {string} code Kod elementu
     * @return {string}      Numer oferty
     */
    function elemInOrderNumber(num, code) {
        const index = OfferKeyService.parseScheme($rootScope.user.order_scheme).map((e, i) => e.type == code ? i : null).filter(e => e !== null)[0];
        return num[index] || '';
    }

    /**
     * Funkcja szuka elementu oryginalnego w numerze oferty
     * @param  {array}  num  Numer oferty
     * @param  {string} code Kod elementu
     * @return {string}      Numer oferty
     */
    function elemInOfferNumber(num, code) {
        const index = OfferKeyService.parseScheme($rootScope.user.offer_scheme).map((e, i) => e.type == code ? i : null).filter(e => e !== null)[0];
        return num[index] || '';
    }


}
