import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { PageComponent, _, ICC_PAGE_DATA, SharedFacade, ModalService } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EventBusService } from '@icc/common';
import { ShutterFacade } from '../+state/shutter.facade';
import { TypeInfoComponent } from '../type-info/type-info.component';

@Component({
  selector: 'icc-systems-page',
  template: require('./systems-page.component.html'),
  styles: [require('./systems-page.component.scss')]
})
export class SystemsPageComponent extends PageComponent implements OnInit, OnDestroy {
  public title = _('ROLLERSHUTTER|System');

  systems: iccListItem[] = [];
  selectedSystem$ = this.shutterFacade.selectedSystem$.pipe(map(system => Number(system.id)));

  private subscriptions: Subscription[] = [];

  constructor(
      private sharedFacade: SharedFacade,
      private shutterFacade: ShutterFacade,
      private modalService: ModalService,
      @Inject(ICC_PAGE_DATA) private pageData: {
        systems: any[],
        selectedSystem: any,
        type: any
      },
  ) {
    super();
    this.init();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }

  private init() {
    this.systems = this.pageData.systems.map(system => ({
      id: Number(system.id),
      title: system.name,
      content: system.description,
      imageUrl: '/files/rollershuttersystem/' + system.image,
      showInfo: true
    }));
  }
  showInfo(item: iccListItem) {
    const system =  this.systems.filter(system=>system.id===item.id)[0];
    let systemSplitArr = system.imageUrl.split('/');
    system.imageUrl = systemSplitArr[systemSplitArr.length-1];
    const modalService = this.modalService
        .open({
            pageComponent: TypeInfoComponent,
            resolve: {
                system: () => system,
                imageFieldName: () => "imageUrl",
                imageDirectory: () => "rollershuttersystem",
                button: () => true,
            },
        })
        .result.then((result: boolean) => {
            if (result) {
                this.selectSystem(item);
            }
        });
  }
  selectSystem(item: iccListItem) {
    const system = this.pageData.systems.find(s => Number(s.id) === Number(item.id));
    if (system) {
      this.sharedFacade.closePage(system);
    }
  }

}
