import { Pipe, PipeTransform } from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';

@Pipe({name: 'currency'})

export class CurrencyPipe implements PipeTransform {

    transform(data: any, currency: any, noneSymbol?: string | null, onlyValue = false): any {
        let value: any;

        if (Common.isUndefined(data)) {
            return noneSymbol || null;
        } else if (isNaN(data)) {
            return noneSymbol || null;
        } else if (Common.isUndefined(currency)
            || Common.isUndefined(currency.value)
            || Common.isUndefined(currency.thousands)
            || Common.isUndefined(currency.decimals)) {
            if (typeof currency === 'string') {
                try {
                    const o = JSON.parse(currency);
                    if (o && typeof o === 'object' && o !== null) {
                        currency = o;

                        value = Math.round(100 * (parseFloat(data) || 0) / parseFloat(currency.value)) / 100;
                        if (onlyValue) {
                            return value;
                        }
                        if (currency.position === 'before') {
                            return currency.symbol + value.toString();
                        } else {
                            return value.toString() + currency.symbol;
                        }
                    }
                } catch (e) {
                    if (onlyValue) {
                        return parseFloat(data) || 0;
                    }
                    return (parseFloat(data) || 0).toFixed(2).toString() + 'zł';
                }
            }

            return (parseFloat(data) || 0).toFixed(2).toString() + 'zł';
        } else {
            value = Math.round(100 * (parseFloat(data) || 0) / parseFloat(currency.value)) / 100;
            if (onlyValue) {
                return value;
            }
            if (currency.position === 'before') {
                return currency.symbol + core.formatNumber(value, 2, 3, currency.thousands, currency.decimals);
            } else {
                return core.formatNumber(value, 2, 3, currency.thousands, currency.decimals) + ' ' + currency.symbol;
            }
        }
    }
  }
