import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-filling-colors-page',
  template: require('./filling-colors-page.component.html'),
  styles: [require('./filling-colors-page.component.scss')]
})
export class FillingColorsPageComponent extends PageComponent implements OnInit {
  public title = _('WINDOW|Wybierz kolor dodatku');
  public options = [];

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
