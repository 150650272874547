import { ThresholdColorsService } from './../profiles/threshold-colors.service';
import { Injectable, Inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { WindowPartialState } from './window.reducer';
import { windowQuery } from './window.selectors';
import { LoadWindow } from './window.actions';
import { Shape } from '@icc/window';
import { SharedFacade } from '@icc/configurator/shared';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService, EventBusService, AppConfigFactory, APP_CONFIG } from '@icc/common';
import { isNotNullOrUndefined } from '@icc/helpers';
import { FittingService } from '@icc/legacy/configurator/steps/window/handles/fitting.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { combineLatest } from 'rxjs';
import { BrowserProfilesService } from '../profiles/profiles.service';
import { DoorPortalsService } from '../door-portals/door-portals.service';

@Injectable()
export class WindowFacade {
    constructor(
        private store: Store<WindowPartialState>,
        private sharedFacade: SharedFacade,
        private fittingService: FittingService,
        private dataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private profilesService: BrowserProfilesService,
        private thresholdColorsService: ThresholdColorsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private doorPortalsService: DoorPortalsService
    ) {}

    shape$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Shape),
        filter(shape => Boolean(shape))
    );
    configuration$ = this.configurationsService.configuration$;
    sashTypes$ = this.dataService.data$.pipe(map(data => data.sashTypes));
    fillings$ = this.dataService.data$.pipe(
        map(data => data.fillings),
        filter(isNotNullOrUndefined)
    );

    layouts$ = this.dataService.layouts$.pipe(filter(isNotNullOrUndefined));

    colors$ = this.dataService.data$.pipe(
        map(data => [...(data.windowColorsAll || [])]),
        filter(isNotNullOrUndefined)
    );

    colorGroups$ = this.dataService.data$.pipe(
        map(data => data.windowColorGroups),
        filter(isNotNullOrUndefined)
    );

    windowSystems$ = this.dataService.data$.pipe(
        map(data => data.windowLines),
        filter(isNotNullOrUndefined)
    );

    selectedFilling$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => conf.Sashes[0].glazing)
    );

    selectedModel$ = this.configurationsService.configuration$.pipe(
        map(conf =>
            conf.Sashes.length > 0
                ? conf.Sashes[0].glazing
                : DoorActiveConfiguration.is(conf)
                ? conf.Model
                : null
        )
    );

    selectedModelForSashActive$ = this.configurationsService.configuration$.pipe(
        map(conf => DoorActiveConfiguration.is(conf) ? conf.Model : null)
    );

    modelHasBeenChosen$ = this.configurationsService.configuration$.pipe(
        map(conf =>
            DoorActiveConfiguration.is(conf) && conf.ModelOptions.hasBeenChosen
        )
    );

    selectedGlazingSpacer$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.WarmEdge)
    );

    selectedGlazingBead$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => conf.UsedProfiles.find(p => p.id === conf.Sashes[0].glazingBead.profileId)),
        filter(isNotNullOrUndefined)
    );

    selectedGlazingBeadInSashes$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => {
            const sash = conf.Sashes.find(s => s.type && s.type.type !== 'F');
            return sash && conf.UsedProfiles.find(p => p.id === sash.glazingBead.profileId);
        }),
        filter(isNotNullOrUndefined)
    );

    selectedGlazingBeadInFixed$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => {
            const sash = conf.Sashes.find(s => s.type &&s.type.type === 'F');
            return sash && conf.UsedProfiles.find(p => p.id === sash.glazingBead.profileId);
        }),
        filter(isNotNullOrUndefined)
    );

    selectedGlazingBeadColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.GlazingBeadColor)
    );

    selectedMuntinColorInner$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinsData.color)
    );

    selectedMuntinColorOuter$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinsData.colorOut)
    );

    selectedMuntinType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinsData.type)
    );

    selectedGlazing$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Glazing.type)
    );

    selectedMuntinSizeId$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinsData.sizeId)
    );
    selectedDuplex$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinsData.duplex)
    );

    selectedSealColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.SealColor)
    );

    selectedSiliconeColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.SiliconeColor)
    );

    selectedHandle$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => conf.Sashes[0].handle),
        filter(isNotNullOrUndefined)
    );

    selectedHandleColor$ = this.configurationsService.configuration$.pipe(
        filter(conf => conf.Sashes.length > 0),
        map(conf => conf.Sashes[0].handleColor),
        filter(isNotNullOrUndefined)
    );

    selectedFitting$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Fitting),
        filter(isNotNullOrUndefined)
    );

    selectedColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            if (conf.Colors && conf.Colors.frame) {
                if (conf.Colors.frame.outer && conf.Colors.frame.outer.id) {
                    return conf.Colors.frame.outer;
                } else {
                    return conf.Colors.frame.core;
                }
            }
        })
    );

    outerFrameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            if (conf.Colors && conf.Colors.frame) {
                if (conf.Colors.frame.outer && conf.Colors.frame.outer.id) {
                    return conf.Colors.frame.outer;
                } else {
                    return conf.Colors.frame.core;
                }
            }
        })
    );

    innerFrameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            if (conf.Colors && conf.Colors.frame) {
                if (conf.Colors.frame.inner && conf.Colors.frame.inner.id) {
                    return conf.Colors.frame.inner;
                } else {
                    return conf.Colors.frame.core;
                }
            }
        })
    );

    coreFrameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors && conf.Colors.frame && conf.Colors.frame.core)
    );

    alushellFrameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors && conf.Colors.frame && conf.Colors.frame.alushell)
    );

    outerSashColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            if (conf.Colors && conf.Colors.sash) {
                if (conf.Colors.sash.outer && conf.Colors.sash.outer.id) {
                    return conf.Colors.sash.outer;
                } else {
                    return conf.Colors.sash.core;
                }
            }
        })
    );

    innerSashColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            if (conf.Colors && conf.Colors.sash) {
                if (conf.Colors.sash.inner && conf.Colors.sash.inner.id) {
                    return conf.Colors.sash.inner;
                } else {
                    return conf.Colors.sash.core;
                }
            }
        })
    );

    coreSashColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors && conf.Colors.sash && conf.Colors.sash.core)
    );

    alushellSashColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors && conf.Colors.sash && conf.Colors.sash.alushell)
    );

    hasAlushell$ = this.configurationsService.configuration$.pipe(map(conf => conf.HasAlushell));

    colorType$ = this.configurationsService.configuration$.pipe(map(conf => conf.ColorType));
    colorSashExt$ = this.configurationsService.configuration$.pipe(map(conf => conf.ColorsSashExt));

    height$ = this.configurationsService.configuration$.pipe(map(conf => conf.Height));

    width$ = this.configurationsService.configuration$.pipe(map(conf => conf.Width));

    accessories$ = this.configurationsService.configuration$.pipe(map(conf => conf.Accessories));
    sideAccessories$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.SideAccessories)
    );

    sashes$ = this.configurationsService.configuration$.pipe(map(conf => conf.Sashes));

    selectedHinge$ = this.configurationsService.configuration$.pipe(map(conf => conf.Hinge));

    hingeColor$ = this.configurationsService.configuration$.pipe(map(conf => conf.HingeColor));

    locks$ = this.configurationsService.configuration$.pipe(map(conf => conf.Lock));

    innerPullBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.innerPullBox(conf))
    );

    innerLeverBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.innerLeverBox(conf))
    );

    outerPullBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.outerPullBox(conf))
    );

    windowLeverBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.windowLeverBox(conf))
    );

    hingeBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.hingeBoxSelect(conf))
    );

    showInnerPullBox$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.showInnerPullBox(conf))
    );

    showDoubleLeverBox$ = this.configurationsService.configuration$.pipe(
        map(
            conf => this.fittingService.showInnerLeverBox(conf) && conf.HandleType === 'DoubleLever'
        )
    );

    showInnerLeverBox$ = this.configurationsService.configuration$.pipe(
        map(
            conf => this.fittingService.showInnerLeverBox(conf) && conf.HandleType !== 'DoubleLever'
        )
    );

    showOuterPullBox$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.showOuterPullBox(conf))
    );

    showWindowLeverBox$ = this.configurationsService.configuration$.pipe(
        map(conf => this.fittingService.showWindowLeverBox(conf))
    );

    handleType$ = this.configurationsService.configuration$.pipe(map(conf => conf.HandleType));

    woodType$ = this.configurationsService.configuration$.pipe(map(conf => conf.Wood));

    balcony$ = this.configurationsService.configuration$.pipe(map(conf => conf.Balcony));

    layout$ = this.configurationsService.configuration$.pipe(map(conf => conf.Layout));

    lowThreshold$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Frames.some(frame => frame.lowThreshold))
    );

    weldFinishType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.weldFinishType)
    );

    drainagePosition$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.drainagePosition)
    );

    alushellType$ = this.configurationsService.configuration$.pipe(map(conf => conf.AlushellType));

    monoblock$ = this.configurationsService.configuration$.pipe(map(conf => conf.Monoblock));

    system$ = this.configurationsService.configuration$.pipe(
        withLatestFrom(this.dataService.data$),
        map(([conf, data]) => {
            if (conf.System && conf.System.subsystem && data.windowLinesAll) {
                return data.windowLinesAll.find(s => !s.subsystem && Number(conf.System.parent_id) === Number(s.id));
            }
            return conf.System;
        })
    );

    profileSet$ = this.configurationsService.configuration$.pipe(map(conf => conf.ProfileSet));

    oneGlazing$ = this.configurationsService.configuration$.pipe(map(conf => conf.OneGlazing));
    oneGlazingBead$ = this.configurationsService.configuration$.pipe(
        map(
            conf =>
                conf.OneGlazing
                && this.config().IccConfig.Configurators.glazingBeadsSelect
                && (conf.OneGlazingBead
                    || !this.config().IccConfig.Configurators.glazingBeadsSelectSash)
        )
    );
    oneGlazingBeadSashes$ = this.configurationsService.configuration$.pipe(
        map(
            conf =>
                conf.OneGlazing
                && this.config().IccConfig.Configurators.glazingBeadsSelect
                && this.config().IccConfig.Configurators.glazingBeadsSelectSash
                && !conf.OneGlazingBead
                && conf.OneGlazingBeadSash.sashes
        )
    );
    oneGlazingBeadFix$ = this.configurationsService.configuration$.pipe(
        map(
            conf =>
                conf.OneGlazing
                && this.config().IccConfig.Configurators.glazingBeadsSelect
                && this.config().IccConfig.Configurators.glazingBeadsSelectSash
                && !conf.OneGlazingBead
                && conf.OneGlazingBeadSash.fix
        )
    );

    hasWarmEdge$ = this.configurationsService.configuration$.pipe(map(conf => conf.HasWarmEdge));

    hasAlushellColorSelect$ = combineLatest(this.hasAlushell$, this.system$).pipe(
        map(([hasAlushell, system]) => hasAlushell && system.type !== 'alu')
    );

    hasThresholdColorSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            const profile = this.profilesService.getProfile(this.profilesService.getUsedThresholdId(conf));
            return profile && profile.priceLevelId !== null;
        })
    );

    availableThresholdColors$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return this.thresholdColorsService.getThresholdColors(conf);
        })
    );

    thresholdColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.thresholdColor)
    );

    lippingColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.lippingColor)
    );

    selectedLock$ = this.configurationsService.configuration$.pipe(map(conf => conf.Lock && Number(conf.Lock.id)));

    hasLippingColorSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return conf.System.available_lipping_color;
        })
    );

    hasLippingBicolorSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return conf.System.available_lipping_in_bicolor;
        }),
    );

    innerLippingColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.innerLippingColor)
    );

    selectedDoorPortal$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return conf.doorPortal;
        })
    )

    selectedDoorPortalColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return conf.doorPortal && conf.doorPortal.selectedColor;
        })
    )

    availablePortal$ = this.configurationsService.configuration$.pipe(
        map(conf => this.doorPortalsService.canAddPortal())
    );

    onlyDoubleSidedFrameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            const frameProfileIds = this.profilesService.getUsedFrameProfileIds(conf)
            return frameProfileIds && frameProfileIds.every(profileId => {
                const profileColorSides = this.profilesService.getProfilePriceLevelColorSides(profileId)
                return profileColorSides && profileColorSides.length > 0 && profileColorSides.every(side => side === 'double');
            })
        })
    );

    loadAll() {
        this.store.dispatch(new LoadWindow());
    }
}
