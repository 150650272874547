import { core, logger } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { EventBusService } from '@icc/common/event-bus.service';
import { UserService } from '@icc/common/user.service';
import { Injectable, Inject } from '@angular/core';
import { DatabaseManagerToken } from '@icc/helpers/browser';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { fixCustomFillings } from '@icc/common/fix-glazing-units';

@Injectable()
export class BrowserConfiguratorsDataService extends ConfiguratorsDataService {
    data: any = {};
    layouts: any[] = [];
    layoutsVariants: any[] = [];
    get loaded() {
        return (
            this.data
            && this.data.windowLinesAll
            && this.data.windowLinesAll.length > 0
            && this.data.windowPrices
            && Object.keys(this.data.windowPrices).length > 0
        );
    }

    constructor(
        @Inject(DatabaseManagerToken) private DatabaseManager,
        private eventBusService: EventBusService,
        private userService: UserService,
    ) {
        super();
        this.load();

        this.eventBusService.subscribeWithoutConfiguration(
            ['syncedPrices', 'syncedConfigurators'],
            () => {
                this.reload();
            }
        );
    }

    async reload() {
        this.data = {};
        this.layouts = [];
        this.layoutsVariants = [];

        this.load();
    }

    async load() {
        if (!this.loaded) {
            let r = await this.DatabaseManager.get('Configurators').get();
            let data = core.parseJson(r && r.data ? r.data : '{}');
            const user = this.userService.get();
            if (Common.isObject(data)) {
                if (data.marketId && user && user.marketId && Number(data.marketId) !== Number(user.marketId)) {
                    logger.error('Dane konfiguratora ze złego rynku!', {userMarketId: user.marketId, dataMarketId: data.marketId});
                }
                data = data.configsData || {};
                let customFillings = core.parseJson(localStorage.getItem('customFillings') || '[]');
                customFillings = fixCustomFillings(customFillings, data.glasses, data.interPaneSpaces);
                data.fillings = customFillings.concat(data.fillings);
                this.data = data;
            }

            r = await this.DatabaseManager.get('Prices').get();

            data = core.parseJson(r && r.data ? r.data : '{}');
            if (data.marketId && user && user.marketId && Number(data.marketId) !== Number(user.marketId)) {
                logger.error('Dane cen ze złego rynku!', {userMarketId: user.marketId, dataMarketId: data.marketId});
            }
            Common.extend(this.data, data);
            this.data$.next(this.data);

            let layouts = await this.DatabaseManager.get('Layouts').get();
            this.layouts =
                layouts && layouts.data ? core.parseJson(layouts.data).sashesLayouts || [] : [];
            this.layoutsVariants =
                layouts && layouts.data ? core.parseJson(layouts.data).sashesVariants || [] : [];

            if (this.loaded) {
                this.eventBusService.post({
                    key: 'loadedConfiguratorsData',
                    value: this.data,
                });
            }
        }
        return this.data;
    }
}
