export function isUndefined(value: any): value is undefined {
    return typeof value === 'undefined';
}

export function isDefined(value: any) {
    return typeof value !== 'undefined';
}

export function isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function isObject<T>(value: null | undefined | string | number | symbol | any[] | T): value is T {
    return value !== null && typeof value === 'object';
}

export function isNotNullOrUndefined<T>(value: null | undefined | T): value is T {
    return value != null;
}

export function isEmptyObject(value: any): value is {} {
    return isObject(value) && Object.keys(value).length === 0;
}

export function castToNullable(value: any) {
    return value === 'null' ? null : value
}

export function castToNullableNumber(value: any) {
    return (value === null) ? null : Number(value);
}

export function isValidDate(date: any): date is Date {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}


export const isArray = Array.isArray;

type Required<T> =
  T extends object
    ? { [P in keyof T]-?: NonNullable<T[P]>; }
    : T;


export interface Point {
    x: number;
    y: number;
    cr?: number;
}
