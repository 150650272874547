import { Common } from '@icc/common/Common';
import { core, logger } from '@icc/common/helpers';
import { ProfilesService } from '@icc/common/profiles.service';
import { ShapeService } from '@icc/common/shape.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { Injectable, Inject } from '@angular/core';
import { IccDrawMathService } from '@icc/draw';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';

class Point {
    x: number;
    y: number;
    cr?: number;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

class Vector {
    start;
    end;
    x;
    y;

    constructor(start, end) {
        this.start = start;
        this.end = end;
        this.x = end.x - start.x;
        this.y = end.y - start.y;
    }
}

class Line {
    p1;
    p2;

    constructor(p1, p2) {
        this.p1 = p1;
        this.p2 = p2;
    }
}

@Injectable()
export class BrowserShapeService extends ShapeService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private ConfiguratorsDataService: ConfiguratorsDataService,
        private ProfilesService: ProfilesService,
        private EventBusService: EventBusService
    ) {
        super();
        if (this.ConfiguratorsDataService.loaded) {
            this.init();
        }

        this.EventBusService.subscribeWithoutConfiguration(['loadedConfiguratorsData'], () =>
            this.init()
        );

        this.EventBusService.subscribe(
            ['loadedProfiles', 'setMullionProfile', 'setFrameProfile', 'setSashProfile'],
            data => {
                this.setShapes(data.activeConfiguration);
            }
        );
    }

    setShapes(conf) {
        const { Sashes: sashes, Shape: shape, Mullions: dividers, drawData } = conf;
        sashes.forEach(sash => {
            this.setSashShape(sash, shape, null, drawData);

            sash.intSashes.forEach(intSash => {
                this.setSashShape(intSash, shape, sash, drawData);
            });
            sash.intMullions.forEach(intDivider => {
                this.setDividerShape(intDivider, shape, drawData, sash);
            });
        });
        dividers.forEach(divider => {
            this.setDividerShape(divider, shape, drawData);
        });
    }

    isAvailable(shape, systemId) {
        if (shape === 'rect') {
            return true;
        }
        return (
            this.shapeAvailability[shape]
            && Common.isArray(this.shapeAvailability[shape])
            && this.shapeAvailability[shape].indexOf(Number(systemId)) > -1
        );
    }


    private init() {
        if ( this.config().preset === 'b2c' ) {
            this.shapeAvailability = this.ConfiguratorsDataService.data.windowShapesB2C || {};
        } else {
            this.shapeAvailability = this.ConfiguratorsDataService.data.windowShapes || {};
        }
    }

    private setSashShape(sash, shape, parent, drawData) {
        const sashId = sash.id;
        const sashDrawData = drawData && drawData.sash.find(s => s.sashId === sashId);
        if (!sashDrawData) {
            return;
        }

        sash.shape.points = sashDrawData.inner.poly;
        sash.shape.angles = this.getSashAngles(sash, parent);
        sash.shape.shape = this.getSashShape(sash);
        sash.shape.valid = this.validateSashShape(sash, parent, drawData);

        sash.shape.d = 0;
        sash.shape.radius = 0;
        if (sash.shape.shape === 'circle') {
            sash.shape.d = shape.d;
        } else if (sash.shape.shape === 'arc') {
            sash.shape.radius = shape.radius;
        }

        sash.shape.height = core.round10(sashDrawData.outer.rect.height, -1);
        sash.shape.width = core.round10(sashDrawData.outer.rect.width, -1);
        sash.shape.y = core.round10(sashDrawData.outer.rect.y, -1);
        sash.shape.x = core.round10(sashDrawData.outer.rect.x, -1);
    }

    private getSashAngles(sash, parent) {
        const sashType = parent ? parent.type : sash.type;
        const points = sash.shape.points;
        let hingeSidePointsIndexes = [];
        const sidesInfo = points.map((point, index) =>
            IccDrawMathService.getLineInfo([
                point,
                points[(index + points.length + 1) % points.length],
            ])
        );
        if (['F', 'FF', 'OFF'].indexOf(sashType.type) === -1) {
            let desirableSide = 'left';
            if (sashType.type === 'K') {
                desirableSide = 'bottom';
            } else if (sashType.handle_position === 'L') {
                desirableSide = 'right';
            } else {
                desirableSide = 'left';
            }
            hingeSidePointsIndexes = sidesInfo
                .map((side, index) =>
                    side.side === desirableSide ? [index, (index + 1) % sidesInfo.length] : null
                )
                .filter(indexes => indexes !== null)
                .reduce((prev, cur) => {
                    prev = prev.concat(cur);
                    return prev;
                }, []);
        }
        const hasLeftSlopeSide =
            sash.shape.points.every(p => !p.cr)
            && sidesInfo.some(
                sideInfo => sideInfo.side === 'left' && core.round10(sideInfo.angle, -3) !== 0.5
            );
        const hasRightSlopeSide =
            sash.shape.points.every(p => !p.cr)
            && sidesInfo.some(
                sideInfo => sideInfo.side === 'right' && core.round10(sideInfo.angle, -3) !== 1.5
            );
        const angles = points.map((point, index) =>
            this.findAngle(
                points[(index + points.length - 1) % points.length],
                point,
                points[(index + points.length + 1) % points.length]
            )
        );
        const hingeSideAngles = angles.filter(
            (angle, index) => hingeSidePointsIndexes.indexOf(index) > -1
        );
        const nonHingeSideAngles = angles.filter(
            (angle, index) => hingeSidePointsIndexes.indexOf(index) === -1
        );

        return {
            angles,
            hingeSide: hingeSideAngles,
            nonHingeSide: nonHingeSideAngles,
            hasLeftSlopeSide,
            hasRightSlopeSide,
        };
    }

    private getSashShape(sash) {
        let type = 'rect';

        if (sash.shape.points.every(p => !p.cr)) {
            if (sash.shape.points.length === 3) {
                type = 'triangle';
            } else if (
                sash.shape.points.length > 3
                && sash.shape.angles.angles.some(angle => angle !== 90)
            ) {
                type = 'poligon';
            }
        } else {
            if (sash.shape.points.every(p => p.cr)) {
                type = 'circle';
            } else {
                type = 'arc';
            }
        }

        return type;
    }

    private validateSashShape(sash, parent, drawData) {
        if (!this.ProfilesService.loadedData) {
            return false;
        }

        const sashFrame = parent ? parent.frame : sash.frame;
        if (!sashFrame || !sashFrame.bottom) {
            return false;
        }

        const sashFrameProfile = sashFrame.bottom;
        const profile = this.ProfilesService.getProfile(sashFrameProfile.profileId);
        const sashType = parent ? parent.type : sash.type;
        const sashId = parent ? parent.id : sash.id;
        const sashDrawData = drawData.sashFrame.find(s => s.sashId === sashId);
        let sashFrameRadius = 0;
        if (sashDrawData) {
            sashFrameRadius = sashDrawData.outer.poly.reduce((cr, o) => (o.cr > cr ? o.cr : cr), 0);
        }

        let valid = true;
        if (sashType.type !== 'F') {
            if (['PSK'].indexOf(sashType.type) > -1 && sash.shape.shape !== 'rect') {
                valid = false;
            } else if (sash.shape.shape === 'arc' || sash.shape.shape === 'circle') {
                valid = profile && sashFrameRadius >= Number(profile.minBendRadius);
            } else {
                let minAngle = 0;
                let minAngleHinge = 0;
                if (profile) {
                    minAngle = ['DK', 'D', 'K'].indexOf(sashType.type) > -1
                        ? profile[core.toCamelCase('min_angle_' + sashType.type.toLowerCase())]
                        : profile.minAngle;
                    minAngleHinge = ['DK', 'D', 'K'].indexOf(sashType.type) > -1
                        ? profile[core.toCamelCase('min_angle_hinge_' + sashType.type.toLowerCase())]
                        : profile.minAngleHinge;
                }
                valid =
                    profile
                    && sash.shape.angles.nonHingeSide.every(
                        angle => angle >= Number(minAngle)
                    )
                    && sash.shape.angles.hingeSide.every(
                        angle => angle >= Number(minAngleHinge)
                    );
            }
        }
        return valid;
    }

    private setDividerShape(divider, shape, drawData, parent?) {
        const mullionId = divider.id;
        const mullionDrawData = drawData.mullion.find(s => s.mullionId === mullionId);

        if (!mullionDrawData) {
            return;
        }

        divider.cutAngle = this.getDividerCutAngle(divider, shape, parent, mullionDrawData);
        divider.valid = this.validateDividerCutAngle(divider);
    }

    private getDividerCutAngle(divider, shape, parentSash, drawData) {
        const cutAngles = drawData.poly.map((point, index) =>
            this.findLineAngle([
                point,
                drawData.poly[(index + drawData.poly.length + 1) % drawData.poly.length],
            ])
        );

        return cutAngles
            .filter(angle => [0, 90, 180, 270].indexOf(angle) === -1)
            .reduce((prev, cur) => (cur > prev ? cur : prev), 0);
    }

    private findAngle(a: Point, b: Point, c: Point) {
        if (b.cr || c.cr) {
            return null;
        }
        const AB = Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
        const BC = Math.sqrt(Math.pow(b.x - c.x, 2) + Math.pow(b.y - c.y, 2));
        const AC = Math.sqrt(Math.pow(a.x - c.x, 2) + Math.pow(a.y - c.y, 2));
        return core.round10(
            (Math.acos((AB * AB + BC * BC - AC * AC) / (2 * BC * AB)) * 180) / Math.PI,
            -2
        );
    }

    private findLineAngle(line) {
        if (line[1].cr) {
            return null;
        }
        const angle = Math.abs(Math.atan2(line[1].y - line[0].y, line[1].x - line[0].x));
        return core.round10(180 - (angle * 180) / Math.PI, -2);
    }

    private validateDividerCutAngle(divider) {
        if (!divider.profileId && divider.type === 'no_mullion') {
            return true;
        }
        if (!divider.profileId || !this.ProfilesService.loadedData) {
            return false;
        }
        const profile = this.ProfilesService.getProfile(divider.profileId);
        if (!profile) {
            return false;
        }
        return divider.cutAngle <= Number(profile.maxAngle);
    }
}
