import { core } from '@icc/common/helpers';
import { InterPaneSpace, Glass } from '@icc/window';

export function fixCustomFillings(customFillings: any[], allGlasses, allInterPaneSpaces) {
    const fixedFillings = [];
    if (customFillings && customFillings.length > 0 && allGlasses.length > 0) {
        customFillings.forEach(filling => {
            if (filling.glazing_unit) {
                let extendedGlazingUnit = core.copy(filling.glazing_unit);
                const glasses = [];
                const interPaneSpaces = [];
                let hasAll = true;
                extendedGlazingUnit = extendedGlazingUnit.map(el => {
                    if (el.type === 'glass') {
                        el.data = core.fIdO(allGlasses, el.value) as Glass;
                        if (!el.data) {
                            hasAll = false;
                        }
                        glasses.push(core.copy(el.data));
                    } else if (el.type === 'interPaneSpace') {
                        el.data = core.fIdO(allInterPaneSpaces, el.value) as InterPaneSpace;
                        if (!el.data) {
                            hasAll = false;
                        }
                        interPaneSpaces.push(core.copy(el.data));
                    }
                    return el;
                });

                if (hasAll) {
                    filling.thickness_mm =
                        glasses.reduce((prev, curr) => prev + curr.thickness, 0)
                        + interPaneSpaces.reduce((prev, curr) => prev + curr.thickness, 0);
                    filling.thinkness_glass = Math.min(
                        ...glasses.map(el => el.thicknessForCalculations)
                    );
                    fixedFillings.push(filling);
                }
            } else {
                fixedFillings.push(filling);
            }
        });
    }
    return fixedFillings;
}
