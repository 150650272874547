import { Injectable, Inject } from '@angular/core';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { ChangedStepValue, StepFactory } from './steps/StepFactory';
import { IssuesService, IssueLevel } from '@icc/common/issues.service';
import { Issue, InfoService, StepsService, RegisterDataProblemOptions, SimpleRegisterOptions } from '@icc/helpers';

@Injectable()
export class BrowserIssuesService extends IssuesService {
    constructor(
        @Inject('$translate') private $translate,
        private infoFactory: InfoService,
        private eventBusService: EventBusService,
        private stepFactory: StepsService
    ) {
        super();
        this.eventBusService.subscribe<ChangedStepValue>('changedStep', data => {
            const issuesBlockingSteps = data.activeConfiguration.Issues.filter(
                (issue: Issue) => issue.blockStepsAfter
            );

            if (issuesBlockingSteps.length > 0) {
                const minAllowedStep = issuesBlockingSteps
                    .map((issue: Issue) => stepFactory.getStepByCode(issue.blockStepsAfter))
                    .reduce((prev, cur) => (cur < prev ? cur : prev), Infinity);
                if (data.value.nextStep.i > minAllowedStep) {
                    stepFactory.selectStep(data.value.prevStep.i);
                }
            }
        });
    }

    registerDataProblem(
        key: string,
        description: string,
        conf: ActiveConfiguration,
        options: RegisterDataProblemOptions = {}
    ) {
        const registerOptions: SimpleRegisterOptions = options;
        this.simpleRegister(
            key,
            description,
            this.$translate.instant(
                'INTERFACE|Wybrany produkt nie może być aktualnie skonfigurowany. Skontaktuj się ze swoim opiekunem po stronie producenta'
            ),
            conf,
            registerOptions
        );
    }

    showMessage(issue) {
        if (this.modalOpened) {
            this.modalOpened = false;
            this.infoFactory.openWarning(issue.message).finally(() => {
                this.modalOpened = true;
            });
        }
    }
}
