import { ExternalBlindActiveConfiguration } from './../configurations/ExternalBlindActiveConfiguration';
import { Common } from '../Common';
import { IccDrawMathService } from '@icc/draw';
import { core } from '../helpers';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { ProfilesService } from '@icc/common/profiles.service';
import { SashTypes, Frame } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { IccWarmEdge, IccAccessory, IccParameters } from '@icc/common/data-types';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';

@Injectable()
export class ParametersService {
    RwO = {
        // Rw szyby: Rw okna
        27: 20,
        28: 31,
        29: 32,
        30: 33,
        31: 33,
        32: 34,
        33: 34,
        34: 35,
        35: 35,
        36: 36,
        37: 36,
        38: 37,
        39: 37,
        40: 38,
    };

    Ra2 = {
        24: 26,
        25: 27,
        26: 28,
        27: 29,
        28: 30,
        29: 30,
        30: 31,
        31: 31,
        32: 32,
        33: 32,
        34: 33,
        35: 33,
        36: 34,
    };
    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        this.eventBusService.subscribe(
            [
                'changedBalcony',
                'changedFillings',
                'changedSashes',
                'changedStep',
                'putAlignmentInField',
                'putExtensionOnSide',
                'setExtensionProfile',
                'removedAlignmentInField',
                'removedAlignment',
                'removedExtensionFromSide',
                'setConstructionColor',
                'setFrameProfile',
                'setGlazingInSash',
                'setGlazingBeadColor',
                'setGlazingBeadInSash',
                'setMullionProfile',
                'setProfileSet',
                'setSashProfile',
                'setSealColor',
                'setShape',
                'setSystem',
                'setWarmEdge',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'icc-redraw',
                'saveGuideProfile',
                'saveSlatProfile',
                'changedDoorHardware',
            ],
            data =>
                this.count(data.activeConfiguration as
                    | WindowActiveConfiguration
                    | RollerShutterActiveConfiguration)
        );
    }

    /**
     * Funkcja zwracająca wagę rolki
     * @param  {Object} profile Profil
     * @param  {Number} width   Szerokość
     * @param  {Number} height  Wysokość
     * @return {Number}         Waga
     */
    getRollerWeight(roller, shutter) {
        let weight = 0;
        if (roller && Common.isObject(roller.profile)) {
            let width = shutter.realWidth;
            let height = shutter.realHeight;
            if (this.config().IccConfig.Configurators.roller_shutter.realDimensions) {
                const leftGuideRail = roller.guideRails.find(g => g.leftOf === shutter.id);
                const rightGuideRail = roller.guideRails.find(g => g.rightOf === shutter.id);
                const slat = roller.slats.find(g => g.rollerId === shutter.id);
                if (leftGuideRail && rightGuideRail) {
                    const leftGuideRailProfile = this.configuratorsDataService.data.rollerShutterProfiles.find(
                        el => el.id === leftGuideRail.id
                    );
                    const rightGuideRailProfile = this.configuratorsDataService.data.rollerShutterProfiles.find(
                        el => el.id === rightGuideRail.id
                    );
                    if (leftGuideRailProfile && rightGuideRailProfile) {
                        width -=
                            leftGuideRailProfile.widthOut
                            - leftGuideRailProfile.depth
                            + rightGuideRailProfile.widthOut
                            - rightGuideRailProfile.depth;
                    }
                }
                if (slat) {
                    const slatProfile = this.configuratorsDataService.data.rollerShutterProfiles.find(
                        el => el.id === slat.id
                    );
                    height =
                        Math.ceil(
                            (height + roller.realBoxHeight / 2 - slatProfile.widthOut)
                                / roller.profile.width
                        )
                            * roller.profile.width
                        + slatProfile.widthOut;
                }
            }
            weight =
                ((Number(width) * Number(height)) / 1000000)
                * (parseFloat(roller.profile.weight_m2) || 0);
        }

        return weight;
    }

    /**
     * Funkcja licząca współczynniki Uw i Sw dla danej kwatery
     * @param conf konfiguracja
     * @param sash kwatera rodzic (skrzydło)
     * @param intSash kwatera dziecko (szyba)
     * @return wyliczone wartości współczynników
     */
    getSashValues(conf: WindowActiveConfiguration, sash: ActiveSash, intSash: ActiveSash) {
        // figury
        const inner = {
            poly: [],
            area: 0,
            edge: 0,
        };
        const joins = [];
        const outer = {
            poly: [],
            area: 0,
            edge: 0,
        };

        // składowe sw/uw
        const values = {
            Af: [],
            Sf: [],
            Uf: [],
            AfSf: 0,
            AfUf: 0,

            Ag: 0,
            Sg: 0,
            Ug: 0,
            AgSg: 0,
            AgUg: 0,

            Psi: 0,
            L: 0,
            PsiL: 0,
        };

        const frame = conf.Frames.find(f => f.id === sash.frameId);

        // współczynnik koloru
        const alfa = conf.Colors.sash.outer.id
            ? Number(conf.Colors.sash.outer.alfa)
            : conf.Colors.sash.core.id
            ? Number(conf.Colors.sash.core.alfa)
            : conf.Colors.frame.outer.id
            ? Number(conf.Colors.frame.outer.alfa)
            : conf.Colors.frame.core.id
            ? Number(conf.Colors.frame.core.alfa)
            : 0;

        const he = Number(conf.System.he) || 0;

        // zewnętrzna figura
        const sashData = conf.drawData.sash.find(o => o.sashId === intSash.id);

        outer.poly = sashData ? sashData.outer.poly : [];
        outer.area = sashData ? sashData.outer.polyArea : 0;
        outer.edge = sashData ? sashData.outer.polyEdge : 0;

        // wewnętrzna figura
        const glazingBeadData = conf.drawData.glazingBead.find(o => o.sashId === intSash.id);

        inner.poly = glazingBeadData ? glazingBeadData.inner.poly : [];
        inner.area = glazingBeadData ? glazingBeadData.inner.polyArea : 0;
        inner.edge = glazingBeadData ? glazingBeadData.inner.polyEdge : 0;

        // przylegające profile ramy
        const parallelFrameSegments = sashData ? sashData.parallel.poly : [];

        // renson
        const rensonData = sash.hardware.some(e => e.accessory.type === 'renson')
            ? conf.drawData.renson.find(o => o.sashId === intSash.id)
            : null;

        // współczynniki szyby
        values.Ag = Number(inner.area) - (rensonData ? Number(rensonData.polyArea) : 0) || 0;
        values.Sg = Number(intSash.glazing.s) || 0;
        values.Ug = Number(intSash.glazing.u) || 0;

        values.Psi = Number(this.findPsi(conf.WarmEdge, conf.System, intSash.glazing)) || 0;
        values.L = Number(inner.edge) || 0;

        values.AgSg = values.Ag * values.Sg;
        values.AgUg = values.Ag * values.Ug;
        values.PsiL = values.Psi * values.L;

        if (intSash.glazing.type !== 'glazing' && values.PsiL === 0) {
            values.PsiL = 0.000001;
        }

        // współczynniki złożeń
        let i: number;
        let polygons = [];

        if (outer.poly.length === inner.poly.length) {
            polygons = IccDrawMathService.getPolygons(outer.poly, inner.poly).polygons || [];
        }

        for (i = 0; i < polygons.length; i++) {
            const poly = polygons[i];

            // złożenie
            joins[i] = {};
            joins[i].poly = poly;
            joins[i].area = IccDrawMathService.getAreaFromPoly(poly) / 1e6 || 0;
            joins[i].side = IccDrawMathService.getLineInfo([poly[0], poly[1]]).side;

            // słupek w skrzydle
            if (intSash.nearMullions[joins[i].side] > -1 && sash.intMullions.length > 0) {
                const divider =
                    core.fIdO<any>(sash.intMullions, intSash.nearMullions[joins[i].side]) || {};

                joins[i].divider = core.fIdO<any>(conf.UsedProfiles, divider.profileId) || {};

                values.Uf[i] = joins[i].divider.u;
            }
            // słupek w ramie + skrzydło
            else if (
                sash.nearMullions[joins[i].side] > -1
                && conf.Mullions.length > 0
                && sash.intSashes.length > 0
            ) {
                const divider =
                    core.fIdO<any>(conf.Mullions, sash.nearMullions[joins[i].side]) || {};

                joins[i].divider = core.fIdO<any>(conf.UsedProfiles, divider.profileId) || {};
                joins[i].sashFrame =
                    core.fIdO<any>(conf.UsedProfiles, sash.frame.bottom.profileId) || {};

                const combination = this.configuratorsDataService.data.profilesCombinations.find(
                    el =>
                        el.combination.every(id =>
                            [joins[i].divider.id, joins[i].sashFrame.id].includes(Number(id))
                        )
                );

                values.Uf[i] = combination
                    ? combination.u
                    : Math.max(joins[i].divider.u, joins[i].sashFrame.u);
            }
            // słupek w ramie + szklenie stałe
            else if (
                sash.nearMullions[joins[i].side] > -1
                && conf.Mullions.length > 0
                && sash.intSashes.length === 0
            ) {
                const divider =
                    core.fIdO<any>(conf.Mullions, sash.nearMullions[joins[i].side]) || {};

                joins[i].divider = core.fIdO<any>(conf.UsedProfiles, divider.profileId) || {};

                values.Uf[i] = joins[i].divider.u;
            }
            // rama + skrzydło
            else if (sash.intSashes.length > 0) {
                joins[i].confFrame =
                    core.fIdO<any>(
                        conf.UsedProfiles,
                        (
                            frame.frame[parallelFrameSegments[i]]
                            || frame.frame[1]
                            || frame.frame[0] || { profileId: null }
                        ).profileId
                    ) || {};
                joins[i].sashFrame =
                    core.fIdO<any>(conf.UsedProfiles, sash.frame.bottom.profileId) || {};

                const combination = this.configuratorsDataService.data.profilesCombinations.find(
                    el =>
                        el.combination.every(id =>
                            [joins[i].confFrame.id, joins[i].sashFrame.id].includes(Number(id))
                        )
                );

                values.Uf[i] = combination
                    ? combination.u
                    : Math.max(joins[i].confFrame.u, joins[i].sashFrame.u);
            }
            // rama + szklenie stałe
            else {
                joins[i].confFrame =
                    core.fIdO<any>(
                        conf.UsedProfiles,
                        (
                            frame.frame[parallelFrameSegments[i]]
                            || frame.frame[1]
                            || frame.frame[0] || { profileId: null }
                        ).profileId
                    ) || {};

                values.Uf[i] = joins[i].confFrame.u;
            }

            values.Af[i] = Number(joins[i].area) || 0;
            values.Sf[i] = he > 0 ? (alfa * values.Uf[i]) / he : 0;

            values.AfSf += values.Af[i] * values.Sf[i];
            values.AfUf += values.Af[i] * values.Uf[i];
        }

        // renson
        if (rensonData) {
            i = joins.length;

            joins[i] = {};

            joins[i].poly = rensonData.poly;
            joins[i].area = rensonData.polyArea;
            joins[i].side = 'renson';

            const index = joins.findIndex(el => el.side === 'top');
            const renson = sash.hardware.find(el => el.accessory.type === 'renson');

            values.Af[i] = Number(joins[i].area) || 0;
            values.Sf[i] = Number(renson.accessory.s) || values.Sf[index];
            values.Uf[i] = Number(renson.accessory.u) || values.Uf[index];

            values.AfSf += values.Af[i] * values.Sf[i];
            values.AfUf += values.Af[i] * values.Uf[i];
        }

        return { inner, joins, outer, values };
    }

    /**
     * Funcja licząca wagę i inne parametry
     * @param {Object} conf konfiguracja pozycji
     */
    count(
        conf:
            | WindowActiveConfiguration
            | RollerShutterActiveConfiguration
            | ExternalBlindActiveConfiguration
            | CoupledWindowActiveConfiguration
    ) {
        if (Common.isUndefined(conf) || Common.isUndefined(conf.drawData) || !conf.drawData) {
            return;
        }

        if (RollerShutterActiveConfiguration.is(conf)) {
            conf.Weight = this.countRollerShutterWeight(conf);
        } else if (conf.type === 'coupled_window') {
            conf.parameters.weight = this.countCoupledWindowWeight(conf);
        } else if (conf.type) {
            this.setWindowWeight(conf);
            this.setWindowValues(conf);
        }
    }

    /**
     * Funkcja licząca współczynniki Uw i Sw dla całej konfiguracji
     * @param conf konfiguracja pozycji
     */
    private setWindowValues(conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        if (
            !conf
            || !conf.Shape
            || !conf.Sashes
            || !conf.System
            || !conf.Frames
            || !conf.Frames.length
        ) {
            return;
        }

        conf.Frames.forEach(frame => {
            // powierzchnia okna
            const frameDrawData =
                conf.drawData.frame && conf.drawData.frame.find(f => f.frameId === frame.id);

            const AfAg = frameDrawData ? frameDrawData.outer.polyArea : 0;

            // współczynniki
            let isS = true;
            let isU = true;

            let Sw = 0;
            let Uw = 0;

            // współczynniki
            const rw = {
                Rw: null,
                RwBf: null,
                C: null,
                Ctr: null,
            };

            let newrw = {
                Rw: null,
                RwBf: null,
                C: null,
                Ctr: null,
            };

            let g = 0;
            let lt = 0;

            const values = [];

            // liczba wszystkich szprosów
            let muntins = 0;

            this.layoutsParameters(frame, conf);

            // rozbicie na kwatery
            conf.Sashes.filter(sash => sash.frameId === frame.id).forEach(sash => {
                if (sash.intSashes.length > 0) {
                    sash.intSashes.forEach(intSash => {
                        muntins += intSash.muntins.length;

                        values[intSash.id] = this.getSashValues(conf, sash, intSash).values;

                        if (intSash.glazing) {
                            if (
                                rw.Rw === null
                                || core.num(intSash.glazing.rw) < rw.Rw
                                || (intSash.glazing.rw === rw.Rw
                                    && (core.num(intSash.glazing.ctr) < rw.Ctr
                                        || core.num(intSash.glazing.c) < rw.C))
                            ) {
                                rw.Rw = core.num(intSash.glazing.rw);
                                rw.C = core.num(intSash.glazing.c);
                                rw.Ctr = core.num(intSash.glazing.ctr);
                            }

                            g =
                                core.num(intSash.glazing.g) < g || !g
                                    ? core.num(intSash.glazing.g)
                                    : g;
                            lt =
                                core.num(intSash.glazing.lt) < lt || !lt
                                    ? core.num(intSash.glazing.lt)
                                    : lt;
                        }
                    });
                } else {
                    muntins += sash.muntins.length;

                    values[sash.id] = this.getSashValues(conf, sash, sash).values;

                    // współczynniki
                    if (sash.glazing) {
                        if (
                            rw.Rw === null
                            || core.num(sash.glazing.rw) < rw.Rw
                            || (sash.glazing.rw === rw.Rw && core.num(sash.glazing.ctr) < rw.Ctr)
                            || (sash.glazing.rw === rw.Rw
                                && core.num(sash.glazing.ctr) === rw.Ctr
                                && core.num(sash.glazing.c) < rw.C)
                        ) {
                            rw.Rw = core.num(sash.glazing.rw);
                            rw.C = core.num(sash.glazing.c);
                            rw.Ctr = core.num(sash.glazing.ctr);
                        }

                        g = core.num(sash.glazing.g) < g || !g ? core.num(sash.glazing.g) : g;
                        lt = core.num(sash.glazing.lt) < lt || !lt ? core.num(sash.glazing.lt) : lt;
                    }
                }
            });

            // sumowanie współczynników
            values.forEach(value => {
                isS = isS && value.AfSf > 0 && value.AgSg > 0;
                isU = isU && value.AfUf > 0 && value.AgUg > 0 && value.PsiL > 0;

                Sw += value.AfSf + value.AgSg;
                Uw += value.AfUf + value.AgUg + value.PsiL;
            });

            // zapisanie do konfiguracji
            frame.parameters.uw = isU
                ? Number(Uw) / Number(AfAg)
                      + (Number(this.configuratorsDataService.data.uwCorrection) || 0) || 0
                : null;

            frame.parameters.sw = isS
                ? Number(Sw) / Number(AfAg) || 0
                : null;

            frame.parameters.ug =
                conf.OneGlazing
                && Common.isDefined(conf.Sashes[0])
                && Common.isDefined(conf.Sashes[0].glazing)
                && conf.Sashes[0].glazing.u
                    ? conf.Sashes[0].glazing.u
                    : DoorActiveConfiguration.is(conf)
                      && Common.isDefined(conf.Model)
                      && conf.Model !== null
                      && conf.Model.u > 0
                    ? conf.Model.u
                    : null;

            // zwiększenie Uw dla szprosów wewnętrznych
            if (
                frame.parameters.uw
                && conf.HasMuntins
                && muntins > 0
                && (conf.MuntinsData.type.type === 'internal' || conf.MuntinsData.duplex)
            ) {
                frame.parameters.uw += muntins > 1 ? 0.2 : 0.1;
            }

            const isDiffuser = conf.Accessories.filter(e => e.accessory.dnew !== null);
            const nocorectRw = this.RwO[rw.Rw] - this.F((frame.width * frame.height) / 1000000);

            newrw = {
                Rw: isDiffuser.length
                    ? this.dnewcorection(
                          nocorectRw,
                          (frame.width * frame.height) / 1000000,
                          isDiffuser.length,
                          isDiffuser[0].accessory.dnew
                      )
                    : nocorectRw,
                RwBf: nocorectRw,
                C: -1,
                Ctr: this.Ra2[rw.Rw + rw.Ctr] - this.RwO[rw.Rw],
            };

            frame.parameters.rw =
                newrw.Rw && newrw.Ctr ? newrw.Rw + ' (' + newrw.C + '; ' + newrw.Ctr + ')' : null;
            frame.parameters.lt = lt ? lt : null;
            frame.parameters.g = g ? g : null;
        });
    }

    /**
     * Funcja licząca wagę okna
     * @param {Object} conf konfiguracja pozycji
     */
    private setWindowWeight(conf: WindowActiveConfiguration) {
        if (
            !conf
            || !conf.Frames
            || !conf.Frames.length
            || !conf.Shape
            || !conf.Sashes
            || !conf.Sashes.length
        ) {
            return;
        }

        // waga konstrukcji
        conf.Weight = 0;

        // waga ramy
        for (const frame of conf.Frames) {
            frame.parameters.weight = 0;

            frame.parameters.weight += this.profilesService.getProfilesWeight(
                'frame',
                frame.frame,
                conf,
                {
                    frameId: frame.id,
                }
            );

            // waga wyrównań
            if (conf.Alignments) {
                for (const alignment of conf.Alignments.filter(a => a.frameId === frame.id)) {
                    frame.parameters.weight += this.profilesService.getProfilesWeight(
                        'alignment',
                        alignment,
                        conf,
                        { alignmentId: alignment.id }
                    );
                }
            }

            // waga słupków w ramie
            for (const mullion of conf.Mullions.filter(m => m.frameId === frame.id)) {
                frame.parameters.weight += this.profilesService.getProfilesWeight(
                    'mullion',
                    mullion,
                    conf,
                    {
                        mullionId: mullion.id,
                    }
                );
            }

            // kwatery
            for (const sash of conf.Sashes.filter(s => s.frameId === frame.id)) {
                sash.weight = 0;

                if (sash.handle) {
                    sash.weight += core.num(sash.handle.weight);
                }
                let sashFrameWeight = 0;

                // waga ramy skrzydła
                if (sash.type.type !== 'F') {
                    sashFrameWeight = this.profilesService.getProfilesWeight(
                        'sashFrame',
                        sash.frame,
                        conf,
                        { sashId: sash.id }
                    );
                    sash.weight += sashFrameWeight;
                }

                // waga wyrównań
                for (const alignment of sash.intAlignments) {
                    sash.weight += this.profilesService.getProfilesWeight(
                        'alignment',
                        alignment,
                        conf,
                        { alignmentId: alignment.id }
                    );
                }

                if (Common.isUndefined(sash.intSashes) || sash.intSashes.length === 0) {
                    if (Common.isUndefined(sash.glazing)) {
                        continue;
                    }

                    // waga listwy przyszybowej
                    sash.weight += this.profilesService.getProfilesWeight(
                        'glazingBead',
                        sash.glazingBead,
                        conf,
                        { sashId: sash.id }
                    );

                    // jeżeli podano wagę profilu, liczona jest osobno waga szyb
                    // i osobno waga profilu
                    if (
                        this.config().IccConfig.Configurators.sashAreaWhenNoFrameWeight
                        && sashFrameWeight === 0
                    ) {
                        sash.weight +=
                            (Number(sash.glazing.weight) * sash.rWidth * sash.rHeight) / 1000000 || 0;
                    } else {
                        sash.weight += Number(sash.glazing.weight) * sash.glazingSizes.area || 0;
                    }

                    sash.weight += this.sumWeight(sash.hardware);
                }

                if (Common.isDefined(sash.intSashes) && sash.intSashes.length > 0) {
                    // waga słupków w skrzydle
                    for (const imullion of sash.intMullions) {
                        sash.weight += this.profilesService.getProfilesWeight(
                            'mullion',
                            imullion,
                            conf,
                            { mullionId: imullion.id }
                        );
                    }

                    for (const is of sash.intSashes) {
                        // waga listwy przyszybowej
                        sash.weight += this.profilesService.getProfilesWeight(
                            'glazingBead',
                            is.glazingBead,
                            conf,
                            { sashId: is.id }
                        );

                        // waga wyrównań
                        for (const alignment of is.intAlignments) {
                            sash.weight += this.profilesService.getProfilesWeight(
                                'alignment',
                                alignment,
                                conf,
                                { alignmentId: alignment.id }
                            );
                        }

                        if (Common.isDefined(is.glazing)) {
                            // waga szyb
                            if (
                                this.config().IccConfig.Configurators.sashAreaWhenNoFrameWeight
                                && sashFrameWeight === 0
                            ) {
                                sash.weight +=
                                    (Number(is.glazing.weight) * is.rWidth * is.rHeight) / 1000000 || 0;
                            } else {
                                sash.weight += Number(is.glazing.weight) * is.glazingSizes.area || 0;
                            }
                        }
                    }
                }

                if (SashTypes.PASSIVE.indexOf(sash.type.type) > -1) {
                    let mullion;
                    if (sash.nearMullions.left > -1) {
                        mullion = core.fIdO(conf.Mullions, sash.nearMullions.left);
                    } else if (sash.nearMullions.right > -1) {
                        mullion = core.fIdO(conf.Mullions, sash.nearMullions.right);
                    }

                    if (Common.isDefined(mullion)) {
                        sash.weight += this.profilesService.getProfilesWeight(
                            'mullion',
                            mullion,
                            conf,
                            { mullionId: mullion.id }
                        );
                    }
                }

                frame.parameters.weight += sash.weight;
            }

            // pozostałe wagi
            if (conf.Hinge && conf.Hinge.weight) {
                frame.parameters.weight += parseFloat(conf.Hinge.weight) || 0;
            }
            if (conf.Lock && conf.Lock.weight) {
                frame.parameters.weight += parseFloat(conf.Lock.weight) || 0;
            }
            if (Common.isObject(conf.Fitting)) {
                frame.parameters.weight += core.num(conf.Fitting.weight);
            }

            conf.Weight += frame.parameters.weight;
        }

        // waga poszerzeń
        for (const sideProfile of conf.SideProfiles) {
            conf.Weight += this.profilesService.getProfilesWeight('extension', sideProfile, conf, {
                extensionId: sideProfile.id,
            });
        }

        // waga łączników
        for (const coupling of conf.couplings) {
            conf.Weight += this.profilesService.getProfilesWeight('coupling', coupling, conf, {
                couplingId: coupling.id,
            });
        }

        conf.Weight += this.sumWeight(conf.Accessories);
        conf.Weight += this.sumWeight(conf.SideAccessories.left);
        conf.Weight += this.sumWeight(conf.SideAccessories.right);
        conf.Weight += this.sumWeight(conf.SideAccessories.top);
        conf.Weight += this.sumWeight(conf.SideAccessories.bottom);

        if (Common.isObject(conf.Sill)) {
            conf.Weight += core.num(conf.Sill.weight);
        }

        if (conf.hasRoller) {
            conf.Weight += this.countRollerShutterWeight(conf);
        }
    }

    private sumWeight(arr: IccAccessory[]) {
        let i = 0;
        let weight = 0;

        for (i = 0; i < arr.length; i++) {
            weight += core.num(arr[i].weight);
        }
        return weight;
    }

    private dnewcorection(Ro: number, So: number, Cnt: number, Dne: string | number) {
        //                /  -0,1 * Ro             10      -0,1 * Dne \
        // Rwyp = -10 log( 10            +  Cnt * ---- * 10            )
        //                \                        So                 /
        // Rwyp – wskaźnik wypadkowej izolacyjności akustycznej całości
        // Ro   - wskaźnik wypadkowej izolacyjności akustycznej okna bez nawiewnika
        // So   - pole powierzchni okna
        // Dne  - wskaźnik izolacyjności nawiewnika
        return (
            Math.round(
                -10
                    * Math.log10(
                        Math.pow(10, -0.1 * Ro) + Cnt * (10 / So) * Math.pow(10, -0.1 * Number(Dne))
                    )
                    * 100
            ) / 100
        );
    }

    private layoutsParameters(frame: Frame, conf: WindowActiveConfiguration) {
        if (
            Common.isObject(conf.System)
            && Common.isDefined(conf.System.id)
            && Common.isArray(conf.Sashes)
        ) {
            const area = (frame.width * frame.height) / 1000000;
            const width = frame.width;
            const height = frame.height;
            const constructionType = this.getConstructionType(conf);

            let parameters: IccParameters[] = (
                this.configuratorsDataService.data.layoutsParameters || []
            ).filter(
                e =>
                    e.window_lines.indexOf(conf.System.id) > -1
                    && ((e.area !== null && e.area * 1.5 >= area)
                        || (e.width !== null
                            && e.height !== null
                            && e.width * 1.5 >= width
                            && e.height * 1.5 >= height))
                    && e.construction_type === constructionType
            );

            if (!parameters.length) {
                return;
            }

            parameters = parameters.sort((a, b) => {
                const areaA = Number(a.area) || (Number(a.width) * Number(a.height)) / 1000000;
                const areaB = Number(b.area) || (Number(b.width) * Number(b.height)) / 1000000;
                return areaA - areaB;
            });

            const airPermeability = parameters.filter(e => e.air_permeability);

            const resistanceToWindLoad = parameters.filter(e => e.resistance_to_wind_load);

            const waterProof = parameters.filter(e => e.waterproof);

            const lab = parameters.filter(e => e.lab);

            frame.parameters.ap = Common.isDefined(airPermeability[0])
                ? Number(airPermeability[airPermeability.length - 1].air_permeability)
                : null;
            frame.parameters.wl = Common.isDefined(resistanceToWindLoad[0])
                ? resistanceToWindLoad[resistanceToWindLoad.length - 1].resistance_to_wind_load
                : null;
            frame.parameters.wp = Common.isDefined(waterProof[0])
                ? waterProof[waterProof.length - 1].waterproof
                : null;
            frame.parameters.lab = Common.isDefined(lab[0]) ? lab[lab.length - 1].lab : null;
        }
    }

    private findPsi(
        warmEdge: Partial<IccWarmEdge>,
        system: { id: any },
        glazing: { glazing_count?: number | string }
    ) {
        let elem;

        if (!Common.isObject(warmEdge) || !Common.isArray(warmEdge.prices)) {
            return NaN;
        }

        const matchedPrices = warmEdge.prices.filter(
            e => e.count === Number(glazing.glazing_count)
        );
        if (!matchedPrices.length) {
            return NaN;
        }

        elem = matchedPrices.filter(e => e.window_line_id === system.id);
        if (elem.length) {
            return elem[0].psi * 1;
        }

        elem = matchedPrices.filter(e => !e.window_line_id);
        if (elem.length) {
            return elem[0].psi * 1;
        }

        return matchedPrices[0].psi * 1;
    }

    /**
     * Funkcja licząca wagę rolety
     * @param {Object} conf konfiguracja pozycji
     * @return {Number} Waga
     */
    private countRollerShutterWeight(
        conf: RollerShutterActiveConfiguration | WindowActiveConfiguration
    ) {
        let weight = 0;

        const boxPrices = this.configuratorsDataService.data.rollerShutterBoxSizesPrices;
        const roller = conf.RollerShutter;

        if (
            !Common.isObject(roller.system)
            || !Common.isDefined(boxPrices)
            || !Common.isDefined(boxPrices[roller.system.id])
            || !Common.isDefined(roller.realBoxHeight)
            || !Common.isArray(roller.shutters)
        ) {
            return weight;
        }

        // masa skrzynki
        const systemPrices = boxPrices[roller.system.id];
        let boxWeightMb = 0;
        let maxRollerHeight = 0;
        for (const minHeight in systemPrices) {
            if (
                systemPrices.hasOwnProperty(minHeight)
                && Number(minHeight) <= roller.realBoxHeight
                && Number(minHeight) >= maxRollerHeight
            ) {
                maxRollerHeight = Number(minHeight);
                boxWeightMb = systemPrices[minHeight].box_weight;
            }
        }
        const boxWeight = (core.num(boxWeightMb) * conf.Width) / 1000;

        let profileWeight = 0;
        let driveWeight = 0;
        let guideWeight = 0;
        let profileWeightForDrive = 0;
        let profileWidthForDrive = 0;
        let accessoryWeight = 0;

        roller.shutters.forEach((shutter, index) => {
            // masa rolki
            const shutterProfileWeight = this.getRollerWeight(roller, shutter);
            profileWeight += shutterProfileWeight;
            profileWeightForDrive += shutterProfileWeight;
            profileWidthForDrive += shutter.realWidth;

            // masa prowadnicy przy moskitierze
            if (shutter.mosquito) {
                guideWeight +=
                    (2 * core.num(roller.system.mosquito_weight) * shutter.realHeight) / 1000;
            } else {
                // masa prowadnicy dwustronnej
                if (shutter.commonRail) {
                    guideWeight +=
                        (core.num(roller.system.double_side_weight) * shutter.realHeight) / 1000;
                } else {
                    // masa prowadnicy jednostronnej
                    guideWeight +=
                        (core.num(roller.system.single_side_weight) * shutter.realHeight) / 1000;
                }

                const nextShutter = roller.shutters[index + 1];
                // czy prowadnica jest wspólna między obecną a następną rolką
                if (Common.isObject(nextShutter) && nextShutter.commonRail) {
                    return;
                }

                // masa prowadnicy jednostronnej
                guideWeight +=
                    (core.num(roller.system.single_side_weight) * shutter.realHeight) / 1000;
            }

            // masa silnika
            if (Common.isObject(roller.drive) && Common.isArray(roller.drive.prices)) {
                const drive = roller.drive.prices.find(
                    (element: { from: number; to: number }) =>
                        element.from <= profileWidthForDrive && element.to >= profileWeightForDrive
                );
                if (Common.isObject(drive)) {
                    driveWeight += core.num(drive.weight);
                }
            }
            profileWeightForDrive = 0;
            profileWidthForDrive = 0;
        });
        if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
            accessoryWeight += this.sumWeight(conf.Accessories);
        }
        weight = boxWeight + profileWeight + driveWeight + guideWeight + accessoryWeight;
        return weight;
    }

    private getConstructionType(conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        let constructionType: string;

        if (conf.type === 'door') {
            constructionType = 'door' + conf.Sashes.length;
        } else if (conf.System.confType === 'window' && conf.Balcony) {
            constructionType = 'balcony' + conf.Sashes.length;
        } else if (conf.System.confType === 'window') {
            constructionType = 'window' + conf.Sashes.length;
        } else if (conf.System.confType === 'hs') {
            if (
                conf.Sashes.length === 2
                && conf.Sashes[0].type.type === 'HS'
                && conf.Sashes[1].type.type === 'HS'
            ) {
                constructionType = 'hsd';
            } else if (
                conf.Sashes.length === 2
                && ((conf.Sashes[0].type.type === 'HS' && conf.Sashes[1].type.type !== 'HS')
                    || (conf.Sashes[0].type.type !== 'HS' && conf.Sashes[1].type.type === 'HS'))
            ) {
                constructionType = 'hsa';
            } else if (
                conf.Sashes.length === 4
                && conf.Sashes[0].type.type !== 'HS'
                && conf.Sashes[1].type.type === 'HS'
                && conf.Sashes[2].type.type === 'HS'
                && conf.Sashes[3].type.type !== 'HS'
            ) {
                constructionType = 'hsc';
            }
        }

        return constructionType;
    }

    private F(a: number) {
        if (a <= 2.7) {
            return 0;
        } else if (a > 2.7 && a <= 3.6) {
            return 1;
        } else if (a > 3.6 && a <= 4.6) {
            return 2;
        } else {
            return 3;
        }
    }

    private countCoupledWindowWeight(conf: CoupledWindowActiveConfiguration) {
        let weight = 0;
        weight = conf.windows.reduce(
            (sum, window) => window.details.parameters.weight + sum,
            weight
        );
        weight = conf.couplings.reduce(
            (sum, coupling) =>
                this.profilesService.getProfilesWeight('coupling', coupling, conf, {
                    couplingId: coupling.id,
                }) + sum,
            weight
        );
        weight = conf.sideProfiles.reduce(
            (sum, sideProfile) =>
                this.profilesService.getProfilesWeight('extension', sideProfile, conf, {
                    extensionId: sideProfile.id,
                }) + sum,
            weight
        );
        weight = conf.rollerShutters.reduce(
            (sum, rollerShutter) => rollerShutter.details.parameters.weight + sum,
            weight
        );
        return weight;
    }
}
