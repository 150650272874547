import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, ModalService, ConfiguratorOptions } from '@icc/configurator/shared';
import { iccListTab, iccSelect, iccListItem } from '@icc/configurator/ui';
import { StepComponent } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { Observable, from, Subscription, combineLatest } from 'rxjs';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import {
    ConfigurationsService,
    GlazingBeadsService,
    WindowActiveConfiguration,
    APP_CONFIG,
    AppConfigFactory,
} from '@icc/common';
import { IccFilling } from '@icc/common/data-types';
import { BrowserGlazingBeadsService } from '@icc/legacy/configurator/steps/window/glazings/glazing-beads.service';
import { WarmEdgesService } from '@icc/legacy/configurator/steps/window/glazings/warm-edges.service';
import { GlazingBeadColorsService } from '@icc/legacy/configurator/steps/window/glazings/glazing-bead-colors.service';
import { SealColorsService } from '@icc/legacy/configurator/steps/window/glazings/seal-colors.service';
import { SiliconeColorsService } from '@icc/legacy/configurator/steps/window/glazings/silicone-colors.service';
import { GlazingLayoutPageComponent } from '../glazing-layout-page/glazing-layout-page.component';
import { FillingsFacade } from '../glazings/fillings.facade';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { map, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'icc-glazings',
    template: require('./glazings.component.html'),
    styles: [require('./glazings.component.scss')],
})
export class GlazingsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('WINDOW|Szyby i wypełnienia');
    static stepIcon = {
        fontSet: 'icc-icons',
        fontIcon: 'icc-icons-glazing',
    };

    public configurator = 'window';
    public stepId = 'glazings';
    public title = _('WINDOW|Szyby i wypełnienia');
    public options = [
        {
            title: _('WINDOW|Wypełnienie per kwatera'),
            component: GlazingLayoutPageComponent,
        },
    ];

    public glazingSpacer$ = this.windowFacade.selectedGlazingSpacer$;
    public glazingBead$ = this.windowFacade.selectedGlazingBead$;
    public glazingBeadInSashes$ = this.windowFacade.selectedGlazingBeadInSashes$;
    public glazingBeadInFix$ = this.windowFacade.selectedGlazingBeadInFixed$;
    public glazingBeadColor$ = this.windowFacade.selectedGlazingBeadColor$;
    public sealColor$ = this.windowFacade.selectedSealColor$;
    public siliconeColor$ = this.windowFacade.selectedSiliconeColor$;

    availableGlazingBeadColor$ = this.windowFacade.system$.pipe(
        map(
            system =>
                system.type !== 'wood'
                && this.config().IccConfig.Configurators.glazingBeadsColorSelect
        )
    );
    availableSealColor$ = this.windowFacade.system$.pipe(
        map(
            system =>
                this.config().IccConfig.Configurators.sealColorSelect
                || (this.config().IccConfig.Configurators.siliconeColor && system.type === 'wood')
        )
    );
    availableSiliconeColor$ = this.windowFacade.system$.pipe(
        map(system => this.config().IccConfig.Configurators.siliconeColor && system.type === 'wood')
    );
    availableGlazingBead$ = this.windowFacade.oneGlazingBead$;
    availableGlazingBeadSashes$ = this.windowFacade.oneGlazingBeadSashes$;
    availableGlazingBeadFix$ = this.windowFacade.oneGlazingBeadFix$;

    hasWarmEdge$ = this.windowFacade.hasWarmEdge$;

    oneFillingBox?: ReturnType<FillingsFacade['fillingBox']>;
    doorActiveFillingBox?: ReturnType<FillingsFacade['fillingBox']>;
    doorPassiveFillingBox?: ReturnType<FillingsFacade['fillingBox']>;
    windowFillingBox?: ReturnType<FillingsFacade['fillingBox']>;

    private subscriptions: Subscription[] = [];

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.type !== 'door' || (!conf.System || !Boolean(conf.System.door_type));

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private windowFacade: WindowFacade,
        public fillingsFacade: FillingsFacade,
        private modalService: ModalService,
        private fillingsService: FillingsService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private glazingBeadsService: BrowserGlazingBeadsService,
        private warmEdgesService: WarmEdgesService,
        private glazingBeadColorsService: GlazingBeadColorsService,
        private sealColorsService: SealColorsService,
        private siliconeColorsService: SiliconeColorsService
    ) {
        super();
    }

    openModalDecoPanelOptions(field: string | undefined | ActiveSash) {
        if (DoorActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.fillingsService.openModalPanelOptions(
                field,
                this.configurationsService.conf.Current
            );
        }
    }

    openModalFilling(field: string | undefined | ActiveSash, sash?: ActiveSash) {
        this.fillingsService.openModal(
            field,
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default,
            sash
        );
    }

    openModalGlazingBead(field: 'sashes' | 'fix' | undefined | ActiveSash, sash?: ActiveSash) {
        this.glazingBeadsService.openModalGlazingBead(
            this.configurationsService.conf.Current,
            field,
            sash
        );
    }

    openModalWarmEdge() {
        this.warmEdgesService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    openModalBeadColor() {
        this.glazingBeadColorsService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    /**
     * Funkcja otwierajaca okno modal z kolorem uszczelek
     */
    openModalSealColor() {
        this.sealColorsService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    /**
     * Funkcja otwierajaca okno modal z kolorem uszczelek
     */
    openModalSiliconeColor() {
        this.siliconeColorsService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.fillingsFacade.oneFillingBox$.subscribe(box => {
                this.oneFillingBox = box;
            })
        );
        this.subscriptions.push(
            this.fillingsFacade.doorActiveFillingBox$.subscribe(box => {
                this.doorActiveFillingBox = box;
            })
        );
        this.subscriptions.push(
            this.fillingsFacade.doorPassiveFillingBox$.subscribe(box => {
                this.doorPassiveFillingBox = box;
            })
        );
        this.subscriptions.push(
            this.fillingsFacade.windowFillingBox$.subscribe(box => {
                this.windowFillingBox = box;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
