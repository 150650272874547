import * as angular from 'angular';

import ComplementaryGoodsCtrl from './ComplementaryGoodsCtrl';
import ModalComplementaryGoodsCtrl from './ModalComplementaryGoodsCtrl';
import ModalComplementaryGoodsImageCtrl from './ModalComplementaryGoodsImageCtrl';
import { ComplementaryGoodsService } from './complementary-goods.service';
import { WindowSillsComponent } from './window-sills/window-sills.component';
import { ModalColorsCtrl } from './colors/ModalColorsCtrl';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WindowSillsService } from './window-sills/window-sills.service';

export default angular
    .module('icc.configurator.steps.complementary_goods', [])
    .controller('ComplementaryGoodsCtrl', ComplementaryGoodsCtrl)
    .controller('ModalComplementaryGoodsCtrl', ModalComplementaryGoodsCtrl)
    .controller('ModalComplementaryGoodsImageCtrl', ModalComplementaryGoodsImageCtrl)
    .controller('ModalColorsCtrl', ModalColorsCtrl)
    .component('windowSills', WindowSillsComponent)
    .factory('ComplementaryGoodsService', downgradeInjectable(ComplementaryGoodsService))
    .factory('WindowSillsService', downgradeInjectable(WindowSillsService)).name;
