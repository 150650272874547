import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, NgModuleFactoryLoader, SystemJsNgModuleLoader, Injectable, Type, Inject, Optional } from '@angular/core';

import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { IccDrawModule } from '@icc/draw';
import { ConfiguratorModule } from './configurator/configurator.module';
import { PriceModule } from '@icc/price/b2b';
import { PanelModule } from './panel/panel.module';
import { ConfiguratorPriceModule } from './price/price.module';
import { SyncModule } from './sync/sync.module';
import { ColorsModule } from '@icc/common/colors/colors.module';
import { LicenseModule } from './license/license.module';
import { DependenciesModule } from './dependencies/dependencies.module';
import { BrowserTranslateService } from './app/translate.service';
import { TranslateService } from '@icc/common/translate.service';
import { StateService } from '@icc/common/state.service';
import { TokenService } from './authorization/token.service';
import { AuthService } from './authorization/auth.service';
import { LangService } from './app/lang.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { CONFIG } from './app/config';
import {
    Location,
    LocationStrategy,
    HashLocationStrategy,
    PathLocationStrategy,
    PlatformLocation,
    APP_BASE_HREF,
} from '@angular/common';
import { logger } from '@icc/common/helpers';
import { BrowserConfiguratorsDataService } from './configurator/configurators-data.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';

import { EventBusService } from '@icc/common/event-bus.service';
import { NewConfiguratorModule } from './new-configurator/new-configurator.module';

import { AccessoriesModule } from '@icc/legacy/configurator/steps/window/accessories/accessories.module';
import { TimeLimitModule } from '@icc/common/time-limit/time-limit.module';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        logger.error(err.originalError || err);
    }
}

export function configFactory(): AppConfigFactory {
    return ()  => CONFIG;
}

export function locationStrategyFactory(_platformLocation: PlatformLocation, _baseHref?: string): LocationStrategy {
    return CONFIG.isPhonegap ? new HashLocationStrategy(_platformLocation, _baseHref) : new PathLocationStrategy(_platformLocation, _baseHref);
}

@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        UpgradeModule,
        HttpClientModule,
        IccDrawModule,
        ConfiguratorModule,
        PanelModule,
        ConfiguratorPriceModule,
        SyncModule,
        ColorsModule,
        LicenseModule,
        DependenciesModule,
        NewConfiguratorModule,
        AccessoriesModule,
        TimeLimitModule,
    ],
    providers: [
        BrowserTranslateService,
        { provide: TranslateService, useExisting: BrowserTranslateService },
        StateService,
        TokenService,
        AuthService,
        LangService,
        EventBusService,
        { provide: APP_CONFIG, useFactory: configFactory },
        Location,
        {
            provide: LocationStrategy,
            useFactory: locationStrategyFactory,
            deps: [PlatformLocation, [new Inject(APP_BASE_HREF), new Optional()],]
        },
        { provide: ErrorHandler, useClass: RavenErrorHandler },
        BrowserConfiguratorsDataService,
        { provide: ConfiguratorsDataService, useExisting: BrowserConfiguratorsDataService },
        { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    ],
})
export class AppModule {
    constructor() {}

    ngDoBootstrap(): void {}
}
