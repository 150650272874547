import { logger, core } from '../helpers';
import { Common } from '../Common';

export class OfferGroupCodeService {
    static mapping = {
        window: {
            sign: '01',
            code: 'WIN',
            schema: true,
        },
        hs: {
            sign: '02',
            code: 'HS',
            schema: true,
        },
        door: {
            sign: '03',
            code: 'DOOR',
            schema: true,
        },
        folding_door: {
            sign: '02',
            code: 'FST',
            schema: true,
        },
        sliding_door: {
            sign: '02',
            code: 'ST',
            schema: true,
        },
        garage_door: {
            sign: '04',
            code: 'GRG',
            schema: true,
        },
        roller_shutter: {
            sign: '05',
            code: 'RSH',
            schema: true,
        },
        mosquito: {
            sign: '06',
            code: 'MSQ',
            schema: false,
        },
        winter_garden: {
            sign: '07',
            code: 'WG',
            schema: false,
        },
        awning: {
            sign: '08',
            code: 'AWN',
            schema: false,
        },
        complementary_goods: {
            sign: '09',
            code: 'CG',
            schema: false,
        },
        glazing: {
            sign: '10',
            code: 'GLS',
            schema: false,
        },
        accessory: {
            sign: '11',
            code: 'ACC',
            schema: false,
        },
        additional: {
            sign: '12',
            code: 'ADD',
            schema: false,
        },
        transport_cost: {
            sign: '',
            code: 'TRS',
            schema: false,
        },
        custom: {
            sign: '',
            code: 'NST',
            schema: false,
        },
        colors_cost: {
            sign: '',
            code: 'CRS',
            schema: false,
        },
        colors_waste_cost: {
            sign: '',
            code: 'CRW',
            schema: false,
        },
        coupled_window: {
            sign: '',
            code: 'CWIN',
            schema: false,
        },
    };

    /**
     * Generowanie kodu na podstawie schematu
     * @param  {string} groupCode     Początek kodu
     * @param  {object} configuration Konfiguracja pozycji
     * @param  {string} type          Typ konfiguracji
     * @return {string}               Kod grupy
     */
    static generateGroupCodeFromSchema(groupCode, configuration, type, schema) {
        if (Common.isDefined(schema[type])) {
            groupCode = groupCode.substr(0, groupCode.length - 1);
            let groupCodeSchema = null;
            if (Common.isArray(schema[type])) {
                groupCodeSchema = core.copy(schema[type]);
            } else {
                groupCodeSchema = core.copy(
                    schema[schema[type]]
                );
            }
            for (let i = 0; i < groupCodeSchema.length; i++) {
                if (groupCodeSchema[i] === 'configuration.System.id') {
                    groupCode += '_' + (configuration.System.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.frame.alushell.id') {
                    groupCode +=
                        '_'
                        + ((configuration.Colors.frame.alushell.id && configuration.HasAlushell)
                            || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.frame.core.id') {
                    groupCode += '_' + (configuration.Colors.frame.core.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.frame.inner.id') {
                    groupCode += '_' + (configuration.Colors.frame.inner.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.frame.outer.id') {
                    groupCode += '_' + (configuration.Colors.frame.outer.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.sash.alushell.id') {
                    groupCode +=
                        '_'
                        + ((configuration.Colors.sash.alushell.id && configuration.HasAlushell)
                            || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.sash.core.id') {
                    groupCode += '_' + (configuration.Colors.sash.core.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.sash.inner.id') {
                    groupCode += '_' + (configuration.Colors.sash.inner.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.Colors.sash.outer.id') {
                    groupCode += '_' + (configuration.Colors.sash.outer.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.RollerShutter.type.id') {
                    groupCode += '_' + (configuration.RollerShutter.type.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.RollerShutter.system.id') {
                    groupCode += '_' + (configuration.RollerShutter.system.id || 0);
                } else if (groupCodeSchema[i] === 'configuration.HasAlushell') {
                    groupCode += '_' + (~~configuration.HasAlushell || 0);
                }

                else if (groupCodeSchema[i] === 'BigSizeMontages') {
                    groupCode += '_BS' + (OfferGroupCodeService.getSizeType(configuration) ? 1 : 0);
                }

                else if (
                    groupCodeSchema[i] === 'configuration.Wood.id'
                    && configuration.System.type === 'wood'
                ) {
                    groupCode += '_' + (configuration.Wood.id || 0);
                }
            }
        }
        return groupCode;
    }

    /**
     * Generowanie kodu grupy dla pozycji
     * @memberof PositionsFactory
     * @param {object} [params] Objekt ozycji
     * @returns {String}        Kod grupy pozycji
     */
    static generateGroupCode(params, IccConfig, schema?) {
        let groupCode = '';
        let map = {
            sign: '',
            code: '',
            schema: false,
        };
        let sign = '';

        // kiedy szybko się klika może pojawi się string
        if (typeof params.configuration === 'string') {
            params.configuration = core.parseJson(params.configuration);
        }

        if (OfferGroupCodeService.mapping[params.configuration.type]) {
            map = OfferGroupCodeService.mapping[params.configuration.type];
            if (
                IccConfig.Offer.singlePosGroup
                && !schema
                && !([
                    'additional',
                    'transport_cost',
                    'colors_cost',
                    'colors_waste_cost',
                    'custom',
                    'coupled_window',
                ] as any).includes(params.configuration.type)
            ) {
                map = OfferGroupCodeService.mapping.window;
            }
        }

        if (IccConfig.Offer.notSortedGroups) {
            sign = '00';
            if (params.configuration.type === 'custom') {
                sign = '';
            }
        } else {
            sign = map.sign;
        }

        if (sign.length > 0) {
            groupCode += sign + '_';
        }
        groupCode += map.code;

        if (map.schema) {
            groupCode += '_';
            if (IccConfig.Offer.groupCodeSchema || schema) {
                groupCode = OfferGroupCodeService.generateGroupCodeFromSchema(
                    groupCode,
                    params.configuration,
                    params.configuration.type,
                    schema || IccConfig.Offer.groupCodeSchema
                );
            }
        }

        if (params.configuration.type === 'coupled_window') {
            const firstCode = params.configuration.windows[0].groupCode;
            if (params.configuration.windows.every(w => w.groupCode === firstCode)) {
                groupCode = firstCode;
            }
        }

        return groupCode;
    }

    static getPositionTypeFromGroupCode(groupCode: string): string {
        const groupCodeParts = groupCode.split('_');
        let code = '';
        if (groupCodeParts.length > 1) {
            code = groupCodeParts[1];
        } else if (groupCodeParts.length === 1) {
            code = groupCodeParts[0];
        }
        const results = Object.keys(OfferGroupCodeService.mapping).filter(
            type => OfferGroupCodeService.mapping[type].code === code
        );
        if (results.length > 0) {
            return results[0];
        } else {
            return null;
        }
    }

    static getSizeType(configuration) {
        if(configuration.type === "window"){
            return (configuration.Weight > 100)
        } else if (configuration.type === "roller_shutter") {
            return (configuration.Width > 2000)
        }
    }

}
