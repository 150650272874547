import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReturnButton } from '@icc/configurator/shared';
import { Issue } from '@icc/helpers';
@Component({
  selector: 'icc-client-info-dialog',
  template: require('./client-info-dialog.component.html'),
  styles: [require('./client-info-dialog.component.scss')]
})
export class ClientInfoDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ClientInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            issues: Issue[],
            actions: [],
            message: string;
            shopMessage: boolean;
            configuration;
            returnButton?: ReturnButton;
            configurationShortcode?: string;
        }
    ) { }

    clientInfoFormEnabled = false;
    vats = [
        {'value': 0, 'viewValue': 'netto'},
        {'value': 0.08, 'viewValue': '8%'},
        {'value': 0.23, 'viewValue': '23%'}
    ]

    configurationShortcode: string;
    returnButton: ReturnButton;
    configuration;
    positionName: string;
    offerDurationInDays: number;
    name: string;
    fullName: string;
    phoneNumber: string;
    address: string;
    city: string;
    postCode: string;
    selectedVat: number;
    nettoPrice: number;
    amount: number;
    discountPercent: number;
    additionalServicesPrice: number;
    comment: string;
    currency = {
        auto_update: false,
        currency: "PLN",
        currency_name: "Polski Złoty",
        decimals: ",",
        deleted: null,
        id: 23,
        nbp_value: null,
        negative: "",
        position: "after",
        symbol: "zł",
        thousands: " ",
        value: 1,
        zero: ""
    }

    ngOnInit(): void {
        this.configurationShortcode = this.data.configurationShortcode;
        this.configuration = this.data.configuration
        this.returnButton = this.data.returnButton;
        this.positionName = this.configuration.System && this.configuration.System.name;
        this.discountPercent = 0;
        this.selectedVat = 0;
        this.amount = 1;
        this.nettoPrice = this.configuration.Price || 0;
        this.offerDurationInDays = 14;
        this.comment = 'Oferta ważna tylko i wyłącznie w przypadku pełnego zamówienia kompletu drzwiowego wraz z montażem';
    }

    getNettoPrice(): number {
        return this.amount * this.nettoPrice;
    }

    getFullNettoPrice(): number {
        const nettoPrice = this.getNettoPrice();
        return  (nettoPrice - (nettoPrice * ((this.discountPercent / 100) || 0))) + (this.additionalServicesPrice || 0);
    }

    getVatPrice(): number {
        return this.selectedVat * this.getFullNettoPrice();
    }

    getFullPrice(): number {
        return this.getVatPrice() + this.getFullNettoPrice();
    }

    getVatByValue(value) {
        const vat = this.vats.find(vat => vat.value === value)
        return  vat && vat.viewValue || null;
    }

    getFormData() {
        return {
            offerDurationInDays: this.offerDurationInDays,
            name: this.name,
            fullName: this.fullName,
            phoneNumber: this.phoneNumber,
            address: this.address,
            city: this.city,
            postCode: this.postCode,
            selectedVat: this.getVatByValue(this.selectedVat),
            vatPrice: this.getVatPrice(),
            nettoPriceOne: this.nettoPrice,
            nettoPrice: this.getNettoPrice(),
            fullNettoPrice: this.getFullNettoPrice(),
            amount: this.amount,
            discountPercent: this.discountPercent,
            additionalServicesPrice: this.additionalServicesPrice,
            comment: this.comment,
            fullPrice: this.getFullPrice(),
            positionName: this.positionName
        }
    }

    closeWithData() {
        if (this.clientInfoFormEnabled) {
            this.dialogRef.close({action: 'print', data: this.getFormData(), returnButton: this.returnButton});
        } else {
            this.dialogRef.close({action: 'print', returnButton: this.returnButton})
        }
    }
}
