import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewConfiguratorContainerComponent } from './new-configurator-container/new-configurator-container.component';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    imports: [CommonModule, StoreModule.forRoot({}, { runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false }}), BrowserAnimationsModule],
    declarations: [NewConfiguratorContainerComponent],
    exports: [],
    providers: [],
    entryComponents: [NewConfiguratorContainerComponent]
})
export class NewConfiguratorModule {}
