import { Common } from '../Common';
import { Injectable } from '@angular/core';
import { IccSideColors, IccColors } from '@icc/common/data-types';

@Injectable()
export class ColorsDefaultsService {
    /**
     * Domyslne zestawy dla typów kolorów.
     * @type {Object}
     */
    private defaultColorsTypes = {
        White: {
            outer: 'NONE',
            inner: 'NONE',
            core: 'WHITE',
        },
        Cream: {
            outer: 'NONE',
            inner: 'NONE',
            core: 'CREAM',
        },
        Outer: {
            outer: 'COLOR',
            inner: 'NONE',
            core: 'WHITE',
        },
        Inner: {
            outer: 'NONE',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Bilateral: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Bicolor: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        '3D': {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Transparent: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Opaque: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
    };

    getDefaultColorType(set, side, coreColorInBilateralAlwaysAsDefault = false) {
        if (this.defaultColorsTypes[set] && this.defaultColorsTypes[set][side]) {
            if (
                (set === 'Bilateral' || set === 'Bicolor')
                && side === 'core'
                && coreColorInBilateralAlwaysAsDefault
            ) {
                return 'COLOR';
            }
            return this.defaultColorsTypes[set][side];
        }
        return null;
    }

    /**
     * Zwraca rodzaj koloru ustawionego po danej stronie konstrukcji.
     * @memberof ColorsService
     * @param  {object} colors Obiekt z kolorami
     * @param  {string} side   Miejsce konfiguracji
     * @return {string}        Rodzaj koloru
     */
    getColorValue(colors: IccColors, side: keyof IccColors['frame'], onlyFrame = false) {
        let value = 'NONE';
        if (colors.frame && colors.frame[side] && (onlyFrame || colors.sash && colors.sash[side])) {
            if (colors.frame[side].type === 'white' && (onlyFrame || colors.sash[side].type === 'white')) {
                value = 'WHITE';
            } else if (colors.frame[side].type === 'cream' && (onlyFrame || colors.sash[side].type === 'cream')) {
                value = 'CREAM';
            } else if (
                Common.isDefined(colors.frame[side].id)
                && colors.frame[side].type !== 'white'
                && colors.frame[side].type !== 'cream'
                && (onlyFrame || 
                    Common.isDefined(colors.sash[side].id)
                    && colors.sash[side].type !== 'white'
                    && colors.sash[side].type !== 'cream'
                    && colors.frame[side].id === colors.sash[side].id
                    && colors.frame[side].RAL === colors.sash[side].RAL
                )
            ) {
                value = 'COLOR';
            } else if (
                Common.isDefined(colors.frame[side].id)
                || (!onlyFrame && Common.isDefined(colors.sash[side].id))
            ) {
                value = 'BICOLOR';
            }
        }
        return value;
    }

    /**
     * Zwraca typ kolorów dla kolorów
     *
     * zew/wew
     * @param  {object} colors Kolory
     * @param  {number} sashesCount Liczba skrzydeł
     * @return {string}        Typ kolorów
     */
    getColorTypeForColors(colors: IccColors, onlyFrame = false) {
        let colorType = 'Bicolor';
        if (Common.isObject(colors)) {
            const outer = this.getColorValue(colors, 'outer', onlyFrame);
            const inner = this.getColorValue(colors, 'inner', onlyFrame);
            const core = this.getColorValue(colors, 'core', onlyFrame);

            // Biały
            if (
                this.getDefaultColorType('White', 'outer') === outer
                && this.getDefaultColorType('White', 'inner') === inner
                && this.getDefaultColorType('White', 'core') === core
            ) {
                colorType = 'White';
                // Krem w masie
            } else if (
                this.getDefaultColorType('Cream', 'outer') === outer
                && this.getDefaultColorType('Cream', 'inner') === inner
                && this.getDefaultColorType('Cream', 'core') === core
            ) {
                colorType = 'Cream';
                // zewnętrzny
            } else if (
                (['WHITE', this.getDefaultColorType('Outer', 'outer')] as any).includes(outer)
                && this.getDefaultColorType('Outer', 'inner') === inner
                && this.getDefaultColorType('Outer', 'core') === core
            ) {
                colorType = 'Outer';
                // wewnętrzny
            } else if (
                this.getDefaultColorType('Inner', 'outer') === outer
                && (['WHITE', this.getDefaultColorType('Inner', 'inner')] as any).includes(inner)
                && this.getDefaultColorType('Inner', 'core') === core
            ) {
                colorType = 'Inner';
                // Kolor obustronny
            } else if (
                this.getDefaultColorType('Bilateral', 'outer') === outer
                && this.getDefaultColorType('Bilateral', 'inner') === inner
                && colors.frame.outer.id === colors.frame.inner.id
            ) {
                colorType = 'Bilateral';
                // Bicolor
            } else {
                colorType = 'Bicolor';
            }
        }
        return colorType;
    }

    /**
     * Ustawia układ kolorów na podstawie ustawionych kolorów, jeśli wybrany nie pasuje.
     * @private
     * @param  {Object} colors      Kolory
     * @param  {Cbject} sashesCount Count skrzydeł
     * @return {Array}              Pary
     */
    getColorPairs(colors) {
        let pairs = [];
        const colorType = this.getColorTypeForColors(colors);
        switch (colorType) {
            case 'White':
                pairs = ['b_b'];
                if (Common.isArray(colors.frame.core.groups)) {
                    for (let i = 0; i < colors.frame.core.groups.length; i++) {
                        if (
                            pairs.indexOf(
                                colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                            )
                        ) {
                            pairs.push(
                                colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                            );
                        }
                    }
                }
                break;
            case 'Cream':
                pairs = ['c_c'];
                if (Common.isArray(colors.frame.core.groups)) {
                    for (let i = 0; i < colors.frame.core.groups.length; i++) {
                        if (
                            pairs.indexOf(
                                colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                            )
                        ) {
                            pairs.push(
                                colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                            );
                        }
                    }
                }
                break;
            case 'Outer':
                if (Common.isArray(colors.frame.outer.groups)) {
                    for (let i = 0; i < colors.frame.outer.groups.length; i++) {
                        if (pairs.indexOf(colors.frame.outer.groups[i] + '_b')) {
                            pairs.push(colors.frame.outer.groups[i] + '_b');
                        }
                    }
                }
                break;
            case 'Inner':
                if (Common.isArray(colors.frame.inner.groups)) {
                    for (let i = 0; i < colors.frame.inner.groups.length; i++) {
                        if (pairs.indexOf('b_' + colors.frame.inner.groups[i])) {
                            pairs.push('b_' + colors.frame.inner.groups[i]);
                        }
                    }
                }
                break;
            case 'Bilateral':
                if (
                    Common.isArray(colors.frame.outer.groups)
                    && Common.isArray(colors.frame.inner.groups)
                ) {
                    for (let i = 0; i < colors.frame.outer.groups.length; i++) {
                        for (let j = 0; j < colors.frame.inner.groups.length; j++) {
                            if (
                                pairs.indexOf(
                                    colors.frame.outer.groups[i]
                                        + '_'
                                        + colors.frame.inner.groups[j]
                                ) === -1
                            ) {
                                pairs.push(
                                    colors.frame.outer.groups[i]
                                        + '_'
                                        + colors.frame.inner.groups[j]
                                );
                            }
                        }
                    }
                }
                break;
            case 'Bicolor':
                if (
                    Common.isArray(colors.frame.outer.groups)
                    && Common.isArray(colors.frame.inner.groups)
                ) {
                    for (let i = 0; i < colors.frame.outer.groups.length; i++) {
                        for (let j = 0; j < colors.frame.inner.groups.length; j++) {
                            if (
                                pairs.indexOf(
                                    colors.frame.outer.groups[i]
                                        + '__'
                                        + colors.frame.inner.groups[j]
                                ) === -1
                            ) {
                                pairs.push(
                                    colors.frame.outer.groups[i]
                                        + '__'
                                        + colors.frame.inner.groups[j]
                                );
                            }
                        }
                    }
                }
                break;
        }
        return pairs;
    }
}
