import 'angular-ui-bootstrap';
import { core } from '@icc/common/helpers';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';

export class ModalProfileColorsCtrl {
    config = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
            && this.selectedProfile.selectedColor.frame
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        ColorType: 'White',
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    configDefault = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
            && this.selectedProfile.selectedColor.frame
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        ColorType: 'White',
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    hasCream = false;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private profile,
        private profilesPrices,
        private selectedProfile,
        private ColorsService,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService
    ) {
        const conf = this.configurationsService.conf.Current;

        if (WindowActiveConfiguration.is(conf)) {
            this.config.System.type = this.configDefault.System.type = conf.System.type || null;
        }

        this.hasCream = ColorsService.windowColors.some(
            el => el.sides && el.sides.indexOf('F|C') && el.type === 'cream'
        );

        ColorsService.loadData();
        this.setDefaults();

        if (
            this.profile
        ) {
            this.filter = group =>
                group.systems
                && ((WindowActiveConfiguration.is(conf) && group.systems.includes(conf.System.id))
                    || !WindowActiveConfiguration.is(conf))
                && group.target.includes('show')
                && (this.profile.priceLevelColorGroups.some(g => g == group.id)
                    || this.profile.priceLevelColorGroupsOut.some((g: any) => g == group.id))
        }
    }

    setColorType(type) {
        this.ColorsService.setColorType(
            type,
            false,
            this.config,
            this.configDefault,
            this.filterColors.bind(this)
        );

        this.config.Colors.frame.outer = this.configDefault.Colors.frame.outer = {};
        this.config.Colors.frame.inner = this.configDefault.Colors.frame.inner = {};

        this.config.Colors.sash = this.config.Colors.frame;
        this.configDefault.Colors.sash = this.configDefault.Colors.frame;

        this.eventBusService.post({
            key: 'setProfileColor',
            value: {
                color: this.config.Colors,
                profile: this.profile,
            },
        });
    }

    openModalWood() {
        this.ColorsService.openModalWood(
            this.config,
            this.configDefault,
            this.setDefaults.bind(this)
        );
    }

    filter = group => true;
    filterColors = color => true;

    setDefaults() {
        this.ColorsService.loadData();
        this.ColorsService.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsService.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsService.setDefaultWood(this.config, this.configDefault);
        this.ColorsService.loadColorsByWood(this.config);
        this.ColorsService.setDefaultColors(
            this.config,
            this.configDefault,
            this.filter.bind(this)
        );
        this.ColorsService.setDefaultColorTypeForColors(this.config, this.configDefault);
    }

    close() {
        this.$uibModalInstance.close({ colors: this.config.Colors, wood: this.config.Wood });
    }
}
ModalProfileColorsCtrl.$inject = [
    '$uibModalInstance',
    'profile',
    'profilesPrices',
    'selectedProfile',
    'ColorsService',
    'ConfigurationsService',
    'EventBusService',
];
