import { Component, OnInit, Inject } from '@angular/core';
import {
    PageComponent,
    _,
    SharedFacade,
    ICC_PAGE_DATA,
    ModalService,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryAccessory, IccColor } from '@icc/common/data-types';
import { AccessoriesOptionsPageComponent } from '../accessories-options-page/accessories-options-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ConfigurationsService, TranslateService } from '@icc/common';
import { Profile } from '@icc/window';

@Component({
    selector: 'icc-accessories-list-page',
    template: require('./accessories-list-page.component.html'),
    styles: [require('./accessories-list-page.component.scss')],
})
export class AccessoriesListPageComponent extends PageComponent implements OnInit {
    public title = this.translateService.instant('ACCESSORY|Wybierz akcesoria dla ');
    public options = [];

    accessories: iccListItem[] = [];
    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'door'>,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessories: IccAccessoryAccessory[];
            categories: any[];
            forIt: 'configuration' | 'sash' | 'side' | 'drive';
            sashNum?: number;
            sash?: ActiveSash | string;
            colors?: IccColor[];
            noPrice: boolean;
        }
    ) {
        super();
        let subtitle = '';
        switch (pageData.forIt) {
            case 'configuration':
                subtitle = this.translateService.instant('ACCESSORY|całej konstrukcji');
                break;
            case 'sash':
                subtitle =
                    this.translateService.instant('ACCESSORY|kwatery ID:') + ' ' + pageData.sashNum;
                break;
            case 'side':
                switch (pageData.sash) {
                    case 'left':
                        subtitle = this.translateService.instant('ACCESSORY|lewej krawędzi');
                        break;
                    case 'right':
                        subtitle = this.translateService.instant('ACCESSORY|prawej krawędzi');
                        break;
                    case 'top':
                        subtitle = this.translateService.instant('ACCESSORY|górnej krawędzi');
                        break;
                    case 'bottom':
                        subtitle = this.translateService.instant('ACCESSORY|dolnej krawędzi');
                        break;
                }
                break;
            case 'drive':
                subtitle = this.translateService.instant('ACCESSORY|sterowania rolety');
                break;
        }

        this.title =
            this.translateService.instant('ACCESSORY|Wybierz akcesoria dla') + ' ' + subtitle;
    }

    ngOnInit() {
        this.accessories = this.pageData.accessories.map<iccListItem>((accessory) => ({
            id: Number(accessory.id),
            title: accessory.name,
            imageUrl: `/files/windowaccessory/${accessory.img}`,
            description: `${accessory.description}`,
        }));   
    }

    selectAccessory(item: iccListItem) {
        const accessory = this.pageData.accessories.find(a => Number(a.id) === Number(item.id));
        if (accessory) {
            this.modalService
                .open({
                    pageComponent: AccessoriesOptionsPageComponent,
                    resolve: {
                        accessory,
                        forIt: this.pageData.forIt,
                        sash: this.pageData.sash,
                        colors: this.pageData.colors,
                    },
                })
                .result.then(result => {
                    if (result) {
                        this.sharedFacade.closePage(result);
                    }
                });
        }
    }
}