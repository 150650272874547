import {Injectable} from '@angular/core';

import { Common } from '../Common';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';

@Injectable()
export class SiliconeColorsDefaultsService {

    getDefaultColor(colors = [], conf: WindowActiveConfiguration, defaultConf?) {
        let matchingColors = [];

        if (!colors) {
            return null;
        }

        // Dopasowanie do systemu i koloru konstrukcji
        if (Common.isObject(conf.System)
            && Common.isDefined(conf.System.id)
            && Common.isObject(conf.Colors.sash)
            && Common.isObject(conf.Colors.sash.inner)
            && Common.isObject(conf.Colors.sash.outer)
            && Common.isObject(conf.Colors.sash.core)
            && (Common.isDefined(conf.Colors.sash.inner.id)
                || Common.isDefined(conf.Colors.sash.outer.id)
                || Common.isDefined(conf.Colors.sash.core.id)
            )
        ) {
            matchingColors = colors.filter((el) => {
                if (Common.isArray(el.mapping)) {
                    for (let i = 0; i < el.mapping.length; i++) {
                        if ((
                                Common.isDefined(conf.Colors.sash.inner.id)
                                && Number(el.mapping[i].color) === Number(conf.Colors.sash.inner.id)
                            )
                            || (
                                Common.isDefined(conf.Colors.sash.outer.id)
                                && Number(el.mapping[i].color) === Number(conf.Colors.sash.outer.id)
                            )
                            || (
                                Common.isUndefined(conf.Colors.sash.inner.id)
                                && Common.isUndefined(conf.Colors.sash.outer.id)
                                && Common.isDefined(conf.Colors.sash.core.id)
                                && Number(el.mapping[i].color) === Number(conf.Colors.sash.core.id)
                            )
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }

        if (defaultConf
            && Common.isDefined(defaultConf.SiliconeColor)
            && Common.isDefined(defaultConf.SiliconeColor.id)
            && !defaultConf.SiliconeColor.isDefault
        ) {
            return defaultConf.SiliconeColor;
        } else {
            return matchingColors[0] || colors[0];
        }
    }
}
