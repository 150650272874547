import {InjectionToken} from '@angular/core';
import { AppConfig } from '@icc/common';
import { IIccConfig } from '@icc/config';
import { DatabaseConfiguration, IDatabase } from './IDatabase';
import { IccSimpleDatabase } from './IccSimpleDatabase';
import { IccDatabase } from './IccDatabase';

export abstract class DatabaseManager {
    protected abstract databasesConfigurations: DatabaseConfiguration[];

    databasesStore: IDatabase[] = [];
    eventEmitter = {
        emit(eventName: string, ...args: any[]) {
            return;
        }
    };

    constructor() {}

    async initAll(user: any, machine: string, config: {IccConfig: IIccConfig}) {
        this.databasesStore = this.databasesConfigurations.map(dbConfiguration => {
            if (dbConfiguration.simple) {
                return new IccSimpleDatabase({name: dbConfiguration.name, user}, dbConfiguration.options);
            } else {
                return new IccDatabase({
                    name: dbConfiguration.name,
                    user,
                    indexes: dbConfiguration.indexes,
                    machine,
                    hasAttachments: Boolean(dbConfiguration.attachments),
                    eventEmitter: this.eventEmitter,
                    fieldsToSync: config.IccConfig.Sync[dbConfiguration.name] === false ? dbConfiguration.fieldsToSync : [],
                }, dbConfiguration.options);
            }
        });
        this.databasesStore.map(database => database.init());
    }

    async refreshIndexesAll() {};

    get(name: string): IDatabase | undefined {
        const findedDatabases = this.databasesStore.filter(database => database.name === name);
        if (findedDatabases.length > 0) {
            return findedDatabases[0];
        }
    }

    setEmitter(emmiter: ((eventName: string, ...args: any[]) => any)) {
        this.eventEmitter.emit = emmiter;
    }

}

export const DatabaseManagerToken = new InjectionToken<DatabaseManager>('DatabaseManager');
