angular.module('flashMessage', []).factory('flashMessage', ['$rootScope', '$timeout', function($rootScope, $timeout){

    $rootScope.alert = {};
    return {
        show: function(alert, scope, time){
            scope = scope || 'root';
            $rootScope.alert[scope] = alert;

            if (time) {
                $timeout(function(){
                    $rootScope.alert[scope] = null;
                }, time);
            }
        },
        clear: function(){
            $rootScope.alert = [];
        }
    };
}]);

angular.module('flashMessage').directive('flashMessage', function() {

    return {
//        templateUrl: '/flashMessage.html',
        template:   '<div style="position: relative;">' +
                        '<div class="alert animated shake" ng-class="a.type" ng-if="a" ng-animate="animate">' +
                            '<div class="container">' +
                             '   <button type="button" class="close" ng-click="close(a)">&times;</button>' +
                              '  <div><strong ng-bind-html-unsafe="a.msg">' +
                               '         {{a.msg}}' +
                                '    </strong></div>' +
                                '<div ng-if="a._v">' +
                                 '   <ul ng-repeat="vs in a._v">' +
                                  '      <li ng-repeat="v in vs">{{ v[0]}}</li>' +
                                   ' </ul>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>',
        restrict: 'A',
        transclude: true,
        scope: {a: '=ngModel'},
        link: function(scope){
            $(document).keyup(function(e){
                if(e.keyCode === 27) {
                    scope.a = null;
                    scope.$apply();
                }
            });
            scope.close = function(){
                scope.a = null;
            }
        }
    }
});
