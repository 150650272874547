import { Component, OnInit, Inject } from '@angular/core';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/helpers';
import { ModalInfoComponent } from '../modal-info/modal-info.component';

@Component({
  selector: 'icc-list-page',
  template: require('./list-page.component.html'),
  styles: [require('./list-page.component.scss')]
})
export class ListPageComponent extends PageComponent implements OnInit {
    public title = _('INTERFACE|Lista');
    public options = [];

    items: any[] = [];
    selectedId: number | string | null = null;
    showItemInfo = false;

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: {
            title: string,
            items: any[],
            selectedId: number | string | null,
            imageUrl: string
            showItemInfo?: boolean;
            itemNamePropertyName?: string
            itemDescriptionPropertyName?: string,
            itemImgPropertyName?: string,
        },
        private sharedFacade: SharedFacade,
        private modalService: ModalService
    ) {
        super();
        this.showItemInfo = this.pageData.showItemInfo ? this.pageData.showItemInfo : false;
        this.title = this.pageData.title;
        this.items = this.pageData.items.map(item => ({
            id: item.id,
            title: item[this.pageData.itemNamePropertyName] || item.name || item.title,
            imageUrl: `${this.pageData.imageUrl}/${item[this.pageData.itemImgPropertyName] || item.image || item.img || item.color_img}`,
            showInfo: this.pageData.itemDescriptionPropertyName &&
                        item[this.pageData.itemDescriptionPropertyName]
                        && this.showItemInfo,
            content: item[this.pageData.itemDescriptionPropertyName] || item.content || item.description
        }));
        if (this.pageData.selectedId) {
            // Cast selectedId to the same type as found item
            // If there are more than 1 items with same id string/number, don't cast the type
            const foundItems = this.items.filter(i => i.id == this.pageData.selectedId);
            const idType = foundItems.length === 1 ? typeof foundItems[0].id : typeof this.pageData.selectedId;
            this.selectedId = idType === 'string'
                                ? String(this.pageData.selectedId)
                                : idType === 'number'
                                    ? Number(this.pageData.selectedId)
                                    : this.pageData.selectedId;
        }
    }

    ngOnInit() {}

    selectItem(item: any) {
        const element = this.pageData.items.find(i => i.id === item.id);
        this.sharedFacade.closePage(element);
    }

    showInfo(item) {
        if (item.content) {
            this.modalService
            .open({
                pageComponent: ModalInfoComponent,
                resolve: {
                    item,
                    button: true,
                },
            })
            .result.then(selected => {
                if (selected) {
                    this.selectItem(item);
                }
            });
        }
    }
}
