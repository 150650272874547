import { Injectable, Inject } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { fromSharedActions } from './shared.actions';
import { sharedQuery } from './shared.selectors';
import { ComponentType } from '@angular/cdk/portal';
import { PageComponent } from '@icc/helpers';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';

@Injectable()
export class ConfiguratorFacade {
    constructor(
        private configurationsService: ConfigurationsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    price$ = this.configurationsService.configuration$.pipe(
        map(conf => ({
            price:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGross
                    : conf.Price,
            priceAfterDiscounts:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGrossAfterDiscounts
                    : conf.PriceAfterDiscounts,
            discount: conf.Discount,
            hasGrossPrice:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C,
        }))
    );
    issues$ = this.configurationsService.configuration$.pipe(map(conf => conf.Issues));
}
