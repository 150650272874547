import {NgModule} from '@angular/core';
import { ColorsService } from './colors.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorsService
    ],
})
export class ColorsModule { }
