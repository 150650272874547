import { Component, OnInit, Inject } from '@angular/core';
import { ModalService } from '@icc/helpers';
import {
    APP_CONFIG,
    AppConfigFactory,
    UserService,
} from '@icc/common';
import { PriceDetailsComponent, ProductFeaturesComponent, _, StepComponent } from '@icc/configurator/shared';

@Component({
    selector: 'icc-product-details',
    template: require('./product-details.component.html'),
    styles: [require('./product-details.component.scss')],
})
export class ProductDetailsComponent extends StepComponent implements OnInit {
    static stepName = _('INTERFACE|Szczegóły');
    static stepIcon = {
        ligature: 'info',
    };

    public configurator = 'window';
    public stepId = 'details';
    public title = _('INTERFACE|Szczegóły ');
    public options = [
        {
            title: _('OFFER|Szczegóły wyceny'),
            component: PriceDetailsComponent,
            show: () => this.showPriceDetails
        },
        {
            title: _('OFFER|Cechy produktów'),
            component: ProductFeaturesComponent,
            // show: () => this.showPriceDetails
        }
    ]

    details: any = {};
    priceElementsVisible = false;
    showPriceDetails = false;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        const user = this.userService.get();
        this.showPriceDetails = this.config().IccConfig.Configurators.showPriceDetails && user.access == 'producent' && !this.userService.b2c;
    }

    openPriceDetails() {
        this.modalService.open({
            pageComponent: PriceDetailsComponent,
        });
    }

}
