import { Injectable } from '@angular/core';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { IccSill } from '@icc/common/data-types';
import { Sill } from '@icc/common/configurations/parts/complementary_goods';
import { core } from '@icc/common/helpers';

@Injectable()
export class WindowSillsService {
    sills: IccSill[] = [];

    constructor(private eventBusService: EventBusService) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.init(data.value);
            }
        );
    }

    /**
     * Ustawianie w zakresie
     * @param {Object} sill Parapet
     */
    setInRange(sill: IccSill) {
        if (sill.in_range === false) {
            sill.currentWidth = Number(sill.pricing_data[0].raster[0].width);
        }
        this.calcSill(sill);
    }

    /**
     * Obliczanie parapetu
     * @param  {Object} sill Parapet
     */
    calcSill(sill: IccSill) {
        let plugPrice = 0;
        let price = 0;
        let raster;

        if (
            sill.pricing_data
            && sill.pricing_data.length
            && sill.colors_pricing
            && sill.colors_pricing[sill.selectedColor]
        ) {
            raster = sill.pricing_data[sill.colors_pricing[sill.selectedColor].index].raster;
            if (raster[0].width === sill.currentWidth) {
                price = raster[0].price;
            } else {
                for (let i = 0; i < raster.length; i++) {
                    if (raster[i].width >= sill.currentWidth) {
                        price = raster[i].price;
                        break;
                    }
                }
            }

            if (sill.pricing_type === 'standard' || sill.pricing_type === 'm2') {
                price =
                    parseFloat(String(price).replace(',', '.'))
                    * (sill.currentLength / 1000)
                    * (sill.currentWidth / 1000);
            } else {
                if (sill.currentLength > 0) {
                    price =
                        parseFloat(String(price).replace(',', '.')) * (sill.currentLength / 1000);
                } else {
                    price = null;
                }
            }
            sill.price = parseFloat(String(price).replace(',', '.'));

            if (sill.plugs === '1') {
                for (let i = 0; i < sill.plugs_data.length; i++) {
                    if (Number(sill.plugs_data[i].width) >= sill.currentWidth) {
                        plugPrice = parseFloat(String(sill.plugs_data[i].price).replace(',', '.'));
                        break;
                    }
                }
            }
        }

        if (
            sill.pricing_data
            && sill.pricing_data.length
            && sill.pricing_data[0].raster.length
            && !sill.pricing_data[0].colors.length
        ) {
            raster = sill.pricing_data[0].raster;
            for (let i = 0; i < raster.length; i++) {
                if (raster[i].width === sill.currentWidth) {
                    price = raster[i].price;
                    break;
                }
            }
            sill.price = parseFloat(String(price).replace(',', '.'));
        }

        if (
            sill.max_length
            && sill.pricing_data
            && sill.pricing_data.length
            && !sill.pricing_data[0].raster[0].width
            && sill.colors_pricing
            && sill.colors_pricing[sill.selectedColor]
        ) {
            raster = sill.pricing_data[sill.colors_pricing[sill.selectedColor].index].raster;
            price = raster[0].price;
            if (sill.pricing_type === 'standard' || sill.pricing_type === 'm2') {
                price =
                    parseFloat(String(price).replace(',', '.'))
                    * (sill.currentLength / 1000)
                    * (sill.currentWidth / 1000);
            } else {
                if (sill.currentLength > 0) {
                    price =
                        parseFloat(String(price).replace(',', '.')) * (sill.currentLength / 1000);
                } else {
                    price = null;
                }
            }
            sill.price = price;
        }

        if (
            (sill.pricing_type === 'raster' && raster)
            || (sill.in_range && sill.pricing_type === 'standard')
        ) {
            let standard = false;
            const tolerance = ~~sill.size_tolerance;
            if (sill.pricing_type === 'raster' && raster) {
                for (let i = 0; i < raster.length; i++) {
                    const width = parseFloat(String(raster[i].width).replace(',', '.'));
                    if (
                        width - tolerance <= sill.currentWidth
                        && sill.currentWidth <= width + tolerance
                    ) {
                        standard = true;
                        break;
                    }
                }
            } else if (sill.in_range && sill.pricing_type === 'standard') {
                for (let i = 0; i < sill.dimensions_data.length; i++) {
                    const width = parseFloat(
                        String(sill.dimensions_data[i].width).replace(',', '.')
                    );
                    const length = parseFloat(
                        String(sill.dimensions_data[i].length).replace(',', '.')
                    );
                    if (
                        width - tolerance <= sill.currentWidth
                        && sill.currentWidth <= width + tolerance
                        && length - tolerance <= sill.currentLength
                        && sill.currentLength <= length + tolerance
                    ) {
                        standard = true;
                        break;
                    }
                }
            }
            if (!standard) {
                sill.price *= (100 + ~~sill.width_in_range) / 100;
            }
        }

        if (sill.price) {
            sill.price += plugPrice;
        }

        if (sill.addons_data) {
            for (let i = 0; i < sill.addons_data.length; i++) {
                if (sill.addons_data[i].selected) {
                    sill.price += sill.addons_data[i].value * 1;
                }
            }
        }
        return sill.price;
    }

    getSillWidths(sill: IccSill) {
        if (sill.pricing_type === 'raster') {
            return sill.pricing_data[0].raster.map(dimen => dimen.width);
        } else if (sill.pricing_type === 'standard') {
            return Array.from(new Set(sill.dimensions_data.map(dimen => dimen.width)));
        }
        return [];
    }

    getSillLengths(sill: IccSill) {
        if (sill.pricing_type === 'standard') {
            return sill.dimensions_data
                .filter(dimen => ~~dimen.width === sill.currentWidth)
                .map(dimen => dimen.length);
        }
        return [];
    }

    iccSillToSill(iccSill: IccSill) {
        if (iccSill) {
            const newSill: Sill = {
                id: Number(iccSill.id),
                name: iccSill.name,
                type: iccSill.type,
                count: Number(iccSill.count),
                width: Number(iccSill.currentWidth),
                length: Number(iccSill.currentLength),
                weight: 0,
                comment: '',
                addons: iccSill.addons_data.map(addon => addon.name),
                price: iccSill.price,
                framesId: [],
                image: iccSill.image
            };
            if (typeof iccSill.plugs !== 'undefined') {
                newSill.plugs = !!~~iccSill.plugs;
            }
            newSill.color = {
                id: Number(iccSill.selectedColor),
                name:
                    iccSill.colors_pricing && iccSill.colors_pricing[iccSill.selectedColor]
                        ? iccSill.colors_pricing[iccSill.selectedColor].name
                        : '',
                isDefault: false,
            };
            return newSill;
        }
        return null;
    }

    sillToIccSill(sill: Sill | null) {
        if (sill) {
            const newSill: IccSill = core.copy(this.sills.find(s => Number(s.id) === sill.id));
            newSill.count = sill.count;
            newSill.selectedColor = String(sill.color.id);
            newSill.currentWidth = sill.width;
            newSill.currentLength = sill.length;
            newSill.price = sill.price;
            if (sill.plugs) {
                newSill.plugs = String(sill.plugs);
            }
            return newSill;
        }
        return null;
    }

    private init(data) {
        this.sills = data.windowSillsGoods;
        if (this.sills) {
            for (const sill of this.sills) {
                if (
                    sill.pricing_data
                    && sill.pricing_data[0]
                    && sill.pricing_data[0].raster
                    && sill.pricing_data[0].raster[0]
                ) {
                    sill.currentWidth = Number(sill.pricing_data[0].raster[0].width) || 0;
                }

                sill.currentWidth = sill.currentWidth || parseFloat(sill.min_width) || 0;
                sill.currentLength = sill.currentLength || parseFloat(sill.min_length) || 0;

                this.calcSill(sill);
            }
        }
    }
}
