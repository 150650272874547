import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { EventBusService } from '@icc/common/event-bus.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price';
import { BrowserShapeService } from './shape.service';
import { AlignmentsService } from './alignments.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';

@Injectable()
export class ResizeSashService {
    minSize: number = this.config().IccConfig.Configurators.minWidth;
    minSizeGlass: number = this.config().IccConfig.Configurators.minWidthGlass;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private warrantyService: WarrantyService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private shapeService: BrowserShapeService,
        private alignmentsService: AlignmentsService,
        private constructionLimitationService: ConstructionLimitationService
    ) {}

    /**
     * Zmienia rozmiar skrzydła
     *
     * @param divider   Słupek/poprzeczka
     * @param diff      O ile ma być przesuniętę w pikselach
     * @param direction Czy podział poziomy czy pionowy
     */
    changeSashWidth(
        divider: ActiveMullion,
        diff: number,
        direction: 'horizontal' | 'vertical',
        conf: WindowActiveConfiguration,
        minWidthPrev?: number,
        minWidthNext?: number,
    ) {
        if (direction === 'horizontal') {
            this.resizeSashesWidth(
                diff,
                conf,
                divider.multiAlignTop,
                divider.multiAlignBottom,
                divider,
                divider.multiAlignTopDiv,
                divider.multiAlignBottomDiv,
                [
                    'vertical',
                    'top',
                    'bottom',
                    'left',
                    'right',
                    'Height',
                    'rHeight',
                    'ry',
                    'multiAlignLeft',
                ],
                false,
                minWidthPrev,
                minWidthNext,
            );
        } else {
            this.resizeSashesWidth(
                diff,
                conf,
                divider.multiAlignLeft,
                divider.multiAlignRight,
                divider,
                divider.multiAlignLeftDiv,
                divider.multiAlignRightDiv,
                [
                    'horizontal',
                    'left',
                    'right',
                    'top',
                    'bottom',
                    'Width',
                    'rWidth',
                    'rx',
                    'multiAlignTop',
                ],
                false,
                minWidthPrev,
                minWidthNext,
            );
        }
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
        this.shapeService.setShapes(conf);
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    resizeSashesWidth(
        diff: number,
        conf: WindowActiveConfiguration,
        oneSideSashes: ActiveSash[],
        otherSideSashes: ActiveSash[],
        divider: ActiveMullion,
        oneSideMullions: Partial<ActiveMullion>[],
        otherSideMullions: Partial<ActiveMullion>[],
        [
            orientation,
            side,
            side2,
            side3,
            side4,
            sizeField,
            rSizeField,
            coordField,
            multiAlignField,
        ]: [
            'vertical' | 'horizontal',
            'top' | 'left',
            'bottom' | 'right',
            'left' | 'top',
            'right' | 'bottom',
            'Height' | 'Width',
            'rHeight' | 'rWidth',
            'ry' | 'rx',
            'multiAlignLeft' | 'multiAlignTop'
        ],
        symetric = false,
        minWidthPrev?: number,
        minWidthNext?: number,
    ) {
        diff = this.getMaximalSizeDiff(conf[sizeField], oneSideSashes, otherSideSashes, diff, minWidthPrev, minWidthNext, [
            side,
            side2,
            rSizeField,
        ]);
        const oneSideDiff = this.getOneSideDiff(diff, symetric);
        const otherSideDiff = this.getOtherSideDiff(diff, symetric);
        divider[coordField] -= oneSideDiff;
        for (const sash of oneSideSashes) {
            this.resizeOneSideSash(sash, oneSideDiff, [side2, side4, rSizeField, coordField], conf);
            this.alignmentsService.resizeAlignments(conf, side3, side2, sash, -oneSideDiff, true);
            this.alignmentsService.resizeAlignments(conf, side4, side2, sash, -oneSideDiff, true);
            this.alignmentsService.rearrangeAlignments(conf, side2, sash);
        }
        for (const sash of otherSideSashes) {
            this.resizeSash(
                sash,
                otherSideDiff,
                [orientation, side, side4, rSizeField, coordField, multiAlignField],
                conf
            );
            this.alignmentsService.resizeAlignments(conf, side3, side, sash, otherSideDiff, true);
            this.alignmentsService.resizeAlignments(conf, side4, side, sash, otherSideDiff, true);
            this.alignmentsService.rearrangeAlignments(conf, side, sash);
        }
        for (let i = 0; i < oneSideMullions.length; i++) {
            oneSideMullions[i][rSizeField] -= oneSideDiff;
        }
        for (let i = 0; i < otherSideMullions.length; i++) {
            otherSideMullions[i][rSizeField] += otherSideDiff;
            otherSideMullions[i][coordField] -= otherSideDiff;
        }
        return diff;
    }

    resizeOtherSideSashField(
        field: ActiveSash,
        diff: number,
        mullions: ActiveMullion[],
        [side, side2, sizeField, coordField]: [
            'top' | 'left',
            'right' | 'bottom',
            'rHeight' | 'rWidth',
            'ry' | 'rx'
        ]
    ) {
        if (field.nearMullions[side] === -1) {
            field[sizeField] += diff;
            if (field.nearMullions[side2] !== -1) {
                const iDiv = mullions.find(m => m.id === field.nearMullions[side2]);
                iDiv[sizeField] += diff;
            }
        } else {
            field[coordField] += diff;
        }
    }

    resizeOtherSideSashMullion(
        iDiv: ActiveMullion,
        diff: number,
        [orientation, multiAlignField, side, coordField]: [
            'vertical' | 'horizontal',
            'multiAlignLeft' | 'multiAlignTop',
            'top' | 'left',
            'ry' | 'rx'
        ]
    ) {
        if (
            iDiv.direction !== orientation
            || (iDiv[multiAlignField][0] && iDiv[multiAlignField][0].nearMullions[side] > -1)
        ) {
            iDiv[coordField] += diff;
        }
    }

    resizeOneSideSash(
        sash: ActiveSash,
        diff: number,
        [side, side2, sizeField, coordField]: [
            'right' | 'bottom',
            'bottom' | 'right',
            'rHeight' | 'rWidth',
            'ry' | 'rx'
        ],
        conf: WindowActiveConfiguration
    ) {
        sash[sizeField] -= diff;
        for (const fieldId of sash.intEdgeSashes[side]) {
            const field = sash.intSashes.find(el => el.id === fieldId);
            if (field[coordField] + field[sizeField] === sash[sizeField] + diff) {
                field[sizeField] -= diff;
            }
            if (field.nearMullions[side2] !== -1) {
                const iDiv = sash.intMullions.find(m => m.id === field.nearMullions[side2]);
                iDiv[sizeField] -= diff;
            }
            this.alignmentsService.resizeAlignments(conf, core.opposite(side2), side, field, -diff);
            this.alignmentsService.resizeAlignments(conf, side2, side, field, -diff);
            this.alignmentsService.rearrangeAlignments(conf, side, field);
        }
    }

    private resizeSash(
        sash: ActiveSash,
        diff: number,
        [orientation, side, side2, sizeField, coordField, multiAlignField]: [
            'vertical' | 'horizontal',
            'top' | 'left',
            'right' | 'bottom',
            'rHeight' | 'rWidth',
            'ry' | 'rx',
            'multiAlignLeft' | 'multiAlignTop'
        ],
        conf: WindowActiveConfiguration
    ) {
        sash[sizeField] += diff;
        sash[coordField] -= diff;

        for (const field of sash.intSashes) {
            this.resizeOtherSideSashField(field, diff, sash.intMullions, [
                side,
                side2,
                sizeField,
                coordField,
            ]);
            this.alignmentsService.resizeAlignments(conf, core.opposite(side2), side, field, diff);
            this.alignmentsService.resizeAlignments(conf, side2, side, field, diff);
            this.alignmentsService.rearrangeAlignments(conf, side, field);
            this.alignmentsService.rearrangeAlignments(conf, core.opposite(side), field);
        }
        for (const iDiv of sash.intMullions) {
            this.resizeOtherSideSashMullion(iDiv, diff, [
                orientation,
                multiAlignField,
                side,
                coordField,
            ]);
        }
    }

    private getMaximalSizeDiff(
        size: number,
        oneSideSashes: ActiveSash[],
        otherSideSashes: ActiveSash[],
        diff: number,
        minWidthPrev: number,
        minWidthNext: number,
        [side, reverseSide, sizeField]: ['left' | 'top', 'right' | 'bottom', 'rWidth' | 'rHeight']
    ) {
        const oneSideMaxSize = this.getMinimalSize(size, oneSideSashes, reverseSide, sizeField);
        const otherSideMaxSize = this.getMinimalSize(size, otherSideSashes, side, sizeField);
        if (oneSideMaxSize - diff <= this.minSizeGlass + minWidthPrev) {
            diff = oneSideMaxSize - this.minSizeGlass - minWidthPrev;
        }
        if (otherSideMaxSize + diff <= this.minSizeGlass + minWidthNext) {
            diff = this.minSizeGlass + minWidthNext - otherSideMaxSize;
        }
        return diff;
    }

    private getMinimalSize(
        minSize: number,
        sashes: ActiveSash[],
        side: 'left' | 'right' | 'top' | 'bottom',
        sizeField: 'rWidth' | 'rHeight'
    ) {
        for (const finded of sashes) {
            if (finded[sizeField] < minSize) {
                minSize = finded[sizeField];
            }
            for (const iFinded of finded.intSashes) {
                if (iFinded.nearMullions[side] === -1 && iFinded[sizeField] < minSize) {
                    minSize = iFinded[sizeField];
                }
            }
        }
        return minSize;
    }

    private getOneSideDiff(diff: number, symetrical: boolean) {
        return symetrical ? (diff > 0 ? Math.floor(diff / 2) : Math.ceil(diff / 2)): diff;
    }

    private getOtherSideDiff(diff: number, symetrical: boolean) {
        return symetrical ? (diff > 0 ? -Math.ceil(diff / 2) : -Math.floor(diff / 2)): diff;
    }
}
