import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService, InfoService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { Profile, ProfileType, Reinforcement } from '@icc/window';
import { BrowserProfilesService } from '../profiles.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { IccSideColors } from '@icc/common/data-types';
import { Wood } from '@icc/common/configurations/parts/common';
import { Common, core, AppConfigFactory, APP_CONFIG, TranslateService, ConfigurationsService } from '@icc/common';
import { iccListItem, iccListTab, UnitConverterService } from '@icc/configurator/ui';
import { PriceBaseService } from '@icc/price/b2b';
import { ProfileColorsService } from '../profile-colors.service';
import { ProfileOptionsPageComponent } from '../profile-options-page/profile-options-page.component';
import { ReinforcementsModalService } from '../../reinforcements/reinforcements-modal.service';
import { BehaviorSubject } from 'rxjs';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';

interface iccProfileListItem extends iccListItem {
    price: {
        price_field: any;
        price_length: any;
        price_piece: any;
        price_construction: any;
        price_sash: any;
        price_area: any;
        price_percent: any;
    };
    type: ProfileType;
    selectedColor: Profile['selectedColor'];
    selectedWood: Profile['selectedWood'];
    width: Profile['width'];
    maxHeight: Profile['maxHeight'];
    minHeight: Profile['minHeight'];
}

@Component({
    selector: 'icc-profiles-list-page',
    template: require('./profiles-list-page.component.html'),
    styles: [require('./profiles-list-page.component.scss')],
})
export class ProfilesListPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz profil');
    public options = [];

    showTabs = false;
    showColorsWhenCoupling = this.CurConfService.conf === 'coupled_window';
    profiles: iccProfileListItem[] = [];
    selectedTypeTab = 'fixed_mullion';
    currency: any = null;
    selectedProfileId?: number;
    profileCategories: iccListTab[] = [];

    selectedColors$: BehaviorSubject<any> = new BehaviorSubject( {
        frame: {
            core: {},
            outer: {},
            inner: {},
            alushell: {},
        },
        sash: {
            core: {},
            outer: {},
            inner: {},
            alushell: {},
        },
    })

    tabs: iccListTab[] = [
        {
            id: 'fixed_mullion',
            name: this.translateService.instant('WINDOW|Słupki')
        },
        {
            id: 'coupling',
            name: this.translateService.instant('WINDOW|Łączniki')
        },
        {
            id: 'extension',
            name: this.translateService.instant('WINDOW|Poszerzenie')
        },
        {
            id: 'other',
            name: this.translateService.instant('WINDOW|Inny profil')
        },
    ]

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private sharedFacade: SharedFacade,
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private priceBaseService: PriceBaseService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private CurConfService: CurrentConfiguratorService,
        private profileColorsService: ProfileColorsService,
        private modalService: ModalService,
        private reinforcementsModalService: ReinforcementsModalService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profiles: Profile[];
            profilesPrices: any[];
            selectedProfile: Profile;
            system: any | null;
            type: ProfileType;
            currency: any;
            color?: IccSideColors;
            wood?: Wood;
            reinforcement?: Reinforcement;
            profileCategories?: iccListTab[];
        },
        private unitConverterService: UnitConverterService,
        private infoService: InfoService,
        private accessoriesService: AccessoriesService
    ) {
        super();
    }

    ngOnInit() {
        let types: ProfileType[] = []; 
        this.currency = this.pageData.currency;
        this.pageData.profiles.forEach(profile => {
            if (this.pageData.color) {
                if (
                    this.pageData.selectedProfile
                    && this.pageData.selectedProfile.id === profile.id
                ) {
                    profile.selectedColor = {
                        frame: this.pageData.color,
                        sash: this.pageData.color,
                    };
                    profile.selectedWood = this.pageData.wood;
                    this.selectedColors$.next(profile.selectedColor);
                } else  if (!profile.selectedColor || !profile.selectedColor.frame || !profile.selectedColor.frame.core) {
                    profile.selectedColor = {
                        frame: this.profilesService.getDefaultProfileColorSet(
                            profile,
                            this.pageData.color,
                            this.pageData.system
                        ),
                        sash: this.profilesService.getDefaultProfileColorSet(
                            profile,
                            this.pageData.color,
                            this.pageData.system
                        ),
                    };
                }
            }
            if (
                this.pageData.reinforcement
                && this.pageData.selectedProfile
                && this.pageData.selectedProfile.id === profile.id
            ) {
                profile.reinforcement = this.pageData.reinforcement;
            }

            if (!types.includes(profile.type)) {
                types.push(profile.type);
            }
        });

        let hasCouplings = types.includes('coupling');

        const tabsAsCategories = this.pageData.profileCategories
            && types.length === 1;
        if (tabsAsCategories) {
            this.tabs = this.pageData.profileCategories?.map((category: any) => {
                return {id: category.id, name: category.name};
            }) || [];
        }

        this.profiles = this.pageData.profiles.map(profile => ({
            id: Number(profile.id),
            title: profile.name || profile.code,
            imageUrl: `/files/profile/${profile.img}`,
            description: `${profile.name}`,
            price: profile.price,
            type: profile.type,
            selectedColor: profile.selectedColor,
            selectedWood: profile.selectedWood,
            width: profile.width,
            maxHeight: profile.maxHeight,
            minHeight: profile.minHeight,
            reinforcement: profile.reinforcement,
            tabs: tabsAsCategories ? [profile.profileCategoryId] : [profile.type],
        }));
        this.selectedProfileId = this.pageData.selectedProfile && Number(this.pageData.selectedProfile.id);

        if (types.length > 1 || tabsAsCategories) {
            this.showTabs = true;
        } 
    }

    hasZeroPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.pageData.type) && type === 'frame')
            || this.pageData.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
            requiredFields.push(price.price_construction);
        }
        if (
            (Common.isArray(this.pageData.type) && type === 'threshold')
            || this.pageData.type === 'threshold'
        ) {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (
            !this.pageData.type
            || (Common.isArray(this.pageData.type)
                && type
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.pageData.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.pageData.type) && type === 'sandwich')
            || this.pageData.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return true;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.pageData.type) && type === 'frame')
            || this.pageData.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
            requiredFields.push(price.price_construction);
        }
        if (
            (Common.isArray(this.pageData.type) && type === 'threshold')
            || this.pageData.type === 'threshold'
        ) {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (
            !this.pageData.type
            || (Common.isArray(this.pageData.type)
                && type
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.pageData.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.pageData.type) && type === 'sandwich')
            || this.pageData.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    selectProfile(profileItem: iccProfileListItem) {
        this.changeProfile(profileItem);
    }

    showInvalidAccessoriesForFrameInfo(invalidAccessories: String[], profileItem: iccProfileListItem) {
        this.infoService.openConfirmModal(
            this.translateService.instant('WINDOW|Niedostępne dodatki dla profilu ramy'),
            this.translateService.instant(`WINDOW|Po zmianie profilu następujące dodatki zostaną usunięte: `) + invalidAccessories.filter(name => ' ' + name),
            [
                {
                    name: this.translateService.instant('INTERFACE|Anuluj'),
                    callback: () => {},
                    accent: true,
                },
                {
                    name: this.translateService.instant('INTERFACE|Kontynuuj'),
                    callback: () => {
                        this.accessoriesService.removeInvalidAccessories(profileItem.id);
                        this.selectProfile(profileItem);
                    },
                },
            ]
        );
    }

    changeProfile(profileItem: iccProfileListItem) {
        const invalidAccessories = this.profilesService.checkIfAccessoryMatchFrameProfile(
            Number(profileItem.id)
        );
        
        if (invalidAccessories && invalidAccessories.length) {
            this.showInvalidAccessoriesForFrameInfo(invalidAccessories, profileItem);
        } else {
            const profile = core.copy(
                this.pageData.profiles.find((p) => Number(p.id) === Number(profileItem.id))
            );
            if (profile) {
                profile.width = profileItem.width || profile.width;
                if (
                    profile.type === 'extension' ||
                    profile.type === 'sandwich' ||
                    profile.type === 'coupling' && this.showColorsWhenCoupling ||
                    this.CurConfService.conf === 'complementary_goods'
                ) {
                    this.selectedColors$.next(profile.selectedColor);
                    this.modalService
                        .open({
                            pageComponent: ProfileOptionsPageComponent,
                            resolve: {
                                profile,
                                profilesPrices: this.pageData.profilesPrices,
                                profile$: new BehaviorSubject({
                                    count: 1,
                                    currentLength: 0,
                                }),
                                selectedColors$: this.selectedColors$,
                            },
                        })
                        .result.then((result) => {
                            this.sharedFacade.closePage(result);
                        });
                } else {
                    this.sharedFacade.closePage(profile);
                }
            }
        }
    }

    openModalColors(profile: Profile) {
        this.profileColorsService
            .openProfileColorsModal(profile, this.pageData.selectedProfile, this.pageData.profilesPrices, this.selectedColors$)
            .then(selection => {
                profile.price = this.profilesPriceService.getProfilePrice(
                    profile.id,
                    Common.isArray(this.pageData.type) ? profile.type : this.pageData.type,
                    this.pageData.system,
                    selection ? selection.colors : profile.selectedColor,
                    this.pageData.profilesPrices
                );

                profile.selectedColor = core.copy(
                    selection ? selection.colors : profile.selectedColor
                );
                profile.selectedWood = core.copy(selection ? selection.wood : profile.selectedWood);
            });
    }

    openModalReinforcements(profile: Profile) {
        this.reinforcementsModalService.openReinforcementsModal(
            profile.id,
            profile.reinforcement ? profile.reinforcement : null
        ).then((reinforcement: Reinforcement | false) => {
            if (reinforcement) {
                const profileTmp = this.pageData.profiles.find(el => el.id === profile.id);
                if (profileTmp) {
                    profileTmp.reinforcement = reinforcement.id === null ? null : core.copy(reinforcement);
                }
            }
        });

    }

    priceMarket(price: number) {
        return this.priceBaseService.addMarginMarketFactor(price);
    }
}
