import angular from 'angular';

import Base64Decode2Filter from './Base64Decode2Filter';
import Base64DecodeFilter from '@icc/common/decode/Base64DecodeFilter';
import Base64DecoderFilter from '@icc/common/decode/Base64DecoderFilter';
import SvgImageFilter from '@icc/common/decode/SvgImageFilter';
import CheckDeleteFilter from './CheckDeleteFilter';
import CompareGroupPositionFilter from './CompareGroupPositionFilter';
import CurrencyFilter from '@icc/common/currency/CurrencyFilter';
import UnitMMFilter from '@icc/common/unit/UnitMMFilter';
import UnitMFilter from '@icc/common/unit/UnitMFilter';
import UnitM2Filter from '@icc/common/unit/UnitM2Filter';
import Unit2DFilter from '@icc/common/unit/Unit2DFilter';
import FileSizeFilter from '@icc/common/unit/FileSizeFilter';
import CustomListSearchFilter from './CustomListSearchFilter';
import DateFormatFilter from './DateFormatFilter';
import FindIndexFilter from './FindIndexFilter';
import HederOfferFilter from './HederOfferFilter';
import IsArrayFilter from './IsArrayFilter';
import JoinFilter from './JoinFilter';
import NotEmptyFilter from './NotEmptyFilter';
import Obj2ArrFilter from './Obj2ArrFilter';
import ObjLengthFilter from '@icc/common/object/ObjLengthFilter';
import PagFilter from './PagFilter';
import PluralFilter from './PluralFilter';
import RatioDimenFilter from './RatioDimenFilter';
import SelectIntFilter from './SelectIntFilter';
import StripHTMLFilter from './StripHTMLFilter';
import SumByPropertyFilter from '@icc/common/object/SumByPropertyFilter';
import UniqueFilter from './UniqueFilter';
import UserAccessFilter from './UserAccessFilter';
import SashHasMuntinsFilter from './SashHasMuntinsFilter';
import StringToDateFilter from './StringToDateFilter';

export default angular.module('icc.app.filters', [])
    .filter('base64decode', Base64DecodeFilter)
    .filter('base64Decoder', Base64DecoderFilter)
    .filter('base64decode2', Base64Decode2Filter)
    .filter('checkDelete', CheckDeleteFilter)
    .filter('compareGroupPosition', CompareGroupPositionFilter)
    .filter('currency', CurrencyFilter)
    .filter('unitmm', UnitMMFilter)
    .filter('unitm', UnitMFilter)
    .filter('unitm2', UnitM2Filter)
    .filter('unit2d', Unit2DFilter)
    .filter('fileSize', FileSizeFilter)
    .filter('customListSearch', CustomListSearchFilter)
    .filter('dateFormat', DateFormatFilter)
    .filter('stringToDate', StringToDateFilter)
    .filter('findIndex', FindIndexFilter)
    .filter('hederOffer', HederOfferFilter)
    .filter('isArray', IsArrayFilter)
    .filter('join', JoinFilter)
    .filter('notEmpty', NotEmptyFilter)
    .filter('obj2arr', Obj2ArrFilter)
    .filter('objLength', ObjLengthFilter)
    .filter('pag', PagFilter)
    .filter('plural', PluralFilter)
    .filter('ratioDimen', RatioDimenFilter)
    .filter('selectInt', SelectIntFilter)
    .filter('stripHTML', StripHTMLFilter)
    .filter('sumByProperty', SumByPropertyFilter)
    .filter('unique', UniqueFilter)
    .filter('userAccessFilter', UserAccessFilter)
    .filter('sashHasMuntinsFilter', SashHasMuntinsFilter)
    .filter('svgImage', SvgImageFilter)
    .name;
