import 'angular-ui-bootstrap';
/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export class ModalColorsCtrl {

    config = {
        type: 'accessory',
        Colors: this.accessory.selectedColor || {
            frame: {
                core: {},
                outer: {},
                inner: {},
                alushell: {}
            },
            sash: {
                core: {},
                outer: {},
                inner: {},
                alushell: {}
            }
        },
        HasAlushell: false,
        AlushellType: '',
        System: {
            type: this.material || this.accessory.material
        },
        ColorType: 'White',
        Wood: this.accessory.selectedWood || {},
        ColorsSashExt: false,
        valid: {}
    };

    configDefault = {
        type: 'accessory',
        Colors: this.accessory.selectedColor || {
            frame: {
                core: {},
                outer: {},
                inner: {},
                alushell: {}
            },
            sash: {
                core: {},
                outer: {},
                inner: {},
                alushell: {}
            }
        },
        HasAlushell: false,
        AlushellType: '',
        System: {
            type: this.accessory.material
        },
        ColorType: 'White',
        Wood: this.accessory.selectedWood || {},
        ColorsSashExt: false,
        valid: {}
    };

    hasCream = false;
    setColorType(type) {
        this.ColorsService.setColorType(type, false, this.config, this.configDefault, this.filterColors.bind(this));

        this.config.Colors.sash = this.config.Colors.frame;
        this.configDefault.Colors.sash = this.configDefault.Colors.frame;
    }
    openModalWood() {
        this.ColorsService.openModalWood(this.config, this.configDefault, this.setDefaults.bind(this));
    }

    filter = (group) => true;
    filterColors = (color) => true;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private accessory,
        private ColorsService,
        private type,
        private material,
    ) {
        'ngInject';

        for (let j = 0; j < ColorsService.windowColors.length; j++) {
            if (ColorsService.windowColors[j].sides.indexOf('F|C') > -1 && ColorsService.windowColors[j].type === 'cream') {
                this.hasCream = true;
                break;
            }
        }

        ColorsService.loadData();
        this.setDefaults();
            this.filter = (group) => {
                if (type === 'profile') {
                    return group.target.indexOf('show') > -1 && this.accessory.systems.some(systemId => group.systems && group.systems.map(Number).indexOf(Number(systemId)) > -1);
                }
                return (typeof accessory.conf_color_groups_ids !== 'undefined') && accessory.conf_color_groups_ids.map(Number).indexOf(parseInt(group.id)) > -1;
            };
            this.filterColors = (color) => {
                if (type === 'profile') {
                    return this.accessory.systems.some(systemId => color.systems && color.systems.map(Number).indexOf(Number(systemId)) > -1);
                }
                return this.accessory.conf_colors_ids.map(Number).indexOf(~~color.id) > -1
                || color.groups && this.accessory.conf_color_groups_ids.map(Number).some(c => color.groups.map(Number).indexOf(~~c) > -1);
            };
    }



    setDefaults() {
        this.ColorsService.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsService.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsService.setDefaultWood(this.config, this.configDefault);
        this.ColorsService.loadColorsByWood(this.config);
        this.ColorsService.setDefaultColors(this.config, this.configDefault, this.filter.bind(this));
        this.ColorsService.setDefaultColorTypeForColors(this.config, this.configDefault);
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close({colors: this.config.Colors, wood: this.config.Wood});
    }
}
