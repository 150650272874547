import angular from 'angular';

export default function ComplaintCtrl($rootScope, $scope, $http, $routeParams, $route, $timeout, $location, Core, ComplaintFactory, DefectFactory, UserService) {
    'ngInject';

    var vm           = this;
    var defectsData  = {};
    vm.complaint     = {};
    vm.attachments   = [];
    vm.tmpId         = $routeParams.id;
    vm.checksum      = $routeParams.id2;
    vm.saveComplaint = saveComplaint;
    vm.addFile       = addFile;
    vm.removeFile    = removeFile;
    vm.addDefect     = addDefect;
    vm.editDefect    = editDefect;
    vm.removeDefect  = removeDefect;
    vm.hasOrder      = false;
    vm.edit          = true;
    vm.dateOptions   = {startingDay: 1, showWeeks: false};
    vm.openDeadline  = openDeadline;
    vm.new           = true;
    vm.defectHistory = defectHistory;
    vm.addComment    = addComment;
    vm.comment       = null;
    vm.checkedMail   = {};
    vm.lastStatus    = null;
    vm.edited        = false;
    vm.toggleTypeSelection = toggleTypeSelection;
    vm.commentEmails = ComplaintFactory.commentEmails;
    vm.blockSave = false;

    getData();

    /**
     * Otwieranie datepickera
     * @param  {object} $event Event
     */
    function openDeadline($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedDeadline = true;
    }

    /**
     * Pobieranie danych
     */
    function getData() {
        $rootScope.loader = true;
        if ($rootScope.user.access === null) {
            UserService.synchronizeUser().then(() => getDataFunction());
        } else {
            getDataFunction();
        }

    }

    function getDataFunction() {
        if ($rootScope.user.access) {
            ComplaintFactory.getData((res) => {
                if (!res.complaint && res.tmpId && angular.isUndefined(vm.checksum)) {
                    if ($rootScope.user.access == 'Super Admin'
                        || $rootScope.user.access == 'Administratorzy'
                        || $rootScope.user.access == 'market_leader'
                        || $rootScope.user.access == 'complaint_admin'
                    ) {
                        window.location.replace('/admin/complaint/complaints/');
                    } else {
                        window.location.replace('/app/complaints/');
                    }
                }
                vm.edited            = false;
                vm.countries         = res.countries;
                vm.eAdresses         = res.eAdresses;
                vm.dealers           = res.dealers;
                vm.markets           = res.markets;
                vm.regions           = $route.current.templateUrl.includes('print_complaint') ?  Core.arrayOfObjectsToList(res.regions, 'value') : res.regions;
                defectsData          = res.defectsData;
                vm.productTypes      = Core.arrayOfObjectsToList(defectsData.productTypes);
                vm.locations         = Core.arrayOfObjectsToList(defectsData.locations);
                vm.defectTypes       = Core.arrayOfObjectsToList(defectsData.defectTypes);
                vm.defectSizes       = Core.arrayOfObjectsToList(defectsData.defectSizes);
                vm.materials         = Core.arrayOfObjectsToList(defectsData.materials);
                vm.sides             = Core.arrayOfObjectsToList(defectsData.sides);
                vm.areas             = Core.arrayOfObjectsToList(defectsData.areas);
                vm.numberOfDefects   = Core.arrayOfObjectsToList(defectsData.numberOfDefects);
                vm.defectOwners      = Core.arrayOfObjectsToList(defectsData.defectOwners);
                vm.costAllocations   = Core.arrayOfObjectsToList(defectsData.costAllocations);
                vm.providers         = Core.arrayOfObjectsToList(defectsData.providers);
                vm.carers            = Core.arrayOfObjectsToList(defectsData.carers);
                vm.complaintTypes    = res.complaintTypes;
                vm.complaint.defects = [];
                vm.payableComplaintInfo = res.payableComplaintInfo;
                if (res.complaint) {
                    vm.complaint          = res.complaint;
                    vm.lastStatus         = res.complaint.status;
                    vm.complaint.deadline = vm.complaint.deadline === null ? vm.complaint.deadline : new Date(vm.complaint.deadline),
                    vm.new                = false;
                    if ($rootScope.user.access != 'Super Admin'
                        && $rootScope.user.access != 'Administratorzy'
                        && $rootScope.user.access != 'market_leader'
                        && $rootScope.user.access != 'complaint_admin'
                        && $rootScope.user.access != 'dealer'
                        && $rootScope.user.access != 'dealerh'
                        || ($rootScope.user.access == 'market_leader'
                            && ['2', '3'].indexOf(vm.complaint.status) > -1)
                        || (vm.complaint.visited == 1
                            && ($rootScope.user.access == 'dealer'
                                || $rootScope.user.access == 'dealerh'))
                    ) {
                        vm.edit = false;
                    }
                }
                if (res.offer) {
                    vm.hasOrder            = true;
                    vm.offer               = res.offer;
                    vm.complaint.dealer_id = res.offer.Dealer.id;
                    vm.complaint.order_id  = res.offer.Offer.tmp_id;
                    vm.offer.Offer.created = new Date(vm.offer.Offer.created);
                } else if ($rootScope.user && ['dealer', 'dealerh'].includes($rootScope.user.access)) {
                    vm.complaint.dealer_id = $rootScope.user.dealerid;
                }
                if ($rootScope.user && (angular.isUndefined(vm.complaint.user_id) || vm.complaint.user_id === null)) {
                    vm.complaint.user_id = $rootScope.user.id;
                }
                vm.printUrl = $location.url().replace('complaint', 'print_complaint');
                $rootScope.loader = false;
                if ($route.current.templateUrl.includes('print_complaint')) {
                    $timeout(() => window.print(), 1000);
                }
            });
        } else {
            window.location = '/' + $scope.curLang;
        }
    }

    /**
     * Zapis zgłoszenia reklamacyjnego
     */
    function saveComplaint() {
        vm.blockSave = true;
        let afterEdit = false;
        if(document.getElementsByClassName('ng-dirty').length > 0 || vm.edited){
            afterEdit = true;
        } else {
            afterEdit = false;
        }

        if (!vm.complaint.types || !vm.complaint.types.length) {
            ComplaintFactory.lackOfCheckboxes();
            vm.blockSave = false;
            return;
        }

        if (vm.complaint.defects['0']) {
            let changeStatus = false;
            if (vm.complaint.status != vm.lastStatus) {
                changeStatus = true;
            }
            ComplaintFactory.saveComplaint(vm.complaint, vm.attachments, changeStatus, afterEdit);
        } else {
            ComplaintFactory.lackOfDefects();
            vm.blockSave = false;
            return;

        }
    }

    /**
     * Dodawanie załączników
     * @param {File} files pliki
     */
    function addFile(files) {
        ComplaintFactory.addFile(files, (attachments) => {
            vm.attachments = vm.attachments.concat(attachments);
            vm.edited = true;
        });
    }

    /**
     * Usuwanie załączników
     * @param {File} file plik
     */
    function removeFile(file) {
        ComplaintFactory.removeFile(file, () => {
            vm.attachments.splice(Core.fId(vm.attachments, file.id), 1);
            vm.edited = true;
        });
    }

    /**
     * Dodawanie usterki
     */
    function addDefect() {
        DefectFactory.openModalComplaintDefect(defectsData, false, true, defect => {
            if (defect) {
                vm.complaint.defects.push(defect);
                vm.edited = true;
            }
        });
    }

    /**
     * Dodawanie usterki
     * @param {object} defectData Usterka
     */
    function editDefect(defectData) {
        DefectFactory.openModalComplaintDefect(defectsData, defectData, vm.edit, defect => {
            if (defect) {
                vm.complaint.defects[Core.fId(vm.complaint.defects, defectData.id)] = defect;
                vm.edited = true;
            }
        });
    }

    /**
     * Dodawanie usterki
     * @param {object} defectData Usterka
     */
    function defectHistory(defectData) {
        DefectFactory.openModalComplaintDefectHistory(defectsData, defectData);
    }

    /**
     * Usuwanie usterki
     * @param {object} defect Usterka
     */
    function removeDefect(defect) {
        DefectFactory.removeDefect(defect, () => {
            vm.complaint.defects.splice(Core.fId(vm.complaint.defects, defect.id), 1);
            vm.edited = true;
        });
    }

    function addComment() {
        let emails = [];
        angular.forEach(vm.checkedMail, function(value, key) {
            if (value) {
                emails.push(key);
            }
        });
        ComplaintFactory.addComment(vm.comment, vm.complaint.id, emails,  comment => {
            if (comment) {
                vm.complaint.comments.push(comment);
                vm.checkedMail = {};
                vm.comment = null;
            }
        });
    }

    function toggleTypeSelection(type) {
        if (!vm.complaint.types) {
            vm.complaint.types = [];
        }
        var idx = vm.complaint.types.indexOf(type);
        if (idx > -1) {
            vm.complaint.types.splice(idx, 1);
        } else {
            vm.complaint.types.push(type);
        }
        vm.edited = true;
    }

}
