import 'angular-ui-bootstrap';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { EventBusService } from '@icc/common/event-bus.service';

export class ModalSystemComparisonColorsCtrl {

    hasCream = false;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private config,
        private configDefault,
        private ColorsService,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService
    ) { }

    setColorType(type) {
        this.ColorsService.setColorType(
            type,
            false,
            this.config,
            this.configDefault,
            this.filterColors.bind(this)
        );
    }

    openModalWood() {
        this.ColorsService.openModalWood(
            this.config,
            this.configDefault,
            this.setDefaults.bind(this)
        );
    }

    filter = group => false;
    filterColors = color => false;

    setDefaults() {
        this.ColorsService.loadData();
        this.ColorsService.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsService.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsService.setDefaultWood(this.config, this.configDefault);
        this.ColorsService.loadColorsByWood(this.config);
        this.ColorsService.setDefaultColors(
            this.config,
            this.configDefault,
            this.filter.bind(this)
        );
        this.ColorsService.setDefaultColorTypeForColors(this.config, this.configDefault);
    }

    close() {
        this.$uibModalInstance.close();
    }
}
ModalSystemComparisonColorsCtrl.$inject = [
    '$uibModalInstance',
    'config',
    'configDefault',
    'ColorsService',
    'ConfigurationsService',
    'EventBusService',
];
