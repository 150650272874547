import { IEnvConfig, IIccConfig } from '@icc/config';
import { ConfigurationImpl, ActiveConfigurationImpl } from '@icc/common/configurations/configurations.service';
import { Observable, Subject } from 'rxjs';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';

export type ReturnButtonVariant = 'ADD' | 'SAVE' | 'OK' | 'GENERATE_THUMBNAIL' | 'ADD_TO_BASKET' | 'ADD_TO_OFFER' | 'PRINT';
export type ReturnButton = {notifyUrl: string, returnUrl: string, returnButton: string, materialIcon: string}

export interface ConfiguratorBaseOptions {
    type: ConfigurationType;
    preset: 'b2c' | 'b2b';
    userId?: string;
    homeButtonText?: string;
    clientId?: number;
    dealerSellerId?: number;
    userLevel: 'klient' | 'dealer' | 'seller' | null;
    userName: string;
    marketId?: number;
    currency?: any;
    languageCode: string;
    returnCallback?: (data: any) => any;
    notifyCallback?: (data: any) => Observable<any>;
    homeUrl?: string;
    lastUrl?: string;
    returnUrl?: string;
    errorUrl?: string;
    notifyUrl?: string;
    actions?: {
        title: string;
        show: (data: any) => boolean;
        url?: string;
        callback?: (data: any) => any;
    }[];
    returnButton: ReturnButtonVariant | (() => ReturnButtonVariant);
    additionalReturnButtons?: ReturnButton[] | (() => ReturnButton[]);
    projectName: string;
    config: IIccConfig;
    mode: 'loadStartState' | 'loadAllDefaults' | 'loadDefaultsFromStateState' | 'layoutThumbnail' | 'loadDefaultsFromStateByShortcode';
    startState?: ConfigurationImpl;
    startStateRef?: string;
    configurationEncryptedId?: string;
    configurationAction?: 'EDIT';
    configurationShortcode?: string;
    defaultState?: ActiveConfigurationImpl;
    defaultWindowLayoutId?: number;
    dimensionUnit: 'mm' | 'inch';
    theme?: IIccConfig['Theme'];
    logo: string;
    startStep?: 'FIRST' | 'DEFAULT';
    embeddedInB2B?: boolean;
    secretToken?: string;
    secretTokenHeader?: string;
    payloadEncoding?: 'json' | 'base64';
}

export interface ConfiguratorOptions {
    type: ConfigurationType;
    preset: 'b2c' | 'b2b';
    userData?: any;
    userLevel: 'klient' | 'dealer' | 'seller' | null;
    userName: string;
    user?: any;
    machine?: string;
    marketId?: number;
    currency?: any;
    languageCode: string;
    returnCallback?: (data: any) => any;
    notifyCallback?: (data: any) => Observable<any>;
    homeUrl?: string;
    lastUrl?: string;
    returnUrl?: string;
    notifyUrl?: string;
    actions?: {
        title: string;
        show: (configuration: ActiveConfigurationImpl, isBlocked: boolean) => boolean;
        url?: string;
        callback?: (data: any) => any;
        resetConfigurator?: boolean;
    }[];
    returnButton: ReturnButtonVariant | ((configuration: ActiveConfigurationImpl, options: ConfiguratorOptions, data: LoadedConfiguratorsDataValue) => ReturnButtonVariant);
    additionalReturnButtons?: ReturnButton[] | ((configuration: ActiveConfigurationImpl, options: ConfiguratorOptions, data: LoadedConfiguratorsDataValue) => ReturnButton[]);
    projectName: string;
    config: IIccConfig;
    env: IEnvConfig,
    mode: 'loadStartState' | 'loadAllDefaults' | 'loadDefaultsFromStateState' | 'layoutThumbnail' | 'loadDefaultsFromStateByShortcode';
    startState?: ConfigurationImpl;
    defaultState?: ActiveConfigurationImpl;
    configurationEncryptedId?: string;
    configurationAction?: 'EDIT';
    configurationShortcode?: string;
    defaultWindowLayoutId?: number;
    dimensionUnit: 'mm' | 'inch';
    theme?: IIccConfig['Theme'];
    logo: string;
    startStep?: 'FIRST' | 'DEFAULT';
    init$?: Subject<any>;
    configuratorDataLoaded?: boolean;
    homeButtonText?: string
    embeddedInB2B?: boolean;
    domain?: {
        id: number;
        domain: string;
        insert_head_begin: string;
        insert_head_end: string;
        insert_body_end: string;
        theme_logo: string;
        theme_favicon: string;
    };
    measurementsAttachments?: any[];
}

export type ConfigurationType =
    | 'accessory'
    | 'awning'
    | 'complementary_goods'
    | 'coupled_window'
    | 'door'
    | 'folding_door'
    | 'garage_door'
    | 'hs'
    | 'sliding_door'
    | 'mosquito'
    | 'roller_shutter'
    | 'external_blind'
    | 'pleated_blind'
    | 'window'
    | 'winter_garden';

export const initialOptions: ConfiguratorOptions = {
    type: 'window',
    preset: 'b2c',
    userLevel: null,
    userName: '',
    languageCode: '',
    mode: 'loadAllDefaults',
    config: null,
    env: null,
    projectName: 'icc',
    dimensionUnit: 'mm',
    logo: '',
    returnButton: 'ADD'
};
