import { Injectable } from '@angular/core';
import { IssuesService as AbstractIssuesService, Issue, IssueLevel, StepsService, SimpleRegisterOptions, InfoService } from '@icc/helpers';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';
import { StartedChangingStepValue } from '@icc/legacy/configurator/steps/StepFactory';
import { logger, Logger } from '@icc/common/Logger';
import * as Raven from 'raven-js';
import { EventBusService } from '@icc/common/event-bus.service';
import { TranslateService } from '@icc/common/translate.service';

@Injectable()
export class IssuesService extends AbstractIssuesService {
    protected modalOpened = true;
    protected validateFunctions: ((conf: ActiveConfiguration) => any)[] = [];


    constructor(
        private eventBusService: EventBusService,
        private stepsService: StepsService,
        private translateService: TranslateService,
        private infoService: InfoService
    ) {
        super();
        this.eventBusService.subscribe<StartedChangingStepValue>('startedChangingStep', data => {
            const issuesBlockingSteps = data.activeConfiguration.Issues.filter(
                (issue: Issue) => issue.blockStepsAfter
            );

            if (issuesBlockingSteps.length > 0) {
                const minAllowedStep = issuesBlockingSteps
                    // tslint:disable-next-line:no-non-null-assertion
                    .map((issue: Issue) => stepsService.getStepByCode(issue.blockStepsAfter!))
                    .reduce((prev, cur) => (cur < prev ? cur : prev), Infinity);
                if (data.value.nextStep.i > minAllowedStep) {
                   data.value.cancel();
                }
            }
        });
    }

    registerDataProblem(
        key: string,
        description: string,
        conf: ActiveConfiguration,
        options: {
            level?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: { [id: string]: string };
            extra?: any;
        }
    ) {
        const registerOptions: SimpleRegisterOptions = options;
        this.simpleRegister(
            key,
            description,
            this.translateService.instant(
                'INTERFACE|Wybrany produkt nie może być aktualnie skonfigurowany. Skontaktuj się ze swoim opiekunem po stronie producenta'
            ),
            conf,
            registerOptions
        );
    }

    showMessage(issue: Issue) {
        if (this.modalOpened) {
            this.modalOpened = false;
            this.infoService.openWarning(issue.message).finally(() => {
                this.modalOpened = true;
            });
        }
    }
    register(issue: Issue, conf: ActiveConfiguration) {
        try {
            if (!issue.description) {
                return;
            }
            if (issue.showMessage) {
                this.showMessage(issue);
            }
            if (conf.Issues.map(i => i.key).indexOf(issue.key) === -1) {
                conf.Issues.push(issue);
                const levels: {
                    [key in IssueLevel]: {
                        logger: Logger['debug'] | Logger['info'] | Logger['warn'] | Logger['err'] | null,
                        raven: string | null
                    }
                } = {
                    [IssueLevel.NONE]: {
                        logger: null,
                        raven: null,
                    },
                    [IssueLevel.DEBUG]: {
                        logger: logger.debug.bind(logger),
                        raven: 'info',
                    },
                    [IssueLevel.INFO]: {
                        logger: logger.info.bind(logger),
                        raven: 'info',
                    },
                    [IssueLevel.WARN]: {
                        logger: logger.warn.bind(logger),
                        raven: 'warning',
                    },
                    [IssueLevel.ERROR]: {
                        logger: logger.err.bind(logger),
                        raven: 'error',
                    },
                    [IssueLevel.FATAL]: {
                        logger: logger.err.bind(logger),
                        raven: 'error',
                    },
                };
                if (levels[issue.level].logger) {
                    levels[issue.level].logger('ISSUE', issue.description);
                }
                if (levels[issue.logLevel].raven) {
                    setTimeout(() => {
                        Raven.captureMessage(issue.description, {
                            level: levels[issue.level].raven,
                            tags: issue.tags,
                            extra: issue.extra,
                        });
                    }, 100);
                }
            }
        } catch (error) {
            logger.error(error);
        }
    }
    unregister(issueKey: Issue['key'], conf: ActiveConfiguration) {
        const issue = conf.Issues.find(i => i.key === issueKey);
        conf.Issues = conf.Issues.filter(i => i.key !== issueKey);
        if (issue) {
            this.infoService.dismissWarning(issue.message);
        }
    }
    simpleRegister(
        key: string,
        description: string,
        message: string,
        conf: ActiveConfiguration,
        options: {
            level?: IssueLevel;
            logLevel?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: { [id: string]: string };
            extra?: any;
        }
    ) {
        this.register(
            new Issue({
                key,
                description,
                message,
                level: options.level == null ? IssueLevel.WARN : options.level,
                logLevel: options.logLevel == null ? options.level : options.logLevel,
                showMessage: conf.systemComparison ? false : true,
                blockAddToOffer: true,
                blockStepsAfter: null,
                noPrice: true,
                tags: {},
                ...options,
            }),
            conf
        );

        return true;
    }

    addValidateFunction(validateFunction: (conf: ActiveConfiguration) => any) {
        if (this.validateFunctions.indexOf(validateFunction) === -1) {
            this.validateFunctions.push(validateFunction);
        }
    }

    validateAll(conf: ActiveConfiguration) {
        this.modalOpened = false;
        for (let i = 0; i < this.validateFunctions.length; i++) {
            this.validateFunctions[i](conf);
        }
        this.modalOpened = true;
    }
}
