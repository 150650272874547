import { PleatedBlindActiveConfiguration } from './PleatedBlindActiveConfiguration';
import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { Accessory } from './parts/common';
import { core } from '../helpers';
import { Shape } from '@icc/window';

export class PleatedBlindConfiguration implements Configuration {
    $version = 1;
    type: 'pleated_blind' = 'pleated_blind';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    accessories: Accessory[] = [];
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];
    
    shape: Shape = {
        shape: 'rect',
        width: 1500,
        height: 1500,
        circuit: 6000,
        s1: 1500,
        s2: 0,
        s3: 0,
        h1: 1500,
        h2: 0,
        h3: 1500,
    };
    mainFabric;
    additionalFabric;
    profilesColor;
    System;
    variant;
    drive;
    driveSide: any = 'left';
    guidanceType;
    closureType;

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (PleatedBlindConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (PleatedBlindActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts =
                    configuration.PriceAfterDiscounts || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.attributes = configuration.attributes;

                this.shape = configuration.Shape;
                this.System = configuration.System;
                this.variant = configuration.variant;
                this.mainFabric = configuration.mainFabric;
                this.additionalFabric = configuration.additionalFabric;
                this.profilesColor = configuration.profilesColor;
                this.drive = configuration.drive;
                this.driveSide = configuration.driveSide;
                this.guidanceType = configuration.guidanceType;
                this.closureType = configuration.closureType;
                if (configuration.accessories) {
                    this.accessories = configuration.accessories.map<Accessory>(
                        accessory => new Accessory(accessory)
                    );
                }
            }
        }
    }

    public static is(configuration): configuration is PleatedBlindConfiguration {
        return configuration instanceof PleatedBlindConfiguration || configuration.$version;
    }
}
