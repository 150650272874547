import { Injectable, Inject } from '@angular/core';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { MountingMethod, Measurement } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ModalService, InfoService } from '@icc/helpers';
import { MountingMethodPageComponent } from 'libs/configurator/window/src/lib/mounting-method-page/mounting-method-page.component';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service'
import { TranslateService } from '@icc/common/translate.service';
import { isNull } from 'util';
import { isDefined } from '@angular/compiler/src/util';


@Injectable()
export class MeasurementsService {
    mountingMethods: MountingMethod[] = [];
    measurements: Measurement[] = [];
    showMeasurementsWarning: {show: boolean, constructions: Number[]} = {show: false, constructions: []};
    measurementsModalInstance:any = null;
    shapeData = {width: 0, height: 0}

    constructor(
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private infoService: InfoService,
        private translateService: TranslateService,
        private userService: UserService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadMountingMethods(data.value);
            }
        );
    }

    loadMountingMethods(data) {
        this.mountingMethods = data.mountingMethods;
    }

    initMeasurements(conf?: WindowActiveConfiguration) {
        const user = this.userService.get();
        if(isNull(conf) || !isDefined(conf)) {
            conf = this.configurationsService.conf.Current as WindowActiveConfiguration;
        }
        if (conf.Measurements && Common.isArray(conf.Measurements)) {
            this.measurements = core.copy(conf.Measurements);
        }
        if (conf.Measurements.length > Number(conf.Quantity)) {
            this.measurements = core.copy(conf.Measurements).slice(0, Number(conf.Quantity) - 1);
        } else if (conf.Measurements.length < Number(conf.Quantity)) {
            for (let i = conf.Measurements.length; i < Number(conf.Quantity); i++) {
                this.measurements.push({
                    mountingMethod: this.mountingMethods[0],
                    hightInside: 0,
                    hightOutside: 0,
                    hightWarmingLayer: 0,
                    crossHightInside: 0,
                    crossHightOutside: 0,
                    widthInside: 0,
                    widthOutside: 0,
                    widthWarmingLayer: 0,
                    fit: 'frame',
                    distanceOut: {
                        top: user.dealer && user.dealer.distance_out_top
                            ? user.dealer.distance_out_top
                            : (this.config().IccConfig.Settings.distance_out_top
                                ? this.config().IccConfig.Settings.distance_out_top
                                : ''),
                        bottom: user.dealer && user.dealer.distance_out_bottom
                            ? user.dealer.distance_out_bottom
                            : (this.config().IccConfig.Settings.distance_out_bottom
                                ? this.config().IccConfig.Settings.distance_out_bottom
                                : ''),
                        left: user.dealer && user.dealer.distance_out_left
                            ? user.dealer.distance_out_left
                            : (this.config().IccConfig.Settings.distance_out_left
                                ? this.config().IccConfig.Settings.distance_out_left
                                : ''),
                        right: user.dealer && user.dealer.distance_out_right
                            ? user.dealer.distance_out_right
                            : (this.config().IccConfig.Settings.distance_out_right
                                ? this.config().IccConfig.Settings.distance_out_right
                                : '')
                    },
                    enlargement: {
                        top: '',
                        left: '',
                        right: '',
                    },
                    distanceIn: {
                        top: '',
                        left: '',
                        right: '',
                    },
                    notes: '',
                    attachments: [],
                } as Measurement);
            }
        }
        if (
            this.configurationsService.conf.measurementsAttachments
            && this.configurationsService.conf.measurementsAttachments.length
        ) {
            this.configurationsService.conf.measurementsAttachments.forEach(attachment => {
                if (
                    !this.measurements[attachment.position_number].attachments.some(
                        el => el.tmp_id === attachment.tmp_id
                    )
                ) {
                    this.measurements[attachment.position_number].attachments.push({
                        name: attachment.name,
                        type: attachment.type,
                        position_number: attachment.position_number,
                        data: attachment._attachments[Object.keys(attachment._attachments)[0]].data,
                        tmp_id: attachment.tmp_id,
                        saved: true,
                    });
                }
            });
        }
    }

    checkMeasurementsWarning() {
        return this.showMeasurementsWarning;
    }

    checkField(measurements, field, index) {
        return measurements && measurements[index] && measurements[index].mountingMethod.fields.indexOf(field) > -1;
    }

    checkMeasurementsValues(measurements) {
        this.showMeasurementsWarning.show = false;
        this.showMeasurementsWarning.constructions = [];
        const tests = measurements.map((e, index) => {
            let valid = (this.checkField(measurements, 'enlargementTop', index) && this.checkField(measurements, 'enlargementLeft', index) && this.checkField(measurements, 'enlargementRight', index)
                            ? (e.enlargement.top !== '' && e.distanceOut.bottom !== '' && e.enlargement.left !== '' && e.enlargement.right !== '')
                            : (e.distanceOut.top !== '' && e.distanceOut.bottom !== '' && e.distanceOut.left !== '' && e.distanceOut.right !== '')
                        );
            valid = valid && (this.checkField(measurements, 'distanceInTop', index) && this.checkField(measurements, 'distanceInLeft', index) && this.checkField(measurements, 'distanceInRight', index)
                                ? (e.distanceIn.top !== '' && e.distanceIn.left !== '' && e.distanceIn.right !== '')
                                : true
                            );
            return valid
        })

        tests.forEach((test, index) => {
            if(!test) {
                this.showMeasurementsWarning.constructions.push(index + 1);
            }
        })

        const valid = tests.reduce((a, b) => {return a && b})
        if(!valid) {
            this.showMeasurementsWarning.show = true;
        }

        return valid
    }

    openModalMountingMethod(measurement: Measurement) {
        const modalInstance = this.modalService.open({
            component: 'mountingMethodModal',
            resolve: {
                mountingMethods: () => this.mountingMethods,
                selMountingMethod: () => core.copy(measurement.mountingMethod),
            },
            pageComponent: MountingMethodPageComponent
        });

        modalInstance.result.then(data => {
            if (data) {
                measurement.mountingMethod = data;
            }
        });
    }

    openModalMeasurements(shapeData) {
        const conf = this.configurationsService.conf.Current as WindowActiveConfiguration;
        this.shapeData = shapeData;
        this.initMeasurements(conf);
        this.measurementsModalInstance = this.modalService.open({
            component: 'measurements',
            resolve: {
                measurements: () => JSON.parse(JSON.stringify(this.measurements)),
            },
            pageComponent: MountingMethodPageComponent
        });
        return this.measurementsModalInstance.closed;
    }

    saveMeasurements(measurements, autoClose = true) {
        const conf = this.configurationsService.conf.Current as WindowActiveConfiguration;
        const valid = this.checkMeasurementsValues(measurements)
        if (valid) {
            measurements.forEach(measurement => {
                measurement.attachments.forEach((attachment, index) => {
                    delete attachment.data;
                    if (!attachment.saved && !attachment.deleted) {
                        conf.Attachments.push(attachment);
                    } else if (attachment.saved && attachment.deleted) {
                        if (!conf.DeletedAttachments) {
                            conf.DeletedAttachments = [];
                        }
                        conf.DeletedAttachments.push(attachment);
                    }
                });
            });
            conf.Measurements = core.copy(measurements);
            if (autoClose) {
                this.modalInstanceClose();
            }
        }
        return valid;
    }

    saveAndCalcMeasurements(measurements, autoClose = true) {
        return new Promise((resolve, reject) => {
            const transpose = (array) => array[0].map((col, i) => array.map(row => row[i]));
            const conf = this.configurationsService.conf.Current as WindowActiveConfiguration;
            const valid = this.saveMeasurements(measurements);
            if (valid) {
                const extensions = {
                    vertical: (conf.SideProfiles.filter((profile) => profile.side === "top").map(p => p.width).reduce(((a, b) => a+b), 0)
                            + conf.SideProfiles.filter((profile) => profile.side === "bottom").map(p => p.width).reduce(((a, b) => a+b), 0)), 
                    horizontal: (conf.SideProfiles.filter((profile) => profile.side === "left").map(p => p.width).reduce(((a, b) => a+b), 0)
                                + conf.SideProfiles.filter((profile) => profile.side === "right").map(p => p.width).reduce(((a, b) => a+b), 0)),
                }
                const dimensions_set = transpose(measurements.map((e, index) => {
                    return (this.checkField(measurements, 'enlargementTop', index) && this.checkField(measurements, 'enlargementLeft', index) && this.checkField(measurements, 'enlargementRight', index) && e.fit === 'frame'
                            ? [(  e.hightOutside
                                + e.enlargement.left
                                + e.enlargement.right
                                - extensions.vertical
                            ),
                            (  e.widthOutside
                                + e.enlargement.top
                                - e.distanceOut.bottom
                                - extensions.horizontal
                            ), "WEGAREK"]
                            
                            : [(  e.widthInside
                                - e.distanceOut.left
                                - e.distanceOut.right
                                - extensions.horizontal
                            ), 
                            (  e.hightInside
                                - e.distanceOut.bottom
                                - e.distanceOut.top
                                - extensions.vertical
                            ), "NIE WEGAREK"]
                        )
                }))
                const dimensions = {
                    width: Math.min.apply(0, dimensions_set[0]),
                    height: Math.min.apply(0, dimensions_set[1])
                }

                const slot = {
                    width: Math.min.apply(0, measurements.map((m) => m.widthInside)),
                    height: Math.min.apply(0, measurements.map((m) => m.hightInside)),
                }

                if (dimensions.width + extensions.horizontal > slot.width || dimensions.height + extensions.vertical > slot.height) {
                    this.infoService.openConfirmModal(
                        this.translateService.instant('OFFER|Potwierdzenie przeliczenia'),
                        this.translateService.instant('OFFER|Okno z poszerzeniami może nie zmieścić się w otworze. Czy na pewno chcesz przeliczyć?.'),
                        [
                            {
                                name: this.translateService.instant('INTERFACE|Nie'),
                                callback: () => {},
                                accent: true,
                            },
                            {
                                name: this.translateService.instant('INTERFACE|Tak'),
                                callback: () => {
                                    this.shapeData.width = dimensions.width;
                                    this.shapeData.height = dimensions.height;
                                    if(autoClose) this.modalInstanceClose();
                                    conf.ChangedDimensions = true;
                                    resolve(this.shapeData);
                                },
                            },
                        ]
                    );
                } else {
                    this.shapeData.width = dimensions.width;
                    this.shapeData.height = dimensions.height;
                    if(autoClose) this.modalInstanceClose();
                    conf.ChangedDimensions = true;
                    resolve(this.shapeData);
                }
            } else {
                resolve(valid);
            }
        });
    }

    discard() {
        this.modalInstanceClose();
    }

    modalInstanceClose() {
        if (this.measurementsModalInstance) {
            this.measurementsModalInstance.close();
        }
    }

    async addFiles(event, measurementNumber) {
        if (event.target.files && event.target.files.length > 0) {
            await [].slice.call(event.target.files).forEach(async file => {
                const fileContents = (await this.readFile(file)) as any;
                file.position_number = measurementNumber;
                file.data = fileContents.split(',')[1];
                this.measurements[measurementNumber].attachments.push(file);
            });
        }
    }

    async readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => resolve((event.target as any).result);
            reader.readAsDataURL(file);
        });
    }

    downloadFile(file) {
        setTimeout(() => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(core.b64toBlob(file.data));
            downloadLink.download = file.name;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }, 0);
    }

    removeFile(index, measurementNumber) {
        if (this.measurements[measurementNumber].attachments[index].saved) {
            this.measurements[measurementNumber].attachments[index].deleted = 1;
        } else {
            this.measurements[measurementNumber].attachments.splice(index, 1);
        }
    }

    pull() {
        return (this.configurationsService.conf.Current as WindowActiveConfiguration).ChangedDimensions;
    }
}
