import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { fromSharedActions } from './shared.actions';
import { sharedQuery } from './shared.selectors';
import { ComponentType } from '@angular/cdk/portal';
import { PageComponent } from '@icc/helpers';
import { ConfigurationsService, EventBusService } from '@icc/common';
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedFacade {
    constructor(private store: Store<any>, private eventBusService: EventBusService) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.configuratorInitialized$.next(true);
        });
    }

    config$ = this.store.select(sharedQuery.getConfig);
    messages$ = this.store.select(sharedQuery.getMessages);
    languageAndProject$ = this.store.select(sharedQuery.getLanguageAndProject);
    currency$ = this.store.select(sharedQuery.getCurrency);
    fullscreen$ = this.store.select(sharedQuery.getFullscreen);
    colorsStepMode$ = this.store.select(sharedQuery.getColorsStepMode);
    systemStepMode$ = this.store.select(sharedQuery.getSystemStepMode);
    lightsGlazingStepMode$ = this.store.select(sharedQuery.getLightsGlazingStepMode);
    options$ = this.store.select(sharedQuery.getOptions);
    savingButton$ = this.store.select(sharedQuery.getSavingButton);
    returnButton$ = this.store.select(sharedQuery.getReturnButton);
    additionalReturnButtons$ = this.store.select(sharedQuery.getAdditionalReturnButtons);

    activeSearch$ = this.store.select(sharedQuery.getActiveSearch);
    searchValue$ = this.store.select(sharedQuery.getSearchValue);

    previewAnimationDone$ = new Subject();
    configuratorInitialized$ = new BehaviorSubject<boolean>(false);

    closePage(result?: any) {
        this.store.dispatch(
            new fromSharedActions.ClosePage({
                result,
            })
        );
    }

    openPage(
        id: string,
        page: ComponentType<PageComponent>,
        data?: { [key: string]: string | Function | Array<string | Function> | Object }
    ) {
        this.store.dispatch(new fromSharedActions.OpenPage({ page, id, data }));
    }

    closeFilterSheet(result?: any) {
        this.store.dispatch(
            new fromSharedActions.CloseFilterSheet({
                result,
            })
        );
    }

    openFilterSheet(
        id: string,
        page: ComponentType<PageComponent>,
        data?: { [key: string]: string | Function | Array<string | Function> | Object }
    ) {
        this.store.dispatch(new fromSharedActions.OpenFilterSheet({ page, id, data }));
    }

    enableFullscreen() {
        this.store.dispatch(new fromSharedActions.EnableFullscreen());
    }

    disableFullscreen() {
        this.store.dispatch(new fromSharedActions.DisableFullscreen());
    }

    changeColorsStepMode(mode: 'simple' | 'advanced' | 'door_extended') {
        this.store.dispatch(new fromSharedActions.ChangedColorsStepMode(mode));
    }

    changeSystemStepMode(mode: 'list' | 'details') {
        this.store.dispatch(new fromSharedActions.ChangedSystemStepMode(mode));
    }

    changeLightsGlazingStepMode(mode: 'list' | 'extra') {
        this.store.dispatch(new fromSharedActions.ChangedLightsGlazingStepMode(mode));
    }

    enableSearch() {
        this.store.dispatch(new fromSharedActions.EnableSearch());
    }

    disableSearch() {
        this.store.dispatch(new fromSharedActions.DisableSearch());
    }

    stopSearch() {
        this.store.dispatch(new fromSharedActions.StopSearch());
    }

    updateSearch(value: string) {
        this.store.dispatch(new fromSharedActions.UpdateSearch(value));
    }
}
