import { ProfilesService } from '@icc/common/profiles.service';
import { logger, core } from '@icc/common/helpers';
import {
    isArray,
    ModalService,
    isUndefined,
    IssuesService,
    InfoService,
    isObject,
    isDefined,
    isString,
    isNotNullOrUndefined,
    IssueLevel,
} from '@icc/helpers';
import {
    TranslateService,
    AppConfigFactory,
    APP_CONFIG,
    ConfigurationsService,
    ConfiguratorsDataService,
    ColorsDefaultsService,
    EventBusService,
    ValidationService,
    WindowActiveConfiguration,
    Common,
} from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ConstructionLimitationService } from '../dimensions/construction-limitation.service';
import { HandlesService } from '../handles/handles.service';
import { PriceService } from '@icc/price';
import { MuntinColorsService } from '../muntins/muntin-colors.service';
import { FillingsService } from '../glazings/fillings.service';
import { ColorsPageComponent } from 'libs/configurator/window/src/lib/colors-page/colors-page.component';
import { WoodsPageComponent } from 'libs/configurator/window/src/lib/woods-page/woods-page.component';
import { IccColor, IccWood } from '@icc/common/data-types';
import { Side, IccColorGroup } from '@icc/common/data-types/ColorGroup';
import { ColorType } from '@icc/common/configurations/parts/common';
import { ColorsService as RollerColorsService } from '../../roller_shutter/colors.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';

@Injectable()
export class ColorsService {
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    /**
     * Kolory konstrukcji, np. okleiny, lazury
     * @memberof ColorsService
     * @type {Array}
     */
    windowColors: IccColor[] = [];
    /**
     * Rodzaje drewna
     * @memberof ColorsService
     * @type {Array}
     */
    woods: IccWood[] = [];
    /**
     * Czy dane zostały załadowane
     * @memberof ColorsService
     * @type {Boolean}
     */
    loadedData = false;

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private constructionLimitationService: ConstructionLimitationService,
        private handlesService: HandlesService,
        private issuesService: IssuesService,
        private priceService: PriceService,
        private colorMappingService: ColorMappingService,
        private rollerColorsService: RollerColorsService,
        private colorsDefaultsService: ColorsDefaultsService,
        private eventBusService: EventBusService,
        private validationService: ValidationService,
        private infoService: InfoService,
        private muntinColorsService: MuntinColorsService,
        private fillingsService: FillingsService,
        private profilesService: ProfilesService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.eventBusService.subscribeWithoutConfiguration('reloadColorsData', () => {
            this.reloadColors();
        });

        this.eventBusService.subscribe('setFillingColor', data => {
            const conf = data.activeConfiguration as WindowActiveConfiguration;
            const confDefault = data.defaultConfiguration as WindowActiveConfiguration;
            if (conf.type === 'door') {
                this.setDefaultColors(conf, confDefault, null, true);
            }
        });
    }

    /**
     * Inicjalizuje działanie ColorsService.
     * @private
     */
    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }
        this.issuesService.addValidateFunction(this.validate.bind(this));
        this.loadData();
        this.setDefaults();
        this.loadedData = true;
    }

    /**
     * Ładuje dane
     */
    loadData() {
        if (isArray(this.configuratorsDataService.data.woodTypes)) {
            this.woods = this.configuratorsDataService.data.woodTypes;
        }
    }

    /**
     * Ustawia domyślne kolory i rodzaj drewna po uprzednim przefiltrowaniu ich po systemie.
     * @memberof ColorsService
     */
    setDefaults(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (!isObject(conf.System) || isUndefined(conf.System.id)) {
            return;
        }
        this.hasSystemAlushell(conf, defaultConf);
        this.setDefaultWood(conf, defaultConf);
        this.loadColorsBySystem(null, conf);
        this.setDefaultColorTypeForSystem(conf, defaultConf);
        this.loadColorsByWood(conf);
        this.setDefaultColors(conf, defaultConf);
        this.setDefaultColorTypeForColors(conf, defaultConf);

        if (
            this.config().IccConfig.Configurators.alushell
            && conf.System.alushell
            && conf.System.alushell_active
        ) {
            conf.HasAlushell = true;
        }
        this.priceService.count();

        const value = {
            windowColors: this.windowColors,
            woods: this.woods,
        };

        this.eventBusService.post({
            key: 'setDefaultColors',
            value,
            conf,
            defaultConf,
        });
    }

    reloadColors() {
        this.loadColorsBySystem();
        this.loadColorsByWood();
    }

    /**
     * Ustawia domyslne kolory po zmianie rodzaju drewna (po odpowiednim ich przefiltrowaniu).
     * @private
     */
    setDefaultsOnChangeWoodType(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        if (
            ['Opaque', 'Transparent'].indexOf(conf.ColorType) > -1
            || this.config().IccConfig.Configurators.woodBicolor
        ) {
            this.loadColorsBySystem(filterColors);
            this.loadColorsByWood(conf);
            this.setDefaultColors(conf, confDefault);
        }
    }

    /**
     * Ładuje kolory przefiltrowane do wybranego systemu.
     * @private
     */
    loadColorsBySystem(
        filterColors: (color?: IccColor) => boolean | null = null,
        conf = this.configurationsService.conf.Current
    ) {
        if (!isObject(conf.System) || isUndefined(conf.System.id)) {
            return;
        }

        let allColors = filterColors
            ? this.configuratorsDataService.data.windowColorsAll.filter(filterColors)
            : core.copy(this.configuratorsDataService.data.windowColors[conf.System.id]);

        const colorGroups = this.getColorsGroups(filterColors, allColors, conf);
        // sortuj kolory względem grup i nazw
        allColors = allColors.sort((a, b) => {
            if (isUndefined(a.groups) || a.groups === null) {
                return 1;
            } else if (isUndefined(b.groups) || b.groups === null) {
                return -1;
            }
            let pos = 0,
                x = 0;
            // najpierw sortuj względem grup
            let compareE1 = 9999999;
            for (; x < a.groups.length; ++x) {
                pos = colorGroups.indexOf(String(a.groups[x]));
                if (pos > -1 && compareE1 > pos) {
                    compareE1 = pos;
                }
            }
            let compareE2 = 9999999;
            for (x = 0; x < b.groups.length; ++x) {
                pos = colorGroups.indexOf(String(b.groups[x]));
                if (pos > -1 && compareE2 > pos) {
                    compareE2 = pos;
                }
            }
            if (compareE1 < compareE2) {
                return -1;
            } else if (compareE1 > compareE2) {
                return 1;
            } else {
                return Number(a.order) - Number(b.order);
            }
        });

        core.clear(this.windowColors);
        core.rewriteArray(this.windowColors, allColors);
        allColors = [];
    }

    getColorsGroups(
        filterColors: ((color?: IccColor) => boolean) | null,
        allColors: IccColor[],
        conf = this.configurationsService.conf.Current
    ) {
        // pobierz grupy kolorów z dostępnych kolorów
        const groups: (number | string)[] = [];
        for (let x = 0; x < allColors.length; ++x) {
            const colorGroups = allColors[x].groups;
            if (isNotNullOrUndefined(colorGroups)) {
                for (let y = 0; y < colorGroups.length; ++y) {
                    if (groups.indexOf(colorGroups[y]) < 0) {
                        groups.push(colorGroups[y]);
                    }
                }
            }
        }
        // pobierz grupy kolorów dla danej linii (sprawdź czy grupa kolorów dla linii nie jest pusta)
        const colorGroups = this.configuratorsDataService.data.windowColorGroups.filter(el => {
            const val =
                isArray(el.systems)
                && isArray(groups)
                && groups.indexOf(el.id) > -1
                && (filterColors
                    || (conf.System
                        && el.systems.indexOf(conf.System.id) > -1
                        && el.target.indexOf('show') > -1
                        && (conf.System.type !== 'wood'
                            || (isArray(el.woodTypes)
                                && el.woodTypes.indexOf(conf.Wood.id) > -1))));
            return val;
        });
        const colorGroupsIds: string[] = [];
        for (let x = 0; x < colorGroups.length; ++x) {
            colorGroupsIds[x] = colorGroups[x].id;
        }
        return colorGroupsIds;
    }

    /**
     * Ładuje kolory przefiltrowane wg wybranego rodzaju drewna.
     * @private
     */
    loadColorsByWood(conf = this.configurationsService.conf.Current) {
        if (isUndefined(conf)) {
            return;
        }
        const allColors = core.copy(this.windowColors);
        core.clear(this.windowColors);
        for (let i = 0; i < allColors.length; i++) {
            const color = allColors[i];
            if (
                conf.ColorType === 'Transparent'
                || this.config().IccConfig.Configurators.woodBicolor
            ) {
                if (
                    (this.config().IccConfig.Configurators.woodBicolor && !color.wood_type)
                    || (conf.Wood && color.wood_type === conf.Wood.id)
                ) {
                    this.windowColors.push(color);
                }
            } else {
                if (isNaN(parseInt(String(color.wood_type)))) {
                    this.windowColors.push(color);
                }
            }
        }
    }

    /**
     * Ustawia domyślny rodzaj drewna.
     * @private
     */
    setDefaultWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        if (conf.System.type === 'wood') {
            if (
                isArray(this.woods)
                && this.woods.length > 0
                && isUndefined(core.fId(this.woods, conf.Wood.id))
            ) {
                conf.Wood = core.copy(this.woods[0]);
                confDefault.Wood = core.copy(this.woods[0]);
            }
        } else {
            conf.Wood = {};
            confDefault.Wood = {};
        }
    }

    /**
     * Ustawia domyslne kolory
     * @private
     */
    setDefaultColors(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null,
        updateDefaults = false
    ) {
        if (isObject(conf.Colors) || isObject(confDefault.Colors)) {
            if (isObject(confDefault.Colors)) {
                conf.Colors = core.copy(confDefault.Colors);
            }
            let colors = conf.Colors;
            const colorsDefault = confDefault.Colors;
            if (isObject(colors.frame) && isObject(colors.sash)) {
                const allColors = this.windowColors.filter(
                    color =>
                        color.type !== 'RAL'
                        || ((conf.System.type !== 'wood' || conf.ColorType !== 'Transparent')
                            && color.type === 'RAL')
                );

                const pauseId = this.eventBusService.pause([
                    'setConstructionColor',
                    'changedSashes',
                ]);
                try {
                    const colorGroups = this.getColorsGroups(filterColors, allColors);
                    if (
                        !colors.frame.outer
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.frame.outer.id, colors.frame.outer.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || !colorGroups.some(group => colors.frame.outer.groups.includes(group))
                        || (colors.frame.outer.isDefault && updateDefaults)
                    ) {
                        colors.frame.outer = {};
                        colorsDefault.frame.outer = {};
                        this.setColorSide(
                            colors.frame.outer,
                            this.getDefaultColorForSide('outer', 'frame', true, conf),
                            undefined,
                            'outer',
                            'frame',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.frame.outer,
                            this.getDefaultColorForSide('outer', 'frame', true, conf),
                            undefined,
                            'outer',
                            'frame',
                            conf,
                            true
                        );
                    }
                    if (
                        !colors.frame.inner
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.frame.inner.id, colors.frame.inner.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || !colorGroups.some(group => colors.frame.inner.groups.includes(group))
                        || (colors.frame.inner.isDefault && updateDefaults)
                    ) {
                        colors.frame.inner = {};
                        colorsDefault.frame.inner = {};

                        this.setColorSide(
                            colors.frame.inner,
                            this.getDefaultColorForSide('inner', 'frame', true, conf),
                            undefined,
                            'inner',
                            'frame',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.frame.inner,
                            this.getDefaultColorForSide('inner', 'frame', true, conf),
                            undefined,
                            'inner',
                            'frame',
                            conf,
                            true
                        );
                    }
                    if (this.onlyDoubleSidedFrameColor(conf)) {
                        this.setFrameInnerColorByFrameOuterColor(conf);
                    }
                    if (
                        !colors.frame.core
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.frame.core.id, colors.frame.core.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || (colors.frame.core.isDefault && updateDefaults)
                    ) {
                        colors.frame.core = {};
                        colorsDefault.frame.core = {};

                        this.setColorSide(
                            colors.frame.core,
                            this.getDefaultColorForSide('core', 'frame', false, conf),
                            undefined,
                            'core',
                            'frame',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.frame.core,
                            this.getDefaultColorForSide('core', 'frame', false, conf),
                            undefined,
                            'core',
                            'frame',
                            conf,
                            true
                        );
                    }

                    if (
                        !colors.sash.outer
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.sash.outer.id, colors.sash.outer.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || !colorGroups.some(group => colors.sash.outer.groups.includes(group))
                        || (colors.sash.outer.isDefault && updateDefaults)
                    ) {
                        colors.sash.outer = {};
                        colorsDefault.sash.outer = {};

                        this.setColorSide(
                            colors.sash.outer,
                            this.getDefaultColorForSide('outer', 'sash', true, conf),
                            undefined,
                            'outer',
                            'sash',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.sash.outer,
                            this.getDefaultColorForSide('outer', 'sash', true, conf),
                            undefined,
                            'outer',
                            'sash',
                            conf,
                            true
                        );
                    }
                    if (
                        !colors.sash.inner
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.sash.inner.id, colors.sash.inner.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || !colorGroups.some(group => colors.sash.inner.groups.includes(group))
                        || (colors.sash.inner.isDefault && updateDefaults)
                    ) {
                        colors.sash.inner = {};
                        colorsDefault.sash.inner = {};

                        this.setColorSide(
                            colors.sash.inner,
                            this.getDefaultColorForSide('inner', 'sash', true, conf),
                            undefined,
                            'inner',
                            'sash',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.sash.inner,
                            this.getDefaultColorForSide('inner', 'sash', true, conf),
                            undefined,
                            'inner',
                            'sash',
                            conf,
                            true
                        );
                    }
                    if (
                        !colors.sash.core
                        || isUndefined(
                            core.fId(
                                allColors,
                                [colors.sash.core.id, colors.sash.core.RAL],
                                ['id', 'RAL']
                            )
                        )
                        || (colors.sash.core.isDefault && updateDefaults)
                    ) {
                        colors.sash.core = {};
                        colorsDefault.sash.core = {};

                        this.setColorSide(
                            colors.sash.core,
                            this.getDefaultColorForSide('core', 'sash', false, conf),
                            undefined,
                            'core',
                            'sash',
                            conf,
                            true
                        );
                        this.setColorSide(
                            colorsDefault.sash.core,
                            this.getDefaultColorForSide('core', 'sash', false, conf),
                            undefined,
                            'core',
                            'sash',
                            conf,
                            true
                        );
                    }

                    if (conf.System.alushell) {
                        if (
                            isUndefined(
                                core.fId(
                                    allColors,
                                    [colors.frame.alushell.id, colors.frame.alushell.RAL],
                                    ['id', 'RAL']
                                )
                            )
                            || (colors.frame.alushell.isDefault && updateDefaults)
                        ) {
                            this.setColorSide(
                                colors.frame.alushell,
                                this.getDefaultColorForSide('alushell', 'frame', false, conf),
                                undefined,
                                'alushell',
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.frame.alushell,
                                this.getDefaultColorForSide('alushell', 'frame', false, conf),
                                undefined,
                                'alushell',
                                'frame',
                                conf,
                                true
                            );
                        }
                        if (
                            isUndefined(
                                core.fId(
                                    allColors,
                                    [colors.sash.alushell.id, colors.sash.alushell.RAL],
                                    ['id', 'RAL']
                                )
                            )
                            || (colors.sash.alushell.isDefault && updateDefaults)
                        ) {
                            this.setColorSide(
                                colors.sash.alushell,
                                this.getDefaultColorForSide('alushell', 'sash', false, conf),
                                undefined,
                                'alushell',
                                'sash',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.sash.alushell,
                                this.getDefaultColorForSide('alushell', 'sash', false, conf),
                                undefined,
                                'alushell',
                                'sash',
                                conf,
                                true
                            );
                        }
                    }
                    if (conf.valid != null) {
                        Object.assign(conf, this.validationService.valid(conf, 'colors'));
                    }
                } finally {
                    this.eventBusService.resume(['setConstructionColor', 'changedSashes'], pauseId);
                }
            }
            colors = null;
        }
    }

    onlyDoubleSidedFrameColor(conf: WindowActiveConfiguration): boolean {
        const frameProfileIds = this.profilesService.getUsedFrameProfileIds(conf)
        return frameProfileIds && frameProfileIds.every(profileId => {
            const profileColorSides = this.profilesService.getProfilePriceLevelColorSides(profileId)
            return profileColorSides && profileColorSides.length > 0 && profileColorSides.every(side => side === 'double');
        })
    }

    setFrameInnerColorByFrameOuterColor(conf: WindowActiveConfiguration) {
        const frameOuterColor = this.getFrameOuterColor(conf);
        const frameInnerColor = this.getFrameInnerColor(conf);
        if (
            frameOuterColor
            && frameInnerColor
            && frameOuterColor.id !== frameInnerColor.id
        ) {
            this.setColorSide(
                frameInnerColor,
                frameOuterColor,
                undefined,
                'inner',
                'frame',
                conf,
                true
            );
        }
    }

    getFrameOuterColor(conf: WindowActiveConfiguration) {
        return conf.Colors.frame && (!core.isEmptyObject(conf.Colors.frame.outer) ? conf.Colors.frame.outer : conf.Colors.frame.core);
    }

    getFrameInnerColor(conf: WindowActiveConfiguration) {
        return conf.Colors.frame && (!core.isEmptyObject(conf.Colors.frame.inner) ? conf.Colors.frame.inner : conf.Colors.frame.core);
    }

    /**
     * Ustawia domyślny układ kolorów.
     * @private
     */
    setDefaultColorTypeForSystem(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        if (isDefined(confDefault.ColorType) && confDefault.ColorType !== null) {
            conf.ColorType = confDefault.ColorType;
        }
        const winTypeLineId = conf.System.type;
        const pvcTypes = ['White', 'Cream', 'Outer', 'Inner', 'Bilateral', '3D', 'Bicolor'];
        const aluTypes = ['Bilateral', 'Bicolor'];
        const woodTypes = ['Opaque', 'Transparent'];
        if (winTypeLineId === 'pvc' && pvcTypes.indexOf(conf.ColorType) === -1) {
            const visColor = this.getColorFromVisualizer();
            if (visColor) {
                conf.ColorType = 'Bilateral';
                confDefault.ColorType = 'Bilateral';
            } else {
                conf.ColorType = 'White';
                confDefault.ColorType = 'White';
            }
        } else if (
            aluTypes.indexOf(conf.ColorType) === -1
            && (winTypeLineId === 'alu'
                || (winTypeLineId === 'wood' && this.config().IccConfig.Configurators.woodBicolor))
        ) {
            conf.ColorType = 'Bilateral';
            confDefault.ColorType = 'Bilateral';
        } else if (
            winTypeLineId === 'wood'
            && woodTypes.indexOf(conf.ColorType) === -1
            && !this.config().IccConfig.Configurators.woodBicolor
        ) {
            conf.ColorType = 'Transparent';
            confDefault.ColorType = 'Transparent';
        }
    }

    /**
     * Ustawia układ kolorów na podstawie ustawionych kolorów, jeśli wybrany nie pasuje.
     * @private
     */
    setDefaultColorTypeForColors(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        if (isObject(conf.Colors) || isObject(confDefault.Colors)) {
            const colors = conf.Colors;
            // if (isObject(confDefault.Colors)) {
            //     colors = confDefault.Colors;
            // }
            const winTypeLineId = conf.System.type;

            if (winTypeLineId !== 'wood') {
                const outer = this.colorsDefaultsService.getColorValue(colors, 'outer');
                const inner = this.colorsDefaultsService.getColorValue(colors, 'inner');
                const core = this.colorsDefaultsService.getColorValue(colors, 'core');

                if (
                    (this.colorsDefaultsService.getDefaultColorType('White', 'outer') === outer
                        && this.colorsDefaultsService.getDefaultColorType('White', 'inner')
                            === inner
                        && this.colorsDefaultsService.getDefaultColorType('White', 'core')
                            === core)
                    || (outer === 'WHITE' && inner === 'WHITE')
                ) {
                    conf.ColorType = 'White';
                    confDefault.ColorType = 'White';
                } else if (
                    this.colorsDefaultsService.getDefaultColorType('Cream', 'outer') === outer
                    && this.colorsDefaultsService.getDefaultColorType('Cream', 'inner') === inner
                    && this.colorsDefaultsService.getDefaultColorType('Cream', 'core') === core
                ) {
                    conf.ColorType = 'Cream';
                    confDefault.ColorType = 'Cream';
                } else if (
                    [
                        'WHITE',
                        this.colorsDefaultsService.getDefaultColorType('Outer', 'outer'),
                    ].includes(outer)
                    && this.colorsDefaultsService.getDefaultColorType('Outer', 'inner') === inner
                    && this.colorsDefaultsService.getDefaultColorType('Outer', 'core') === core
                ) {
                    conf.ColorType = 'Outer';
                    confDefault.ColorType = 'Outer';
                } else if (
                    this.colorsDefaultsService.getDefaultColorType('Inner', 'outer') === outer
                    && [
                        'WHITE',
                        this.colorsDefaultsService.getDefaultColorType('Inner', 'inner'),
                    ].includes(inner)
                    && this.colorsDefaultsService.getDefaultColorType('Inner', 'core') === core
                ) {
                    conf.ColorType = 'Inner';
                    confDefault.ColorType = 'Inner';
                } else if (
                    ((this.colorsDefaultsService.getDefaultColorType('Bilateral', 'outer')
                        === outer
                        && this.colorsDefaultsService.getDefaultColorType('Bilateral', 'inner')
                            === inner)
                        || outer === inner)
                    && colors.frame.outer.id === colors.frame.inner.id
                ) {
                    conf.ColorType = 'Bilateral';
                    confDefault.ColorType = 'Bilateral';
                } else {
                    conf.ColorType = 'Bicolor';
                    confDefault.ColorType = 'Bicolor';
                }
            }
        }
    }

    /**
     * Zwraca domyślny kolor dla danego miejsca w konstrukcji
     * @private
     * @param  {string} type                 Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place                Miejsce - skrzydło, rama
     * @param  {bool}   [visualizator=false] Czy uwzględnić kolor z wizualizatora
     * @return {object}                      Kolor
     */
    getDefaultColorForSide(
        type,
        place,
        visualizator,
        conf = this.configurationsService.conf.Current,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        if (!isArray(this.windowColors)) {
            logger.error(new Error('Nie załadowane kolory'));
            return {};
        }
        const visColor = this.getColorFromVisualizer();
        const doorPanelMatchingColors = this.getColorFromDoorPanel(conf, type);
        const coreColorInBilateralAlwaysAsDefault = this.config().IccConfig.Configurators.window
            .coreColorInBilateralAlwaysAsDefault;
        const defaultValue = this.colorsDefaultsService.getDefaultColorType(
            conf.ColorType,
            type,
            coreColorInBilateralAlwaysAsDefault
        );
        if (defaultValue) {
            let side = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
            if (
                ['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(conf.ColorType) > -1
                && ['inner', 'outer'].indexOf(type) > -1
            ) {
                side = [place[0].toUpperCase() + '|B'];
            }
            if (conf.ColorType === 'Bicolor' && ['inner', 'outer'].indexOf(type) > -1) {
                side = [place[0].toUpperCase() + '|D' + type[0].toUpperCase()];
            }
            const colors = [];
            const whites = [];
            const creams = [];
            const visColors = [];
            const doorPanelColors = [];
            let allColors = this.windowColors.filter(
                color =>
                    color.type !== 'RAL'
                    || ((conf.System.type !== 'wood' || conf.ColorType !== 'Transparent')
                        && color.type === 'RAL')
            );
            if (filterColors) {
                allColors = allColors.filter(filterColors);
            }

            for (let i = 0; i < allColors.length; i++) {
                if (
                    (isArray(allColors[i].sides)
                        && side.every(s => allColors[i].sides.indexOf(s) > -1))
                    || allColors[i].RAL
                ) {
                    if (allColors[i].type === 'white') {
                        whites.push(allColors[i]);
                    } else if (allColors[i].type === 'cream') {
                        creams.push(allColors[i]);
                    } else if (
                        doorPanelMatchingColors
                        && doorPanelMatchingColors.length > 0
                        && doorPanelMatchingColors.includes(Number(allColors[i].id))
                    ) {
                            doorPanelColors.push(allColors[i]);
                    } else if (visColor && isString(allColors[i].color)) {
                        if (
                            allColors[i].color.toUpperCase() === visColor.toUpperCase()
                            || allColors[i].color_img === visColor
                        ) {
                            visColors.push(allColors[i]);
                        }
                    } else {
                        colors.push(allColors[i]);
                    }
                }
            }
            if (doorPanelColors.length > 0) {
                return doorPanelColors[0];
            }
            if (visualizator && visColor && isDefined(visColors[0])) {
                return visColors[0];
            }
            if (
                (defaultValue === 'WHITE'
                    || (defaultValue !== 'NONE' && conf.System.type === 'alu'))
                && isDefined(whites[0])
            ) {
                return whites[0];
            }
            if (defaultValue === 'CREAM' && isDefined(creams[0])) {
                return creams[0];
            }
            if (defaultValue !== 'NONE' && isDefined(colors[0])) {
                return colors[0];
            }
            return {};
        }
        return {};
    }

    /**
     * Definiuje na podstawie cennika, że dla wybranego systemu (nie) jest dostepna nakładka aluminiowa.
     * @private
     * @return {Boolean} Dostępność nakładki
     */
    hasSystemAlushell(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        const winLineId = conf.System.id;
        if (
            (isObject(this.configuratorsDataService.data.pricesDepends)
                && isObject(this.configuratorsDataService.data.pricesDepends[winLineId + '|n']))
            || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
        ) {
            const prices = this.configuratorsDataService.data.pricesDepends[winLineId + '|n'];
            if (
                isDefined(prices)
                || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
            ) {
                conf.System.alushell = true;
                defaultConf.System.alushell = true;
                return true;
            }
        }

        conf.System.alushell = false;
        defaultConf.System.alushell = false;

        if (conf.HasAlushell) {
            conf.HasAlushell = false;
        }
        return false;
    }

    /**
     * Ustawia kolor dla danego miejsca w danym obiekcie
     * @private
     * @param {object}     object Stary kolor
     * @param {object}     color  Nowy kolor
     * @param {string|int} type   Grupa koloru
     * @param {string}     side   Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place  Miejsce - skrzydło, rama
     */
    setColorSide(
        object: Partial<IccColor>,
        color: Partial<IccColor> | undefined,
        type: number | 'none' | undefined | string,
        side: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        conf = this.configurationsService.conf.Current,
        isDefault = false
    ) {
        if (isNotNullOrUndefined(color)) {
            object.id = color.id;
            object.alfa = color.alfa;
            object.color = color.color;
            object.color_img = color.color_img;
            object.name = color.name;
            object.code = color.code;
            object.RAL = color.RAL;
            object.type = color.type;
            object.types = color.types;
            object.groups = color.groups;
            object.sides = color.sides;
            object.isDefault = isDefault;
            object.visualization = color.visualization;
            if (type === 'none') {
                type = undefined;
            }
            object.group = String(type);
            if (
                conf.type
                && (conf.type as any) !== 'complementary_goods'
                && (conf.type as any) !== 'accessory'
                && conf.Frames
            ) {
                Object.assign(conf, this.validationService.valid(conf, 'colors'));
                this.eventBusService.post({
                    key: 'setConstructionColor',
                    value: {},
                    conf,
                });
            }
        }
    }

    /**
     * Ustawia domyślny kolor do wybranego układu kolorów, dla danej strony konstrukcji
     * @private
     * @param {string} side Strona konstrukcji (outer, inner, core, alushell)
     */
    setDefaultColorForType(
        side,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        const type = conf.ColorType;
        let colors = conf.Colors;
        const colorsDefault = confDefault.Colors;
        const coreColorInBilateralAlwaysAsDefault = this.config().IccConfig.Configurators.window
            .coreColorInBilateralAlwaysAsDefault;
        const defaultColorType = this.colorsDefaultsService.getDefaultColorType(
            type,
            side,
            coreColorInBilateralAlwaysAsDefault
        );
        if (defaultColorType) {
            const pauseId = this.eventBusService.pause(['setConstructionColor']);
            try {
                switch (defaultColorType) {
                    case 'NONE':
                        if (
                            Object.keys(colors.frame[side]).length > 0
                            || Object.keys(colors.sash[side]).length > 0
                        ) {
                            this.setColorSide(
                                colors.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colors.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                        }
                        break;
                    case 'WHITE':
                        if (
                            colors.frame[side].type !== 'white'
                            || colors.sash[side].type !== 'white'
                        ) {
                            this.setColorSide(
                                colors.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colors.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                        }
                        break;
                    case 'CREAM':
                        if (
                            colors.frame[side].type !== 'cream'
                            || colors.sash[side].type !== 'cream'
                        ) {
                            this.setColorSide(
                                colors.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.frame[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'frame',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'frame',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colors.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                            this.setColorSide(
                                colorsDefault.sash[side],
                                this.getDefaultColorForSide(
                                    side,
                                    'sash',
                                    false,
                                    conf,
                                    filterColors
                                ),
                                undefined,
                                side,
                                'sash',
                                conf,
                                true
                            );
                        }
                        break;
                    case 'COLOR':
                        if (
                            ['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(type) > -1
                            && ['outer', 'inner'].indexOf(side) > -1
                        ) {
                            if (
                                side === 'outer'
                                && (!isString(colors.frame.outer.type)
                                    || ['white', 'cream'].indexOf(colors.frame.outer.type) > -1
                                    || (colors.frame.outer.sides
                                        && colors.frame.outer.sides.indexOf('F|B') === -1))
                            ) {
                                if (
                                    isString(colors.frame.inner.type)
                                    && colors.frame.inner.type !== 'white'
                                    && colors.frame.inner.type !== 'cream'
                                    && colors.frame.inner.sides
                                    && colors.frame.inner.sides.indexOf('F|B') > -1
                                ) {
                                    this.setColorSide(
                                        colors.frame.outer,
                                        colors.frame.inner,
                                        undefined,
                                        'outer',
                                        'frame',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colorsDefault.frame.outer,
                                        colorsDefault.frame.inner,
                                        undefined,
                                        'outer',
                                        'frame',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colors.sash.outer,
                                        colors.sash.inner,
                                        undefined,
                                        'outer',
                                        'sash',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colorsDefault.sash.outer,
                                        colorsDefault.sash.inner,
                                        undefined,
                                        'outer',
                                        'sash',
                                        conf,
                                        true
                                    );
                                } else {
                                    this.setColorSide(
                                        colors.frame.outer,
                                        this.getDefaultColorForSide(
                                            'outer',
                                            'frame',
                                            false,
                                            conf,
                                            filterColors
                                        ),
                                        undefined,
                                        'outer',
                                        'frame',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colorsDefault.frame.outer,
                                        this.getDefaultColorForSide(
                                            'outer',
                                            'frame',
                                            false,
                                            conf,
                                            filterColors
                                        ),
                                        undefined,
                                        'outer',
                                        'frame',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colors.sash.outer,
                                        this.getDefaultColorForSide(
                                            'outer',
                                            'sash',
                                            false,
                                            conf,
                                            filterColors
                                        ),
                                        undefined,
                                        'outer',
                                        'sash',
                                        conf,
                                        true
                                    );
                                    this.setColorSide(
                                        colorsDefault.sash.outer,
                                        this.getDefaultColorForSide(
                                            'outer',
                                            'sash',
                                            false,
                                            conf,
                                            filterColors
                                        ),
                                        undefined,
                                        'outer',
                                        'sash',
                                        conf,
                                        true
                                    );
                                }
                            } else if (
                                colors.frame.outer.id !== colors.frame.inner.id
                                || colors.frame.outer.RAL !== colors.frame.inner.RAL
                            ) {
                                this.setColorSide(
                                    colors.frame.inner,
                                    colors.frame.outer,
                                    undefined,
                                    'inner',
                                    'frame',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colorsDefault.frame.inner,
                                    colorsDefault.frame.outer,
                                    undefined,
                                    'inner',
                                    'frame',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colors.sash.inner,
                                    colors.sash.outer,
                                    undefined,
                                    'inner',
                                    'sash',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colorsDefault.sash.inner,
                                    colorsDefault.sash.outer,
                                    undefined,
                                    'inner',
                                    'sash',
                                    conf,
                                    true
                                );
                            }
                        } else {
                            const sides = [];
                            if (type === 'Bicolor' && side !== 'core' && side !== 'alushell') {
                                sides.push('F|D' + side[0].toUpperCase());
                            } else {
                                sides.push('F|' + side[0].toUpperCase());
                            }
                            if (
                                !isString(colors.frame[side].type)
                                || ['white', 'cream'].indexOf(colors.frame[side].type) > -1
                                || !isString(colors.sash[side].type)
                                || ['white', 'cream'].indexOf(colors.sash[side].type) > -1
                                || (colors.frame[side].sides
                                    && sides.some(s => colors.frame[side].sides.indexOf(s) === -1))
                            ) {
                                this.setColorSide(
                                    colors.frame[side],
                                    this.getDefaultColorForSide(
                                        side,
                                        'frame',
                                        false,
                                        conf,
                                        filterColors
                                    ),
                                    undefined,
                                    side,
                                    'frame',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colorsDefault.frame[side],
                                    this.getDefaultColorForSide(
                                        side,
                                        'frame',
                                        false,
                                        conf,
                                        filterColors
                                    ),
                                    undefined,
                                    side,
                                    'frame',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colors.sash[side],
                                    this.getDefaultColorForSide(
                                        side,
                                        'sash',
                                        false,
                                        conf,
                                        filterColors
                                    ),
                                    undefined,
                                    side,
                                    'sash',
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colorsDefault.sash[side],
                                    this.getDefaultColorForSide(
                                        side,
                                        'sash',
                                        false,
                                        conf,
                                        filterColors
                                    ),
                                    undefined,
                                    side,
                                    'sash',
                                    conf,
                                    true
                                );
                            }
                        }
                        break;
                }
            } finally {
                this.eventBusService.resume(['setConstructionColor'], pauseId);
            }
        }
        colors = null;
    }

    /**
     * Ustawia układ kolorów.
     * @memberof ColorsService
     * @param {string} type Układ kolorów
     */
    setColorType(
        type: ColorType,
        setRelated = true,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        conf.ColorType = type;
        confDefault.ColorType = type;
        this.setDefaultsOnChangeWoodType(conf, confDefault, filterColors);
        this.setDefaultColorForType('outer', conf, confDefault, filterColors);
        this.setDefaultColorForType('inner', conf, confDefault, filterColors);
        this.setDefaultColorForType('core', conf, confDefault, filterColors);

        if (['White', 'Cream'].indexOf(type) > -1) {
            conf.ColorsSashExt = false;
            this.setColorsSashExt(conf.ColorsSashExt, true, conf, confDefault);
        }

        if (setRelated) {
            this.constructionLimitationService.findReinforcement(conf);
            this.priceService.count();
            this.handlesService.checkIsOneHandleAndAllHasHandle(conf);
            this.handlesService.setDefaultColorForHinge();

            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        }

        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }

        if (conf.Sashes) {
            conf.Sashes.forEach(sash => {
                if (sash.glazing.type === 'pvc_panels') {
                    sash.glazing.selectedColor = core.copy(conf.Colors);
                    sash.intSashes.forEach(intSash => {
                        if (intSash.glazing.type === 'pvc_panels') {
                            intSash.glazing.selectedColor = core.copy(conf.Colors);
                        }
                    });
                    this.eventBusService.post({
                        key: 'changedSashes',
                        value: {},
                    });
                }
            });
        }
    }

    /**
     * Ustawia kolory przy przełączaniu innego koloru skrzydła.
     * @memberof ColorsService
     * @param {bool} refresh Czy odświeżać ceny, rysunek, itp.
     */
    setColorsSashExt(
        colorsSashExt = false,
        refresh = false,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        conf.ColorsSashExt = colorsSashExt;
        confDefault.ColorsSashExt = conf.ColorsSashExt;
        if (!conf.ColorsSashExt) {
            const object = conf.Colors;
            const objectd = confDefault.Colors;
            const eventId = this.eventBusService.pause(['setConstructionColor']);
            try {
                this.setColorSide(
                    object.sash.outer,
                    object.frame.outer,
                    object.frame.outer.group,
                    'outer',
                    'sash',
                    conf,
                    object.frame.outer.isDefault
                );
                this.setColorSide(
                    objectd.sash.outer,
                    objectd.frame.outer,
                    objectd.frame.outer.group,
                    'outer',
                    'sash',
                    conf,
                    objectd.frame.outer.isDefault
                );
                this.setColorSide(
                    object.sash.inner,
                    object.frame.inner,
                    object.frame.inner.group,
                    'inner',
                    'sash',
                    conf,
                    object.frame.inner.isDefault
                );
                this.setColorSide(
                    objectd.sash.inner,
                    objectd.frame.inner,
                    objectd.frame.inner.group,
                    'inner',
                    'sash',
                    conf,
                    objectd.frame.inner.isDefault
                );
                this.setColorSide(
                    object.sash.core,
                    object.frame.core,
                    object.frame.core.group,
                    'core',
                    'sash',
                    conf,
                    object.frame.core.isDefault
                );
                this.setColorSide(
                    objectd.sash.core,
                    objectd.frame.core,
                    objectd.frame.core.group,
                    'core',
                    'sash',
                    conf,
                    objectd.frame.core.isDefault
                );
                this.setColorSide(
                    object.sash.alushell,
                    object.frame.alushell,
                    object.frame.alushell.group,
                    'alushell',
                    'sash',
                    conf,
                    object.frame.alushell.isDefault
                );
                this.setColorSide(
                    objectd.sash.alushell,
                    objectd.frame.alushell,
                    objectd.frame.alushell.group,
                    'alushell',
                    'sash',
                    conf,
                    objectd.frame.alushell.isDefault
                );
            } finally {
                this.eventBusService.resume(['setConstructionColor'], eventId);
            }
        }

        if (!refresh) {
            this.handlesService.checkIsOneHandleAndAllHasHandle(conf);
            this.handlesService.setDefaultColorForHinge();
            this.priceService.count();
        }
    }

    /**
     * Ustawia kolor w danym w miejscu konstrukcji.
     * @memberof ColorsService
     * @param {string}     colorType Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place     Miejsce - skrzydło, rama
     * @param {object}     color     Kolor
     * @param {string|int} type      Grupa koloru
     * @param {bool}       refresh   Czy odświeżać ceny, rysunek, itp.
     */
    setColor(
        colorType: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        color: IccColor | 'none',
        type: number | 'none' | undefined,
        refresh: boolean,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        const object = conf.Colors;
        const objectd = confDefault.Colors;
        if (color === 'none') {
            color = {} as IccColor;
        }
        const eventId = this.eventBusService.pause(['setConstructionColor']);
        try {
            if (!conf.ColorsSashExt) {
                this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
            } else {
                this.setColorSide(object[place][colorType], color, type, colorType, place);
                this.setColorSide(objectd[place][colorType], color, type, colorType, place);
            }
        } finally {
            this.eventBusService.resume(['setConstructionColor'], eventId);
        }

        if (
            colorType === 'outer'
            && (['Bilateral', 'Transparent', 'Opaque', '3D'].indexOf(conf.ColorType) > -1
                || (place === 'frame' && this.onlyDoubleSidedFrameColor(conf)))
        ) {
            this.setColor('inner', place, color, type, refresh, conf, confDefault);
        }

        if (!refresh) {
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
            ) {
                this.rollerColorsService.setDefaultsColorsOnChangeColor('window');
            }
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
                || this.currentConfiguratorService.conf === 'door'
            ) {
                this.handlesService.checkIsOneHandleAndAllHasHandle(conf);
                this.handlesService.setDefaultColorForHinge();
                this.constructionLimitationService.findReinforcement(conf);
                this.priceService.count();
            }
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Ustawia rodzaj drewna
     * @memberof ColorsService
     * @param {object} wood Rodzaj drewna.
     */
    setWood(
        wood,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        callback
    ) {
        conf.Wood = core.copy(wood);
        confDefault.Wood = core.copy(wood);

        if (typeof callback === 'function') {
            callback();
        } else {
            this.setDefaults();
        }
    }

    /**
     * Otwiera popup z wyborem koloru.
     * @memberof ColorsService
     * @param  {string} type  Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place Miejsce - skrzydło, rama
     */
    openModalColor(
        type: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        conf = this.configurationsService.conf.Current,
        filterColors: ((color?: IccColorGroup) => boolean) | null = null,
        refreshConf = true,
        novalidate = false
    ) {
        let disable: ColorType[] = [];
        switch (type) {
            case 'outer':
                disable = ['White', 'Cream', 'Inner'];
                break;
            case 'inner':
                disable = ['White', 'Cream', 'Outer', 'Bilateral', 'Transparent', 'Opaque', '3D'];
                break;
            case 'core':
                disable = ['White', 'Cream'];
                break;
        }
        if (disable.indexOf(conf.ColorType) > -1) {
            return;
        }
        let side: Side[] = [];
        if (type !== 'outer') {
            side = [(place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side];
            if (['Bicolor'].indexOf(conf.ColorType) > -1 && type === 'inner') {
                side = [(place[0].toUpperCase() + '|D' + type[0].toUpperCase()) as Side];
            }
        } else {
            if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(conf.ColorType) > -1) {
                side = [(place[0].toUpperCase() + '|B') as Side];
            } else {
                side = [(place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side];
                if (['Bicolor'].indexOf(conf.ColorType) > -1) {
                    side = [(place[0].toUpperCase() + '|D' + type[0].toUpperCase()) as Side];
                }
            }
        }
        const colorsWin: IccColor[] = [];
        let groups: (number | string)[] = [];

        this.windowColors
            .filter(color => {
                let match = false;
                if (isArray(color.sides)) {
                    match = side.every(s => color.sides.indexOf(s) > -1);
                }
                return match;
            })
            .forEach(color => {
                if (isArray(color.groups)) {
                    colorsWin.push(color);
                    groups = groups.concat(
                        color.groups.filter(group => groups.indexOf(group) === -1)
                    );
                }
            });

        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => colorsWin,
                selectedColor: () => conf.Colors[place][type],
                colorGroups: () =>
                    (this.configuratorsDataService.data.windowColorGroups || []).filter(el => {
                        let val =
                            groups.indexOf(el.id) > -1
                            && ((isArray(el.systems)
                                && el.systems.indexOf(conf.System.id) > -1
                                && el.target.indexOf('show') > -1
                                && (conf.System.type !== 'wood'
                                    || (isArray(el.woodTypes)
                                        && el.woodTypes.indexOf(conf.Wood.id) > -1)))
                                || (filterColors && filterColors(el)));

                        const groupSides = (el.sides || []).filter(Boolean);
                        if (groupSides.length) {
                            val = val && side.some(s => groupSides.includes(s));
                        }

                        return val;
                    }),
                transparentWood: () =>
                    conf.System.type === 'wood' && conf.ColorType === 'Transparent',
                colorsSashExt: () => conf.ColorsSashExt,
                type: () => type,
            },
        });

        modalInstance.result.then(data => {
            if (isObject(data)) {
                let defaultConf: any = this.configurationsService.conf.Default;

                if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
                    defaultConf = {
                        Colors: {
                            frame: {
                                outer: {},
                                inner: {},
                                core: {},
                                alushell: {},
                            },
                            sash: {
                                outer: {},
                                inner: {},
                                core: {},
                                alushell: {},
                            },
                        },
                    };
                }
                this.setColor(type, place, data.color, data.group, !refreshConf, conf, defaultConf);
                this.refreshFillingsColors(novalidate, conf);
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    openModalColorSimple(
        type: 'outer' | 'inner' | 'alushell',
        place: 'frame' | 'sash',
        conf = this.configurationsService.conf.Current,
        filterColors?: (color?: IccColorGroup) => boolean,
        refreshConf = true,
        novalidate = false,
        onSelect?: (color?: IccColor) => void,
        selectedColor?: Partial<IccColor> | null
    ) {
        let side: Side[] = [];
        let typec: 'outer' | 'inner' | 'alushell' | 'core' = type;
        if (type === 'outer') {
            side = [
                (place[0].toUpperCase() + '|O') as Side,
                (place[0].toUpperCase() + '|DO') as Side,
                (place[0].toUpperCase() + '|B') as Side,
                (place[0].toUpperCase() + '|C') as Side,
            ];
        } else if (type === 'inner') {
            side = [
                (place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side,
                (place[0].toUpperCase() + '|D' + type[0].toUpperCase()) as Side,
            ];
        } else if (type === 'alushell') {
            side = [(place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side];
        }

        const colorsWin: IccColor[] = [];
        let groups: (number | string)[] = [];

        this.windowColors
            .filter(color => {
                let match = false;
                if (isArray(color.sides)) {
                    match = side.some(s => color.sides.indexOf(s) > -1);
                }
                if (color.RAL && type === 'alushell') {
                    match = true;
                }
                return match;
            })
            .forEach(color => {
                if (isArray(color.groups)) {
                    colorsWin.push(color);
                    groups = groups.concat(
                        color.groups.filter(group => groups.indexOf(group) === -1)
                    );
                }
            });
        const colorInPlaceType = conf.Colors[place][type];
        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => colorsWin,
                selectedColor: () =>
                    selectedColor && selectedColor.id
                        ? selectedColor
                        : colorInPlaceType && colorInPlaceType.id
                        ? colorInPlaceType
                        : conf.Colors[place]['core'],
                colorGroups: () =>
                    (this.configuratorsDataService.data.windowColorGroups || []).filter(el => {
                        let val =
                            groups.indexOf(el.id) > -1
                            && (filterColors
                                ? filterColors(el)
                                : isArray(el.systems)
                                  && el.systems.indexOf(conf.System.id) > -1
                                  && el.target.indexOf('show') > -1
                                  && (conf.System.type !== 'wood'
                                      || (isArray(el.woodTypes)
                                          && el.woodTypes.indexOf(conf.Wood.id) > -1)));

                        const groupSides = (el.sides || []).filter(Boolean);
                        if (groupSides.length) {
                            val = val && side.some(s => groupSides.includes(s));
                        }

                        return val;
                    }),
                transparentWood: () =>
                    conf.System.type === 'wood' && conf.ColorType === 'Transparent',
                colorsSashExt: () => conf.ColorsSashExt,
                type: () => type,
            },
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        return modalInstance.result.then(data => {
            if (isObject(data)) {
                if (onSelect) {
                    onSelect(data.color);
                } else {
                    let defaultConf: any = this.configurationsService.conf.Default;

                    if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
                        defaultConf = {
                            Colors: {
                                frame: {
                                    outer: {},
                                    inner: {},
                                    core: {},
                                    alushell: {},
                                },
                                sash: {
                                    outer: {},
                                    inner: {},
                                    core: {},
                                    alushell: {},
                                },
                            },
                        };
                    }
                    if (data.color.type === 'white' || data.color.type === 'cream') {
                        if (typec === 'inner' || typec === 'outer') {
                            this.setColor(
                                typec,
                                place,
                                'none',
                                data.group,
                                !refreshConf,
                                conf,
                                defaultConf
                            );
                        }
                        typec = 'core';
                    }
                    this.setColor(
                        typec,
                        place,
                        data.color,
                        data.group,
                        !refreshConf,
                        conf,
                        defaultConf
                    );
                    this.setDefaultColorTypeForColors();
                    this.refreshFillingsColors(novalidate, conf);
                }
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });
    }

    getSimpleColors(
        conf = this.configurationsService.conf.Current,
        side: Side[] = [Side.FC, Side.FB]
    ) {
        const colorsWin: IccColor[] = [];
        let groups: (number | string)[] = [];

        this.windowColors
            .filter(color => {
                let match = false;
                if (isArray(color.sides)) {
                    match = side.some(s => color.sides.indexOf(s) > -1);
                }
                if (color.RAL && side.includes(Side.FA)) {
                    match = true;
                }
                return match;
            })
            .forEach(color => {
                if (isArray(color.groups)) {
                    colorsWin.push(color);
                    groups = groups.concat(
                        color.groups.filter(group => groups.indexOf(group) === -1)
                    );
                }
            });

        const colorGroups = (this.configuratorsDataService.data.windowColorGroups || []).filter(
            el => {
                let val =
                    groups.indexOf(el.id) > -1
                    && (isArray(el.systems)
                        && el.systems.indexOf(conf.System.id) > -1
                        && el.target.indexOf('show') > -1
                        && (conf.System.type !== 'wood'
                            || (isArray(el.woodTypes) && el.woodTypes.indexOf(conf.Wood.id) > -1)));

                const groupSides = (el.sides || []).filter(Boolean);
                if (groupSides.length) {
                    val = val && side.some(s => groupSides.includes(s));
                }

                return val;
            }
        );
        return {
            colors: colorsWin,
            groups: colorGroups,
        };
    }

    setSimpleColors(color: IccColor, conf = this.configurationsService.conf.Current) {
        let defaultConf: any = this.configurationsService.conf.Default;
        if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
            defaultConf = {
                Colors: {
                    frame: {
                        outer: {},
                        inner: {},
                        core: {},
                        alushell: {},
                    },
                    sash: {
                        outer: {},
                        inner: {},
                        core: {},
                        alushell: {},
                    },
                },
            };
        }
        this.setColor('outer', 'frame', color, undefined, false, conf, defaultConf);
        const alushellColor = this.getAlushellColor(color.id, conf);
        this.setColor('alushell', 'frame', alushellColor, undefined, false, conf, defaultConf);
        this.refreshFillingsColors(false, conf);
    }

    getAlushellColor(colorId?, conf = this.configurationsService.conf.Current) {
        let color;
        const matchedColors = this.colorMappingService.getColors(
            Number(colorId),
            'window',
            'window'
        );
        const colors = this.getSimpleColors(conf, [Side.FA]);
        const windowColors = matchedColors
            .map(m => colors.colors.filter(c => Number(c.id) === m)[0])
            .filter(m => m);
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else {
            color = core.copy(colors[0]);
        }
        if (color) {
            color.isDefault = true;
        }
        return color;
    }

    private refreshFillingsColors(novalidate: boolean, conf: WindowActiveConfiguration) {
        if (!novalidate && conf.Sashes) {
            conf.Sashes.forEach(sash => {
                if (sash.glazing.type === 'pvc_panels') {
                    sash.glazing.selectedColor = core.copy(conf.Colors);
                    sash.intSashes.forEach(intSash => {
                        if (intSash.glazing.type === 'pvc_panels') {
                            intSash.glazing.selectedColor = core.copy(conf.Colors);
                        }
                    });
                    this.eventBusService.post({
                        key: 'changedSashes',
                        value: {},
                    });
                }
                if (
                    sash.glazing.type === 'deco_panels'
                    || (sash.glazing.type === 'door_panels' && !conf.System.door_type)
                ) {
                    this.fillingsService.setDefaultDecoFillingColors(conf, sash.glazing, this.fillingsService.isDoorPassiveSash(sash) ? 'passive' : 'active');
                    sash.intSashes.forEach(intSash => {
                        if (
                            intSash.glazing.type === 'deco_panels'
                            || intSash.glazing.type === 'door_panels'
                        ) {
                            this.fillingsService.setDefaultDecoFillingColors(conf, intSash.glazing, this.fillingsService.isDoorPassiveSash(sash) ? 'passive' : 'active');
                        }
                    });
                    this.eventBusService.post({
                        key: 'changedSashes',
                        value: {},
                    });
                }
            });
        }
    }

    /**
     * Otwiera popup z wyborem rodzaju drewna.
     * @memberof ColorsService
     */
    openModalWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        callback?: () => void
    ) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalWood.html',
            controller: 'ModalWoodCtrl as wood',
            pageComponent: WoodsPageComponent,
            resolve: {
                woods: () => this.woods,
                selWood: () => conf.Wood,
            },
        });

        modalInstance.result.then(selectedWood => {
            if (isObject(selectedWood)) {
                this.setWood(selectedWood, conf, confDefault, callback);
            }
        });
    }

    /**
     * Sprawdza poprawność kolorów konstrukcji.
     * @return {bool} Poprawność kolorów konstrukcji.
     */
    validate() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            this.issuesService.unregister(
                'no-window-colors',
                this.configurationsService.conf.Current
            );
            this.issuesService.unregister(
                'no-window-alushell-color',
                this.configurationsService.conf.Current
            );
            return true;
        }
        let colors = this.configurationsService.conf.Current.Colors;
        if ((this.configurationsService.conf.Current.System || {}).type === 'alu') {
            if (
                isUndefined(colors.frame.outer.id)
                || isUndefined(colors.sash.outer.id)
                || isUndefined(colors.frame.inner.id)
                || isUndefined(colors.sash.inner.id)
            ) {
                this.issuesService.simpleRegister(
                    'no-window-colors',
                    'Kolory nie zostały określone.',
                    this.translateService.instant('COLOR|Kolory nie zostały określone.'),
                    this.configurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
        } else {
            if (
                (this.configurationsService.conf.Current.System || {}).type === 'pvc'
                && (isUndefined(colors.frame.core.id) || isUndefined(colors.sash.core.id))
            ) {
                this.issuesService.simpleRegister(
                    'no-window-colors',
                    'Kolory nie zostały określone.',
                    this.translateService.instant('WINDOW|Kolor okna nie został określony.'),
                    this.configurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
        }
        if (
            this.configurationsService.conf.Current.HasAlushell
            && ((this.config().IccConfig.Configurators.alushellExt
                && this.configurationsService.conf.Current.AlushellType !== 'brushed')
                || !this.config().IccConfig.Configurators.alushellExt)
            && (isUndefined(colors.frame.alushell.id) || isUndefined(colors.sash.alushell.id))
        ) {
            this.issuesService.simpleRegister(
                'no-window-alushell-color',
                'Wybierz kolor nakładki.',
                this.translateService.instant('WINDOW|Wybierz kolor nakładki.'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE,
                }
            );
            return false;
        }
        colors = null;
        this.issuesService.unregister('no-window-colors', this.configurationsService.conf.Current);
        this.issuesService.unregister(
            'no-window-alushell-color',
            this.configurationsService.conf.Current
        );
        return true;
    }

    /**
     * Zwraca kolor wybrany w wizualizatorze.
     * @return {boolean|string} Nazwa koloru lub false kiedy nie wybrany.
     */
    getColorFromVisualizer() {
        const visColors = core.parseJson(localStorage.getItem('colors'));
        let windowColor;
        let visColor;
        const timeLimit = new Date(new Date().getTime() - 24 * 60 * 60 * 1000); // doba wstecz

        if (isUndefined(visColors.time) || new Date(visColors.time) >= timeLimit) {
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
            ) {
                visColor = visColors.okna;
            } else if (this.currentConfiguratorService.conf === 'door') {
                visColor = visColors.drzwi;
            }
        }

        if (isString(visColor)) {
            if (visColor.indexOf('okleiny/') > -1) {
                windowColor = visColor.replace('okleiny/', '');
            } else {
                windowColor = visColor.substring(1);
            }
            return windowColor;
        } else {
            return false;
        }
    }

    clearAlushellColor() {
        this.configurationsService.conf.Current.Colors.frame.alushell = {};
        this.configurationsService.conf.Current.Colors.sash.alushell = {};
    }

    setAlushellColor(colorType = '') {
        if (this.configurationsService.conf.Current.HasAlushell) {
            if (!this.configurationsService.conf.Current.Colors.frame.alushell.id) {
                if (colorType === 'brushed' || this.config().IccConfig.Configurators.alushellExt) {
                    this.configurationsService.conf.Current.AlushellType = 'brushed';
                    const windowColors = this.configuratorsDataService.data.windowColorsAll || [];
                    const colorsGroups = this.configuratorsDataService.data.windowColorGroups || [];

                    const brushedAluGroup = colorsGroups.find(
                        group => group.brushed_alu_group === true
                    );
                    if (!isObject(brushedAluGroup)) {
                        this.clearAlushellColor();
                        this.infoService.showInfo(
                            this.translateService.instant(
                                'WINDOW|Brak zdefiniowanej grupy dla aluminium szczotkowanego'
                            ),
                            null
                        );
                        return;
                    }

                    const brushedAluColor = windowColors.find(
                        color =>
                            color.groups != null && color.groups.indexOf(brushedAluGroup.id) > -1
                    );

                    if (brushedAluColor && isObject(brushedAluColor)) {
                        this.setColor(
                            'alushell',
                            'frame',
                            brushedAluColor,
                            Number(brushedAluGroup.id),
                            true
                        );
                        if (this.configurationsService.conf.Current.ColorsSashExt) {
                            this.setColor(
                                'alushell',
                                'sash',
                                brushedAluColor,
                                Number(brushedAluGroup.id),
                                true
                            );
                        }
                    } else {
                        this.clearAlushellColor();
                        this.infoService.showInfo(
                            this.translateService.instant(
                                'WINDOW|Brak przypisanego koloru do grupy dla nakładki aluminiowej szczotkowanej'
                            ),
                            null
                        );
                        return;
                    }
                }
            } else if (colorType === 'painted') {
                this.clearAlushellColor();
                this.eventBusService.post({
                    key: 'alushellColorTypeChange',
                    value: {},
                });
                this.configurationsService.conf.Current.AlushellType = colorType;
            }
        } else {
            this.clearAlushellColor();
            this.eventBusService.post({
                key: 'alushellColorTypeChange',
                value: {},
            });
        }
    }

    getColorFromDoorPanel(conf: WindowActiveConfiguration, type) {
        const sash =
            conf.Sashes
            && conf.Sashes.length > 0
            && conf.Sashes.find(s => s.type.type === 'DRA' || s.type.type === 'DOA');
        if (
            (sash
                && sash.glazing
                && (type === 'outer' && sash.glazing.type === 'door_panels' || sash.glazing.type === 'deco_panels')
                && sash.glazing.selectedColor)
            || (type === 'inner' && sash && sash.panelInner && sash.panelInner.selectedColor)
        ) {
            const panelColor = (sash.glazing.type === 'door_panels' && type === 'inner' ? sash.panelInner : sash.glazing).selectedColor
                .frame[type];
            const matchedColors = panelColor && panelColor.id
                ? this.colorMappingService.getColors(Number(panelColor.id), 'window', 'window')
                : [];
            return matchedColors;
        }
        return null;
    }
}
