import {Injectable, Inject} from '@angular/core';
import {Glass, InterPaneSpace, GlazingUnitElement, FillingsProducer} from '@icc/window';
import {EventBusService} from '@icc/common/event-bus.service';
import {LoadedConfiguratorsDataValue, ConfiguratorsDataService} from '@icc/common/configurators/configurators-data.service';
import {core} from '@icc/common/helpers';
import { ModalService } from '@icc/helpers';
import { GlazingUnitPageComponent } from 'libs/configurator/window/src/lib/glazing-unit-page/glazing-unit-page.component';
import { IccFilling } from '@icc/common/data-types';
import { fixCustomFillings } from '@icc/common/fix-glazing-units';

@Injectable()
export class GlazingUnitsService {

    glasses: Glass[] = [];
    interPaneSpaces: InterPaneSpace[] = [];
    private fillingsProducers: FillingsProducer[] = [];
    private systemIds: string[] = [];

    constructor(
        private ConfiguratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService,
        private modalService: ModalService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            this.glasses = core.copy(data.value.glasses);
            this.interPaneSpaces = core.copy(data.value.interPaneSpaces);
            this.fillingsProducers = core.copy(data.value.fillingsProducers);
            this.systemIds = data.value.windowLines.map(el => el.id);
        });
    }

    newFillingFromGlazingUnit(glazingUnit: GlazingUnitElement[], producerId, filling = null) {
        let extendedGlazingUnit = core.copy(glazingUnit);
        let glasses = [];
        let interPaneSpaces = [];
        let producer = <FillingsProducer>core.fIdO(this.fillingsProducers, producerId);
        const ugFactor = this.setNewGlazingUnitUgFactor(glazingUnit) || null;
        extendedGlazingUnit = extendedGlazingUnit.map(el => {
            if (el.type == 'glass') {
                el.data = <Glass>core.fIdO(this.glasses, el.value);
                glasses.push(core.copy(el.data));
            } else if (el.type == 'interPaneSpace') {
                el.data = <InterPaneSpace>core.fIdO(this.interPaneSpaces, el.value);
                interPaneSpaces.push(core.copy(el.data));
            }
            return el;
        });
        let newFilling: IccFilling = {
            id: core.generateUUID(),
            fillings_producer_id: producerId,
            custom: true,
            name: extendedGlazingUnit.map(el => el.data.name).join(' / '),
            glazing_unit: core.copy(glazingUnit),
            conf: filling ? filling.conf : [
                'door',
                'window'
            ],
            frame_width: Math.min(...interPaneSpaces.map(el => el.thickness)),
            glazing_count: glasses.length,
            has_glazing_unit: true,
            minBillingArea: filling ? filling.minBillingArea : producer.min_area_price,
            ornament: filling ? filling.ornament : false,
            price_sq_m: this.glazingUnitPrice(extendedGlazingUnit, filling),
            thickness_mm: (glasses.reduce((prev, curr) => prev + curr.thickness, 0))
                        + (interPaneSpaces.reduce((prev, curr) => prev + curr.thickness, 0)),
            thinkness_glass: Math.min(...glasses.map(el => el.thicknessForCalculations)),
            overlay_thickness: 0,
            always_match_profile: false,
            type: 'glazing',
            u: ugFactor,
            weight: glasses.reduce((prev, curr) => prev + curr.weight, 0),
            system_types: filling ? filling.system_types : [
                'pvc',
                'alu',
                'wood'
            ],
            system_ids: filling ? filling.system_ids : this.systemIds,
            security_level_inner: null,
            security_level_outer: null,
            code: '',
            order: 0,
            parent_id: null,
            glass_types: [],
            visualizations: {}
        };

        return newFilling;
    }

    setNewGlazingUnitUgFactor(newGlazingUnit) {
        const ugFactorSchemaSets = JSON.parse(this.ConfiguratorsDataService.data.windowUgFactorSets);
        if (ugFactorSchemaSets === null) {
            return null;
        }

        let unitSchema: any = [];

        let simplifiedSchemaSets = ugFactorSchemaSets.map(set => Object.keys(set).map(key => set[key]));
        simplifiedSchemaSets.forEach((text, index, self) => {
          text.pop();
          // [1, 2, 1, 34] => [1:2:1:34]
          self[index] = text.join(':');
        });

        newGlazingUnit.forEach((unitElem, index) => {
            if (unitElem.type === 'glass') {

                const glassCoating = this.glasses.filter(glass => glass.id === unitElem.value)[0].glassCoating;
                if (glassCoating === null) {
                    unitSchema.push('0');
                } else {
                    unitSchema.push(String(glassCoating.id));
                }
            }
            if (unitElem.type === 'interPaneSpace') {
                unitSchema.push(String(unitElem.value));
            }
        });
        unitSchema = unitSchema.join(':');

        const foundedUgFactorSchemaIndex = simplifiedSchemaSets.findIndex(elem => elem === unitSchema);
        if (foundedUgFactorSchemaIndex > -1) {
            return ugFactorSchemaSets[foundedUgFactorSchemaIndex].ug;
        }
    }

    glazingUnitPrice(glazingUnit: GlazingUnitElement[], filling = null) {
        let price;

        if (filling == null) {
            price = glazingUnit.reduce((prev, curr) => prev + curr.data.price, 0);
        } else {
            price = filling.price_sq_m;
            for (let i = 0; i < glazingUnit.length; i++) {
                if (glazingUnit[i].value != filling.glazing_unit[i].value) {
                    if (glazingUnit[i].type == 'glass') {
                        let glass = <Glass>core.fIdO(this.glasses, filling.glazing_unit[i].value);
                        price = price - glass.price + (<Glass>glazingUnit[i].data).price;
                    } else if (glazingUnit[i].type == 'interPaneSpace') {
                        let interPaneSpace = <InterPaneSpace>core.fIdO(this.interPaneSpaces, filling.glazing_unit[i].value);
                        price = price - interPaneSpace.price + (<InterPaneSpace>glazingUnit[i].data).price;
                    }
                }
            }
        }

        return price;
    }

    async openGlazingUnitModal(glazingUnit: GlazingUnitElement[] | null, producerId: number) {
        const modalInstance = this.modalService.open({
            component: 'glazingUnitModal',
            pageComponent: GlazingUnitPageComponent,
            resolve: {
                glasses: () => this.glasses,
                interPaneSpaces: () => this.interPaneSpaces,
                fillingsProducers: () => this.fillingsProducers,
                glazingUnit: () => glazingUnit,
                producerId: () => producerId,
            }
        });

        return await modalInstance.result;
    }

    fixGlazingUnits(customFillings: IccFilling[]) {
        return fixCustomFillings(customFillings, this.glasses, this.interPaneSpaces);
    }
}
