import { PriceBaseService } from './lib/price-base.service';
import { PriceColorsService } from './lib/price-colors.service';
import { PriceDictionaryService } from './lib/price-dictionary.service';

export * from './lib/Prices';
export * from './lib/PriceSegment';
export * from './lib/discounts-and-multipliers.service';
export * from './lib/price.module';
export * from './lib/price-details.module';
export * from './lib/price-details.service';
export {PriceBaseService, PriceColorsService, PriceDictionaryService};
export * from './lib/price.service';
export * from './lib/price-roller.service';
export * from './lib/helpers';