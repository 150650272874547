import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA, isArray } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { Profile, ProfileType, Reinforcement } from '@icc/window';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActiveSash } from '@icc/common/layout/active-sash';
import {
    TranslateService,
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
    core,
} from '@icc/common';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import { AccessoriesColorsPageComponent } from '@icc/configurator/accessory';
import { BehaviorSubject } from 'rxjs';
import { ProfileColorsService } from '../profile-colors.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceBaseService } from '@icc/price/b2b';
import { UnitConverterService } from '@icc/configurator/ui';
import { ReinforcementsModalService } from '../../reinforcements/reinforcements-modal.service';

@Component({
    selector: 'icc-profile-options-page',
    template: require('./profile-options-page.component.html'),
    styles: [require('./profile-options-page.component.scss')],
})
export class ProfileOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('ACCESSORY|Opcje profilu');

    profile: Profile | null = null;
    form: FormGroup;
    focused = false;
    showColorsWhenCoupling = this.currentConfiguratorService.conf === 'coupled_window';
    showColorsInComplementary = this.currentConfiguratorService.conf === 'complementary_goods';
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;
    optionsInComplementary = this.currentConfiguratorService.conf === 'complementary_goods';

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private translateService: TranslateService,
        private priceAccessoryService: PriceAccessoryService,
        private configurationsService: ConfigurationsService<'window'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceBaseService: PriceBaseService,
        private sharedFacade: SharedFacade,
        private profileColorsService: ProfileColorsService,
        private profilesPriceService: ProfilesPriceService,
        private fb: FormBuilder,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profile: Profile;
            profilesPrices: any[];
            profile$: BehaviorSubject<Profile>,
            selectedColors$: BehaviorSubject<any>
        },
        private unitConverterService: UnitConverterService,
        private reinforcementsModalService: ReinforcementsModalService,
    ) {
        super();
    }

    ngOnInit() {
        this.profile = this.pageData.profile;

        this.form = this.fb.group({
            count: 1,
            currentLength: [0, [Validators.min(0), Validators.required]],
            width: [this.profile.width || 0],
            comment: '',
        });

        this.pageData.selectedColors$.subscribe( c => {
            this.profile.selectedColor = c;
        });

        this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        });

        this.pageData.profile$.subscribe(p => {
            this.form.patchValue(p);
        });
    }

    add() {
        Object.assign(this.profile, this.form.value);
        this.sharedFacade.closePage(this.profile);
    }

    getPrice() {
        let price = null;
        if (this.profile && this.profile.selectedColor) {
            price = this.profilesPriceService.getProfilePrice(
                this.profile.id,
                this.profile.type,
                null,
                this.profile.selectedColor,
                this.pageData.profilesPrices
            );
        }
        return !isNaN(price) && price !== null
                ? price
                : this.translateService.instant('CONFIGURATOR|Do wyceny');
    }

    openModalColors() {
      if (this.profile) {
        this.profileColorsService
            .openProfileColorsModal(
                core.copy(this.profile),
                core.copy(this.profile),
                this.pageData.profilesPrices,
                this.pageData.selectedColors$,
                true
            )
            .then(selection => {
                if (this.profile) {
                    this.profile.price = this.profilesPriceService.getProfilePrice(
                        this.profile.id,
                        this.profile.type,
                        null,
                        selection ? selection.colors : this.profile.selectedColor,
                        this.pageData.profilesPrices
                    );

                    this.profile.selectedColor = core.copy( selection ? selection.colors : this.profile.selectedColor);
                    this.profile.selectedWood = core.copy(
                        selection ? selection.wood : this.profile.selectedWood
                    );
                    this.pageData.profile$.next(this.form.value);
                }
            });
      }
    }

    openModalReinforcements(profile: Profile) {
        this.reinforcementsModalService.openReinforcementsModal(
            profile.id,
            profile.reinforcement ? profile.reinforcement : null
        ).then((reinforcement: Reinforcement | false) => {
            if (reinforcement) {
                if (this.profile) {
                    this.profile.reinforcement = reinforcement.id === null ? null : core.copy(reinforcement);
                }
            }
        });

    }

    hasZeroPrice(
        price: {
            price_field: any;
            price_length: any;
            price_construction: any;
            price_piece: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece, price.price_percent];
        if (type === 'threshold') {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (type && ['coupling', 'extension', 'alignment', 'other'].includes(type)) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (type === 'sandwich') {
            requiredFields = [price.price_area];
        } else if (type === 'frame') {
            requiredFields.push(price.price_construction);
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return true;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece, price.price_percent];
        if (type === 'threshold') {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (type && ['coupling', 'extension', 'alignment', 'other'].includes(type)) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (type === 'sandwich') {
            requiredFields = [price.price_area];
        } else if (type === 'frame') {
            requiredFields.push(price.price_construction);
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    priceMarket(price: number) {
        return this.priceBaseService.addMarginMarketFactor(price);
    }
}
