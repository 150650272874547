import {Injectable} from '@angular/core';
import { Common } from './Common';

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {object} SynchronizeService  SynchronizeService
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export abstract class ShapeService {
    shapeAvailability = {};

    getAngles(shape) {
        let angles = [];
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        if (shape.shape === 'rect') {
            angles = [90, 90, 90, 90];
        } else if (shape.shape === 'poligon') {
            a = Math.atan2(shape.s1, shape.h1 - shape.h3) * 180 / Math.PI;
            b = Math.atan2(shape.s3, shape.h1 - shape.h2) * 180 / Math.PI;
            c = 90 - b;
            d = 90 - a;

            if (shape.h2 > 0) {
                angles.push(90);
                angles.push(c + 90);
            } else {
                angles.push(c);
            }

            if (shape.s2 > 0) {
                angles.push(b + 90);
                angles.push(a + 90);
            } else {
                angles.push(a + b);
            }

            if (shape.h3 > 0) {
                angles.push(d + 90);
                angles.push(90);
            } else {
                angles.push(d);
            }

        } else if (shape.shape === 'triangle') {
            a = Math.atan2(shape.height, shape.s1) * 180 / Math.PI;
            b = Math.atan2(shape.height, shape.s3) * 180 / Math.PI;
            c = 180 - a - b;
            angles.push(b);
            angles.push(c);
            angles.push(a);
        } else if (shape.shape === 'arc') {
            angles.push(90);
            angles.push(90);
            if (shape.type !== 'F') {
                angles.push(90);
            }
        }
        return angles;
    }

    getAvailableShapes(systemId) {
        const availableShapes = ['rect'];
        for (const shape in this.shapeAvailability) {
            if (
                this.shapeAvailability.hasOwnProperty(shape)
                && Common.isArray(this.shapeAvailability[shape])
                && this.shapeAvailability[shape].indexOf(Number(systemId)) > -1
            ) {
                availableShapes.push(shape);
            }
        }
        return availableShapes;
    }

}
