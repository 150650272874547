import { ColorType, Accessory } from '../../parts/common';
import { HandlesType } from '@icc/window';
import { RollerShutter } from '../../parts/roller_shutter';
import { Common } from '../../../Common';

export function changesInVersion4(oldConfiguration, dataRequiredToUpdate) {
    const configuration: any = {
        $version: 4,
        type: 'window',
        name: '',
        price: null,
        priceNoMargin: null,
        quantity: 1,
        priceSegments: [],
        priceSegmentsNoMargin: [],
        priceParts: [],
        pricePartsNoMargin: [],
        discountGroups: {},
        discountGroupsNoMargin: {},
        title: '',
        description: '',
        attachments: [],
        height: 1500,
        width: 1500,
        system: null,
        colors: {
            frame: {
                outer: null,
                inner: null,
                core: null,
                alushell: null,
            },
            sash: {
                outer: null,
                inner: null,
                core: null,
                alushell: null,
            },
        },
        colorType: 'White',
        colorsSashExt: false,
        steel: null,
        wood: null,
        hasAlushell: false,
        alushellType: null,
        shape: {
            shape: 'rect',
            width: 1500,
            height: 1500,
            circuit: 6000,
            s1: 1500,
            s2: 0,
            s3: 0,
            h1: 1500,
            h2: 0,
            h3: 1500,
        },
        monoblock: null,
        reno: null,
        sashes: [],
        sashesType: 'Fix',
        ownedSashesTypes: {
            window: true,
            doorActive: false,
            doorPassive: false,
        },
        dividers: [],
        lowThreshold: false,
        balcony: false,
        kicker: false,
        layout: null,
        oneGlazing: false,
        oneGlazingBead: false,
        oneHandle: false,
        oneHandlesHeight: false,
        oneReinforcement: true,
        glazingBeadColor: null,
        sealColor: null,
        siliconeColor: null,
        hasHandle: false,
        handleType: 'InnerLever',
        hinge: null,
        lock: null,
        glazingSpacer: null,
        muntins: null,
        fitting: null,
        accessories: [],
        sideAccessories: {
            top: [],
            bottom: [],
            left: [],
            right: [],
        },
        rollerShutter: null,
        isMistakeProduct: false,
        hasWarranty: true,
        frame: null,
        sashFrame: null,
        parameters: {
            ap: null,
            g: null,
            lt: null,
            rw: null,
            ug: null,
            uw: null,
            sw: null,
            weight: null,
            wl: null,
            wp: null,
            lab: null,
        },
    };
    configuration.name = oldConfiguration.Name;
    configuration.price = oldConfiguration.Price;
    configuration.priceNoMargin = oldConfiguration.PriceNoMargin;
    configuration.quantity = oldConfiguration.Quantity;
    configuration.priceSegments = oldConfiguration.PriceSegments;
    configuration.priceSegmentsNoMargin = oldConfiguration.PriceSegmentsNoMargin;
    configuration.priceParts = oldConfiguration.PriceParts;
    configuration.pricePartsNoMargin = oldConfiguration.PricePartsNoMargin;
    configuration.discountGroups = oldConfiguration.DiscountGroups;
    configuration.discountGroupsNoMargin = oldConfiguration.DiscountGroupsNoMargin;
    configuration.title = oldConfiguration.Title;
    configuration.description = oldConfiguration.Description;
    configuration.attachments = oldConfiguration.Attachments;

    configuration.height = oldConfiguration.Height;
    configuration.width = oldConfiguration.Width;
    configuration.system = {
        id: Number(oldConfiguration.System.id),
        imageSideOnOffer: oldConfiguration.System.image_side_on_offer,
        name: oldConfiguration.System.name,
        titleImage: oldConfiguration.System.title_image,
        type: oldConfiguration.System.type,
        offerInfo: oldConfiguration.System.offer_info,
    };

    ['frame', 'sash'].forEach(part => {
        ['outer', 'inner', 'core', 'alushell'].forEach(side => {
            if (oldConfiguration.Colors[part][side].id) {
                configuration.colors[part][side] = {
                    id: Number(oldConfiguration.Colors[part][side].id),
                    name: oldConfiguration.Colors[part][side].name,
                    code: oldConfiguration.Colors[part][side].code,
                    RAL: Boolean(oldConfiguration.Colors[part][side].RAL),
                    isDefault: oldConfiguration.Colors[part][side].isDefault,
                };
            }
        });
    });

    configuration.colorType = oldConfiguration.ColorType as ColorType;
    configuration.colorsSashExt = oldConfiguration.ColorsSashExt;
    configuration.steel = oldConfiguration.Steel;
    if (oldConfiguration.Wood && oldConfiguration.Wood.id) {
        configuration.wood = {
            id: Number(oldConfiguration.Wood.id),
            name: oldConfiguration.Wood.name,
        };
    }
    configuration.hasAlushell = oldConfiguration.HasAlushell;
    if (configuration.hasAlushell) {
        configuration.alushellType = oldConfiguration.AlushellType;
    }
    configuration.shape = {
        shape: oldConfiguration.Shape.shape,
        circuit: oldConfiguration.Shape.circuit,
    };
    ['width', 'height', 's1', 's2', 's3', 'h1', 'h2', 'h3', 'd'].forEach(field => {
        if (oldConfiguration.Shape[field] != null) {
            configuration.shape[field] = Number(oldConfiguration.Shape[field]);
        }
    });
    if (oldConfiguration.Shape.type != null) {
        configuration.shape.type = oldConfiguration.Shape.type;
    }
    if (oldConfiguration.System.type_frame === 'monoblock') {
        configuration.monoblock = {};
        ['top', 'bottom', 'sides', 'onewidth'].forEach(field => {
            if (oldConfiguration.Monoblock[field] != null) {
                configuration.monoblock[field] = Number(oldConfiguration.Monoblock[field]);
            }
        });
    }
    if (oldConfiguration.System.type_frame === 'reno') {
        configuration.reno = {
            shape: oldConfiguration.Reno.shape,
            minCroppingWidth: oldConfiguration.Reno.minCroppingWidth,
            hasCropping: Boolean(oldConfiguration.Reno.hasCropping),
        };
        ['topWidth', 'bottomWidth', 'leftWidth', 'rightWidth', 'allWidths', 'width'].forEach(
            field => {
                if (oldConfiguration.Reno[field] != null) {
                    configuration.reno[field] = Number(oldConfiguration.Reno[field]);
                }
            }
        );
    }
    configuration.sashes = oldConfiguration.Sashes.map(oldMapSash);
    configuration.sashesType = oldConfiguration.SashesType;
    if (oldConfiguration.OwnedSashesTypes) {
        configuration.ownedSashesTypes = {
            window: oldConfiguration.OwnedSashesTypes.window,
            doorActive: oldConfiguration.OwnedSashesTypes.doorActive,
            doorPassive: oldConfiguration.OwnedSashesTypes.doorPassive,
        };
    }
    configuration.dividers = oldConfiguration.Dividers.map(oldMapDivider);
    if (oldConfiguration.LowThreshold) {
        configuration.lowThreshold = {
            id: Number(oldConfiguration.LowThreshold.id),
            name: oldConfiguration.LowThreshold.name,
        };
    }
    configuration.balcony = oldConfiguration.Balcony;
    configuration.kicker = oldConfiguration.Kicker;
    configuration.layout = {
        id: Number(oldConfiguration.Layout.id),
        name: oldConfiguration.Layout.name,
        changed: oldConfiguration.Layout.changed,
        sashes: oldConfiguration.Layout.sashes,
        divs: oldConfiguration.Layout.divs,
    };
    configuration.oneGlazing = oldConfiguration.OneGlazing;
    configuration.oneGlazingBead = oldConfiguration.OneGlazingBead;
    configuration.oneHandle = oldConfiguration.OneHandle;
    configuration.oneHandlesHeight = oldConfiguration.OneHandlesHeight;
    configuration.oneReinforcement = oldConfiguration.OneReinforcement;
    configuration.glazingBeadColor = {
        id: Number(oldConfiguration.GlazingBeadColor.id),
        name: oldConfiguration.GlazingBeadColor.name,
        isDefault: oldConfiguration.GlazingBeadColor.isDefault,
        RAL: Boolean(oldConfiguration.GlazingBeadColor.RAL),
    };
    if (oldConfiguration.SealColor && oldConfiguration.SealColor.id) {
        configuration.sealColor = {
            id: Number(oldConfiguration.SealColor.id),
            name: oldConfiguration.SealColor.name,
            isDefault: oldConfiguration.SealColor.isDefault,
        };
    }
    configuration.hasHandle = oldConfiguration.HasHandle;
    configuration.handleType = oldConfiguration.HandleType as HandlesType;
    if (oldConfiguration.Hinge && oldConfiguration.Hinge.id) {
        configuration.hinge = {
            id: Number(oldConfiguration.Hinge.id),
            name: oldConfiguration.Hinge.name,
            priceSource: oldConfiguration.Hinge.price_source,
            color: {
                id: Number(oldConfiguration.HingeColor.id),
                name: oldConfiguration.HingeColor.name,
                isDefault: oldConfiguration.HingeColor.isDefault,
            },
        };
    }
    if (oldConfiguration.Lock && oldConfiguration.Lock.id) {
        configuration.lock = {
            id: Number(oldConfiguration.Lock.id),
            name: oldConfiguration.Lock.name,
        };
    }
    if (oldConfiguration.HasWarmEdge) {
        configuration.glazingSpacer = {
            id: Number(oldConfiguration.WarmEdge.id),
            name: oldConfiguration.WarmEdge.name,
            u: Number(oldConfiguration.WarmEdge.u),
        };
    }
    if (
        configuration.sashes.some(
            sash =>
                sash.muntins.vertical.length > 0
                || sash.muntins.horizontal.length > 0
                || sash.intSashes.some(
                    intSash =>
                        intSash.muntins.vertical.length > 0 || intSash.muntins.horizontal.length > 0
                )
        )
    ) {
        configuration.muntins = {
            sizeId: Number(oldConfiguration.MuntinsData.sizeId),
            size: Number(oldConfiguration.MuntinsData.rWidth),
            colorDraw: oldConfiguration.MuntinsData.colorDraw,
            colorInner: {
                id: Number(oldConfiguration.MuntinsData.color.id),
                idWin: Number(oldConfiguration.MuntinsData.color.idWin),
                name: oldConfiguration.MuntinsData.color.name,
                code: oldConfiguration.MuntinsData.color.code,
                isDefault: false,
                RAL: Boolean(oldConfiguration.MuntinsData.color.RAL),
                type: oldConfiguration.MuntinsData.color.type,
            },
            colorOuter: {
                id: Number(oldConfiguration.MuntinsData.colorOut.id),
                idWin: Number(oldConfiguration.MuntinsData.colorOut.idWin),
                name: oldConfiguration.MuntinsData.colorOut.name,
                code: oldConfiguration.MuntinsData.colorOut.code,
                isDefault: false,
                RAL: Boolean(oldConfiguration.MuntinsData.colorOut.RAL),
                type: oldConfiguration.MuntinsData.colorOut.type,
            },
            type: {
                id: Number(oldConfiguration.MuntinsData.typeCode),
                type: oldConfiguration.MuntinsData.type,
            },
            duplex: oldConfiguration.MuntinsData.duplex,
        };
    }

    configuration.fitting = {
        id: Number(oldConfiguration.Fitting.id),
        name: oldConfiguration.Fitting.name,
    };

    configuration.accessories = oldConfiguration.Accessories.map(
        accessory => new Accessory(accessory)
    );
    configuration.sideAccessories = {
        top: oldConfiguration.SideAccessories.top.map(accessory => new Accessory(accessory)),
        bottom: oldConfiguration.SideAccessories.bottom.map(accessory => new Accessory(accessory)),
        left: oldConfiguration.SideAccessories.left.map(accessory => new Accessory(accessory)),
        right: oldConfiguration.SideAccessories.right.map(accessory => new Accessory(accessory)),
    };

    configuration.rollerShutter = oldConfiguration.hasRoller
        ? new RollerShutter(oldConfiguration.RollerShutter)
        : null;
    configuration.isMistakeProduct = oldConfiguration.IsMistakeProduct;
    configuration.hasWarranty = oldConfiguration.Warranty;
    configuration.frame = {
        id: Number(oldConfiguration.Frame.id),
        isDefault: oldConfiguration.Frame.isDefault,
        name: oldConfiguration.Frame.name,
    };
    configuration.sashFrame = {
        id: Number(oldConfiguration.SashFrame.id),
        isDefault: oldConfiguration.SashFrame.isDefault,
        name: oldConfiguration.SashFrame.name,
    };
    configuration.parameters = {
        ap: oldConfiguration.Ap,
        g: oldConfiguration.G,
        lt: oldConfiguration.Lt,
        rw: oldConfiguration.Rw,
        ug: oldConfiguration.Ug,
        uw: oldConfiguration.Uw,
        sw: oldConfiguration.Sw,
        weight: oldConfiguration.Weight,
        wl: oldConfiguration.Wl,
        wp: oldConfiguration.Wp,
        lab: oldConfiguration.Lab,
    };
    return configuration;
}

function oldMapSash(sash) {
    const newSash: any = {
        id: Number(sash.id),
        index: Number(sash.index),
        x: Number(sash.rx),
        y: Number(sash.ry),
        width: Number(sash.rWidth),
        height: Number(sash.rHeight),
        muntins: {
            horizontal: sash.muntins.horizontal.map(oldMapMuntin),
            vertical: sash.muntins.vertical.map(oldMapMuntin),
        },
        nearDividers: {
            left: Number(sash.nearDividers.left),
            right: Number(sash.nearDividers.right),
            top: Number(sash.nearDividers.top),
            bottom: Number(sash.nearDividers.bottom),
        },
        filling: {
            id: Number(sash.glazing.id),
            name: sash.glazing.name,
            producer: sash.glazing.producer,
            type: sash.glazing.type,
            weight: Number(sash.glazing.weight),
        },
        glazingBead: {
            id: Number(sash.glazingBead.id),
            name: sash.glazingBead.name,
            shape: sash.glazingBead.shape,
        },
        panelGlazing: null,
        fillingSizes: {
            area: Number(sash.glazingSizes.area),
            width: Number(sash.glazingSizes.width),
            height: Number(sash.glazingSizes.height),
            x: Number(sash.glazingSizes.x),
            y: Number(sash.glazingSizes.y),
        },
        fillingSizesUg: sash.glazingSizesUg
            ? {
                  area: Number(sash.glazingSizesUg.area),
                  width: Number(sash.glazingSizesUg.width),
                  height: Number(sash.glazingSizesUg.height),
                  x: Number(sash.glazingSizesUg.x),
                  y: Number(sash.glazingSizesUg.y),
              }
            : {
                  area: Number(sash.glazingSizes.area),
                  width: Number(sash.glazingSizes.width),
                  height: Number(sash.glazingSizes.height),
                  x: Number(sash.glazingSizes.x),
                  y: Number(sash.glazingSizes.y),
              },
        shape: sash.shape.shape,
        hardware: sash.hardware.map(accessory => new Accessory(accessory)),
        reinforcement: null,
    };

    ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
        if (sash.glazing[field] != null) {
            newSash.filling[field] = Number(sash.glazing[field]);
        }
    });

    if (sash.parentId != null) {
        newSash.parentId = sash.parentId;
    } else {
        newSash.type = {
            id: Number(sash.type.id),
            type: sash.type.type,
            name: sash.type.type,
            handlePosition: sash.type.handle_position,
        };
        newSash.intSashes = sash.intSashes.map(oldMapSash);
        newSash.intDividers = sash.intDividers.map(oldMapDivider);
        newSash.intAdjacentSashes = {
            top: sash.intEdgeSashes.top
                .filter(e => Common.isObject(e))
                .map(edgeSash => Number(edgeSash.id)),
            bottom: sash.intEdgeSashes.bottom
                .filter(e => Common.isObject(e))
                .map(edgeSash => Number(edgeSash.id)),
            left: sash.intEdgeSashes.left
                .filter(e => Common.isObject(e))
                .map(edgeSash => Number(edgeSash.id)),
            right: sash.intEdgeSashes.right
                .filter(e => Common.isObject(e))
                .map(edgeSash => Number(edgeSash.id)),
        };

        if (sash.glazing.type === 'deco_panels' && sash.panelGlazing.id) {
            newSash.panelGlazing = {
                id: Number(sash.panelGlazing.id),
                name: sash.panelGlazing.name,
                producer: sash.panelGlazing.producer,
                type: sash.panelGlazing.type,
                weight: Number(sash.panelGlazing.weight),
            };
            ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
                if (sash.panelGlazing[field] != null) {
                    newSash.panelGlazing[field] = Number(sash.panelGlazing[field]);
                }
            });
            newSash.panelType = sash.panelType;
        }

        newSash.oneGlazing = sash.oneGlazing;
        newSash.oneGlazingBead = sash.oneGlazingBead;

        newSash.mosquito = null;
        if (sash.mosquito.isMosquito) {
            newSash.mosquito = {
                colors: {
                    frame: null,
                    net: null,
                    panel: null,
                },
                width: Number(sash.mosquito.dimensions.width),
                height: Number(sash.mosquito.dimensions.height),
            };
            ['frame', 'net', 'panel'].forEach(side => {
                if (sash.mosquito.colors[side].name) {
                    newSash.mosquito.colors[side] = {
                        id: Number(sash.mosquito.colors[side].id),
                        name: sash.mosquito.colors[side].name,
                        code: sash.mosquito.colors[side].code,
                        RAL: Boolean(sash.mosquito.colors[side].RAL),
                        isDefault: sash.mosquito.colors[side].isDefault,
                    };
                }
            });
        }

        newSash.frame = {
            id: Number(sash.frame.id),
            isDefault: sash.frame.isDefault,
            name: sash.frame.name,
        };

        if (sash.reinforcement) {
            newSash.reinforcement = {
                id: Number(sash.reinforcement.id),
                name: sash.reinforcement.name,
                showOnOffer: sash.reinforcement.show_on_offer,
            };
        }

        if (['F', 'FF', 'OFF', 'DS', 'DSC', 'DRP'].indexOf(newSash.type.type) === -1) {
            newSash.handleInner = sash.handle
                ? {
                    id: Number(sash.handle.id),
                    handleType: sash.handle.handle_type,
                    name: sash.handle.name,
                    color: {
                        id: Number(sash.handleColor.id),
                        isDefault: sash.handleColor.isDefault,
                        name: sash.handleColor.name,
                    },
                }
                : null;
            newSash.handleOuter = sash.handleOuter
                ? {
                    id: Number(sash.handleOuter.id),
                    handleType: sash.handleOuter.handle_type,
                    name: sash.handleOuter.name,
                    color: {
                        id: Number(sash.handleOuterColor.id),
                        isDefault: sash.handleOuterColor.isDefault,
                        name: sash.handleOuterColor.name,
                    },
                }
                : null;
            newSash.handleHeight = Number(sash.rHandleY);
            newSash.defaultHandleHeight = sash.defaultHandleHeight;
            newSash.handleHeightType = sash.handleHeightType;
        }
    }

    return newSash;
}

function oldMapMuntin(muntin) {
    return {
        id: Number(muntin.id),
        width: Number(muntin.width),
        direction: muntin.direction,
        positionInGlazing: Number(muntin.rPosition),
        positionInSash: Number(muntin.rPositionS),
        lengthInGlazing: Number(muntin.rLength),
        lengthInSash: Number(muntin.rLengthS),
        startCoordInGlazing: Number(muntin.startCoord),
        startCoordInSash: Number(muntin.startCoordS),
        startPinPoint: muntin.startPinPoint == null ? null : Number(muntin.startPinPoint),
        endPinPoint: muntin.endPinPoint == null ? null : Number(muntin.endPinPoint),
    };
}

function oldMapDivider(divider) {
    const newDivider = {
        id: Number(divider.id),
        isDefault: divider.isDefault,
        type: divider.type,
        dividerId: Number(divider.div.id),
        name: divider.div.name,
        position: Number(divider.direction === 'horizontal' ? divider.ry : divider.rx),
        length: Number(divider.direction === 'horizontal' ? divider.rWidth : divider.rHeight),
        innerWidth: Number(divider.div.width),
        outerWidth: Number(divider.div.width_outer),
        direction: divider.direction,
        adjacentSashes: {},
        adjacentDividers: {},
    };
    if (newDivider.direction === 'horizontal') {
        newDivider.adjacentSashes = {
            top: divider.multiAlignTop.filter(e => Common.isObject(e)).map(sash => Number(sash.id)),
            bottom: divider.multiAlignBottom
                .filter(e => Common.isObject(e))
                .map(sash => Number(sash.id)),
        };
        newDivider.adjacentDividers = {
            top: divider.multiAlignTopDiv
                .filter(e => Common.isObject(e))
                .map(div => Number(div.id)),
            bottom: divider.multiAlignBottomDiv
                .filter(e => Common.isObject(e))
                .map(div => Number(div.id)),
        };
    } else {
        newDivider.adjacentSashes = {
            left: divider.multiAlignLeft
                .filter(e => Common.isObject(e))
                .map(sash => Number(sash.id)),
            right: divider.multiAlignRight
                .filter(e => Common.isObject(e))
                .map(sash => Number(sash.id)),
        };
        newDivider.adjacentDividers = {
            left: divider.multiAlignLeftDiv
                .filter(e => Common.isObject(e))
                .map(div => Number(div.id)),
            right: divider.multiAlignRightDiv
                .filter(e => Common.isObject(e))
                .map(div => Number(div.id)),
        };
    }
    return newDivider;
}
