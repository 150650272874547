import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'icc-confirm-dialog',
  template: require('./confirm-dialog.component.html'),
  styles: [require('./confirm-dialog.component.scss')]
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      question: string,
      options: {
        name: string,
        callback: () => void,
        accent?: boolean
    }[]
    }
  ) { }

  ngOnInit() {
  }
}
