import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { IccFilling } from '@icc/common/data-types';

@Component({
  selector: 'icc-panel-glazings-list-page',
  template: require('./panel-glazings-list-page.component.html'),
  styles: [require('./panel-glazings-list-page.component.scss')]
})
export class PanelGlazingsListPageComponent extends PageComponent implements OnInit {

  public title = _('STEPS|Wypełnienie');
    public options = [];

    fillings: iccListItem[] = [];
    selectedFilling: number | undefined;

    constructor(
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            fillings: IccFilling[];
            selectedFilling: IccFilling;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.fillings = this.pageData.fillings.map<iccListItem>(filling => ({
            id: Number(filling.id),
            title: filling.name,
            imageUrl: `/files/filling/${filling.image}`,
            description: `${filling.code}`,
        }));
        this.selectedFilling = Number(this.pageData.selectedFilling && this.pageData.selectedFilling.id);
    }

    selectFilling(item: iccListItem) {
        const filling = this.pageData.fillings.find(f => Number(f.id) === item.id);
        this.sharedFacade.closePage({
            glazing: filling,
        });
    }

}
