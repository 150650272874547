import { Common } from '@icc/common/Common';
import { logger, core } from '@icc/common/helpers';
import { OnlineStatusService } from '../online-status.service';
import { UserService } from '@icc/common/user.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { ResourceService } from '../resource.service';
import { IccDatabase, IccSimpleDatabase, DatabaseManagerToken } from '@icc/helpers/browser';
import { redirectOnChangedUser } from './RedirectUser';
import * as camelCase from 'camelcase';
import * as pluralize from 'pluralize';
import * as hash from 'object-hash';

import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { TranslateService } from '@icc/common/translate.service';
import { OfferSequenceService } from '@icc/common/offers/OfferSequenceService';
import { Location } from '@angular/common';
import { OfferSummaryService } from '@icc/legacy/panel/offer-summary.service';
import { TokenService } from '@icc/legacy/authorization/token.service';
import { SyncError } from '../sync-error';

interface SyncInfo {
    utc: {
        [prop: string]: number;
    };
    seq: {
        [prop: string]: number;
    };
    revs?: {
        [prop: string]: {
            [key: string]: string;
        };
    };
}

type SyncElement = 'Offer' | 'Position' | 'PositionAttachment' | 'OfferAttachment' | 'Client';

interface DataFromServerInfo {
    elements: SyncElement[];
    [name: string]: any[];
}

@Injectable()
export class SyncMethodsService {
    messageShown = false;
    socket: SocketIOClient.Socket;

    constructor(
        private OnlineStatusService: OnlineStatusService,
        private UserService: UserService,
        @Inject(DatabaseManagerToken) private DatabaseManager,
        private EventBusService: EventBusService,
        private ResourceService: ResourceService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private TranslateService: TranslateService,
        private location: Location,
        private tokenService: TokenService
    ) {}

    setSocket(socket: SocketIOClient.Socket) {
        this.socket = socket;
    }

    redirectOnChangedUser() {
        if (core.isWorker()) {
            (self as any).postMessage({
                subject: 'refresh',
                event: 'refresh',
            });
        } else {
            redirectOnChangedUser();
        }
    }

    redirectLogout() {
        if (core.isWorker()) {
            (self as any).postMessage({
                subject: 'logout',
                event: 'logout',
            });
        } else {
            if (this.tokenService.getToken()) {
                this.location.go('/app/logout');
            }
        }
    }

    emitSyncedEvent(name, info: DataFromServerInfo | {} = {}) {
        if (core.isWorker()) {
            (self as any).postMessage({
                subject: 'synchronize',
                event: name.length > 0 ? pluralize(camelCase('synced_' + name)) : 'synced',
                syncedInfo: info,
            });
        } else {
            this.EventBusService.post({
                key: name.length > 0 ? pluralize(camelCase('synced_' + name)) : 'synced',
                value: info,
            });
        }
    }

    getSyncActionName(name: string, filterName: string = '') {
        const user = this.UserService.get();
        const syncActionNameParts = [user.marketId, user.id || '', name];
        if (filterName.length > 0) {
            syncActionNameParts.push(filterName);
        }
        const syncActionName = syncActionNameParts.join('_');
        return syncActionName;
    }

    async getLastSyncInfo(name: string, filterName: string = '') {
        const syncActionName = this.getSyncActionName(name, filterName);
        let utcObject = { utc: 0 };
        let seqObject = { seq: -1 };
        try {
            utcObject = await (this.DatabaseManager.get('Synchronization') as IccDatabase).get(
                `utc_${syncActionName}`
            );
            seqObject = await (this.DatabaseManager.get('Synchronization') as IccDatabase).get(
                `seq_${syncActionName}`
            );
            // tslint:disable-next-line:no-empty
        } catch (error) {}
        return {
            utc: utcObject.utc,
            seq: seqObject.seq,
        };
    }

    async getDependentSyncInfo(
        name: string,
        dependentFilter: { [prop: string]: any },
        db: IccDatabase
    ) {
        const dependentDocuments = await db.getAll('by_dealer_offer_id', {
            key: dependentFilter.dealer_offer_id,
        });
        const revs: {
            [key: string]: string;
        } = {};
        dependentDocuments.forEach(doc => {
            if (doc.doc.revision) {
                revs[doc.doc.tmp_id] = hash(doc.doc.revision);
            }
        });
        return revs;
    }

    async setSyncInfo(name: string, info: { utc: number; seq?: number }, filterName: string = '') {
        const syncActionName = this.getSyncActionName(name, filterName);
        const syncDocuments: any[] = [
            {
                _id: `utc_${syncActionName}`,
                utc: info.utc,
            },
        ];
        if (info.seq) {
            syncDocuments.push({
                _id: `seq_${syncActionName}`,
                seq: info.seq,
            });
        }
        await (this.DatabaseManager.get('Synchronization') as IccDatabase).updateMany(
            syncDocuments
        );
    }

    async oneWayXHR(name: string, resource, props, compact) {
        if (this.OnlineStatusService.getStatus()) {
            await this.checkAvailableStorage();
            const user = this.UserService.get();
            const db = this.DatabaseManager.get(name) as IccSimpleDatabase;
            const syncInfo = await this.getLastSyncInfo(name);
            let data = await resource({
                utc: syncInfo.utc,
                user_id: user.id,
                appVersion: this.config().IccConfig.Version,
            });
            if (
                data.marketId
                && ((user && user.marketId && Number(data.marketId) !== Number(user.marketId))
                    || Number(db.getUserMarketId()) !== Number(data.marketId))
            ) {
                logger.error('Dane ze złego rynku podczas synchronizacji!', {
                    userMarketId: user.marketId,
                    dataMarketId: data.marketId,
                });
                this.redirectOnChangedUser();
                throw new SyncError(
                    'INCORRECT_MARKET',
                    'Dane ze złego rynku podczas synchronizacji!'
                );
            }
            if (Common.isDefined(data.last_utc)) {
                if (data.refresh) {
                    this.redirectOnChangedUser();
                }
                const dataToSave = Object.keys(data).reduce<any>((prev, prop) => {
                    if (
                        data[prop]
                        && ['last_utc', 'refresh', '$promise', '$resolved'].indexOf(prop) === -1
                    ) {
                        prev[prop] = data[prop];
                    }
                    return prev;
                }, {});
                if (Object.keys(dataToSave).length > 0) {
                    const document = {
                        data: dataToSave,
                    };
                    await db.put(document);
                    if (compact) {
                        await db.compact();
                    }
                    await this.setSyncInfo(name, {
                        utc: data.last_utc,
                    });
                    data = null;
                    this.emitSyncedEvent(name);
                }
            }
        }
        return true;
    }

    async twoWaySocket(
        mode: 'sync' | 'up' | 'down' = 'sync',
        names,
        resource,
        compact,
        rowsToGet,
        dependentFilter: { [prop: string]: any }
    ) {
        if (!this.OnlineStatusService.getStatus()) {
            return;
        }
        await this.checkAvailableStorage();

        const [, ...dependent] = names;
        const user = this.UserService.get();
        const syncInfo: SyncInfo = {
            utc: {},
            seq: {},
            revs: {},
        };
        const db: Record<string, IccDatabase> = {};
        for (const name of names) {
            const lastSyncInfo = await this.getLastSyncInfo(name);
            syncInfo.utc[name] = lastSyncInfo.utc;
            syncInfo.seq[name] = lastSyncInfo.seq;
            db[name] = this.DatabaseManager.get(name) as IccDatabase;
        }

        if (mode !== 'up' && dependent) {
            for (const name of dependent) {
                const dependentSyncInfo = await this.getDependentSyncInfo(
                    name,
                    dependentFilter,
                    db[name]
                );
                syncInfo.revs[name] = dependentSyncInfo;
            }
        }

        switch (mode) {
            case 'sync':
                return this.sync(names, resource, compact, syncInfo, db, user);
            case 'up':
                return this.upload(names, resource, compact, syncInfo, db, user);
            case 'down':
                return this.download(names, resource, compact, syncInfo, db, user, rowsToGet);
        }
    }

    async sync(names, resource, compact, syncInfo: SyncInfo, db, user, incoherencesErrors = []) {
        let changesToSend, changedDocuments;
        if (incoherencesErrors.length > 0) {
            const changes = await this.getCoherents(names, db, syncInfo, incoherencesErrors);
            changesToSend = changes.changesToSend;
            changedDocuments = changes.changedDocuments;
        } else {
            const changes = await this.getChanges(names, db, syncInfo);
            changesToSend = changes.changesToSend;
            changedDocuments = changes.changedDocuments;
        }
        const newResource = {
            utc: syncInfo.utc,
            revs: syncInfo.revs,
            changes: changesToSend,
            user_id: user.id,
            appVersion: this.config().IccConfig.Version,
        };
        return new Promise((resolve, reject) => {
            this.socket.emit('sync_' + resource, newResource, async dataFromServer => {
                try {
                    if (dataFromServer.error != null) {
                        let error = new Error(dataFromServer.error);
                        error = { ...error, ...dataFromServer };
                        throw error;
                    }
                    if (
                        dataFromServer.refresh
                        || user.id != dataFromServer.userId
                        || db[names[0]].getUserId() != dataFromServer.userId
                        || Number(db[names[0]].getUserMarketId()) != Number(dataFromServer.marketId)
                    ) {
                        this.redirectOnChangedUser();
                    }
                    if (Common.isDefined(dataFromServer.lastUtc)) {
                        const dataFromServerInfo: DataFromServerInfo = {
                            elements: [],
                        };
                        for (const name of names) {
                            await this.saveDataFromServer(
                                dataFromServer,
                                name,
                                db,
                                compact,
                                dataFromServerInfo
                            );
                            await this.updateSyncInfo(
                                syncInfo,
                                name,
                                changedDocuments,
                                dataFromServer,
                                incoherencesErrors.length === 0
                            );
                        }
                        this.emitSyncedChanges(
                            names,
                            changedDocuments,
                            dataFromServer,
                            dataFromServerInfo
                        );
                    }
                    resolve();
                } catch (error) {
                    if (
                        error.errorCode === 'ER_INCOHERENT_OFFER'
                        && incoherencesErrors.length === 0
                    ) {
                        try {
                            await this.sync(
                                names,
                                resource,
                                compact,
                                syncInfo,
                                db,
                                user,
                                error.errorsList
                            );
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    } else if (error.errorCode === 'NO_SESSION') {
                        this.redirectLogout();
                    } else {
                        reject(error);
                    }
                }
            });
        });
    }

    async upload(names, resource, compact, syncInfo: SyncInfo, db, user, incoherencesErrors = []) {
        let changesToSend, changedDocuments;
        if (incoherencesErrors.length > 0) {
            const changes = await this.getCoherents(names, db, syncInfo, incoherencesErrors);
            changesToSend = changes.changesToSend;
            changedDocuments = changes.changedDocuments;
        } else {
            const changes = await this.getChanges(names, db, syncInfo);
            changesToSend = changes.changesToSend;
            changedDocuments = changes.changedDocuments;
        }
        const newResource = {
            utc: syncInfo.utc,
            changes: changesToSend,
            user_id: user.id,
            appVersion: this.config().IccConfig.Version,
        };
        return new Promise((resolve, reject) => {
            this.socket.emit('changed_' + resource, newResource, async dataFromServer => {
                try {
                    if (dataFromServer.error != null) {
                        let error = new Error(dataFromServer.error);
                        error = { ...error, ...dataFromServer };
                        throw error;
                    }
                    if (
                        dataFromServer.refresh
                        || user.id != dataFromServer.userId
                        || db[names[0]].getUserId() != dataFromServer.userId
                        || Number(db[names[0]].getUserMarketId()) != Number(dataFromServer.marketId)
                    ) {
                        this.redirectOnChangedUser();
                    }
                    if (Common.isDefined(dataFromServer.lastUtc)) {
                        const dataFromServerInfo: DataFromServerInfo = {
                            elements: [],
                        };
                        for (const name of names) {
                            await this.saveDataFromServer(
                                dataFromServer,
                                name,
                                db,
                                compact,
                                dataFromServerInfo
                            );
                            await this.updateSyncInfo(
                                syncInfo,
                                name,
                                changedDocuments,
                                dataFromServer,
                                false
                            );
                        }
                        this.emitSyncedChanges(
                            names,
                            changedDocuments,
                            dataFromServer,
                            dataFromServerInfo
                        );
                    }
                    resolve();
                } catch (error) {
                    if (
                        error.errorCode === 'ER_INCOHERENT_OFFER'
                        && incoherencesErrors.length === 0
                    ) {
                        await this.sync(
                            names,
                            resource,
                            compact,
                            syncInfo,
                            db,
                            user,
                            error.errorsList
                        );
                    } else {
                        reject(error);
                    }
                }
            });
        });
    }

    async download(names, resource, compact, syncInfo: SyncInfo, db, user, rowsToGet = null) {
        const newResource = {
            utc: syncInfo.utc,
            revs: syncInfo.revs,
            user_id: user.id,
            rowsToGet,
            appVersion: this.config().IccConfig.Version,
        };

        return new Promise((resolve, reject) => {
            this.socket.emit('get_' + resource, newResource, async dataFromServer => {
                try {
                    if (dataFromServer.error != null) {
                        throw new Error(dataFromServer.error);
                    }
                    if (
                        dataFromServer.refresh
                        || user.id != dataFromServer.userId
                        || db[names[0]].getUserId() != dataFromServer.userId
                        || Number(db[names[0]].getUserMarketId()) != Number(dataFromServer.marketId)
                    ) {
                        this.redirectOnChangedUser();
                    }
                    if (Common.isDefined(dataFromServer.lastUtc)) {
                        const changedDocuments: {
                            [name: string]: {
                                changes: any[];
                            };
                        } = {};
                        const dataFromServerInfo: DataFromServerInfo = {
                            elements: [],
                        };
                        for (const name of names) {
                            changedDocuments[name] = {
                                changes: [],
                            };
                            await this.saveDataFromServer(
                                dataFromServer,
                                name,
                                db,
                                compact,
                                dataFromServerInfo
                            );
                            await this.updateSyncInfo(
                                syncInfo,
                                name,
                                changedDocuments,
                                dataFromServer
                            );
                        }
                        this.emitSyncedChanges(
                            names,
                            changedDocuments,
                            dataFromServer,
                            dataFromServerInfo
                        );
                    }
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        });
    }

    /**
     * Funkcja do pojedynczej synchronizacji przy początkowej synchronizacji
     * @param  {function} syncFunc Funkcja do synchronizowania
     * @return {promise}          Promise
     */
    async once(syncFunc) {
        try {
            await syncFunc(true, 'sync');
        } catch (error) {
            logger.error(error);
            throw error;
        }
    }

    async liveXHR(syncFunc, interval = this.config().syncInterval3, empty = true) {
        await setTimeout(async () => {
            try {
                empty = !(await syncFunc(false, empty));
            } catch (error) {
                logger.error(error);
            }
            await this.liveXHR(syncFunc, interval, empty);
        }, interval);
    }

    async liveTwoWay(syncFunc, resource: string, eventsUp = null, eventsSync = null) {
        if (resource) {
            this.socket.on('updated_' + resource, data => syncFunc(false, 'down', data));
        }
        if (eventsUp) {
            for (const event of eventsUp) {
                this.EventBusService.subscribeWithoutConfiguration(event, data => {
                    syncFunc(false, 'up');
                });
            }
        }
        this.EventBusService.subscribeWithoutConfiguration('connected', data => {
            syncFunc(false, 'sync');
        });
        if (eventsSync) {
            for (const event of eventsSync) {
                this.EventBusService.subscribeWithoutConfiguration(event, data => {
                    syncFunc(false, 'sync');
                });
            }
        }
    }

    async liveOneWay(syncFunc, resource) {
        if (resource) {
            this.socket.on('updated_' + resource, () => syncFunc(false, true));

            this.EventBusService.subscribeWithoutConfiguration('connected', () =>
                syncFunc(false, true)
            );
        }
    }

    async checkAvailableStorage() {
        const estimate = await Common.availableStorage();
        if (!estimate || this.messageShown) {
            return;
        }
        const { quota, usage } = estimate;
        if (quota - usage < 50000000) {
            this.messageShown = true;
            const ravenMessage = `Kończy się miejsce na dysku dla przeglądarki. Wykorzystano: ${Common.humanFileSize(
                usage
            )}/${Common.humanFileSize(quota)}`;
            const message = this.TranslateService.instant(
                'SYNCHRONIZE|Kończy się miejsce na dysku dla przeglądarki. Prosimy skontaktować się z działem technicznym.'
            );
            if (core.isWorker()) {
                (self as any).postMessage({ subject: 'availableStorage', message, ravenMessage });
            } else {
                logger.error(ravenMessage);
                alert(message);
            }
        }
    }

    private emitSyncedChanges(
        names: SyncElement[],
        changedDocuments: {},
        dataFromServer: any,
        dataFromServerInfo: DataFromServerInfo
    ) {
        if (
            names.some(name => changedDocuments[name].changes.length > 0)
            || (Common.isObject(dataFromServer.rows)
                && names.some(
                    name =>
                        Common.isArray(dataFromServer.rows[name])
                        && dataFromServer.rows[name].length > 0
                ))
        ) {
            this.emitSyncedEvent('', dataFromServerInfo);
        } else {
            this.emitSyncedEvent('', {
                elements: [],
            });
        }
    }

    private async updateSyncInfo(
        syncInfo: SyncInfo,
        name: any,
        changedDocuments: {},
        dataFromServer: any,
        utcUpdate = true
    ) {
        let newSequence = syncInfo.seq[name];
        if (
            ((changedDocuments[name].changes.length === 0
                && (!Common.isObject(dataFromServer.rows)
                    || !Common.isArray(dataFromServer.rows[name])))
                || (Common.isObject(dataFromServer.rows)
                    && Common.isArray(dataFromServer.rows[name])
                    && changedDocuments[name].changes.length <= dataFromServer.rows[name].length))
            && changedDocuments[name].sequence
        ) {
            newSequence = changedDocuments[name].sequence;
        }
        if (syncInfo.utc[name] !== dataFromServer.lastUtc || syncInfo.seq[name] !== newSequence) {
            let newUtc = syncInfo.utc[name];
            if (
                Common.isObject(dataFromServer.rows)
                && Common.isArray(dataFromServer.rows[name])
                && dataFromServer.rows[name].length > 0
                && utcUpdate
            ) {
                newUtc = dataFromServer.lastUtc;
            }
            await this.setSyncInfo(name, {
                utc: newUtc,
                seq: newSequence,
            });
        }
    }

    private async saveDataFromServer(
        dataFromServer: any,
        name: any,
        db: Record<string, IccDatabase>,
        compact: any,
        dataFromServerInfo: DataFromServerInfo
    ) {
        if (
            Common.isObject(dataFromServer.rows)
            && Common.isArray(dataFromServer.rows[name])
            && dataFromServer.rows[name].length > 0
            && db[name].getUserId() === dataFromServer.userId
            && Number(db[name].getUserMarketId()) === Number(dataFromServer.marketId)
        ) {
            await db[name].updateMany(dataFromServer.rows[name], {
                machine: this.config().machine,
                synced: true,
                noChangeRevision: true,
                onlyNoChanged: true,
            });
            dataFromServerInfo.elements.push(name);
            dataFromServerInfo[name] = dataFromServer.rows[name];
            if (compact) {
                await db[name].compact();
            }
        }
        return dataFromServerInfo;
    }

    private async getChanges(names: any, db: {}, syncInfo: SyncInfo) {
        const changedDocuments = {};
        const changesToSend = {};
        for (const name of names) {
            changedDocuments[name] = await db[name].getChanged({
                fromSequence: syncInfo.seq[name],
            });
            changesToSend[name] = changedDocuments[name].changes;
        }
        return { changesToSend, changedDocuments };
    }

    private async getCoherents(names, db: any, syncInfo: SyncInfo, incoherencesErrors: any[]) {
        const changedDocuments: any = {};
        let changesToSend = {};
        for (const name of names) {
            changedDocuments[name] = {
                changes: [],
            };
        }
        for (const error of incoherencesErrors) {
            let offer;
            if (
                !changedDocuments.Offer.changes.length
                || !changedDocuments.Offer.changes.every(o => o.id !== error.offerId)
            ) {
                offer = await db.Offer.get(error.offerId);
                changedDocuments.Offer.changes.push({
                    doc: offer,
                    id: offer.tmp_id,
                });
            } else {
                offer = changedDocuments.Offer.changes.find(o => o.id === error.offerId);
            }
            if (!offer) {
                continue;
            }

            switch (error.name) {
                case 'OFFER_CLIENT_NOT_EXISTS':
                    if (!changedDocuments.Client) {
                        changedDocuments.Client = {
                            changes: [],
                        };
                    }
                    if (
                        !changedDocuments.Client.changes.length
                        || !changedDocuments.Client.changes.every(o => o.id !== offer.client_id)
                    ) {
                        const client = await db.Client.get(offer.client_id);
                        changedDocuments.Client.changes.push({
                            doc: client,
                            id: client.tmp_id,
                        });
                    }
                    break;
                case 'OFFER_POSITIONS_NOT_EXISTS':
                    const positionsKeys = OfferSequenceService.keysFromSequence(offer.sequence);
                    const positions = await db.Position.getMany(positionsKeys);
                    changedDocuments.Position.changes.push(...positions);
                    break;
                case 'OFFER_INCORRECT_TOTAL_PRICE':
                    if (core.isWorker()) {
                        (self as any).postMessage({
                            subject: 'emittedEvent',
                            name: 'correctTotalOfferPrice',
                            value: {
                                offerId: offer.tmp_id,
                                sequence: error.sequence,
                            },
                        });
                    } else {
                        this.EventBusService.post({
                            key: 'correctTotalOfferPrice',
                            value: {
                                offerId: offer.tmp_id,
                                sequence: error.sequence,
                            },
                        });
                    }
                    const result = await this.EventBusService.toPromise('correctedTotalOfferPrice');
                    changedDocuments.Offer.changes = changedDocuments.Offer.changes.filter(
                        o => o.id !== offer.tmp_id
                    );
                    changedDocuments.Offer.changes.push({
                        doc: result.value.offer,
                        id: offer.tmp_id,
                    });
                    break;
            }
            changesToSend = Object.keys(changedDocuments).reduce((prev, name) => {
                prev[name] = changedDocuments[name].changes;
                return prev;
            }, {});
        }
        return { changesToSend, changedDocuments };
    }
}
