import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import { APP_CONFIG, AppConfigFactory, EventBusService, ConfigurationsService, DrawService, TranslateService, core, Common, WindowActiveConfiguration } from '@icc/common';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { EqualDivisionService } from '@icc/legacy/configurator/layout/equal-division.service';
import { ResizeSashService } from '@icc/legacy/configurator/layout/resize-sash.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { AlignmentsService } from '@icc/legacy/configurator/layout/alignments.service';
import { SashesService } from '@icc/legacy/configurator/steps/window/sashes/sashes.service';
import { RemoveSashService } from '@icc/legacy/configurator/layout/remove-sash.service';
import { SplitSashService } from '@icc/legacy/configurator/layout/split-sash.service';

import { MuntinsLayoutService } from '@icc/legacy/configurator/steps/window/muntins/muntins-layout.service';


@Component({
  selector: 'icc-muntins-layout-edit-page',
  template: require('./muntins-layout-edit-page.component.html'),
  styles: [require('./muntins-layout-edit-page.component.scss')]
})
export class MuntinsLayoutEditPageComponent extends PageComponent implements OnInit {
  public title = _('WINDOW|Szprosy niestandardowe');
  fullscreen = true;

//   openInfo    = openInfo;
    // drawService = DrawService;
    drawOptions: any = null;
    drawButtons = {
        fullscreen: false,
        moveShift : true,
        alignments: true,
      dimensions: false,
      extensions: true,
      mullions: true,
      profiles: false,
      sashes: true,

        toggle: (type: 'alignments' | 'dimensions' | 'extensions'
      | 'fullscreen'
      | 'mullions'
      | 'profiles'
      | 'sashes') => {
          if (type && typeof this.drawButtons[type] !== 'undefined') {
              this.drawButtons[type] = !this.drawButtons[type];
          }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            if (conf) {
            this.drawOptions = Object.assign({}, this.drawService.options, {
                dimensions: {
                    type: 'extended',
                },
                interface: {
                    moveShift: this.drawButtons.moveShift,
                },

                glazing: {
                    buttons: [
                        {
                            class: 'ico-split-h',
                            title: this.translateService.instant('INTERFACE|Dodaj szpros poziomy'),
                            condition: (response: any) => this.muntinsLayoutService.isValidSash(conf, response.sash.id),
                            onClick: (response: any) => this.muntinsLayoutService.insertMuntin(conf, response.sash.id, {v: 0, h: 1}),
                        },
                        {
                            class: 'ico-split-v',
                            title: this.translateService.instant('INTERFACE|Dodaj szpros pionowy'),
                            condition: (response: any) => this.muntinsLayoutService.isValidSash(conf, response.sash.id),
                            onClick: (response: any) => this.muntinsLayoutService.insertMuntin(conf, response.sash.id, {v: 1, h: 0}),
                        },
                        {
                            class: 'ico-gear',
                            title: this.translateService.instant('INTERFACE|Wybierz gotowy układ szprosów'),
                            condition: (response: any) => this.muntinsLayoutService.isValidSash(conf, response.sash.id),
                            onClick: (response: any) => this.muntinsLayoutService.openModalMuntinsLayout(conf, response.sash.id),
                        },
                    ],
                },
                muntins: {
                    buttons: [
                        {
                            condition: (response: any) => response.muntin.start.y === response.muntin.end.y,
                            positions: ['rect'],
                            title: this.translateService.instant('INTERFACE|Edytuj szpros poziomy'),
                            buttons: [
                                {
                                    class: 'ico-remove',
                                    title: this.translateService.instant('INTERFACE|Usuń szpros poziomy'),
                                    onClick: (response: any) => this.muntinsLayoutService.removeMuntin(conf, response.sash.id, response.muntin.id),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-move',
                                    title: this.translateService.instant('INTERFACE|Przesuń szpros poziomy'),
                                    onMove: (response: any) => this.muntinsLayoutService.moveMuntin(conf, response.sash.id, response.muntin.id, response.position),
                                    positions: ['top'],
                                },
                            ],
                        },
                        {
                            condition: (response: any) => response.muntin.start.x === response.muntin.end.x,
                            positions: ['rect'],
                            title: this.translateService.instant('INTERFACE|Edytuj szpros pionowy'),
                            buttons: [
                                {
                                    class: 'ico-arrow-down',
                                    condition: (response: any) => response.sash.muntins.some(o => o.start.y === o.end.y && o.start.y > response.muntin.start.y && o.end.y < response.muntin.end.y),
                                    title: this.translateService.instant('INTERFACE|Skróć szpros pionowy'),
                                    onClick: (response: any) => this.muntinsLayoutService.updateMuntin(conf, response.sash.id, response.muntin.id, true, true),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-arrow-up',
                                    condition: (response: any) => response.sash.muntins.some(o => o.start.y === o.end.y && o.start.y > response.muntin.start.y && o.end.y < response.muntin.end.y),
                                    title: this.translateService.instant('INTERFACE|Skróć szpros pionowy'),
                                    onClick: (response: any) => this.muntinsLayoutService.updateMuntin(conf, response.sash.id, response.muntin.id, false, true),
                                    positions: ['bottom'],
                                },
                                {
                                    class: 'ico-arrow-up',
                                    // condition: response => response.sash.muntins.some(o => o.start.y < response.muntin.start.y),
                                    title: this.translateService.instant('INTERFACE|Wydłuż szpros pionowy'),
                                    onClick: (response: any) => this.muntinsLayoutService.updateMuntin(conf, response.sash.id, response.muntin.id, true, false),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-arrow-down',
                                    // condition: response => response.sash.muntins.some(o => o.end.y > response.muntin.end.y),
                                    title: this.translateService.instant('INTERFACE|Wydłuż szpros pionowy'),
                                    onClick: (response: any) => this.muntinsLayoutService.updateMuntin(conf, response.sash.id, response.muntin.id, false, false),
                                    positions: ['bottom'],
                                },
                                {
                                    class: 'ico-remove',
                                    title: this.translateService.instant('INTERFACE|Usuń szpros pionowy'),
                                    onClick: (response: any) => this.muntinsLayoutService.removeMuntin(conf, response.sash.id, response.muntin.id),
                                    positions: ['left'],
                                },
                                {
                                    class: 'ico-move',
                                    title: this.translateService.instant('INTERFACE|Przesuń szpros pionowy'),
                                    onMove: (response: any) => this.muntinsLayoutService.moveMuntin(conf, response.sash.id, response.muntin.id, response.position),
                                    positions: ['left'],
                                },
                            ],
                        },
                    ],
                },
                onInterfaceClick: () => {},
            });
            this.drawOptions.base.side = 'inner';
        }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null
            });
        },
    };


    // this.close = function() {
    //     $uibModalInstance.close();
    // };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private framesService: BrowserFramesService,
        private extensionsService: ExtensionsService,
        private equalDivisionService: EqualDivisionService,
        private resizeSashService: ResizeSashService,
        private alignmentsService: AlignmentsService,
        private sashesService: SashesService,
        private removeSashService: RemoveSashService,
        private splitSashService: SplitSashService,
        private muntinsLayoutService: MuntinsLayoutService
    ) {

        super();
      }

      ngOnInit() {

        this.drawButtons.update();
      }

     /* openInfo(youtubeAdrress) {
        youtubeAdrress += '?rel=0';
        this.infoService.openInfoModal({title: this.translateService.instant('INTERFACE|Pomoc - edycja szprosów'), message: `<iframe width="855" height="500" src="${youtubeAdrress}" frameborder="0" allowfullscreen></iframe>`});
    } */
}





