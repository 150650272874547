import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Common } from '@icc/common/Common';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';


@Injectable()
export class PriceDoorService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private colorsDefaultsService: ColorsDefaultsService,
    ) {}

    /**
     * Cena modelu drzwi
     * @param  {Object} model       Model
     * @param  {Object} colors      Kolory
     * @param  {Number} sashesCount Liczba skrzydeł
     * @return {Number}             Cena
     */
    priceDoorModel(model, colors, sashesCount) {
        const colorType = this.colorsDefaultsService.getColorTypeForColors(colors);

        let field = 'price_bi_color';
        if (['White'].indexOf(colorType) > -1) {
            field = 'price_white';
        } else if (['Inner', 'Outer'].indexOf(colorType) > -1) {
            field = 'price_one_color';
        } else if (['Cream', 'Bilateral', 'Bicolor'].indexOf(colorType) > -1) {
            field = 'price_bi_color';
        }

        let price = 0;
        if (Common.isObject(model) && Common.isDefined(model[field])) {
            price = parseFloat(model[field]) * ~~sashesCount;
        }
        return price;
    }

    /**
     * Dodawanie ceny za model drzwi
     * @param  {Number} price         Cena
     * @param  {Array} PriceElems     Składowe ceny
     * @param  {Object} model         Model
     * @param  {Object} colors        Kolory
     * @param  {Number} sashesCount   Liczba skrzydeł
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @return {Number}               Cena
     */
    @PriceFunc({
        shortName: 'doorModel',
        data: {
            model: 'conf.Model',
            colors: 'conf.Colors',
            sashesCount: 'conf.Sashes.length',
        }
    })
    suppDoorModel({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {model, colors, sashesCount}): PriceSegment[] {
        if (this.config().IccConfig.Configurators.door.version > 1) {
            return [];
        }
        let supp = this.priceDoorModel(model, colors, sashesCount);
        if (Common.isUndefined(supp) || supp === null || isNaN(supp)) {
            supp = NaN;
            NoPriceCauses.push('no price for model');
        } else {
            PriceElems.doorModel = {
                id           : model.id,
                name         : model.name,
                count        : sashesCount,
                price        : supp,
                // colorField   : getAccessoryPriceField(colors),
            };
        }

        return <PriceSegment[]>[{
            type: 'doorModel',
            baseValue: supp,
            value: supp,
            valueType: 'value',
            data: {
                id   : model.id,
                name : model.name,
                count: sashesCount,
            }
        }]
    }

    /**
     * Cena portalu drzwi
     * @param  {Object} portal       Portal
     * @return {Number}             Cena
     */
    priceDoorPortal(portal) {
        let price = 0;
        if (Common.isObject(portal) && Common.isDefined(portal['price_white'])) {
            price = parseFloat(portal['price_white']);
        }
        return price;
    }

    /**
     * Dodawanie ceny za portal drzwi
     * @param  {Number} price         Cena
     * @param  {Array}  PriceElems    Składowe ceny
     * @param  {Object} portal        Portal
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @return {Number}               Cena
     */
    @PriceFunc({
        shortName: 'doorPortal',
        data: {
            portal: 'conf.doorPortal'
        }
    })
    suppDoorPortal({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {portal}): PriceSegment[] {
        const hasPortal = portal && portal.id;

        if (!hasPortal) return [];
        let supp = this.priceDoorPortal(portal);

        if (Common.isUndefined(supp) || supp === null || isNaN(supp)) {
            supp = NaN;
            NoPriceCauses.push('no price for door portal');
        } else {
            PriceElems.doorPortal = {
                id           : portal.id,
                name         : portal.name,
                price        : supp,
            };
        }

        return <PriceSegment[]>[{
            type: 'doorPortal',
            baseValue: supp,
            value: supp,
            valueType: 'value',
            data: {
                id   : portal.id,
                name : portal.name,
            }
        }]
    }

    @PriceFunc({
        shortName: 'doorSize',
        data: {
            doorSizes: 'data.doorSizes',
            sashSize: 'conf.doorSizes.sashSizeId',
        },
    })
    suppDoorSize(
        { }: PriceElemsData,
        { doorSizes, sashSize }
    ): PriceSegment[] {
        if (Common.isArray(doorSizes) && sashSize) {
            const doorSize = doorSizes.find(
                s => Number(sashSize) === Number(s.id)
            );
            return [
                {
                    type: 'doorSize',
                    baseValue: !isNaN(doorSize?.price) ? 1 + doorSize?.price / 100 : null,
                    value: !isNaN(doorSize?.price) ? 1 + doorSize?.price / 100 : null,
                    valueType: 'percent',
                    data: {
                        sashSize: sashSize,
                    },
                },
            ] as PriceSegment[];
        }
        return [];
    }
}

