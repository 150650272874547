import * as angular from 'angular';
import { TutorialsService } from './tutorials.service';

export class TutorialsCtrl {
    private subscriptions = [];
    private scrollListener = null;
    tutorialData;
    currImg = '';
    timeout = null;
    modalImg = '';
    currentTutorialStep = 0;
    tutorialActive = true;
    eventStep = '';
    currentVisited = {window: [], hs: [], door: [], roller_shutter: []};
    icoActive = false;
    modal = false;
    style = {
        paddingTop: `0px`,
        top: `${-window.pageYOffset}px` || null,
    };
    constructor(
        private $scope,
        private EventBusService,
        private TutorialsService
    ) {
        'ngInject';
        this.init();
    }
    async init() {
        this.scrollListener = window.addEventListener('scroll', () => {
            if (document.body.className.indexOf('modal-open') === -1) {
                this.style.top = `${-window.pageYOffset}px`;
                this.$scope.$apply();
            }
        });
        if (!this.TutorialsService.data) {
            await this.getData();
        }
        this.setActive();
        this.getImage(1, false);
        this.subscriptions.push(this.EventBusService.subscribe('tutorialSteps', data => {
            this.eventStep = data.value.toLowerCase();
            if (data.value === 'getStepImg') {
                this.getImage(this.$scope.config.step, false);
            } else {
                this.getImage(false, this.eventStep);
            }
        }));
        this.subscriptions.push(this.EventBusService.subscribe('changedStep', data => {
            let vm = this;
            clearTimeout(vm.timeout);
            vm.timeout = setTimeout(() => {
                if (data.value.nextStep.i !== 3 || data.value.nextStep.code === 'rollerdimensions') {
                    vm.getImage(data.value.nextStep.i, false);
                }
                vm.eventStep = data.value.nextStep.i;
            }, 50);
        }));
        this.subscriptions.push(this.EventBusService.subscribe('initializedConfigurator', data => {
            let vm = this;
            clearTimeout(vm.timeout);
            vm.timeout = setTimeout(() => {
                vm.getImage(vm.$scope.config.step, false);
            }, 50);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
        window.removeEventListener('scroll', this.scrollListener);
    }

     /**
    * Ustawianie statusu aktywności samouczka
    */
    setActive() {
        if (sessionStorage.getItem('tutorial')) {
            let visited = JSON.parse(sessionStorage.getItem('tutorial'));
            if (visited[this.$scope.config.conf].length > 0) {
                this.tutorialActive = false;
            } else {
                this.tutorialActive = true;
            }
        }
    }
     /**
    * Pobieranie danych
    */
    async getData() {
        await this.TutorialsService.init();
    }

    /**
    * Status działania tutoriala, włączony/wyłączony
    */
    tutorialOnOff() {
        this.tutorialActive = !this.tutorialActive;
    }

    /**
    * Otwiera modal z obrazem tutoriala
    */
    showTutorialModal() {
        if (this.modal) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const offsetY = document.querySelector('.modal-content').getBoundingClientRect().top;
                if (offsetY >= 0) {
                    this.style.paddingTop = `${offsetY}px`;
                    this.style.top = `0px`;
                } else {
                    this.style.paddingTop = `0px`;
                    this.style.top = `${offsetY}px`;
                }
                if (this.currImg) {
                    this.icoActive = true;
                    this.modalImg = '/files/tutorialview/' + this.currImg;
                } else {
                    this.icoActive = false;
                }
            }, 1200);
        } else {
            this.style.paddingTop = `0px`;
            this.style.top = `${-window.pageYOffset}px` || null;
            if (this.currImg) {
                this.icoActive = true;
                this.modalImg = '/files/tutorialview/' + this.currImg;
            } else {
                this.icoActive = false;
            }
        }
    }

    /**
    * Zamyka modal z obrazem tutoriala
    */
    closeTutorialModal() {
        this.style.paddingTop = `0px`;
        this.icoActive = false;
    }

    /**
    * Zapisuje do SessionStorage odwiedzone już kroki tutoriala
    */
    setVisitedSteps(step?, subStep?) {
        if (this.tutorialActive) {
            if (!subStep) {
                this.TutorialsService.sessionData[this.$scope.config.conf].push(String(step));
            } else {
                this.TutorialsService.sessionData[this.$scope.config.conf].push(String('step' + subStep));
            }
            this.TutorialsService.sessionData[this.$scope.config.conf] = (Array.from(new Set(this.TutorialsService.sessionData[this.$scope.config.conf]))).sort();
            sessionStorage.setItem('tutorial', JSON.stringify(this.TutorialsService.sessionData));
        }
    }

    /**
     * Przypisanie obrazu dla danego kroku konfiguratora
     */
    getImage(nextStep?, placementParameter?) {
            let element = null;
            this.currImg = null;
            if (nextStep) {
                element = this.TutorialsService.data.find(el => {
                    let code = el.code.toLowerCase().split('-');
                    return !code[3] && (code[0] + '-' + code[1] === this.$scope.config.conf + '-' + nextStep);
                });
            }
            if (placementParameter) {
                element = this.TutorialsService.data.find(el => {
                    let code = el.code.toLowerCase().split('-');
                    return code[0] + '-' + code[1] + '-' + code[2] === this.$scope.config.conf + '-' + this.$scope.config.step + '-' + placementParameter;
                });
            }
            if (element) {
                this.currImg = element.img;
                this.modal = element.modal;
                this.currentTutorialStep = element.code.toLowerCase().split('step')[1];
                if (nextStep && !this.TutorialsService.newPositionFlag) {
                    if (!this.TutorialsService.sessionData[this.$scope.config.conf].some(el => el === nextStep.toString()) && this.tutorialActive) {
                        this.showTutorialModal();
                        this.setVisitedSteps(nextStep, false);
                    }
                }
                if (placementParameter && !this.TutorialsService.newPositionFlag) {
                    if (!this.TutorialsService.sessionData[this.$scope.config.conf].some(el => el === 'step' + this.currentTutorialStep.toString()) && this.tutorialActive) {
                        this.showTutorialModal();
                        this.setVisitedSteps(false, this.currentTutorialStep);
                    }
                }
            }
        this.TutorialsService.newPositionFlag = null;
    }
}


