import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-guides-page',
  template: require('./guides-page.component.html'),
  styles: [require('./guides-page.component.scss')]
})
export class GuidesPageComponent extends PageComponent implements OnInit {
  public title = _('INTERFACE|Wybierz prowadnicę');

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
