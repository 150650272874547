import { Injectable } from '@angular/core';
import {
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    Common,
    core,
} from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';

@Injectable()
export class MontagesService {
    montages = [];
    loadedData = false;
    profilePositions = ['up', 'dow', 'left', 'right'];
    windowLines = {};

    constructor(
        private configurationsService: ConfigurationsService<'mosquito' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    private init() {
        if (this.configurationsService.conf.Current.type === 'mosquito') {
            this.montages = this.getMontages();
            if (
                ((Common.isObject(this.configurationsService.conf.Current.Montage)
                    && Common.isUndefined(this.configurationsService.conf.Current.Montage.id))
                    || this.configurationsService.conf.Current.Montage === null)
                && (!Common.isObject(this.configurationsService.conf.Current.WindowLine)
                    || Common.isUndefined(this.configurationsService.conf.Current.WindowLine.id))
                && !this.configurationsService.conf.Current.HoldersWidth
            ) {
                this.setDefaultValues();
            }
            this.windowLines = this.getWindowLines();
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie montazu bram garazowych
     */
    getMontages(conf = this.configurationsService.conf.Current) {
        if (
            conf
            && conf.type === 'mosquito'
            && conf.Type
            && conf.Type.id
            && this.configuratorsDataService.data.mosquitoMontages
        ) {
            return this.configuratorsDataService.data.mosquitoMontages.filter(
                e => e.types.indexOf(conf.Type.id) >= 0
            );
        } else {
            return [];
        }
    }

    /**
     * Pobranie powiązanych systemów
     */
    getWindowLines() {
        return core.copy(this.configuratorsDataService.data.mosquitoWindowLines);
    }

    /**
     * Wybor montazu
     * @param  {object}  montage     Wybrany montage
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    selectMontage(montage, price?) {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }
        this.configurationsService.conf.Current.Montage = montage;
        this.configurationsService.conf.Default.Montage = montage;

        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    setDefaultValues() {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }

        this.montages = this.getMontages();

        if (Common.isDefined(this.montages[0])) {
            this.selectMontage(this.montages[0]);
        } else {
            this.selectMontage(null);
        }

        this.configurationsService.conf.Current.ProfilePositions = {};

        if (Number(this.configurationsService.conf.Current.Type.muntin_position) > 0) {
            this.configurationsService.conf.Current.MuntinType = 'standard';
            this.configurationsService.conf.Current.MuntinPosition = Number(
                this.configurationsService.conf.Current.Type.muntin_position
            );
        } else {
            this.configurationsService.conf.Current.MuntinType = null;
            this.configurationsService.conf.Current.MuntinPosition = null;
        }
        const lines = this.getLines();
        if (
            (Common.isObject(this.configurationsService.conf.Current.WindowLine)
                && Common.isArray(
                    lines
                )
                && lines.length)
            || (this.configurationsService.conf.Current.WindowLine === null
                && this.configurationsService.conf.Current.HoldersWidth != null)
        ) {
            // Do nothing
        } else if (
            !Common.isArray(lines)
            || lines.length === 0
        ) {
            this.setLine(null);
        } else {
            this.setLine(lines[0]);
        }
    }

    /**
     * Ustawia na starcie domyslna wysokosc szprosa
     * @param {string} position Pozycja standardowa
     */
    setDefaultMountinPosition() {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.MuntinPosition = Number(this.configurationsService.conf.Current.Type.muntin_position);
        this.priceService.count();
    }

    /**
     * Dopasuj do linii okiennej
     * @param {object} line Wybrana linia
     */
    setLine(line) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.WindowLine = line;
        this.configurationsService.conf.Current.HoldersWidth = null;
    }

    getLines() {
        return this.windowLines[this.configurationsService.conf.Current.System.id] || [];
    }

    /**
     * Funkcja po wyborze profilu
     */
    selectProfile() {
        this.priceService.count();
    }

    setProfilePositions(profilePositions: {
        top: boolean;
        bottom: boolean;
        left: boolean;
        right: boolean;
    }) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.ProfilePositions = profilePositions;
        this.priceService.count();
    }

    setMuntinType(type: 'standard' | 'custom') {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.MuntinType = type;
        if (type === 'standard') {
            this.setDefaultMountinPosition();
        }
        this.priceService.count();
    }

    setMuntinPosition(position: number) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.MuntinPosition = position;
        this.priceService.count();
    }

    setHoldersWidth(width: number) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.HoldersWidth = width;
        this.priceService.count();
    }

    setSealThickness(thickness: number) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.sealThickness = thickness;
        this.priceService.count();
    }

    setHasThickSeal(hasThickSeal: boolean) {
        if (this.configurationsService.conf.Current.type !== 'mosquito') {
            return;
        }
        this.configurationsService.conf.Current.hasThickSeal = hasThickSeal;

        if (hasThickSeal) {
            this.setSealThickness(10);
        } else {
            this.setSealThickness(null);
        }
        this.priceService.count();
    }
}
