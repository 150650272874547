import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, ConfiguratorOptions } from '@icc/configurator/shared';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { APP_CONFIG, AppConfigFactory, WindowActiveConfiguration } from '@icc/common';
import { PriceService } from '@icc/price';
import { EventBusService } from '@icc/common/event-bus.service';

@Component({
    selector: 'icc-construction',
    template: require('./construction.component.html'),
    styles: [require('./construction.component.scss')],
})
export class ConstructionComponent extends StepComponent implements OnInit {
    static stepName = _('WINDOW|Konstrukcja');
    static stepIcon = {
        ligature: 'build',
    };

    public configurator = 'window';
    public stepId = 'construction';
    public title = _('WINDOW|Konstrukcja');
    public options = [];

    isWood = this.configurationsService.conf.Current.System.type === 'wood';
    isPVC = this.configurationsService.conf.Current.System.type === 'pvc';
    woodType$ = this.windowFacade.woodType$;
    withAlushell =
        this.config().IccConfig.Configurators.alushell
        && this.configurationsService.conf.Current.System.alushell;
    aluShell$ = this.windowFacade.hasAlushell$;
    extendedAlushell = this.config().IccConfig.Configurators.alushellExt;
    alushellFrameColor$ = this.windowFacade.alushellFrameColor$;
    alushellType$ = this.windowFacade.alushellType$;
    alushellActive = this.configurationsService.conf.Current.System.alushell_active;
    alushellButton = this.config().IccConfig.Configurators.alushellButton;
    weldFinishType$ = this.windowFacade.weldFinishType$;
    drainagePosition$ = this.windowFacade.drainagePosition$;

    showOptions =
        this.configurationsService.conf.Current.type !== 'door'
        && this.config().IccConfig.Configurators.window.additionalOptionsInAccessory;

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.System.type === 'wood'
        || (conf.type !== 'door' && options.config.Configurators.window.additionalOptionsInAccessory)
        || (options.config.Configurators.alushell && conf.System.alushell && !conf.System.alushell_active)
        || (conf.HasAlushell && options.config.Configurators.alushellExt);

    constructor(
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private colorsService: ColorsService,
        private windowFacade: WindowFacade,
        private accessoriesService: AccessoriesService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
    }

    openModalWood() {
        this.colorsService.openModalWood(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    placeDowelHole() {
        this.accessoriesService.placeDowelHole('outer', 'inner', 'n');
    }

    changeAlushell(value: boolean) {
        this.configurationsService.conf.Current.HasAlushell = value;
        if (value) {
            this.colorsService.setAlushellColor();
        }
        this.eventBusService.post({
            key: 'changedAlushell',
            value,
        });
    }

    setWeldFinishType(value: 'V' | 'thin') {
        this.configurationsService.conf.Current.weldFinishType = value;
        this.eventBusService.post({
            key: 'changedOptions',
            value,
});

    }

    openModalColor(type: 'inner' | 'outer' | 'alushell', place: 'frame' | 'sash' = 'frame') {
        this.colorsService.openModalColorSimple(
            type,
            place,
            this.configurationsService.conf.Current
        );
    }


    setDrainage(value: 'front' | 'bottom') {
        this.configurationsService.conf.Current.drainagePosition = value;
        this.eventBusService.post({
            key: 'changedOptions',
            value,
        });
    }

    alushellType(value: 'painted' | 'brushed') {
        this.configurationsService.conf.Current.AlushellType = value;
        this.colorsService.setAlushellColor(value);
        this.eventBusService.post({
            key: 'changedAlushell',
            value,
        });
    }

    ngOnInit() {}
}
