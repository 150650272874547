import { StepComponentType } from '@icc/configurator/shared';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { SystemComponent } from './system/system.component';
import { LayoutComponent } from './layout/layout.component';
import { ColorsComponent } from './colors/colors.component';
import { GlazingsComponent } from './glazings/glazings.component';
import { MuntinsComponent } from './muntins/muntins.component';
import { FittingComponent } from './fitting/fitting.component';
import { AccessoriesComponent } from '@icc/configurator/accessory';
import { ConstructionComponent } from './construction/construction.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SystemPacketsComponent } from './system-packets/system-packets.component';

export const windowStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    sashes: LayoutComponent,
    system: SystemComponent,
    packets: SystemPacketsComponent,
    dimensions: DimensionsComponent,
    construction: ConstructionComponent,
    color: ColorsComponent,
    glazing: GlazingsComponent,
    muntins: MuntinsComponent,
    fitting: FittingComponent,
    accessories: AccessoriesComponent,
    details: ProductDetailsComponent
};
