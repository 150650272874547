import { Injectable, Inject } from '@angular/core';
import {
    EventBusService,
    core,
    ConfigurationsService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
} from '@icc/common';
import { ModalService, isNotNullOrUndefined } from '@icc/helpers';
import { FillingColorsPageComponent } from 'libs/configurator/window/src/lib/filling-colors-page/filling-colors-page.component';
import { IccFilling, IccColors, IccColor, IccWood } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ColorsService } from '../colors/colors.service';
import { FillingsService } from './fillings.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
const colorsModalTemplate = require('!raw-loader!../../complementary_goods/colors/modal.html');

@Injectable()
export class FillingsColorsService {
    constructor(
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private colorsService: ColorsService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private fillingsService: FillingsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    /**
     * Otwieranie modala wyboru  koloru panela wypełnieniowegi
     * @param  {Object} accessory Panel wypełnieniowy
     */
    openModalColors(sash, novalidate) {
        const modalInstance = this.modalService.open({
            template: colorsModalTemplate,
            controller: 'ModalPvcColorsCtrl as $ctrl',
            pageComponent: FillingColorsPageComponent,
            resolve: { sash: () => sash, novalidate: () => novalidate },
        });

        modalInstance.result.then(selection => {
            sash.selectedColor = core.copy(selection.colors);
            sash.selectedWood = core.copy(selection.wood);
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'pvcModal',
            });
        }
    }

    /**
     * Otwieranie modala wyboru  koloru panelu deco
     * @param  {Object} conf Przekazana konfiguracja
     * @param  {Object} sashId Id sasha
     * @param  {Object} dualColor Rodzaj wybieranego koloru - true kolor2, false kolor1 panelu
     * @param  {Object} novalidate Czy walidować kolor
     */
    openModalDecoPanelColors(conf, sashId, dualColor, novalidate) {
        const field = dualColor ? 'selectedColorSecond' : 'selectedColor';
        const sash = conf.Sashes.reduce((sashes, s) => sashes.concat([s], s.intSashes), []).find(
            s => s.id === sashId
        );
        const modalInstance = this.modalService.open({
            template: colorsModalTemplate,
            controller: 'ModalDecoPanelColorsCtrl as $ctrl',
            pageComponent: FillingColorsPageComponent,
            resolve: {
                sash: () => sash.glazing,
                dualColor: () => dualColor,
                novalidate: () => novalidate,
            },
        });

        modalInstance.result.then(selection => {
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            sash.glazing[field] = core.copy(selection.colors);
            if (typeof sash.parentId !== 'undefined') {
                conf.Sashes.find(s => s.id === sash.parentId).glazing[field] = core.copy(
                    selection.colors
                );
            }
            sash.selectedWood = core.copy(selection.wood);
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            this.eventBusService.post({
                key: 'reloadColorsData',
                value: null,
            });
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Otwieranie modala wyboru  kolorów wszystkich paneli wypełnieniowych
     * @param  {Object} accessory Panel wypełnieniowy
     */
    selectColor(glazing, novalidate) {
        this.modalService
            .open({
                template: colorsModalTemplate,
                controller: 'ModalPvcColorsCtrl as $ctrl',
                pageComponent: FillingColorsPageComponent,
                resolve: { sash: () => glazing, novalidate: () => novalidate },
            })
            .result.then(selection => {
                this.configurationsService.conf.Current.Sashes.forEach(sash => {
                    sash.glazing.selectedColor = core.copy(selection.colors);
                    sash.glazing.selectedWood = core.copy(selection.wood);
                    sash.intSashes.forEach(intSash => {
                        intSash.glazing.selectedColor = core.copy(selection.colors);
                        intSash.glazing.selectedWood = core.copy(selection.wood);
                    });
                });
                this.eventBusService.post({
                    key: 'changedSashes',
                    value: {},
                });
            });
    }

    setPanelColor(filling: ActiveSash['glazing'], colors: IccColors) {
        filling.selectedColor = core.copy(colors);
    }

    /**
     * Ustawia kolor w danym w miejscu konstrukcji.
     * @memberof ColorsService
     * @param {string}     colorType Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place     Miejsce - skrzydło, rama
     * @param {object}     color     Kolor
     * @param {string|int} type      Grupa koloru
     * @param {bool}       refresh   Czy odświeżać ceny, rysunek, itp.
     */
    setColor(
        colorType: 'outer' | 'inner',
        place: 'first' | 'other' = 'first',
        color: IccColor | 'none',
        type: number | 'none' | undefined,
        refresh: boolean,
        filling: any
    ) {
        let object = filling.selectedColor;
        if (place === 'other') {
            object = filling.selectedColorSecond;
        }
        if (color === 'none') {
            color = {} as IccColor;
        }
        this.setColorSide(object.frame[colorType], color, type);
        this.setColorSide(object.sash[colorType], color, type);
        this.eventBusService.post({
            key: 'setFillingColor',
            value: {},
        });
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    setColorSide(
        object: Partial<IccColor>,
        color: Partial<IccColor> | undefined,
        type: number | 'none' | undefined | string,
        isDefault = false
    ) {
        if (isNotNullOrUndefined(color)) {
            object.id = color.id;
            object.alfa = color.alfa;
            object.color = color.color;
            object.color_img = color.color_img;
            object.name = color.name;
            object.code = color.code;
            object.RAL = color.RAL;
            object.type = color.type;
            object.types = color.types;
            object.groups = color.groups;
            object.sides = color.sides;
            object.isDefault = isDefault;
            object.group = String(type);
        }
    }

    openModalColor(
        type: 'outer' | 'inner',
        place: 'first' | 'other' = 'first',
        sash: 'door' | 'doorActive' | 'doorActiveInner' | 'doorPassive',
        conf = this.configurationsService.conf.Current
    ) {
        const sashes = conf.Sashes.filter(
            this.fillingsService.getFilterForField(sash, conf)
        );
        if (sashes.length > 0 && sashes[0] && sashes[0].glazing) {
            let selectedColor = sashes[0].glazing.selectedColor;
            if (place === 'other') {
                selectedColor = sashes[0].glazing.selectedColorSecond;
            }

            const hasInnerPanel = conf.type === 'door'
                                    && conf.OwnedSashesTypes.doorActive
                                    && sashes[0].glazing.type === 'door_panels'
                                    && sashes[0].panelInner;

            if (hasInnerPanel && type === 'inner') {
                selectedColor = sashes[0].panelInner.selectedColor;
                if (place === 'other') {
                    selectedColor = sashes[0].panelInner.selectedColorSecond;
                }
            }
            this.colorsService.openModalColorSimple(
                type,
                'sash',
                conf,
                group => {
                    const sideField = (hasInnerPanel && type === 'inner') ? 'panelInner' : 'glazing';
                    let colorGroups = sashes[0][sideField].color_groups_ids;
                    if (place === 'other') {
                        colorGroups = sashes[0][sideField].part_color_groups_ids;
                    }
                    return group != null
                        && typeof colorGroups !== 'undefined'
                        && colorGroups.map(Number).indexOf(Number(group.id))
                            > -1;
                },
                true,
                false,
                (color: IccColor) => {
                    const pauseId = this.eventBusService.pause(['setFillingColor']);
                    this.setFillingColorInAllSashes(sashes, type, place, color, hasInnerPanel, conf);

                    if (sash === 'doorActive') {
                        const passiveSashes = conf.Sashes.filter(
                            this.fillingsService.getFilterForField('doorPassive', conf)
                        );
                        this.setFillingColorInAllSashes(passiveSashes, type, place, color, hasInnerPanel, conf);
                    }
                    this.eventBusService.resume(['setFillingColor'], pauseId);
                },
                selectedColor && selectedColor.frame[type]
            );
        }
    }

    private setFillingColorInAllSashes(
        sashes: ActiveSash[],
        type: 'outer' | 'inner',
        place: 'first' | 'other',
        color: IccColor,
        hasInnerPanel: Partial<IccFilling> & {
            selectedColor?: IccColors;
            selectedColorSecond?: IccColors;
            selectedWood?: IccWood;
        },
        conf: DoorActiveConfiguration | WindowActiveConfiguration
    ) {
        sashes.forEach(sash => {
            this.setColor(type, place, color, 'none', false, sash[type === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing']);

            sash.intSashes.forEach(field => {
                this.setColor(type, place, color, 'none', false, field[type === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing']);
            });
        });

        if (type === 'outer'
            && ['Bilateral', 'Transparent', 'Opaque', '3D'].indexOf(conf.ColorType) > -1) {
            sashes.forEach(sash => {
                this.setColor('inner', place, color, 'none', false, sash[hasInnerPanel ? 'panelInner' : 'glazing']);

                sash.intSashes.forEach(field => {
                    this.setColor('inner', place, color, 'none', false, field[hasInnerPanel ? 'panelInner' : 'glazing']);
                });
            });
        }
    }
}
