import * as angular from 'angular';
import { StepsService } from '@icc/helpers';

export interface ChangedStepValue {
    prevStep: {
        i: number;
        code: string;
    };
    nextStep: {
        i: number;
        code: string;
    };
}

export interface StartedChangingStepValue {
    prevStep: {
        i: number;
        code: string;
    };
    nextStep: {
        i: number;
        code: string;
    };
    cancel: () => void;
}

export class StepFactory extends StepsService {
    steps = [];

    names = {
        window: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            sashes: this.$filter('translate')('STEPS|Układ'),
            color: this.$filter('translate')('STEPS|Kolor'),
            glazing: this.$filter('translate')('STEPS|Szyby'),
            fitting: this.$filter('translate')('STEPS|Okucia i klamki'),
            rollershutter: this.$filter('translate')('STEPS|Roleta'),
            mosquito: this.$filter('translate')('STEPS|Siatki'),
            accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
            muntins: this.$filter('translate')('WINDOW|Szprosy'),
        },
        folding_door: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            sashes: this.$filter('translate')('STEPS|Układ'),
            color: this.$filter('translate')('STEPS|Kolor'),
            glazing: this.$filter('translate')('STEPS|Szyby'),
            fitting: this.$filter('translate')('STEPS|Okucia i klamki'),
            rollershutter: this.$filter('translate')('STEPS|Roleta'),
            accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
        },
        hs: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            sashes: this.$filter('translate')('STEPS|Układ'),
            color: this.$filter('translate')('STEPS|Kolor'),
            glazing: this.$filter('translate')('STEPS|Szyby'),
            fitting: this.$filter('translate')('STEPS|Okucia i klamki'),
            rollershutter: this.$filter('translate')('STEPS|Roleta'),
            mosquito: this.$filter('translate')('STEPS|Siatki'),
            accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
        },
        sliding_door: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            sashes: this.$filter('translate')('STEPS|Układ'),
            color: this.$filter('translate')('STEPS|Kolor'),
            glazing: this.$filter('translate')('STEPS|Szyby'),
            fitting: this.$filter('translate')('STEPS|Okucia i klamki'),
            rollershutter: this.$filter('translate')('STEPS|Roleta'),
            mosquito: this.$filter('translate')('STEPS|Siatki'),
            accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
        },
        door: {
            1: {
                system: this.$filter('translate')('STEPS|Przeznaczenie'),
                models: this.$filter('translate')('STEPS|Model'),
                sashes: this.$filter('translate')('STEPS|Układ'),
                dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
                color: this.$filter('translate')('STEPS|Kolor'),
                fills: this.$filter('translate')('STEPS|Wypełnienie'),
                glazing: this.$filter('translate')('STEPS|Wypełnienie'),
                accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
            },
            2: {
                system: this.$filter('translate')('STEPS|Linia'),
                dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
                sashes: this.$filter('translate')('STEPS|Układ'),
                color: this.$filter('translate')('STEPS|Kolor'),
                fillings: this.$filter('translate')('STEPS|Wypełnienie'),
                fitting: this.$filter('translate')('STEPS|Klamki, antaby'),
                locks: this.$filter('translate')('STEPS|Zamki'),
                accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
            },
        },
        roller_shutter: {
            rollertype: this.$filter('translate')('STEPS|Rodzaj rolety'),
            rollerschema: this.$filter('translate')('STEPS|Schemat'),
            rollerdimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            rollercolor: this.$filter('translate')('STEPS|Kolor'),
            rollerdrive: this.$filter('translate')('STEPS|Napęd'),
            accessories: this.$filter('translate')('STEPS|Akcesoria'),
        },
        garage_door: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            fills: this.$filter('translate')('STEPS|Panel'),
            colors: this.$filter('translate')('STEPS|Kolor'),
            accessories: this.$filter('translate')('STEPS|Dodatki i akcesoria'),
        },
        awning: {
            systems: this.$filter('translate')('STEPS|Rodzaj markizy'),
            dimensions: this.$filter('translate')('DIMENSION|Wymiary'),
            colors: this.$filter('translate')('STEPS|Kolor konstrukcji'),
            sheathing: this.$filter('translate')('STEPS|Poszycie'),
            frill: this.$filter('translate')('STEPS|Falbana'),
            drive: this.$filter('translate')('STEPS|Napęd'),
            assembly: this.$filter('translate')('STEPS|Sposób montażu'),
            accessory: this.$filter('translate')('ACCESSORY|Akcesoria'),
        },
        mosquito: {
            system: this.$filter('translate')('STEPS|Linia'),
            dimensions: this.$filter('translate')('DIMENSION|Rozmiar'),
            types: this.$filter('translate')('STEPS|Typ'),
            colors: this.$filter('translate')('STEPS|Kolor'),
            montages: this.$filter('translate')('STEPS|System montażu'),
        },
        complementary_goods: {
            windowsill: this.$filter('translate')('STEPS|Parapety'),
            cassonetto: this.$filter('translate')('STEPS|Kasonetki'),
            glass: this.$filter('translate')('STEPS|Szyby'),
            accessory: this.$filter('translate')('ACCESSORY|Akcesoria'),
        },
        winter_garden: {
            type: this.$filter('translate')('STEPS|Rodzaj'),
            dimensions: this.$filter('translate')('DIMENSION|Wymiary'),
        },
        accessory: { accessories: this.$filter('translate')('ACCESSORY|Akcesoria') },
        coupled_window: { canvas: this.$filter('translate')('WINDOW|Konstrukcja') },
    };

    private user = this.UserService.get();

    constructor(
        private $rootScope,
        private $filter,
        private Core,
        private IccConfig,
        private ConfigurationsService,
        private UserService,
        private EventBusService
    ) {
        'ngInject';
        super();
        this.setSteps();
        this.EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.setSteps();
        });
    }
    setSteps(conf = this.ConfigurationsService.conf) {
        if (angular.isUndefined(conf) || angular.isUndefined(conf.conf)) {
            return;
        }

        const version = this.IccConfig.Configurators[conf.conf].version || 1;

        let stepsNames = this.names[conf.conf];
        if (angular.isObject(this.names[conf.conf][version])) {
            stepsNames = this.names[conf.conf][version];
        }

        let stepsConfig = this.IccConfig.Configurators[conf.conf].steps;
        if (angular.isArray(stepsConfig[version])) {
            stepsConfig = stepsConfig[version];
        }
        this.Core.clear(this.steps);
        for (let i = 0; i < stepsConfig.length; i++) {
            const step = stepsConfig[i];
            if (angular.isString(step) && step.length > 0) {
                this.steps.push({
                    code: step,
                    step: i + 1,
                    name: stepsNames[step],
                    visits: 0,
                });
            } else if (angular.isObject(step)) {
                this.steps.push(
                    angular.extend({}, step, {
                        step: i + 1,
                        name: stepsNames[step.code],
                        visits: 0,
                    })
                );
            }
        }
        if (angular.isUndefined(conf.step) || angular.isUndefined(conf.stepCode)) {
            this.selectStep(1, true);
        }
    }

    getSteps() {
        if (!angular.isArray(this.steps) || this.steps.length === 0) {
            this.setSteps();
        }
        return this.steps;
    }

    hasStep(code) {
        return this.getStepByCode(code) > -1;
    }

    /**
     * Zwraca indeks kroku na podstawie jego kodu.
     * @param  {string} code Kod kroku.
     * @return {number} Indeks kroku.
     */
    getStepByCode(code) {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].code === code) {
                return this.steps[i].step;
            }
        }
        return -1;
    }

    /**
     * Ustawia wybrany krok, na podstwie jego numeru lub nazwy.
     * @param  {number} newStep Indeks kroku lub jego nazwa.
     * @param  {bool} noEvent Jeśli true funkcja nie wywołuje zdarzenia changedStep.
     */
    selectStep(newStep, noEvent = false) {
        let stepNumber;
        if (angular.isNumber(newStep)) {
            stepNumber = newStep;
        } else {
            stepNumber = this.getStepByCode(newStep);
        }
        if (stepNumber < 1 || stepNumber > this.steps.length) {
            return;
        }
        if (
            angular.isDefined(this.steps[stepNumber - 1].disabledmarkets)
            && angular.isArray(this.steps[stepNumber - 1].disabledmarkets)
            && this.steps[stepNumber - 1].disabledmarkets.indexOf(this.user.market) > -1
        ) {
            if (stepNumber + 1 > this.steps.length) {
                return;
            }
            stepNumber++;
            this.selectStep(stepNumber, noEvent);
            return;
        }

        if (
            angular.isDefined(this.steps[stepNumber - 1].disabled)
            && this.steps[stepNumber - 1].disabled
        ) {
            if (stepNumber + 1 > this.steps.length) {
                return;
            }
            stepNumber++;
            this.selectStep(stepNumber, noEvent);
            return;
        }

        const oldStep = this.getStep();
        const oldStepCode = this.getCode();
        this.ConfigurationsService.conf.stepCode = this.steps[stepNumber - 1].code;
        this.ConfigurationsService.conf.step = stepNumber;

        this.steps[stepNumber - 1].visits++;
        if (!noEvent) {
            this.EventBusService.post({
                key: 'changedStep',
                value: {
                    prevStep: {
                        i: oldStep,
                        code: oldStepCode,
                    },
                    nextStep: {
                        i: stepNumber,
                        code: this.ConfigurationsService.conf.stepCode,
                    },
                },
            });
            this.$rootScope.$broadcast(
                'changedStep',
                {
                    i: stepNumber,
                    code: this.ConfigurationsService.conf.stepCode,
                },
                {
                    i: oldStep,
                    code: oldStepCode,
                }
            );
        }
    }

    goToNextStep(validation) {
        if (validation) {
            this.selectStep(this.ConfigurationsService.conf.step + 1);
        }
    }

    setDefaultStep(noEvent = true) {
        const version = this.IccConfig.Configurators[this.ConfigurationsService.conf.conf].version || 1;

        let defaultStep = this.IccConfig.Configurators[this.ConfigurationsService.conf.conf].defaultStep;
        if (angular.isObject(defaultStep)) {
            defaultStep = defaultStep[version];
        }

        this.selectStep(defaultStep, noEvent);
    }

    getStep() {
        return this.ConfigurationsService.conf.step || 1;
    }

    getCode() {
        let code = this.ConfigurationsService.conf.stepCode;
        if (angular.isUndefined(code) && angular.isDefined(this.steps[0])) {
            code = this.steps[0].code;
        }
        return code;
    }

    enable(step) {
        let stepNumber;
        if (angular.isNumber(step)) {
            stepNumber = step;
        } else {
            stepNumber = this.getStepByCode(step);
        }
        if (stepNumber < 1 || angular.isUndefined(this.steps[stepNumber - 1])) {
            return;
        }
        this.steps[stepNumber - 1].disabled = false;
    }

    disable(step) {
        let stepNumber;
        if (angular.isNumber(step)) {
            stepNumber = step;
        } else {
            stepNumber = this.getStepByCode(step);
        }
        if (stepNumber < 1 || angular.isUndefined(this.steps[stepNumber - 1])) {
            return;
        }
        this.steps[stepNumber - 1].disabled = true;
    }

    switchEnable(step) {
        let stepNumber;
        if (angular.isNumber(step)) {
            stepNumber = step;
        } else {
            stepNumber = this.getStepByCode(step);
        }
        if (stepNumber < 1 || angular.isUndefined(this.steps[stepNumber - 1])) {
            return;
        }
        this.steps[stepNumber - 1].disabled = !this.steps[stepNumber - 1].disabled;
    }

    disableAllSteps() {
        for (let i = 0; i < this.steps.length; i++) {
            this.steps[i].disabled = true;
        }
    }

    enableAllSteps() {
        for (let i = 0; i < this.steps.length; i++) {
            this.steps[i].disabled = false;
        }
    }

    getLastStep() {
        let lastStep;
        if (this.steps.length > 0) {
            lastStep = this.steps[this.steps.length - 1];
        }
        for (let i = this.steps.length - 1; i >= 1; i--) {
            if (!this.steps[i].disabled) {
                lastStep = this.steps[i];
                break;
            }
        }
        return lastStep;
    }

    isVisited(step) {
        let stepNumber;
        if (angular.isNumber(step)) {
            stepNumber = step;
        } else {
            stepNumber = this.getStepByCode(step);
        }
        if (stepNumber < 1 || angular.isUndefined(this.steps[stepNumber - 1])) {
            return false;
        }
        return this.steps[stepNumber - 1].visits > 0;
    }

    getStepVisits(step) {
        let stepNumber;
        if (angular.isNumber(step)) {
            stepNumber = step;
        } else {
            stepNumber = this.getStepByCode(step);
        }
        if (stepNumber < 1 || angular.isUndefined(this.steps[stepNumber - 1])) {
            return 0;
        }
        return this.steps[stepNumber - 1].visits;
    }
}
