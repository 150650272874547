import { Injectable } from '@angular/core';
import { IccColor } from '@icc/common/data-types';
import { EventBusService } from '@icc/common/event-bus.service';
import { IssuesService } from '@icc/configurator/shared';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common, IssueLevel, ValidationService, core, TranslateService } from '@icc/common';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { Side } from '@icc/common/data-types/ColorGroup';

@Injectable({
    providedIn: 'root',
})
export class LippingColorsService {

    constructor(
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private colorsService: ColorsService,
        private validationService: ValidationService,
        private colorMappingService: ColorMappingService,
        private translateService: TranslateService
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {});

        eventBusService.subscribe(['setLowLipping', 'unsetLowLipping'], data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateColorsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    'lippingColor'
                );
            }
        });

        eventBusService.subscribe('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration) &&
                WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.setMatchingColorsIfDefault(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    'lippingColor'
                );
            }
        });

        eventBusService.subscribe('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration) &&
                WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.setMatchingColorsIfDefault(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    'innerLippingColor'
                );
            }
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setDefaultColors', (data) => {
            this.validateColorsAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration,
                data.defaultConfiguration as WindowActiveConfiguration,
                'lippingColor'
            );
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setDefaultColors', (data) => {
            this.validateColorsAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration,
                data.defaultConfiguration as WindowActiveConfiguration,
                'innerLippingColor'
            );
        });
    }

    getLippingColors(conf: WindowActiveConfiguration) {
        return this.colorsService.getSimpleColors(conf, [Side.FO]).colors;
    }

    getInnerLippingColors(conf: WindowActiveConfiguration) {
        return this.colorsService.getSimpleColors(conf, [Side.FI]).colors;
    }

    getLippingColorGroups(conf: WindowActiveConfiguration) {
        return this.colorsService.getColorsGroups(null, this.colorsService.windowColors, conf);
    }

    getDefaultColor(conf: WindowActiveConfiguration) {
        if (!conf.System.available_lipping_color) {
            return null;
        }
        const availColors = this.getLippingColors(conf);
        const windowColorId = this.colorMappingService.getWindowColorId(conf, 'outer');
        const matchedColors = this.colorMappingService.getColors(windowColorId, 'window', 'window');
        const windowColors = matchedColors
            .map(m => availColors.filter(c => Number(c.id) === Number(m))[0])
            .filter(m => m);
        let color;
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else if (Common.isArray(availColors) && Common.isDefined(availColors[0])) {
            color = core.copy(availColors[0]);
        } else {
            return null;
        }
        color.isDefault = true;
        return color;
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf: any) {
        const color = this.getDefaultColor(conf);
        if (color) {
            this.setColor(color, conf, defaultConf);
        } else {
            this.setColor(null, conf, defaultConf);
        }
    }

    setColor(
        color: IccColor | null,
        conf: WindowActiveConfiguration,
        defaultConf: any,
        type?: 'outer' | 'inner'
    ) {
        if (type === 'outer' || !type) {
            conf.lippingColor = color ? { ...color } : null;
            conf.innerLippingColor = color ? { ...color } : null;
        }
        if (type === 'inner' || !type) {
            conf.innerLippingColor = color ? { ...color } : null;
        }

        this.eventBusService.post({
            key: 'setLippingColor',
            value: {
                colorId: color && color.id,
            },
        });
    }

    validColor(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        if (
            sideColor === 'lippingColor' &&
            !conf.System.available_lipping_color &&
            conf.lippingColor &&
            conf.lippingColor.id
        ) {
            return false;
        } else if (
            sideColor === 'innerLippingColor' &&
            !conf.System.available_lipping_in_bicolor &&
            conf.innerLippingColor &&
            conf.innerLippingColor.id
        ) {
            return false;
        }

        if (sideColor === 'lippingColor') {
            const availColors = this.getLippingColors(conf);
            const colorAvailable = availColors.some(
                (el) =>
                    conf.lippingColor &&
                    el.id === conf.lippingColor.id &&
                    el.RAL === conf.lippingColor.RAL
            );
            return colorAvailable;
        } else if (sideColor === 'innerLippingColor') {
            const availColors = this.getInnerLippingColors(conf);
            const colorAvailable = availColors.some(
                (el) =>
                    conf.innerLippingColor &&
                    el.id === conf.innerLippingColor.id &&
                    el.RAL === conf.innerLippingColor.RAL
            );
            return colorAvailable;
        }
    }

    setMatchingColorsIfDefault(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        const pauseId = this.eventBusService.pause(['setLippingColor']);

        if (
            (sideColor === 'lippingColor' && conf.lippingColor && conf.lippingColor.isDefault) ||
            (sideColor === 'innerLippingColor' && conf.innerLippingColor && conf.innerLippingColor?.isDefault)
        ) {
            this.setDefaultColor(conf, defaultConf);
        }

        this.eventBusService.resume(['setLippingColor'], pauseId);
    }

    validateColors(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        this.validationService.indeterminate(conf, sideColor);
        if (this.validationService.isValidElements(conf, ['system'])) {
            const valid = this.validColor(conf, defaultConf, sideColor);
            if (!valid) {
                this.validationService.invalid(conf, sideColor);
                this.issuesService.simpleRegister(
                    'invalid-lippings-colors',
                    'Niepoprawne kolory progów',
                    this.translateService.instant('WINDOW|Niepoprawne kolory progów'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.NONE,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, sideColor));
                this.issuesService.unregister('invalid-lippings-colors', conf);
            }
        }
    }

    validateColorsAndFixIssues(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        this.validateColorAndFixIssue(conf, defaultConf, sideColor);
    }

    validateColorAndFixIssue(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor',
    ) {
        this.validationService.indeterminate(conf, sideColor);
        if (this.validationService.isValidElements(conf, ['system'])) {
            const pauseId = this.eventBusService.pause(['setLippingColor']);
            try {
                if (!this.validColor(conf, defaultConf, sideColor)) {
                    this.validationService.invalid(conf, sideColor);
                    this.setDefaultColor(conf, defaultConf);
                } else {
                    Object.assign(conf, this.validationService.valid(conf, sideColor));
                }
            } finally {
                this.eventBusService.resume(['setLippingColor'], pauseId);
            }
        }
    }

    openModal(type: 'outer' | 'inner', conf: WindowActiveConfiguration, defaultConf: any) {
        return this.colorsService.openModalColorSimple(
            type,
            'frame',
            conf,
            undefined,
            true,
            false,
            (color: IccColor) => {
                this.setColor(color, conf, defaultConf, type);
            },
            type === 'outer' ? conf.lippingColor : conf.innerLippingColor
        );
    }
}
