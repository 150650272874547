import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { PriceBaseService } from './price-base.service';
import {Injectable} from '@angular/core';
import { Common } from '@icc/common/Common';

@Injectable()
export class PriceCassonettoService {
    constructor() {}

    /**
     * Wylicza cenę kasonetek
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'cassonetto',
        data: {
            cassonettos  : 'conf.ComplementaryGoods.cassonetto',
            colors: 'data.windowColorsAll',
            pricelists: 'data.productPricelists'
        }
    })
    cassonettoPrice({}: PriceElemsData, {cassonettos, colors, pricelists}: {cassonettos, colors, pricelists}): PriceSegment[] {
        let priceSegments: PriceSegment[] = [];
        for (const i in cassonettos) {
            const cassonettoPrice = this.cassonettoCalculatePrice(cassonettos[i], colors, pricelists);
            priceSegments.push(cassonettoPrice);
        }
        return priceSegments;
    }


    cassonettoCalculatePrice(cassonetto, colors, pricelists) {

        const noPrice = <PriceSegment>{
            baseValue: null,
            value: null,
            type: 'complementaryGoods',
            valueType: 'value',
            data: {}
        };

        if (!cassonetto.accessory) {
            return noPrice;
        }
        let price;
        let pricelistId;

        const selectedColor = colors.find(e => e.window_color_id === cassonetto.accessory.selectedColor);
        if (typeof (selectedColor) === 'undefined') {
            cassonetto.accessory.price = price;
            return noPrice;
        }

        for (const a in cassonetto.accessory.pricelists) {
            if (selectedColor.groups.find(groupId => cassonetto.accessory.pricelists[a].window_color_group_id === groupId)) {
                pricelistId = cassonetto.accessory.pricelists[a].product_pricelist_id;
                break;
            }
        }

        // pricelists - znajdź cennik wg ID
        if (!pricelistId || !pricelists.productPricelists[pricelistId]) {
            cassonetto.accessory.price = price;
            return noPrice;
        }

        // szerokość i wysokość kasonetki - znajdź w rastrze
        const priceNode = pricelists.productPricelists[pricelistId].data.find(node => {
            return Number(node.height_from) <= Number(cassonetto.accessory.currentHeight) && Number(node.height_to) >= Number(cassonetto.accessory.currentHeight)
                      && Number(node.width_from) <= Number(cassonetto.accessory.currentWidth) && Number(node.width_to) >= Number(cassonetto.accessory.currentWidth);
            });
        if (!priceNode) {
            // jeśli poza wymiarem, to do wyceny
            cassonetto.accessory.price = price;
            return noPrice;
        }

        // czy pozostałe parametry mieszczą się w zakresie wg ustawień?
        if (cassonetto.accessory.show_p1 && (
                typeof(cassonetto.accessory.currentP1) === 'undefined' ||
                (cassonetto.accessory.min_p1_mm !== null && Number(cassonetto.accessory.currentP1) < Number(cassonetto.accessory.min_p1_mm)) ||
                (cassonetto.accessory.max_p1_mm !== null && Number(cassonetto.accessory.currentP1) > Number(cassonetto.accessory.max_p1_mm)))) {
            cassonetto.accessory.price = price;
            return noPrice;
        }

        if (cassonetto.accessory.show_p2 && (
                typeof(cassonetto.accessory.currentP2) === 'undefined' ||
                (cassonetto.accessory.min_p2_mm !== null && Number(cassonetto.accessory.currentP2) < Number(cassonetto.accessory.min_p2_mm)) ||
                (cassonetto.accessory.max_p2_mm !== null && Number(cassonetto.accessory.currentP2) > Number(cassonetto.accessory.max_p2_mm)))) {
            cassonetto.accessory.price = price;
            return noPrice;
        }

        if (cassonetto.accessory.show_l1 && (
                typeof(cassonetto.accessory.currentL1) === 'undefined' ||
                (cassonetto.accessory.min_l1_mm !== null && Number(cassonetto.accessory.currentL1) < Number(cassonetto.accessory.min_l1_mm)) ||
                (cassonetto.accessory.max_l1_mm !== null && Number(cassonetto.accessory.currentL1) > Number(cassonetto.accessory.max_l1_mm)))) {
            cassonetto.accessory.price = price;
            return noPrice;
        }

        // podaj cenę
        cassonetto.accessory.price = priceNode.price;

        const cassonettoPrice = <PriceSegment>{
            type: 'complementaryGoods',
            baseValue: priceNode.price * cassonetto.count,
            value: priceNode.price * cassonetto.count,
            valueType: 'value',
            data: {
                id   : cassonetto.accessory.id,
                good : 'cassonetto',
                name : cassonetto.accessory.name,
                count: cassonetto.count,
                amount       : cassonetto.amount,
                color        : Number(cassonetto.accessory.selectedColor),
                currentWidth : cassonetto.accessory.currentWidth,
                currentHeight: cassonetto.accessory.currentHeight,
                l1   : cassonetto.accessory.sel_l1,
                p1   : cassonetto.accessory.sel_p1,
                p2   : cassonetto.accessory.sel_p2,
            },
        };

        return cassonettoPrice;
    }

}
