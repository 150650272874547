import { PriceFunc, PriceElemsData, PriceSegment, price } from './Prices';
import { Injectable, Inject } from '@angular/core';
import { Coupling } from '@icc/window';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { Common } from '@icc/common/Common';


@Injectable()
export class PriceCoupledWindowService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private profilesPriceService: ProfilesPriceService
    ) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru uszczelki.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'coupledWindow',
        data: {
            windows: 'conf.windows',
            rollerShutters: 'conf.rollerShutters',
        },
    })
    suppCoupledWindow(
        {  }: PriceElemsData,
        { windows, rollerShutters }: { windows; rollerShutters }
    ): PriceSegment[] {
        const priceStack: PriceSegment[] = [];

        for (const window of windows) {
            priceStack.push({
                type: 'coupledWindow',
                baseValue: window.details.price,
                value: window.details.price,
                valueType: 'value',
                data: {
                    positionId: window.positionId,
                    priceStack: window.details.priceSegments,
                },
            });
        }

        for (const rollerShutter of rollerShutters) {
            priceStack.push({
                type: 'coupledWindow',
                baseValue: rollerShutter.details.price,
                value: rollerShutter.details.price,
                valueType: 'value',
                data: {
                    positionId: rollerShutter.positionId,
                    priceStack: rollerShutter.details.priceSegments,
                },
            });
        }

        return priceStack;
    }

    /**
     * Dolicza doplatę za łączniki
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {object} conf        Konstrukcja
     * @param  {object} prices      Ceny slupkow
     * @param  {object} colors      Kolory
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'coupled-couplings',
        data: {
            drawData: 'conf.drawData',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            couplings: 'conf.couplings',
            reinforcements: 'data.reinforcements',
            alushells: 'data.alushells',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppCouplings(
        {  }: PriceElemsData,
        {
            drawData,
            prices,
            profiles,
            couplings,
            reinforcements,
            alushells,
            alushellPrices,
            customPrice,
        }: {
            drawData: any;
            prices;
            profiles;
            couplings: Coupling[];
            reinforcements;
            alushells;
            alushellPrices;
            customPrice;
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];

        if (couplings.length) {
            couplings.forEach((coupling, index) => {
                const couplingData = drawData.coupling.find(o => o.couplingId === coupling.id);
                const couplingProfile = profiles.find(o => o.id === coupling.profileId);
                const profilePrice = this.profilesPriceService.getProfilePrice(
                    coupling.profileId,
                    'coupling',
                    null,
                    {
                        frame: coupling.color,
                        sash: coupling.color,
                    },
                    prices,
                    customPrice
                );
                const reinforcement = reinforcements
                    ? reinforcements.find(
                          o =>
                              coupling.reinforcement
                              && Number(o.id) === Number(coupling.reinforcement.id)
                      )
                    : null;

                let length = coupling.length / 1000;
                if (
                    couplingData
                    && this.config().IccConfig.Configurators.price.mullionsLength === 'real'
                ) {
                    const couplingLength = Number(couplingData.length) || 0;
                    length = couplingLength / 1000;
                }

                if (profilePrice) {
                    const pricePiece = parseFloat(profilePrice.price_piece);
                    const priceLength = parseFloat(profilePrice.price_length);

                    priceSegments.push({
                        type: 'couplings',
                        baseValue: price(pricePiece),
                        value: price(pricePiece),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'couplings',
                        baseValue: price(priceLength, length),
                        value: price(priceLength, length),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                    if (reinforcement) {
                        let reinforcementLength = length * 1000;
                        if (couplingProfile) {
                            reinforcementLength =
                                length * 1000
                                - couplingProfile.spaceReinforcement * 2
                                - reinforcement.length_correction * 2;
                        }
                        if (reinforcement.length_step) {
                            reinforcementLength =
                                Math.floor(reinforcementLength / reinforcement.length_step)
                                * reinforcement.length_step;
                        }
                        priceSegments.push({
                            type: 'couplings',
                            baseValue: price(reinforcement.price, reinforcementLength / 1000),
                            value: price(reinforcement.price, reinforcementLength / 1000),
                            valueType: 'value',
                            data: {
                                profileId: coupling.profileId,
                                couplingId: coupling.id,
                                priceLevelId: couplingProfile.priceLevelId,
                                length: reinforcementLength / 1000,
                            },
                        });
                    }
                }

                if (!profilePrice) {
                    priceSegments.push({
                        type: 'couplings',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                }
            });
        }

        return priceSegments;
    }

    @PriceFunc({
        shortName: 'coupled-sideProfiles',
        data: {
            drawData: 'conf.drawData',
            sideProfiles: 'conf.sideProfiles',
            prices: 'data.profilesPrices',
            allProfiles: 'data.profiles',
            customPrice: 'price.Profile',
        },
    })
    suppSideProfiles(
        { PriceElems, NoPriceCauses }: PriceElemsData,
        { drawData, sideProfiles, prices, allProfiles, customPrice }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (Common.isObject(prices) && Common.isArray(sideProfiles)) {
            sideProfiles.forEach((sideProfile, index) => {
                const profileColors = sideProfile.color
                    ? {
                          sash: sideProfile.color,
                          frame: sideProfile.color,
                      }
                    : null;
                const profileDimensions = drawData.extension.find(
                    o => o.extensionId === sideProfile.id
                );
                const profile = allProfiles.find(o => o.id === sideProfile.profileId);
                const profilePrice = this.profilesPriceService.getProfilePrice(
                    sideProfile.profileId,
                    profile ? profile.type : null,
                    null,
                    profileColors,
                    prices,
                    customPrice
                );
                if (profilePrice) {
                    priceSegments.push({
                        type: 'sideProfile',
                        baseValue: price(profilePrice.price_piece),
                        value: price(profilePrice.price_piece),
                        valueType: 'value',
                        data: {
                            profileId: sideProfile.profileId,
                            priceLevelId: profile.priceLevelId,
                            id: sideProfile.id,
                        },
                    });
                    if (profileDimensions) {
                        priceSegments.push({
                            type: 'sideProfile',
                            baseValue:
                                (price(profilePrice.price_length) * profileDimensions.length)
                                / 1000,
                            value:
                                (price(profilePrice.price_length) * profileDimensions.length)
                                / 1000,
                            valueType: 'value',
                            data: {
                                profileId: sideProfile.profileId,
                                priceLevelId: profile.priceLevelId,
                                id: sideProfile.id,
                            },
                        });
                        priceSegments.push({
                            type: 'sideProfile',
                            baseValue: price(profilePrice.price_area) * profileDimensions.polyArea,
                            value: price(profilePrice.price_area) * profileDimensions.polyArea,
                            valueType: 'value',
                            data: {
                                profileId: sideProfile.profileId,
                                priceLevelId: profile.priceLevelId,
                                id: sideProfile.id,
                            },
                        });
                    }
                } else {
                    priceSegments.push({
                        type: 'sideProfile',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {},
                    });
                    NoPriceCauses.push('no prices for sideProfile');
                }
            });
        } else {
            priceSegments.push({
                type: 'sideProfile',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
            NoPriceCauses.push('no prices for sideProfile');
        }
        return priceSegments;
    }
}
