import { IDocument } from './IDatabase';
import { PouchDBNew, clearIDB, PouchDBConfiguration } from './IDB';
import { ISimpleDatabase } from './ISimpleDatabase';

export class IccSimpleDatabase implements ISimpleDatabase {
    private DB: PouchDB.Database<{}> | null = null;
    private options: PouchDBConfiguration = {};

    name: string;
    private user: any;
    type: 'simple' = 'simple';
    private mainRecordId = '1';

    constructor({ name, user }: { name: string; user: any }, options?: PouchDBConfiguration) {
        this.name = name;
        this.user = user;
        if (options) {
            this.options = options;
        }
    }

    init() {
        this.DB = PouchDBNew<any>(this.name, this.user, this.options);
    }

    async refreshIndexes() {}

    async get<T extends any = any>(): Promise<T> {
        let document: any = {};
        if (this.DB) {
            try {
                document = await this.DB.get(this.mainRecordId);
            } catch (err) {}
        }
        return document;
    }

    async put<T extends IDocument>(document: T, { internalRev }: { internalRev?: string } = {}): Promise<T> {
        if (this.DB) {
            await this.appendAdditionalProperties(document, { internalRev });
            await this.DB.put(document);
        }
        return document;
    }

    async compact(): Promise<void> {
        if (this.DB) {
            await this.DB.compact();
        }
    }

    getUserId() {
        return this.user.id;
    }

    getUserMarketId() {
        return this.user.marketId;
    }

    getUserLanguage() {
        return this.user.user_language;
    }

    private async appendAdditionalProperties<T extends IDocument>(
        document: T,
        { internalRev }: { internalRev?: string } = {}
    ): Promise<T> {
        document._id = this.mainRecordId;
        if (!internalRev && !document._rev) {
            let documentFromDatabase;
            try {
                documentFromDatabase = await this.get();
            } catch (error) {}
            if (documentFromDatabase && documentFromDatabase._rev) {
                document._rev = documentFromDatabase._rev;
            }
        } else {
            document._rev = internalRev || document._rev;
        }
        return document;
    }
}
