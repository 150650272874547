import { __decorate, __metadata, __param } from "tslib";
import { Component, Input, Inject } from '@angular/core';

var SyncIconComponent =
/** @class */
function () {
  function SyncIconComponent($translate) {
    this.$translate = $translate;
  }

  Object.defineProperty(SyncIconComponent.prototype, "infoSynced", {
    get: function get() {
      return this.$translate.instant('SYNCHRONIZE|Zsynchronizowane');
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SyncIconComponent.prototype, "infoNotSynced", {
    get: function get() {
      return this.$translate.instant('SYNCHRONIZE|Niezsynchronizowane');
    },
    enumerable: false,
    configurable: true
  });

  __decorate([Input(), __metadata("design:type", Object)], SyncIconComponent.prototype, "synced", void 0);

  SyncIconComponent = __decorate([Component({
    selector: 'sync-icon',
    template: require('./sync-icon.component.html')
  }), __param(0, Inject('$translate')), __metadata("design:paramtypes", [Object])], SyncIconComponent);
  return SyncIconComponent;
}();

export { SyncIconComponent };