import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade } from '@icc/configurator/shared';
import { iccListTab, iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { Observable, SubscriptionLike } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { FillingsColorsService } from '@icc/legacy/configurator/steps/window/glazings/fillings-colors.service';
import { FillingsFacade } from '../glazings/fillings.facade';
import { APP_CONFIG, AppConfigFactory, TranslateService } from '@icc/common';
import { ThresholdColorsService } from '../profiles/threshold-colors.service';
import { LippingColorsService } from '../profiles/lipping-colors.service';
import { DoorPortalsService } from '../door-portals/door-portals.service';

@Component({
    selector: 'icc-colors',
    template: require('./colors.component.html'),
    styles: [require('./colors.component.scss')],
})
export class ColorsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Kolor');
    static stepIcon = {
        ligature: 'palette',
    };

    public configurator = 'window';
    public stepId = 'colors';
    public title = _('COLOR|Wybierz kolor:');
    public options = [
        {
            title: _('WINDOW|Kolory zaawansowane'),
            callback: () => {
                this.mode = 'advanced';
                this.sharedFacade.changeColorsStepMode('advanced');
                this.colorsService.setColorsSashExt(false);
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.mode !== 'advanced',
        },
        {
            title: _('COLOR|Różne kolory skrzydło/rama'),
            callback: () => {
                this.mode = 'extended';
                this.sharedFacade.changeColorsStepMode('advanced');
                this.colorsService.setColorsSashExt(true);
            },
            show: () => {
                return this.mode === 'advanced' && this.availableColorsSashExt();
            },
        },
        {
            title: _('COLOR|Kolor obustronny'),
            callback: () => {
                this.setBilateral();
                this.mode = 'simple';
                this.sharedFacade.changeColorsStepMode('simple');
            },
            show: () => this.mode === 'advanced' && !this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode,
        },
        {
            title: _('WINDOW|Kolory zaawansowane'),
            callback: () => {
                this.mode = 'door_extended';
                this.sharedFacade.changeColorsStepMode('door_extended');
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.mode === 'advanced'
                && this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode
                && this.configurationsService.conf.Current.type === 'door'
                && this.configurationsService.conf.Current.System.door_type,
        },
    ];

    colors: iccListItem[] = [];
    colorGroups: iccListTab[] = [];
    selectedColorId$ = this.windowFacade.selectedColor$.pipe(
        map(color => color && Number(color.id))
    );

    onlyDoubleSidedFrameColor = false;
    onlyDoubleSidedFrameColor$ = this.windowFacade.onlyDoubleSidedFrameColor$;
    innerFrame$ = this.windowFacade.innerFrameColor$;
    outerFrame$ = this.windowFacade.outerFrameColor$;
    coreFrame$ = this.windowFacade.coreFrameColor$;
    alushellFrame$ = this.windowFacade.alushellFrameColor$;
    innerSash$ = this.windowFacade.innerSashColor$;
    outerSash$ = this.windowFacade.outerSashColor$;
    coreSash$ = this.windowFacade.coreSashColor$;
    alushellSash$ = this.windowFacade.alushellSashColor$;
    hasAlushellColorSelect$ = this.windowFacade.hasAlushellColorSelect$;
    doorType$ = this.windowFacade.system$.pipe(map(system => system.door_type));
    showCore = false;

    hasDecoPanel$ = this.fillingsFacade.hasDecoPanel$;
    availableDecoPanelSecondColor$ = this.fillingsFacade.availableDecoPanelSecondColor$;
    innerFillingFirst$ = this.fillingsFacade.innerFirstColor$;
    outerFillingFirst$ = this.fillingsFacade.outerFirstColor$;
    innerFillingOther$ = this.fillingsFacade.innerOtherColor$;
    outerFillingOther$ = this.fillingsFacade.outerOtherColor$;
    innerFillingFirstPassive$ = this.fillingsFacade.innerFirstColorPassive$;
    outerFillingFirstPassive$ = this.fillingsFacade.outerFirstColorPassive$;
    innerFillingOtherPassive$ = this.fillingsFacade.innerOtherColorPassive$;
    outerFillingOtherPassive$ = this.fillingsFacade.outerOtherColorPassive$;
    hasDoorPassiveSash$ =  this.fillingsFacade.hasDoorPassiveSash$;
    hasDecoPanelPassive$ = this.fillingsFacade.hasDecoPanelPassive$;
    availableDecoPanelSecondColorPassive$ = this.fillingsFacade.availableDecoPanelSecondColorPassive$;
    availableInnerDecoPanelSecondColor$ = this.fillingsFacade.availableInnerDecoPanelSecondColor$;
    hasDoorActiveInner$ = this.fillingsFacade.hasDoorActiveInner$;

    hasThresholdColorSelect$ = this.windowFacade.hasThresholdColorSelect$;
    availableThresholds$ = this.windowFacade.availableThresholdColors$;
    thresholdColor$ = this.windowFacade.thresholdColor$;
    hasLippingColorSelect$ = this.windowFacade.hasLippingColorSelect$;
    lippingColor$ = this.windowFacade.lippingColor$;
    innerLippingColor$ = this.windowFacade.innerLippingColor$
    hasLippingBicolorSelect$ = this.windowFacade.hasLippingBicolorSelect$;
    selectedDoorPortalColor$ = this.windowFacade.selectedDoorPortalColor$;
    selectedDoorPortal$ = this.windowFacade.selectedDoorPortal$;

    mode: 'simple' | 'advanced' | 'extended' | 'door_extended' = this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode ? 'advanced' : 'simple';
    private subscriptions: SubscriptionLike[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private fillingsFacade: FillingsFacade,
        private colorsService: ColorsService,
        private fillingsColorsService: FillingsColorsService,
        private configurationsService: ConfigurationsService<'window'>,
        private translateService: TranslateService,
        private thresholdColorsService: ThresholdColorsService,
        private lippingColorsService: LippingColorsService,
        private doorPortalsService: DoorPortalsService
    ) {
        super();
    }

    ngOnInit() {
        this.onlyDoubleSidedFrameColor$.subscribe(o => {
            this.onlyDoubleSidedFrameColor = o;
        });
        const { colors, groups } = this.colorsService.getSimpleColors();
        this.colors = colors.map<iccListItem>(color => ({
            id: Number(color.id),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/color/' + color.color_img,
            tabs: (color.groups && color.groups.map(Number)) || [],
        }));

        this.colorGroups = groups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));
        this.subscriptions.push(
            this.sharedFacade.colorsStepMode$
                .pipe(withLatestFrom(this.windowFacade.colorSashExt$))
                .subscribe(([stepMode, colorSashExt]) => {
                    if (stepMode === 'advanced') {
                        this.mode = colorSashExt ? 'extended' : 'advanced';
                    }
                    if (stepMode === 'door_extended') {
                        this.mode = 'door_extended';
                    }
                })
        );

        this.subscriptions.push(
            this.windowFacade.colorType$.subscribe(colorType => {
                if (
                    ['White', 'Bilateral', 'Cream', 'Transparent', 'Opaque'].indexOf(colorType)
                        === -1
                    && this.mode !== 'extended'
                    && this.mode !== 'door_extended'
                    && this.mode !== 'advanced'
                ) {
                    this.mode = 'advanced';
                    this.sharedFacade.changeColorsStepMode('advanced');
                }
            })
        );
        this.subscriptions.push(
            this.windowFacade.colorSashExt$.subscribe(colorSashExt => {
                if (colorSashExt) {
                    if (this.mode !== 'extended') {
                        this.mode = 'extended';
                        this.sharedFacade.changeColorsStepMode('advanced');
                    }
                } else if (this.mode === 'extended') {
                    this.mode = 'advanced';
                }
            })
        );

        this.subscriptions.push(
            this.fillingsFacade.hasDifferentColors$.subscribe(differentColors => {
                if (differentColors
                    && this.configurationsService.conf.Current.type === 'door'
                    && this.configurationsService.conf.Current.System.door_type
                ) {
                    this.mode = 'door_extended';
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    selectColor(item: iccListItem) {
        const { colors } = this.colorsService.getSimpleColors();
        const color = colors.find(c => Number(c.id) === item.id);
        if (color) {
            if (color.type !== 'white' && color.type !== 'cream') {
                this.colorsService.setColorType('Bilateral');
                this.colorsService.setSimpleColors(color);
            } else if (color.type === 'white') {
                this.colorsService.setColorType('White');
            } else if (color.type === 'cream') {
                this.colorsService.setColorType('Cream');
            }
        }
    }

    setBilateral() {
        const color =
            this.configurationsService.conf.Current.Colors
            && this.configurationsService.conf.Current.Colors.frame
            && ((this.configurationsService.conf.Current.Colors.frame.outer
                && this.configurationsService.conf.Current.Colors.frame.outer.id)
                ? this.configurationsService.conf.Current.Colors.frame.outer
                : this.configurationsService.conf.Current.Colors.frame.core);
        if (color) {
            if (color.type !== 'white' && color.type !== 'cream') {
                this.colorsService.setColorType('Bilateral');
            } else if (color.type === 'white') {
                this.colorsService.setColorType('White');
            } else if (color.type === 'cream') {
                this.colorsService.setColorType('Cream');
            }
        }
    }

    openModalColor(type: 'outer' | 'inner' | 'alushell', place: 'frame' | 'sash' = 'frame') {
        this.colorsService.openModalColorSimple(
            type,
            place,
            this.configurationsService.conf.Current
        );
    }

    openModalFillingColor(type: 'outer' | 'inner', place: 'first' | 'other' = 'first', sash: 'door' | 'doorActive' | 'doorActiveInner' | 'doorPassive' = 'door') {
        this.fillingsColorsService.openModalColor(
            type,
            place,
            sash,
            this.configurationsService.conf.Current
        );
    }

    openModalThresholdColor() {
        this.thresholdColorsService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    openModalLippingColor(type: 'outer' | 'inner' ) {
        this.lippingColorsService.openModal(
            type,
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default,
        )
    }

    availableColorsSashExt() {
        return (
            this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current)
            && this.config().IccConfig.Configurators.extendedColorSelect
            && this.configurationsService.conf.Current.System.different_sash_color
            && this.config().IccConfig.Configurators.selectSashFrameColor
        );
    }

    showInnerDoorPanel(doorType: boolean) {
        return (this.mode === 'door_extended' && doorType || (this.mode !== 'door_extended' && !doorType))
    }

    getFrameAlushellTitle() {
        return this.mode === 'extended' ? this.translateService.instant('COLOR|Kolor nakładki aluminiowej ościeżnicy') : this.translateService.instant('COLOR|Kolor nakładki aluminiowej');
    }

    getFrameOuterTitle(doorType: boolean, hasDecoPanel = false) {
        return ((this.mode === 'extended' || this.mode === 'door_extended' && doorType || this.mode === 'advanced' && hasDecoPanel) && !this.onlyDoubleSidedFrameColor)
            ? this.translateService.instant('COLOR|Kolor zewnętrzny ościeżnicy')
            : ((this.onlyDoubleSidedFrameColor || this.mode !== 'door_extended') && doorType)
            ? this.translateService.instant('COLOR|Kolor ościeżnicy')
            : this.translateService.instant('COLOR|Kolor zewnętrzny');
    }

    getFrameInnerTitle(doorType: boolean, hasDecoPanel = false) {
        return (this.mode === 'extended' || doorType || this.mode === 'advanced' && hasDecoPanel) ? this.translateService.instant('COLOR|Kolor wewnętrzny ościeżnicy') : this.translateService.instant('COLOR|Kolor wewnętrzny')
    }

    showFrameInner(doorType: boolean) {
        return (this.mode === 'door_extended' && doorType || (this.mode !== 'door_extended' && !doorType)) && !this.onlyDoubleSidedFrameColor;
    }

    getFrameCoreTitle() {
        return this.mode === 'extended' ? this.translateService.instant('WINDOW|Rdzeń ościeżnicy') : this.translateService.instant('WINDOW|Rdzeń')
    }

    showInnerLippingColor(doorType: boolean) {
        return (this.mode === 'door_extended' && doorType || (this.mode !== 'door_extended' && !doorType))
    }
    
    openModalPortalColor() {
        this.doorPortalsService.openModalPortalColor();
    }
}
