import { StepComponent, SharedFacade, ICC_PAGE_DATA, _ } from '@icc/configurator/shared';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'icc-modal-info',
  template: require('./modal-info.component.html'),
  styles: [require('./modal-info.component.scss')]
})
export class ModalInfoComponent extends StepComponent implements OnInit {

  public configurator = 'window';
  public stepId = 'itemInfo';
  public title = _('WINDOW|Informacja na temat produktu');
  public options = [];

  item: any | null = null;
  button = true;

  constructor(
      private sharedFacade: SharedFacade,
      @Inject(ICC_PAGE_DATA) private pageData: {
        item: any,
        button: boolean,
      }
  ) {
      super();
  }

  ngOnInit() {
      this.item = this.pageData.item;
      this.button = this.pageData.button;
  }

  close() {
      this.sharedFacade.closePage(true);
  }
}
