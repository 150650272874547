import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryColor } from '@icc/common/data-types';
import {
    isNotNullOrUndefined,
    ModalService,
} from '@icc/configurator/shared';
import { HingesOptionsPageComponent } from '../../hinges-options-page/hinges-options-page.component';
import { IccHinge } from '@icc/common/data-types/Hinge';

@Component({
    selector: 'icc-hinges-page',
    template: require('./hinges-page.component.html'),
    styles: [require('./hinges-page.component.scss')],
})
export class HingesPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz zawiasy');
    public options = [];

    hinges: iccListItem[] = [];
    selectedHingeId = 0;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            hinges: any[];
            hingeColors: IccAccessoryColor[];
            selHinge : IccHinge;
            selHingeColor: IccAccessoryColor;
        },
        private modalService: ModalService,
    ) {
        super();
    }

    ngOnInit() {
        this.hinges = this.pageData.hinges.map(hinge => {
            const selectedColor =
                (hinge.colors_ids
                    && ((this.pageData.selHingeColor
                        && Number(
                            hinge.colors_ids.find(
                                id => Number(this.pageData.selHingeColor.id) === Number(id)
                            )
                        ))
                        || Number(hinge.colors_ids[0])))
                || 0;
            return {
                id: Number(hinge.id),
                title: hinge.name,
                description: hinge.description,
                imageUrl: this.getImageUrl(hinge, selectedColor),
                colors:
                    hinge.colors_ids
                    && hinge.colors_ids
                        .map(c =>
                            this.pageData.hingeColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img,
                        })) || [],
                selectedColor,
                showColorSelection: false,
            };
        });

        this.selectedHingeId = Number(this.pageData.selHinge.id);
    }

    private getImageUrl(hinge: IccHinge, selectedColor: number): string {
        return hinge.colors_imgs && hinge.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hinge.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hinge.img;
    }

    selectHinge(item: iccListItem) {
        const hinge = this.pageData.hinges.find(f => Number(f.id) === Number(item.id));
        this.modalService.open({
            pageComponent: HingesOptionsPageComponent,
            resolve:{
                selectedHinge: hinge,
                hingeColors: this.pageData.hingeColors,
                selHingeColor: this.pageData.selHingeColor,
            }
        }).result.then((result) => {
            this.sharedFacade.closePage(Object.assign(result, {
                hinge,
                category: null,
                subcategory: null
            }));
        })
    }
}
