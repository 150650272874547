import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';
import { Injectable, Inject } from '@angular/core';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { UserService } from '@icc/common/user.service';
import { core } from '@icc/common/Core';
import { PriceSegment } from '@icc/price';


@Injectable()
export class DriveEngineService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfigFactory,
    private userService: UserService,
    private parametersService: ParametersService
  ) {}
  getDriveEngine(rollerObject) {
        const priceSegments: PriceSegment[]  = [];
        rollerObject.drive.prices = core.objToArray(rollerObject.drive.prices || []);
        rollerObject.drive.prices.sort(function sortDrivePrices(a, b) {
            return (
                parseFloat(a.price.replace(',', '.')) - parseFloat(b.price.replace(',', '.'))
            );
        });
        let width = 0;
        let height = 0;
        let weight = 0;
        let pricesList = [];
        const roller = core.copy(rollerObject);
        roller.shutters.forEach((shutter, index) => {
            const shutterWeight = this.parametersService.getRollerWeight(roller, shutter);
            let drivePrice = 0;
            let engineName = '';

            weight += shutterWeight;
            width += shutter.realWidth;
            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                height = shutter.realHeight + roller.realBoxHeight;
            } else {
                height = shutter.realHeight;
            }
            const nextWidth = roller.shutters.length > index + 1 ? roller.shutters[index + 1].realWidth : null;
                if (roller.drive.type === 'manual') {
                    drivePrice = parseFloat(roller.drive.price) * 1;
                } else {
                    pricesList.push(roller.drive.prices.filter(function filterDrivePrices(e) {
                        return (
                            weight <= Number(e.to.replace(',', '.'))
                            && ((roller.driveType === 'override' && ~~e.override)
                                || (roller.driveType !== 'override' && !~~e.override))
                            && width >= Number(e.from.replace(',', '.'))
                            && height <= Number(e.height.replace(',', '.'))
                        );
                    })[0]);
                }
        });
        if (pricesList.length > 0){
          return pricesList;
        } else {
          return null;
        }
  }
}

