import $ from 'jquery';

/**
 * Kontroler ramki przyszybowej
 * @param {object}  $scope             Angular scope
 * @param {object}  $uibModalInstance     Okno modal
 * @param {object}  $timeout           Timeout
 * @param {object}  colorsByTypeFilter Filtr kolorow wg typow
 * @param {object}  colors             Kolory
 * @param {object}  colorsRAL          Kolory RAL
 * @param {object}  typeColor          Typ koloru
 * @param {object}  selColor           Kolor uszczelki
 * @param {object}  hasRal             Posiada RAL
 * @param {object}  confColor          Konfiguracja koloru
 */
export default function ModalBeadColorCtrl($scope, $uibModalInstance, $timeout, colorsByTypeFilter, colors, // jshint ignore:line
                            colorsRAL, typeColor, selColor, hasRal, confColor, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.colors = colors;
    vm.colorsRAL = colorsRAL;
    vm.colorType = typeColor;
    vm.selColor = selColor;
    vm.confColor = confColor;

    vm.window = colorsByTypeFilter(vm.colors, 'window').length > 0;
    vm.white = colorsByTypeFilter(vm.colors, 'white').length > 0;
    vm.veneer = colorsByTypeFilter(vm.colors, 'srenolit').length > 0;
    vm.scolor = colorsByTypeFilter(vm.colors, 'scolor').length > 0;
    vm.RAL = hasRal;

    vm.selectColor = function(color) {
        if (vm.colorType === 'RAL') {
            color.type = 'RAL';
        }
        $uibModalInstance.close(color);
    };

    vm.isSelected = function(color, RAL) {
        if (RAL) {
            if (color.id == vm.selColor.id && 'RAL' == vm.selColor.type) {
                return true;
            }
        } else {
            if (color.id == vm.selColor.id && vm.colorType == vm.selColor.type) {
                return true;
            }
        }
        return false;
    };

    vm.close = function() {
        $uibModalInstance.close();
    };

    vm.refresh = function() {
        ScrollbarService.update();
    };
}
