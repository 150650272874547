import { core, logger } from '../helpers';
import { Common } from '../Common';
import { Injectable } from '@angular/core';
import { IccConfiguratorData, IccPricesData, IccLayoutsData } from '../data-types';
import { BehaviorSubject } from 'rxjs';

export interface LoadedConfiguratorsDataValue {
    [prop: string]: any;
}

@Injectable()
export abstract class ConfiguratorsDataService {
    data: IccConfiguratorData & IccPricesData;
    data$: BehaviorSubject<IccConfiguratorData & IccPricesData> = new BehaviorSubject<IccConfiguratorData & IccPricesData>({});
    layouts: IccLayoutsData['sashesLayouts'] = [];
    layouts$: BehaviorSubject<IccLayoutsData['sashesLayouts']> = new BehaviorSubject<IccLayoutsData['sashesLayouts']>([]);
    layoutsVariants: IccLayoutsData['sashesVariants'] = [];
    layoutsVariants$: BehaviorSubject<IccLayoutsData['sashesVariants']> = new BehaviorSubject<IccLayoutsData['sashesVariants']>([]);
    loaded: boolean;

    abstract load(): Promise<IccConfiguratorData & IccPricesData>;

    async dataAsync() {
        return this.load();
    }

    get<T = any>(type: string, ...ids: (number | { val: any; key: string } | string)[]): T | null {
        if (!this.loaded) {
            this.load();
        }
        if (ids.length === 0) {
            throw new Error('Nie podano wyszukiwanego id');
        }
        let keys = [];
        [type, ...keys] = type.split('.');
        const path = keys.join('.');
        if (Common.isArray(this.data[type])) {
            const conditions = [];
            if (Common.isNumber(ids[0])) {
                conditions.push({ val: ids[0], key: 'id', type: 'number' });
            } else {
                conditions.push(ids[0]);
            }
            const foundedObjects = this.data[type].filter(obj =>
                conditions.every(
                    condition =>
                        condition != null
                        && ((condition.type === 'number'
                            && Number(core.deepFind(obj, condition.key)) === Number(condition.val))
                            || core.deepFind(obj, condition.key) === condition.val)
                )
            );
            if (foundedObjects.length > 0) {
                if (keys.length > 0) {
                    return core.copy(core.deepFind(foundedObjects[0], path));
                } else {
                    return core.copy(foundedObjects[0]);
                }
            } else {
                logger.warn(
                    'Brak danych w kluczu '
                        + type
                        + ' dla podanych parametrów '
                        + core.stringJson(ids)
                );
                return null;
            }
        } else if (Common.isObject(this.data[type])) {
            const matchedElement = core.deepFind(this.data[type], ids[0]);
            if (Common.isArray(matchedElement)) {
                const conditions = [];
                if (Common.isNumber(ids[1])) {
                    conditions.push({ val: ids[1], key: 'id', type: 'number' });
                } else {
                    conditions.push(ids[1]);
                }
                const foundedObjects = matchedElement.filter(obj =>
                    conditions.every(
                        condition =>
                            condition !== null
                            && ((condition.type === 'number'
                                && Number(core.deepFind(obj, condition.key))
                                    === Number(condition.val))
                                || core.deepFind(obj, condition.key) === condition.val)
                    )
                );
                if (foundedObjects.length > 0) {
                    if (keys.length > 0) {
                        return core.copy(core.deepFind(foundedObjects[0], path));
                    } else {
                        return core.copy(foundedObjects[0]);
                    }
                } else {
                    logger.warn(
                        'Brak danych w kluczu '
                            + type
                            + ' dla podanych parametrów '
                            + core.stringJson(ids)
                    );
                    return null;
                }
            } else {
                return matchedElement as T;
            }
        } else if (this.data[type] === null) {
            logger.warn('Klucz ' + type + ' ma wartość null w danych konfiguratorów!');
        } else {
            throw new Error('Nie ma klucza ' + type + ' w danych konfiguratorów!');
        }
    }

    getLayout(id: number) {
        if (!this.loaded) {
            this.load();
        }
        const matchedLayouts = this.layouts.filter(
            layout => Number(layout.SashesLayoutsVariant.id) === Number(id)
        );
        if (matchedLayouts.length > 0) {
            return matchedLayouts[0];
        } else {
            return null;
        }
    }
}
