import { WindowConfiguration } from './WindowConfiguration';
import { DoorActiveConfiguration } from './DoorActiveConfiguration';
import { DoorModel, DoorModelGroup, HandlesType } from '@icc/window';
import { HardwareVisualization } from '../data-types';

export class DoorConfiguration extends WindowConfiguration {
    type: 'door' = 'door';

    height = 2000;
    width = 1000;

    model: DoorModel = null;
    modelOptions: any;
    modelGroup: DoorModelGroup = null;
    sashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';
    ownedSashesTypes = {
        window: false,
        doorActive: true,
        doorPassive: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false
    };
    handleType: HandlesType;
    doorHardware: {
        doorHandleAdjustment: null | number,
        type: null | number,
        set: null | 'leverPull' | 'doubleLever' | 'leverKnob',
        pull: null | number,
        pullColor: null | number,
        pullColorRelatedToPlate: boolean;
        electronicLock: null | number,
        beaconProximity: boolean,
        color: null | number,
        plateType: null | number,
        doorViewer: null | number,
        doorViewerPunching: boolean,
        plates: {
            mainLock: null | number,
            additionalLock: null | number,
            color: null | number
        },
        cylinders: {
            mainLock: null | number,
            additionalLock: null | number,
            system: null | number,
            color: null | number,
            isKeyAvailableForMainLock: null | boolean,
            maxKeyQuantityForMainLock: null | number,
            keyQuantityForMainLock: null | number,
            keyPriceForMainLock: null | number,
            isKeyAvailableForAdditionalLock: null | boolean,
            maxKeyQuantityForAdditionalLock: null | number,
            keyQuantityForAdditionalLock: null | number,
            keyPriceForAdditionalLock: null | number,
        },
        decorativeHingeCovers: null | number,
        decorativeHingeCoversColor: null | number,
        visualizations: {
            pull: HardwareVisualization | null,
            doorViewer: HardwareVisualization | null,
            mainLockPlate: HardwareVisualization | null,
            additionalLockPlate: HardwareVisualization | null,
            mainLockCylinder: HardwareVisualization | null,
            additionalLockCylinder: HardwareVisualization | null,
            decorativeHingeCovers: HardwareVisualization | null
        }
    } = {
        doorHandleAdjustment: null,
        type: null,
        set: null,
        pull: null,
        pullColor: null,
        pullColorRelatedToPlate: null,
        electronicLock: null,
        beaconProximity: false,
        color: null,
        plateType: null,
        doorViewer: null,
        doorViewerPunching: false,
        plates: {
            mainLock: null,
            additionalLock: null,
            color: null
        },
        cylinders: {
            mainLock: null,
            additionalLock: null,
            system: null,
            color: null,
            isKeyAvailableForMainLock: null,
            maxKeyQuantityForMainLock: null,
            keyQuantityForMainLock: null,
            keyPriceForMainLock: null,
            isKeyAvailableForAdditionalLock: null,
            maxKeyQuantityForAdditionalLock: null,
            keyQuantityForAdditionalLock: null,
            keyPriceForAdditionalLock: null,
        },
        decorativeHingeCovers: null,
        decorativeHingeCoversColor: null,
        visualizations: {
            pull: null,
            doorViewer: null,
            mainLockPlate: null,
            additionalLockPlate: null,
            mainLockCylinder: null,
            additionalLockCylinder: null,
            decorativeHingeCovers: null
        }
    };

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?,
        langCode = 'pl'
    ) {
        super(configuration, dataRequiredToUpdate, false, configuratorsDataService, colorsDefaultsService, langCode);

        if (DoorConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else if (DoorActiveConfiguration.is(configuration)) {
            if (configuration.Model && configuration.Model.id) {
                this.model = {
                    id: configuration.Model.id,
                    name: configuration.Model.name,
                    standardFillings: configuration.Model.standard_fills,
                    indexNo: configuration.Model.index_no,
                };
            }

            if (configuration.ModelOptions) {
                this.modelOptions = configuration.ModelOptions;
            }

            this.height = configuration.Height;
            this.sashesType = configuration.SashesType;
            this.width = configuration.Width;
            this.ownedSashesTypes = configuration.OwnedSashesTypes;
            this.doorHardware = configuration.doorHardware;
            this.dictionary.doorHardware = configuration.doorHardwareDictionary.doorHardware;
            this.dictionary.hardwareSystems = configuration.doorHardwareDictionary.hardwareSystems;
            this.dictionary.accessoryColors = {...this.dictionary.accessoryColors, ...configuration.doorHardwareDictionary.accessoryColors};
        }
        delete this.configuratorsDataService;
        delete this.colorsDefaultsService;
    }

    public static is(configuration): configuration is DoorConfiguration {
        return (
            configuration instanceof DoorConfiguration
            || (configuration.$version && !configuration.active && configuration.type === 'door')
        );
    }
}
