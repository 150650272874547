import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    DrawService,
    TranslateService,
    core,
    Common,
    WindowActiveConfiguration,
} from '@icc/common';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { FillingsColorsService } from '@icc/legacy/configurator/steps/window/glazings/fillings-colors.service';
import { BrowserGlazingBeadsService } from '@icc/legacy/configurator/steps/window/glazings/glazing-beads.service';

@Component({
    selector: 'icc-glazing-layout-page',
    template: require('./glazing-layout-page.component.html'),
    styles: [require('./glazing-layout-page.component.scss')],
})
export class GlazingLayoutPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Edycja konstrukcji');
    fullscreen = true;

    drawOptions: any = null;
    drawButtons = {
        fullscreen: false,
        moveShift: true,
        alignments: true,
        dimensions: false,
        extensions: true,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    glazing: {
                        buttons: [
                            {
                                innerHtml: `<img src="/application/dist/web/img/icons/gr_glazzing.png">`,
                                title: this.translateService.instant('INTERFACE|Wybierz szklenie'),
                                onClick: (response: any) => {
                                    if (Common.isDefined(response.sash.parentId)) {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.parentId
                                        );
                                        if (sash) {
                                            const intSash = core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            this.fillingsService.openModal(
                                                intSash,
                                                this.configurationsService.conf.Current,
                                                this.configurationsService.conf.Default,
                                                sash
                                            );
                                        }
                                    } else {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.id
                                        );
                                        this.fillingsService.openModal(
                                            sash,
                                            this.configurationsService.conf.Current,
                                            this.configurationsService.conf.Default,
                                            sash
                                        );
                                    }
                                },
                            },
                            {
                                condition: () =>
                                    (!this.configurationsService.conf.Current.OneGlazing
                                        || this.config().IccConfig.Configurators
                                            .glazingBeadsSelectSash)
                                    && this.config().IccConfig.Configurators.glazingBeadsSelect,
                                innerHtml: `<img src="/application/dist/web/img/icons/gr_glazzing2.png">`,
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz listwę przyszybową'
                                ),
                                onClick: (response: any) => {
                                    if (Common.isDefined(response.sash.parentId)) {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.parentId
                                        );
                                        if (sash) {
                                            const intSash = core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            this.glazingBeadsService.openModalGlazingBead(
                                                this.configurationsService.conf.Current,
                                                intSash,
                                                sash
                                            );
                                        }
                                    } else {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.id
                                        );
                                        this.glazingBeadsService.openModalGlazingBead(
                                            this.configurationsService.conf.Current,
                                            sash,
                                            sash
                                        );
                                    }
                                },
                            },
                            {
                                condition: (response: any) =>
                                    response.glazing.type === 'pvc_panels'
                                    && this.config().IccConfig.Configurators
                                        .pvcPanelsDifferentColors,
                                innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz kolor panelu wypełnieniowego'
                                ),
                                onClick: (response: any) => {
                                    if (Common.isDefined(response.sash.parentId)) {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.parentId
                                        );
                                        if (sash) {
                                            const intSash = core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            if (intSash) {
                                                this.fillingsColorsService.openModalColors(
                                                    intSash.glazing,
                                                    true
                                                );
                                            }
                                        }
                                    } else {
                                        const sash = core.fIdO(
                                            this.configurationsService.conf.Current.Sashes,
                                            response.sash.id
                                        );
                                        if (sash) {
                                            this.fillingsColorsService.openModalColors(
                                                sash.glazing,
                                                true
                                            );
                                        }
                                    }
                                },
                            },
                            {
                                innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz kolor panelu dekoracyjnego'
                                ),
                                condition: (response: any) =>
                                    response.glazing.type === 'deco_panels',
                                onClick: (response: any) =>
                                    this.fillingsColorsService.openModalDecoPanelColors(
                                        this.configurationsService.conf.Current,
                                        response.sash.id,
                                        false,
                                        true
                                    ),
                            },
                            {
                                innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz drugi kolor panelu dekoracyjnego'
                                ),
                                condition: (response: any) =>
                                    response.glazing.type === 'deco_panels'
                                    && response.glazing.availableSecondColor,
                                onClick: (response: any) =>
                                    this.fillingsColorsService.openModalDecoPanelColors(
                                        this.configurationsService.conf.Current,
                                        response.sash.id,
                                        true,
                                        true
                                    ),
                            },
                        ],
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private fillingsColorsService: FillingsColorsService,
        private glazingBeadsService: BrowserGlazingBeadsService,
        private fillingsService: FillingsService
    ) {
        super();
    }

    ngOnInit() {
        this.drawButtons.update();
    }
}
