import { SashTypes, Sash } from '@icc/window';
import { isUndefined, ModalService, IssuesService, isDefined } from '@icc/helpers';
import { core, ConfigurationsService, ConfiguratorsDataService, APP_CONFIG, AppConfigFactory, ProfilesService, EventBusService, TranslateService, UserService, WindowActiveConfiguration } from '@icc/common';
import { Injectable, Inject, Optional } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { SashTypesService } from '@icc/legacy/configurator/layout/sash-types.service';
import { IccSashType } from '@icc/common/data-types';
import { SashLayoutPageComponent } from 'libs/configurator/window/src/lib/sash-layout-page/sash-layout-page.component';
import { SashTypesPageComponent } from 'libs/configurator/window/src/lib/sash-types-page/sash-types-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';

@Injectable()
export class SashesTypesService {
    private sashTypes: IccSashType[] = [];
    sills = [];
    layouts = [];
    loadedData = false;

    constructor(
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private sashTypesService: SashTypesService,
        private profilesService: ProfilesService,
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }


    /**
     * Funkcja inicjalizujaca
     */
    private init() {
        this.sashTypes = this.configuratorsDataService.data.sashTypes;
        this.loadedData = true;
    }

    /**
     * Funkcja otwierania okna modal typów skrzydeł
     */
    public openSashTypeModalOne() {
        this.openSashTypeModal(this.configurationsService.conf.Current.Sashes[0]);
    }

    /**
     * Funkcja otwierania okna modal typów skrzydeł
     * @param  {[type]} sash [description]
     * @return {[type]}      [description]
     */
    public openSashTypeModal(sash: ActiveSash) {
        // brak skrzydła, bład powodowany za szybkim klikaniem (kliknięciem w podział i kliknięciem w wybór skrzydła przed podzieleniem)
        if (isUndefined(sash) || sash === null) {
            return;
        }

        const conf = this.configurationsService.conf.Current;
        const filteredProfiles = this.profilesService.getFilteredProfiles(conf,  conf.System.door_type ? 'virtual_door_sash' : 'sash', {and: ['bottom']});

        const modalInstance = this.modalService.open({
            templateUrl: 'modalSashType.html',
            controller: 'ModalSashTypeCtrl as msashType',
            pageComponent: SashTypesPageComponent,
            resolve: {
                profiles: () => filteredProfiles,
                sashTypes: () => {
                    return this.getMatchingSashTypes(this.sashTypes, sash);
                },
                sash: () => {
                    return sash;
                },
            },
        });

        modalInstance.closed.then(() => {});

        modalInstance.result.then(selectedSashType => {
            if (typeof selectedSashType !== 'undefined') {
                const divs = this.configurationsService.conf.Current.Mullions;
                const lDiv = divs[core.fId(divs, sash.nearMullions.left)];
                const rDiv = divs[core.fId(divs, sash.nearMullions.right)];

                const falseMullionsMap = {
                    DS: 'DK',
                    DSH: 'DK',
                    DSC: 'DK',
                    ODS: 'OD',
                    ODSH: 'OD',
                    DRP: 'DRA',
                    DOP: 'DOA',
                    S: 'S',
                    HS: 'HS',
                    PSK: 'PSK',
                };
                this.sashTypesService.setType(
                    sash,
                    selectedSashType,
                    this.configurationsService.conf.Current
                );

                if (falseMullionsMap[selectedSashType.type] && selectedSashType.passive) {
                    if (selectedSashType.handle_position === 'R' && rDiv.multiAlignRight[0]) {
                        const sashType = this.sashTypes.find(
                            s =>
                                s.type === falseMullionsMap[selectedSashType.type]
                                && s.handle_position === 'L'
                                && !s.passive
                        );
                        if (sashType) {
                            this.sashTypesService.setType(
                                rDiv.multiAlignRight[0],
                                sashType,
                                this.configurationsService.conf.Current
                            );
                        }
                    }
                    if (selectedSashType.handle_position === 'L' && lDiv.multiAlignLeft[0]) {
                        const sashType = this.sashTypes.find(
                            s =>
                                s.type === falseMullionsMap[selectedSashType.type]
                                && s.handle_position === 'R'
                                && !s.passive
                        );
                        if (sashType) {
                            this.sashTypesService.setType(
                                lDiv.multiAlignLeft[0],
                                sashType,
                                this.configurationsService.conf.Current
                            );
                        }
                    }
                }

                // przerysuj rysunek
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: 'frame',
                });
            }
        });
    }

    public getMatchingSashTypes(sashTypes: IccSashType[], sash: ActiveSash) {
        const conf = this.configurationsService.conf.Current;
        const divs = conf.Mullions;
        const shape = conf.Shape;
        const lDiv = divs[core.fId(divs, sash.nearMullions.left)];
        const rDiv = divs[core.fId(divs, sash.nearMullions.right)];

        const minWidthGlass = this.config().IccConfig.Configurators.minWidthGlass;
        const profileInner =  this.profilesService.getProfile(conf.ProfileSet['sash']);
        const profileOuter =  this.profilesService.getProfile(conf.ProfileSet['sashOutward']);

        return sashTypes.filter((type) => {
            if (['HS', 'S'].includes(type.type) && !this.config().IccConfig.Configurators.hs.layoutEditing) {
                return false;
            }
            if (type.type === 'PSK' && !this.config().IccConfig.Configurators.selectPSK) {
                return false;
            }
            if (['DRA', 'DRP', 'DOA', 'DOP'].includes(type.type) && conf.type === 'window') {
                return false;
            }

            if (type.passive
                && type.handle_position === 'R'
                && (isUndefined(rDiv)
                    || rDiv.multiAlignRight.length !== 1
                    || rDiv.multiAlignLeft.length !== 1
                )
            ) {
                return false;
            }
            if (type.passive
                && type.handle_position === 'L'
                && (isUndefined(lDiv)
                    || lDiv.multiAlignLeft.length !== 1
                    || lDiv.multiAlignRight.length !== 1
                )
            ) {
                return false;
            }

            const doorSashTypes = ['DRA', 'DRP', 'DOA', 'DOP', 'F'];
            if (this.config().IccConfig.Configurators.fixInFixDoor) {
                doorSashTypes.push('FF');
            }
            if (conf.type === 'door' && doorSashTypes.indexOf(type.type) === -1) {
                return false;
            }

            if (conf.type === 'door' && type.type === 'F'
                && sash.nearMullions.top === -1 && sash.nearMullions.bottom === -1
                && sash.nearMullions.left === -1 && sash.nearMullions.right === -1) {
                return false;
            }

            if (conf.type === 'door' && sash.type.type === 'F' && sash.nearMullions.top === -1 && sash.nearMullions.bottom > -1) {
                return false;
            }

            let allowedTypes;
            let restrict;
            //koło - jedno skrzydło
            if (shape.shape === 'circle'
                && sash.nearMullions.top === -1 && sash.nearMullions.bottom === -1
                && sash.nearMullions.left === -1 && sash.nearMullions.right === -1) {
                allowedTypes = ['F', 'FF', 'OFF','K'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ],
                    K  : [230, 600]
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    return false;
                }
                if (isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła ||
            if (shape.shape === 'circle'
                && sash.nearMullions.top === -1 && sash.nearMullions.bottom === -1
                && (sash.nearMullions.left > -1 || sash.nearMullions.right > -1)) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ]
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    return false;
                }
                if (isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła = - skrzydło górne
            if (shape.shape === 'circle'
                && sash.nearMullions.left === -1 && sash.nearMullions.right === -1
                && sash.nearMullions.top === -1 && sash.nearMullions.bottom > -1) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ],
                    K  : [230, ]
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    return false;
                }
                if (isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła = - skrzydło dolne
            if (shape.shape === 'circle'
                && sash.nearMullions.left === -1 && sash.nearMullions.right === -1
                && sash.nearMullions.bottom === -1 && sash.nearMullions.top > -1) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ]
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    return false;
                }
                if (isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            if (['PSK'].indexOf(type.type) > -1 && sash.shape.shape !== 'rect') {
                return false;
            }

            if (
                this.config().IccConfig.Configurators.noHingesOnSlope &&
                ['F', 'FF', 'OFF'].indexOf(type.type) === -1 &&
                ((type.handle_position === 'R' && sash.shape.angles.hasLeftSlopeSide) ||
                    (type.handle_position === 'L' && sash.shape.angles.hasRightSlopeSide))
            ) {
                return false;
            }

            if (conf.drawData && type.type !== 'F') {
                const glazingBeadData = conf.drawData.glazingBead.find(gb => gb.sashId === sash.id);
                const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);

                if (glazingBeadData && sashData) {
                    const minWidthSash = glazingBeadData.inner.rect.y - sashData.outer.rect.y + minWidthGlass + (sashData.outer.rect.y + sashData.outer.rect.height) - ( glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height);
                    if (profileInner && !type.out_open && sash.rWidth < minWidthSash + profileInner.width * 2) {
                        return false;
                    }

                    if (profileOuter && type.out_open && sash.rWidth < minWidthSash + profileOuter.width * 2) {
                        return false;
                    }
                }
            }

            return true;
        });
    };

    canBeSashTypeChanged(conf: WindowActiveConfiguration, sash: Sash) {
        const availableSashTypes = conf.System.available_sash_types;
        const minWidthGlass = this.config().IccConfig.Configurators.minWidthGlass;

        const canBeInner =
            availableSashTypes && availableSashTypes.length
                ? availableSashTypes.some(t =>
                      this.sashTypes.some(st => st.id === t && !st.out_open)
                  )
                : true;
        const canBeOuter =
            availableSashTypes && availableSashTypes.length
                ? availableSashTypes.some(t =>
                      this.sashTypes.some(st => st.id === t && st.out_open)
                  )
                : true;

        if (conf.drawData) {
            const glazingBeadData = conf.drawData.glazingBead.find(gb => gb.sashId === sash.id);
            const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);
            const profileInner = this.profilesService.getProfile(conf.ProfileSet['sash']);
            const profileOuter = this.profilesService.getProfile(conf.ProfileSet['sashOutward']);
            if (glazingBeadData && sashData && (profileInner || profileOuter)) {
                const minWidthSash = glazingBeadData.inner.rect.y - sashData.outer.rect.y + minWidthGlass + (sashData.outer.rect.y + sashData.outer.rect.height) - ( glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height);
                if ((!profileInner || profileInner && canBeInner && sash.width < minWidthSash + profileInner.width * 2)
                    && (!profileOuter || profileOuter && canBeOuter && sash.width < minWidthSash + profileOuter.width * 2)) {
                    return false;
                }
            }
        }
        return true;
    }

}
