import { NgModule } from '@angular/core';

import { AccessoriesService } from './accessories.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [AccessoriesService],
})
export class AccessoriesModule {}
