import { ActiveConfiguration } from './ActiveConfiguration';
import { AwningConfiguration } from './AwningConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export class AwningActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is AwningActiveConfiguration {
        return configuration instanceof AwningActiveConfiguration || !configuration.$version;
    }
    type: 'awning' = 'awning';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    PriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    SystemId = 1;
    System: any = {};
    Outreach = 0;
    Width = 0;
    SizePrice = 0;
    Colour: any = {};
    Fabric: any = {};
    FabricStyle: any = {};
    Valance: any = {};
    ValanceInscription = '';
    RollUpValance = false;
    RollUpPrices: any[] = [];
    DriveSide: string = '';
    DriveType: 'motor' | 'crank' = 'crank';
    Crank: any = {};
    Motor: any = {};
    MotorOptions: any[] = [];
    Mounting: any = {};
    MountingQuantity = 0;
    Addons: any[] = [];

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (AwningActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (AwningConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.attributes = configuration.attributes;

                    this.SystemId = configuration.system.id;
                    this.System = configuratorsDataService.get(
                        'awningLines',
                        configuration.system.id
                    );
                    this.Outreach = configuration.outreach;
                    this.Width = configuration.width;
                    this.Colour = configuratorsDataService.get(
                        'awningColours',
                        String(configuration.system.id),
                        configuration.color.id
                    );
                    this.Fabric = configuratorsDataService.get(
                        'awningFabrics',
                        configuration.fabric.id
                    );
                    if (this.Fabric) {
                        this.FabricStyle = this.Fabric.styles.filter(
                            style => Number(style.id) === configuration.fabric.style.id
                        )[0];
                    }

                    this.Valance = configuration.valance;
                    if (configuration.valance) {
                        this.ValanceInscription = configuration.valance.inscription;
                        this.RollUpValance = configuration.valance.rollUp;
                    }
                    this.DriveSide = configuration.drive && configuration.drive.side;
                    this.DriveType = configuration.drive && configuration.drive.type || 'motor';
                    if (configuration.drive.type === 'crank') {
                        this.Crank = configuratorsDataService.get(
                            'awningCranks',
                            configuration.drive.id
                        );
                    } else {
                        this.Motor = configuratorsDataService.get(
                            'awningMotors',
                            configuration.drive.id
                        );
                    }
                    this.MotorOptions = configuration.drive.options.map(
                        option => this.Motor.options.filter(o => Number(o.id) === option.id)[0]
                    );
                    this.Mounting = configuratorsDataService.get(
                        'awningLineMountings',
                        String(configuration.system.id),
                        configuration.mounting.id
                    );
                    this.MountingQuantity = configuration.mounting.quantity;
                    this.Addons = configuration.accessories.map(accessory =>
                        configuratorsDataService.get('awningAccessories', accessory.id)
                    );
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
