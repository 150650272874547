import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { IccAccessoryAccessory, IccAccessory, IccAccessoryColor } from '@icc/common/data-types';
import {
    TranslateService,
    AppConfigFactory,
    APP_CONFIG,
    core,
    ConfigurationsService,
} from '@icc/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { Profile } from '@icc/window';
import { AccessoriesColorsPageComponent } from '../accessories-colors-page/accessories-colors-page.component';
import { Subject, BehaviorSubject } from 'rxjs';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-accessories-options-page',
    template: require('./accessories-options-page.component.html'),
    styles: [require('./accessories-options-page.component.scss')],
})
export class AccessoriesOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('ACCESSORY|Opcje dodatku');

    accessory: IccAccessoryAccessory;
    form: FormGroup = this.fb.group({
        count: 1,
        selectedColor: null,
        colorOptions: null,
        amount_width: 0,
        position: 0,
        amount_height: 0,
        amount: 0,
        comment: '',
    });
    focused = false;
    forIt: 'configuration' | 'sash' | 'side' = 'configuration';
    topFrameProfile: Profile | null = null;
    sash: ActiveSash | null = null;
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private translateService: TranslateService,
        private priceAccessoryService: PriceAccessoryService,
        private configurationsService: ConfigurationsService<'window'>,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private fb: FormBuilder,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessory: IccAccessoryAccessory;
            forIt: 'configuration' | 'sash' | 'side';
            sash?: ActiveSash;
            colors: IccAccessoryColor[];
        },
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        this.accessory = this.pageData.accessory;
        this.forIt = this.pageData.forIt;
        this.sash = this.pageData.sash || null;
        if (
            this.config().IccConfig.Configurators.ventilatorPositioning
            && this.pageData.sash
            && this.pageData.sash.frame
            && this.pageData.sash.frame.top
        ) {
            this.topFrameProfile =
                this.configurationsService.conf.Current.UsedProfiles.find(
                    o => this.pageData.sash && o.id === this.pageData.sash.frame.top.profileId
                ) || null;
        }
        if (this.accessory.blockCountChange) {
            this.form.get('count').disable();
        }
        this.form.patchValue({
            count: this.accessory.count || 1,
            selectedColor: this.accessory.selectedColor,
        });

        this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        });
    }

    add() {
        const colorControl = this.form.get('selectedColor');
        Object.assign(this.accessory, this.form.value);
        this.sharedFacade.closePage({
            accessory: this.accessory,
            color:
                colorControl
                && this.pageData.colors.find(c => Number(c.id) === Number(colorControl.value)),
        });
    }

    canAdd() {
        return true; /* (!IccConfig.Configurators.ventilatorPositioning || !maccessory.selectedVentilatorType || maccessory.selectedVentilatorType == access.type)
                  && access.type != 'dowel_hole' || (maccessory.forIt == 'side'
                      && !maccessory.hasDowelHole(maccessory.config.SideAccessories[maccessory.sash])); */
    }

    getPrice() {
        if (!this.accessory.no_price /*&& !noPrice*/) {
            return this.priceAccessoryService.getPriceAccessoryOne(
                this.accessory,
                this.accessory.show_colors && this.accessory.price_source == 'table'
                    ? this.accessory.colorOptions
                    : this.accessory.selectedColor,
                this.configurationsService.conf.Current
            );
        }
    }

    getAccessoryColors(accessory: IccAccessoryAccessory) {
        return (this.pageData.colors || []).filter(
            c => accessory.colors_ids && accessory.colors_ids.map(Number).includes(Number(c.id))
        );
    }

    openModalColors(accessory: IccAccessoryAccessory) {
        this.modalService.open({
            pageComponent: AccessoriesColorsPageComponent,
            resolve: {
                accessory: accessory,
                type: 'accessory',
                colors$: new BehaviorSubject(accessory.selectedColor),
            },
        });
    }
}
