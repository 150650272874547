import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AccessoryPartialState } from './accessory.reducer';
import { accessoryQuery } from './accessory.selectors';
import { LoadAccessory } from './accessory.actions';
import { SharedFacade } from '@icc/configurator/shared';
import { ConfiguratorsDataService, ConfigurationsService, WindowActiveConfiguration } from '@icc/common';
import { map, tap } from 'rxjs/operators';
import { ComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';
import { AccessoriesActiveConfiguration } from '@icc/common/configurations/AccessoriesActiveConfiguration';
import { SideProfile } from '@icc/window';

@Injectable()
export class AccessoryFacade {

    accessories$ = this.configurationsService.configuration$.pipe(map(conf => !ComplementaryGoodsActiveConfiguration.is(conf) && conf.Accessories || []));
    complementaryAccessories$ = this.configurationsService.configuration$.pipe(map(conf => ComplementaryGoodsActiveConfiguration.is(conf) && conf.ComplementaryGoods.accessory || []));
    complementarySills$ = this.configurationsService.configuration$.pipe(map(conf => ComplementaryGoodsActiveConfiguration.is(conf) && conf.ComplementaryGoods.windowsill || []));
    complementaryCassonettos$ = this.configurationsService.configuration$.pipe(map(conf => ComplementaryGoodsActiveConfiguration.is(conf) && conf.ComplementaryGoods.cassonetto || []));
    complementaryFillings$ = this.configurationsService.configuration$.pipe(map(conf => ComplementaryGoodsActiveConfiguration.is(conf) && conf.ComplementaryGoods.glass || []));
    complementaryProfiles$ = this.configurationsService.configuration$.pipe(map(conf => ComplementaryGoodsActiveConfiguration.is(conf) && conf.ComplementaryGoods.profile || []));
    sideAccessories$ = this.configurationsService.configuration$.pipe(
        map(conf => WindowActiveConfiguration.is(conf) && conf.SideAccessories)
    );
    sideProfiles$ = this.configurationsService.configuration$.pipe(
        map(conf => WindowActiveConfiguration.is(conf) && conf.SideProfiles.reduce<Partial<Record<SideProfile['side'], (SideProfile & {
            name: string;
            img: string;
            code: string;
        })[]>>>((prev, sideProfile) => {
            if (!prev[sideProfile.side]) {
                prev[sideProfile.side] = [];
            }
            const profile = conf.UsedProfiles.find(p => p.id === sideProfile.profileId);
            prev[sideProfile.side].push({
                ...sideProfile,
                name: profile && profile.name || '',
                img: profile && profile.img || '',
                code: profile && profile.code || ''
            });
            return prev;
        }, {}))
    );

    sashes$ = this.configurationsService.configuration$.pipe(map(conf => WindowActiveConfiguration.is(conf) ? conf.Sashes : null));

    isAccessoryConfigurator$ =  this.configurationsService.configuration$.pipe(map(conf => AccessoriesActiveConfiguration.is(conf) ||  ComplementaryGoodsActiveConfiguration.is(conf)))

    constructor(
        private store: Store<AccessoryPartialState>,
        private sharedFacade: SharedFacade,
        private dataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'complementary_goods' | 'accessory' | 'window' | 'roller_shutter' | 'external_blind'>
    ) {}

    loadAll() {
        this.store.dispatch(new LoadAccessory());
    }
}
