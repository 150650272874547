import { ConfiguratorOptions } from './../../../configurator/shared/src/lib/configurator-options';
import { OnInit, Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class PageComponent {
    public abstract title: string | ((options?: ConfiguratorOptions) => string);
    public options: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string,
            fontIcon?: string,
            ligature?: string
        };
        show?: () => boolean
    }[] = [];
    public fullscreen = false;
    public isSummaryHidden = false;

    constructor() {}
}
