import angular from 'angular';
import {logger} from '@icc/common/helpers';

export default function NewOfferCtrl($rootScope, $scope, $location, $filter, $uibModal, ClientsFactory, OffersFactory, IccConfig, Core, EventBusService, ConfigurationsService,
    OnlineStatusService, SynchronizeService, DatabaseManager, StateService, OffersService) {
    'ngInject';
    'use strict';

    $rootScope.loader = true;

    $scope.paginatorSettings = {
        currentClientsPage: 0,             // aktualna strona dla klientów indywidualnych lub wszystkich klientów liczona od 0
        currentCompanyPage: 0,             // jw, tylko dla firm gdy włączony jest podział na klientów
        totalClientsItems : 10,            // ilość elementów na jednej stronie
        totalCompanyItems : 10,            // -#-
        itemsPerPage      : 10,            // ilość elementów na stronę
        constItemsPerPage : 10             // stała ilość elementów przypisywana domyślnie
    };

    $scope.client         = {};
    $scope.oldClients     = {};
    $scope.oldCompany     = {};
    $scope.clients        = {};
    $scope.clientsPrivate = {};
    $scope.clientsCompany = {};
    $scope.clientsTypes   = {};
    $scope.isloaded       = false;

    $scope.filter = {
        people : true,
        company: true
    };
    var clientsList;

    init();

    function init() {
        getClientsTypes();
        $scope.offer = StateService.getCurrentOffer();
        if ($rootScope.user.access === 'producent' && !$scope.offer) {
            $location.url('/app/start/');
        }else if ($rootScope.user.access === 'producent' && $scope.offer.client_id !== null) {
            $location.url('/app/offers_view/' + $scope.offer.tmp_id);
        }
    }


    /*
     * Liczba na tablicę...
     * @param  {int} num Liczba ilości elementów w tablicy.
     * @return {Array} Nowa tablica z ilością elementów przekazaną w parametrze.
     */
    $scope.getNumber = function(num) {
        return new Array(num);
    };

    /**
     * Odświeżenie klientów przy wyszukiwaniu.
     * @param  {int} index        Numer strony (paginator) liczony od zera.
     * @param  {int} itemsPerPage Ilość elementów wyświetlanych na jednej stronie.
     */
    $scope.refreshClients = function(index, itemsPerPage) {
        var searchText = '';

        // domyślne wartości
        if (angular.isUndefined(index)) {
            index = 0;
        }
        if (angular.isUndefined(itemsPerPage)) {
            itemsPerPage = $scope.paginatorSettings.constItemsPerPage;
        }

        $scope.paginatorSettings.itemsPerPage = itemsPerPage;

        // wszyscy klienci na jednej liście
        if (IccConfig.Client.allClientsOnList) {
            // wyszukiwanie
            if (!angular.isUndefined($scope.search_x) && $scope.search_x !== '') {
                searchText = $scope.search_x.toLowerCase();

                $scope.clients = $scope.oldClients.filter(function(elem) {
                    if (!elem.doc.company) {
                        return (elem.doc.surname != null && elem.doc.surname.toLowerCase().indexOf(searchText) >= 0)
                            || (elem.doc.name != null && elem.doc.name.toLowerCase().indexOf(searchText) >= 0);
                    }
                    return (elem.doc.name.toLowerCase().indexOf(searchText) >= 0)
                            || (elem.doc.nip != null && elem.doc.nip.indexOf(searchText) >= 0);
                });

                $scope.paginatorSettings.currentClientsPage = index;
                $scope.paginatorSettings.totalClientsItems  = $scope.clients.length;

                // bez wyszukiwania (przycinanie SLICE, $filter dziwnie działa)
                $scope.clients = $scope.clients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);

            // bez wyszukiwania
            } else {
                $scope.paginatorSettings.currentClientsPage = index;
                $scope.paginatorSettings.totalClientsItems  = $scope.oldClients.length;

                $scope.clients = $scope.oldClients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);
            }
        // klienci podzieleni na firmy i indywidualnych
        } else {
            if (!angular.isUndefined($scope.search_x) && $scope.search_x !== '') {
                searchText = $scope.search_x.toLowerCase();

                // szukaj w klientach indywidualnych
                if ($scope.client.company === false || $scope.client.company === '') {
                    $scope.clientsPrivate = $scope.oldClients.filter(function(elem) {
                        return (elem.doc.surname != null && elem.doc.surname.toLowerCase().indexOf(searchText) >= 0)
                            || (elem.doc.name !== null && elem.doc.name.toLowerCase().indexOf(searchText) >= 0);
                    });

                    $scope.paginatorSettings.currentClientsPage = index;
                    $scope.paginatorSettings.totalClientsItems  = $scope.clientsPrivate.length;

                    $scope.clientsPrivate = $scope.clientsPrivate.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);

                // szukaj w firmach
                } else if ($scope.client.company) {
                    $scope.clientsCompany = $scope.oldCompany.filter(function(elem) {
                        return (elem.doc.name.toLowerCase().indexOf(searchText) >= 0)
                            || (elem.doc.nip !== null && elem.doc.nip.indexOf(searchText) >= 0);
                    });

                    $scope.paginatorSettings.currentCompanyPage = index;
                    $scope.paginatorSettings.totalCompanyItems  = $scope.clientsCompany.length;

                    $scope.clientsCompany = $scope.clientsCompany.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);
                }
            // bez wyszukiwania
            } else {
                if ($scope.client.company === false || $scope.client.company === '') {
                    $scope.paginatorSettings.currentClientsPage = index;
                    $scope.paginatorSettings.totalClientsItems  = $scope.oldClients.length;

                    $scope.clientsPrivate = $scope.oldClients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);

                } else if ($scope.client.company) {
                    $scope.paginatorSettings.currentCompanyPage = index;
                    $scope.paginatorSettings.totalCompanyItems  = $scope.oldCompany.length;

                    $scope.clientsCompany = $scope.oldCompany.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);
                }
            }
        }
    };

    $scope.filterClients = function(isClient, isCompany) {
        $scope.clients = clientsList.filter(function(e) {
            return (e.doc.company == true && isCompany) || (e.doc.company == false && isClient);
        });
    };

    /**
     * Pobieranie klientów.
     * @param  {int} index        Indeks strony
     * @param  {int} itemsPerPage Limit elementów na stronę
     */
    $scope.getClients = function(index, itemsPerPage, countExec = 0) {
        if (angular.isUndefined(index)) {
            index = 0;
        }
        if (angular.isUndefined(itemsPerPage)) {
            itemsPerPage = $scope.paginatorSettings.constItemsPerPage;
        }
        $scope.paginatorSettings.itemsPerPage = itemsPerPage;

        if (IccConfig.Client.allClientsOnList) {
            ClientsFactory.list().then(function(clients) {
                $scope.oldClients = clients.filter(el => !el.doc.deleted && el.doc.surname);

                $scope.paginatorSettings.currentClientsPage = index;
                $scope.paginatorSettings.totalClientsItems  = $scope.oldClients.length;

                $scope.clients = $scope.oldClients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);


                $scope.clients    = clients;
                clientsList       = Core.copy(clients);
                $rootScope.loader = false;
            }).catch(err => {
                logger.error(err);
                countExec < 2 && $scope.getClients(index, itemsPerPage, ++countExec);
            });
        } else {
            if ($scope.isloaded === false) {
                // lista firm
                ClientsFactory.listCompany().then(function(clients) {
                    $scope.oldCompany = clients.filter(el => !el.doc.deleted && el.doc.name);

                    $scope.paginatorSettings.currentCompanyPage = index;
                    $scope.paginatorSettings.totalCompanyItems  = $scope.oldCompany.length;

                    $scope.clientsCompany = $scope.oldCompany.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);

                    // lista klientów
                    ClientsFactory.listPrivate().then(function(clients2) {
                        $scope.oldClients = clients2.filter(el => !el.doc.deleted && el.doc.surname);

                        $scope.paginatorSettings.currentClientsPage = index;
                        $scope.paginatorSettings.totalClientsItems  = $scope.oldClients.length;

                        $scope.clientsPrivate = $scope.oldClients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);

                        $rootScope.loader = false;
                        $scope.isloaded   = true;
                    }).catch(err => {
                        logger.error(err);
                        countExec < 2 && $scope.getClients(index, itemsPerPage, ++countExec);
                    });
                }).catch(err => {
                    logger.error(err);
                    countExec < 2 && $scope.getClients(index, itemsPerPage, ++countExec);
                });
            // odświeżanie tylko listy firm
            } else if ($scope.client.company) {
                ClientsFactory.listCompany().then(function(clients) {
                    $scope.oldCompany = clients.filter(el => !el.doc.deleted && el.doc.name);

                    $scope.paginatorSettings.currentCompanyPage = index;
                    $scope.paginatorSettings.totalCompanyItems  = $scope.oldCompany.length;

                    $scope.clientsCompany = $scope.oldCompany.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);
                }).catch(err => {
                    logger.error(err);
                    countExec < 2 && $scope.getClients(index, itemsPerPage, ++countExec);
                });
            // odświeżanie tylko listy klientów
            } else {
                ClientsFactory.listPrivate().then(function(clients) {
                    $scope.oldClients = clients.filter(el => !el.doc.deleted && el.doc.surname);

                    $scope.paginatorSettings.currentClientsPage = index;
                    $scope.paginatorSettings.totalClientsItems  = $scope.oldClients.length;

                    $scope.clientsPrivate = $scope.oldClients.slice(index * itemsPerPage, index * itemsPerPage + itemsPerPage);
                }).catch(err => {
                    logger.error(err);
                    countExec < 2 && $scope.getClients(index, itemsPerPage, ++countExec);
                });
            }
        }
    };

    /**
     * Dodawanie nowych klientów
     */
    $scope.addClient = function() {
        ClientsFactory.addClient($scope.client).then(function() {
            $scope.client = {company: $scope.client.company};
            $scope.getClients();
            $rootScope.showInfo($filter('translate')('CLIENT|Nowy klient został dodany do listy'), null);
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
            if ($scope.form2) {
                $scope.form2.$setPristine();
                $scope.form2.$setUntouched();
            }
        });
    };

    /**
     * Edycja klienta
     */
    $scope.editClient = function(_id, object, edit) {
        edit = false;

        ClientsFactory.updateClient(_id, object).then(function() {
            $scope.getClients();
            $rootScope.showInfo($filter('translate')('CLIENT|Zaktualizowano dane klienta'), null);

            $scope.form.$setPristine();
            $scope.form.$setUntouched();
        });
    };

    /**
     * Usuwanie klienta
     * @param {object} [obj] objekt klienta
     */
    $scope.deleteClient = function(obj) {
        if (confirm($filter('translate')('INTERFACE|Czy na pewno chesz usunąć?'))) {
            ClientsFactory.removeClient(obj.doc._id, obj.doc).then(function() {
                $scope.getClients();
                $rootScope.showInfo($filter('translate')('CLIENT|Klient został usunięty'), null);
            });
        }
    };

    /**
     * Dodawanie nowej oferty
     * @param {string} [client] obiekt klienta
     */
    $scope.addOffer = function(client) {
        if (ConfigurationsService.conf && ConfigurationsService.conf.Current) {
            ConfigurationsService.conf.Current = null;
            ConfigurationsService.conf.Default = null;
        }
        $rootScope.editedPositionId = null;
        OffersFactory.add({
            tax_rate_id: $rootScope.user.dealer.default_tax_rate,
        }, client).then(id => {
            $location.url('/app/offers_view/' + id);
        }).catch(err => {
            $rootScope.showInfo($filter('translate')('OFFER|Nie udało się utworzyć oferty'), null);
            logger.err(err);
        });
    };

    $scope.selectClient = function(client) {
        $rootScope.loader = true;
        OffersService.get($scope.offer.tmp_id, true).then(offer => {
            var key = OffersFactory.changeKey($rootScope.user.offer_scheme, offer.key, {K_CLIENT: client.reference});
            StateService.setKey('key', key);
            OffersService.update(offer._id, angular.extend(offer, {
                client_id: client.tmp_id,
                forComapny: parseInt(client.company) === 1 ? true : false,
                companyname: client.company_name || '',
                clientname: client.name || '',
                clientsurname: client.surname || '',
                key
            })).then(() => {
                offer.synced = false;
                $rootScope.loader = false;
                $rootScope.showInfo($filter('translate')('OFFER|Przypisano klienta do oferty'), null);
                $location.url('/app/offers_view/' + offer.tmp_id);
            });
        });
    }

    $scope.openClientModal = function(company) {
        ClientsFactory.openClientModal(company, $scope.getClients());
    };

    $scope.openEditClientModal = function(client) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalEditClient.html',
            controller : 'ModalEditClientCtrl as editClient',
            resolve    : {
                client: function() {
                    return client;
                }
            }
        });

        modalInstance.result.then(function(client) {
            if (angular.isObject(client)) {
                ClientsFactory.updateClient(client.tmp_id, client).then(function() {
                    $scope.getClients();
                });
            }

        });
    };

    async function getClientsTypes() {
        if (IccConfig.Client.types) {
            let users = await DatabaseManager.get('Users').get();
            users = users && users.data ? Core.parseJson(users.data).users || {} : {};
            $scope.clientsTypes = users.clientsTypes;
            $scope.$apply();
        }
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('synced', data => data.value.elements.indexOf('Client') > -1 && $scope.getClients()));
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('importedClients', () => $scope.getClients()));

    $scope.$on('$destroy', () => subscriptions.forEach(subscription => subscription.unsubscribe()));
}
