import { Common } from '@icc/common/Common';

import { StateService } from '@icc/common/state.service';
import { EventBusService } from '@icc/common/event-bus.service';

import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;

import {
    ConfigurationsService,
    ConfigurationType,
    Configurator,
} from '@icc/common/configurations/configurations.service';
import { TranslateService } from '@icc/common/translate.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

@Injectable()
export class BrowserConfigurationsService<
    T extends ConfigurationType = ConfigurationType
> extends ConfigurationsService<T> {
    conf: Configurator<T>;

    constructor(
        private currentConfigurationService: CurrentConfiguratorService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        protected translateService: TranslateService,
        private stateService: StateService,
        private eventBusService: EventBusService
    ) {
        super(translateService, config);
        this.init();

        this.eventBusService.subscribeWithoutConfiguration(
            ['changedConfigurator', 'initializedConfigurator'],
            () => {
                this.init();
            }
        );
    }

    /**
     * Uruchamianie wybranego konfiguratora
     */
    init() {
        const currentState = this.stateService.state;
        this.price.noPrice =
            currentState
            && currentState.offers
            && currentState.offers[0]
            && currentState.offers[0].doc
                ? currentState.offers[0].doc.no_price
                : null;

        this.conf = this.getOrInitConfiguratorConfigurations(
            this.currentConfigurationService.conf
        ) as Configurator<T>;
        this.eventBusService.setCurrentConfiguration(this.conf);

        if (Common.isObject(this.config().IccConfig.Configurators[this.currentConfigurationService.conf])) {
            this.version =
                this.config().IccConfig.Configurators[this.currentConfigurationService.conf].version || 1;
        }
    }

    getOrInitConfiguratorConfigurations<U extends ConfigurationType>(type: U, init = false) {
        if (!this.configurators[type] || !this.configurators[type].Current || init) {
            this.initConfiguratorConfigurations(type);
        }
        return this.configurators[type] as Configurator<U>;
    }

    initConfiguratorConfigurations<U extends ConfigurationType>(type: U) {
        const activeConfiguration = this.getActiveConfiguration(type);
        if (activeConfiguration) {
            this.configurators[type] = {
                Configuration: activeConfiguration,
                Current: this.setDefaults(new activeConfiguration()),
                Edit: undefined,
                Default: this.setDefaults(new activeConfiguration()),
                conf: type,
                NewPosition: false,
                measurementsAttachments: [],
                offerConfigurator: false
            };
        }
    }
}
