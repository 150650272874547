import { Common } from '@icc/common/Common';
import {
    ConfigurationsService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
    TranslateService,
} from '@icc/common';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { core } from '@icc/common/helpers';
import { Injectable, Inject } from '@angular/core';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { WindowFacade } from 'libs/configurator/window/src/lib/+state/window.facade';
import { map } from 'rxjs/operators';
import { IccFilling } from '@icc/common/data-types';
import { combineLatest } from 'rxjs';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';

@Injectable()
export class FillingsFacade {
    loadedData = false;

    oneFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'default'))
    );

    doorActiveFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'doorActive'))
    );

    doorPassiveFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'doorPassive'))
    );

    windowFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'window'))
    );

    leftLightFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'leftLight'))
    );

    rightLightFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'rightLight'))
    );

    topLightFillingBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.fillingBox(conf, 'topLight'))
    );

    doorActiveModelBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.modelBox(conf, 'doorActive'))
    );

    doorActiveInnerModelBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.modelBox(conf, 'doorActiveInner'))
    );

    doorPassiveModelBox$ = this.windowFacade.configuration$.pipe(
        map(conf => this.modelBox(conf, 'doorPassive'))
    );

    hasDecoPanel$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            return conf.Sashes.some(
                s =>
                    (s.type.type === 'DRA'
                        || s.type.type === 'DRP'
                        || s.type.type === 'DOA'
                        || s.type.type === 'DOP')
                    && s.glazing
                    && s.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
            );
        })
    );

    availableDecoPanelSecondColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            return conf.Sashes.some(
                s =>
                    (s.type.type === 'DRA'
                        || s.type.type === 'DRP'
                        || s.type.type === 'DOA'
                        || s.type.type === 'DOP')
                    && s.glazing
                    && s.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
                    && s.glazing.available_second_color
            );
        })
    );

    availableInnerDecoPanelSecondColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActive');
            if (conf.type === 'door' && conf.OwnedSashesTypes.doorActive && sash && sash.panelInner && sash.panelInner.type === 'door_panels') {
                return sash.panelInner.available_second_inside_color;
            }
            return sash && sash.glazing
                && sash.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
                && sash.glazing.available_second_color;
        })
    );

    outerFirstColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActive');
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColor
                && sash.glazing.selectedColor.frame
            ) {
                if (conf.Colors.frame.outer && sash.glazing.selectedColor.frame.outer.id) {
                    return sash.glazing.selectedColor.frame.outer;
                } else {
                    return sash.glazing.selectedColor.frame.core;
                }
            }
        })
    );

    innerFirstColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActive');
            if (
                sash
                && sash.panelInner
                && sash.panelInner.selectedColor
                && sash.panelInner.selectedColor.frame
            ) {
                if (conf.Colors.frame.inner && sash.panelInner.selectedColor.frame.inner.id) {
                    return sash.panelInner.selectedColor.frame.inner;
                } else {
                    return sash.panelInner.selectedColor.frame.core;
                }
            }
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColor
                && sash.glazing.selectedColor.frame
            ) {
                if (conf.Colors.frame.inner && sash.glazing.selectedColor.frame.inner.id) {
                    return sash.glazing.selectedColor.frame.inner;
                } else {
                    return sash.glazing.selectedColor.frame.core;
                }
            }
        })
    );

    outerOtherColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActive');
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColorSecond
                && sash.glazing.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.outer && sash.glazing.selectedColorSecond.frame.outer.id) {
                    return sash.glazing.selectedColorSecond.frame.outer;
                } else {
                    return sash.glazing.selectedColorSecond.frame.core;
                }
            }
        })
    );

    innerOtherColor$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActive');
            if (
                sash
                && sash.panelInner
                && sash.panelInner.selectedColorSecond
                && sash.panelInner.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.inner && sash.panelInner.selectedColorSecond.frame.inner.id) {
                    return sash.panelInner.selectedColorSecond.frame.inner;
                } else {
                    return sash.panelInner.selectedColorSecond.frame.core;
                }
            }
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColorSecond
                && sash.glazing.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.inner && sash.glazing.selectedColorSecond.frame.inner.id) {
                    return sash.glazing.selectedColorSecond.frame.inner;
                } else {
                    return sash.glazing.selectedColorSecond.frame.core;
                }
            }
        })
    );

    outerFirstColorPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColor
                && sash.glazing.selectedColor.frame
            ) {
                if (conf.Colors.frame.outer && sash.glazing.selectedColor.frame.outer.id) {
                    return sash.glazing.selectedColor.frame.outer;
                } else {
                    return sash.glazing.selectedColor.frame.core;
                }
            }
        })
    );

    innerFirstColorPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            if (
                sash
                && sash.panelInner
                && sash.panelInner.selectedColor
                && sash.panelInner.selectedColor.frame
            ) {
                if (conf.Colors.frame.inner && sash.panelInner.selectedColor.frame.inner.id) {
                    return sash.panelInner.selectedColor.frame.inner;
                } else {
                    return sash.panelInner.selectedColor.frame.core;
                }
            }
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColor
                && sash.glazing.selectedColor.frame
            ) {
                if (conf.Colors.frame.inner && sash.glazing.selectedColor.frame.inner.id) {
                    return sash.glazing.selectedColor.frame.inner;
                } else {
                    return sash.glazing.selectedColor.frame.core;
                }
            }
        })
    );

    outerOtherColorPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColorSecond
                && sash.glazing.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.outer && sash.glazing.selectedColorSecond.frame.outer.id) {
                    return sash.glazing.selectedColorSecond.frame.outer;
                } else {
                    return sash.glazing.selectedColorSecond.frame.core;
                }
            }
        })
    );

    innerOtherColorPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            if (
                sash
                && sash.panelInner
                && sash.panelInner.selectedColorSecond
                && sash.panelInner.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.inner && sash.panelInner.selectedColorSecond.frame.inner.id) {
                    return sash.panelInner.selectedColorSecond.frame.inner;
                } else {
                    return sash.panelInner.selectedColorSecond.frame.core;
                }
            }
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColorSecond
                && sash.glazing.selectedColorSecond.frame
            ) {
                if (conf.Colors.frame.inner && sash.glazing.selectedColorSecond.frame.inner.id) {
                    return sash.glazing.selectedColorSecond.frame.inner;
                } else {
                    return sash.glazing.selectedColorSecond.frame.core;
                }
            }
        })
    );

    selectedLightGlazingId$ = this.windowFacade.configuration$.pipe(
        map(conf => conf.OneFilling.doorLight || null)
    );

    hasDoorPassiveSash$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            return !!sash;
        })
    );

    hasDecoPanelPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            return sash
                    && sash.glazing
                    && sash.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels');
        })
    );

    availableDecoPanelSecondColorPassive$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorPassive');
            return sash
                   && sash.glazing
                    && sash.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
                    && sash.glazing.available_second_color;
        })
    );

    hasDoorActiveInner$ = this.windowFacade.configuration$.pipe(
        map(conf => {
            const sash = this.getDoorSash(conf, 'doorActiveInner');
            return conf.type === 'door' && conf.OwnedSashesTypes.doorActive && sash && sash.panelType === 'Mixed';
        })
    );

    hasDoorActiveDifferentColors$ = combineLatest([
        this.outerFirstColor$,
        this.innerFirstColor$,
        this.outerOtherColor$,
        this.innerOtherColor$,
        this.availableDecoPanelSecondColor$,
        this.availableInnerDecoPanelSecondColor$,
    ]).pipe(
        map(([
            outerFirstColor,
            innerFirstColor,
            outerOtherColor,
            innerOtherColor,
            availableDecoPanelSecondColor,
            availableInnerDecoPanelSecondColor,
        ]) => {
            return outerFirstColor?.id
                    && innerFirstColor?.id
                    && outerFirstColor?.id !== innerFirstColor?.id
                || availableDecoPanelSecondColor
                    && availableInnerDecoPanelSecondColor
                    && outerOtherColor?.id
                    && innerOtherColor?.id
                    && outerOtherColor?.id !== innerOtherColor?.id
        })
    );

    hasDoorPassiveDifferentColors$ = combineLatest([
        this.outerFirstColorPassive$,
        this.innerFirstColorPassive$,
        this.outerOtherColorPassive$,
        this.innerOtherColorPassive$,
        this.availableDecoPanelSecondColorPassive$
    ]).pipe(
        map(([
            outerFirstColorPassive,
            innerFirstColorPassive,
            outerOtherColorPassive,
            innerOtherColorPassive,
            availableDecoPanelSecondColorPassive
        ]) => {
            return availableDecoPanelSecondColorPassive
                    && outerFirstColorPassive?.id
                    && innerFirstColorPassive?.id
                    && outerFirstColorPassive?.id !== innerFirstColorPassive?.id
                || availableDecoPanelSecondColorPassive
                    && outerOtherColorPassive?.id
                    && innerOtherColorPassive?.id
                    && outerOtherColorPassive?.id !== innerOtherColorPassive?.id
        })
    );

    hasDifferentColors$ = combineLatest([
        this.hasDoorActiveDifferentColors$,
        this.hasDoorPassiveDifferentColors$
    ]).pipe(
        map(([
            hasDoorActiveDifferentColors,
            hasDoorPassiveDifferentColors
        ]) => {
            return hasDoorActiveDifferentColors || hasDoorPassiveDifferentColors;
        })
    );

    constructor(
        private translateService: TranslateService,
        private windowFacade: WindowFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private fillingsService: FillingsService
    ) {}

    init() {
        this.loadedData = true;
    }
    fillingBox(conf: DoorActiveConfiguration | WindowActiveConfiguration, boxType: string) {
        let img = null;
        let title = this.translateService.instant('WINDOW|Wypełnienie');
        let name = this.translateService.instant('INTERFACE|Brak');
        let u = this.translateService.instant('CONFIGURATOR|brak danych');
        let oneGlazing = conf.OneGlazing;
        let bondedGlazing = false;
        let partlyBondedGlazing = false;
        let panelType = 'Inset';
        let type = 'glazing';
        let panelGlazingName = '';
        let color = '';
        let color2 = '';
        let editGlazing = false;

        let oneBondedGlazing = conf.OneBondedGlazing;
        const sash = this.getDoorSash(conf, boxType);

        let show = false;
        if (typeof boxType === 'undefined' || boxType === 'default') {
            show = conf.type !== 'door' || conf.OneGlazing;
        } else if (boxType === 'doorActive') {
            show = conf.type === 'door' && !conf.OneGlazing && conf.OwnedSashesTypes.doorActive;
        } else if (boxType === 'doorPassive') {
            show = conf.type === 'door' && !conf.OneGlazing && conf.OwnedSashesTypes.doorPassive;
        } else if (boxType === 'window') {
            show = conf.type === 'door' && !conf.OneGlazing && conf.OwnedSashesTypes.window;
        } else if (boxType === 'leftLight') {
            show = conf.type === 'door' && conf.OwnedSashesTypes.doorLeftLight;
        } else if (boxType === 'rightLight') {
            show = conf.type === 'door' && conf.OwnedSashesTypes.doorRightLight;
        } else if (boxType === 'topLight') {
            show = conf.type === 'door' && conf.OwnedSashesTypes.doorTopLight;
        }

        if (sash && sash.glazing) {
            img = '/files/filling/' + sash.glazing.image;

            if (typeof boxType === 'undefined' || boxType === 'default') {
                if (
                    (!oneGlazing && conf.type !== 'door')
                    || (oneGlazing && sash.glazing.type === 'glazing')
                ) {
                    title = this.translateService.instant('WINDOW|Pakiet szybowy');
                } else if (oneGlazing && sash.glazing.type === 'pvc_panels') {
                    title = this.translateService.instant('WINDOW|Panel wypełnieniowy');
                }
            } else {
                if (boxType === 'doorActive') {
                    title = this.translateService.instant('WINDOW|Wypełnienie skrzydła aktywnego');
                    oneGlazing = Boolean(conf.OneFilling.doorActive);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.doorActive;
                } else if (boxType === 'doorPassive') {
                    title = this.translateService.instant('WINDOW|Wypełnienie skrzydła biernego');
                    oneGlazing = Boolean(conf.OneFilling.doorPassive);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.doorPassive;
                } else if (boxType === 'window') {
                    title = this.translateService.instant('WINDOW|Wypełnienie naświetli');
                    oneGlazing = Boolean(conf.OneFilling.window);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.window;
                } else if (boxType === 'topLight') {
                    title = this.translateService.instant('WINDOW|Wypełnienie doświetla górnego');
                    oneGlazing = Boolean(conf.OneFilling.doorTopLight);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.doorTopLight;
                } else if (boxType === 'leftLight') {
                    title = this.translateService.instant('WINDOW|Wypełnienie doświetla lewego');
                    oneGlazing = Boolean(conf.OneFilling.doorLeftLight);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.doorLeftLight;
                } else if (boxType === 'rightLight') {
                    title = this.translateService.instant('WINDOW|Wypełnienie doświetla prawego');
                    oneGlazing = Boolean(conf.OneFilling.doorRightLight);
                    oneBondedGlazing = conf.OneBondedGlazingPerSash.doorRightLight;
                }
            }

            name = sash.glazing.name;
            if (this.config().IccConfig.Configurators.showFillingsProducers && sash.glazing.producer) {
                name += ` (${sash.glazing.producer})`;
            }
            if (sash.glazing.u) {
                u = sash.glazing.u + ' W/m2*K';
            }

            bondedGlazing =
                this.config().IccConfig.Configurators.bondedGlazingAvailable
                && oneBondedGlazing
                && sash.bondedGlazing;
            partlyBondedGlazing =
                this.config().IccConfig.Configurators.bondedGlazingAvailable && !oneBondedGlazing;
            type = sash.glazing.type;
            panelType = sash.panelType;
            if (sash.panelGlazing) {
                panelGlazingName = sash.panelGlazing.name;
            }
            color = this.getFillingColorsDescription(conf, sash.glazing);
            if (sash.glazing.available_second_color) {
                color2 = this.getFillingColorsDescription(conf, sash.glazing, true);
            }

            boxType = boxType === 'default' ? this.doorOneGlazingType(conf) : boxType;

        }
        return {
            img,
            title,
            name,
            color,
            color2,
            u,
            oneGlazing,
            bondedGlazing,
            partlyBondedGlazing,
            panelType,
            type,
            panelGlazingName,
            show,
            boxType,
        };
    }

    modelBox(conf: DoorActiveConfiguration | WindowActiveConfiguration, boxType: string) {
        let img = null;
        let title = this.translateService.instant('DOOR|Model');
        let name = this.translateService.instant('INTERFACE|Brak');
        let oneGlazing = conf.OneGlazing;
        let panelType = 'Inset';
        let type = 'deco_panels';
        let panelGlazingName = '';
        let color = '';
        let color2 = '';
        let disabledButton1 = false;

        const sash = this.getDoorSash(conf, boxType);

        let show = false;
        if (typeof boxType === 'undefined' || boxType === 'default' || boxType === 'doorActive') {
            show = conf.type === 'door' && (conf.OneGlazing || conf.OwnedSashesTypes.doorActive);
        } else if (boxType === 'doorActiveInner') {
            show = conf.type === 'door' && conf.System?.door_type && conf.OwnedSashesTypes.doorActive;
        } else if (boxType === 'doorPassive') {
            show = conf.type === 'door' && conf.OwnedSashesTypes.doorPassive;
        }

        let showOptions = false;
        if (sash && sash.glazing) {
            if (boxType === 'doorActive') {
                title = this.translateService.instant('WINDOW|Model skrzydła aktywnego');
                oneGlazing = Boolean(conf.OneFilling.doorActive);
                showOptions = this.fillingsService.getPanelGlazingsByFieldType(boxType, conf)['panelGlazings'].length > 0
                    || this.fillingsService.getMatchingFillingVariantsForField(conf, boxType, sash).length > 1;
            } else if (boxType === 'doorActiveInner') {
                title = this.translateService.instant('WINDOW|Model wewnętrzny skrzydła aktywnego');
                oneGlazing = Boolean(conf.OneFilling.doorActiveInner);
            } else if (boxType === 'doorPassive') {
                title = this.translateService.instant('WINDOW|Model skrzydła biernego');
                oneGlazing = Boolean(conf.OneFilling.doorPassive);
                showOptions = this.fillingsService.getPanelGlazingsByFieldType(boxType, conf)['panelGlazings'].length > 0
                    || this.fillingsService.getMatchingFillingVariantsForField(conf, boxType, sash).length > 1;
            }
            if (boxType === 'doorActiveInner' && sash.panelInner) {
                name = sash.panelInner.name;
                type = sash.panelInner.type;
                img = '/files/filling/' + (sash.panelInner.image_in ? sash.panelInner.image_in : sash.panelInner.image);
                color = this.getFillingColorsDescription(conf, sash.panelInner);
                if (sash.panelInner.available_second_inside_color) {
                    color2 = this.getFillingColorsDescription(conf, sash.panelInner, true);
                }
            } else {
                name = sash.glazing.name;
                type = sash.glazing.type;
                img = '/files/filling/' + sash.glazing.image;
                panelType = sash.panelType;
                if (sash.panelGlazing) {
                    panelGlazingName = sash.panelGlazing.name;
                }
                color = this.getFillingColorsDescription(conf, sash.glazing);
                if (sash.glazing.available_second_color) {
                    color2 = this.getFillingColorsDescription(conf, sash.glazing, true);
                }
            }

            boxType = boxType === 'default' ? this.doorOneGlazingType(conf) : boxType;

        }
        return {
            img,
            title,
            name,
            color,
            color2,
            oneGlazing,
            panelType,
            type,
            panelGlazingName,
            show,
            showOptions,
            boxType,
            disabledButton1
        };
    }

    doorOneGlazingType(conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        return conf.OwnedSashesTypes.doorActive && conf.Sashes.length === 1
            ? 'doorActive'
            : 'default';
    }

    canHaveSashColor(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        return conf.Sashes.every(sash =>
            sash.type && sash.type.type === 'F'
                ? sash.glazing
                  && (sash.glazing.type !== 'deco_panels' && sash.glazing.type !== 'door_panels' || sash.panelType === 'Inset')
                : sash.intSashes.every(
                      field =>
                          field.glazing
                          && (field.glazing.type !== 'deco_panels' && sash.glazing.type !== 'door_panels' || sash.panelType === 'Inset')
                  )
        );
    }

    private getFillingColorsDescription(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        filling: ActiveSash['glazing'],
        second = false
    ) {
        let innerColorName = '';
        let outerColorName = '';

        const colorKey = second ? 'selectedColorSecond' : 'selectedColor';

        if (filling && filling[colorKey] && filling[colorKey].frame) {
            if (filling[colorKey].frame.inner && filling[colorKey].frame.inner.name) {
                innerColorName = filling[colorKey].frame.inner.name;
            } else if (filling[colorKey].frame.core && filling[colorKey].frame.core.name) {
                innerColorName = filling[colorKey].frame.core.name;
            }

            if (filling[colorKey].frame.outer && filling[colorKey].frame.outer.name) {
                outerColorName = filling[colorKey].frame.outer.name;
            } else if (filling[colorKey].frame.core && filling[colorKey].frame.core.name) {
                outerColorName = filling[colorKey].frame.core.name;
            }
        }

        return `${innerColorName}/${outerColorName}`;
    }

    getDoorSash(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        boxType?: string
    ) {
        let sash: ActiveSash;
        if (conf.Sashes) {
            if (typeof boxType === 'undefined' || boxType === 'default') {
                sash = conf.Sashes[0];
            } else if (boxType === 'doorActive' || boxType === 'doorActiveInner') {
                sash = conf.Sashes.find(s => s.type.type === 'DRA' || s.type.type === 'DOA');
            } else if (boxType === 'doorPassive') {
                sash = conf.Sashes.find(s => s.type.type === 'DRP' || s.type.type === 'DOP');
            } else if (boxType === 'door') {
                sash = conf.Sashes.find(
                    s =>
                        s.type.type === 'DRA'
                        || s.type.type === 'DRP'
                        || s.type.type === 'DOA'
                        || s.type.type === 'DOP'
                );
            } else if (boxType === 'window') {
                sash = conf.Sashes.find(
                    s =>
                        s.type.type !== 'DRA'
                        && s.type.type !== 'DRP'
                        && s.type.type !== 'DOA'
                        && s.type.type !== 'DOP'
                );
            } else if (boxType === 'topLight') {
                sash = conf.Sashes.find(
                    s => {
                        const frame = conf.Frames.find(f => f.id === s.frameId);
                        return s.type.type === 'F'
                            && s.nearMullions.left === -1 && ((frame && frame.x) || 0) + s.rx === 0
                            && s.nearMullions.top === -1
                            && ((frame && frame.x) || 0) + s.rx + s.rWidth === conf.Width
                });
            } else if (boxType === 'leftLight') {
                sash = conf.Sashes.find(
                    s => {
                        const frame = conf.Frames.find(f => f.id === s.frameId);
                        return s.type.type === 'F'
                            && s.nearMullions.left === -1 && ((frame && frame.x) || 0) + s.rx === 0
                            && (s.nearMullions.top !== -1
                                || ((frame && frame.x) || 0) + s.rx + s.rWidth !== conf.Width
                            )
                });
            } else if (boxType === 'rightLight') {
                sash = conf.Sashes.find(
                    s => {
                        const frame = conf.Frames.find(f => f.id === s.frameId);
                        return s.type.type === 'F'
                            && s.nearMullions.right === -1 && ((frame && frame.x) || 0) + s.rx > 0
                });
            }
        }
        return sash;
    }
}
