import { Common } from '@icc/common/Common';
const snakeCase = require('snake-case');
import { logger, core } from '@icc/common/helpers';
import { ModalService } from '@icc/configurator/shared';
import { IccColor } from '@icc/common/data-types';
import {
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    APP_CONFIG,
    AppConfigFactory,
} from '@icc/common';
import { PriceService } from '@icc/price';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { Inject, Injectable } from '@angular/core';
import { ColorsPageComponent } from 'libs/configurator/window/src/lib/colors-page/colors-page.component';

/**
 * Serwis kolorów rolet
 */
@Injectable()
export class ColorsService {
    private colors: IccColor[] = [];
    loadedData = false;
    boxGroups = [];
    guideRailGroups = [];
    revisionGroups = [];
    boxInnerGroups = [];
    boxGuideOuterGroups = [];
    profileGroups = [];
    endslatGroups = [];
    boxSideSurfaceGroups = [];
    private configurators = ['window', 'hs', 'roller_shutter', 'external_blind', 'sliding_door', 'folding_door'];

    constructor(
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private colorsMappingService: ColorMappingService,
        private eventBusService: EventBusService
    ) {
        'ngInject';

        if (
            this.configuratorsDataService.loaded
            && this.configurators.indexOf(this.currentConfiguratorService.conf) > -1
        ) {
            this.init(true);
        }

        this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => {
            this.init(true);
        });
        this.eventBusService.subscribeWithoutConfiguration(
            ['saveGuideProfile', 'saveSlatProfile'],
            () => {
                this.init();
            }
        );
    }

    /**
     * Funkcja inicjalizujaca
     */
    public init(
        first = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (
            Common.isArray(this.configuratorsDataService.data.windowColorsAll)
            && Common.isArray(this.configuratorsDataService.data.windowColorGroups)
        ) {
            this.colors = this.configuratorsDataService.data.windowColorsAll;

            const guideRailsIds = [];
            if (conf.RollerShutter.guideRails && conf.RollerShutter.guideRails.length) {
                for (let i = 0; i < conf.RollerShutter.guideRails.length; i++) {
                    if (!guideRailsIds.includes(conf.RollerShutter.guideRails[i].id)) {
                        guideRailsIds.push(conf.RollerShutter.guideRails[i].id);
                    }
                }
            }

            const slatsIds = [];
            if (conf.RollerShutter.slats && conf.RollerShutter.slats.length) {
                for (let i = 0; i < conf.RollerShutter.slats.length; i++) {
                    if (!slatsIds.includes(conf.RollerShutter.slats[i].id)) {
                        slatsIds.push(conf.RollerShutter.slats[i].id);
                    }
                }
            }

            this.boxGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('box') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );

            this.guideRailGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('guide_rail') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
            if (this.config().IccConfig.Configurators.roller_shutter.guides) {
                this.guideRailGroups = this.guideRailGroups.filter(
                    el => el.profiles && el.profiles.some(r => guideRailsIds.includes(Number(r)))
                );
            }

            this.revisionGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('revision') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
            this.boxInnerGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('box_inner') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
            this.boxGuideOuterGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('box_guide_outer') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
            this.profileGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('profile') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
                    && ((el.profiles && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1)
                        || this.config().IccConfig.Configurators.roller_shutter
                            .autoProfileSelection)
            );

            this.endslatGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('endslat') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
            if (this.config().IccConfig.Configurators.roller_shutter.slats) {
                this.endslatGroups = this.endslatGroups.filter(
                    el => el.profiles && el.profiles.some(r => slatsIds.includes(Number(r)))
                );
            }

            this.boxSideSurfaceGroups = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_element)
                    && el.roller_element.indexOf('box_side_surface') > -1
                    && el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
            );
        }
        conf.RollerShutter.guideType = 'pvc';
        defaultConf.RollerShutter.guideType = 'pvc';
        if (!(this.configurationsService.conf.Edit && first)) {
            this.setDefaultsColors(conf, defaultConf);
        }
        this.eventBusService.post({
            key: 'setShutterColor',
            value: null,
        });
        this.loadedData = true;
    }

    /**
     * Funkcja ładujaca kolor dla systemów
     */
    public loadColorsForSystem() {
        this.init();
    }

    /**
     * Funkcja ustawiajaca domyślny kolor
     */
    public setDefaultsColors(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
            this.setColor(
                this.getDefaultColorForGroup('profile', conf),
                null,
                'profile',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('box', conf),
                null,
                'box',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('boxInner', conf),
                null,
                'boxInner',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('boxGuideOuter', conf),
                null,
                'boxGuideOuter',
                true,
                conf,
                defaultConf
            );
        } else {
            this.setColor(
                this.getDefaultColorForGroup('box', conf),
                null,
                'box',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('boxInner', conf),
                null,
                'boxInner',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('boxGuideOuter', conf),
                null,
                'boxGuideOuter',
                true,
                conf,
                defaultConf
            );
            this.setColor(
                this.getDefaultColorForGroup('profile', conf),
                null,
                'profile',
                true,
                conf,
                defaultConf
            );
        }
        this.setColor(
            this.getDefaultColorForGroup('guideRail', conf),
            null,
            'guideRail',
            true,
            conf,
            defaultConf
        );
        this.setColor(
            this.getDefaultColorForGroup('endslat', conf),
            null,
            'endslat',
            true,
            conf,
            defaultConf
        );
        this.setColor(
            this.getDefaultColorForGroup('revision', conf),
            null,
            'revision',
            true,
            conf,
            defaultConf
        );
        this.setColor(
            this.getDefaultColorForGroup('boxSideSurface', conf),
            null,
            'boxSideSurface',
            true,
            conf,
            defaultConf
        );
    }

    /**
     * Ustawia domyślne kolory pasujące do danego koloru.
     *
     * @param {any}     group              Miejsce koloru
     * @param {boolean} [setDefault=false] Czy ustawić w tym miejscu domyślny.
     */
    public setDefaultsColorsOnChangeColor(
        group,
        setDefault = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Current
    ) {
        if (group !== 'window') {
            if (
                setDefault
                && (!conf.RollerShutter.colors[group] || conf.RollerShutter.colors[group].isDefault)
            ) {
                this.setColor(
                    this.getDefaultColorForGroup(group, conf),
                    null,
                    group,
                    true,
                    conf,
                    defaultConf
                );
            }
        }
        if (group === 'window') {
            this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
            if (this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                this.setDefaultsColorsOnChangeColor('guideRail', true, conf, defaultConf);
            } else {
                this.setDefaultsColorsOnChangeColor('boxGuideOuter', true, conf, defaultConf);
            }
        } else if (group === 'boxGuideOuter') {
            if (
                !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                && conf.RollerShutter.type.type === 'N'
            ) {
                if (conf.type !== 'roller_shutter' && conf.type !== 'external_blind') {
                    this.setDefaultsColorsOnChangeColor('profile', true, conf, defaultConf);
                } else {
                    this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
                    this.setDefaultsColorsOnChangeColor('box', true, conf, defaultConf);
                }
                this.setDefaultsColorsOnChangeColor('endslat', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('boxSideSurface', true, conf, defaultConf);
            }
        } else if (group === 'profile') {
            if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
                if (
                    this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                    || conf.RollerShutter.type.type !== 'N'
                ) {
                    this.setDefaultsColorsOnChangeColor('guideRail', true, conf, defaultConf);
                } else {
                    this.setDefaultsColorsOnChangeColor('boxGuideOuter', true, conf, defaultConf);
                }
            }
            this.setDefaultsColorsOnChangeColor('revision', true, conf, defaultConf);
        } else if (group === 'guideRail') {
            if (conf.type !== 'roller_shutter' && conf.type !== 'external_blind') {
                this.setDefaultsColorsOnChangeColor('profile', true, conf, defaultConf);
            }
            this.setDefaultsColorsOnChangeColor('boxGuideOuter', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('box', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('endslat', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('boxSideSurface', true, conf, defaultConf);
        }
    }

    /**
     * Funkcja ładujaca domyślny profil koloru
     */
    public loadDefaultProfileColor() {
        this.setDefaultsColors();
    }

    /**
     * Funkcja ustawiajaca kolor
     * @param {object} color Kolor
     * @param {object} group Grupa
     * @param {object} prop  Prop
     */
    public setColor(
        color,
        group,
        prop,
        isDefault = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (!Common.isObject(color)) {
            logger.warn('Kolor nie może być być null!');
            return;
        }
        color = core.copy(color);
        color.isDefault = isDefault;
        conf.RollerShutter.colors[prop] = color;
        defaultConf.RollerShutter.colors[prop] = color;

        const groupsForColor = this.findGroupForColor(color, conf, prop);
        const charge = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_percent');
        const chargeM2 = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_m2');
        const chargeH = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h');
        const chargeW = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_w');
        if (!Common.isString(group)) {
            group = this.getColorGroup(color, prop, conf);
        }

        conf.RollerShutter.colors[prop + 'Group'] = group;
        defaultConf.RollerShutter.colors[prop + 'Group'] = group;

        conf.RollerShutter.colors[prop + 'Charge'] = charge;
        defaultConf.RollerShutter.colors[prop + 'Charge'] = charge;

        conf.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;
        defaultConf.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;

        conf.RollerShutter.colors[prop + 'ChargeH'] = chargeH;
        defaultConf.RollerShutter.colors[prop + 'ChargeH'] = chargeH;

        conf.RollerShutter.colors[prop + 'ChargeW'] = chargeW;
        defaultConf.RollerShutter.colors[prop + 'ChargeW'] = chargeW;

        this.setDefaultsColorsOnChangeColor(prop, false, conf, defaultConf);
        this.eventBusService.post({
            key: 'setShutterColor',
            value: null,
        });
        this.priceService.count();
    }

    /**
     * Funkcja pobierajaca max grupe kolorow
     * @param  {object} colorGroups Grupa kolorow
     * @param  {object} prop        Prop
     */
    getMaxColorGroupFactor(colorGroups, prop) {
        let colorFactor = null;
        if (Common.isDefined(colorGroups)) {
            for (let i = 0; i < colorGroups.length; i++) {
                if (colorFactor === null || colorGroups[i][prop] * 1 > colorFactor) {
                    colorFactor = colorGroups[i][prop] * 1;
                }
            }
        }

        if (colorFactor === null) {
            colorFactor = 0;
        }

        return colorFactor;
    }

    /**
     * Zwraca grupę koloru - bez dopłaty, za dopłata.
     *
     * @param      {object}  color   Kolor
     * @return     {string}  Grupa koloru
     */
    getColorGroup(color, prop, conf = this.configurationsService.conf.Current) {
        const groupsForColor = this.findGroupForColor(color, conf, prop);
        const charge = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_percent');
        const chargeM2 = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_m2');
        const chargeH = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h');
        const chargeW = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_w');
        let group = 'color';
        if (charge > 0 || chargeM2 > 0 || chargeH > 0 || chargeW > 0) {
            group = 'charge';
        }
        return group;
    }

    /**
     * Funkcja znajdujaca grupe kolorow
     * @param  {object} color Kolor
     * @param  {object} conf  Konfiguracja
     */
    findGroupForColor(color, conf, prop) {
        let gr = [];
        if (!Common.isObject(color)) {
            return gr;
        }
        if (
            Common.isObject(conf.RollerShutter.system)
            && Common.isObject(conf.RollerShutter.profile)
        ) {
            gr = this.configuratorsDataService.data.windowColorGroups.filter(
                el =>
                    Common.isArray(el.roller_systems)
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
                    && Common.isArray(el.profiles)
                    && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1
                    && (color.groups && color.groups.indexOf(el.id) > -1)
                    && Common.isArray(el.roller_element)
                    && el.roller_element.indexOf(snakeCase(prop)) > -1
            );
        }
        return gr;
    }

    /**
     * Funkcja służaca do sortowania kolorów w kolejnosci pierwsze bez dopłaty, potem te za dopłata.
     *
     * @param      {object}  a       Kolor 1
     * @param      {object}  b       Kolor 2
     * @return     {number}  Wynik porównania
     */
    compareColors(group, a, b) {
        const groupA = this.getColorGroup(a, group);
        const groupB = this.getColorGroup(b, group);
        if (groupA === 'color' && groupB === 'charge') {
            return -1;
        } else if (groupA === groupB) {
            return a.order - b.order;
        } else if (groupA === 'charge' && groupB === 'color') {
            return 1;
        }
        return 0;
    }

    /**
     * Funkcja pobierajaca grupe kolorow
     * @param  {object}  group     Grupa
     * @param  {object}  hasCharge Załadowana
     * @param  {object}  conf      Konfiguracja
     */
    getColorsForGroup(group, conf) {
        let colorGroups = this.configuratorsDataService.data.windowColorGroups;
        switch (group) {
            case 'box':
                colorGroups = this.boxGroups;
                break;
            case 'guideRail':
                colorGroups = this.guideRailGroups;
                break;
            case 'revision':
                colorGroups = this.revisionGroups;
                break;
            case 'boxInner':
                colorGroups = this.boxInnerGroups;
                break;
            case 'boxGuideOuter':
                colorGroups = this.boxGuideOuterGroups;
                break;
            case 'profile':
                colorGroups = this.profileGroups;
                break;
            case 'endslat':
                colorGroups = this.endslatGroups;
                break;
            case 'boxSideSurface':
                colorGroups = this.boxSideSurfaceGroups;
                break;
        }
        let gr = [];
        if (
            Common.isObject(conf.RollerShutter.system)
            && Common.isObject(conf.RollerShutter.profile)
        ) {
            gr = colorGroups.filter(
                el =>
                    Common.isArray(el.roller_systems)
                    && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
                    && ((Common.isArray(el.profiles)
                        && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1)
                        || this.config().IccConfig.Configurators.roller_shutter
                            .autoProfileSelection)
            );
        }
        const groupsIds = [];

        for (let i = gr.length - 1; i >= 0; i--) {
            groupsIds.push(gr[i].id);
        }

        const availColors = this.configuratorsDataService.data.windowColorsAll
            .filter(el => el.groups && el.groups.some(el2 => groupsIds.indexOf(el2) > -1))
            .sort(this.compareColors.bind(this, group));
        return availColors;
    }

    /**
     * Funckaj pobierajaca domyslny kolor dla grupy
     * @param  {object} group Grupa
     * @param  {object} conf  Konfiguracja
     */
    getDefaultColorForGroup(group, conf) {
        let matchedColors = [];

        if (group === 'endslat') {
            // z prowadnic - guideRail lub boxGuideOuter
            let guideColorId = null;
            if (
                conf.RollerShutter.type.type === 'N'
                && !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                && conf.RollerShutter.colors.boxGuideOuter
            ) {
                guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
            } else if (conf.RollerShutter.colors.guideRail) {
                guideColorId = conf.RollerShutter.colors.guideRail.id;
            }

            matchedColors = this.colorsMappingService.getColors(guideColorId, 'window', 'window');
        } else {
            if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
                if (group !== 'guideRail' && group !== 'boxGuideOuter') {
                    // z prowadnic - guideRail lub boxGuideOuter
                    let guideColorId = null;
                    if (
                        conf.RollerShutter.type.type === 'N'
                        && !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                        && conf.RollerShutter.colors.boxGuideOuter
                    ) {
                        guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                    } else if (conf.RollerShutter.colors.guideRail) {
                        guideColorId = conf.RollerShutter.colors.guideRail.id;
                    }
                    matchedColors = this.colorsMappingService.getColors(
                        guideColorId,
                        'window',
                        'window'
                    );
                } else if (group === 'guideRail') {
                    // z pancerza
                    if (conf.RollerShutter.colors.profile) {
                        const profileColorId = conf.RollerShutter.colors.profile.id;
                        matchedColors = this.colorsMappingService.getColors(
                            profileColorId,
                            'window',
                            'window'
                        );
                    }
                } else if (group === 'boxGuideOuter') {
                    if (
                        conf.RollerShutter.type.type === 'N'
                        && !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                    ) {
                        // z pancerza
                        if (conf.RollerShutter.colors.profile) {
                            const profileColorId = conf.RollerShutter.colors.profile.id;
                            matchedColors = this.colorsMappingService.getColors(
                                profileColorId,
                                'window',
                                'window'
                            );
                        }
                    } else {
                        // z prowadnic
                        let guideColorId = null;
                        if (conf.RollerShutter.colors.guideRail) {
                            guideColorId = conf.RollerShutter.colors.guideRail.id;
                        }
                        matchedColors = this.colorsMappingService.getColors(
                            guideColorId,
                            'window',
                            'window'
                        );
                    }
                }
            } else {
                if (
                    (group === 'boxGuideOuter'
                        && !this.config().IccConfig.Configurators.roller_shutter
                            .boxColorGuideColor)
                    || group === 'guideRail'
                ) {
                    // z okna zewn.
                    const windowColorId = this.colorsMappingService.getWindowColorId(conf, 'outer');
                    matchedColors = this.colorsMappingService.getColors(
                        windowColorId,
                        'window',
                        'window'
                    );
                } else if (group === 'boxInner') {
                    // z okna wewn.
                    const windowColorId = this.colorsMappingService.getWindowColorId(conf, 'inner');
                    matchedColors = this.colorsMappingService.getColors(
                        windowColorId,
                        'window',
                        'window'
                    );
                } else {
                    // z prowadnic - guideRail lub boxGuideOuter
                    let guideColorId = null;
                    if (
                        !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                        && conf.RollerShutter.colors.boxGuideOuter
                    ) {
                        guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                    } else if (conf.RollerShutter.colors.guideRail) {
                        guideColorId = conf.RollerShutter.colors.guideRail.id;
                    }
                    matchedColors = this.colorsMappingService.getColors(
                        guideColorId,
                        'window',
                        'window'
                    );
                }
            }
        }

        const colorsForGroup = this.getColorsForGroup(group, conf);
        if (colorsForGroup.length > 0) {
            const matchedColorsToGroup = matchedColors
                .map(m => colorsForGroup.filter(c => c && c.id === m && !c.RAL)[0])
                .filter(m => m);
            const inInsulationColorsToGroup = colorsForGroup.filter(c => c && c.in_insulation);
            if (
                (group === 'boxInner' || group === 'boxGuideOuter')
                && this.config().IccConfig.Configurators.roller_shutter.inInsulation
                && conf.RollerShutter.system.inInsulation
                && conf.RollerShutter.inInsulation
                && inInsulationColorsToGroup.length
            ) {
                return inInsulationColorsToGroup[0];
            }
            if (
                matchedColorsToGroup.length
                && this.config().IccConfig.Configurators.colorsMapping.roller_shutter
            ) {
                return matchedColorsToGroup[0];
            }
            return colorsForGroup[0];
        }
        logger.warn('Brak kolorów w tej grupie! Grupa ' + group.name);
        return null;
    }

    /**
     * Funkcja otwierajaca okno modalne
     * @param  {object} colorType Typ koloru
     * @param  {object} conf      Konfiguracja
     */
    public openModalRollerColor(colorType, conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => this.getColorsForGroup(colorType, conf),
                colorsRAL: () => [],
                selectedColor: () => conf.RollerShutter.colors[colorType],
                colorGroups: () => this[colorType + 'Groups'],
                transparentWood: () => false,
                colorsSashExt: () => false,
                type: () => false,
            },
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'color',
            });
        }

        modalInstance.result.then(data => {
            if (Common.isDefined(data)) {
                this.setColor(data.color, data.group, colorType);
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }
}
