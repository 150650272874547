import { Component, OnInit, Inject } from '@angular/core';
import {_, PageComponent, ICC_PAGE_DATA, SharedFacade, ModalService} from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { IccFilling } from '@icc/common/data-types';
import { ModelInfoComponent } from '../model-info/model-info.component';

@Component({
  selector: 'icc-model-list-page',
  template: require('./model-list-page.component.html'),
  styles: [require('./model-list-page.component.scss')]
})
export class ModelListPageComponent extends PageComponent implements OnInit{
    public title = _('DOOR|Model');

    models = this.data.fillings.map<iccListItem>(model => ({
        id: String(model.id),
        title: model.name,
        imageUrl: '/files/filling/' + (this.data.fillingsViewSide === 'inner' && model.image_in ? model.image_in : model.image),
        tabs: [model.fillings_category_id],
        showInfo: true
    }));
    modelCategories = this.data.fillingsCategories.map<iccListTab>(group => ({
        id: Number(group.id),
        name: group.name,
    }));
    currentModelGroup = null;
    selectedFilling = this.data.selectedFilling && String(this.data.selectedFilling.id);

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        @Inject(ICC_PAGE_DATA) private data: {
            fillings: IccFilling[],
            fillingsCategories: any[],
            selectedFilling: IccFilling,
            fillingsViewSide?: 'inner' | 'outer'
        }
    ) {
        super();
    }

    ngOnInit() {
    }

    /**
     * Wybieranie modelu
     * @param  {Object} model Model
     */
    selectModel(modelItem: iccListItem) {
        const filling = this.data.fillings.find(m => String(m.id) === String(modelItem.id));
        if (filling) {
            this.sharedFacade.closePage({
                filling
            });
        }
    }

    showInfo(item: iccListItem) {
        const filling = this.data.fillings.find(m => String(m.id) === String(item.id));
        const modalService = this.modalService
            .open({
                pageComponent: ModelInfoComponent,
                resolve: {
                    filling: () => filling,
                    button: () => true,
                    fillingViewSide: () => this.data.fillingsViewSide
                },
            })
            .result.then((result: boolean) => {
                if (result) {
                    this.selectModel(item);
                }
            });
    }

}
