import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import { SiliconeColorsDefaultsService } from '@icc/common/colors/silicone-colors-defaults.service';

@Injectable()
export class PriceSiliconeService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private siliconeColorsDefaultsService: SiliconeColorsDefaultsService
    ) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru uszczelki.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'siliconeColor',
        data: {
            siliconeColor: 'conf.SiliconeColor',
            conf         : 'conf',
            customPrice  : 'price.WindowSiliconeColor',
            colors       : 'data.windowSiliconeColors'
        }
    })
    suppSiliconeColor({}: PriceElemsData,
        {siliconeColor, conf, customPrice, colors}: {siliconeColor, conf, customPrice: CustomPricesRecords, colors}
    ): PriceSegment[] {
        let factors = 1;
        if (!this.config().IccConfig.Configurators.siliconeColor || conf.System.type !== 'wood') {
            return [];
        }
        const defaultColor = this.siliconeColorsDefaultsService.getDefaultColor(colors, conf);
        if (Common.isObject(siliconeColor) && defaultColor && Number(defaultColor.id) !== Number(siliconeColor.id)) {
            let siliconeColorPrice;
            if (Common.isObject(customPrice) && customPrice[siliconeColor.id]) {
                siliconeColorPrice = customPrice[siliconeColor.id].getPrice('price');
            } else {
                siliconeColorPrice = parseFloat(siliconeColor.price);
            }

            factors *= (100 + siliconeColorPrice) / 100;
        }

        return <PriceSegment[]>[{
            type: 'siliconeColor',
            baseValue: factors,
            value: factors,
            valueType: 'percent',
            data: {
                id  : siliconeColor.id,
                name: siliconeColor.name,
            }
        }];
    }
}
