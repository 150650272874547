import 'angular-ui-bootstrap';
import {core} from '@icc/common/helpers';

/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export class ModalDecoPanelColorsCtrl {
    hideCore = true;

    config = {
        type: 'door',
        Colors: core.copy((this.dualColor ? this.sash.selectedColorSecond :  this.sash.selectedColor)) || {
            frame: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        ColorType: 'White',
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    configDefault = {
        type: 'door',
        Colors: core.copy((this.dualColor ? this.sash.selectedColorSecond : this.sash.selectedColor)) || {
            frame: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        ColorType: 'White',
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    hasCream = false;
    setColorType(type) {
        this.ColorsService.setColorType(type, false, this.config, this.configDefault, this.filterColors.bind(this));

        this.config.Colors.sash = this.config.Colors.frame;
        this.configDefault.Colors.sash = this.configDefault.Colors.frame;
    }
    openModalWood() {
        this.ColorsService.openModalWood(this.config, this.configDefault, this.setDefaults.bind(this));
    }

    filter = (group) => true;
    filterColors = (color) => true;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private sash,
        private dualColor,
        private novalidate,
        private ColorsService,
        private EventBusService,
    ) {
        'ngInject';
        this.filter = (group) => (typeof sash.color_groups_ids !== 'undefined') && sash.color_groups_ids.map(Number).indexOf(parseInt(group.id)) > -1;
        this.filterColors = (color) => color.groups && this.sash.color_groups_ids.map(Number).some(c => color.groups.map(Number).indexOf(~~c) > -1);

        ColorsService.loadData();
        this.setDefaults();
        const filteredColors = ColorsService.windowColors.filter(this.filterColors.bind(this));
        this.hasCream = filteredColors.some(color => color.sides.indexOf('F|C') > -1 && color.type === 'cream');
        this.hideCore = filteredColors.every(color => color.sides.indexOf('F|C') === -1);
    }



    setDefaults() {
        this.ColorsService.loadData();
        this.ColorsService.loadColorsBySystem(this.filterColors.bind(this));
        this.ColorsService.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsService.setDefaultWood(this.config, this.configDefault);
        this.ColorsService.loadColorsByWood(this.config);
        this.ColorsService.setDefaultColors(this.config, this.configDefault, this.filter.bind(this));
        this.ColorsService.setDefaultColorTypeForColors(this.config, this.configDefault);
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close({
                colors: this.config.Colors,
                wood: this.config.Wood
        });
    }

}
