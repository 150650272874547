import { OfferSequenceService } from './OfferSequenceService';
import { Common } from '../Common';
import { core } from '../Core';
import { PositionDetailedSummaryService } from '../PositionDetailedSummaryService';
import { OfferTransportCostService } from './OfferTransportCostService';
import { OfferDiscountsService } from './OfferDiscountsService';
import { OfferHistoryService } from './offer-history.service';

export class OfferSummaryServiceStatic {

    /**
     * Automatyczne uaktualnienie oferty po zmianach w pozycjach
     * @param  {string} _id              Id oferty
     * @param  {object} posObj           Objekt pozycji
     * @param  {string} action           Akcja na pozycji
     * @param  {object} preparedSequence Gotowa struktura pozycji
     * @return {object}                  Promise
     */
    static autoUpdateOffer (
        offerId,
        posObj,
        action,
        preparedSequence,
        user,
        offer,
        IccConfig,
        allPositions,
        market,
        forceUpdateBasedOnDB = false,
        dealer = null,
        transportCostTypes = [],
        transportCosts = []
    ) {
        if (!dealer) {
            dealer = user.dealer;
        }

        const newOfferData = {
            circuit: 0,
            quantity: 0,
            weight: 0,
            weight_positions_quantity: 0,
            area: 0,
            glazing_area: 0,
            number_items: 0,
            valuation: 0,
            sequence: '[]',

            client_discount_position: 0,

            client_price_before_discount_position: 0,
            client_price_before_discount: 0,
            client_price: 0,

            dealer_client_price_before_discount_position: 0,
            dealer_client_price_before_discount: 0,
            dealer_client_price: 0,

            dealer_price_before_discount_position: 0,
            dealer_price_before_discount: 0,
            dealer_price: 0,

            transport_cost: 0,
            client_transport_cost: 0,
            client_discount_special: 0,
            time_limit: 0,
            country_id: offer.country_id,
            postal_code: offer.postal_code,
            transport_cost_type: null,
            transport_cost_type_id: offer.transport_cost_type_id,
            unmatched_oversize_rules: {},
            transport_costs: [],
            transport_from_producent_to_client:  offer.transport_from_producent_to_client,
            transport_from_producent:  offer.transport_from_producent,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
            },
        };

        const sequence = OfferSequenceService.updateOfferSequence(
            preparedSequence || offer.sequence,
            [posObj],
            action,
            IccConfig
        );
        const searched = OfferSequenceService.keysFromSequence(sequence);

        let positions = allPositions;

        positions = positions.map(el => {
            if (!Common.isObject(el.doc.details)) {
                el.doc.details = core.parseJson(el.doc.details);
            }
            return el;
        });
        const position = positions.filter(elem => {
            return Common.isObject(posObj) && elem.doc.tmp_id === posObj.id;
        });
        const positionsGroupsData = {}; /* this.offerGroupService.updatePositionsGroupsData(
            offer.positions_groups_data,
            sequence,
            position[0]
        ) */;
        let transportPosition = null;
        let i = 0;
        let offerGroupDiscounts = [];
        const transportCost = OfferSummaryServiceStatic.calculateTransportCost(positions, user, market, IccConfig);
        while (i < positions.length) {
            if (!Common.isUndefined(positions[i].doc)) {
                if (!positions[i].doc.coupled_position_id) {
                    newOfferData.circuit += positions[i].doc.circuit * positions[i].doc.quantity;
                    newOfferData.weight += positions[i].doc.weight * positions[i].doc.quantity;
                    if (newOfferData.weight) {
                        newOfferData.weight_positions_quantity++;
                    }
                    newOfferData.area += positions[i].doc.area * positions[i].doc.quantity;
                    newOfferData.glazing_area += positions[i].doc.glazing_area * positions[i].doc.quantity;
                    newOfferData.number_items += 1;
                    newOfferData.quantity += Number(positions[i].doc.quantity) || 0;
                }

                if (
                    !positions[i].doc.standard
                    && !positions[i].doc.valuated_price
                    && !positions[i].doc.coupled_position_id
                ) {
                    newOfferData.valuation = 1;
                }

                if (
                    IccConfig.Offer.calculatedDealerTransportCost
                    && positions[i].doc.confType === 'transport_cost'
                    && transportCost !== 0
                    && positions[i].doc.dealer_price !== transportCost
                ) {
                    positions[i].doc.dealer_price = transportCost;
                    positions[i].doc.dealer_price_before_discount = transportCost;
                    positions[i].doc.client_price = transportCost;
                    positions[i].doc.client_price_before_discount = transportCost;

                    transportPosition = core.copy(positions[i].doc);
                }

                if (!positions[i].doc.coupled_position_id) {
                    if (positions[i].doc.confType !== 'additional') {
                        newOfferData.dealer_price_before_discount_position += core.roundPrice(
                            positions[i].doc.dealer_price_before_discount
                                * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_price_before_discount += core.roundPrice(
                            positions[i].doc.dealer_price * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_price +=
                            core.roundPrice(
                                positions[i].doc.dealer_price * positions[i].doc.quantity
                            )
                            - core.roundPrice(
                                (1 / 100)
                                    * positions[i].doc.dealer_price
                                    * positions[i].doc.quantity
                                    * offer.dealer_discount_producer_special
                            ); // jscs:ignore

                        newOfferData.dealer_client_price_before_discount_position += core.roundPrice(
                            positions[i].doc.client_price_before_discount
                                * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_client_price_before_discount += core.roundPrice(
                            positions[i].doc.client_price * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_client_price +=
                            core.roundPrice(
                                positions[i].doc.client_price * positions[i].doc.quantity
                            )
                            - core.roundPrice(
                                (1 / 100)
                                    * positions[i].doc.client_price
                                    * positions[i].doc.quantity
                                    * offer.client_discount_special
                            ); // jscs:ignore
                    }

                    newOfferData.client_price_before_discount_position += core.roundPrice(
                        positions[i].doc.client_price_before_discount * positions[i].doc.quantity
                    ); // jscs:ignore

                    newOfferData.client_price_before_discount += core.roundPrice(
                        positions[i].doc.client_price * positions[i].doc.quantity
                    ); // jscs:ignore
                    newOfferData.client_price +=
                        core.roundPrice(positions[i].doc.client_price * positions[i].doc.quantity)
                        - core.roundPrice(
                            (1 / 100)
                                * positions[i].doc.client_price
                                * positions[i].doc.quantity
                                * offer.client_discount_special
                        ); // jscs:ignore
                }

                if (IccConfig.Offer.detailedSummary) {
                    newOfferData.summary = PositionDetailedSummaryService.detailedSummary(
                        positions[i].doc,
                        IccConfig,
                        newOfferData.summary
                    );
                }

                i++;
            }

        }

        newOfferData.client_price_before_discount_position += core.roundPrice(offer.client_montages_price_before_discount);
        newOfferData.dealer_client_price_before_discount_position += core.roundPrice(offer.dealer_producer_montages_price_before_discount);
        newOfferData.dealer_price_before_discount_position += core.roundPrice(offer.dealer_montages_price_before_discount);

        newOfferData.client_price_before_discount += core.roundPrice(offer.client_montages_price_before_discount * ((100 - offer.client_discount_position) / 100));
        newOfferData.dealer_client_price_before_discount += core.roundPrice(offer.dealer_producer_montages_price_before_discount * ((100 - offer.client_discount_position) / 100));
        newOfferData.dealer_price_before_discount += core.roundPrice(offer.dealer_montages_price_before_discount * ((100 - offer.dealer_discount_producer) / 100));

        newOfferData.client_price += core.roundPrice(offer.client_montages_price_before_discount * ((100 - offer.client_discount_position - offer.client_discount_special) / 100));
        newOfferData.dealer_client_price += core.roundPrice(offer.dealer_producer_montages_price_before_discount * ((100 - offer.client_discount_position - offer.client_discount_special) / 100));
        newOfferData.dealer_price += core.roundPrice(offer.dealer_montages_price_before_discount * ((100 - offer.dealer_discount_producer) / 100));

        if (i === positions.length) {
            if (
                ['logistic-minimum', 'm2-cost', 'weight', 'transport-types'].includes(
                    String(IccConfig.Offer.transportCostType)
                )
                && (offer.transport_from_producent || offer.transport_from_producent_to_client)
                && !offer.split_transport_cost
            ) {
                newOfferData.transport_cost = offer.transport_cost;
                if (
                    IccConfig.Offer.transportCostType === 'm2-cost'
                    && !Number(offer.order)
                    && offer.transport_from_producent
                ) {
                    newOfferData.transport_cost = OfferTransportCostService.transportM2Cost(
                        user.dealer,
                        newOfferData
                    );
                    offer.transport_cost = newOfferData.transport_cost;
                }
                if (IccConfig.Offer.transportCostType === 'weight' && !Number(offer.order) && offer.transport_from_producent) {
                    newOfferData.transport_cost = OfferTransportCostService.transportWeightCost(
                        dealer,
                        newOfferData
                    );
                    offer.transport_cost = newOfferData.transport_cost;
                }

                if (IccConfig.Offer.transportCostType === 'transport-types' && !Number(offer.order) && offer.transport_from_producent_to_client) {
                    const transportTypes = OfferTransportCostService.transportTypesCost(
                        newOfferData,
                        positions.map(el => el.doc),
                        transportCostTypes,
                        transportCosts
                    );
                    if (transportTypes.transportTypes.length > 0) {
                        if (!newOfferData.transport_cost_type_id || transportTypes.transportTypes.every(t => Number(t.transportCostTypeId) !== Number(newOfferData.transport_cost_type_id))) {
                            newOfferData.transport_cost_type_id = transportTypes.transportTypes.sort((a, b) => a.price - b.price)[0].transportCostTypeId;
                        }
                        const transportCostType = transportTypes.transportTypes.find(c => Number(c.transportCostTypeId) === Number(newOfferData.transport_cost_type_id));
                        newOfferData.transport_cost_type = transportCostType?.type;
                        newOfferData.transport_cost = Number(transportCostType?.price);
                        newOfferData.valuation = ['expected_price', 'on_request'].indexOf(transportCostType?.type) > -1 ? 1 : newOfferData.valuation;
                        transportTypes.transportTypes.forEach(type => {
                            newOfferData.transport_costs.push(type.transportCostTypeId);
                            if (type.unmatchedRules?.length > 0) {
                                newOfferData.unmatched_oversize_rules[type.transportCostTypeId] = type.unmatchedRules;
                            }
                        });
                    } else {
                        newOfferData.transport_cost = 0;
                        newOfferData.valuation = 1;
                        newOfferData.unmatched_oversize_rules = transportTypes.unmatchedRules;
                        newOfferData.transport_costs = null;
                    }
                }

                if (newOfferData.transport_cost) {
                    OfferTransportCostService.addTransportService(newOfferData, IccConfig);
                }
            }

            if (
                !offer.transport_from_producent_to_client
                && IccConfig.Offer.transportCostType === 'transport-types'
                && !Number(offer.order)
                && !offer.split_transport_cost
            ) {
                newOfferData.transport_cost = 0;
            }

            if (
                ['logistic-minimum', 'm2-cost', 'weight', 'transport-types'].includes(
                    String(IccConfig.Offer.transportCostType)
                )
                && offer.client_transport_cost
                && (['1', '3'].includes(offer.status) || !offer.client_split_transport_cost)
            ) {
                newOfferData.client_transport_cost = offer.client_transport_cost;
                newOfferData.client_discount_position = offer.client_discount_position;
                newOfferData.client_discount_special = offer.client_discount_special;
                OfferTransportCostService.addClientTransportService(
                    newOfferData,
                    IccConfig
                );
            }
            if (IccConfig.Offer.offerDiscountsMulti) {
                const discountsData = OfferDiscountsService.groupDiscounts(
                    core.parseJson(offer.base_discounts || "[]").concat(core.parseJson(offer.group_discounts).filter(d => d.custom)),
                    newOfferData.dealer_price_before_discount,
                    newOfferData.client_price_before_discount,
                    user,
                    offer
                );
                offerGroupDiscounts = discountsData.discounts;
                newOfferData.dealer_price = offerGroupDiscounts.length
                    ? discountsData.dealerPrice
                    : newOfferData.dealer_price;
                newOfferData.client_price = offerGroupDiscounts.length
                    ? discountsData.clientPrice
                    : newOfferData.client_price;
            }
            if (IccConfig.Configurators.timeLimits && !Number(offer.order)) {
                newOfferData.time_limit = OfferSummaryServiceStatic.getOfferTimeLimit(positions);
            }

            if (offer.dealer_price !== newOfferData.dealer_price || offer.client_price !== newOfferData.client_price) {
                OfferHistoryService.addHistoryEntry(offer, user, [{
                    type: 'price',
                    oldDealerPrice: offer.dealer_price + offer.measure_price,
                    dealerPrice: newOfferData.dealer_price + offer.measure_price,
                    oldClientPrice: offer.client_price + offer.measure_price,
                    clientPrice: newOfferData.client_price + offer.measure_price,
                }]);
            }

            offer = Common.extend(offer, {
                quantity: newOfferData.quantity,
                circuit: newOfferData.circuit,
                number_items: newOfferData.number_items,
                valuation: newOfferData.valuation,
                weight: newOfferData.weight,
                weight_positions_quantity: newOfferData.weight_positions_quantity,
                area: newOfferData.area,
                glazing_area: newOfferData.glazing_area,
                sequence,
                dealer_price_before_discount_position:
                    newOfferData.dealer_price_before_discount_position,
                dealer_price_before_discount: newOfferData.dealer_price_before_discount,
                dealer_price: newOfferData.dealer_price,
                dealer_client_price_before_discount_position:
                    newOfferData.dealer_client_price_before_discount_position,
                dealer_client_price_before_discount:
                    newOfferData.dealer_client_price_before_discount,
                dealer_client_price: newOfferData.dealer_client_price,
                client_price_before_discount_position:
                    newOfferData.client_price_before_discount_position,
                client_price_before_discount: newOfferData.client_price_before_discount,
                client_price: newOfferData.client_price,
                summary: newOfferData.summary,
                group_discounts: offerGroupDiscounts,
                positions_groups_data: positionsGroupsData,
                changed_positions: OfferSummaryServiceStatic.saveOfferChanges(offer.changed_positions, posObj, action),
                time_limit: newOfferData.time_limit,
                transport_cost: newOfferData.transport_cost,
                transport_cost_type_id: newOfferData.transport_cost_type_id,
                transport_cost_type: newOfferData.transport_cost_type,
                unmatched_oversize_rules: newOfferData.unmatched_oversize_rules,
                transport_costs: newOfferData.transport_costs,
            });

            return offer;
        }
    }

    static calculateTransportCost(positions, user, market, IccConfig) {
        if (
            IccConfig.Offer.calculatedDealerTransportCost
            && Common.isObject(user.dealer)
            && user.dealer.add_transport_cost
        ) {
            const positionsQuantity = positions.reduce((prev, curr) => {
                if (curr.doc.confType === 'transport_cost') {
                    return prev;
                } else {
                    return prev + (curr.doc.quantity || 0);
                }
            }, 0);

            const transportCost =
                Math.ceil(positionsQuantity / market.average_number_of_positions_on_stand)
                * (Common.isObject(user.dealer) ? user.dealer.distance : 0)
                * market.price_for_one_stand_per_km
                * market.factor_for_transport_cost
                * 1;
            return transportCost;
        } else {
            return 0;
        }
    }

    /**
     * Zapisuje zmiany pozycji w zamowieniu/ofercie
     * @param  {object} oldChanges Lista zmian wg typu zmiany
     * @param  {string} posObj     Id pozycji
     * @param  {string} action     Nazwa akcji
     * @return {object}            Nowa lista zmian
     */
    static saveOfferChanges(oldChanges, posObj, action) {
        const changes = core.parseJson(oldChanges) || {};

        if (posObj && posObj.id) {
            if (action === 'remove') {
                // nie zapamietujemy dodanych i zaraz usunietych pozycji
                if (!Common.isArray(changes.add) || changes.add.indexOf(posObj.id) === -1) {
                    if (!Common.isArray(changes[action])) {
                        changes[action] = [posObj.id];
                    } else {
                        changes[action].push(posObj.id);
                    }
                }

                // jak cos jest usuniete, nie powinno byc w dodanych i zmienionych
                if (Common.isArray(changes.add) && changes.add.indexOf(posObj.id) >= 0) {
                    changes.add.splice(changes.add.indexOf(posObj.id), 1);
                }
                if (Common.isArray(changes.update) && changes.update.indexOf(posObj.id) >= 0) {
                    changes.update.splice(changes.update.indexOf(posObj.id), 1);
                }
            } else if (action === 'add') {
                // kazda nowe pozycja
                if (!Common.isArray(changes[action])) {
                    changes[action] = [posObj.id];
                } else {
                    changes[action].push(posObj.id);
                }
            } else {
                // zmiany istniejacych pozycji, dodane sa w grupie z nowymi
                if (
                    (!Common.isArray(changes.update) || changes.update.indexOf(posObj.id) === -1)
                    && (!Common.isArray(changes.add) || changes.add.indexOf(posObj.id) === -1)
                ) {
                    if (!Common.isArray(changes.update)) {
                        changes.update = [posObj.id];
                    } else {
                        changes.update.push(posObj.id);
                    }
                }
            }
        }

        return changes;
    }

    static getOfferTimeLimit(positions) {
        if (positions && positions.length) {
            return positions.map(p => core.parseJson(p.doc.configuration))
                .map(conf => conf.timeLimit)
                .filter(s => s)
                .reduce((max, s) => s > max ? s : max, 0);
        }
    }
}
