import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { Accessory } from './parts/common';

import { AccessoriesActiveConfiguration } from './AccessoriesActiveConfiguration';

export class AccessoriesConfiguration implements Configuration {
    $version = 5;
    type: 'accessory' = 'accessory';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {
        accessories: null | boolean;
    } = {
        accessories: null,
    };
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    accessories: Accessory[] = [];
    sideAccessories: {
        top: Accessory[];
        bottom: Accessory[];
        left: Accessory[];
        right: Accessory[];
    } = {
        top: [],
        bottom: [],
        left: [],
        right: [],
    };

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (AccessoriesConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (AccessoriesActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.attributes = configuration.attributes;

                this.accessories = configuration.Accessories.map<Accessory>(
                    accessory => new Accessory(accessory)
                );
                this.sideAccessories = {
                    top: configuration.SideAccessories.top.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    bottom: configuration.SideAccessories.bottom.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    left: configuration.SideAccessories.left.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    right: configuration.SideAccessories.right.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                };
            }
        }
    }

    public static is(configuration): configuration is AccessoriesConfiguration {
        return configuration instanceof AccessoriesConfiguration || configuration.$version;
    }
}
