import { PouchDBNew, clearIDB, PouchDBConfiguration } from './IDB';
import {
    IExtendDatabase,
    PutOptions,
    PutManyOptions,
    IndexesOption,
    GetAllOptions,
    GetChangedOptions,
} from './IDatabase';
import format from 'date-fns/format';
import { Common } from '@icc/common/Common';
import { logger } from '@icc/common/helpers';

export class IccDatabase implements IExtendDatabase {
    private DB: PouchDB.Database<{}> | null = null;
    private options: PouchDBConfiguration = {};

    name: string;
    private user: any;
    private machine: string;
    private indexes: IndexesOption = {};
    private hasAttachments = false;
    private fieldsToSync: string[] = [];
    private eventEmitter: {
        emit: (eventName: string, ...args: any[]) => void;
    };
    type: 'extend' = 'extend';

    constructor(
        {
            name,
            user,
            indexes = {},
            machine,
            hasAttachments,
            eventEmitter,
            fieldsToSync,
        }: {
            name: string;
            user: any;
            indexes?: IndexesOption;
            machine: string;
            hasAttachments: boolean;
            eventEmitter: { emit: (name: string) => void };
            fieldsToSync?: string[];
        },
        options?: PouchDBConfiguration
    ) {
        this.name = name;
        this.user = user;
        this.indexes = indexes;
        this.machine = machine;
        this.hasAttachments = hasAttachments;
        this.eventEmitter = eventEmitter;
        if (options) {
            this.options = options;
        }
        if (fieldsToSync) {
            this.fieldsToSync = fieldsToSync;
        }
    }

    init() {
        this.DB = PouchDBNew<any>(this.name, this.user, this.options);
        this.initIndexes(this.indexes);
    }

    async create(document: any): Promise<any> {
        return this.put(document);
    }

    async createMany(documents: any[], importing: boolean = false): Promise<any> {
        return this.putMany(documents, { importing });
    }

    async update(
        document: any,
        { internalId, internalRev, internalRevision, machine, synced }: PutOptions = {}
    ): Promise<any> {
        return await this.put(document, {
            internalId,
            internalRev,
            internalRevision,
            machine,
            synced,
        });
    }

    async updateMany(
        documents: any[],
        {
            machine,
            synced,
            noChangeRevision,
            onlyNoChanged,
        }: {
            machine?: string;
            synced?: boolean;
            noChangeRevision?: boolean;
            onlyNoChanged?: boolean;
        } = {}
    ): Promise<any> {
        return this.putMany(documents, { machine, synced, noChangeRevision, onlyNoChanged });
    }

    async remove(document: any): Promise<any> {
        await this.put(document, { deleted: true });
    }

    async removeMany(documents: any[]): Promise<any> {
        await this.putMany(documents, { deleted: true });
    }

    async compact(): Promise<void> {
        if (this.DB) {
            await this.DB.compact();
        }
    }

    async get<T extends any = any>(id: string): Promise<T> {
        let document: any = {};
        if (this.DB) {
            document = await this.DB.get(id, { attachments: this.hasAttachments });
        }
        return document;
    }

    async getMany(
        documentsIds: string[],
        {
            includeDocs,
            includeRemoved,
            attachments,
        }: { includeDocs?: boolean; includeRemoved?: boolean; attachments?: boolean } = {}
    ): Promise<any> {
        const allDocsResponse = await this.getAll(null, {
            keys: documentsIds,
            includeDocs,
            includeRemoved,
            attachments,
        });
        return allDocsResponse;
    }

    async getAll(
        queryKey: string | null = null,
        {
            limit = null,
            key = null,
            keys = null,
            attachments = this.hasAttachments,
            includeDocs = true,
            includeRemoved = false,
        }: GetAllOptions = {}
    ): Promise<any[]> {
        let allDocsResponse;
        if (queryKey) {
            return this.query(queryKey, {
                limit,
                key,
                keys,
                attachments,
                includeDocs,
                includeRemoved,
            });
        } else {
            const options: {
                include_docs: boolean;
                attachments: boolean;
                keys?: null | any[];
                limit?: null | number;
            } = {
                include_docs: includeDocs,
                attachments,
            };
            if (keys) {
                options.keys = keys;
            }
            if (limit) {
                options.limit = limit;
            }
            allDocsResponse = await this.DB.allDocs(options);
        }

        const allDocuments = allDocsResponse.rows.filter(
            document => includeRemoved || (document.doc && !document.doc.deleted)
        );
        return allDocuments;
    }

    async getChanged({ fromSequence, attachments = this.hasAttachments }: GetChangedOptions) {
        if (!this.DB) {
            return {
                changes: [],
                sequence: -1,
            };
        }
        const changesResponse = await this.DB.changes({
            include_docs: true,
            since: fromSequence,
            attachments,
        });
        const changedDocuments = changesResponse.results.filter(
            document => !(document.doc as any).synced
        );
        const maxSequence = changedDocuments.reduce(
            (prev, document) => (document.seq > prev ? document.seq : prev),
            fromSequence
        );
        if (this.fieldsToSync && this.fieldsToSync.length) {
            this.overrideFields(changedDocuments, [], true);
        }
        return {
            changes: changedDocuments,
            sequence: maxSequence,
        };
    }

    getInternalName() {
        return (this.DB as any).name;
    }

    async refreshIndexes() {
        for (const key in this.indexes) {
            if (this.indexes.hasOwnProperty(key)) {
                await this.query(key, {
                    includeDocs: false,
                });
            }
        }
    }

    getUserId() {
        return this.user.id;
    }

    getUserMarketId() {
        return this.user.marketId;
    }

    getUserLanguage() {
        return this.user.user_language;
    }

    getMachineId(machine: string) {
        return `${this.getUserId() || ''}:${machine}`;
    }

    private async initIndexes(indexes: IndexesOption = {}) {
        if (!this.DB) {
            return;
        }
        try {
            const idb = await new Promise<IDBDatabase>((resolve, reject) => {
                if (this.DB) {
                    const req = window.indexedDB.open(
                        (<any>this.DB).prefix + (<any>this.DB).name,
                        5
                    );
                    req.onerror = reject;
                    req.onsuccess = (e: any) => resolve(e.target.result);
                }
            });
            if (idb) {
                const empty = idb.objectStoreNames.length <= 0;
                idb.close();
                if (empty) {
                    window.indexedDB.deleteDatabase((<any>this.DB).prefix + (<any>this.DB).name);
                    this.DB = PouchDBNew<any>(this.name, this.user, this.options);
                }
            }
        } catch (err) {
            logger.error(err);
        }

        if (Object.keys(indexes).length > 0) {
            let indexDocumentFromDatabase: any = null;
            try {
                indexDocumentFromDatabase = await this.DB.get('_design/my_index', {});
            // tslint:disable-next-line: no-empty
            } catch (error) {}
            const indexDocument: {
                _id: string;
                synced: boolean;
                views: {
                    [key: string]: any;
                };
                _rev?: string;
            } = {
                _id: '_design/my_index',
                synced: true,
                views: {},
            };
            for (const key in indexes) {
                if (indexes.hasOwnProperty(key)) {
                    const indexKeys = indexes[key];
                    if (Array.isArray(indexKeys)) {
                        indexDocument.views[key] = {
                            map: `function(doc) { emit([${indexKeys.join(',')}].join('_')); }`,
                        };
                    } else {
                        indexDocument.views[key] = {
                            map: `function(doc) { emit(doc.${indexKeys}); }`,
                        };
                    }
                }
            }

            if (indexDocumentFromDatabase && indexDocumentFromDatabase.views) {
                if (!Common.equals(indexDocument.views, indexDocumentFromDatabase.views)) {
                    indexDocument._rev = indexDocumentFromDatabase._rev;
                    this.DB.put(indexDocument);
                }
            } else {
                this.DB.put(indexDocument as any);
            }
        }
    }

    private async query(
        queryKey: string,
        {
            limit = null,
            key = null,
            keys = null,
            attachments = this.hasAttachments,
            includeDocs = true,
            includeRemoved = false,
        }: GetAllOptions = {}
    ) {
        let allDocuments = [];
        let allDocsResponse;
        const options: {
            include_docs: boolean;
            attachments: boolean;
            key?: null | string;
            limit?: null | number;
            startkey?: string;
            endkey?: string;
        } = {
            include_docs: includeDocs,
            attachments,
        };
        if (Array.isArray(keys)) {
            if (limit) {
                options['limit'] = limit;
            }
            for (const k of keys) {
                options.startkey = k;
                options.endkey = k + '\ufff0';
                allDocsResponse = await this.DB.query(`my_index/${queryKey}`, options);
                allDocuments = allDocuments.concat(allDocsResponse.rows);
            }
        } else {
            if (key) {
                options.key = key;
            }
            if (limit) {
                options.limit = limit;
            }
            allDocsResponse = await this.DB.query(`my_index/${queryKey}`, options);
            allDocuments = allDocsResponse.rows;
        }
        return allDocuments.filter(
            document => includeRemoved || (document.doc && !document.doc.deleted)
        );
    }

    private async put(
        document: any,
        { internalId, internalRev, machine, deleted = false, synced = false }: PutOptions = {}
    ): Promise<any> {
        if (!this.DB) {
            return {};
        }
        document = await this.appendAdditionalProperties(document, {
            internalId,
            internalRev,
            machine,
            deleted,
            synced,
        });
        try {
            await this.DB.put(document);
        } catch (error) {
            logger.error(error);
            delete document._rev;
            document = await this.appendAdditionalProperties(document, {
                internalId,
                machine,
                deleted,
                synced,
            });
            await this.DB.put(document);
        }
        this.eventEmitter.emit('updatedDB', this.name);
        return document;
    }

    private async putMany(
        documents: any[],
        {
            machine,
            deleted = false,
            synced = false,
            noChangeRevision = false,
            importing = false,
            onlyNoChanged = false,
        }: PutManyOptions = {}
    ) {
        if (!this.DB) {
            return [];
        }
        const documentsFromDatabase = await this.getMany(
            documents.map(document => document._id || document.tmp_id),
            { includeRemoved: true }
        );
        const newMachine = machine;
        if (this.fieldsToSync && this.fieldsToSync.length && !importing) {
            this.overrideFields(documents, documentsFromDatabase);
        }
        if (onlyNoChanged) {
            documents = documents.filter((document, index) => {
                const documentFromDatabase =
                    documentsFromDatabase.find(d => d.id === document.tmp_id)
                    || documentsFromDatabase[index];
                if (
                    documentFromDatabase.value
                    && documentFromDatabase.doc
                    && documentFromDatabase.doc.revision
                    && document.revision
                ) {
                    const dbRevision = JSON.parse(documentFromDatabase.doc.revision);
                    const docRevision = JSON.parse(document.revision);
                    return (
                        !machine
                        || !docRevision[this.getMachineId(machine)]
                        || (dbRevision[this.getMachineId(machine)]
                            && docRevision[this.getMachineId(machine)]
                            && dbRevision[this.getMachineId(machine)] === docRevision[this.getMachineId(machine)])
                    );
                }
                return true;
            });
        }
        documents = await Promise.all(
            documents.map((document, index) => {
                let internalRev = null;
                const documentFromDatabase =
                    documentsFromDatabase.find(d => d.id === document.tmp_id)
                    || documentsFromDatabase[index];
                if (documentFromDatabase.value && documentFromDatabase.value.rev) {
                    internalRev = documentFromDatabase.value.rev;
                }
                let internalRevision = null;
                if (
                    documentFromDatabase.value
                    && documentFromDatabase.doc
                    && documentFromDatabase.doc.revision
                ) {
                    internalRevision = documentFromDatabase.doc.revision;
                }
                return this.appendAdditionalProperties(document, {
                    internalRev,
                    internalRevision,
                    noChangeRevision,
                    machine: newMachine,
                    deleted,
                    synced,
                });
            })
        );
        const resp = await this.DB.bulkDocs(documents);
        if (resp.some(r => r instanceof Error)) {
            resp.filter(r => r instanceof Error).forEach(r => {
                logger.error(r);
            });
        }
        this.eventEmitter.emit('updatedDB', this.name);
        return documents;
    }

    private overrideFields(documents: any[], documentsFromDatabase: any[], toNull = false) {
        documents.forEach(document => {
            const localData = documentsFromDatabase.find(
                el => el && el.doc && el.doc.tmp_id === document.tmp_id
            );
            if (localData || toNull) {
                Object.keys(document.doc || document).forEach(field => {
                    if (!this.fieldsToSync.includes(field)) {
                        (document.doc || document)[field] = localData ? localData.doc[field] : null;
                    }
                });
            }
        });
    }

    private async appendAdditionalProperties(
        document: any,
        {
            internalId,
            internalRev,
            noChangeRevision,
            machine,
            internalRevision,
            deleted = false,
            synced = false,
        }: PutOptions = {}
    ) {
        document.modified_tmp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
        document.synced = synced;
        document._id = internalId || document._id || document.tmp_id;
        if (deleted) {
            document.deleted = true;
        }
        if (machine || this.machine) {
            document.machine = machine || this.machine;
        }

        let documentFromDatabase;
        if (!internalRev || !internalRevision) {
            try {
                documentFromDatabase = await this.get(document._id);
            // tslint:disable-next-line: no-empty
            } catch (error) {}
            if (documentFromDatabase && documentFromDatabase._rev) {
                document._rev = documentFromDatabase._rev;
            }
            if (documentFromDatabase && documentFromDatabase.revision) {
                document.revision = documentFromDatabase.revision;
            }
        } else {
            if (internalRev) {
                document._rev = internalRev;
            }
            if (internalRevision) {
                if (document.revision) {
                    const oldRevision = JSON.parse(internalRevision);
                    const newRevision = JSON.parse(document.revision);
                    if (Common.isObject(oldRevision)) {
                        for (const [iMachine, version] of Object.entries(oldRevision)) {
                            if (newRevision[iMachine] == null || version > newRevision[iMachine]) {
                                newRevision[iMachine] = version;
                            }
                        }
                    }
                    document.revision = JSON.stringify(newRevision);
                } else {
                    document.revision = internalRevision;
                }
            }
        }

        if (!document.revision || !Common.isObject(JSON.parse(document.revision))) {
            document.revision = JSON.stringify({
                [this.getMachineId(document.machine)]: 1,
            });
        } else {
            const tmpRevision = JSON.parse(document.revision);
            if (!noChangeRevision) {
                if (!tmpRevision[this.getMachineId(document.machine)]) {
                    tmpRevision[this.getMachineId(document.machine)] = 1;
                } else {
                    tmpRevision[this.getMachineId(document.machine)] += 1;
                }
            }
            document.revision = JSON.stringify(tmpRevision);
        }

        // od tego momentu ustawiane sa wartości "psujące" widok, a wymagane dla PouchDB
        document = Common.extend({}, document);
        if (this.hasAttachments && !deleted) {
            document._attachments = {};
            document._attachments[document.name] = {
                content_type: document.type,
                data: document.data,
            };
            delete document.data;
        }

        return document;
    }
}
