import { core } from '../helpers';
import { Common } from '../Common';
import Position from './Position';
import { OfferGroupCodeService } from './OfferGroupCodeService';
import { OfferSequenceService } from './OfferSequenceService';
import { OfferSummaryServiceStatic } from './offer-summary.service';
import { MontagesService } from './montages.service';

export class PositionService {
    static getCopied(positions, source, destination, skip, IccConfig, dealer, allDiscounts) {
        const relations = {};
        skip = Common.isArray(skip) ? skip : [];
        const newPositions = positions
            .filter(pos => pos && skip.indexOf(pos.confType) === -1)
            .map(pos => {
                const originalId = pos.tmp_id || pos._id;
                const params = Common.extend(pos, {
                    dealer_offer_id: destination,
                    id: null,
                    tmp_id: null,
                    created: null,
                });

                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                params.configuration = core.parseJson(params.configuration);
                const newPosition = new Position(
                    Common.extend(params, {
                        groupCode,
                        offer: source,
                    }),
                    IccConfig,
                    dealer,
                    allDiscounts
                );
                newPosition.configuration = core.stringJson(newPosition.configuration);
                relations[originalId] = newPosition.id;
                return newPosition;
            });
        newPositions
            .filter(position => position.confType === 'coupled_window')
            .forEach(position => {
                position.details = core.parseJson(position.details);
                position.details.windows = position.details.windows.map(window => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.details.rollerShutters = position.details.rollerShutters.map(
                    rollerShutter => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.details.couplings = position.details.couplings.map(coupling => {
                    coupling.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    coupling.otherFramesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return coupling;
                });
                position.details.sideProfiles = position.details.sideProfiles.map(sideprofile => {
                    sideprofile.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sideprofile;
                });
                position.details.windowSills = position.details.windowSills.map(sill => {
                    sill.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sill;
                });
                position.details = core.stringJson(position.details);

                position.configuration = core.parseJson(position.configuration);
                position.configuration.windows = position.configuration.windows.map(window => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.configuration.rollerShutters = position.configuration.rollerShutters.map(
                    rollerShutter => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.configuration.couplings = position.configuration.couplings.map(coupling => {
                    coupling.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    coupling.otherFramesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return coupling;
                });
                position.configuration.sideProfiles = position.configuration.sideProfiles.map(sideprofile => {
                    sideprofile.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sideprofile;
                });
                position.configuration.windowSills = position.configuration.windowSills.map(sill => {
                    sill.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sill;
                });
                position.configuration = core.stringJson(position.configuration);
            });

        newPositions
            .filter(position => position.coupled_position_id)
            .forEach(position => {
                position.coupled_position_id = relations[position.coupled_position_id];
            });

        const newSequence = newPositions.reduce(
            (prevSequence, pos) =>
                OfferSequenceService.updateOfferSequence(
                    prevSequence,
                    [{
                        id: pos.tmp_id,
                        groupCode: pos.groupCode,
                    }],
                    'add',
                    IccConfig
                ),
            []
        );

        return {
            newPositions,
            newSequence,
            relations,
        };
    }

    static getDimensions(position, IccConfig) {
        const constructionData =
            position.details && position.details.drawData
            ? (position.configuration.type === 'coupled_window'
               || position.configuration.type === 'roller_shutter'
               || position.configuration.type === 'external_blind'
               && IccConfig.Configurators.roller_shutter.sumRollerAndBoxHeight)
                  && position.details.drawData.base.find(b => !b.positionId)
                    ? position.details.drawData.base.find(b => !b.positionId)
                    : position.details.drawData.reno
                      && position.details.drawData.reno[0]
                    ? position.details.drawData.reno[0]
                    : position.details.drawData.shape
                      && position.details.drawData.shape[0]
                    ? position.details.drawData.shape[0]
                    : null
                : null;

        const area =
            constructionData && constructionData.polyArea
                ? constructionData.polyArea
                : position.details?.width && position.details?.height
                ? (position.details.width * position.details.height) / 1e6
                : 0;

        const circuit =
            constructionData && constructionData.polyEdge
                ? constructionData.polyEdge * 1e3
                : position.details?.shape && position.details?.shape.circuit
                ? position.details.shape.circuit
                : 0;

        const size =
            constructionData && constructionData.rect
                ? `${constructionData.rect.width}x${constructionData.rect.height}`
                : position.details?.width && position.details?.height
                ? `${position.details.width}x${position.details.height}`
                : ``;

        let glazingArea = 0;
        if (position.configuration?.Sashes) {
            position.configuration.Sashes.forEach(sash => {
                glazingArea += parseFloat(sash.glazingSizes.area || 0);

                sash.intSashes.forEach(intSash => {
                    glazingArea += parseFloat(intSash.glazingSizes.area || 0);
                });
            });
        }

        return { area, glazingArea, circuit, size };
    }

    /**
     * Parsuje wartości pozycji przed zwroceniem
     * @param  {object} offer Obiekt pozycji
     * @return {object}       Obiekt sparsowany
     */
    static parsePositionValues(position) {
        return Common.extend(position, {
            configuration: core.parseJson(position.configuration),
            dealer_price: parseFloat(position.dealer_price),
            dealer_price_before_discount: parseFloat(position.dealer_price_before_discount),
            client_price: parseFloat(position.client_price),
            client_price_before_discount: parseFloat(position.client_price_before_discount),
            group_discounts: core.parseJson(position.group_discounts),
            circuit: parseFloat(position.circuit),
            area: parseFloat(position.area),
            weight: parseFloat(position.weight),
            quantity: parseFloat(position.quantity),
        });
    }

    static addPosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {
        const parsedSequence = core.parseJson(offer.sequence);
        const emptyOffer = Object.keys(parsedSequence).length === 0 ? true : false;
        const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
        const newPosition = new Position(Object.assign(params, { groupCode }), IccConfig, dealer, allDiscounts);

        const posObj = {
            id: newPosition.tmp_id,
            groupCode,
        };

        if (!allPositions) {
            allPositions = [{
                doc: newPosition
            }]
        } else {
            allPositions.push({
                doc: newPosition
            });
        }

        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'add',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig)
        }

        return {
            offer: offer,
            positions: allPositions
        };
    }

    static updatePosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {
        if (allPositions) {
            const position = allPositions.find(p => p.doc.tmp_id === params.tmp_id);
            if (params.details) {
                delete position.doc.dealer_price_before_discount;
                delete position.doc.dealer_price;
                delete position.doc.dealer_discount;
                delete position.doc.client_price_before_discount;
                delete position.doc.client_price;
                delete position.doc.client_discount;
                delete position.doc.weight;
                delete position.doc.weight_positions_quantity;
                delete position.doc.configuration;
                delete position.doc.details;
                delete position.doc.confType;
                delete position.doc.size;
                delete position.doc.name;
                delete position.doc.quantity;
                delete position.doc.circuit;
                delete position.doc.area;
            }
            Object.assign(position.doc, params);
            params = position.doc;
        }
        const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
        const newPosition = new Position(Object.assign(params, { groupCode }), IccConfig, dealer, allDiscounts);
        const posObj = {
            id: newPosition.tmp_id,
            groupCode,
        };

        if (!allPositions) {
            allPositions = [{
                doc: newPosition
            }]
        } else {
            allPositions = allPositions.map(pos => {
                if (pos.doc.tmp_id === newPosition.tmp_id) {
                    return {
                        doc: newPosition
                    }
                } else {
                    return pos;
                }
            })
        }
        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'update',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig)
        }

        return {
            offer: offer,
            positions: allPositions
        };
    }

    static removePosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {

        const posObj = {
            id: params.tmp_id,
        };

        allPositions = allPositions.filter(p => p.doc.tmp_id !== params.tmp_id);

        params.offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'remove',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),

        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig)
        }

        return {
            offer: offer,
            positions: allPositions
        };
    }

    static refreshMontages(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?,
        transportCostsTypes?,
        transportCosts?
    ) {

        allPositions = allPositions.filter(p => p.doc.tmp_id !== params.tmp_id);

        params.offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig)
        }

        OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
            null,
            transportCostsTypes,
            transportCosts
        );

        return {
            offer: offer,
            positions: allPositions
        };
    }

    
    static calcMontagesOptions(
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        market,
        cartOptions: {
            montage: boolean;
            montageType: null | string;
            measurePrice: number;
            measurement: boolean;
            personalPickup?: boolean;
        }[],
        allMontages,
        transportCostsTypes?,
        transportCosts?,
        measurement?
    ) {
        offer = core.copy(offer);
        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
        );

        const prices: {
            default: number;
            defaultDiscount: number;
            withMeasurement: number;
            withMeasurementDiscount: number;
            withMeasurementMeasure: number;
            withMeasurementAndMontage: Record<string, number>;
            withMeasurementAndMontageDiscount: number;
            withMeasurementAndMontageMeasure: Record<string, number>;
            personalPickup: number;
            personalPickupDiscount: number;
            transportCostType: null | false | string;
        } = {
            default: 0,
            defaultDiscount: 0,
            withMeasurement: 0,
            withMeasurementDiscount: 0,
            withMeasurementMeasure: 0,
            withMeasurementAndMontage: {},
            withMeasurementAndMontageDiscount: 0,
            withMeasurementAndMontageMeasure: {},
            personalPickup: 0,
            personalPickupDiscount: 0,
            transportCostType: null,
        };

        cartOptions.forEach(option => {
            let offerCopy = core.copy(offer);
            offerCopy.montages = {
                montage: option.montage,
                montageType: option.montageType
            };
            offerCopy.measurement = option.measurement;
            offerCopy.measure_price = option.measurePrice;
            offerCopy.transport_from_producent_to_client = !option.personalPickup;

            if (allMontages) {
                const groups = PositionService.getOfferGroups(offerCopy, allPositions);
                MontagesService.setMontagesDataStatic(
                    offerCopy,
                    groups,
                    allMontages,
                    dealer && dealer.id,
                    market.id,
                    (group: any) => ''
                );
                MontagesService.updateMontages(offerCopy, groups, allPositions, user, market, IccConfig)
            }

            offerCopy = OfferSummaryServiceStatic.autoUpdateOffer(
                offerCopy._id || offerCopy.tmp_id,
                undefined,
                undefined,
                undefined,
                user,
                offerCopy,
                IccConfig,
                allPositions,
                market,
                Boolean(Number(offerCopy.order)),
                null,
                transportCostsTypes,
                transportCosts
            );
            const groupDiscounts = core.parseJson(offerCopy.group_discounts);
            const multiplier = groupDiscounts.reduce((a, b) => a + b.discount, 0);

            const measurePrice = (measurement?.price || 0) + (measurement?.supplement || 0) / 100 * offerCopy.client_price;
            offerCopy.measure_price = offerCopy.measurement ? measurePrice : 0;
            option.measurePrice = offerCopy.measure_price;

            if (option.montage && option.montageType) {
                prices.withMeasurementAndMontage[option.montageType] = !offerCopy.valuation ? offerCopy.client_price + (option.measurePrice || 0) : 0;
                prices.withMeasurementAndMontageDiscount = multiplier;
                prices.withMeasurementAndMontageMeasure[option.montageType] = option.measurePrice;
            } else if (option.measurement) {
                prices.withMeasurement = !offerCopy.valuation ? offerCopy.client_price + (option.measurePrice || 0) : 0;
                prices.withMeasurementDiscount = multiplier;
                prices.withMeasurementMeasure = option.measurePrice;
            } else if (option.personalPickup) {
                prices.personalPickup = !offerCopy.valuation ? offerCopy.client_price : 0;
                prices.personalPickupDiscount = multiplier;
            } else {
                prices.default = !offerCopy.valuation ? offerCopy.client_price : 0;
                prices.defaultDiscount = multiplier;
            }

            if (offerCopy.transport_cost_type || !prices.transportCostType) {
                prices.transportCostType = offerCopy.transport_cost_type;
            }
        });

        return prices;
    }

    static getOfferGroups(offer, positions) {
        const groups = [];
        for (let i = 0; i < offer.sequence.length; i++) {
            for (const key in offer.sequence[i]) {
                if (offer.sequence[i].hasOwnProperty(key)) {
                    const pos = positions.filter(
                        e =>
                            offer.sequence[i][key].indexOf(e.doc.tmp_id) > -1
                            && !e.doc.coupled_position_id
                    );
                    if (pos.length) {
                        groups.push({
                            groupCode: pos[0].doc.groupCode,
                            confType: pos[0].doc.confType,
                            rows: pos,
                            groupData: offer.positions_groups_data[pos[0].doc.groupCode],
                            groupEdition: 0,
                        });
                    }
                }
            }
        }
        return groups;
    }
}
