import { core } from '@icc/common/helpers';
import { Alignment, ShapeType, Muntin, GlazingBeadProfile, Sash, FrameProfile } from '@icc/window';
import { ActiveMullion } from './active-mullion';
import { IccSashType, IccFilling, IccAccessoryColor, IccColors, IccWood } from '@icc/common/data-types';
import { MosquitoActiveConfiguration } from '../configurations/MosquitoActiveConfiguration';
import { IccHandle } from '../data-types/Handle';
import { ActiveAccessory } from '../configurations/parts/common/ActiveAccessory';
import { Point } from '@icc/helpers';

/**
 * Kwatera/skrzydło
 */
export class ActiveSash {
    id: number;
    parentId?: number | null;
    frameId?: number | null;
    index: number;
    rx: number;
    ry: number;
    rWidth: number;
    rHeight: number;
    drawing: any[];
    type?: IccSashType;
    muntins: Muntin[];
    intSashes: ActiveSash[] = [];
    intMullions: ActiveMullion[] = [];
    intEdgeSashes: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    } = {
        top: [],
        left: [],
        right: [],
        bottom: [],
    };
    intAlignments: Alignment[] = [];
    nearMullions: { left: number; right: number; top: number; bottom: number };
    nearAlignments: { left: number; right: number; top: number; bottom: number };
    glazing: Partial<IccFilling> & {
        selectedColor?: IccColors;
        selectedColorSecond?: IccColors;
        selectedWood?: IccWood;
    };
    glazingBead: Partial<GlazingBeadProfile>;
    panelGlazing: Partial<IccFilling>;
    panelInner?: (Partial<IccFilling> & {
        selectedColor?: IccColors;
        selectedColorSecond?: IccColors;
        selectedWood?: IccWood;
    }) | null = null;
    bondedGlazing: boolean;
    panelType: 'Inset' | 'Outer' | 'Double' | 'Inner' | 'Mixed';
    oneGlazing: boolean;
    oneFilling: {
        window: boolean;
        doorActive: boolean;
        doorPassive: boolean;
        doorTopLight: boolean;
        doorLeftLight: boolean;
        doorRightLight: boolean;
    };
    glazingSizes: { width: number; height: number; area: number; x: number; y: number };
    margins: { bottom: number; left: number; right: number; top: number };
    mosquito: MosquitoActiveConfiguration | null;
    frame: {
        top: FrameProfile;
        bottom: FrameProfile;
        left: FrameProfile;
        right: FrameProfile;
    };
    handle: IccHandle | null;
    handleOuter: IccHandle | null;
    handleColor: Partial<IccAccessoryColor>;
    handleOuterColor: Partial<IccAccessoryColor>;
    handleY: number;
    rHandleY: number;
    defaultHandleHeight: boolean;
    handleHeightType: 'standard' | 'nonstandard' | 'middle';
    hardware: ActiveAccessory[];
    shape: {
        points?: Point[];
        shape?: ShapeType;
        width?: number;
        height?: number;
        x?: number;
        y?: number;
        figure?: {};
        d?: number;
        radius?: number;
        angles?: {
            hingeSide: number[];
            nonHingeSide: number[];
            hasLeftSlopeSide?: boolean;
            hasRightSlopeSide?: boolean;
        };
        valid?: boolean;
    };
    oneGlazingBead: boolean | number;
    oneBondedGlazing = true;
    canHaveDuplex: boolean;
    weight = 0;
    /**
     * Tworzy nową kwaterę/skrzydło.
     *
     * @param {number} id Id nowego skrzydła
     * @param {Object} param Dane kwatery
     * @param {number} param.parentId Id kwatery w której jest skrzydło
     * @param {number} param.rx Wsp. x kwatery w mm
     * @param {number} param.ry Wsp. y kwatery w mm
     * @param {number} param.rWidth Szerokość kwatery w mm
     * @param {number} param.rHeight Wysokość kwatery w mm
     * @param {Object} param.type Typ kwatery/skrzydła
     * @param {number} param.divs Id poprzeczek/słupków sąsiadujących z kwaterą
     * @param {number} param.divs.left Id poprzeczki/słupka będącego po lewej stronie kwatery
     * @param {number} param.divs.right Id poprzeczki/słupka będącego po prawej stronie kwatery
     * @param {number} param.divs.bottom Id poprzeczki/słupka będącego na górze kwatery
     * @param {number} param.divs.left Id poprzeczki/słupka będącego na dole kwatery
     * @param {Sash} parentSash Kwatera nadrzędna
     */
    constructor(
        id: number,
        param: {
            parentId?: number;
            frameId?: number;
            index?: number;
            rx: number;
            ry: number;
            rWidth: number;
            rHeight: number;
            type?: IccSashType;
            divs: {
                left: number;
                right: number;
                top: number;
                bottom: number;
            };
            nearAlignments?: {
                left: number;
                right: number;
                top: number;
                bottom: number;
            };
            frame?: any;
            bondedGlazing?: boolean;
            panelType?: 'Inset' | 'Outer' | 'Double' | 'Inner' | 'Mixed';
            panelGlazing?: Partial<IccFilling>;
            panelInner?: Partial<IccFilling>;
        }
    ) {
        /**
         * Id
         * @type {number}
         */
        this.id = id;
        /**
         * Id kwatery w której jest skrzydło
         * @type {number|null}
         */
        this.parentId = typeof param.parentId === 'undefined' ? null : param.parentId;

        this.frameId = typeof param.frameId === 'undefined' ? null : param.frameId;
        /**
         * Indeks
         */
        this.index = typeof param.index === 'undefined' ? null : param.index;
        /**
         * Wsp. x kwatery w mm
         * @type {number}
         */
        this.rx = param.rx;
        /**
         * Wsp. y kwatery w mm
         * @type {number}
         */
        this.ry = param.ry;
        /**
         * Szerokość kwatery w mm
         * @type {number}
         */
        this.rWidth = param.rWidth;
        /**
         * Wysokość kwatery w mm
         * @type {number}
         */
        this.rHeight = param.rHeight;
        /**
         * Elementy kwatery dla rysunku
         * @type {Array}
         */
        this.drawing = [];

        /**
         * Typ kwatery/skrzydła
         * @type {Object}
         */
        this.type = core.copy(param.type);
        /**
         * Szprosy w kwaterze
         * @type {Muntin[]}
         */
        this.muntins = [];
        /**
         * Skrzydła w kwaterze
         * @type {Sash[]}
         */
        this.intSashes = [];
        /**
         * Słupki/poprzeczki w kwaterze
         * @type {ActiveMullion[]}
         */
        this.intMullions = [];
        /**
         * Skrzydła brzegowe w kwaterze
         * @type {{top: Sash[], bottom: Sash[], left: Sash[], right: Sash[]}}
         */
        this.intEdgeSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };

        /**
         * Wyrównania w kwaterze
         * @type {Alignment[]}
         */
        this.intAlignments = [];
        /**
         * Id poprzeczek/słuków sąsiadujacych z kwaterą
         * @type {{left: number, right: number, top: number, bottom: number}}
         */
        this.nearMullions = {
            left: typeof param.divs.left === 'undefined' ? -1 : param.divs.left,
            right: typeof param.divs.right === 'undefined' ? -1 : param.divs.right,
            top: typeof param.divs.top === 'undefined' ? -1 : param.divs.top,
            bottom: typeof param.divs.bottom === 'undefined' ? -1 : param.divs.bottom,
        };

        this.nearAlignments = {
            left:
                !param.nearAlignments || typeof param.nearAlignments.left === 'undefined'
                    ? -1
                    : param.nearAlignments.left,
            right:
                !param.nearAlignments || typeof param.nearAlignments.right === 'undefined'
                    ? -1
                    : param.nearAlignments.right,
            top:
                !param.nearAlignments || typeof param.nearAlignments.top === 'undefined'
                    ? -1
                    : param.nearAlignments.top,
            bottom:
                !param.nearAlignments || typeof param.nearAlignments.bottom === 'undefined'
                    ? -1
                    : param.nearAlignments.bottom,
        };
        /**
         * Czy wyświetlać ikony do edycji kwatery
         * @type {boolean}
         */
        this.glazing = {};
        this.glazingBead = {};
        this.panelGlazing = param.panelGlazing  || {};
        this.panelInner = param.panelInner  || {};
        this.bondedGlazing = param.bondedGlazing || false;
        this.panelType = param.panelType || 'Inset';
        /**
         * Czy w kwaterze jest tylko jeden rodzaj szklenia
         * @type {boolean}
         */
        this.oneGlazing = true;
        this.oneFilling = {
            window: true,
            doorActive: true,
            doorPassive: true,
            doorTopLight: true,
            doorLeftLight: true,
            doorRightLight: true
        };
        this.oneGlazingBead = true;
        this.glazingSizes = {
            width: 0,
            height: 0,
            area: 0,
            x: 0,
            y: 0,
        };
        this.margins = {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
        };

        /**
         * Obsluga siatek w skrzydle
         */
        this.mosquito = null;

        /**
         * profil skrzydła
         * @type {Object}
         */
        this.frame = param.frame;

        this.handle = {};
        this.handleOuter = {};
        this.handleColor = {};
        this.handleOuterColor = {};
        this.handleY = null;
        this.rHandleY = null;
        this.defaultHandleHeight = true;
        this.handleHeightType = 'standard';
        this.hardware = [];
        this.shape = {
            points: [],
            shape: 'rect',
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            figure: {},
            d: 0,
            radius: 0,
            angles: {
                hingeSide: [],
                nonHingeSide: [],
            },
            valid: true,
        };
        this.canHaveDuplex = true;
    }
}
