import { Component, OnInit, OnChanges, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { ProfileSet, ProfileShape } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesService, ProfileSetsService, TranslateService } from '@icc/common';
import { core } from '@icc/common/helpers';
import { SharedFacade } from '@icc/configurator/shared';
import { ProfilesModalService } from '../profiles-modal.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'icc-profile-sets-page',
    template: require('./profile-sets-page.component.html'),
    styles: [require('./profile-sets-page.component.scss')],
})
export class ProfileSetsPageComponent extends PageComponent implements OnInit {
    public title = 'WINDOW|Zestaw profili';
    public options = [];

    selectedSetId: any;
    selectedSet = {
        frameTop: {
            id: null,
            name: '',
            img: '',
        },
        frameBottom: {
            id: null,
            name: '',
            img: '',
        },
        frameSide: {
            id: null,
            name: '',
            img: '',
        },
        sash: {
            id: null,
            name: '',
            img: '',
        },
        fixedMullion: {
            id: null,
            name: '',
            img: '',
        },
        falseMullion: {
            id: null,
            name: '',
            img: '',
        },
        threshold: {
            id: null,
            name: '',
            img: '',
        },
        sashOutward: {
            id: null,
            name: '',
            img: '',
        },
        centralHandleFalseMullion: {
            id: null,
            name: '',
            img: '',
        },
        doubleHandleFalseMullion: {
            id: null,
            name: '',
            img: '',
        },
        zMullion: {
            id: null,
            name: '',
            img: '',
        },
        glazingBeadShape: {
            id: null,
            name: '',
            img: '',
        },
    };

    profileTypes = [
        'frameTop',
        'frameBottom',
        'frameSide',
        'sash',
        'fixedMullion',
        'falseMullion',
        'threshold',
        'sashOutward',
        'centralHandleFalseMullion',
        'doubleHandleFalseMullion',
        'zMullion',
        'glazingBeadShape',
    ];

    profileTypesTitle = {
        frameTop: this.translateService.instant('WINDOW|Rama góra'),
        frameBottom: this.translateService.instant('WINDOW|Rama dół'),
        frameSide: this.translateService.instant('WINDOW|Rama boki'),
        threshold: this.translateService.instant('WINDOW|Próg'),
        sash: this.translateService.instant('WINDOW|Skrzydło'),
        sashOutward: this.translateService.instant('WINDOW|Skrzydło otwierane na zewnątrz'),
        fixedMullion: this.translateService.instant('WINDOW|Słupek stały'),
        zMullion: this.translateService.instant('WINDOW|Słupek stały typu Z'),
        falseMullion: this.translateService.instant('WINDOW|Słupek ruchomy'),
        centralHandleFalseMullion: this.translateService.instant(
            'WINDOW|Słupek ruchomy z centralna klamką'
        ),
        doubleHandleFalseMullion: this.translateService.instant(
            'WINDOW|Słupek ruchomy z dwoma klamkami'
        ),
        glazingBeadShape: this.translateService.instant('WINDOW|Kształt listwy przyszybowej'),
    };



    constructor(
        private profilesService: ProfilesService,
        private profileSetsService: ProfileSetsService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        public resolve: {
            profileSets: ProfileSet[];
            profileShapes: ProfileShape[];
            selectedProfileSet: ProfileSet;
            defaultProfileSet: ProfileSet;
            conf: WindowActiveConfiguration,
            selectedSet$: Subject<{
                selectedSet: any,
                selectedProfileSet: ProfileSet
            }>
        }
    ) {
        super();
    }

    ngOnInit() {
        this.setProfiles();
        this.resolve.selectedSet$.subscribe((set) => {
            this.selectedSet = set.selectedSet;
            this.resolve.selectedProfileSet = set.selectedProfileSet;
        });
    }

    private setProfiles() {
        if (this.resolve.selectedProfileSet) {
            this.selectedSetId = this.resolve.selectedProfileSet.id;
            this.profileTypes.forEach(type => {
                let profile;
                if (type === 'glazingBeadShape') {
                    profile = this.profilesService.getProfileShape(this.resolve.selectedProfileSet[type]);
                }
                else {
                    profile = this.profilesService.getProfile(this.resolve.selectedProfileSet[type]);
                }
                if (profile) {
                    this.selectedSet[type] = {
                        id: profile.id,
                        name: profile.name,
                        img: profile.img,
                    };
                }
            });
        }
    }

    changeSet(setId: number | null) {
        this.selectedSetId = setId;
        const matchedSet = this.profileSetsService.getProfileSet(this.selectedSetId);
        this.resolve.selectedProfileSet = core.copy(matchedSet);
        if (matchedSet) {
            this.resolve.selectedProfileSet.isDefault =
                matchedSet.id === this.resolve.defaultProfileSet.id
                && this.resolve.defaultProfileSet.id !== null;
            this.removeNotstandardSet();
            this.profileTypes.forEach(type => {
                let profile;
                if (type === 'glazingBeadShape') {
                    profile = this.profilesService.getProfileShape(matchedSet[type]);
                } else {
                    profile = this.profilesService.getProfile(matchedSet[type]);
                }
                if (profile) {
                    this.selectedSet[type] = {
                        id: profile.id,
                        name: profile.name,
                        img: profile.img,
                    };
                }
            });
        }
    }

    changeProfile(type, profileId) {
        const profileTypes = {
            frameTop: {
                type: 'frame',
                options: {
                    and: ['top'],
                },
            },
            frameBottom: {
                type: 'frame',
                options: {
                    and: ['bottom'],
                },
            },
            frameSide: {
                type: 'frame',
                options: {
                    and: ['side'],
                },
            },
            sash: {
                type: 'sash',
                options: {
                    not: ['outward_opening'],
                },
            },
            fixedMullion: {
                type: 'fixed_mullion',
                options: {
                    not: ['type_z'],
                },
            },
            falseMullion: {
                type: 'false_mullion',
                options: {
                    not: ['central_handle'],
                },
            },
            threshold: {
                type: 'threshold',
            },
            sashOutward: {
                type: 'sash',
                options: {
                    and: ['outward_opening'],
                },
            },
            centralHandleFalseMullion: {
                type: 'false_mullion',
                options: {
                    and: ['central_handle'],
                },
            },
            doubleHandleFalseMullion: {
                type: 'false_mullion',
                options: {
                    and: ['double_handle'],
                },
            },
            zMullion: {
                type: 'fixed_mullion',
                options: {
                    and: ['type_z'],
                },
            },
            glazingBeadShape: {
                type: 'glazingBeadShape',
            },
        };
        const shapes = this.profilesService.getProfileShapes();
        const shapesArray = Object.keys(shapes).map(i => shapes[i]);

        if (type === 'glazingBeadShape') {
            this.profilesService
                .openProfilesShapesModal(
                    this.profilesService.getProfileShape(profileId),
                    shapesArray
                )
                .then(selectedProfile => {
                    if (selectedProfile) {
                        this.selectedSet[type].id = selectedProfile.id;
                        this.selectedSet[type].name = selectedProfile.name;
                        this.selectedSet[type].img = selectedProfile.img;
                        this.resolve.selectedProfileSet.id = null;
                        this.resolve.selectedProfileSet.isDefault = false;
                        this.selectedSetId = null;
                        this.addNotstandardSet();
                        this.resolve.selectedSet$.next({
                            selectedSet: this.selectedSet,
                            selectedProfileSet: this.resolve.selectedProfileSet
                        });
                    }
                });
        } else {
            this.profilesModalService
                .openProfilesModal(
                    this.resolve.conf,
                    profileTypes[type].type,
                    profileTypes[type].options,
                    this.profilesService.getProfile(profileId)
                )
                .then(selectedProfile => {
                    if (selectedProfile) {
                        this.selectedSet[type].id = selectedProfile.id;
                        this.selectedSet[type].name = selectedProfile.name;
                        this.selectedSet[type].img = selectedProfile.img;
                        this.resolve.selectedProfileSet.id = null;
                        this.resolve.selectedProfileSet.isDefault = false;
                        this.selectedSetId = null;
                        this.addNotstandardSet();
                        this.resolve.selectedSet$.next({
                            selectedSet: this.selectedSet,
                            selectedProfileSet: this.resolve.selectedProfileSet
                        });
                    }
                });
        }
    }

    closeModal() {
        const newProfileSet: ProfileSet = {
            id: this.resolve.selectedProfileSet.id,
            name: this.resolve.selectedProfileSet.name,
            frameTop: null,
            frameBottom: null,
            frameSide: null,
            sash: null,
            fixedMullion: null,
            falseMullion: null,
            threshold: null,
            sashOutward: null,
            centralHandleFalseMullion: null,
            doubleHandleFalseMullion: null,
            zMullion: null,
            glazingBeadShape: null,
            systems: this.resolve.selectedProfileSet.systems,
            isDefault: this.resolve.selectedProfileSet.isDefault,
        };
        this.profileTypes.forEach(type => {
            newProfileSet[type] = this.selectedSet[type].id;
        });
        this.sharedFacade.closePage(newProfileSet);
    }

    addNotstandardSet() {
        if (!this.resolve.profileSets.find(set => set.id === null)) {
            this.resolve.profileSets.push({
                id: null,
                name: this.translateService.instant('CONFIGURATOR|Niestandardowe'),
                frameTop: null,
                frameBottom: null,
                frameSide: null,
                sash: null,
                fixedMullion: null,
                falseMullion: null,
                threshold: null,
                sashOutward: null,
                centralHandleFalseMullion: null,
                doubleHandleFalseMullion: null,
                zMullion: null,
                glazingBeadShape: null,
                systems: [],
                isDefault: false,
            });
        }
    }

    removeNotstandardSet() {
        this.resolve.profileSets = this.resolve.profileSets.filter(set => set.id !== null);
    }
}
