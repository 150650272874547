import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { IccFilling } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { BehaviorSubject } from 'rxjs';
import { FillingsFacade } from '../glazings/fillings.facade';
import { PanelOptionsFacade } from './panel-options.facade';

@Component({
    selector: 'icc-deco-panel-options-page',
    template: require('./deco-panel-options-page.component.html'),
    styles: [require('./deco-panel-options-page.component.scss')],
})
export class DecoPanelOptionsPageComponent extends PageComponent implements OnInit {
    public title = _('INTERFACE|Opcje panelu ozdobnego');
    public options = [];

    public panelGlazingBox: ReturnType<
        PanelOptionsFacade['panelGlazingBox']
    > = this.panelOptionsFacade.panelGlazingBox(
        this.configurationsService.conf.Current,
        this.pageData.selGlazing
    );
    public selectedPanelGlazing: ActiveSash['panelGlazing'] = {};
    public panelType: ActiveSash['panelType'] = 'Inset';
    public selectedFilling: ActiveSash['glazing'] = {};

    constructor(
        private sharedFacade: SharedFacade,
        private panelOptionsFacade: PanelOptionsFacade,
        private configurationsService: ConfigurationsService<'door'>,
        @Inject(ICC_PAGE_DATA)
        public pageData: {
            fillings: IccFilling[];
            fillingsCategories: any[];
            modalData: any;
            selGlazing: ActiveSash['panelGlazing'];
            panelType: ActiveSash['panelType'];
            filling: ActiveSash['glazing'];
            filteredFillings: IccFilling[];
            openFillingModal: (
                panelGlazings: IccFilling[],
                selectedFilling: Partial<IccFilling>,
                conf: WindowActiveConfiguration
            ) => PromiseLike<any>;
            options$: BehaviorSubject<{
                selGlazing: ActiveSash['panelGlazing'];
                panelType: ActiveSash['panelType'];
                filling: ActiveSash['glazing'];
            }>;
        }
    ) {
        super();
        this.selectedPanelGlazing = this.pageData.selGlazing;
        this.panelType = this.pageData.panelType;
        this.pageData.options$.subscribe(data => {
            this.selectedPanelGlazing = data.selGlazing;
            this.panelType = data.panelType;
            this.selectedFilling = data.filling;
            this.panelGlazingBox = this.panelOptionsFacade.panelGlazingBox(
                this.configurationsService.conf.Current,
                data.selGlazing
            );
        });
    }

    ngOnInit() {}

    changePanelGlazing() {
        this.pageData
            .openFillingModal(
                this.pageData.fillings,
                this.pageData.selGlazing,
                this.configurationsService.conf.Current
            )
            .then(result => {
                if (result) {
                    this.pageData.options$.next({
                        selGlazing: result.glazing,
                        panelType: this.panelType,
                        filling: this.selectedFilling,
                    });
                }
            });
    }

    setPanelType(panelType: ActiveSash['panelType'], filling: IccFilling) {
        this.panelType = panelType;
        this.selectedFilling = filling;
    }

    save() {
        this.sharedFacade.closePage({
            selGlazing: this.selectedPanelGlazing,
            panelType: this.panelType,
            filling: this.selectedFilling,
        });
    }
}
