import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, isNotNullOrUndefined } from '@icc/helpers';
import { SharedFacade } from '@icc/configurator/shared';
import { IccMaterial } from '@icc/common/data-types';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { IIccConfig } from '@icc/config';

export interface SystemFilters {
    type: IccMaterial;
    groupId: number;
    tags: string[];
}

@Component({
    selector: 'icc-system-filters',
    template: require('./system-filters.component.html'),
    styles: [require('./system-filters.component.scss')],
})
export class SystemFiltersComponent extends PageComponent implements OnInit {
    public title = 'Filtry';

    filtersGroup = this.fb.group({
        type: [''],
        groupId: [''],
        tags: this.fb.array([])
    });

    systemTypes: any[] = [];
    systemGroups: any[] = [];
    systemTags: any[] = [];

    constructor(
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            filters: SystemFilters;
            systemTypes: any[];
            systemGroups: any[];
            systemTags: any[];
        },
        private sharedFacade: SharedFacade,
        private fb: FormBuilder
    ) {
        super();
        pageData.systemTags.forEach(tag => {
            const formArray = this.filtersGroup.get('tags') as FormArray;
            if (formArray) {
                formArray.push(new FormControl(false))
            }
        });
    }

    ngOnInit() {
        this.systemTypes = this.pageData.systemTypes;
        this.systemGroups = this.pageData.systemGroups;
        this.systemTags = this.pageData.systemTags;
        if (this.pageData.filters) {
            this.filtersGroup.setValue({

                    type: this.pageData.filters.type || '',
                    groupId: this.pageData.filters.groupId || '',
                    tags: this.systemTags.map(tag => this.pageData.filters.tags.includes(tag.id))
            });
        }
    }
    save() {
        this.sharedFacade.closeFilterSheet({
            filters: {
                ...this.filtersGroup.value,
                tags: this.filtersGroup.value.tags.map((checked: boolean, i: number) => checked ? this.systemTags[i].id : null).filter(isNotNullOrUndefined)
            }
        });
    }
}
