import { PriceFunc, PriceElemsData, PriceSegment, getConstrElems } from './Prices';
import { PriceColorsService } from './price-colors.service';
import { Injectable, Inject } from '@angular/core';
import { Coupling, Frame } from '@icc/window';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Common } from '@icc/common/Common';
import { TranslateService } from '@icc/common/translate.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { EventBusService } from '@icc/common/event-bus.service';
import { core } from '@icc/common/helpers';
import { ProfileSetsService } from '@icc/common/profile-sets.service';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { PriceShapeService } from './price-shape.service';
import { ConstructionCodeService } from '@icc/common/layout/construction-code.service';
import { PriceGlazingService } from './price-glazing.service';

@Injectable()
export class PriceSashService {
    constructor(
        private translateService: TranslateService,
        private PriceColorsService: PriceColorsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private IssuesService: IssuesService,
        private colorsDefaultsService: ColorsDefaultsService,
        private EventBusService: EventBusService,
        private profileSetsService: ProfileSetsService,
        private priceShapeService: PriceShapeService,
        private priceGlazingService: PriceGlazingService,
        private constructionCodeService: ConstructionCodeService
    ) {}

    biInterpolate(x1, x2, y1, y2, x, y, Q11, Q12, Q21, Q22) {
        const f1 = 1 / ((x2 - x1) * (y2 - y1));
        const f2 = Q11 * (x2 - x) * (y2 - y);
        const f3 = Q21 * (x - x1) * (y2 - y);
        const f4 = Q12 * (x2 - x) * (y - y1);
        const f5 = Q22 * (x - x1) * (y - y1);
        return f1 * (f2 + f3 + f4 + f5);
    }

    lInterpolate(x1, x2, y1, y2, x) {
        const f1 = ((x - x1) * (y2 - y1)) / (x2 - x1);
        return f1 + parseFloat(y1);
    }

    tInterpolate(x1, x2, y1, y2, x, y, Q11, Q12, Q21, Q22) {
        x1 = parseInt(x1);
        x2 = parseInt(x2);
        y1 = parseInt(y1);
        y2 = parseInt(y2);
        x = parseInt(x);
        y = parseInt(y);
        Q11 = parseFloat(Q11);
        Q12 = parseFloat(Q12);
        Q21 = parseFloat(Q21);
        Q22 = parseFloat(Q22);
        if (x === x1 && y === y1) {
            return Q11;
        }
        if (x === x2 && y === y2) {
            return Q22;
        }
        if (x === x1 && y === y2) {
            return Q21;
        }
        if (x === x1 && y === y1) {
            return Q12;
        }

        let f1, f2, f3, f4, xy, yx, w, z;
        if (
            core.isNumberAndNotZero(Q11)
            && !core.isNumberAndNotZero(Q12)
            && core.isNumberAndNotZero(Q21)
            && core.isNumberAndNotZero(Q22)
        ) {
            f1 = this.lInterpolate(x1, x2, Q21, Q22, x);
            f2 = this.lInterpolate(y1, y2, Q11, Q21, y);
            f3 = this.lInterpolate(x1, x2, Q11, Q22, x);
            f4 = this.lInterpolate(y1, y2, Q11, Q22, y);
            yx = (y * (f3 - Q22) + y2 * (f4 - f3)) / (f4 - Q22);
            xy = ((f4 - f3) * (x2 - x)) / (Q22 - f3) + x;
            w = this.lInterpolate(x1, xy, f2, f4, x);
            z = this.lInterpolate(yx, y2, f3, f1, y);
        } else if (
            core.isNumberAndNotZero(Q11)
            && core.isNumberAndNotZero(Q12)
            && core.isNumberAndNotZero(Q21)
            && !core.isNumberAndNotZero(Q22)
        ) {
            f1 = this.lInterpolate(x1, x2, Q11, Q12, x);
            f2 = this.lInterpolate(y1, y2, Q11, Q21, y);
            f3 = this.lInterpolate(x1, x2, Q21, Q12, x);
            f4 = this.lInterpolate(y1, y2, Q12, Q21, y);
            yx = (y * (f3 - Q12) + y1 * (f4 - f3)) / (f4 - Q12);
            xy = -((f3 - f4) * (x - x2)) / (f3 - Q12) + x;
            w = this.lInterpolate(x1, xy, f2, f4, x);
            z = this.lInterpolate(yx, y1, f3, f1, y);
        } else if (
            !core.isNumberAndNotZero(Q11)
            && core.isNumberAndNotZero(Q12)
            && core.isNumberAndNotZero(Q21)
            && core.isNumberAndNotZero(Q22)
        ) {
            f1 = this.lInterpolate(x1, x2, Q21, Q22, x);
            f2 = this.lInterpolate(y1, y2, Q12, Q22, y);
            f3 = this.lInterpolate(x1, x2, Q21, Q12, x);
            f4 = this.lInterpolate(y1, y2, Q12, Q21, y);
            yx = (y * (Q21 - f3) + y2 * (f3 - f4)) / (Q21 - f4);
            xy = -((f3 - f4) * (x - x2)) / (f3 - Q12) + x;
            w = this.lInterpolate(xy, x2, f4, f2, x);
            z = this.lInterpolate(yx, y2, f3, f1, y);
        } else if (
            core.isNumberAndNotZero(Q11)
            && core.isNumberAndNotZero(Q12)
            && !core.isNumberAndNotZero(Q21)
            && core.isNumberAndNotZero(Q22)
        ) {
            f1 = this.lInterpolate(x1, x2, Q11, Q12, x);
            f2 = this.lInterpolate(y1, y2, Q12, Q22, y);
            f3 = this.lInterpolate(x1, x2, Q11, Q22, x);
            f4 = this.lInterpolate(y1, y2, Q11, Q22, y);
            yx = (y * (f3 - Q11) + y1 * (f4 - f3)) / (f4 - Q11);
            xy = -((f3 - f4) * (x - x1)) / (f3 - Q11) + x;
            w = this.lInterpolate(x2, xy, f2, f4, x);
            z = this.lInterpolate(yx, y1, f3, f1, y);
        }

        return w || z;
    }

    /**
     * Zwraca cenniki dla wybranego systemu.
     * @param  {object}  priceDepends  Zalezności między liniami, skrzydłami, kolorami i cennikami.
     * @param  {array}   system        System
     * @param  {object}  colors        Kolory
     * @param  {object}  NoPriceCauses Powody braku ceny
     * @return {array}                 Cenniki
     */
    getPricesForSystem(priceDepends, system, colors, sashesCount, NoPriceCauses) {
        const colorPairs = this.colorsDefaultsService.getColorPairs(colors);
        const pricesMaps = {};
        if (Common.isObject(priceDepends)) {
            if (Common.isDefined(priceDepends[system.id + '|_'])) {
                Common.extend(pricesMaps, priceDepends[system.id + '|_']);
            }
            for (let i = 0; i < colorPairs.length; i++) {
                if (Common.isDefined(priceDepends[system.id + '|' + colorPairs[i]])) {
                    Common.extend(pricesMaps, priceDepends[system.id + '|' + colorPairs[i]]);
                }
            }
            return pricesMaps;
        }
        NoPriceCauses.push('no prices for system');
    }

    /**
     * Cena z cennika rastrowego dla danego skrzydła.
     * @param  {object} priceData     Mapowanie cenników
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} matrixes      Rastry
     * @return {number}               cena za skrzydło
     */
    priceForRect(
        priceData,
        balcony,
        PriceElems,
        NoPriceCauses,
        matrixes,
        ruSupp,
        interpolate,
        conf,
        profileSets
    ) {
        let priceId, priceSetId;
        if (Common.isObject(priceData) && Common.isObject(priceData.id)) {
            const matchingSetsIds = this.profileSetsService.getProfileSetsForConstructionPart(
                priceData,
                conf,
                profileSets
            );
            const setsFromPrices = Object.keys(priceData.id).map(setId => {
                if (Number(setId) === 0) {
                    setId = conf.System.default_profile_set_id;
                    const profileSetsForSystem = this.profileSetsService.getProfileSetsForSystem(
                        conf.System.id
                    );
                    if (!setId && profileSetsForSystem.length > 0) {
                        setId = String(profileSetsForSystem[0].id);
                    }
                    priceData.id[setId] = priceData.id[0];
                    delete priceData.id[0];
                }
                return setId;
            });
            const matchingPricesToSets = setsFromPrices.filter(
                setId => matchingSetsIds.indexOf(Number(setId)) > -1
            );
            const matchingPricesToSet = setsFromPrices.filter(
                setId => conf.ProfileSet && conf.ProfileSet.id === Number(setId)
            );
            if (matchingPricesToSets.length > 0) {
                priceSetId = matchingPricesToSets[0];
            } else if (matchingPricesToSet.length > 0) {
                priceSetId = matchingPricesToSet[0];
            } else {
                priceSetId = setsFromPrices[0];
            }
            const pricesForSet = priceData.id[priceSetId];

            const fillingsFromPrices = Object.keys(pricesForSet);
            const fillingsIds = this.priceGlazingService.getFillingsForConstructionPart(priceData, conf);
            const matchingPricesToFillings = fillingsFromPrices.filter(
                fillingId => fillingsIds.indexOf(Number(fillingId)) > -1
            );

            const pricesForFilling = pricesForSet[matchingPricesToFillings[0] || '_'];

            if (Common.isDefined(pricesForFilling.one)) {
                priceId = pricesForFilling.one;
            } else {
                if (priceData.height < pricesForFilling.over.from && !pricesForFilling.options.win.onlyDimensions) {
                    priceId = pricesForFilling.win;
                } else if (
                    priceData.height >= pricesForFilling.over.from
                    && priceData.height <= pricesForFilling.over.to
                ) {
                    if (balcony && !pricesForFilling.options.bal.onlyDimensions) {
                        priceId = pricesForFilling.bal;
                    } else {
                        priceId = pricesForFilling.win;
                    }
                } else {
                    priceId = pricesForFilling.bal;
                }
            }
        }
        const noPrice = [
            {
                baseValue: null,
                value: null,
                type: 'sashes',
                valueType: 'value',
                data: {},
            },
        ] as PriceSegment[];
        if (Common.isDefined(priceId) && priceId !== null) {
            const price = matrixes[priceId];
            if (Common.isDefined(price) && price !== null && Common.isArray(price.data)) {
                let priceF = NaN;
                if (interpolate) {
                    priceF = this.priceForRectInterpolated(priceData, price.data);
                } else {
                    const priceField = price.data.filter(function(el) {
                        return (
                            el.height_from <= priceData.height
                            && el.height_to >= priceData.height
                            && el.width_from <= priceData.width
                            && el.width_to >= priceData.width
                        );
                    });
                    if (Common.isArray(priceField) && priceField.length > 0) {
                        priceF = parseFloat(priceField[0].price);
                    }
                }

                if (!isNaN(priceF)) {
                    PriceElems.sashes.push({
                        code: priceData.code,
                        price: priceF,
                        priceId,
                        height: priceData.height,
                        width: priceData.width,
                    });
                    if (priceData.ru - price.ru_count - priceData.rus > 0) {
                        PriceElems.sashTypes.push({
                            sashId: null,
                            type: 'DK',
                            typeId: null,
                            height: priceData.height,
                            width: priceData.width,
                            count: priceData.ru - price.ru_count - priceData.rus,
                        });
                    }
                    return [
                        {
                            baseValue: priceF,
                            value: priceF,
                            type: 'sashes',
                            valueType: 'value',
                            data: {
                                code: priceData.code,
                                priceId,
                                height: priceData.height,
                                width: priceData.width,
                                types: priceData.types,
                                typesSymbol: priceData.typesSymbol,
                                overRuCount: priceData.ru - price.ru_count - priceData.rus,
                                divIds: priceData.divIds,
                                sashIds: priceData.sashIds,
                                profileSetId: priceSetId,
                            },
                        },
                    ] as PriceSegment[];
                } else {
                    NoPriceCauses.push('no price in matrix');
                    return noPrice;
                }
            } else {
                NoPriceCauses.push('no matrix');
                return noPrice;
            }
        }
    }

    priceForRectInterpolated(priceData, prices) {
        const sashPriceAll = prices.filter(function(el) {
            return (
                el.height_from <= priceData.height
                && el.height_to >= priceData.height
                && el.width_from <= priceData.width
                && el.width_to >= priceData.width
            );
        });
        let sashPrice = NaN;
        if (!Common.isUndefined(sashPriceAll) && !Common.isUndefined(sashPriceAll[0])) {
            const sashPrice22 = (sashPrice = sashPriceAll[0]);
            const sashPrice21s = prices.filter(function(el) {
                return (
                    el.height_from <= priceData.height
                    && el.height_to >= priceData.height
                    && el.width_from <= sashPrice22.width_from - 1
                    && el.width_to >= sashPrice22.width_from - 1
                );
            });
            const sashPrice11s = prices.filter(function(el) {
                return (
                    el.height_from <= sashPrice22.height_from - 1
                    && el.height_to >= sashPrice22.height_from - 1
                    && el.width_from <= sashPrice22.width_from - 1
                    && el.width_to >= sashPrice22.width_from - 1
                );
            });
            const sashPrice12s = prices.filter(function(el) {
                return (
                    el.height_from <= sashPrice22.height_from - 1
                    && el.height_to >= sashPrice22.height_from - 1
                    && el.width_from <= priceData.width
                    && el.width_to >= priceData.width
                );
            });
            if (
                (Common.isUndefined(sashPrice21s) || Common.isUndefined(sashPrice21s[0]))
                && (Common.isUndefined(sashPrice11s) || Common.isUndefined(sashPrice11s[0]))
                && (Common.isUndefined(sashPrice12s) || Common.isUndefined(sashPrice12s[0]))
            ) {
                sashPrice = sashPrice22.price;
            } else if (
                Common.isDefined(sashPrice21s)
                && Common.isDefined(sashPrice21s[0])
                && (Common.isUndefined(sashPrice11s) || Common.isUndefined(sashPrice11s[0]))
                && (Common.isUndefined(sashPrice12s) || Common.isUndefined(sashPrice12s[0]))
            ) {
                const sashPrice21 = sashPrice21s[0];
                sashPrice = this.lInterpolate(
                    sashPrice21.width_to,
                    sashPrice22.width_to,
                    sashPrice21.price,
                    sashPrice22.price,
                    priceData.width
                );
            } else if (
                (Common.isUndefined(sashPrice21s) || Common.isUndefined(sashPrice21s[0]))
                && (Common.isUndefined(sashPrice11s) || Common.isUndefined(sashPrice11s[0]))
                && (Common.isDefined(sashPrice12s) && Common.isDefined(sashPrice12s[0]))
            ) {
                const sashPrice12 = sashPrice12s[0];
                sashPrice = this.lInterpolate(
                    sashPrice12.height_to,
                    sashPrice22.height_to,
                    sashPrice12.price,
                    sashPrice22.price,
                    priceData.height
                );
            } else if (
                Common.isUndefined(sashPrice21s)
                || Common.isUndefined(sashPrice21s[0])
                || Common.isUndefined(sashPrice11s)
                || Common.isUndefined(sashPrice11s[0])
                || Common.isUndefined(sashPrice12s)
                || Common.isUndefined(sashPrice12s[0])
            ) {
                const sashPrice21 = sashPrice21s[0];
                let sashPrice21p = 0;
                if (Common.isDefined(sashPrice21)) {
                    sashPrice21p = sashPrice21.price;
                }
                const sashPrice11 = sashPrice11s[0];
                let sashPrice11p = 0;
                if (Common.isDefined(sashPrice11)) {
                    sashPrice11p = sashPrice11.price;
                }
                const sashPrice12 = sashPrice12s[0];
                let sashPrice12p = 0;
                if (Common.isDefined(sashPrice12)) {
                    sashPrice12p = sashPrice12.price;
                }
                let x1;
                if (Common.isDefined(sashPrice21)) {
                    x1 = sashPrice21.width_to;
                } else if (Common.isDefined(sashPrice11)) {
                    x1 = sashPrice11.width_to;
                }
                let x2;
                if (Common.isDefined(sashPrice22)) {
                    x2 = sashPrice22.width_to;
                } else if (Common.isDefined(sashPrice12)) {
                    x2 = sashPrice12.width_to;
                }
                let y1;
                if (Common.isDefined(sashPrice11)) {
                    y1 = sashPrice11.height_to;
                } else if (Common.isDefined(sashPrice12)) {
                    y1 = sashPrice12.height_to;
                }
                let y2;
                if (Common.isDefined(sashPrice22)) {
                    y2 = sashPrice22.height_to;
                } else if (Common.isDefined(sashPrice21)) {
                    y2 = sashPrice21.height_to;
                }
                sashPrice = this.tInterpolate(
                    x1,
                    x2,
                    y1,
                    y2,
                    priceData.width,
                    priceData.height,
                    sashPrice11p,
                    sashPrice21p,
                    sashPrice12p,
                    sashPrice22.price
                );
            } else if (
                Common.isDefined(sashPrice21s)
                && Common.isDefined(sashPrice21s[0])
                && Common.isDefined(sashPrice11s)
                && Common.isDefined(sashPrice11s[0])
                && Common.isDefined(sashPrice12s)
                && Common.isDefined(sashPrice12s[0])
            ) {
                const sashPrice21 = sashPrice21s[0];
                const sashPrice11 = sashPrice11s[0];
                const sashPrice12 = sashPrice12s[0];
                sashPrice = this.biInterpolate(
                    sashPrice21.width_to,
                    sashPrice22.width_to,
                    sashPrice11.height_to,
                    sashPrice22.height_to,
                    priceData.width,
                    priceData.height,
                    sashPrice11.price,
                    sashPrice21.price,
                    sashPrice12.price,
                    sashPrice22.price
                );
            }
        }
        return sashPrice;
    }

    /**
     * Cena z cennika rastrowego dla danego skrzydła.
     * @param  {object} priceData     Mapowanie cenników
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} matrixes      Rastry
     * @return {number}               cena za skrzydło
     */
    priceForAlushellRect(
        priceData,
        balcony,
        PriceElems,
        NoPriceCauses,
        matrixes,
        ruSupp,
        interpolate,
        conf,
        profileSets
    ) {
        let priceId, priceSetId;
        if (Common.isObject(priceData) && Common.isObject(priceData.id)) {
            const matchingSetsIds = this.profileSetsService.getProfileSetsForConstructionPart(
                priceData,
                conf,
                profileSets
            );
            const setsFromPrices = Object.keys(priceData.id).map(setId => {
                if (Number(setId) === 0) {
                    setId = conf.System.default_profile_set_id;
                    const profileSetsForSystem = this.profileSetsService.getProfileSetsForSystem(
                        conf.System.id
                    );
                    if (!setId && profileSetsForSystem.length > 0) {
                        setId = String(profileSetsForSystem[0].id);
                    }
                    priceData.id[setId] = priceData.id[0];
                    delete priceData.id[0];
                }
                return setId;
            });
            const matchingPricesToSets = setsFromPrices.filter(
                setId => matchingSetsIds.indexOf(Number(setId)) > -1
            );
            const matchingPricesToSet = setsFromPrices.filter(
                setId => conf.ProfileSet && conf.ProfileSet.id === Number(setId)
            );
            if (matchingPricesToSets.length > 0) {
                priceSetId = matchingPricesToSets[0];
            } else if (matchingPricesToSet.length > 0) {
                priceSetId = matchingPricesToSet[0];
            } else {
                priceSetId = setsFromPrices[0];
            }
            const pricesForSet = priceData.id[priceSetId];

            const fillingsFromPrices = Object.keys(pricesForSet);
            const fillingsIds = this.priceGlazingService.getFillingsForConstructionPart(priceData, conf);
            const matchingPricesToFillings = fillingsFromPrices.filter(
                fillingId => fillingsIds.indexOf(Number(fillingId)) > -1
            );

            const pricesForFilling = pricesForSet[matchingPricesToFillings[0] || '_'];

            if (Common.isDefined(pricesForFilling.one)) {
                priceId = pricesForFilling.one;
            } else {
                if (
                    priceData.height < pricesForFilling.over.from
                    && !pricesForFilling.options.win.onlyDimensions
                ) {
                    priceId = pricesForFilling.win;
                } else if (
                    priceData.height >= pricesForFilling.over.from
                    && priceData.height <= pricesForFilling.over.to
                ) {
                    if (balcony && !pricesForFilling.options.bal.onlyDimensions) {
                        priceId = pricesForFilling.bal;
                    } else {
                        priceId = pricesForFilling.win;
                    }
                } else {
                    priceId = pricesForFilling.bal;
                }
            }
        }
        const noPrice = [
            {
                baseValue: null,
                value: null,
                type: 'sashes',
                valueType: 'value',
                data: {},
            },
        ] as PriceSegment[];
        if (Common.isDefined(priceId) && priceId !== null) {
            const price = matrixes[priceId];
            if (Common.isDefined(price) && price !== null && Common.isArray(price.data)) {
                let priceF = NaN;
                if (interpolate) {
                    priceF = this.priceForRectInterpolated(priceData, price.data);
                } else {
                    const priceField = price.data.filter(function(el) {
                        return (
                            el.height_from <= priceData.height
                            && el.height_to >= priceData.height
                            && el.width_from <= priceData.width
                            && el.width_to >= priceData.width
                        );
                    });
                    if (Common.isArray(priceField) && priceField.length > 0) {
                        priceF = parseFloat(priceField[0].price);
                    }
                }

                if (!isNaN(priceF)) {
                    PriceElems.alushell.push({
                        code: priceData.code,
                        price: priceF,
                        priceId,
                        height: priceData.height,
                        width: priceData.width,
                    });
                    return [
                        {
                            baseValue: priceF,
                            value: priceF,
                            type: 'alushell',
                            valueType: 'value',
                            data: {
                                code: priceData.code,
                                priceId,
                                height: priceData.height,
                                width: priceData.width,
                                types: priceData.types,
                                typesSymbol: priceData.typesSymbol,
                                divIds: priceData.divIds,
                                sashIds: priceData.sashIds,
                                profileSetId: priceSetId,
                            },
                        },
                    ] as PriceSegment[];
                } else {
                    NoPriceCauses.push('no price in matrix');
                    return noPrice;
                }
            } else {
                NoPriceCauses.push('no matrix');
                return noPrice;
            }
        }
    }

    /**
     * Sprawdzenie pod kątem wymiarówi  zestawów profili
     * czy cennik rastrowy obejmuje zadany obszar
     * @param  {object} priceData     Mapowanie cenników
     * @param  {object} matrixes      Rastry
     * @return {boolean}               cena za skrzydło
     */
    isDimensionsInPrices(prices, width, height, matrixes, conf, profileSets): {hasPrice: boolean, priceType: ('one' | 'win' | 'bal')[]} {
        let priceType: ('one' | 'win' | 'bal')[] = [];
        if (Common.isObject(prices) && Common.isObject(prices.id)) {
            const matchingSetsIds = this.profileSetsService.getProfileSetsForConstructionPart(
                prices,
                conf,
                profileSets
            );
            const setsFromPrices = Object.keys(prices.id).map(setId => {
                if (Number(setId) === 0) {
                    setId = conf.System.default_profile_set_id;
                    const profileSetsForSystem = this.profileSetsService.getProfileSetsForSystem(
                        conf.System.id
                    );
                    if (!setId && profileSetsForSystem.length > 0) {
                        setId = String(profileSetsForSystem[0].id);
                    }
                    prices.id[setId] = prices.id[0];
                    delete prices.id[0];
                }
                return setId;
            });
            const matchingPricesToSets = setsFromPrices.filter(
                setId => matchingSetsIds.indexOf(Number(setId)) > -1
            );
            const matchingPricesToSet = setsFromPrices.filter(
                setId => conf.ProfileSet && conf.ProfileSet.id === Number(setId)
            );
            let priceSetId;
            if (matchingPricesToSets.length > 0) {
                priceSetId = matchingPricesToSets[0];
            } else if (matchingPricesToSet.length > 0) {
                priceSetId = matchingPricesToSet[0];
            } else {
                priceSetId = setsFromPrices[0];
            }
            const pricesForSet = prices.id[priceSetId];

            const fillingsFromPrices = Object.keys(pricesForSet);
            const fillingsIds = this.priceGlazingService.getFillingsForConstructionPart(prices, conf);
            const matchingPricesToFillings = fillingsFromPrices.filter(
                fillingId => fillingsIds.indexOf(Number(fillingId)) > -1
            );

            const pricesForFilling = pricesForSet[matchingPricesToFillings[0] || '_'];

            if (Common.isDefined(pricesForFilling)) {
                if (Common.isDefined(pricesForFilling.one)) {
                    const hasPrice = this.hasPriceForDimensions(
                        pricesForFilling.one,
                        width,
                        height,
                        matrixes
                    );
                    if (hasPrice) {
                        priceType.push('one');
                    }
                    return {hasPrice, priceType};
                } else {
                    let hasWinPrice = this.hasPriceForDimensions(
                        pricesForFilling.win,
                        width,
                        height,
                        matrixes
                    );
                    let hasBalPrice = this.hasPriceForDimensions(
                        pricesForFilling.bal,
                        width,
                        height,
                        matrixes
                    );
                    if (hasWinPrice) {
                        priceType.push('win');
                    }
                    if (hasBalPrice) {
                        priceType.push('bal');
                    }

                    return {hasPrice: hasWinPrice || hasBalPrice, priceType};
                }
            }
        }
        return {hasPrice: false, priceType};

    }

    /**
     * Cena z cennika rastrowego dla danego skrzydła.
     * @param  {object} priceData     Mapowanie cenników
     * @param  {object} matrixes      Rastry
     * @return {number}               cena za skrzydło
     */
    hasPriceForDimensions(priceId, width, height, matrixes) {
        if (Common.isDefined(priceId) && priceId !== null) {
            const price = matrixes[priceId];
            if (Common.isDefined(priceId) && priceId !== null && Common.isArray(price.data)) {
                const priceField = price.data.filter(function(el) {
                    return (
                        el.height_from <= height
                        && el.height_to >= height
                        && el.width_from <= width
                        && el.width_to >= width
                    );
                });
                if (Common.isArray(priceField) && priceField.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Zamienia kod konstrukcji z kilkoma stałymi szkleniami obok siebie w jedno.
     *
     * ----------------                         ----------------
     * | + | + |  /\  |   (([F]|[F])_[F])|[U]   |       |  /\  |
     * |-------- /  \ |       zamienia na       |   +   | /  \ |
     * |   +   |/    \|         [F]|[U]         |       |/    \|
     * ----------------                         ----------------
     *
     * @param  {string} codeC Kod konstrukcji do połączenia stałych szkleń.
     * @return {object} Zamieniony kod konstrukcji.
     */
    joinFixInOne(codeC, sashes, map) {
        let fh = -1,
            fv = -1,
            fi = -1,
            fm1 = -1,
            fm2 = -1,
            i = 0,
            c = -1;
        let cCodeC = codeC;
        let index = 0;

        const cSashes = core.copy(sashes);
        const cMap = core.copy(map);
        const mergedSashesIds = new Map();
        let sashToMergeId = null;
        let sashFromMergeId = null;

        while ((fh = cCodeC.indexOf('[F]|[F]')) > -1 || (fv = cCodeC.indexOf('[F]_[F]')) > -1) {
            index = -1;

            // szukaj indeksu elementu z map
            for (fi = 0; fi < cMap.length; ++fi) {
                if (cMap[fi].index === fh || cMap[fi].index === fv) {
                    index = fi;
                    break;
                }
            }

            // to nie powinno się zdarzyć...
            if (index === -1) {
                return { code: '', sashes: [], map: [] };
            }

            // pion
            if (fh !== -1) {
                fm1 = ~~cMap[index].sashId;
                fm2 = ~~cMap[index + 1].sashId;

                if (Common.isUndefined(cSashes[fm2])) {
                    continue;
                }

                // zmień wymiary
                cSashes[fm1].shape.width += cSashes[fm2].shape.width;
                cSashes[fm1].shape.x =
                    cSashes[fm1].shape.x > cSashes[fm2].shape.x
                        ? cSashes[fm2].shape.x
                        : cSashes[fm1].shape.x;
                cSashes[fm1].shape.height =
                    cSashes[fm1].shape.height > cSashes[fm2].shape.height
                        ? cSashes[fm1].shape.height
                        : cSashes[fm2].shape.height;

                cMap[index].sashId =
                    ~~cMap[index + 1].sashId < ~~cMap[index].sashId
                        ? cMap[index + 1].sashId
                        : cMap[index].sashId;

                for (i = 0, c = ~~cMap[index + 1].sashId; i < cMap.length; ++i) {
                    // tu było (~~cMap[i].sashId) - 1, po coś to było, nie pamiętam po co
                    if (~~cMap[i].sashId > c) {
                        cMap[i].sashId = ~~cMap[i].sashId - 1;
                    }
                }
                sashToMergeId = cSashes[fm2].id;
                sashFromMergeId = cSashes[fm1].id;
                cSashes.splice(fm2, 1);
                cMap.splice(index + 1, 1);
                // poziom
            } else if (fv !== -1) {
                fm1 = ~~cMap[index].sashId;
                fm2 = ~~cMap[index + 1].sashId;

                if (Common.isUndefined(cSashes[fm2])) {
                    continue;
                }

                // zmień wymiary
                cSashes[fm1].shape.height += cSashes[fm2].shape.height;
                cSashes[fm1].shape.y =
                    cSashes[fm1].shape.y > cSashes[fm2].shape.y
                        ? cSashes[fm2].shape.y
                        : cSashes[fm1].shape.y;
                cSashes[fm1].shape.width =
                    cSashes[fm1].shape.width > cSashes[fm2].shape.width
                        ? cSashes[fm1].shape.width
                        : cSashes[fm2].shape.width;

                cMap[index].sashId =
                    ~~cMap[index + 1].sashId < ~~cMap[index].sashId
                        ? cMap[index + 1].sashId
                        : cMap[index].sashId;

                for (i = 0, c = ~~cMap[index + 1].sashId; i < cMap.length; ++i) {
                    // tu było (~~cMap[i].sashId) - 1, po coś to było, nie pamiętam po co
                    if (~~cMap[i].sashId > c) {
                        cMap[i].sashId = ~~cMap[i].sashId - 1;
                    }
                }

                sashToMergeId = cSashes[fm2].id;
                sashFromMergeId = cSashes[fm1].id;
                cSashes.splice(fm2, 1);
                cMap.splice(index + 1, 1);
            }

            if (fh > -1) {
                cCodeC = cCodeC.replace('[F]|[F]', '[F]');
                cCodeC = cCodeC.replace('(|', '(');
                cCodeC = cCodeC.replace('|)', ')');

                // gdy ([F]) odejmij indeks
                if (fh > 0 && cCodeC[fh - 1] === '(' && cCodeC[fh + 3] === ')') {
                    cMap[index].index--;
                }
            }
            if (fv > -1) {
                cCodeC = cCodeC.replace('[F]_[F]', '[F]');
                cCodeC = cCodeC.replace('(_', '(');
                cCodeC = cCodeC.replace('_)', ')');

                // gdy ([F]) odejmij indeks
                if (fv > 0 && cCodeC[fv - 1] === '(' && cCodeC[fv + 3] === ')') {
                    cMap[index].index--;
                }
            }
            cCodeC = cCodeC.replace('([F])', '[F]');

            // oblicz nowe współrzędne
            i = -1;
            for (fi = 0; fi < cMap.length; ++fi) {
                i = cCodeC.indexOf('[', i + 1);
                cMap[fi].index = i;
            }

            let arrayOfIds = mergedSashesIds.get(sashFromMergeId);
            if (!arrayOfIds) {
                arrayOfIds = [];
            }
            if (mergedSashesIds.has(sashToMergeId)) {
                arrayOfIds = arrayOfIds.concat(mergedSashesIds.get(sashToMergeId));
                mergedSashesIds.delete(sashToMergeId);
            }
            arrayOfIds.push(sashToMergeId);
            mergedSashesIds.set(sashFromMergeId, arrayOfIds);
        }

        return { code: cCodeC, sashes: cSashes, map: cMap, mergedSashesIds };
    }

    /**
     * Cena za konstrukcję z rastra.
     * @param  {Array}  sashes        Skrzydła
     * @param  {object} constrData    Dane konstrukcji
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} priceMaps     Mapowanie cenników
     * @param  {object} matrixes      Rastry
     * @param  {bool}   interpolated  Czy interpolowana?
     * @return {number}               Cena za układ skrzydeł
     */
    @PriceFunc({
        shortName: 'sashes',
        data: {
            sashes: 'conf.Sashes',
            balcony: 'conf.Balcony',
            matrixes: 'data.windowPrices',
            supps: 'data.sashTypesSupplement',
            type: 'conf.type',
            pricesDepends: 'data.pricesDepends',
            system: 'conf.System',
            frames: 'conf.Frames',
            layout: 'conf.Layout',
            colors: 'conf.Colors',
            dividers: 'conf.Mullions',
            cWidth: 'conf.Width',
            cHeight: 'conf.Height',
            usedProfiles: 'conf.UsedProfiles',
            conf: 'conf',
            profileSets: 'data.profileSets',
            shape: 'conf.Shape',
            shapeSupps: 'data.windowShapesFactors',
        },
    })
    priceForSashes(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        {
            sashes,
            balcony,
            matrixes,
            supps,
            type,
            pricesDepends,
            system,
            frames,
            layout,
            colors,
            dividers,
            cWidth,
            cHeight,
            usedProfiles,
            conf,
            profileSets,
            shape,
            shapeSupps,
        },
        alushell,
        priceMaps?
    ): PriceSegment[] {
        if (!priceMaps) {
            priceMaps = this.getPricesForSystem(
                pricesDepends,
                system,
                colors,
                sashes.length,
                NoPriceCauses
            );
        }
        const constrData = this.constructionCodeService.getConstructionCode({
            width: cWidth,
            height: cHeight,
            sashes,
            dividers,
            frames: conf.Frames,
            couplings: conf.couplings,
            type,
            conf,
        });
        const interpolated = this.config().IccConfig.Configurators.price.interpolation;

        const pricesArr = [];
        if (
            !Common.isObject(constrData)
            || !Common.isDefined(constrData.code)
            || !Common.isDefined(constrData.sashMapping)
        ) {
            return [];
        }

        const sashShapeSupps = this.priceShapeService.getSashShapePercentSupps(
            sashes,
            shape,
            system,
            shapeSupps
        );
        let prices = [];
        Common.forEach(priceMaps, function(id, code) {
            prices.push({
                id,
                code,
            });
        });

        // Wyznaczanie dopłaty kwotowej za skrzydło RU
        let ruSupp: any = {};
        let ruSupps = [];
        if (Common.isArray(supps)) {
            ruSupps = supps.filter(el => {
                if (isNaN(parseInt(el.width_from))) {
                    el.width_from = 0;
                }
                if (isNaN(parseInt(el.width_to))) {
                    el.width_to = Infinity;
                }
                if (isNaN(parseInt(el.height_from))) {
                    el.height_from = 0;
                }
                if (isNaN(parseInt(el.height_to))) {
                    el.height_to = Infinity;
                }
                return el.type === 'ru';
            });
        }
        if (Common.isDefined(ruSupps[0])) {
            ruSupp = ruSupps[0];
        }

        // Sortowanie cenników od najbardziej złożonych do konstrukcji do najmniej złożonych
        prices = prices.sort(function(a, b) {
            const ac = a.code;
            const bc = b.code;
            const an = ac.split(/(\||_|=|\+)/);
            const bn = bc.split(/(\||_|=|\+)/);
            return bn.length - an.length;
        });

        let codeC = constrData.code;
        let joined: any = {};
        let map = constrData.sashMapping;
        let mergedSashesIds = new Map();
        let joinFixAddAstragals = this.config().IccConfig.Configurators.window.joinFixAddAstragals;
        if (Common.isObject(joinFixAddAstragals)) {
            const normalizedType = type === 'door' ? 'door' : 'window';
            joinFixAddAstragals = this.config().IccConfig.Configurators.window.joinFixAddAstragals[
                normalizedType
            ];
        }

        // Opcjonalnie łączenie sąsiadujących fixów
        if (joinFixAddAstragals) {
            joined = this.joinFixInOne(constrData.code, sashes, map);

            codeC = joined.code;
            sashes = joined.sashes;
            map = joined.map;
            mergedSashesIds = joined.mergedSashesIds;
        }

        const fullCode = codeC;

        // Dopasowywanie cenników do układu, aż w kodzie konstrukcji
        // nie zostanie kod żadnego skrzydła
        let lastInSearch = false;
        do {
            const oldCode = codeC;

            // Iteracja po wszystkich pasujących cennikach
            for (let i = 0; i < prices.length; i++) {
                const localMapping = core.copy(map);
                const pricesOptions = Object.keys(prices[i].id)
                    .filter(k => k && prices[i].id[k])
                    .map(k => prices[i].id[k].options)
                    .filter(el => el);

                // Opuszczanie cennika z opcją "Tylko do ograniczeń wymiarowych",
                if (pricesOptions.some(o => o && o.one && o.one.onlyDimensions)) {
                    continue;
                }

                // Sprawdzenie czy kod danego cennika mieści się w pozostałym kodzie konstrukcji
                if (
                    (prices[i].code !== ''
                        && codeC.indexOf(prices[i].code) > 0
                        && !/[a-z]/i.test(codeC[codeC.indexOf(prices[i].code) - 1]))
                    || codeC.indexOf(prices[i].code) === 0
                ) {
                    // Opuszczenie cennika z opcją "Tylko do całej konstrukcji",
                    // gdy w kodzie konstrukcji po usunięciu fragmentu z kodem tego cennika
                    // pozostaną jeszcze kody skrzydeł (będące literami alfabetu)
                    if (
                        /[a-z]/i.test(fullCode.replace(prices[i].code, ''))
                        && pricesOptions.some(o => o && o.one && o.one.onlyWhole)
                    ) {
                        continue;
                    }
                    const index = codeC.indexOf(prices[i].code);
                    const indexEnd = index + prices[i].code.length - 1;
                    let maxWidth = 0;
                    let maxHeight = 0;
                    let minX, minY;
                    let founded = false;
                    const sashIds = [];
                    const sashTypes = [];
                    const sashTypesId = [];
                    const dividersId = [];
                    let ruSashesCount = 0;
                    let ruSashesCountSized = 0;
                    // Iteracja po mapowaniach indeksów części kodu konstrukcji
                    // na identyfikatory kwater
                    for (let j = 0; j < localMapping.length; j++) {
                        const pos = localMapping[j].index;
                        const sashId = localMapping[j].sashId;
                        const sash = sashes[sashId];
                        if (pos >= index && pos <= indexEnd) {
                            // Wyznaczenie granicznych wymiarów obszaru dla cennika rastrowego
                            if (sashIds.length === 0) {
                                minX = sash.shape.x;
                                minY = sash.shape.y;
                            }
                            if (sash.shape.y + sash.shape.height > maxHeight) {
                                maxHeight = sash.shape.y + sash.shape.height;
                            }
                            if (sash.shape.x + sash.shape.width > maxWidth) {
                                maxWidth = sash.shape.x + sash.shape.width;
                            }
                            if (Common.isUndefined(minY) || sash.shape.y < minY) {
                                minY = sash.shape.y;
                            }
                            if (Common.isUndefined(minX) || sash.shape.x < minX) {
                                minX = sash.shape.x;
                            }
                            // Zebranie słupków/poprzeczek wewnątrz tego obszaru
                            const tmpDivArr = [];
                            for (const side in sash.nearMullions) {
                                if (sash.nearMullions[side] > -1) {
                                    tmpDivArr.push(sash.nearMullions[side]);
                                }
                            }
                            if (j === 0) {
                                dividersId.push(...tmpDivArr);
                            } else {
                                dividersId.push(...tmpDivArr.filter(d => !dividersId.includes(d)));
                            }
                            // Wyznaczenie ilości potrzebnych dopłat za skrzydło RU
                            if (sash.type.type === 'DK') {
                                ruSashesCount++;
                                if (
                                    sash.shape.height > ruSupp.height_to
                                    || sash.shape.height < ruSupp.height_from
                                    || sash.shape.width > ruSupp.width_to
                                    || sash.shape.width < ruSupp.width_from
                                ) {
                                    ruSashesCountSized++;
                                }
                            }
                            sashIds.push(sash.id);
                            if (mergedSashesIds.has(sash.id)) {
                                sashIds.push(...mergedSashesIds.get(sash.id));
                            }
                            sashTypes.push(sash.type.type);
                            sashTypesId.push(sash.type.id);
                            localMapping[j].index = -1;
                            founded = true;
                            continue;
                        }
                        if (founded) {
                            localMapping[j].index -= prices[i].code.length;
                        }
                    }
                    // Określenie szerokości i wysokości obszaru
                    const width = Math.ceil(maxWidth - minX);
                    const height = Math.ceil(maxHeight - minY);

                    const part = {
                        id: prices[i].id,
                        width,
                        height,
                        code: prices[i].code,
                        sashIds,
                        ru: ruSashesCount,
                        rus: ruSashesCountSized,
                        types: sashTypesId,
                        typesSymbol: sashTypes,
                        divIds: dividersId,
                    };

                    if (
                        sashShapeSupps
                            .map(supp => supp.sashIds.some(sId => sashIds.some(id => id === sId)))
                            .filter(supp => supp).length > 1
                    ) {
                        continue;
                    }

                    // Sprawdzenie pod kątem wymiarów i zestawów profili
                    // czy cennik rastrowy obejmuje zadany obszar i jeśli obejmuje
                    // zachowanie danych tego cennika i obszaru oraz usunięcie z kodu konstrukcji
                    // fragmentu kodu tego cennika
                    const dimensionsInPrices = this.isDimensionsInPrices(
                        part,
                        width,
                        height,
                        matrixes,
                        conf,
                        profileSets
                    );
                    if (
                        dimensionsInPrices.priceType.every(el =>
                            pricesOptions.some(
                                o => o
                                    && o[el]
                                    && (o[el].onlyDimensions
                                        || (/[a-z]/i.test(fullCode.replace(prices[i].code, ''))
                                            && o[el].onlyWhole))
                            )
                        )
                    ) {
                        continue;
                    }
                    if (dimensionsInPrices.hasPrice) {
                        codeC = codeC.replace(prices[i].code, '');
                        map = localMapping;

                        pricesArr.push(part);
                    } else {
                        // Jeśli ten cennik nie obejmuje danego obszaru wymiarami, zestawem profili
                        // i kształtami i ma ustawioną opcję "Nie szukaj w konstrukcjach prostszych"
                        // przerywamy poszukiwania
                        if (
                            dimensionsInPrices.priceType.every(
                                el => pricesOptions.some(o => o && o[el] && o[el].lastInSearch)
                            )
                        ) {
                            lastInSearch = true;
                        } else {
                            continue;
                        }
                    }

                    break;
                }
            }
            // Jeśli po sprawdzeniu wszystkich cenników kod się nie zmienił
            //  to przerywamy poszukiwania
            if (oldCode === codeC || lastInSearch) {
                break;
            }
        } while (/[a-z]/i.test(codeC));
        let noPrice = false;
        const priceSegments: PriceSegment[] = [];
        if (pricesArr.length === 0 || /[a-z]/i.test(codeC)) {
            noPrice = true;
            NoPriceCauses.push('no prices');
        }
        this.EventBusService.post({ key: 'foundedPrices', value: pricesArr });
        for (let i = 0; i < pricesArr.length; i++) {
            if (!alushell) {
                priceSegments.push.apply(
                    priceSegments,
                    this.priceForRect(
                        pricesArr[i],
                        balcony,
                        PriceElems,
                        NoPriceCauses,
                        matrixes,
                        ruSupp,
                        interpolated,
                        conf,
                        profileSets
                    )
                );
            } else {
                priceSegments.push.apply(
                    priceSegments,
                    this.priceForAlushellRect(
                        pricesArr[i],
                        balcony,
                        PriceElems,
                        NoPriceCauses,
                        matrixes,
                        ruSupp,
                        interpolated,
                        conf,
                        profileSets
                    )
                );
            }
        }
        if (noPrice) {
            if (!this.config().IccConfig.Configurators.allowAddOversizedConstruction) {
                this.IssuesService.simpleRegister(
                    'incorrect-dimensions',
                    'Podane wymiary są nieprawidłowe.',
                    this.translateService.instant('WINDOW|Podane wymiary są nieprawidłowe.'),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
            } else {
                this.IssuesService.unregister('incorrect-dimensions', conf);
            }
            return [
                {
                    baseValue: null,
                    value: null,
                    type: 'sashes',
                    valueType: 'value',
                    data: {},
                },
            ] as PriceSegment[];
        } else {
            return priceSegments;
        }
    }

    /**
     * Sprawdza czy wszystkie skrzydła są większe niż minimalny rozmiar.
     *
     * @param {any} balcony
     * @param {any} NoPriceCauses
     * @param {any} sashes
     * @param {any} priceMaps
     * @param {any} matrixes
     * @param {any} type
     * @returns
     */
    checkMinSizes(balcony, NoPriceCauses, sashes, priceMaps, matrixes, type): boolean {
        if (Common.isDefined(priceMaps)) {
            const isHS = sashes.some(s => s.type && (['HS', 'S'].includes(s.type.type)));
            for (let i = 0; i < sashes.length; i++) {
                const sash = sashes[i];
                if (isHS && this.config().IccConfig.Configurators.noHSMinSizeCheck) {
                    return true;
                }
                if (type === 'door' && sash.type.type === 'F') {
                    continue;
                }

                if (Common.isObject(priceMaps['[' + sash.type.type + ']'])) {
                    const priceData = priceMaps['[' + sash.type.type + ']'];
                    let priceId;
                    if (Common.isDefined(priceData.one)) {
                        priceId = priceData.one;
                    } else {
                        if (sash.shape.height < priceData.over.from) {
                            priceId = priceData.win;
                        } else if (
                            sash.shape.height >= priceData.over.from
                            && sash.shape.height <= priceData.over.to
                        ) {
                            if (balcony) {
                                priceId = priceData.bal;
                            } else {
                                priceId = priceData.win;
                            }
                        } else {
                            priceId = priceData.bal;
                        }
                    }
                    if (Common.isDefined(priceId) && priceId !== null) {
                        const matrix = matrixes[priceId];
                        if (Common.isDefined(matrix) && matrix !== null) {
                            const priceField = matrix.data.filter(
                                el =>
                                    el.height_from <= sash.shape.height
                                    && el.height_to >= sash.shape.height
                                    && el.width_from <= sash.shape.width
                                    && el.width_to >= sash.shape.width
                            );
                            if (Common.isArray(priceField) && priceField.length > 0) {
                                continue;
                            } else {
                                NoPriceCauses.push('sash bad size');
                                return false;
                            }
                        } else {
                            NoPriceCauses.push('no matrix');
                            return false;
                        }
                    }
                } else {
                    NoPriceCauses.push('no prices');
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Czy ma sugerować szerokie skrzydło
     * @param  {Array}  sashes        Skrzydła
     * @param  {bool}   balcony       Czy okno balkonowe
     * @param  {object} constrData    Dane konstrukcji
     * @param  {object} priceMaps     Mapowanie cenników
     * @param  {object} matrixes      Rastry
     * @return {number}               Cena za układ skrzydeł
     */
    @PriceFunc({
        shortName: 'wideSash',
        data: {
            sashes: 'conf.Sashes',
            matrixes: 'data.wideSashes',
            wideSashesDepends: 'data.wideSashesDepends',
            system: 'conf.System',
            colors: 'conf.Colors',
            dividers: 'conf.Mullions',
            cWidth: 'conf.Width',
            cHeight: 'conf.Height',
            type: 'conf.type',
            conf: 'conf',
            profileSets: 'data.profileSets',
        },
    })
    checkWideSash(
        {  }: PriceElemsData,
        {
            sashes,
            matrixes,
            wideSashesDepends,
            system,
            colors,
            dividers,
            cWidth,
            cHeight,
            type,
            conf,
            profileSets,
        }
    ): PriceSegment[] {
        if (!this.config().IccConfig.Configurators.price.wideSash) {
            return [];
        }
        const constrData = this.constructionCodeService.getConstructionCode({
            width: cWidth,
            height: cHeight,
            sashes,
            dividers,
            frames: conf.Frames,
            couplings: conf.couplings,
            type,
            conf,
        });
        const priceMaps = this.getPricesForSystem(
            wideSashesDepends,
            system,
            colors,
            sashes.length,
            []
        );
        const pricesArr = [];
        if (
            !Common.isObject(constrData)
            || !Common.isDefined(constrData.code)
            || !Common.isDefined(constrData.sashMapping)
        ) {
            this.EventBusService.post({
                key: 'wideSash',
                value: false,
            });
            return [];
        }
        let prices = [];
        Common.forEach(priceMaps, (id, code) => {
            prices.push({
                id,
                code,
            });
        });

        prices = prices.sort((a, b) => {
            const ac = a.code;
            const bc = b.code;
            const an = ac.split(/(\||_)/);
            const bn = bc.split(/(\||_)/);
            return bn.length - an.length;
        });

        let codeC = constrData.code;
        const map = constrData.sashMapping;
        let i = 0;
        do {
            const oldCode = codeC;
            for (i = 0; i < prices.length; i++) {
                if (
                    (prices[i].code !== ''
                        && codeC.indexOf(prices[i].code) > 0
                        && !/[a-z]/i.test(codeC[codeC.indexOf(prices[i].code) - 1]))
                    || codeC.indexOf(prices[i].code) === 0
                ) {
                    const index = codeC.indexOf(prices[i].code);
                    const indexEnd = index + prices[i].code.length - 1;
                    let maxWidth = 0;
                    let maxHeight = 0;
                    let minX, minY;
                    let founded = false;
                    const sashIds = [];
                    for (let j = 0; j < map.length; j++) {
                        const pos = map[j].index;
                        const sashId = map[j].sashId;
                        if (pos >= index && pos <= indexEnd) {
                            if (sashIds.length === 0) {
                                minX = sashes[sashId].shape.x;
                                minY = sashes[sashId].shape.y;
                            }
                            if (sashes[sashId].shape.y + sashes[sashId].shape.height > maxHeight) {
                                maxHeight = sashes[sashId].shape.y + sashes[sashId].shape.height;
                            }
                            if (sashes[sashId].shape.x + sashes[sashId].shape.width > maxWidth) {
                                maxWidth = sashes[sashId].shape.x + sashes[sashId].shape.width;
                            }
                            if (Common.isUndefined(minY) || sashes[sashId].shape.y < minY) {
                                minY = sashes[sashId].shape.y;
                            }
                            if (Common.isUndefined(minX) || sashes[sashId].shape.x < minX) {
                                minX = sashes[sashId].shape.x;
                            }
                            sashIds.push(sashes[sashId].id);
                            map[j].index = -1;
                            founded = true;
                            continue;
                        }
                        if (founded) {
                            map[j].index -= prices[i].code.length;
                        }
                    }
                    const width = maxWidth - minX;
                    const height = maxHeight - minY;
                    const part = {
                        id: prices[i].id,
                        width,
                        height,
                        code: prices[i].code,
                        sashIds,
                    };
                    if (
                        this.isDimensionsInPrices(
                            part,
                            width,
                            height,
                            matrixes,
                            conf,
                            profileSets
                        ).hasPrice
                    ) {
                        codeC = codeC.replace(prices[i].code, '');
                        pricesArr.push(part);
                    } else {
                        continue;
                    }
                    break;
                }
            }
            if (oldCode === codeC) {
                break;
            }
        } while (/[a-z]/i.test(codeC));
        if (pricesArr.length === 0 || /[a-z]/i.test(codeC)) {
            this.EventBusService.post({
                key: 'wideSash',
                value: false,
            });
            return [];
        } else {
            this.EventBusService.post({
                key: 'wideSash',
                value: true,
            });
            return [];
        }
    }

    /**
     * Suplement typu skrzydła
     * @param  {Number} price           Cena
     * @param  {Object} PriceElems      Cena elementów
     * @param  {Object} NoPriceCauses   Powód braku ceny
     * @param  {Object} sashes          Skrzydła
     * @param  {Object} supplements     Suplementy
     * @return {Number}                 Cena
     */
    @PriceFunc({
        shortName: 'sashType',
        data: {
            sashes: 'conf.Sashes',
            supplements: 'data.sashTypesSupplement',
            customPrice: 'price.SashTypesSupplement',
        },
    })
    suppSashType(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        {
            sashes,
            supplements,
            customPrice,
        }: { sashes; supplements; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        if (Common.isArray(supplements)) {
            const priceSegments: PriceSegment[] = [];
            let ruSupp: any = {};
            let ruSupps = [];
            ruSupps = supplements.filter(el => el.type === 'ru');
            if (Common.isDefined(ruSupps[0])) {
                ruSupp = ruSupps[0];
                let sashTypeRuSupp = parseFloat(ruSupp.supp);
                if (Common.isObject(customPrice) && customPrice[ruSupp.id]) {
                    sashTypeRuSupp = customPrice[ruSupp.id].getPrice('supp');
                }
                PriceElems.sashTypes
                    .filter(el => el.type === 'DK')
                    .map(el => {
                        const ruPrice = sashTypeRuSupp * el.count;

                        el.suppId = ruSupp.id;
                        el.price = ruPrice;
                        el.name = ruSupp.name;
                        priceSegments.push({
                            type: 'sashType',
                            baseValue: ruPrice,
                            value: ruPrice,
                            valueType: 'value',
                            data: {
                                sashId: null,
                                suppId: ruSupp.id,
                                type: 'DK',
                                typeId: null,
                                height: el.height,
                                width: el.width,
                                name: ruSupp.name,
                            },
                        });
                    });
            } else {
                PriceElems.sashTypes = PriceElems.sashTypes.filter(
                    el => el.type !== 'DK' || !el.count
                );
            }

            sashes.forEach(sash => {
                const suppSash = supplements.filter(el => {
                    if (isNaN(parseInt(el.width_from))) {
                        el.width_from = 0;
                    }
                    if (isNaN(parseInt(el.width_to))) {
                        el.width_to = Infinity;
                    }
                    if (isNaN(parseInt(el.height_from))) {
                        el.height_from = 0;
                    }
                    if (isNaN(parseInt(el.height_to))) {
                        el.height_to = Infinity;
                    }
                    return (
                        el.sash_type_id === sash.type.id
                        && sash.shape.width <= el.width_to
                        && sash.shape.width >= el.width_from
                        && sash.shape.height <= el.height_to
                        && sash.shape.height >= el.height_from
                        && el.type !== 'ru'
                    );
                });
                if (Common.isDefined(suppSash[0])) {
                    let sashTypeSupp = parseFloat(suppSash[0].supp);
                    if (Common.isObject(customPrice) && customPrice[suppSash[0].id]) {
                        sashTypeSupp = customPrice[suppSash[0].id].getPrice('supp');
                    }
                    PriceElems.sashTypes.push({
                        sashId: sash.id,
                        suppId: suppSash[0].id,
                        type: sash.type.type,
                        typeId: sash.type.id,
                        height: sash.shape.height,
                        width: sash.shape.width,
                        name: suppSash[0].name,
                        price: sashTypeSupp,
                    });
                    priceSegments.push({
                        type: 'sashType',
                        baseValue: sashTypeSupp,
                        value: sashTypeSupp,
                        valueType: 'value',
                        data: {
                            sashId: sash.id,
                            suppId: suppSash[0].id,
                            type: sash.type.type,
                            typeId: sash.type.id,
                            height: sash.shape.height,
                            width: sash.shape.width,
                            name: suppSash[0].name,
                        },
                    });
                }
            });
            return priceSegments;
        }
        return [];
    }

    /**
     * Dokłata za nadwymiarowość
     * @param  {Number} price      Cena
     * @param  {Array} PriceElems  Składowe ceny
     * @param  {Object} conf       Konfiguracja
     * @param  {Array} oversizes   Nadwymiarowość
     * @return {Number}            Cena
     */
    @PriceFunc({
        shortName: 'oversize',
        data: {
            oversizes: 'data.oversizes',
            type: 'conf.type',
            height: 'conf.Height',
            width: 'conf.Width',
        },
    })
    suppOversize(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { oversizes, type, height, width }
    ): PriceSegment[] {
        let supp = 0;
        let oversizeCause = null;

        if (Common.isArray(oversizes)) {
            for (let i = 0; i < oversizes.length; i++) {
                if (oversizes[i].conf === type) {
                    if (
                        Common.isDefined(oversizes[i].min_height)
                        && oversizes[i].min_height !== null
                        && oversizes[i].min_height <= height
                    ) {
                        oversizeCause = 'height';
                    } else if (
                        Common.isDefined(oversizes[i].min_width)
                        && oversizes[i].min_width !== null
                        && oversizes[i].min_width <= width
                    ) {
                        oversizeCause = 'width';
                    } else if (
                        Common.isDefined(oversizes[i].min_area)
                        && oversizes[i].min_area !== null
                        && oversizes[i].min_area <= (width * height) / 1000000
                    ) {
                        oversizeCause = 'area';
                    }

                    if (oversizeCause !== null) {
                        supp = oversizes[i].price * 1;
                        PriceElems.oversize = {
                            price: oversizes[i].price * 1,
                            cause: oversizeCause,
                        };
                        return [
                            {
                                type: 'oversize',
                                baseValue: parseFloat(oversizes[i].price),
                                value: parseFloat(oversizes[i].price),
                                valueType: 'value',
                                data: {
                                    cause: oversizeCause,
                                },
                            },
                        ] as PriceSegment[];
                    }
                }
            }
        }
        return [];
    }

    /**
     * Dopłata do nakładki aluminiowej
     *
     * @method     suppAlushell
     * @param      {Number}  price             Cena
     * @param      {Object}  PriceElems        Cena elementów
     * @param      {Object}  NoPriceCauses     Powód braku ceny
     * @param      {Object}  priceDepends      Cena zależy od
     * @param      {Object}  constructionCode  Kod konstukcji
     * @param      {Object}  matrixes          Macierze
     * @param      {object}  system            System
     * @param      {Sash[]}  sashes            Skrzydła
     * @param      {object}  colors            Kolory
     * @param      {array}   colorGroups       Grupy kolorów
     * @return     {Number}  Cena
     */
    @PriceFunc({
        shortName: 'alushell',
        data: {
            alushell: 'conf.HasAlushell',
            balcony: 'conf.Balcony',
            sashes: 'conf.Sashes',
            matrixes: 'data.windowPrices',
            type: 'conf.type',
            priceDepends: 'data.pricesDepends',
            system: 'conf.System',
            frames: 'conf.Frames',
            layout: 'conf.Layout',
            colors: 'conf.Colors',
            dividers: 'conf.Mullions',
            width: 'conf.Width',
            height: 'conf.Height',
            colorGroups: 'data.windowColorGroups',
            colorGroupsPrices: 'data.windowColorGroupsPrices',
            wood: 'conf.Wood',
            usedProfiles: 'conf.UsedProfiles',
            conf: 'conf',
            profileSets: 'data.profileSets',
            drawData: 'conf.drawData',
            customPrice: 'price.WindowColorGroup',
            mullions: 'conf.Mullions',
            shape: 'conf.Shape',
            shapeSupps: 'data.windowShapesFactors',
        },
    })
    suppAlushell(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        {
            alushell,
            balcony,
            priceDepends,
            matrixes,
            system,
            frames,
            layout,
            sashes,
            colors,
            colorGroups,
            colorGroupsPrices,
            wood,
            type,
            dividers,
            width,
            height,
            usedProfiles,
            conf,
            profileSets,
            drawData,
            customPrice,
            mullions,
            shape,
            shapeSupps,
        }
    ) {
        if (!alushell) {
            return [];
        }
        const winLineId = system.id;
        let factors = 1;
        const priceSegments = [];
        if (Common.isObject(priceDepends) && Common.isObject(priceDepends[winLineId + '|n'])) {
            const priceMaps = priceDepends[winLineId + '|n'];
            priceSegments.push(
                ...this.priceForSashes(
                    { PriceStack: [], PriceElems, NoPriceCauses },
                    {
                        sashes,
                        balcony,
                        matrixes,
                        supps: [],
                        type,
                        pricesDepends: priceDepends,
                        system,
                        frames,
                        layout,
                        colors,
                        dividers,
                        cWidth: width,
                        cHeight: height,
                        usedProfiles,
                        conf,
                        profileSets,
                        shape,
                        shapeSupps,
                    },
                    true,
                    priceMaps
                )
            );
        }
        if (
            this.config().IccConfig.Configurators.price.alushellColor
            && colors
            && colorGroups
            && wood
        ) {
            let sumSashesPrice = 0;
            let sumSashesPriceFactored = 0;
            let colorFactor = 0;
            if (priceSegments.length > 0) {
                priceSegments.forEach(s => {
                    colorFactor = this.PriceColorsService.getColorFactor({
                        NoPriceCauses,
                        colorType: 'Alushell',
                        colorGroups,
                        colorGroupsPrices,
                        colors,
                        system,
                        wood,
                        sash: s.data.types,
                        sashId: s.data.sashIds || [],
                        divId: s.data.divIds || [],
                        drawData,
                        sashes,
                        mullions,
                        customPrice,
                    });
                    if (isNaN(colorFactor)) {
                        NoPriceCauses.push('alushell color');
                    }
                    sumSashesPrice += s.baseValue;
                    sumSashesPriceFactored += s.baseValue * colorFactor;
                    factors *= 1 + sumSashesPriceFactored / sumSashesPrice / 100;
                });
            } else {
                colorFactor = this.PriceColorsService.getColorFactor({
                    NoPriceCauses,
                    colorType: 'Alushell',
                    colorGroups,
                    colorGroupsPrices,
                    colors,
                    system,
                    wood,
                    sash: [],
                    sashId: [],
                    divId: [],
                    drawData,
                    sashes,
                    mullions,
                    customPrice,
                });
                factors = 1 + colorFactor / 100;
                if (isNaN(colorFactor)) {
                    NoPriceCauses.push('alushell color');
                }
            }

            priceSegments.push({
                type: 'colorAlushell',
                baseValue: factors,
                value: factors,
                valueType: 'percent',
                data: {},
            });
        }
        return priceSegments;
    }

    /**
     * Dolicza dopłatę procentową za nakładkę aluminiową.
     *
     * @param {number} price         Cena wejściowa
     * @param {object} PriceElems    Składowe ceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} system        System
     * @param {bool}   alushell      Czy wybrana nakładka aluminiowa
     * @return {number} Cena z dopłatą
     */
    @PriceFunc({
        shortName: 'alushellPercent',
        data: {
            alushell: 'conf.HasAlushell',
            system: 'conf.System',
        },
    })
    suppAlushellPercent({  }: PriceElemsData, { system, alushell }): PriceSegment[] {
        let factors = 1;
        if (alushell && system.alushell_factor) {
            factors *= 1 + parseFloat(system.alushell_factor) / 100;
        }

        return [
            {
                type: 'alushellPercent',
                baseValue: factors,
                value: factors,
                valueType: 'percent',
                data: {},
            },
        ] as PriceSegment[];
    }

    /**
     * Jeżeli s ograniczenia kostrukcji to musza pasowac wzmocnienia
     *
     * @method     suppAlushell
     * @param      {Number}  price             Cena
     * @param      {Object}  PriceElems        Cena elementów
     * @param      {Object}  NoPriceCauses     Powód braku ceny
     * @param      {Sash[]}  sashes            Skrzydła
     * @return     {Number}  Cena
     */
    @PriceFunc({
        shortName: 'constructionLimitations',
        data: {
            sashes: 'conf.Sashes',
        },
    })
    checkConstructionLimitations(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { sashes }
    ): PriceSegment[] {
        let i = 0;
        if (Common.isArray(sashes) && this.config().IccConfig.Configurators.constructionLimitation) {
            for (i = 0; i < sashes.length; i++) {
                if (
                    Common.isObject(sashes[i].reinforcement)
                    && ~~sashes[i].reinforcement.id === 0
                ) {
                    NoPriceCauses.push('no construction limitation for sash');
                    return [
                        {
                            type: 'constructionLimitations',
                            baseValue: null,
                            value: null,
                            valueType: 'value',
                            data: {
                                sashId: sashes[i].id,
                            },
                        },
                    ] as PriceSegment[];
                }
            }
        }

        return [];
    }

    private getDividerSign(orientation: 'vertical' | 'horizontal', isCoupling = false) {
        switch (`${orientation}_${isCoupling ? 'coupling' : 'mullion'}`) {
            case 'vertical_mullion':
                return '|';
            case 'horizontal_mullion':
                return '_';
            case 'vertical_coupling':
                return '+';
            case 'horizontal_coupling':
                return '=';
        }
    }
}
