import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
} from '@angular/core';

@Component({
    selector: 'icc-select-box',
    template: require('./select-box.component.html'),
    styles: [require('./select-box.component.scss')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBoxComponent implements OnInit {
    constructor() {}

    @Input() disabled = false;
    @Input() disabledButton1 = false;
    @Input() disabledButton2 = false;
    @Input() disabledButton3 = false;
    @Input() title?: string;
    @Input() description?: string;
    @Input() imageUrl = '';
    @Input() color?: string;
    @Input() buttonText?: string;
    @Input() buttonText2?: string;
    @Input() buttonText3?: string;
    @Input() colorSelect = false;
    @Input() descriptionTemplate?: TemplateRef<any>;
    @Input() descriptionContext?: any;

    @Output() select = new EventEmitter();
    @Output() select2 = new EventEmitter();
    @Output() select3 = new EventEmitter();

    ngOnInit() {}
}
