import { Component, Inject, OnInit } from '@angular/core';
import { ConfiguratorOptions, ICC_PAGE_DATA, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-type-info',
  template: require('./type-info.component.html'),
  styles: [require('./type-info.component.scss')]
})
export class TypeInfoComponent extends StepComponent implements OnInit {
  public configurator: string = 'roller_shutter';
  public stepId: string;
  public title = (options?: ConfiguratorOptions) => options.type === 'external_blind'? _('ROLLERSHUTTER|Wybierz rodzaj żaluzji') : _('ROLLERSHUTTER|Wybierz rodzaj rolety');
  system: any;
  button: any;
  descriptionFieldName: any;
  imageFieldName: any;
  imageDirectory: any;

  constructor(
    private sharedFacade: SharedFacade,
    @Inject(ICC_PAGE_DATA) private pageData: {
      system: any,
      button: boolean,
      descriptionFieldName: string,
      imageFieldName: string,
      imageDirectory: string
  }
  ) {
    super();
  }

  ngOnInit() {
    this.system = this.pageData.system || {};
    this.button = this.pageData.button;
    this.descriptionFieldName = this.pageData.descriptionFieldName || 'content';
    this.imageFieldName = this.pageData.imageFieldName || 'title_image';
    this.imageDirectory = this.pageData.imageDirectory || 'windowline';
}

  close() {
    this.sharedFacade.closePage(true);
  } 

}
