import { Reinforcement } from './Reinforcement';
import { Common } from '@icc/common/Common';

/** Słupek, poprzeczka, szpros konstrukcyjny */
export class MullionV2 {
    /** Identyfikator */
    id: number;
    /** Ustawione domyśnie */
    isDefault: boolean;
    /** Identyfikator ramy */
    frameId: number | null;
    /** Id profilu */
    profileId: number;
    /** Pozycja słupka na osi prostopadłej */
    position: number;
    /** Pozycja słupka na osi równoległej */
    shift: number;
    /** Długość */
    length: number;
    /** Orientacja słupka */
    direction: 'horizontal' | 'vertical';
    /** Sąsiednie kwatery/pola */
    adjacentSashes: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Prostopadłe słupki */
    perpendicularMullions: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Równoległe słupki */
    parallelMullions: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Prostopadłe wyrównania */
    perpendicularAlignments: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Równoległe wyrównania */
    parallelAlignments: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    /** Wzmocnienie */
    reinforcement: Reinforcement['id'] | null;
    /** Typ słupka */
    type: string;

    constructor(desc: {
        id: number;
        profileId: number;
        frameId?: number;
        position: number;
        shift: number;
        length: number;
        direction: 'horizontal' | 'vertical';
        adjacentSashes: {
            top?: number[];
            bottom?: number[];
            left?: number[];
            right?: number[];
        };
        perpendicularMullions: {
            top?: number[];
            bottom?: number[];
            left?: number[];
            right?: number[];
        };
        parallelMullions: {
            top?: number[];
            bottom?: number[];
            left?: number[];
            right?: number[];
        };
        perpendicularAlignments: {
            top?: number[];
            bottom?: number[];
            left?: number[];
            right?: number[];
        };
        parallelAlignments: {
            top?: number[];
            bottom?: number[];
            left?: number[];
            right?: number[];
        };
        type: string;
    }) {
        this.id = desc.id;
        this.isDefault = true;
        this.frameId = Common.isDefined(desc.frameId) ? desc.frameId : null;
        this.profileId = desc.profileId;
        this.position = desc.position || 0;
        this.shift = desc.shift || 0;
        this.length = desc.length || 0;
        this.direction = desc.direction || 'vertical';
        this.adjacentSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.perpendicularMullions = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.parallelMullions = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.perpendicularAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.parallelAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.type = desc.type || 'no_mullion';
        (['left', 'right', 'top', 'bottom'] as const).forEach(side => {
            if (Common.isDefined(desc.adjacentSashes[side])) {
                this.adjacentSashes[side] = desc.adjacentSashes[side];
            }
            if (Common.isDefined(desc.perpendicularMullions[side])) {
                this.perpendicularMullions[side] = desc.perpendicularMullions[side];
            }
            if (Common.isDefined(desc.parallelMullions[side])) {
                this.parallelMullions[side] = desc.parallelMullions[side];
            }
            if (Common.isDefined(desc.perpendicularAlignments[side])) {
                this.perpendicularAlignments[side] = desc.perpendicularAlignments[side];
            }
            if (Common.isDefined(desc.parallelAlignments[side])) {
                this.parallelAlignments[side] = desc.parallelAlignments[side];
            }
        });
        this.reinforcement = null;
    }
}
