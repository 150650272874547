import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA, isObject, isString } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { WindowActiveConfiguration, core } from '@icc/common';
import {
    IccColor,
    IccAccessoryAccessory,
    IccMaterial,
    IccColorGroup,
} from '@icc/common/data-types';
import { Profile } from '@icc/window';
import { ColorType } from '@icc/common/configurations/parts/common';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'icc-accessories-colors-page',
    template: require('./accessories-colors-page.component.html'),
    styles: [require('./accessories-colors-page.component.scss')],
    providers: [
        ColorsService
    ]
})
export class AccessoriesColorsPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz kolor dodatku');

    config = {
        type: 'accessory',
        Colors: (!isString(this.pageData.accessory.selectedColor)
            && this.pageData.accessory.selectedColor) || {
            frame: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
        },
        HasAlushell: false,
        AlushellType: '',
        System: {
            type:
                this.pageData.material
                || (this.pageData.type !== 'profile' && this.pageData.accessory.material),
        },
        ColorType: 'White',
        Wood: (this.pageData.type === 'profile' && this.pageData.accessory.selectedWood) || {},
        ColorsSashExt: false,
        valid: {},
    };

    configDefault = {
        type: 'accessory',
        Colors: (!isString(this.pageData.accessory.selectedColor)
            && this.pageData.accessory.selectedColor) || {
            frame: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
        },
        HasAlushell: false,
        AlushellType: '',
        System: {
            type: this.pageData.type !== 'profile' && this.pageData.accessory.material,
        },
        ColorType: 'White',
        Wood: (this.pageData.type === 'profile' && this.pageData.accessory.selectedWood) || {},
        ColorsSashExt: false,
        valid: {},
    };

    hasCream = false;
    showCore = false;

    constructor(
        private modalService: ModalService,
        private sharedFacade: SharedFacade,
        private colorsService: ColorsService,
        @Inject(ICC_PAGE_DATA)
        private pageData:
            | {
                  accessory: IccAccessoryAccessory;
                  type: 'accessory';
                  colors$: BehaviorSubject<any>;
                  material?: IccMaterial;
              }
            | {
                  accessory: Profile;
                  type: 'profile';
                  colors$: BehaviorSubject<any>;
                  material?: IccMaterial;
              }
    ) {
        super();
        for (let j = 0; j < colorsService.windowColors.length; j++) {
            if (
                colorsService.windowColors[j].sides.indexOf('F|C') > -1
                && colorsService.windowColors[j].type === 'cream'
            ) {
                this.hasCream = true;
                break;
            }
        }

        colorsService.loadData();
        this.setDefaults();
        this.filter = group => {
            if (this.pageData.type === 'profile') {
                return Boolean(
                    group
                        && group.target.indexOf('show') > -1
                        && this.pageData.accessory.systems.some(
                            systemId =>
                                group.systems
                                && group.systems.map(Number).indexOf(Number(systemId)) > -1
                        )
                );
            }
            return Boolean(
                group
                    && typeof this.pageData.accessory.conf_color_groups_ids !== 'undefined'
                    && this.pageData.accessory.conf_color_groups_ids
                        .map(Number)
                        .indexOf(Number(group.id)) > -1
            );
        };
        this.filterColors = color => {
            if (this.pageData.type === 'profile') {
                return Boolean(
                    this.pageData.accessory.systems.some(
                        systemId =>
                            color
                            && color.systems
                            && color.systems.map(Number).indexOf(Number(systemId)) > -1
                    )
                );
            }
            return Boolean(
                (color
                    && this.pageData.accessory.conf_colors_ids
                        .map(Number)
                        .indexOf(Number(color.id)) > -1)
                    || (color
                        && color.groups
                        && this.pageData.accessory.conf_color_groups_ids
                            .map(Number)
                            .some(
                                c =>
                                    color
                                    && color.groups
                                    && color.groups.map(Number).indexOf(Number(c)) > -1
                            ))
            );
        };
    }

    ngOnInit() {
        this.pageData.colors$.subscribe(() => {
            this.config.Colors = (!isString(this.pageData.accessory.selectedColor)
                && this.pageData.accessory.selectedColor) || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
            };
            this.configDefault.Colors = (!isString(this.pageData.accessory.selectedColor)
                && this.pageData.accessory.selectedColor) || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
            };
        });
    }

    setColorType(type: ColorType) {
        this.colorsService.setColorType(
            type,
            false,
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration,
            this.filterColors.bind(this)
        );

        if (isObject(this.config.Colors)) {
            this.config.Colors.sash = this.config.Colors.frame;
        }
        if (isObject(this.configDefault.Colors)) {
            this.configDefault.Colors.sash = this.configDefault.Colors.frame;
        }
    }
    openModalWood() {
        this.colorsService.openModalWood(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration,
            this.setDefaults.bind(this)
        );
    }

    openModalColor(type: 'outer' | 'inner' | 'alushell') {
        this.colorsService
            .openModalColorSimple(
                type,
                'frame',
                this.config as WindowActiveConfiguration,
                this.filter.bind(this),
                false
            )
            .then(() => {
                this.pageData.accessory.selectedColor = core.copy(this.config.Colors);
                this.pageData.colors$.next(this.pageData.accessory.selectedColor);
            });
    }

    filter: (color?: IccColorGroup) => boolean = group => true;
    filterColors: (color?: IccColor) => boolean = color => true;

    setDefaults() {
        this.colorsService.loadColorsBySystem(this.filterColors.bind(this));

        this.colorsService.setDefaultColorTypeForSystem(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration
        );
        this.colorsService.setDefaultWood(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration
        );
        this.colorsService.loadColorsByWood(this.config as WindowActiveConfiguration);
        this.colorsService.setDefaultColors(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration,
            this.filterColors.bind(this)
        );
        this.colorsService.setDefaultColorTypeForColors(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration
        );
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.sharedFacade.closePage({ colors: this.config.Colors, wood: this.config.Wood });
    }
}
