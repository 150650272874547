import { Common } from './Common';
import { core } from './helpers';

/**
 * Usługa liczenia ceny
 *
 * @export
 * @class PossitionDetailedSummary
 */
export class PositionDetailedSummaryService {
    /**
     * Creates an instance of PriceService.
     *
     * @param {ng.ui.bootstrap.IModalService} $uibModal $uibModal
     * @param {any} ConfigurationsService ConfigurationsService
     * @param {PriceBaseService} PriceBaseService PriceBaseService
     *
     * @memberOf PriceService
     */
    constructor() {}

    /**
     * Szczegoly do podsumowania
     * @param  {object} summary  Podsumowanie
     * @param  {object} position Pozycja oferty
     * @return {object}          Szczegoly podsumowania
     */
    static detailedSummary(position, IccConfig, summary = null) {
        const details = position.details;
        let quantity = position.quantity;
        if (summary === null) {
            quantity = 1;
            summary = {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                rollerType: 'rollerSupps',
            };
        }

        if (Common.isDefined(details) && details) {
            if (position.confType === 'additional') {
                if (Common.isDefined(summary.client.additionals[details.goodType])) {
                    summary.client.additionals[details.goodType] +=
                        position.client_price_before_discount * quantity;
                }
            } else if (position.confType === 'other') {
                if (Common.isDefined(summary.client.others[details.goodType])) {
                    summary.client.others[details.goodType] +=
                        position.client_price_before_discount * quantity;
                    summary.dealer.others[details.goodType] +=
                        position.dealer_price_before_discount * quantity;
                }
            } else if (position.confType === 'custom') {
                summary.client.components.base += position.client_price_before_discount * quantity;
                summary.dealer.components.base += position.dealer_price_before_discount * quantity;
            } else if (position.confType === 'colors_cost') {
                summary.client.components.colorCost +=
                    position.client_price_before_discount * quantity;
                summary.dealer.components.colorCost +=
                    position.dealer_price_before_discount * quantity;
            } else {
                const summaryParts = core.copy(IccConfig.Offer.summary);
                if (details.type === 'roller_shutter' || details.type === 'external_blind') {
                    summaryParts.base.push(...summaryParts.roller);
                    summaryParts.roller = [];
                }

                if (Common.isDefined(details.priceParts)) {
                    details.priceParts
                        .filter(part => part.value != 0)
                        .forEach(part => {
                            for (const i in summaryParts) {
                                if (
                                    Common.isArray(summaryParts[i])
                                    && this.isInSummaryParts(part, summaryParts[i])
                                ) {
                                    summary.client.components[i] += part.value * quantity;
                                }
                            }
                        });
                    details.pricePartsNoMargin
                        .filter(part => part.value != 0)
                        .forEach(part => {
                            for (const i in summaryParts) {
                                if (
                                    Common.isArray(summaryParts[i])
                                    && this.isInSummaryParts(part, summaryParts[i])
                                ) {
                                    summary.dealer.components[i] += part.value * quantity;
                                }
                            }
                        });
                    if (
                        this.isInSummaryParts(
                            { valueType: 'rollerBoxes', percentType: null },
                            summaryParts.roller
                        )
                    ) {
                        summary.rollerType = 'roller';
                    }
                }
            }
        }

        return summary;
    }

    static isInSummaryParts(part, summaryParts) {
        return summaryParts.some(
            s =>
                (s[0] == part.valueType
                    || s[0] == '*'
                    || (Common.isArray(s[0]) && s[0].indexOf(part.valueType) > -1))
                && (s[1] == part.percentType
                    || s[1] == '*'
                    || (Common.isArray(s[1]) && s[1].indexOf(part.percentType) > -1))
        );
    }

    /**
     * Sumuje rabaty dealera
     * @param  {array} discounts Tabliza rabatów
     * @return {number}          Suma wartości
     */
    static sumDiscounts(discounts, priceType = 'dealer') {
        if (!priceType || priceType === 'valuation') {
            return 0;
        }
        return discounts.reduce((prev, e) => (e[priceType] ? prev + e[priceType].value : prev), 0);
    }
}
