import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';

import { CoupledWindowActiveConfiguration } from './CoupledWindowActiveConfiguration';
import { WindowConfiguration } from './WindowConfiguration';
import { Coupling, SideProfile, Profile, WindowParameters } from '@icc/window';
import { Accessory } from './parts/common';
import { RollerShutter } from './parts/roller_shutter';
import { WindowInCoupling, RollerShutterInCoupling } from './parts/coupled_window';
import { Sill } from './parts/complementary_goods';

export class CoupledWindowConfiguration implements Configuration {
    $version = 1;
    type: 'coupled_window' = 'coupled_window';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    parameters: WindowParameters = {
        weight: null,
    };
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    height: number = 1500;
    width: number = 1500;
    windows: WindowInCoupling[] = [];
    couplings: Coupling[] = [];
    sideProfiles: SideProfile[] = [];
    sideAccessories: Accessory[] = [];
    rollerShutters: RollerShutterInCoupling[] = [];
    windowSills: Sill[] = [];

    usedProfiles: Profile[] = [];

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (CoupledWindowConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (CoupledWindowActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.attributes = configuration.attributes;

                this.parameters = configuration.parameters;

                this.windows = configuration.windows;
                this.height = configuration.height;
                this.width = configuration.width;
                this.rollerShutters = configuration.rollerShutters;

                this.couplings = configuration.couplings;
                this.sideProfiles = configuration.sideProfiles;
                this.windowSills = configuration.windowSills;
                this.usedProfiles = configuration.usedProfiles;
            }
        }
    }

    public static is(configuration): configuration is CoupledWindowConfiguration {
        return configuration instanceof CoupledWindowConfiguration || configuration.$version;
    }
}
