import angular from 'angular';
import $ from 'jquery';
/**
 * Kontroler szklenia
 */
export default function GlazingCtrl($scope, $window, $filter, $uibModal, $rootScope, flashMessage, $timeout, Core, // jshint ignore:line
                    FillingValidationService, ConfigurationsService, glassCatFilter, InfoService, CurConfService, glassOrnamentFilter,
                    GlazingBeadsService, GlazingBeadColorsService, SealColorsService, WarmEdgesService, FillingsService, EventBusService,
                    ProfilesService, DrawService, IccConfig, ScrollbarService, SiliconeColorsService, FillingsColorsService) {
    'ngInject';

    var vm = this;
    vm.subStep = 1;
    vm.selGlassType = {};
    if (angular.isArray(ConfigurationsService.conf.Current.Sashes) && ConfigurationsService.conf.Current.Sashes.length > 0) {
        vm.selGlassType = ConfigurationsService.conf.Current.Sashes[0].glazing;
    }
    vm.openModalGlazing = openModalFilling;
    vm.openModalGlazingBead = GlazingBeadsService.openModalGlazingBead.bind(GlazingBeadsService);
    vm.openModalWarmEdge = openModalWarmEdge;
    vm.openModalDecoPanelOptions = openModalPanelOptions;
    vm.valid = FillingValidationService.valid.bind(FillingValidationService);
    vm.selectedSecurity = FillingsService.modalGlassTypeData.selectedSecurity;
    vm.selectedOrnament = FillingsService.modalGlassTypeData.selectedOrnament;
    vm.selectedUg = FillingsService.modalGlassTypeData.selectedUg;
    vm.selectedRw = FillingsService.modalGlassTypeData.selectedRw;
    vm.goToSubstep = goToSubstep;
    vm.UgFilter = UgFilter;
    vm.refresh = refresh;
    vm.changeSubCategory = changeSubCategory;
    vm.selectGlass = selectGlass;
    vm.openInfo = openInfo;
    vm.openInfoGlass = openInfoGlass;
    vm.openModalBeadColor = openModalBeadColor;
    vm.openModalSealColor = openModalSealColor;
    vm.openModalSiliconeColor = openModalSiliconeColor;
    vm.getBackgroundImage = getBackgroundImage;
    vm.getBackgroundColor = getBackgroundColor;
    vm.getDoorSash = getDoorSash;
    vm.doorOneGlazingType = doorOneGlazingType;
    vm.doorFillType = 0;
    vm.glazingBead = null;
    vm.glazingBeadFix = null;
    vm.glazingBeadSashes = null;
    vm.openModalColors   = FillingsColorsService.openModalColors;
    vm.openModalDecoPanelColors   = FillingsColorsService.openModalDecoPanelColors;
    vm.selectColor = FillingsColorsService.selectColor;
    vm.getFillingColorsDescription = getFillingColorsDescription;

    vm.drawOptions = angular.merge({}, DrawService.options, {
        glazing: {
            buttons: [
                {
                    innerHtml: `<img src="/application/dist/web/img/icons/gr_glazzing.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz szklenie'),
                    onClick: response => {
                        if (angular.isDefined(response.sash.parentId)) {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.parentId);
                            const intSash = Core.fIdO(sash.intSashes, response.sash.id);
                            FillingsService.openModal(intSash, ConfigurationsService.conf.Current, ConfigurationsService.conf.Default, sash);
                        } else {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.id);
                            FillingsService.openModal(sash, ConfigurationsService.conf.Current, ConfigurationsService.conf.Default, sash);
                        }
                    },
                },
                {
                    condition: () => (!ConfigurationsService.conf.Current.OneGlazing || IccConfig.Configurators.glazingBeadsSelectSash) && IccConfig.Configurators.glazingBeadsSelect,
                    innerHtml: `<img src="/application/dist/web/img/icons/gr_glazzing2.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz listwę przyszybową'),
                    onClick: response => {
                        if (angular.isDefined(response.sash.parentId)) {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.parentId);
                            const intSash = Core.fIdO(sash.intSashes, response.sash.id);
                            GlazingBeadsService.openModalGlazingBead(ConfigurationsService.conf.Current, intSash, sash);
                        } else {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.id);
                            GlazingBeadsService.openModalGlazingBead(ConfigurationsService.conf.Current, sash, sash);
                        }
                    },
                },
                {
                    condition: response => response.glazing.type === 'pvc_panels' && IccConfig.Configurators.pvcPanelsDifferentColors,
                    innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz kolor panelu wypełnieniowego'),
                    onClick: response => {
                        if (angular.isDefined(response.sash.parentId)) {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.parentId);
                            const intSash = Core.fIdO(sash.intSashes, response.sash.id);
                            FillingsColorsService.openModalColors(intSash.glazing, true);
                    } else {
                            const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.id);
                            FillingsColorsService.openModalColors(sash.glazing, true);
                        }
                    },
                },
                {
                    innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz kolor panelu dekoracyjnego'),
                    condition: response => response.glazing.type === 'deco_panels' || response.glazing.type === 'door_panels',
                    onClick: response => FillingsColorsService.openModalDecoPanelColors(ConfigurationsService.conf.Current, response.sash.id, false, true),
                },
                {
                    innerHtml: `<img src="/application/dist/web/img/icons/glazing_pvc.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz drugi kolor panelu dekoracyjnego'),
                    condition: response => (response.glazing.type === 'deco_panels' || response.glazing.type === 'door_panels') && response.glazing.availableSecondColor,
                    onClick: response => FillingsColorsService.openModalDecoPanelColors(ConfigurationsService.conf.Current, response.sash.id, true, true),
                },
            ],
        },
        onInterfaceClick: () => {
            $scope.$digest();
        }
    });

    init();

    if ($scope.config.glazingSubStep > 1) {
        vm.subStep = $scope.config.glazingSubStep;
    }

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code != 'glazing' && oldVal.code == 'glazing' && newVal.code !== oldVal.code) {
            vm.valid();
        }
    });

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    subscriptions.push(EventBusService.subscribe('setGlazingBeadInSash', () => {
        setGlazingBeadData();
    }));
    subscriptions.push(EventBusService.subscribe('changedFillings', () => {
        vm.valid();
    }));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    function setGlazingBeadData() {
        if (ConfigurationsService.conf.Current.Sashes.length > 0) {
            const profile = ProfilesService.getProfile(ConfigurationsService.conf.Current.Sashes[0].glazingBead.profileId);
            if (profile) {
                const profileShape = ProfilesService.getProfileShape(profile.profileShapeId);
                vm.glazingBead = {
                    name : profile.name,
                    image: profile.img,
                    shape: profileShape ? profileShape.name : ''
                };
            }
        }
        if (ConfigurationsService.conf.Current.OneGlazingBeadSash.fix) {
            const profile = ProfilesService.getProfile(ConfigurationsService.conf.Current.OneGlazingBeadSash.fix);
            if (profile) {
                const profileShape = ProfilesService.getProfileShape(profile.profileShapeId);
                vm.glazingBeadFix = {
                    name : profile.name,
                    image: profile.img,
                    shape: profileShape ? profileShape.name : ''
                };
            }
        }
        if (ConfigurationsService.conf.Current.OneGlazingBeadSash.sashes) {
            const profile = ProfilesService.getProfile(ConfigurationsService.conf.Current.OneGlazingBeadSash.sashes);
            if (profile) {
                const profileShape = ProfilesService.getProfileShape(profile.profileShapeId);
                vm.glazingBeadSashes = {
                    name : profile.name,
                    image: profile.img,
                    shape: profileShape ? profileShape.name : ''
                };
            }
        }
    }


    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (!FillingsService.fillings || !FillingsService.fillings.length) {
            FillingsService.loadMatchingFillings(ConfigurationsService.conf.Current);
        }
        vm.fillings = FillingsService.getMatchingFillings(ConfigurationsService.conf.Current);
        vm.doorGlassTypes = FillingsService.getMatchingFillings(ConfigurationsService.conf.Current);
        vm.fillingsCategories = FillingsService.fillingsCategories;
        setGlazingBeadData();
        vm.fillingsCategories = vm.fillingsCategories.filter(function(el) {
            return glassCatFilter(vm.fillings, el.id, null).length > 0;
        });
        vm.areOrnaments = glassOrnamentFilter(vm.fillings, true).length > 0;
        vm.valid();
    }

    /**
     * Funkcja przejścia do krou
     * @param  {object} subStep subStep
     */
    function goToSubstep(subStep) {
        vm.subStep = subStep;
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: $scope.config.stepCode + vm.subStep
            });
        }

            if (IccConfig.Configurators.tutorialAvailable)
                if (subStep == 2) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'glazingSashes'
                 });
            } else if (subStep == 1) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                 });
            }

    }

    /**
     * Funkcja filtra Ug
     * @param {object} el El
     */
    function UgFilter(el) {
        if (angular.isUndefined(vm.selectedUg)
            || vm.selectedUg === null
            || vm.selectedUg === ''
            || parseFloat(el.u) <= parseFloat(vm.selectedUg)) {
            if (angular.isUndefined(vm.selectedRw) || vm.selectedRw === null || vm.selectedRw === '' || (parseInt(el.rw) >= parseInt(vm.selectedRw))) {
                return true;
            }
        }
        return false;
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja zmieniajaca podkategorię
     */
    function changeSubCategory() {
        vm.selectedSubcategory = null;
        vm.glassTypesSubcategories = FillingValidationService.glassTypesSubcategories.filter(function(el) {
            return glassCatFilter(vm.fillings, vm.selectedCategory, el.id).length > 0;
        });
        vm.refresh();
    }

    /**
     * Funkcja wybierania szkła
     * @param  {object} glassType Typ szyby
     */
    function selectGlass(glassType) {
        FillingsService.setFillingInAllSashes(ConfigurationsService.conf.Current, glassType);
        vm.selGlassType = ConfigurationsService.conf.Current.Sashes[0].glazing;
    }

    /**
     * Funkcja otwierajaca okno informacyjne
     */
    function openInfo() {
        InfoService.openInfoModal({template: 'glassInfo.html', title: $filter('translate')('INTERFACE|Informacja')});
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'empty'
             });

        }
    }

    /**
     * Funkcja otwierajaca okno informacji dla szyb
     * @param  {object} glass Szyba
     */
    function openInfoGlass(glass) {
        InfoService.openInfoModal({template: 'glassInfoOrnament.html', title: $filter('translate')('INTERFACE|Podgląd'), data: {glass}});
    }

    /**
     * Funkcja otwierajaca okno modal z kolorem
     */
    function openModalBeadColor() {
        GlazingBeadColorsService.openModalBeadColor(ConfigurationsService.conf.Current, ConfigurationsService.conf.Default);
    }

    /**
     * Funkcja otwierajaca okno modal z kolorem uszczelek
     */
    function openModalSealColor() {
        SealColorsService.openModal(ConfigurationsService.conf.Current, ConfigurationsService.conf.Default);
    }

    /**
     * Funkcja otwierajaca okno modal z kolorem uszczelek
     */
    function openModalSiliconeColor() {
        SiliconeColorsService.openModal(ConfigurationsService.conf.Current, ConfigurationsService.conf.Default);
    }

    function openModalWarmEdge() {
        WarmEdgesService.openModal(ConfigurationsService.conf.Current, ConfigurationsService.conf.Default);
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'glazingEdge'
            });
        }

    }

    function openModalFilling(field, sash) {
        FillingsService.openModal(field, ConfigurationsService.conf.Current, ConfigurationsService.conf.Default, sash);
    }

    function openModalPanelOptions(field, sash) {
        FillingsService.openModalPanelOptions(field, ConfigurationsService.conf.Current, sash);
    }

    /**
     * Zwraca tło kontrolki.
     * @memberof ColorBoxCtrl
     * @return {string} Link do tła kontrolki.
     */
    function getBackgroundImage() {
        if (angular.isUndefined(ConfigurationsService.conf.Current.SealColor.id)) {
            return 'url(/application/dist/web/img/layouts/default/config/nocolor.png)';
        } else {
            return ConfigurationsService.conf.Current.SealColor.color_img ? ('url(/files/windowsealcolor/' + ConfigurationsService.conf.Current.SealColor.color_img + ')') : 'none';
        }
    }

    /**
     * Zwraca kolor tła kontrolki.
     * @memberof ColorBoxCtrl
     * @return {string} Kolor tła w formacie #hhhhhh.
     */
    function getBackgroundColor(type) {
        return '#' + (type === 'seal' ? ConfigurationsService.conf.Current.SealColor.color : (type === 'silicone' ? ConfigurationsService.conf.Current.SiliconeColor.color : 'fff'));
    }

    function getDoorSash(sash) {
        if (typeof sash === 'undefined' || sash == 'default') {
            sash = ConfigurationsService.conf.Current.Sashes[0];
        } else if (sash == 'doorActive') {
            sash = ConfigurationsService.conf.Current.Sashes.find(s => s.type.type == 'DRA' || s.type.type == 'DOA');
        } else if (sash == 'doorPassive') {
            sash = ConfigurationsService.conf.Current.Sashes.find(s => s.type.type == 'DRP' || s.type.type == 'DOP');
        } else if (sash == 'window') {
            sash = ConfigurationsService.conf.Current.Sashes.find(s => s.type.type != 'DRA' && s.type.type != 'DRP' && s.type.type != 'DOA' && s.type.type != 'DOP');
        }
        return sash;
    }

    function getFillingColorsDescription(sash, second = false) {
        const doorSash = getDoorSash(sash);
        let innerColorName = '';
        let outerColorName = '';

        const colorKey = second ? 'selectedColorSecond' : 'selectedColor';

        if (doorSash && doorSash.glazing && doorSash.glazing[colorKey] && doorSash.glazing[colorKey].frame) {
            if (doorSash.glazing[colorKey].frame.inner && doorSash.glazing[colorKey].frame.inner.name) {
                innerColorName = doorSash.glazing[colorKey]
                .frame.inner.name;
            } else if (doorSash.glazing[colorKey].frame.core && doorSash.glazing[colorKey].frame.core.name) {
                innerColorName = doorSash.glazing[colorKey].frame.core.name;
            }

            if (doorSash.glazing[colorKey].frame.outer && doorSash.glazing[colorKey].frame.outer.name) {
                outerColorName = doorSash.glazing[colorKey].frame.outer.name;
            } else if (doorSash.glazing[colorKey].frame.core && doorSash.glazing[colorKey].frame.core.name) {
                outerColorName = doorSash.glazing[colorKey].frame.core.name;
            }
        }

        return `${innerColorName}/${outerColorName}`;
    }

    function doorOneGlazingType(conf) {
        return conf.OwnedSashesTypes.doorActive && conf.Sashes.length == 1 ? 'doorActive' : 'default';
    }
}
