import { MuntinsTypesModalComponent } from './../muntins-types-modal/muntins-types-modal.component';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, StepComponent, ModalService, ConfiguratorOptions } from '@icc/configurator/shared';
import { MuntinsColorsComponent } from '../muntins-colors/muntins-colors.component';
import { MuntinsLayoutService } from '@icc/legacy/configurator/steps/window/muntins/muntins-layout.service';
import { MuntinColorsService } from '@icc/legacy/configurator/steps/window/muntins/muntin-colors.service';
import { WindowFacade } from '../+state/window.facade';
import { ConfigurationsService, EventBusService, WindowActiveConfiguration, AppConfigFactory, APP_CONFIG } from '@icc/common';
import { MuntinsService } from '@icc/legacy/configurator/steps/window/muntins/muntins.service';
import { PriceService } from '@icc/price';
import { tap, map, withLatestFrom } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';

@Component({
    selector: 'icc-muntins',
    template: require('./muntins.component.html'),
    styles: [require('./muntins.component.scss')],
})
export class MuntinsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('WINDOW|Szprosy');
    static stepIcon = {
        ligature: 'border_inner',
    };

    public configurator = 'window';
    public stepId = 'muntins';
    public title = _('WINDOW|Szprosy');
    public options = [];

    public muntinsColorInner$ = this.windowFacade.selectedMuntinColorInner$;
    public muntinsColorOuter$ = this.windowFacade.selectedMuntinColorOuter$;
    public muntinsType$ = this.windowFacade.selectedMuntinType$;
    public muntinsSizeId$ = this.windowFacade.selectedMuntinSizeId$.pipe(map(id => String(id)));
    public types = this.muntinsService.muntinsTypes;
    public duplex$ = this.windowFacade.selectedDuplex$;

    muntinsDisabled: boolean = false;

    showSecondColor$ = this.windowFacade.colorType$.pipe(
        withLatestFrom(this.windowFacade.hasAlushell$),
        map(
            ([colorType, hasAlushell]) =>
                (colorType !== 'White' && colorType !== 'Bilateral') || hasAlushell
        )
    );

    configuration: any;

    private subscriptions: Subscription[] = [];

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.type !== 'door' || options.config.Configurators.door.hasMuntins;

    constructor(
        private eventBusService: EventBusService,
        private windowFacade: WindowFacade,
        private modalService: ModalService,
        private muntinsService: MuntinsService,
        private muntinsColorsService: MuntinColorsService,
        private muntinsLayoutService: MuntinsLayoutService,
        private priceService: PriceService,
        private configurationsService: ConfigurationsService<'window'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {
        super();
    }

    ngOnInit() {
        this.configuration = this.config().IccConfig.Configurators;
        this.muntinsDisabled = this.configuration.window.canDisableMuntins ? !this.configurationsService.conf.Current.HasMuntins : false;
    }

    changeMuntinsColor() {
        this.modalService.open({
            pageComponent: MuntinsColorsComponent,
        });
    }

    openModalMuntinsLayout() {
        this.muntinsLayoutService.openModalMuntinsLayout(this.configurationsService.conf.Current);
    }

    changeType(type) {
        this.configurationsService.conf.Current.MuntinsData.typeCode = type.value;
        this.muntinsService.changeType(this.configurationsService.conf.Current);
    }

    openModalTypes() {
        this.modalService.open({
            pageComponent: MuntinsTypesModalComponent,
            resolve: {
                muntinTypes: this.types
            }
        }).result.then(result => {
            if (result) {
                this.configurationsService.conf.Current.MuntinsData.typeCode = String(result.id)
                this.muntinsService.changeType(this.configurationsService.conf.Current);
            }
        })
    }

    openModalColor(side: 'out' | 'in') {
        this.muntinsColorsService.openModalColor(this.configurationsService.conf.Current, side);
    }

    changeWidth(selected: any) {
        this.configurationsService.conf.Current.MuntinsData.sizeId = selected.value;
        this.muntinsService.changeWidth(this.configurationsService.conf.Current);
    }

    openModalMuntinsManualLayout() {
        this.muntinsService.openModalMuntinsManualLayout(this.configurationsService.conf.Current);
    }

    changeDuplex(duplex: MatSlideToggleChange) {
        this.configurationsService.conf.Current.MuntinsData.duplex = duplex.checked;
        this.priceService.count();
    }

    /**
     * Sprawdza, czy dla wybranego typu szprosu dostępny jest duplex
     * @returns {boolean}
     */
    isDuplexAvailable() {
        return (
            this.configurationsService.conf.Current.MuntinsData.type.duplex == 0
            && !this.hasPVCPanelsOnAllSashes()
            && this.muntinsService.isDuplexAvailable(
                this.configurationsService.conf.Current.Sashes,
                this.configurationsService.conf.Current.MuntinsData.type.duplex_availability
            )
        );
    }

    /**
     * Sprawdza czy panele są ustawione we wszystkich skrzydłach.
     * @return {Boolean} Czy panele wypełnieniowe są ustawione na wszystkich skrzydłach?
     */
    hasPVCPanelsOnAllSashes() {
        return this.muntinsService.hasPVCPanelsOnAllSashes(this.configurationsService.conf.Current);
    }

    disableAndRemoveMuntins(disable: boolean) {
        if (disable) {
            this.muntinsLayoutService.removeMuntinsInAllSashes(
                this.configurationsService.conf.Current
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
