import { Common } from '../Common';
import { IIccConfig } from '@icc/config';

export function base64Decoder(data, $, location?: string, changeImagePaths=false) {
    if (Common.isDefined(data) && location) {
        const str = $(decodeURIComponent(escape(data)));
        if (changeImagePaths) {
            str.find("pattern > image").each(function(el) {  
                let attr = $(this).attr('href');
                attr = location + attr;
                $(this).attr('href', attr);
            });
        }
        str.find("rect[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("rect[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker-start: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("*[mask*='url']").each(function(el) {
            let attr = $(this).attr('mask');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('mask', attr);
        });
        str.find("*[fill*='url']").each(function(el) {
            let attr = $(this).attr('fill');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('fill', attr);
        });
        str.find("*[stroke*='url']").each(function(el) {
            let attr = $(this).attr('stroke');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('stroke', attr);
        });
        return str[0].outerHTML || str.parent().html();
    } else {
        return data;
    }
}

export function svgImageFilter(data, IccConfig: IIccConfig, $, location?: string, keepUrl?: boolean, changeImagePaths=false) {
    data = base64Decoder(data, $, location, changeImagePaths);

    if (!keepUrl) {
        data = data.replace(/url\(\'(https?|icc-res)\:\/\/.*?\#/g, `url('#`);
        data = data.replace(/url\((https?|icc-res)\:\/\/.*?\#/g, `url(#`);
    }

    data = data.replace(/cdvfile:\/\/localhost\/persistent\/files/g, ``);

    data = data.replace(
        /class="windowSvg[a-zA-Z0-9-\s]*"/g,
        '$0 height="'
            + IccConfig.Drawing.pdf.height
            + '" width="'
            + IccConfig.Drawing.pdf.width
            + '"'
    );
    data = data.replace(/about:blank/g, '');

    data = data.replace(/<\/?icc-draw[^>]*>/g, '');
    data = data.replace(/_ngcontent-[\w-]*(="")?/g, '');
    data = data.replace(/_nghost-[\w-]*(="")?/g, '');

    if (IccConfig.Drawing.pdf.blackWhite) {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
        data = data.replace(/url\(\'?#iccDraw\-[a-z]*\-[a-z0-9\-]{36}\'?\)/g, `#fff`);
        data = data.replace(/stroke\=\"\#bbb\"/g, `stroke="#000"`);
    }

    if (IccConfig.Drawing.pdf.muntins == 'black') {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
    }

    data = data.replace(/href/g,'xlink:href');
    data = data.replace(/xlink:xlink:href/g,'xlink:href');
    data = data.replace(/class="preview"/g,'class="smallWindowPreview smallWindowPreviewX"');

    return data;
}