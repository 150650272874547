import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { IccFilling } from '@icc/common/data-types';

@Component({
  selector: 'icc-model-info',
  template: require('./model-info.component.html'),
  styles: [require('./model-info.component.scss')]
})
export class ModelInfoComponent extends PageComponent implements OnInit {
  public title = _('WINDOW|Podgląd modelu');
  public options = [];

  filling: IccFilling | null = null;
  fillingViewSide: 'inner' | 'outer';
  button = true;

  constructor(
      private sharedFacade: SharedFacade,
      @Inject(ICC_PAGE_DATA) private pageData: {
          filling: IccFilling,
          fillingViewSide?: 'inner' | 'outer'
      }
  ) {
      super();
  }

  ngOnInit() {
      this.filling = this.pageData.filling;
      this.fillingViewSide = this.pageData.fillingViewSide;
  }

  close() {
      this.sharedFacade.closePage(true);
  }

}
