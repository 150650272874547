import { FrameProfile } from './FrameProfile';
import { ShapeType } from './Shape';
import { FrameParameters } from './FrameParameters';

export class Frame {
    id: number;
    index: number;
    x: number;
    y: number;
    width: number;
    height: number;
    frame: FrameProfile[] = [];
    lowThreshold = false;
    shape: ShapeType = 'rect';
    parameters: FrameParameters = {
        ap: null,
        g: null,
        lt: null,
        rw: null,
        ug: null,
        uw: null,
        sw: null,
        weight: null,
        wl: null,
        wp: null,
        lab: null,
    };

    constructor({
        id,
        index,
        x,
        y,
        width,
        height,
    }: {
        id: number;
        index: number;
        x: number;
        y: number;
        width: number;
        height: number;
    }) {
        this.id = id;
        this.index = index;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }
}
