import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from '@icc/helpers';
import { ProfilesColorsPageComponent } from './profiles-colors-page/profiles-colors-page.component';
import { Profile } from '@icc/window';

@Injectable()
export class ProfileColorsService {
    constructor(private modalService: ModalService) {

    }

    async openProfileColorsModal(profile: Profile, selectedProfile: Profile, profilesPrices: any, selectedColors$: BehaviorSubject<any>, agreeButton=false) {
        const modalInstance = this.modalService.open({
            template: require('!raw-loader!@icc/legacy/configurator/steps/complementary_goods/colors/modal.html'),
            controller: 'ModalProfileColorsCtrl as $ctrl',
            pageComponent: ProfilesColorsPageComponent,
            resolve: {
                profile: () => profile,
                profilesPrices: () => profilesPrices,
                selectedProfile: () => selectedProfile,
                selectedColors$: () => selectedColors$,
                agreeButton
            },
        });
        return modalInstance.result;
    }

}
