import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfigFactory, ConfigurationsService } from '@icc/common';
import { ICC_PAGE_DATA, PageComponent } from '@icc/helpers';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryColor, IccSashType } from '@icc/common/data-types';
import { _, SharedFacade } from '@icc/configurator/shared';
import { FormControl } from '@angular/forms';
import { IccHinge } from '@icc/common/data-types/Hinge';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';

@Component({
    selector: 'icc-hinges-options-page',
    template: require('./hinges-options-page.component.html'),
    styles: [require('./hinges-options-page.component.scss')],
})
export class HingesOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('WINDOW|Opcje zawiasów');
    sashType: IccSashType['type'] | null = null;
    systemHeight = false;
    selectedHinge: IccHinge | null = null;
    colors: iccListItem[] = [];
    confType = 'window';

    selHingeColor = '0';
    defaultHingeColor = false;

    selectedHingeImg = '';

    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            hinges:any;
            selectedHinge: IccHinge;
            hingeColors: IccAccessoryColor[];
            selHingeColor: IccAccessoryColor;
        },
        private configurationsService: ConfigurationsService<'window'>,
        private colorsMappingService: ColorMappingService
    ) {
        super();
    }

    ngOnInit() {
        this.selectedHinge = this.pageData.selectedHinge;
        this.confType = this.configurationsService.conf.Current.type;
        this.selHingeColor = this.pageData.selHingeColor.id;
        this.defaultHingeColor = Boolean(this.pageData.selHingeColor.isDefault);
        this.colors = this.pageData.hingeColors
            .filter(
                color =>
                    this.selectedHinge
                    && this.selectedHinge.colors_ids
                    && this.selectedHinge.colors_ids.some(id => Number(id) === Number(color.id))
            )
            .map(color => ({
                id: color.id,
                title: color.name,
                color: '#' + color.color,
                imageUrl: '/files/windowhandlescolor/' + color.color_img,
            }));
        this.setDefaultHingeColors();
        this.selectedHingeImg = this.getHingeImageUrl(this.selectedHinge, this.selHingeColor);
    }


    private setDefaultHingeColors() {
        if ((this.pageData.selectedHinge.colors_ids || []).map(String).indexOf(this.selHingeColor) === -1) {
            const hingeColorsIds = (this.pageData.selectedHinge.colors_ids || []).map(Number);
            const windowColorId = this.colorsMappingService.getWindowColorId(
                this.configurationsService.conf.Current
            );
            const matchedColors = this.colorsMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = matchedColors
                .map(m => hingeColorsIds.filter(c => c === m)[0])
                .filter(m => m);
            if (colors.length) {
                this.selHingeColor = String(colors[0]);
            } else {
                this.selHingeColor = String(hingeColorsIds[0]);
            }
            this.defaultHingeColor = true;
        }
    }

    selectHingeColor(colorItem: iccListItem) {
        const hinge = this.pageData.selectedHinge;
        const color = this.pageData.hingeColors.find(c => c.id === colorItem.id);
        if (hinge && color) {
            this.selHingeColor = color.id;
            this.defaultHingeColor = false;
            this.selectedHingeImg = this.getHingeImageUrl(hinge, this.selHingeColor);
        }
    }

    private getHingeImageUrl(hinge: IccHinge, selectedColor: string): string {
        return hinge.colors_imgs && hinge.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hinge.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hinge.img;
    }

    save() {
        this.sharedFacade.closePage({
            hinge: this.pageData.selectedHinge,
            color: this.selHingeColor,
            defaultColor: this.defaultHingeColor,
        });
    }
}
