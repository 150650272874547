import { Component, OnInit, Inject } from '@angular/core';
import { ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade, PageComponent } from '@icc/configurator/shared';
import { FormBuilder } from '@angular/forms';
import { IIccConfig } from '@icc/config';

export interface GlazingFilters {
    selectedRw: string;
    selectedUg: string;
    selectedSecurity: string;
    selectedOrnament: string;
    selectedColored: string;
    glazingCount: string;
    selectedOften: boolean;
    q: string;
}

@Component({
    selector: 'icc-fillings-filters-page',
    template: require('./fillings-filters-page.component.html'),
    styles: [require('./fillings-filters-page.component.scss')],
})
export class FillingsFiltersPageComponent extends PageComponent implements OnInit {
    public title: string = _('WINDOW|Filtry pakietów szybowych');

    type: 'simple' | 'extend' | 'extend2' = 'simple';
    filtersGroup = this.fb.group({
        selectedRw: [''],
        selectedUg: [''],
        selectedSecurity: [''],
        selectedOrnament: [''],
        selectedColored: [''],
        glazingCount: [''],
        selectedOften: [''],
        q: [''],
    });
    areOrnaments = false;
    glazingColorSelect = false;
    glassTypes: any[] = [];

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: {
            IccConfig: IIccConfig,
            filters: any,
            areOrnaments: boolean;
            glassTypes: any[];
        },
        private sharedFacade: SharedFacade,
        private fb: FormBuilder
    ) {
        super();
    }

    ngOnInit() {
        this.type = this.pageData.IccConfig.Configurators.glazingFilters;
        this.areOrnaments = this.pageData.areOrnaments;
        this.glazingColorSelect = this.pageData.IccConfig.Configurators.glazingColorSelect;
        this.glassTypes = this.pageData.glassTypes;
        if (this.pageData.filters){
            this.filtersGroup.setValue(this.pageData.filters);
        }
    }

    save() {
        this.sharedFacade.closeFilterSheet({
            filters: this.filtersGroup.value
        });
    }
}
