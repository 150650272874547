import {
    RollerShutter,
    Shutter
} from '../roller_shutter';
import {DriveEngine} from './DriveEngine'
import {SimpleAccessory} from '../common';
import {ConfiguratorsDataService} from '../../../configurators/configurators-data.service';
import {GuideRail} from './GuideRail';
import {Slat} from './Slat';

export class ActiveRollerShutter {
    type            : any = {};
    roundReel       : any = {};
    guideType       : 'pvc' | 'alu' = 'pvc';
    changedSize = false;
    hasAssembly = false;
    inInsulation = false;
    concealedStrip = false;
    mountingLength = 1;
    crankSlope = 45;
    crankLength = 1;
    crankOffset = 1;
    realBoxHeight = 0;
    boxHeightLevel = 0;
    driveSide       : 'L' | 'R' = 'R';
    driveOutputSide: 'rear-up'
        | 'rear-down'
        | 'front-up'
        | 'front-down'
        | 'side' ;
    boxType: 'R' | 'O' = 'R'
    realRollerHeight = 0;
    rollerHeight = 0;
    realBoxWidth = 0;
    boxWidth = 0;
    boxHeight = 0;
    originalBoxHeight = 0;
    driveType       : 'none' | 'override' = 'none';
    driveManual     : any = null;
    driveElements   : any[] = [];
    drive           : any = {};
    hanger          : any = null;
    system          : any = {};
    profile         : any = {};
    shutters        : any[] = [];
    guideRails      : GuideRail[] = [];
    slats           : Slat[] = [];
    schemaId        : string | null = null;
    hasPrices = false;
    railsBottomCut = '0';
    railsHeightModify = 0;
    openingLock = false;
    ratio = 0;
    colors: {
        profile       : any;
        box           : any;
        boxInner      : any;
        boxGuideOuter : any;
        guideRail     : any;
        endslat       : any;
        revision      : any;
        boxSideSurface: any;

        profileGroup       : any;
        boxGroup           : any;
        boxInnerGroup      : any;
        boxGuideOuterGroup : any;
        guideRailGroup     : any;
        endslatGroup       : any;
        revisionGroup      : any;
        boxSideSurfaceGroup: any;

        profileCharge       : any;
        boxCharge           : any;
        boxInnerCharge      : any;
        boxGuideOuterCharge : any;
        guideRailCharge     : any;
        endslatCharge       : any;
        revisionCharge      : any;
        boxSideSurfaceCharge: any;

        profileChargeM2       : any;
        boxChargeM2           : any;
        boxInnerChargeM2      : any;
        boxGuideOuterChargeM2 : any;
        guideRailChargeM2     : any;
        endslatChargeM2       : any;
        revisionChargeM2      : any;
        boxSideSurfaceChargeM2: any;

        profileChargeH       : any;
        boxChargeH           : any;
        boxInnerChargeH      : any;
        boxGuideOuterChargeH : any;
        guideRailChargeH     : any;
        endslatChargeH       : any;
        revisionChargeH      : any;
        boxSideSurfaceChargeH: any;

        profileChargeW       : any;
        boxChargeW           : any;
        boxInnerChargeW      : any;
        boxGuideOuterChargeW : any;
        guideRailChargeW     : any;
        endslatChargeW       : any;
        revisionChargeW      : any;
        boxSideSurfaceChargeW: any;
    } = {
        profile       : {},
        box           : {},
        boxInner      : {},
        boxGuideOuter : {},
        guideRail     : {},
        endslat       : {},
        revision      : {},
        boxSideSurface: {},

        profileGroup       : null,
        boxGroup           : null,
        boxInnerGroup      : null,
        boxGuideOuterGroup : null,
        guideRailGroup     : null,
        endslatGroup       : null,
        revisionGroup      : null,
        boxSideSurfaceGroup: null,

        profileCharge       : null,
        boxCharge           : null,
        boxInnerCharge      : null,
        boxGuideOuterCharge : null,
        guideRailCharge     : null,
        endslatCharge       : null,
        revisionCharge      : null,
        boxSideSurfaceCharge: null,

        profileChargeM2       : null,
        boxChargeM2           : null,
        boxInnerChargeM2      : null,
        boxGuideOuterChargeM2 : null,
        guideRailChargeM2     : null,
        endslatChargeM2       : null,
        revisionChargeM2      : null,
        boxSideSurfaceChargeM2: null,

        profileChargeH       : null,
        boxChargeH           : null,
        boxInnerChargeH      : null,
        boxGuideOuterChargeH : null,
        guideRailChargeH     : null,
        endslatChargeH       : null,
        revisionChargeH      : null,
        boxSideSurfaceChargeH: null,

        profileChargeW       : null,
        boxChargeW           : null,
        boxInnerChargeW      : null,
        boxGuideOuterChargeW : null,
        guideRailChargeW     : null,
        endslatChargeW       : null,
        revisionChargeW      : null,
        boxSideSurfaceChargeW: null,
    };
    driveEngine: DriveEngine[];
    constructor(rollerShutter?: RollerShutter, configuratorsDataService?: ConfiguratorsDataService) {
        if (rollerShutter) {
            this.mapRollerShutter(rollerShutter, configuratorsDataService);
        }
    }

    private mapRollerShutter(rollerShutter: RollerShutter, configuratorsDataService: ConfiguratorsDataService) {
        this.type = {
            type: rollerShutter.type.type,
            id: rollerShutter.type.id,
            name: rollerShutter.type.name,
            static: rollerShutter.type.static,
            title_image: rollerShutter.type.titleImage
        };
        if (rollerShutter.roundReel) {
            this.roundReel = configuratorsDataService.get('roundReels', rollerShutter.roundReel.id);
        }
        this.guideType = rollerShutter.guideType;
        this.guideRails = rollerShutter.guideRails;
        this.slats = rollerShutter.slats;
        this.changedSize = rollerShutter.changedBoxHeight;
        this.hasAssembly = rollerShutter.hasAssembly;
        this.inInsulation = rollerShutter.inInsulation;
        this.concealedStrip = rollerShutter.concealedStrip;
        this.realBoxHeight = rollerShutter.boxHeight;
        this.originalBoxHeight = rollerShutter.originalBoxHeight;
        this.boxHeightLevel = rollerShutter.boxHeightLevel;
        this.driveSide = rollerShutter.driveSide;
        this.driveOutputSide = rollerShutter.driveOutputSide;
        this.realRollerHeight = rollerShutter.rollerHeight;
        this.realBoxWidth = rollerShutter.boxWidth;
        this.driveType = rollerShutter.hasOverride ? 'override' : 'none';
        if (rollerShutter.driveAccessories[0]) {
            this.driveManual = rollerShutter.drive.type === 'manual' ? configuratorsDataService.get('rollerShutterDrivesManuals', rollerShutter.driveAccessories[0].id) : null;
        }
        this.driveElements = rollerShutter.drive.type !== 'manual' ? rollerShutter.driveAccessories.map(this.mapSimpleAccessory(configuratorsDataService).bind(this)).filter(el => el) : [];
        this.drive = {
            ...configuratorsDataService.get('rollerShutterDrives', rollerShutter.drive.id),
            driveEngine: rollerShutter?.drive?.driveEngine ? rollerShutter?.drive?.driveEngine : null
        };
        this.hanger = rollerShutter.hanger ? configuratorsDataService.get('rollerShutterHangers', rollerShutter.hanger.id) : null;
        this.system = configuratorsDataService.get('rollerShutterSystems', rollerShutter.system.id);
        this.profile =  configuratorsDataService.get('rollerShutterProfiles', rollerShutter.profile.id);
        this.shutters = rollerShutter.shutters.map(this.mapShutter.bind(this));
        this.schemaId = rollerShutter.schemaId;
        this.railsBottomCut = rollerShutter.railsBottomCut;
        this.railsHeightModify = rollerShutter.railsHeightModify;
        this.openingLock = rollerShutter.openingLock;
        this.colors = {
            profile       : null,
            box           : null,
            boxInner      : null,
            boxGuideOuter : null,
            guideRail     : null,
            endslat       : null,
            revision      : null,
            boxSideSurface: null,

            profileGroup       : 'color',
            boxGroup           : 'color',
            boxInnerGroup      : 'color',
            boxGuideOuterGroup : 'color',
            guideRailGroup     : 'color',
            endslatGroup       : 'color',
            revisionGroup      : 'color',
            boxSideSurfaceGroup: 'color',

            profileCharge       : 0,
            boxCharge           : 0,
            boxInnerCharge      : 0,
            boxGuideOuterCharge : 0,
            guideRailCharge     : 0,
            endslatCharge       : 0,
            revisionCharge      : 0,
            boxSideSurfaceCharge: 0,

            profileChargeM2       : 0,
            boxChargeM2           : 0,
            boxInnerChargeM2      : 0,
            boxGuideOuterChargeM2 : 0,
            guideRailChargeM2     : 0,
            endslatChargeM2       : 0,
            revisionChargeM2      : 0,
            boxSideSurfaceChargeM2: 0,

            profileChargeH       : 0,
            boxChargeH           : 0,
            boxInnerChargeH      : 0,
            boxGuideOuterChargeH : 0,
            guideRailChargeH     : 0,
            endslatChargeH       : 0,
            revisionChargeH      : 0,
            boxSideSurfaceChargeH: 0,

            profileChargeW       : 0,
            boxChargeW           : 0,
            boxInnerChargeW      : 0,
            boxGuideOuterChargeW : 0,
            guideRailChargeW     : 0,
            endslatChargeW       : 0,
            revisionChargeW      : 0,
            boxSideSurfaceChargeW: 0,
        };

        ['boxInner', 'boxGuideOuter', 'profile', 'endslat', 'guideRail', 'box', 'revision', 'boxSideSurface'].forEach(side => {
            if (rollerShutter.colors[side] && rollerShutter.colors[side].id) {
                this.colors[side] = configuratorsDataService.get('windowColorsAll', rollerShutter.colors[side].id);
            }
            if (rollerShutter.colorsOptions && rollerShutter.colorsOptions[side] && rollerShutter.colorsOptions[side].group) {
                this.colors[side + 'Group'] = rollerShutter.colorsOptions[side].group;
                this.colors[side + 'Charge'] = rollerShutter.colorsOptions[side].charge.percent;
                this.colors[side + 'ChargeM2'] = rollerShutter.colorsOptions[side].charge.m2;
                this.colors[side + 'ChargeH'] = rollerShutter.colorsOptions[side].charge.height;
                this.colors[side + 'ChargeW'] = rollerShutter.colorsOptions[side].charge.width;
            }
        });
    }

    private mapSimpleAccessory(configuratorsDataService: ConfiguratorsDataService) {
        return (accessory: SimpleAccessory) => configuratorsDataService.get('rollerAccessories', accessory.id);
    }

    private mapShutter(shutter: Shutter)  {
        return {
            id: shutter.id,
            x: shutter.x,
            realWidth: shutter.width,
            realHeight: shutter.height,
            railsHeightModify: shutter.railsHeightModify,
            handling: shutter.controlSide === 'L' ? 'l' : 'r',
            commonRail: shutter.commonRail,
            mosquito: shutter.withMosquito,
            railCutHeight: shutter.railCutHeight,
        };
    }
}
