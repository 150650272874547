import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@icc/common/translate.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { AppConfigFactory, APP_CONFIG, EventBusService, ReinforcementsService } from '@icc/common';
import { IssuesService, IssueLevel } from '@icc/helpers';

@Injectable()
export class ConstructionLimitationService {
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    constructor (
        private translateService: TranslateService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private reinforcementsService: ReinforcementsService
    ) {

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribe(['setBondedGlazingInSash', 'setMullionProfile', 'setSashProfile'], (data) => {
            this.findReinforcement(data.activeConfiguration);
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    private init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1 || this.config().IccConfig.Configurators.constructionLimitation === false) {
            return;
        }
        this.reinforcementsService.init();
    }

    /**
     * Szuka czy wsrod ograniczen jest wzmocnienie
     * @param  {object} conf Konfiguracja
     */
    findReinforcement(conf) {
        const {noConstructionLimitations, noMatchedConstructionLimitations} = this.reinforcementsService.findReinforcement(conf);

        if (noConstructionLimitations && this.config().IccConfig.Configurators.noConstructionLimitationInfo) {
            this.issuesService.simpleRegister(
                'no-construction-limitations',
                'UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji',
                this.translateService.instant('WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji'),
                conf,
                {
                    blockAddToOffer: false,
                    noPrice        : false,
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            this.issuesService.unregister('no-construction-limitations', conf);
        }

        if (noMatchedConstructionLimitations) {
            this.issuesService.simpleRegister(
                'no-matched-construction-limitations',
                'UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych',
                this.translateService.instant('WINDOW|UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            this.issuesService.unregister('no-matched-construction-limitations', conf);
        }
    }

}
