import { __awaiter, __decorate, __generator, __metadata } from "tslib";
import { Component, Input, ViewChild, ViewContainerRef, Injector, Compiler, NgModuleFactory } from '@angular/core';

var NewConfiguratorContainerComponent =
/** @class */
function () {
  function NewConfiguratorContainerComponent(injector, compiler) {
    this.injector = injector;
    this.compiler = compiler;
  }

  NewConfiguratorContainerComponent.prototype.ngOnInit = function () {
    this.loadComponent();
  };

  NewConfiguratorContainerComponent.prototype.ngOnDestroy = function () {
    this.moduleRef && this.moduleRef.destroy();
  };

  NewConfiguratorContainerComponent.prototype.loadComponent = function () {
    return __awaiter(this, void 0, void 0, function () {
      var ConfiguratorModule, moduleFactory, factory, component;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , import('../new-configurator-lazy.module'
            /* webpackPrefetch: true */

            /* webpackChunkName: "configurator" */
            )];

          case 1:
            ConfiguratorModule = _a.sent().ConfiguratorModule;
            return [4
            /*yield*/
            , this.loadModuleFactory(ConfiguratorModule)];

          case 2:
            moduleFactory = _a.sent();
            this.moduleRef = moduleFactory.create(this.injector);
            this.moduleRef.instance.loadOptions(this.options);
            factory = this.moduleRef.componentFactoryResolver.resolveComponentFactory(ConfiguratorModule.rootComponent);
            component = this.container.createComponent(factory);
            component.instance.options = this.options;
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  NewConfiguratorContainerComponent.prototype.loadModuleFactory = function (t) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(t instanceof NgModuleFactory)) return [3
            /*break*/
            , 1];
            return [2
            /*return*/
            , t];

          case 1:
            return [4
            /*yield*/
            , this.compiler.compileModuleAsync(t)];

          case 2:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  var _a, _b, _c;

  __decorate([Input(), __metadata("design:type", Object)], NewConfiguratorContainerComponent.prototype, "options", void 0);

  __decorate([ViewChild('container', {
    static: true,
    read: ViewContainerRef
  }), __metadata("design:type", typeof (_a = typeof ViewContainerRef !== "undefined" && ViewContainerRef) === "function" ? _a : Object)], NewConfiguratorContainerComponent.prototype, "container", void 0);

  NewConfiguratorContainerComponent = __decorate([Component({
    selector: 'icc-new-configurator-container',
    template: require('./new-configurator-container.component.html'),
    styles: []
  }), __metadata("design:paramtypes", [typeof (_b = typeof Injector !== "undefined" && Injector) === "function" ? _b : Object, typeof (_c = typeof Compiler !== "undefined" && Compiler) === "function" ? _c : Object])], NewConfiguratorContainerComponent);
  return NewConfiguratorContainerComponent;
}();

export { NewConfiguratorContainerComponent };