import { OfferHistoryService } from "@icc/common/offers/offer-history.service";

export class  AddPaymentModalController {
    payment;

    resolve: {
        offerId,
        offer,
        currency,
        providers
    };
    close: ({$value}) => any;
    dismiss: () => any;

    constructor(
        private $http,
        private $uibModal,
        private $translate,
        private InfoService,
        private UserService,
    ) {}

    $onInit() {
        const offerId = this.resolve.offerId;
        this.payment = {
            dealer_offers_id: offerId,
            amount: 0,
            currency: this.resolve.currency.currency,
            status: 'success',
            payment_provider: Object.keys(this.resolve.providers)[0],
        };
    }

    closeModal(save = false) {
        if (save) {
            const user = this.UserService.get();
            this.$http
                .post('/api/shop/payments/add', {data: this.payment})
                .then((res) => {
                    console.log(res);
                    if (res.status === 200 && res.data === true) {
                        OfferHistoryService.addHistoryEntry(this.resolve.offer, user, [{
                            type: 'payments',
                            addedValue: this.payment.amount,
                            currency: this.payment.currency,
                            status: this.payment.status,
                            paymentProvider: this.payment.payment_provider
                        }]);
                        this.close({$value: this.payment});
                    } else {
                        this.InfoService.showInfo(
                            this.$translate.instant('OFFER|Wystąpił błąd')
                        );
                    }
                });
        } else {
            this.close({$value: false});
        }
    }
}
