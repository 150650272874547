import angular from 'angular';

import FileReadDirective from './FileReadDirective';
import FocusDirective from './FocusDirective';
import IcIncludeDirective from './IcIncludeDirective';
import PerfectDirective from './PerfectDirective';
import StringToNumberDirective from './StringToNumberDirective';
import RoundIntegerDirective from './RoundIntegerDirective';
import { RoundNumberDirective } from './RoundNumberDirective';
import {FocusInDirective, FocusOutDirective} from '@icc/legacy/app/directives/FocusEventsDirectives';

export default angular.module('icc.app.directives', [])
    .directive('fileread', FileReadDirective)
    .directive('autoFocus', FocusDirective)
    .directive('icInclude', IcIncludeDirective)
    .directive('perfect', PerfectDirective)
    .directive('stringToNumber', StringToNumberDirective)
    .directive('roundNumber', () => new RoundNumberDirective())
    .directive('roundIntegerDirective',RoundIntegerDirective)
    .directive('iccFocusin', FocusInDirective)
    .directive('iccFocusout', FocusOutDirective)
    .name;
