import { Injectable } from "@angular/core";
import { ConfiguratorsDataService, ConfigurationsService, EventBusService, ProfilesService } from "@icc/common";
import { DoorActiveConfiguration } from "@icc/common/configurations/DoorActiveConfiguration";
import { IccColor, IccColorGroup } from "@icc/common/data-types";
import { ListPageComponent } from "@icc/configurator/pages";
import { _ } from "@icc/configurator/shared";
import { ModalService } from "@icc/helpers";
import { ColorsPageComponent } from "../colors-page/colors-page.component";
import { DoorPortal } from '@icc/common/data-types';


@Injectable({
    providedIn: 'root'
})
export class DoorPortalsService {
    allPortals: DoorPortal[] = [];

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window'>,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private modalService: ModalService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['initializedConfigurator', 'loadedConfiguratorsData'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe('setConstructionColor', data => {
            if (
                DoorActiveConfiguration.is(data.activeConfiguration)
            ) {
                this.setDefaultPortalColor();
            }
        });

        this.eventBusService.subscribe('changedSashes', data => {
            if (
                DoorActiveConfiguration.is(data.activeConfiguration)
            ) {
                const canHavePortal = this.canAddPortal()
                if (!canHavePortal) this.disableAndRemovePortal()
            }
        });
    }

    init() {
        this.allPortals = this.configuratorsDataService.data.doorPortals || [];
    }

    getFilteredPortals(conf = this.configurationsService.conf.Current) {
        const selectedFrameProfileIds = this.profilesService.getUsedFrameProfileIds(conf);
        return this.allPortals.filter(portal => {
            return selectedFrameProfileIds.every(profileId => portal.frame_profile_ids.map(Number).includes(Number(profileId)))
        }) || [];
    }

    disableAndRemovePortal(disable = true, conf = this.configurationsService.conf.Current) {
        if (disable) {
            this.setPortal(null, conf);
        } else {
            this.setDefaultPortal(conf);
        }
    }

    setDefaultPortal(conf = this.configurationsService.conf.Current) {
        const defaultPortalId = this.getDefaultPortalId()
        const selectedPortalId = this.getSelectedPortalId();
        if (
            defaultPortalId !== null
            && selectedPortalId !== null
            && selectedPortalId == defaultPortalId
        ) return

        this.setPortal(defaultPortalId);
    }

    setDefaultPortalColor(conf = this.configurationsService.conf.Current) {
        if (!(conf.doorPortal
                && conf.doorPortal.selectedColor)
            || conf.doorPortal.selectedColor.isDefault
        ) {
            const defaultColor = this.getDefaultPortalColor(conf);
            this.setPortalColor(defaultColor);
        }
    }

    getDefaultPortalId(conf = this.configurationsService.conf.Current): number {
        const filteredPortals = this.getFilteredPortals(conf);
        const defaultPortal = filteredPortals && filteredPortals[0]
        return (defaultPortal && Number(defaultPortal.id));
    }

    getDefaultPortalColor(conf = this.configurationsService.conf.Current) {
        const filteredColors = this.getFilteredPortalColors(conf);
        const currentFrameOuterColor = this.configurationsService.conf.Current.Colors.frame.outer;
        const defaultPortalColor = currentFrameOuterColor && filteredColors.find(c => c.id == currentFrameOuterColor.id) || filteredColors[0];
        return defaultPortalColor;
    }

    getPortalById(id: number | null) {
        return this.getFilteredPortals().find(p => p.id == id) || null;
    }

    setPortal(portalId: number | null, conf = this.configurationsService.conf.Current) {
        const portal = this.getPortalById(portalId);
        conf.doorPortal = portal;
        this.eventBusService.post({ key: 'setDoorPortal', value: {} });
        this.setDefaultPortalColor(conf);
    }

    setPortalColor(color: IccColor,  conf = this.configurationsService.conf.Current) {
        if (conf.doorPortal) {
            conf.doorPortal.selectedColor = color;
        }
    }

    getSelectedPortal(conf = this.configurationsService.conf.Current): DoorPortal | null {
        return conf.doorPortal;
    }

    getSelectedPortalId(conf = this.configurationsService.conf.Current): number | null {
        const portal = this.getSelectedPortal(conf)
        return (portal && Number(portal.id));
    }

    getFilteredPortalColors(conf = this.configurationsService.conf.Current): IccColor[] {
        const selectedPortal = this.getSelectedPortal(conf);
        const windowColors = this.getAllWindowColors();
        const filteredColors = selectedPortal
                                && selectedPortal.window_color_group_ids
                                && windowColors.filter(c => c.groups && c.groups.map(Number).some(g => selectedPortal.window_color_group_ids.map(Number).includes(g)));
        return filteredColors || [];
    }

    getFilteredPortalColorGroupIds(conf = this.configurationsService.conf.Current): number[] {
        const selectedPortal = this.getSelectedPortal(conf);
        return (selectedPortal && (selectedPortal.window_color_group_ids).map(Number)) || [];
    }

    getFilteredPortalColorGroups(conf = this.configurationsService.conf.Current): IccColorGroup[] {
        const colorGroupIds = this.getFilteredPortalColorGroupIds(conf);
        const colorGroups =  colorGroupIds.map(id => this.getColorGroupById(id));
        return colorGroups || [];
    }

    getSelectedPortalColor(conf = this.configurationsService.conf.Current) {
        return (conf.doorPortal && conf.doorPortal.selectedColor) || null;
    }

    openModalPortal() {
        return this.modalService
        .open({
            pageComponent: ListPageComponent,
            resolve: {
                items: this.getFilteredPortals(),
                selectedId: this.getSelectedPortalId(),
                title: _('DOOR|Wybierz portal'),
                imageUrl: '/files/windowaccessory',
                showItemInfo: true,
                itemNamePropertyName: 'name',
                itemDescriptionPropertyName: 'description',
                itemImgPropertyName: 'img',
            },
        })
        .result.then(selectedData => {
            if (selectedData && selectedData.id) {
                this.setPortal(selectedData.id);
            }
        });
    }

    openModalPortalColor(conf = this.configurationsService.conf.Current) {
        const selectedPortalColorGroups = this.getFilteredPortalColorGroups();
        return this.modalService
        .open({
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => this.getFilteredPortalColors(),
                selectedColor: () => this.getSelectedPortalColor(),
                colorGroups: () => selectedPortalColorGroups || []
            },
        })
        .result.then(selectedData => {
            if (selectedData && selectedData.color) {
                this.setPortalColor(selectedData.color);
            }
        });
    }

    canAddPortal(conf = this.configurationsService.conf.Current): boolean {
        return this.getFilteredPortals().length > 0
                && !(conf.OwnedSashesTypes.doorTopLight || conf.OwnedSashesTypes.doorLeftLight || conf.OwnedSashesTypes.doorRightLight)
    }

    getAllWindowColors() {
        return this.configuratorsDataService.data.windowColorsAll;
    }

    getColorGroupById(id: number): IccColorGroup {
        const allColorGroups = this.configuratorsDataService.data.windowColorGroups;
        const colorGroup = allColorGroups.find(g => Number(g.id) === id)
        return colorGroup;
    }
}
