import { Injectable, Inject } from '@angular/core';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { DiscountsAndMultipliersService } from '@icc/price/b2b';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { core } from '@icc/common/helpers';
import { PriceService } from '@icc/price';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { StateService } from '@icc/common/state.service';
import { Common } from '@icc/common/Common';
import { PositionsDetailsService } from '@icc/common/PositionsDetailsService';
import { GlazingSizesService } from '@icc/common/configurators/glazing-sizes.service';
import { DrawService } from '@icc/common/configurators/draw.service';
import { FillingsService } from '../glazings/fillings.service';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { ColorsService } from '../colors/colors.service';
import { Location } from '@angular/common';
import { EventBusService } from "@icc/common/event-bus.service";
import { OfferGroupCodeService } from "libs/common/src/lib";
import { WindowConfiguration } from "@icc/common/configurations/WindowConfiguration";
import { APP_CONFIG, AppConfigFactory } from "@icc/common/config";
import { IccSideColors, IccColors } from '@icc/common/data-types';

@Injectable()
export class SystemsComparisonService {
    systems;
    edit: { value: boolean } = { value: false };
    editId: number | null = null;
    drawOptions = {};
    onOffer = false;

    private key = '';
    private objectId = 'id';
    private objectIds = {
        'mulions.astragalFrame': 'profileId',
        'mulions.movable': 'profileId',
        'mulions.movableCentral': 'profileId',
        'mulions.movableDouble': 'profileId',
        'mulions.static': 'profileId',
        'mulions.intMullions': 'profileId',
        'mulions.intMullions.astragalFrame': 'profileId',
        'mulions.intMullions.movable': 'profileId',
        'mulions.intMullions.movableCentral': 'profileId',
        'mulions.intMullions.movableDouble': 'profileId',
        'mulions.intMullions.static': 'profileId',
        'frames.frames': 'profileId',
        'couplings.couplings': 'couplingId',
        sashFrames: 'profileId',
        'sashFrames.sashes': 'profileId',
        'sashFrames.sashes.profiles': 'profileId',
    };
    private originalConfig: any = {};
    private configs = [];
    private changes: any = {};

    constructor(
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject('EditPositionFactory') private editPositionFactory,
        @Inject('OffersFactory') private offersFactory,
        @Inject('$rootScope') private $rootScope,
        @Inject('$translate') private $translate: ng.translate.ITranslateService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private colorsService: ColorsService,
        private systemsService: SystemsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService,
        private priceService: PriceService,
        private stateService: StateService,
        private glazingSizesService: GlazingSizesService,
        private drawService: DrawService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        private location: Location,
    ) {}

    async systemsComparison() {
        this.onOffer = false;
        const systems = await this.openSystemsSelectModal(
            (this.configurationsService.conf.Current as WindowActiveConfiguration).System.id,
            (this.configurationsService.conf.Current as WindowActiveConfiguration).type,
            true
        );
        if (systems && systems.length) {
            this.$rootScope.loader = true;
            setTimeout(async () => {
                this.configs = this.calculateSystems(systems);
                this.$rootScope.loader = false;
                const data = await this.openSystemsComparisonModal();
                this.saveOrEditConfig(data);
            });
        }
    }

    saveOrEditConfig(data) {
        if (data.config) {
            this.configurationsService.conf.Current = Object.assign(
                this.configurationsService.conf.Current,
                core.copy(data.config.conf)
            );
            this.configurationsService.conf.Default = Object.assign(
                this.configurationsService.conf.Default,
                core.copy(data.config.defaultConf)
            );
            this.priceService.count();
            if (data.edit) {
                this.edit.value = true;
                this.editId = data.config.id;
            }
        }
    }

    openSystemsComparisonModal() {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonModal',
            resolve: {
                conf: () => this.configurationsService.conf.Current,
                currency: () => this.stateService.state.offers[0].doc.currency,
                configs: () => this.configs,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        modalInstance.closed.then(() => {
            if (!this.edit.value) {
                this.configs = [];
            }
        });

        return modalInstance.result;
    }

    openSystemsComparisonDetailsModal(
        config,
        currentConfig = this.configurationsService.conf.Current,
        allowEdit = true
    ) {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonDetailsModal',
            resolve: {
                config: () => config,
                currentConfig: () => currentConfig,
                currency: () => this.stateService.state.offers[0].doc.currency,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
                allowEdit: () => allowEdit,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    calculateSystems(
        systems,
        configuration = this.configurationsService.conf.Current,
        defaultConfiguration = this.configurationsService.conf.Default,
        resetConfigs = true,
        positionId = '',
        positionIndex = 0,
    ): {
        id: number;
        conf: WindowActiveConfiguration;
        defaultConf: WindowActiveConfiguration;
        details: WindowConfiguration;
        detailsForView: any;
        positionId?: string;
        positionIndex?: number;
    }[] {
        if (resetConfigs) {
            this.configs = [];
        }
        let conf = core.copy(configuration) as WindowActiveConfiguration;
        let defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;

        this.originalConfig = { conf, defaultConf, details: {}, detailsForView: {} };
        this.getDetails(this.originalConfig, false);

        for (let i = 0; i < systems.length; i++) {
            const id = this.configs.length ? this.configs[this.configs.length - 1].id + 1 : 0;
            const system = this.systems.find(el => el.id == systems[i]);
            conf = core.copy(configuration) as WindowActiveConfiguration;
            defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;
            conf.systemComparison = true;

            this.systemsService.selectSystem(conf, defaultConf, system);
            const config: {
                id: number;
                conf: WindowActiveConfiguration;
                defaultConf: WindowActiveConfiguration;
                details: WindowConfiguration | {};
                detailsForView: any;
                positionId?: string;
                positionIndex?: number;
            } = { id, conf, defaultConf, details: {}, detailsForView: {} };
            if (positionId.length) {
                config.positionId = positionId;
            }
            if (positionIndex) {
                config.positionIndex = positionIndex;
            }
            this.getDetails(config);

            this.configs.push(config);
        }

        return this.configs;
    }

    getDetails(config, getChanges = true) {
        let details;
        if (getChanges) {
            this.priceService.count(config.conf);
            details = this.configurationsService.createSimpleConfiguration(config.conf);
            config.conf.drawData = this.drawService.getData(details);
            this.glazingSizesService.count(config.conf);
            this.priceService.count(config.conf);
        }
        details = this.configurationsService.createSimpleConfiguration(config.conf);
        details.timestamp = Date.now();
        const positions = new PositionsDetailsService(
            IccConfig,
            [
                {
                    configuration: config.conf,
                    details,
                    summary: {
                        dealer: {
                            components: {},
                        },
                        client: {
                            components: {},
                        },
                    },
                    group_discounts: {},
                },
            ],
            this.stateService.getCurrentOffer(),
            this.$rootScope.user.access,
            true,
            this.$rootScope.user.access,
            this.$rootScope.curLang,
            this.$translate
        );
        const detailsForView = positions[0].detailsForView;

        if (getChanges) {
            this.changes = [];
            detailsForView.allWidth = true;
            this.extract(detailsForView, this.originalConfig.detailsForView);
        }

        config.details = Object.assign(config.details, details);
        config.detailsForView = Object.assign(config.detailsForView, detailsForView);
        config.changes = core.copy(this.changes);
    }

    extract(elems, currentConfElems, path = '', id = null) {
        for (const k in elems) {
            if (['prices', 'system'].indexOf(k) === -1) {
                if (Common.isArray(elems[k])) {
                    for (let i = 0; i < elems[k].length; i++) {
                        if (path === 'Sashes' || path === 'Frames') {
                            id = i;
                        }
                        if (Common.isObject(elems[k][i])) {
                            this.key = path ? path + '.' + k : k;
                            this.objectId = Common.isDefined(this.objectIds[this.key])
                                ? this.objectIds[this.key]
                                : 'id';
                            if (
                                elems[k][i][this.objectId] != null
                                && (!currentConfElems
                                    || !currentConfElems[k]
                                    || !currentConfElems[k][i]
                                    || !currentConfElems[k][i][this.objectId]
                                    || elems[k][i][this.objectId]
                                        !== currentConfElems[k][i][this.objectId])
                            ) {
                                const currentConfigId =
                                    currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i][this.objectId]
                                        : null;
                                const currentConfigName =
                                    currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i].name
                                        : null;
                                elems[k][i].changed = true;
                                elems[k][i].currentConfigId = currentConfigId;
                                elems[k][i].currentConfigName = currentConfigName;
                                this.changes.push({
                                    key: this.key,
                                    id: {
                                        before: currentConfigId,
                                        after: elems[k][i][this.objectId],
                                    },
                                    name: {
                                        before: currentConfigName,
                                        after: elems[k][i].name,
                                    },
                                });
                            }
                            this.extract(
                                elems[k][i],
                                currentConfElems
                                    && Common.isDefined(currentConfElems[k])
                                    && Common.isDefined(currentConfElems[k][i])
                                    ? currentConfElems[k][i]
                                    : null,
                                this.key,
                                id
                            );
                        }
                    }
                } else if (Common.isObject(elems[k])) {
                    this.key = path ? path + '.' + k : k;
                    this.objectId = Common.isDefined(this.objectIds[this.key])
                        ? this.objectIds[this.key]
                        : 'id';
                    if (
                        elems[k][this.objectId] != null
                        && (!currentConfElems
                            || !currentConfElems[k]
                            || !currentConfElems[k][this.objectId]
                            || elems[k][this.objectId] !== currentConfElems[k][this.objectId])
                    ) {
                        const currentConfigId =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k][this.objectId]
                                : null;
                        const currentConfigName =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k].name
                                : null;
                        elems[k].changed = true;
                        elems[k].currentConfigId = currentConfigId;
                        elems[k].currentConfigName = currentConfigName;
                        this.changes.push({
                            key: this.key,
                            id: {
                                before: currentConfigId,
                                after: elems[k][this.objectId],
                            },
                            name: {
                                before: currentConfigName,
                                after: elems[k].name,
                            },
                        });
                    }
                    this.extract(
                        elems[k],
                        currentConfElems && Common.isDefined(currentConfElems[k])
                            ? currentConfElems[k]
                            : null,
                        this.key,
                        id
                    );
                }
            }
        }
    }

    saveConfig(save = true) {
        if (this.onOffer) {
            this.location.go('/app/offers_view/' + this.stateService.state.offer_id);
        }
        if (save) {
            const config = this.configs.find(el => el.id === this.editId);
            config.conf = core.copy(this.configurationsService.conf.Current);
            config.defaultConf = core.copy(this.configurationsService.conf.Default);
            this.getDetails(config);
        }
        this.configurationsService.conf.Current = Object.assign(
            this.configurationsService.conf.Current,
            core.copy(this.originalConfig.conf)
        );
        this.configurationsService.conf.Default = Object.assign(
            this.configurationsService.conf.Default,
            core.copy(this.originalConfig.defaultConf)
        );
        delete (this.configurationsService.conf.Current as WindowActiveConfiguration)
            .systemComparison;
        this.edit.value = false;
        this.editId = null;
        this.openSystemsComparisonModal();
    }

    changeFilling(config, sashId = null, intSashId = null) {
        let sash;
        let intSash;
        config.conf.valid.frameProfiles = true;
        config.conf.valid.sashesProfiles = true;
        config.conf.valid.loadedFillings = true;
        config.conf.valid.loadedGlazingBeads = true;
        config.conf.valid.sashes = true;
        if (sashId !== null) {
            sash = config.conf.Sashes.find(el => el.id === sashId);
            if (intSashId !== null) {
                intSash = sash.intSashes.find(el => el.id === intSashId);
            }
        }
        this.fillingsService.loadMatchingFillings(config.conf);
        this.fillingsService.openModal(
            intSash ? intSash : sash ? sash : 'default',
            config.conf,
            sash ? sash : undefined,
            () => {
                this.getDetails(config);
                this.fillingsService.loadMatchingFillings(this.originalConfig.conf);
            }
        );
    }

    changeColor(config) {
        this.colorsService.loadColorsBySystem(
            color =>
                color
                && color.systems
                && color.systems.indexOf(config.conf.System.id) > -1,
            config.conf
        );
        const modalInstance = this.$uibModal.open({
            templateUrl: require(
                'ngtemplate-loader!../../complementary_goods/colors/modal.html'
            ),
            controller: 'ModalSystemComparisonColorsCtrl as $ctrl',
            resolve: {
                config: () => config.conf,
                configDefault: () => config.defaultConf,
            },
        });

        modalInstance.closed.then(() => {
            this.getDetails(config);
            this.colorsService.loadColorsBySystem(
                color =>
                    color
                    && color.systems
                    && color.systems.indexOf(this.originalConfig.conf.System.id) > -1,
                this.originalConfig.conf
            );
        });
    }

    async systemsComparisonInOffer(drawOptions) {
        this.onOffer = true;
        this.drawOptions = drawOptions;
        this.configs = [];
        const positions = await this.openGroupSelectModal();
        if (positions) {
            const system = await this.openSystemsSelectModal(
                positions[0].configuration.System.id,
                positions[0].confType,
                false
            );
            if (system) {
                this.configurationsService.conf.Current = core.copy(positions[0].configuration);
                this.configurationsService.conf.Default = core.copy(positions[0].configuration);
                this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                this.calculateSystems(
                    [system.id],
                    this.configurationsService.conf.Current,
                    this.configurationsService.conf.Default,
                    false,
                    positions[0].tmp_id,
                    positions[0].listNumber
                );

                const confData = await this.openSystemsComparisonDetailsModal(
                    this.configs[0],
                    positions[0].configuration,
                    false
                );

                if (confData.nextPosition) {
                    const changes = this.getChanges(
                        positions[0].configuration,
                        this.configs[0].conf
                    );

                    if (positions.length > 1) {
                        for (let i = 1; i < positions.length; i++) {
                            this.configurationsService.conf.Current = core.copy(
                                positions[i].configuration
                            );
                            this.configurationsService.conf.Default = core.copy(
                                positions[i].configuration
                            );
                            this.eventBusService.setCurrentConfiguration(
                                this.configurationsService.conf
                            );
                            this.calculateSystems(
                                [system.id],
                                this.configurationsService.conf.Current,
                                this.configurationsService.conf.Default,
                                false,
                                positions[i].tmp_id,
                                positions[i].listNumber
                            );
                            await this.changeColorsAndFillingsFromChanges(
                                this.configs[i],
                                positions[i],
                                changes
                            );
                        }
                    }
                }
                const data = await this.openSystemsComparisonModal();
                if (data.edit) {
                    const position = {
                        doc: {
                            configuration: data.config.conf,
                            details: data.config.details,
                            quantity: data.config.conf.Quantity
                        }
                    }
                    this.edit.value = true;
                    this.editId = data.config.id;
                    this.editPositionFactory.editPosition(position);
                }
            }
        }
    }

    async changeColorsAndFillingsFromChanges(config, position, changes) {
        let toEdit = false;
        this.colorsService.loadColorsBySystem(
            color => color && color.systems && color.systems.indexOf(config.conf.System.id) > -1,
            config.conf
        );
        for (const type in changes.colors) {
            if (changes.colors.hasOwnProperty(type)) {
                for (const side in changes.colors[type]) {
                    if (changes.colors[type].hasOwnProperty(side)) {
                        const color = changes.colors[type][
                            side
                        ][
                            position.configuration
                                .Colors[type][
                                side
                            ].id
                        ];
                        if (color) {
                            this.colorsService.setColor(
                                side as keyof IccSideColors,
                                type as keyof IccColors,
                                color,
                                color.type,
                                true,
                                config.conf,
                                config.defaultConf,
                            );
                        } else {
                            toEdit = true;
                        }
                    }
                }
            }
        }
        this.fillingsService.loadMatchingFillings(config.conf);
        config.conf.Sashes.forEach((sash, index) => {
            let filling = changes.fillings[position.configuration.Sashes[index].glazing.id];
            if (filling) {
                this.fillingsService.setFillingInSash(
                    sash,
                    sash,
                    filling,
                    config.conf
                );
            } else {
                toEdit = true;
            }
            if (sash.intSashes.length) {
                sash.intSashes.forEach((intSash, i) => {
                    filling =
                        changes.fillings[
                            position.configuration.Sashes[index].intSashes[i]
                                .glazing.id
                        ];
                    if (filling) {
                        this.fillingsService.setFillingInSash(
                            intSash,
                            sash,
                            filling,
                            config.conf
                        );
                    } else {
                        toEdit = true;
                    }
                });
            }
        });
        this.getDetails(config);
        if (toEdit) {
            const data = await this.openSystemsComparisonDetailsModal(
                config,
                position.configuration,
                false
            );
            if (data.nextPosition) {
                this.getChanges(position.configuration, config.conf);
            }
        }
    }

    getChanges(
        originalData,
        changedData,
        changes = {
            colors: {
                frame: {
                    core: {},
                    inner: {},
                    outer: {},
                },
                sash: {
                    core: {},
                    inner: {},
                    outer: {},
                },
            },
            fillings: {},
        }
    ) {

        for (const type in changes.colors) {
            if (changes.colors.hasOwnProperty(type)) {
                for (const side in changes.colors[type]) {
                    if (originalData.Colors[type][side].id !== changedData.Colors[type][side].id) {
                        changes.colors[type][side][originalData.Colors[type][side].id] =
                            core.copy(changedData.Colors[type][side]);
                    }
                }
            }
        }

        originalData.Sashes.forEach((sash, index) => {
            if (sash.glazing.id !== changedData.Sashes[index].glazing.id) {
                changes.fillings[sash.glazing.id] =
                    core.copy(changedData.Sashes[index].glazing);
            }
            if (sash.intSashes.length) {
                sash.intSashes.forEach((intSash, i) => {
                    changes.fillings[intSash.glazing.id] =
                        core.copy(changedData.Sashes[index].intSashes[i].glazing);
                });
            }
        });

        return changes;
    }

    openSystemsSelectModal(systemId, confType, multiple = true) {
        this.systems = this.systemsService.getSystems(confType);
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsSelectModal',
            resolve: {
                systems: () => this.systems.filter(el => Number(el.id) !== Number(systemId)),
                multiple: () => multiple,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    openGroupSelectModal() {
        const positions = core.copy(
            this.stateService
                .getKey('positions')
                .filter(
                    el =>
                        ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(
                            el.doc.configuration.type
                        ) > -1
                )
        );
        const groups = {};
        const schema = {
            window: [
                'configuration.System.id',
                'configuration.Colors.frame.alushell.id',
                'configuration.Colors.frame.core.id',
                'configuration.Colors.frame.inner.id',
                'configuration.Colors.frame.outer.id',
                'configuration.Colors.sash.alushell.id',
                'configuration.Colors.sash.core.id',
                'configuration.Colors.sash.inner.id',
                'configuration.Colors.sash.outer.id',
                'configuration.HasAlushell',
                'configuration.Wood.id'
            ],
            hs: 'window',
            door: 'window',
            folding_door: 'window',
            sliding_door: 'window',
        };
        positions.forEach(position => {
            position.doc.groupCode = OfferGroupCodeService.generateGroupCode(
                position.doc,
                this.config().IccConfig,
                schema
            );
            if (!groups[position.doc.groupCode]) {
                groups[position.doc.groupCode] = [];
            }
            groups[position.doc.groupCode].push(position.doc);
        });

        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'positionsGroupsModal',
            resolve: {
                groups: () => groups,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    createSubOffer() {
        const positions = {};
        this.configs.forEach(el => {
            positions[el.positionId] = {
                conf: el.conf,
                details: el.details,
            }
        });
        this.offersFactory.addSubOffer(this.stateService.state.offer_id, false, false, false, positions);
    }
}
