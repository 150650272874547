import {
    Alignment,
    Mullion,
    FrameProfile,
    GlazingBead,
    GlazingSizes,
    Handle,
    Muntin,
    Profile,
    Reinforcement,
    SashType,
    ShapeType,
    GlazingBeadProfile,
} from '.';
import { MosquitoConfiguration } from '@icc/common/configurations/MosquitoConfiguration';
import { Accessory, Filling } from '@icc/common/types';
import { Common } from '@icc/common/Common';

export class Sash {
    id: number;
    index: number;
    parentId?: null | number = null;
    frameId?: null | number = null;
    x: number;
    y: number;
    width: number;
    height: number;
    type?: SashType | null;
    muntins: Muntin[];
    intSashes?: Sash[];
    intMullions?: Mullion[];
    intAlignments?: Alignment[];
    intAdjacentSashes?: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    nearMullions: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    nearAlignments: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    filling: Filling | null;
    glazingBead: GlazingBeadProfile | null;
    bondedGlazing: boolean;
    panelGlazing: Filling | null = null;
    panelType?: 'Inset' | 'Outer' | 'Double' | 'Inner';
    fillingSizes: GlazingSizes;
    oneGlazing?: boolean;
    oneGlazingBead?: boolean;
    mosquito?: MosquitoConfiguration | null;
    shape: ShapeType;
    frame?: {
        top: FrameProfile | null;
        bottom: FrameProfile | null;
        left: FrameProfile | null;
        right: FrameProfile | null;
    };
    handleInner?: Handle | null;
    handleOuter?: Handle | null;
    handleHeight?: number;
    defaultHandleHeight?: boolean;
    handleHeightType?: 'standard' | 'nonstandard' | 'middle';
    hardware: Accessory[] = []
    weight = 0;

    constructor(desc: {
        id: number;
        index: number;
        parentId?: number;
        frameId?: number;
        x: number;
        y: number;
        width: number;
        height: number;
        type?: SashType;
        nearMullions: {
            left: number;
            right: number;
            top: number;
            bottom: number;
        };
        nearAlignments: {
            left: number;
            right: number;
            top: number;
            bottom: number;
        };
    }) {
        this.id = desc.id;
        this.index = desc.index;
        this.parentId = Common.isDefined(desc.parentId) ? desc.parentId : null;
        this.frameId = Common.isDefined(desc.frameId) ? desc.frameId : null;
        this.x = desc.x;
        this.y = desc.y;
        this.width = desc.width;
        this.height = desc.height;
        this.muntins = [];
        this.intSashes = [];
        this.intMullions = [];
        this.intAlignments = [];
        this.intAdjacentSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.nearMullions = {
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
        };
        this.nearAlignments = {
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
        };
        ['left', 'right', 'top', 'bottom'].forEach((side: keyof Sash['nearMullions']) => {
            if (Common.isDefined(desc.nearMullions[side])) {
                this.nearMullions[side] = desc.nearMullions[side];
            }
            if (Common.isDefined(desc.nearAlignments[side])) {
                this.nearAlignments[side] = desc.nearAlignments[side];
            }
        });
        this.filling = null;
        this.glazingBead = null;
        this.fillingSizes = {
            width: 0,
            height: 0,
            area: 0,
            x: 0,
            y: 0,
        };
        this.oneGlazing = true;
        this.oneGlazingBead = true;
        this.shape = 'rect';
        this.bondedGlazing = false;

        if (Common.isUndefined(desc.parentId)) {
            this.panelGlazing = null;
            this.panelType = 'Inset';
            this.type = desc.type || null;
            this.mosquito = null;
            this.frame = {
                top: null,
                bottom: null,
                left: null,
                right: null,
            };
            this.handleInner = null;
            this.handleOuter = null;
            this.handleHeight = desc.height / 2;
            this.defaultHandleHeight = true;
            this.handleHeightType = 'standard';
            this.hardware = [];
            this.weight = 0;
        }
    }
}
