import { ProfilesService } from '@icc/common/profiles.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';;
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Colors, Wood, SideColors } from '@icc/common/configurations/parts/common';
import { Profile, ProfileType, Reinforcement } from '@icc/window';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import * as $ from 'jquery';
import { BrowserProfilesService, ProfileColorsService } from '@icc/configurator/window';
import { IccSideColors } from '@icc/common/data-types';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ReinforcementsModalService } from '@icc/configurator/window';
import { BehaviorSubject } from 'rxjs';

export class ProfilesModalController {
    resolve: {
        profiles: Profile[];
        profilesPrices: any[];
        selectedProfile: Profile;
        system: any | null;
        type: ProfileType;
        currency;
        color?: IccSideColors;
        wood?: Wood;
        reinforcement?: Reinforcement;
    };
    close: ({ $value: Profile }) => any;
    dismiss: () => any;
    showTabs = false;
    showColorsWhenCoupling = this.CurConfService.conf === 'coupled_window';
    filteredProfiles: Profile[] = [];
    selectedTypeTab = 'fixed_mullion';

    constructor(
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private $timeout,
        public IccConfig,
        private ScrollbarService,
        private CurConfService: CurrentConfiguratorService,
        private reinforcementsModalService: ReinforcementsModalService,
        private profileColorsService: ProfileColorsService
    ) {}

    $onInit() {
        let hasCouplings = false;
        this.resolve.profiles.forEach(profile => {
            delete profile.selectedColor;
            delete profile.selectedWood;
            delete profile.reinforcement;

            if (this.resolve.selectedProfile && this.resolve.selectedProfile.id === profile.id) {
                profile.selectedColor = {
                    frame: this.resolve.color,
                    sash: this.resolve.color,
                };
                profile.selectedWood = this.resolve.wood;
                if (this.resolve.type.includes('extension')) {
                    profile.reinforcement = core.copy(this.resolve.reinforcement);
                }
            } else {
                profile.selectedColor = {
                    frame: this.profilesService.getDefaultProfileColorSet(
                        profile,
                        this.resolve.color,
                        this.resolve.system
                    ),
                    sash: this.profilesService.getDefaultProfileColorSet(
                        profile,
                        this.resolve.color,
                        this.resolve.system
                    ),
                };
            }
            if (profile.type === 'coupling') {
                hasCouplings = true;
            }
        });
        this.filteredProfiles = this.resolve.profiles;
        if (
            Common.isArray(this.resolve.type)
            && this.resolve.type.includes('coupling')
            && hasCouplings
        ) {
            if (this.resolve.type.length > 1) {
                this.showTabs = true;
                this.selectedTypeTab = this.resolve.selectedProfile.type;
            } else {
                this.selectedTypeTab = 'coupling';
            }
            this.filterProfiles();
        }
    }

    $onChanges(changes) {
        this.ScrollbarService.update();
    }

    hasZeroPrice(price, type = null) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.resolve.type) && type === 'frame')
            || this.resolve.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
        }
        if (
            (Common.isArray(this.resolve.type) && type === 'threshold')
            || this.resolve.type === 'threshold'
        ) {
            requiredFields = [price.price_sash, price.price_length];
        } else if (
            !this.resolve.type
            || (Common.isArray(this.resolve.type)
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.resolve.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.resolve.type) && type === 'sandwich')
            || this.resolve.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(price, type = null) {
        if (!price) {
            return true;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.resolve.type) && type === 'frame')
            || this.resolve.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
            requiredFields.push(price.price_construction);
        }
        if (
            (Common.isArray(this.resolve.type) && type === 'threshold')
            || this.resolve.type === 'threshold'
        ) {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (
            !this.resolve.type
            || (Common.isArray(this.resolve.type)
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.resolve.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.resolve.type) && type === 'sandwich')
            || this.resolve.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    selectProfile(profile) {
        this.close({ $value: profile });
    }

    closeModal() {
        this.close({ $value: false });
    }

    openModalColors(profile) {
        const selectedColors$ = new BehaviorSubject( {
            frame: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                core: {},
                outer: {},
                inner: {},
                alushell: {},
            },
        })
        this.profileColorsService
            .openProfileColorsModal(profile, this.resolve.selectedProfile, this.resolve.profilesPrices, selectedColors$)
            .then(selection => {
                profile.price = this.profilesPriceService.getProfilePrice(
                    profile.id,
                    Common.isArray(this.resolve.type) ? profile.type : this.resolve.type,
                    this.resolve.system,
                    selection.colors,
                    this.resolve.profilesPrices
                );

                profile.selectedColor = core.copy(selection.colors);
                profile.selectedWood = core.copy(selection.wood);
            });
    }

    openModalReinforcements(profile) {
        this.reinforcementsModalService.openReinforcementsModal(
            profile.id,
            profile.reinforcement
        ).then(reinforcement => {
            if (reinforcement) {
                profile.reinforcement = reinforcement.id === null ? null : reinforcement;
            }
        });
    }

    refresh() {
        this.ScrollbarService.update();
    }

    filterProfiles() {
        this.filteredProfiles = this.resolve.profiles.filter(
            profile => profile.type === this.selectedTypeTab
        );
    }
}
ProfilesModalController.$inject = [
    'ProfilesService',
    'ProfilesPriceService',
    '$timeout',
    'IccConfig',
    'ScrollbarService',
    'CurConfService',
    'ReinforcementsModalService',
    'ProfileColorsService'
];
