import {
    expandShape,
    getArcRadius,
    getDefaultShapeDimensions,
    getGlobalDimensions,
    getCircuit,
    Shape,
} from '@icc/window';
import { TranslateService } from '@icc/common/translate.service';
import { ConfigurationsService,  ConfiguratorsDataService, ParametersService, ShapeService, ValidationService, EventBusService, ProfilesService, UserService, WindowActiveConfiguration, Common, core, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { Injectable, Inject } from '@angular/core';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { PriceService } from '@icc/price';
import { StepsService, IssuesService, IssueLevel } from '@icc/helpers';

@Injectable()
export class DimensionsService {

    loadedData = false;
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    constructor (
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private issuesService: IssuesService,
        private parametersService: ParametersService,
        private shapeService: ShapeService,
        private validationService: ValidationService,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private userService: UserService,
        private CurConfService: CurrentConfiguratorService,
        private priceService: PriceService,
        private StepFactory: StepsService,
        // private RollerDimensionsFactory,

        configuratorsDataService: ConfiguratorsDataService,
    ) {
        if (configuratorsDataService.loaded) {
            this.init();
        }

        eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        eventBusService.subscribe(['changedSashes', 'setFrameProfile'], data => {
            this.valid((data.activeConfiguration as WindowActiveConfiguration).Shape);
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (this.configurators.indexOf(this.CurConfService.conf) === -1) {
            return;
        }
        const availableShapes = this.shapeService.getAvailableShapes(
            this.configurationsService.conf.Current.System.id
        );
        if (
            Common.isUndefined(this.configurationsService.conf.Current.Shape.shape)
            || availableShapes.indexOf(this.configurationsService.conf.Current.Shape.shape) === -1
        ) {
            this.setShape(availableShapes[0] || 'rect', null, true);
        }
        if (
            this.configurationsService.conf.Current.Width === null
            || this.configurationsService.conf.Current.Height === null
        ) {
            this.setGlobalDimensions();
        }
        if (this.configurationsService.conf.Current.hasRoller) {
            this.StepFactory.enable('rollershutter');
        }
        this.loadedData = true;
    }

    /**
     * Funkcja ustawiania kształtu
     * @param {object} shape Kształt
     * @param {object} conf  Konfiguracja
     * @param {object} def   Def
     */
    public setShape(shape, conf, def?) {
        if (Common.isUndefined(conf) || conf === null) {
            conf = this.configurationsService.conf.Current;
        }
        conf.Shape = getDefaultShapeDimensions('rect', conf.Width, conf.Height);
        if (def) {
            this.configurationsService.conf.Default.Shape = getDefaultShapeDimensions(
                'rect',
                conf.Width,
                conf.Height
            );
        }
        this.setGlobalDimensions();
        Object.assign(conf, this.validationService.valid(conf, 'shape'));
        this.eventBusService.post({
            key: 'setShape',
            value: {},
        });
    }

    /**
     * Funkcja ustawiania globalnego rozmiaru
     */
    setGlobalDimensions() {
        const { width, height } = getGlobalDimensions(this.configurationsService.conf.Current.Shape);
        this.configurationsService.conf.Current.Width = width;
        this.configurationsService.conf.Current.Height = height;
    }

    /**
     * Funkcja przeliczajaca obwód koła
     */
    recalculateCircuit() {
        this.configurationsService.conf.Current.Shape.circuit = getCircuit(
            this.configurationsService.conf.Current.Shape
        );
    }

    /**
     * Funkcja ustawiajace dane kształtu
     */
    public setShapeData() {
        this.configurationsService.conf.Current.Shape = expandShape(
            this.configurationsService.conf.Current.Shape
        );
    }

    /**
     * Funkcja ustawiajaca rozmiar
     * @param {object} dimensions Rozmiar
     */
    public setDimensions(dimensions, recalcParams = true) {
        this.configurationsService.conf.Current.Shape = core.copy(dimensions);
        this.setShapeData();
        this.setGlobalDimensions();
        Object.assign(this.configurationsService.conf.Current, this.validationService.valid(this.configurationsService.conf.Current, 'shape'));


        // odśwież wymiary akcesorii (#21491)
        const sideAccessories = this.configurationsService.conf.Current.SideAccessories;
        for (let x = 0; x < sideAccessories.bottom.length; ++x) {
            if (Number(sideAccessories.bottom[x].accessory.price_type) !== 1) {
                sideAccessories.bottom[x].amount =
                    dimensions.width + sideAccessories.sizes.left + sideAccessories.sizes.right;
            }
        }
        for (let x = 0; x < sideAccessories.top.length; ++x) {
            if (Number(sideAccessories.top[x].accessory.price_type) !== 1) {
                sideAccessories.top[x].amount = dimensions.width + sideAccessories.sizes.left + sideAccessories.sizes.right;
            }
        }
        for (let x = 0; x < sideAccessories.left.length; ++x) {
            if (Number(sideAccessories.left[x].accessory.price_type) !== 1) {
                sideAccessories.left[x].amount = dimensions.height;
            }
        }
        for (let x = 0; x < sideAccessories.right.length; ++x) {
            if (Number(sideAccessories.right[x].accessory.price_type) !== 1) {
                sideAccessories.right[x].amount = dimensions.height;
            }
        }

        // this.RollerDimensionsFactory.loadBoxHeights();
        if (recalcParams) {
            this.recalculateCircuit();
            this.priceService.count();
            this.parametersService.count(this.configurationsService.conf.Current);
        }
    }

    /**
     * Funkcja walidacji kształtu
     * @param  {object} shapeData Dane ksztłtu
     */
    public valid(shapeData) {
        const conf = this.configurationsService.conf.Current;
        if (shapeData.shape === 'rect' && (!shapeData.width || !shapeData.height)) {
            this.issuesService.simpleRegister(
                'no-window-dimensions',
                'Wymiary nie zostały określone.',
                this.translateService.instant('DIMENSION|Wymiary nie zostały określone.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            shapeData.shape === 'rect'
            && (shapeData.width < 250
                || shapeData.height < 25
                || ((conf.type !== 'door' || !conf.System.door_type)
                    && conf.Sashes.length
                    && conf.Sashes.some(
                        sash =>
                            (sash.rWidth / conf.Width) * shapeData.width < this.config().IccConfig.Configurators.minWidth
                            || (sash.rHeight / conf.Height) * shapeData.height < this.config().IccConfig.Configurators.minWidth
                    )))
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        const arcFramesEdges = this.profilesService.getArcFramesEdges(conf);

        if (arcFramesEdges) {
            for (const edge of arcFramesEdges) {
                const arcProfile = this.profilesService.getProfile(edge.profileId);

                const coords =
                    shapeData.type === 'F'
                        ? [
                              { x: 0, y: shapeData.h2 },
                              { x: shapeData.width / 2, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ]
                        : [
                              { x: shapeData.width * -1, y: shapeData.h2 },
                              { x: 0, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ];

                const arcRadius = getArcRadius(coords);

                if (
                    arcProfile
                    && ((shapeData.shape === 'circle'
                        && shapeData.radius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape === 'arc'
                            && arcRadius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape === 'arc'
                            && Number(shapeData.h1) + Number(shapeData.h2) < 250))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-window-dimensions',
                        'Podane wymiary są nieprawidłowe.',
                        this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                        conf,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }

        const angles = this.shapeService.getAngles(shapeData);
        const defaultProfile = this.profilesService.getProfile(conf.ProfileSet.frameSide);
        if (conf.drawData && conf.drawData.shape[0]) {
            const shapeDrawData = conf.drawData.shape[0];

            if (
                (shapeData.shape === 'triangle' || shapeData.shape === 'poligon')
                && angles.some((angle, index) => {
                    const angleEdges = shapeDrawData.angles[index] || shapeDrawData.angles[0];
                    const oneFrame = conf.Frames.find(f => angleEdges[0] && f.id === angleEdges[0].frameId);
                    const sideProfile = this.profilesService.getProfile(
                        (oneFrame && oneFrame.frame[angleEdges[0].frameEdgeIndex] || {
                            profileId: null
                        }).profileId
                    );
                    const nextFrame = conf.Frames.find(f => angleEdges[1] && f.id === angleEdges[1].frameId);
                    const sideProfileNext = this.profilesService.getProfile(
                        (nextFrame && nextFrame.frame[angleEdges[1].frameEdgeIndex] || {
                            profileId: null
                        }).profileId
                    );
                    return (
                        angle
                        < Math.max(
                            (sideProfile || defaultProfile).minAngle || 0,
                            (sideProfileNext || defaultProfile).minAngle || 0
                        )
                    );
                })
            ) {
                this.issuesService.simpleRegister(
                    'incorrect-window-dimensions',
                    'Podane wymiary są nieprawidłowe.',
                    this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }

        if (
            shapeData.shape == 'triangle'
            && (Number(shapeData.s1) + Number(shapeData.s3) < 250 || shapeData.height < 250)
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            ['h1', 'h2', 'h3', 's1', 's2', 's3', 'height', 'width', 'radius', 'd'].some(
                field => Common.isDefined(shapeData[field]) && Number(shapeData[field]) < 0
            )
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        this.issuesService.unregister('no-window-dimensions', conf);
        this.issuesService.unregister('incorrect-window-dimensions', conf);
        this.issuesService.unregister('incorrect-window-fins', conf);
        return true;
    }

    /**
     * Funkcja walidacji rozmiaru modelu drzwi
     * @param  {object} shapeData Dane kształtu
     */
    public validDoorModelDimensions(shapeData) {
        if (
            shapeData.shape === 'rect'
            && this.CurConfService.conf === 'door'
            && DoorActiveConfiguration.is(this.configurationsService.conf.Current)
            && this.configurationsService.conf.Current.Model
            && Number(this.configurationsService.conf.Current.Model.min_width) > 0
            && Number(this.configurationsService.conf.Current.Model.min_height) > 0
        ) {
            const sashes = this.configurationsService.conf.Current.Sashes;
            for (let i = sashes.length - 1; i >= 0; i--) {
                if (
                    sashes[i].rWidth < Number(this.configurationsService.conf.Current.Model.min_width)
                    || sashes[i].rHeight < Number(this.configurationsService.conf.Current.Model.min_height)
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-window-model-dimensions',
                        'Wartości przycięcia płetwy są niepoprawne',
                        this.translateService.instant(
                            'CONFIGURATOR|Wartości przycięcia płetwy są niepoprawne'
                        ),
                        this.configurationsService.conf.Current,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }
        this.issuesService.unregister(
            'incorrect-window-model-dimensions',
            this.configurationsService.conf.Current
        );
    }

    public changeMountingMethod(measurements, mountingMethod) {
        measurements.mountingMethod = mountingMethod;
    }

    public changedDimensions() {
        if (!this.userService.b2c || !this.configurationsService.conf.offerConfigurator) {
            this.configurationsService.conf.Current.ChangedDimensions = true;
        }
    }
}
