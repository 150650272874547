import { RootScope } from '@icc/legacy/app/RootScope';
import { OnlineStatusService } from '@icc/legacy/sync/online-status.service';
import { LicenseService } from '@icc/legacy/license/license.service';
import { ConfiguratorsAvailabilityService } from '@icc/legacy/configurator/configurators-availability.service';
import { StateService } from '@icc/common/state.service';
import { UserService } from '@icc/common/user.service';
import { SynchronizeService } from '@icc/legacy/sync/synchronize.service';
import { LangService } from './lang.service';

enum RejectCause {
    LOGGED_IN,
    LOGGED_OUT_B2C,
    LOGGED_OUT_B2B,
    ONLINE,
    OFFLINE,
    LICENSE_UNACCEPTED,
    DATA_UNSYNCED,
    INACTIVE_OFFER,
    IN_NATIVE,
    OUT_NATIVE,
    CONFIGURATOR_UNAVAILABLE,
    DATA_STALE,
    OFFER_MODULE_DENIED,
    HAS_LOGIN_IN_SHOP
}

export class RoutesGuardsService {
    constructor(
        private $rootScope: RootScope,
        private $location: ng.ILocationService,
        private IccConfig,
        private OnlineStatusService: OnlineStatusService,
        private LicenseService: LicenseService,
        private isElectron,
        private isNative,
        private StateService: StateService,
        private UserService: UserService,
        private SynchronizeService: SynchronizeService,
        private LangService: LangService,
        private ConfiguratorsAvailabilityService: ConfiguratorsAvailabilityService,
        private isPhonegap: boolean,
    ) {
        'ngInject';
    }

    init() {
        this.$rootScope.$on('$routeChangeStart', (e, next, current) => {
            if (next.$$route) {
                if (!next.$$route.availableWhen) {
                    next.$$route.availableWhen = {};
                }
                next.$$route.resolve = {
                    loggedIn: this.loggedInGuard(next.$$route),
                    offline: this.offlineGuard(next.$$route),
                    licenseUnaccepted: this.licenseGuard(next.$$route),
                    dataSynced: this.dataSyncedGuard(next.$$route),
                    dataUpdatedGuard: this.dataUpdatedGuard(next.$$route),
                    inactiveOffer: this.inactiveOfferGuard(next.$$route),
                    native: this.nativeGuard(next.$$route),
                    configuratorUnavailable: this.configuratorUnavailableGuard(next.$$route),
                    offerModuleDenied: this.offerModuleDeniedGuard(next.$$route),
                    hasLoginInShop: this.hasLoginInShopGuard(next.$$route),
                };
            }
        });

        this.$rootScope.$on('$routeChangeError', (e, current, previous, rejection) => {
            this.handleRejection(rejection, previous);
        });
    }

    loggedInGuard(nextRoute) {
        return () => {
            const loggedIn = this.UserService.loggedIn;
            const b2cActive = this.IccConfig.Offer.B2C.active && !this.isElectron;
            if (nextRoute.availableWhen.loggedIn === false && loggedIn) {
                return Promise.reject(RejectCause.LOGGED_IN);
            }
            if (nextRoute.availableWhen.loggedOutB2C === false && !loggedIn && b2cActive) {
                return Promise.reject(RejectCause.LOGGED_OUT_B2C);
            }
            if (!nextRoute.availableWhen.loggedOutB2B && !loggedIn && !b2cActive) {
                return Promise.reject(RejectCause.LOGGED_OUT_B2B);
            }
            return Promise.resolve();
        };
    }

    offlineGuard(nextRoute) {
        return () =>
            new Promise((resolve, reject) => {
                this.OnlineStatusService.checkOnline().then(online => {
                    if (nextRoute.availableWhen.online === false && online) {
                        reject(RejectCause.ONLINE);
                    }
                    if (nextRoute.availableWhen.offline === false && !online) {
                        reject(RejectCause.OFFLINE);
                    }
                    resolve();
                });
            });
    }

    licenseGuard(nextRoute) {
        return () => {
            const licenseAccepted = this.LicenseService.accepted();
            if (
                !nextRoute.availableWhen.licenseUnaccepted
                && !licenseAccepted
                && (this.IccConfig.Dealer.licenseInBrowser || this.isNative)
            ) {
                return Promise.reject(RejectCause.LICENSE_UNACCEPTED);
            }
            return Promise.resolve();
        };
    }

    dataSyncedGuard(nextRoute) {
        return () => {
            const synced = this.SynchronizeService.synced;
            if (!nextRoute.availableWhen.dataUnsynced && !synced) {
                return Promise.reject(RejectCause.DATA_UNSYNCED);
            }
            return Promise.resolve();
        };
    }

    dataUpdatedGuard(nextRoute) {
        return () => {
            const updated = this.SynchronizeService.onceUpdated;
            if (!nextRoute.availableWhen.dataStale && !updated) {
                return Promise.reject(RejectCause.DATA_STALE);
            }
            return Promise.resolve();
        };
    }

    inactiveOfferGuard(nextRoute) {
        return () => {
            const activeOffer = this.StateService.getKey('offer_id') != null;
            const user = this.UserService.get();
            const isAdmin = user.access === 'Super Admin' || user.access === 'Administratorzy';
            const redirectData = window.location.search.replace('?', '').split(/[=\&]+/);
            const isThumb = redirectData[0] === 'thumb' || sessionStorage.getItem('adminThumb');
            if (
                !isThumb
                && ((nextRoute.availableWhen.inactiveOffer === false && !activeOffer)
                    || (nextRoute.availableWhen.admin !== true && isAdmin))
            ) {
                return Promise.reject(RejectCause.INACTIVE_OFFER);
            }
            return Promise.resolve();
        };
    }

    nativeGuard(nextRoute) {
        return () => {
            if (nextRoute.availableWhen.inNative === false && this.isNative) {
                return Promise.reject(RejectCause.IN_NATIVE);
            }
            if (nextRoute.availableWhen.outNative === false && !this.isNative) {
                return Promise.reject(RejectCause.OUT_NATIVE);
            }
            return Promise.resolve();
        };
    }

    configuratorUnavailableGuard(nextRoute) {
        return () => {
            const conf = nextRoute.originalPath.split('/')[2];
            if (
                this.ConfiguratorsAvailabilityService.configs.indexOf(conf) > -1
                && !this.ConfiguratorsAvailabilityService.availableConfigs[conf]
            ) {
                return Promise.reject(RejectCause.CONFIGURATOR_UNAVAILABLE);
            }
            return Promise.resolve();
        };
    }

    offerModuleDeniedGuard(nextRoute) {
        return () => {
            const conf = nextRoute.originalPath.split('/')[2];
            const offerModuleAccess = this.UserService.offerModuleAccess;
            if (!nextRoute.availableWhen.offerModuleDenied && !offerModuleAccess) {
                return Promise.reject(RejectCause.OFFER_MODULE_DENIED);
            }
            return Promise.resolve();
        };
    }

    hasLoginInShopGuard(nextRoute) {
        return () => {
            const conf = nextRoute.originalPath.split('/')[2];
            const user = this.UserService.get();
            const hasShop = this.IccConfig.Modules.eCommerce && !this.isElectron && user.marketHasStore;
            if (nextRoute.availableWhen.hasLoginInShop === false && hasShop) {
                return Promise.reject(RejectCause.HAS_LOGIN_IN_SHOP);
            }
            return Promise.resolve();
        };
    }

    async handleRejection(rejection: RejectCause, previous) {
        const online = await this.OnlineStatusService.checkOnline();
        const synced = this.SynchronizeService.synced;
        const licenseAccepted = this.LicenseService.accepted();
        switch (rejection) {
            case RejectCause.LOGGED_IN:
                this.$location.path('/app/start');
                break;
            case RejectCause.LOGGED_OUT_B2C:
                this.$location.path('/' + this.LangService.getLang());
                break;
            case RejectCause.LOGGED_OUT_B2B:
                if (online) {
                    this.$location.path('/app/login');
                } else {
                    this.$location.path('/app/offline');
                }
                break;
            case RejectCause.ONLINE:
                this.$location.path('/app/start');
                break;
            case RejectCause.OFFLINE:
                if (synced && licenseAccepted) {
                    this.$location.path(previous ? previous.originalPath : '/app/start');
                } else {
                    this.$location.path('/app/offline');
                }
                break;
            case RejectCause.LICENSE_UNACCEPTED:
                this.$location.path('/app/license');
                break;
            case RejectCause.DATA_UNSYNCED:
                if (this.isNative) {
                    if (online) {
                        this.$location.path('/app/sync');
                    } else {
                        this.$location.path(previous ? previous.originalPath : '/app/offline');
                    }
                } else {
                    this.$location.path('/app/sync');
                }
                break;
            case RejectCause.DATA_STALE:
                if (this.isNative) {
                    if (online) {
                        this.$location.path('/app/update');
                    } else {
                        this.$location.path(previous ? previous.originalPath : '/app/offline');
                    }
                }
                break;
            case RejectCause.INACTIVE_OFFER:
                const user = this.UserService.get();
                const isAdmin = user.access === 'Super Admin' || user.access === 'Administratorzy';
                if (isAdmin) {
                    window.location.href = '/admin';
                } else {
                    this.$location.path('/app/start');
                }
                break;
            case RejectCause.IN_NATIVE:
                this.$location.path('/app/start');
                break;
            case RejectCause.OUT_NATIVE:
                this.$location.path('/app/start');
                break;
            case RejectCause.CONFIGURATOR_UNAVAILABLE:
                this.$location.path(previous ? previous.originalPath : '/app/start');
                break;
            case RejectCause.OFFER_MODULE_DENIED:
                this.$location.path('/app/start');
                break;
            case RejectCause.HAS_LOGIN_IN_SHOP:
                window.location.href = '/login';
                break;
        }
    }
}
