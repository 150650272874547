import { Component, Input, Output, Inject, EventEmitter, Optional, NgZone } from '@angular/core';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';

@Component({
    selector: 'tags',
    template: require('./tags.component.html'),
})
export class TagsComponent {
    @Input() iccConfig;
    @Input() tags;
    @Input() items;
    @Input() multiple;
    @Output() filtered: EventEmitter<any> = new EventEmitter();

    seltags: any;

    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory, private zone: NgZone) {}

    ngOnChanges() {
        this.selectTag();
    }

    changed() {
        const selectedItems = this.items.filter(system =>
            this.seltags.every(tag => system.tags.includes(tag))
        );
        this.filtered.emit(selectedItems);
    }

    selectTag(tag: any = null) {
        if (!this.multiple) {
            const selected = tag && tag.selected;
            this.tags = this.tags.map(item => Object.assign(item, { selected: false }));
            if (selected) {
                tag.selected = true;
            }
        }
        const stags = this.tags.filter(t => t.selected === true);
        this.seltags = stags.map(item => item.id);
        this.changed();
    }
}
