import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { IccAccessoryColor, IccAccessoryAccessory } from '@icc/common/data-types';
import {
    isNotNullOrUndefined,
    ModalService,
} from '@icc/configurator/shared';
import { HardwareTypeOptionsPageComponent } from '../hardware-type-options-page/hardware-type-options-page.component';
import { TranslateService } from '@icc/common';
import { KeysService } from '../cylinders-page/keys.service';

@Component({
  selector: 'icc-hardware-types-page',
  template: require('./hardware-types-page.component.html'),
  styles: [require('./hardware-types-page.component.scss')]
})
export class HardwareTypesPageComponent extends PageComponent implements OnInit {
    public title = _('DOOR|Wybierz typ osprzętu');
    public options = [];
    public selected: any = null;

    types: iccListItem[] = [];
    tabs: iccListTab[] = [];
    selectedTypeId = 0;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            title: string;
            types: IccAccessoryAccessory[];
            hardwareColors?: IccAccessoryColor[];
            selType : number;
            selColor: IccAccessoryColor;
            tabs?: string[];
            canOpenOptions?: (item: IccAccessoryAccessory) => boolean;
        },
        private modalService: ModalService,
        private translateService: TranslateService,
        private keysService: KeysService,
    ) {
        super();
        this.title = pageData.title;
    }

    ngOnInit() {
        this.types = this.pageData.types.map(type => {
            const selectedColor =
                (type.colors_ids
                    && ((this.pageData.selColor
                        && Number(
                            type.colors_ids.find(
                                id => Number(this.pageData.selColor.id) === Number(id)
                            )
                        ))
                        || Number(type.colors_ids[0])))
                || 0;
            return {
                id: Number(type.id),
                title: type.name,
                description: type.description,
                imageUrl: this.getImageUrl(type, selectedColor),
                colors: this.pageData.hardwareColors == null ? [] :  
                    type.colors_ids
                    && type.colors_ids
                        .map(c =>
                            this.pageData.hardwareColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img,
                        })) || [],
                selectedColor,
                showColorSelection: false,
                tabs: type.type === 'plate' ? [type.plate_type] : []
            };
        });
        if (this.pageData.tabs) {
            this.tabs = this.pageData.tabs.map(tab => {
                const tabNames = {
                    long: this.translateService.instant('DOOR|Długi'),
                    divided: this.translateService.instant('DOOR|Dzielony'),
                    bottom: this.translateService.instant('DOOR|Dolny'),
                };
                return {
                    id: tab,
                    name: tabNames[tab]
                };
            })
        }

        this.selectedTypeId = Number(this.pageData.selType);
    }

    private getImageUrl(hardware: IccAccessoryAccessory, selectedColor: number): string {
        return hardware.colors_imgs && hardware.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hardware.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hardware.img;
    }

    selectType(item: iccListItem) {
        const type = this.pageData.types.find(f => Number(f.id) === Number(item.id));

        if(type.type === 'cylinder') {
            this.keysService.resetQuantityIfKeysAreDifferent(type);
        }

        if (type && type.id && (typeof this.pageData.canOpenOptions !== 'function' || this.pageData.canOpenOptions(type))) {
            this.modalService.open({
                pageComponent: HardwareTypeOptionsPageComponent,
                resolve:{
                    selectedType: type,
                    hardwareColors: this.pageData.hardwareColors ? this.pageData.hardwareColors : [],
                    selColor: this.pageData.selColor,
                }
            }).result.then((result) => {
                this.sharedFacade.closePage(Object.assign(result, {
                    type
                }));
            });
        } else {
            this.sharedFacade.closePage({
                type
            });
        }
    }
}
