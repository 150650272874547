import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
    selector: 'icc-drives-accessories-page',
    template: require('./drives-accessories-page.component.html'),
    styles: [require('./drives-accessories-page.component.scss')],
})
export class DrivesAccessoriesPageComponent extends PageComponent implements OnInit {
    public title = _('ROLLERSHUTTER|Wybierz dodatek');

    constructor() {
        super();
    }

    ngOnInit() {}
}
