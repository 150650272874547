import { Component, OnInit, Inject } from '@angular/core';
import {
  PageComponent,
  _,
  SharedFacade,
  ICC_PAGE_DATA,
  ModalService,
} from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { TranslateService } from '@icc/common';
import { IccAccessoryAccessory, IccColor } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { SillOptionsPageComponent } from '../sill-options-page/sill-options-page.component';

@Component({
  selector: 'icc-sills-list-page',
  template: require('./sills-list-page.component.html'),
  styles: [require('./sills-list-page.component.scss')]
})
export class SillsListPageComponent extends PageComponent implements OnInit {
  public title = this.translateService.instant('GOODS|Parapety');
  public options = [];

  accessories: iccListItem[] = [];
  tabs: iccListTab[] = [
      {
          id: 'w',
          name: this.translateService.instant('GOODS|Wewnętrzny')
      },
      {
        id: 'z',
        name: this.translateService.instant('GOODS|Zewnętrzny')
    },
    {
        id: 'wz',
        name: this.translateService.instant('GOODS|Wewnętrzny / zewnętrzny')
    }
  ]

  constructor(
      private sharedFacade: SharedFacade,
      private modalService: ModalService,
      private translateService: TranslateService,
      @Inject(ICC_PAGE_DATA)
      private pageData: {
          sills: any[];
          categories: any[];
          colors?: IccColor[];
          noPrice: boolean;
      }
  ) {
      super();
  }

  ngOnInit() {
      this.accessories = this.pageData.sills.map<iccListItem>(accessory => ({
          id: Number(accessory.id),
          title: accessory.name,
          imageUrl: `/files/windowsill/${accessory.image}`,
          description: `${accessory.description}`,
          tabs: [accessory.type]
      }));
  }

  selectAccessory(item: iccListItem) {
      const accessory = this.pageData.sills.find(a => Number(a.id) === Number(item.id));
      if (accessory) {
          this.modalService
              .open({
                  pageComponent: SillOptionsPageComponent,
                  resolve: {
                      accessory,
                      colors: this.pageData.colors,
                  },
              })
              .result.then(result => {
                  this.sharedFacade.closePage(result);
              });
      }
  }

}
