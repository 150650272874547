import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {
    databaseManagerProvider, positionsFactoryProvider,
} from './ajs-upgraded-providers';

import { OnlineStatusService } from './online-status.service';
import { SynchronizeService } from './synchronize.service';
import { SyncMethodsService } from './Infrastructure/sync-methods.service';
import {SocketService} from './socket.service';
import {SyncIconComponent} from '@icc/legacy/synchronize/icon/sync-icon.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    exports: [],
    declarations: [
        SyncIconComponent
    ],
    providers: [
        databaseManagerProvider,
        OnlineStatusService,
        SynchronizeService,
        SyncMethodsService,
        SocketService,
        positionsFactoryProvider
    ],
    entryComponents: [
        SyncIconComponent
    ]
})
export class SyncModule { }
