import {
    core,
    TranslateService,
    Common,
    ParametersService,
    EventBusService,
    ProfilesService,
    WindowActiveConfiguration,
    UserService,
    AppConfigFactory,
    APP_CONFIG,
    StateService,
} from '@icc/common';
import {
    InfoService,
    ModalService,
    StepsService,
    dimToMilimeters,
    isUndefined,
    isArray,
} from '@icc/helpers';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService, DiscountsAndMultipliersService, PriceBaseService } from '@icc/price/b2b';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { ColorsService } from '../colors/colors.service';
import { IccAccessoryAccessory, IccAccessory, IccAccessoryColor } from '@icc/common/data-types';
import { Inject, Injectable } from '@angular/core';
import { AccessoriesListPageComponent } from 'libs/configurator/accessory/src/lib/accessories-list-page/accessories-list-page.component';
import { AccessoriesColorsPageComponent } from 'libs/configurator/accessory/src/lib/accessories-colors-page/accessories-colors-page.component';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { AccessoriesActiveConfiguration } from '@icc/common/configurations/AccessoriesActiveConfiguration';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { Profile } from '@icc/window';
const colorsModalTemplate = require('!raw-loader!../../complementary_goods/colors/modal.html');

@Injectable()
export class AccessoriesService {
    private configurators = [
        'window',
        'hs',
        'door',
        'folding_door',
        'sliding_door',
        'accessory',
        'garage_door',
        'roller_shutter',
        'external_blind'
    ];
    private allAccessories: IccAccessoryAccessory[] = [];
    private accessories = [];
    private categories: any[] = [];
    private subcategories: any[] = [];
    private sashHardwares = [];
    private priceProp = 'price_white';
    private dowelHoleParams = {
        between: 700,
        fromEdge: 200,
    };

    colorsAll: IccAccessoryColor[] = [];
    loadedData = false;

    constructor(
        private infoService: InfoService,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<
            'window' | 'roller_shutter' | 'external_blind' | 'garage_door' | 'door' | 'accessory'
        >,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private priceAccessoryService: PriceAccessoryService,
        private priceBaseService: PriceBaseService,
        private stepService: StepsService,
        private colorsService: ColorsService,
        private eventBusService: EventBusService,
        private timeLimitService: TimeLimitService,
        private profilesService: ProfilesService,
        private translateService: TranslateService,
        private userService: UserService,
        private customPricesService: CustomPricesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private stateService: StateService
    ) {
        'ngInject';

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.eventBusService.subscribe(
            [
                'changedSashes',
                'setGlazingInSash',
                'setShape',
                'setExtensionProfile',
                'setFrameProfile',
                'setSashProfile',
                'setMullionProfile',
                'putAlignmentInField',
                'removedAlignmentInField',
                'removedAlignment',
                'setLowThreshold',
                'unsetLowThreshold',
            ],
            data => {
                this.updateAccessories(data.activeConfiguration as WindowActiveConfiguration);
            }
        );
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }

        // wszystkie akcesorie połączone (sash, sides i conf)
        this.allAccessories = this.configuratorsDataService.data.windowAccessories;
        this.subcategories =
            this.configuratorsDataService.data.windowAccessoriesSubCategories || [];
        this.colorsAll = this.configuratorsDataService.data.windowHandlesColors;
        this.dowelHoleParams.between = this.configuratorsDataService.data.dowelHoleParams
            ? this.configuratorsDataService.data.dowelHoleParams.between
            : 0;
        this.dowelHoleParams.fromEdge = this.configuratorsDataService.data.dowelHoleParams
            ? this.configuratorsDataService.data.dowelHoleParams.fromEdge
            : 0;
        this.categories = this.configuratorsDataService.data.windowAccessoriesCategories;

        this.findAccessories();
        this.refreshPriceProp();
        this.loadedData = true;

        if (this.currentConfiguratorService.conf === 'accessory') {
            this.configurationsService.conf.Current.Name = this.translateService.instant(
                'ACCESSORY|Akcesoria'
            );
        }
    }

    /**
     * Funkcja znajdujaca akcesoria
     */
    findAccessories(conf = this.configurationsService.conf.Current, confType = conf.type) {
        if (conf.type  === 'garage_door') {
            return;
        }
        if (
            Common.isUndefined(conf)
            || this.configurators.indexOf(this.configurationsService.conf.conf) === -1
        ) {
            return;
        }
        this.accessories = [];
        this.sashHardwares = [];
        const sides = ['top', 'bottom', 'left', 'right'];
        let i = 0;

        Common.forEach(this.allAccessories, accessory => {
            const availableSystemsIds = accessory.window_lines_ids;
            if ((WindowActiveConfiguration.is(conf) || RollerShutterActiveConfiguration.is(conf)) && (confType  === 'roller_shutter' || confType  === 'external_blind')) {
                if (
                    Common.isDefined(conf.RollerShutter.type.id)
                    && availableSystemsIds.indexOf(String(conf.RollerShutter.type.id)) > -1
                ) {
                    this.accessories.push(accessory);
                }
            } else if (
                confType  === 'accessory'
                || (WindowActiveConfiguration.is(conf)
                    && Common.isDefined(conf.System.id)
                    && availableSystemsIds.indexOf(conf.System.id) > -1)
            ) {
                this.accessories.push(accessory);
            }
            // szukaj akcesorii dla skrzydeł
            if (WindowActiveConfiguration.is(conf) && Common.isDefined(conf.Fitting)) {
                if (availableSystemsIds.indexOf(conf.System.id) > -1) {
                    this.sashHardwares.push(accessory);
                }
            }
        });

        if (this.accessories.length > 0 || this.configurationsService.conf.conf === 'garage_door') {
            this.stepService.enable('accessories');
        } else {
            this.stepService.disable('accessories');
        }

        conf.Accessories = conf.Accessories.filter(el => this.filterAcessory(el, conf));
        if (WindowActiveConfiguration.is(conf) && conf.SideAccessories) {
            for (i = 0; i < sides.length; i++) {
                conf.SideAccessories[sides[i]] = conf.SideAccessories[sides[i]].filter(el =>
                    this.filterAcessory(el, conf)
                );
            }
        }
        if (WindowActiveConfiguration.is(conf) && Common.isDefined(conf.Sashes)) {
            for (i = 0; i < conf.Sashes.length; i++) {
                conf.Sashes[i].hardware = conf.Sashes[i].hardware.filter(el =>
                    this.filterAcessory(el, conf)
                );
            }
        }
        this.priceService.count();
        if (conf.type !== 'accessory') {
            this.parametersService.count(conf);
        }
    }

    /**
     * Aktualizacja danych w akcesoriach
     *
     * @param {any} place Miejsce
     * @param {any} accessory Akcesoria
     */
    updateAccessories(conf = this.configurationsService.conf.Current) {
        if (WindowActiveConfiguration.is(conf)) {
            ['top', 'bottom'].map(side => {
                if (
                    conf.SideAccessories
                    && conf.SideAccessories[side]
                    && conf.SideAccessories[side].length
                ) {
                    conf.SideAccessories[side].map(accessory => {
                        if (accessory.accessory.type === 'widening') {
                            accessory.amount =
                                conf.Width
                                + conf.SideAccessories.sizes.left
                                + conf.SideAccessories.sizes.right;
                        }
                    });
                }
            });
            if (conf.Accessories && conf.Accessories.length) {
                conf.Accessories.forEach(accessory =>
                    this.setAmountAccessory(accessory, 'configuration', conf, null)
                );
            }
            if (conf.Sashes && conf.Sashes.length) {
                conf.Sashes.forEach(sash =>
                    sash.hardware.forEach(accessory =>
                        this.setAmountAccessory(accessory, 'sash', conf, sash)
                    )
                );
            }
        }
    }

    /**
     * Funkcja filtra akcesoriów
     * @param {object} el Akcesoium
     * @return {boolean}  Czy pasuuje do filtra
     */
    filterAcessory(el, conf = this.configurationsService.conf.Current) {
        const availableSystemsIds = el.accessory.window_lines_ids;
        if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
            if (
                Common.isDefined(conf.RollerShutter.type.id)
                && availableSystemsIds.indexOf(String(conf.RollerShutter.type.id)) > -1
            ) {
                return true;
            }
        } else if (
            conf.type === 'accessory'
            || (WindowActiveConfiguration.is(conf)
                && Common.isDefined(conf.System.id)
                && availableSystemsIds.indexOf(conf.System.id) > -1)
            || (WindowActiveConfiguration.is(conf)
                && conf.hasRoller
                && Common.isDefined(conf.RollerShutter.type.id)
                && availableSystemsIds.indexOf(
                    String(conf.RollerShutter.type.id)
                ) > -1)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Funkcja dodajaca
     * @param {object} place     Miejsce
     * @param {object} accessory Akcesoria
     * @param {object} color     Kolor
     */
    add(place, accessory, color?) {
        let count = Number(accessory.count) || 1;
        const colorOptions =
            accessory.show_colors && accessory.price_source === 'table'
                ? accessory.colorOptions
                : null;

        const sides = ['top', 'bottom', 'left', 'right'];
        const category = this.configuratorsDataService.data.windowAccessoriesCategories.filter(
            e => e.id === accessory.window_accessories_category_id
        );
        const subcategory = this.configuratorsDataService.data.windowAccessoriesSubCategories.filter(
            e => e.id === accessory.window_accessories_category_id
        );
        // pozycja środka nawiewnika
        let position = null;
        if (accessory.type === 'ventilator' || accessory.type === 'ventilator_hole') {
            position =
                Number(accessory.position) || (Common.isObject(place) ? place.rWidth / 2 : 1) || 1;
        }

        // Lista akcesoriow - domyslnie do calego zestawu
        let accessoriesArr = this.configurationsService.conf.Current.Accessories;

        let sizes: Record<'left' | 'right' | 'top' | 'bottom', number> = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        };
        if (WindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
            sizes = this.configurationsService.conf.Current.SideAccessories.sizes;
        }

        // Miejsce docelowa gdzie trafia akcesoria
        let forIt = 'construction';
        if (Common.isObject(place) && Common.isDefined(place.id)) {
            accessoriesArr = place.hardware;
            forIt = 'sash';
        } else if (
            Common.isString(place)
            && sides.indexOf(place) > -1
            && WindowActiveConfiguration.is(this.configurationsService.conf.Current)
        ) {
            accessoriesArr = this.configurationsService.conf.Current.SideAccessories[place];
            forIt = 'side';
        }

        /** Można dodać tylko jeden dodatek z tej grupy */
        let accessoriesFromSameCategory =
            Common.isDefined(category[0]) && category[0].only_one_accessory
                ? accessoriesArr.filter(
                      el =>
                          el.accessory.window_accessories_category_id === category[0].id
                          && el.accessory.id !== accessory.id
                  )
                : [];

        if (!accessoriesFromSameCategory.length) {
            accessoriesFromSameCategory =
                Common.isDefined(subcategory[0]) && subcategory[0].only_one_accessory
                    ? accessoriesArr.filter(
                          el =>
                              el.accessory.window_accessories_category_id === subcategory[0].id
                              && el.accessory.id !== accessory.id
                      )
                    : [];
        }

        /** Można dodać tylko jedną sztukę z tej grupy */
        let accessoryFromSameCategory =
            Common.isDefined(category[0]) && category[0].only_single_accessory
                ? accessoriesArr.filter(
                      el =>
                          el.accessory.window_accessories_category_id === category[0].id
                          && el.accessory.id === accessory.id
                  )
                : [];

        if (!accessoryFromSameCategory.length) {
            accessoryFromSameCategory =
                Common.isDefined(subcategory[0]) && subcategory[0].only_single_accessory
                    ? accessoriesArr.filter(
                          el =>
                              el.accessory.window_accessories_category_id === subcategory[0].id
                              && el.accessory.id === accessory.id
                      )
                    : [];
        }

        if (
            this.config().IccConfig.Configurators.oneVentilator
            && ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf)
                === -1
            && ['ventilator', 'ventilator_hole'].includes(accessory.type)
            && accessoriesArr.some(o =>
                ['ventilator', 'ventilator_hole'].includes(o.accessory.type)
            )
        ) {
            this.infoService.showInfo(
                this.translateService.instant('ACCESSORY|Można dodać tylko jeden nawiewnik')
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf)
                === -1
            && accessoriesFromSameCategory.length
        ) {
            this.infoService.showInfo(
                this.translateService.instant(
                    'ACCESSORY|Można dodać tylko jeden dodatek z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoriesFromSameCategory[0].accessory.name }
                )
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(this.configurationsService.conf.conf)
                === -1
            && accessoryFromSameCategory.length
        ) {
            this.infoService.showInfo(
                this.translateService.instant(
                    'ACCESSORY|Można dodać tylko jedną sztukę dodatków z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoryFromSameCategory[0].accessory.name }
                )
            );
        } else {
            if (
                (this.config().IccConfig.Configurators.oneVentilator
                    && ['ventilator', 'ventilator_hole'].includes(accessory.type))
                || (category[0] && category[0].only_single_accessory)
                || (subcategory[0] && subcategory[0].only_single_accessory)
            ) {
                count = 1;
            }

            // Wybrany kolor akcesorium
            let colorId = null;
            if (Common.isObject(color) && Common.isDefined(color.id)) {
                colorId = color.id;
            }

            // Wymiar elementu
            let amount = 0;

            // Masa elementu
            let weight = 0;

            // Szerokość
            let width = 0;

            // Wysokość
            let height = 0;

            if (
                ['renson', 'ventilator', 'ventilator_hole', 'windowsill'].includes(accessory.type)
            ) {
                width = parseFloat(accessory.width) || 0;
                height = parseFloat(accessory.height) || 0;
            }

            const user = this.userService.get();

            // cena za sztukę
            if (Number(accessory.price_type) === 0) {
                amount = 0;
                weight = parseFloat(accessory.weight) * count || 0;
                // cena za m2
            } else if (Number(accessory.price_type) === 1) {
                width = dimToMilimeters(
                    parseFloat(accessory.amount_width),
                    user,
                    this.config().IccConfig,
                    this.config()
                );
                height = dimToMilimeters(
                    parseFloat(accessory.amount_height),
                    user,
                    this.config().IccConfig,
                    this.config()
                );

                if (width && height) {
                    amount = (width * height) / 1000000;
                    weight =
                        ((count * width * height) / 1000000) * parseFloat(accessory.weight) || 0;
                } else {
                    this.infoService.showInfo(
                        this.translateService.instant(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }
                // cena za mb
            } else {
                const valAmount = dimToMilimeters(
                    parseFloat(accessory.amount),
                    user,
                    this.config().IccConfig,
                    this.config()
                );

                if (valAmount > 0) {
                    amount = valAmount;
                } else if (
                    forIt === 'side'
                    && this.configurationsService.conf.Current.type !== 'accessory'
                ) {
                    amount =
                        place === 'left' || place === 'right'
                            ? this.configurationsService.conf.Current.Height
                            : this.configurationsService.conf.Current.Width
                              + sizes.left
                              + sizes.right;
                } else if (forIt === 'sash' && accessory.type === 'kicker') {
                    amount = place.glazingSizes.width;
                } else if (forIt === 'sash' && accessory.type === 'renson') {
                    amount = width =
                        place.intSashes && place.intSashes.length
                            ? place.intSashes
                                  .filter(o => !o.ry)
                                  .map(o => o.glazingSizes.width)
                                  .reduce((a, b) => a + b, 0)
                            : place.glazingSizes.width;
                } else {
                    this.infoService.showInfo(
                        this.translateService.instant(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }

                weight = (amount / 1000) * parseFloat(accessory.weight) * count || 0;
            }

            // nowe akcesoria
            if (
                !accessoriesArr.some(
                    el =>
                        el.accessory.id === accessory.id
                        && el.amount === amount
                        && ((el.color === colorId && accessory.price_source === 'colors')
                            || (accessory.price_source === 'table'
                                && el.colorOptions === colorOptions)
                            || (accessory.price_source === 'confColors'
                                && accessory.selectedColor
                                && el.accessory.selectedColor
                                && accessory.selectedColor.frame
                                && accessory.selectedColor.frame.core
                                && accessory.selectedColor.frame.inner
                                && accessory.selectedColor.frame.outer
                                && el.accessory.selectedColor.frame
                                && el.accessory.selectedColor.frame.core
                                && el.accessory.selectedColor.frame.inner
                                && el.accessory.selectedColor.frame.outer
                                && el.accessory.selectedColor.frame.core.id
                                    === accessory.selectedColor.frame.core.id
                                && el.accessory.selectedColor.frame.inner.id
                                    === accessory.selectedColor.frame.inner.id
                                && el.accessory.selectedColor.frame.outer.id
                                    === accessory.selectedColor.frame.outer.id))
                )
            ) {
                if (
                    (Number(accessory.price_type) === 0
                        && count > 0
                        && (accessory.price_source !== 'colors'
                            || (Common.isDefined(color) && Common.isDefined(colorId))))
                    || (Number(accessory.price_type) !== 0
                        && count > 0
                        && (amount > 0 || forIt === 'side')
                        && (accessory.price_source !== 'colors'
                            || (Common.isDefined(color) && Common.isDefined(colorId))))
                ) {
                    let newPos = 0;
                    if (forIt === 'side') {
                        sizes[place] += count * accessory.height;

                        if (accessoriesArr.length > 0) {
                            newPos =
                                accessoriesArr[accessoriesArr.length - 1].pos
                                + accessoriesArr[accessoriesArr.length - 1].accessory.height
                                    * accessoriesArr[accessoriesArr.length - 1].count;
                        }
                    }
                    const accessoryClone = core.copy(accessory);
                    accessoryClone.color = core.copy(color);
                    delete accessoryClone.count;
                    delete accessoryClone.amount;
                    delete accessoryClone.position;
                    delete accessoryClone.amount_width;
                    delete accessoryClone.amount_height;
                    accessoriesArr.push({
                        count,
                        amount,
                        position,
                        weight,
                        width,
                        height,
                        colorOptions,
                        accessory: accessoryClone,
                        color: Common.isObject(color) ? color.id : null,
                        pos: newPos,
                        side: Common.isDefined(place)
                            ? Common.isDefined(place.id)
                                ? place.id
                                : place
                            : undefined,
                        category: Common.isDefined(category[0])
                            ? category[0].name
                            : Common.isDefined(subcategory[0])
                            ? subcategory[0].name
                            : null,
                        comment: accessory.comment,
                    });
                }
                // zwiększenei ilości
            } else {
                for (let i = 0; i < accessoriesArr.length; i++) {
                    if (
                        accessoriesArr[i].accessory.id === accessory.id
                        && accessoriesArr[i].amount === amount
                        && ((accessoriesArr[i].color === colorId
                            && accessoriesArr[i].accessory.price_source === 'colors')
                            || (accessoriesArr[i].accessory.price_source === 'table'
                                && accessoriesArr[i].colorOptions === colorOptions)
                            || (accessoriesArr[i].accessory.price_source === 'confColors'
                                && accessory.selectedColor
                                && accessoriesArr[i].accessory.selectedColor
                                && accessory.selectedColor.frame
                                && accessory.selectedColor.frame.core
                                && accessory.selectedColor.frame.inner
                                && accessory.selectedColor.frame.outer
                                && accessoriesArr[i].accessory.selectedColor.frame
                                && accessoriesArr[i].accessory.selectedColor.frame.core
                                && accessoriesArr[i].accessory.selectedColor.frame.inner
                                && accessoriesArr[i].accessory.selectedColor.frame.outer
                                && accessoriesArr[i].accessory.selectedColor.frame.core.id
                                    === accessory.selectedColor.frame.core.id
                                && accessoriesArr[i].accessory.selectedColor.frame.inner.id
                                    === accessory.selectedColor.frame.inner.id
                                && accessoriesArr[i].accessory.selectedColor.frame.outer.id
                                    === accessory.selectedColor.frame.outer.id))
                    ) {
                        accessoriesArr[i].count += count;
                        accessoriesArr[i].weight += weight;
                        if (forIt === 'side') {
                            sizes[place] += count * accessory.height;
                            if (i < accessoriesArr.length - 1) {
                                accessoriesArr[i + 1].pos += count * accessory.height;
                            }
                        }
                    }
                    if (forIt === 'side') {
                        if (i === 0) {
                            accessoriesArr[i].pos = 0;
                        } else {
                            accessoriesArr[i].pos =
                                accessoriesArr[i - 1].accessory.height
                                    * accessoriesArr[i - 1].count
                                + accessoriesArr[i - 1].pos;
                        }
                    }
                }
            }
        }

        accessory.count = this.getCountAccessory(
            accessory,
            forIt,
            this.configurationsService.conf.Current,
            forIt === 'sash' ? place : null
        );
        this.updateAccessories();
        this.priceService.count();
        if (
            this.configurationsService.conf.Current.type !== 'accessory'
            && this.configurationsService.conf.Current.type !== 'garage_door'
        ) {
            this.parametersService.count(this.configurationsService.conf.Current);
        }
    }

    removeAllFromSide(place) {
        const sides = ['top', 'bottom', 'left', 'right'];

        if (sides.indexOf(place) === -1) {
            return;
        }
        if (WindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
            this.configurationsService.conf.Current.SideAccessories.sizes[place] = 0;
            this.configurationsService.conf.Current.SideAccessories[place] = [];
        }
    }

    /**
     * Funkcja sprawdzajaca czy i ktory typ nawiewnika wybrano
     * @param  {object} sashNumber  Numer skrzydła
     */
    selectedVentilatorType(sashNumber) {
        let ventilatorOption = null;
        if (
            WindowActiveConfiguration.is(this.configurationsService.conf.Current)
            && this.configurationsService.conf.Current.Sashes
        ) {
            for (let i = 0; i < this.configurationsService.conf.Current.Sashes.length; i++) {
                if (this.configurationsService.conf.Current.Sashes[i].index === sashNumber) {
                    for (
                        let j = 0;
                        j < this.configurationsService.conf.Current.Sashes[i].hardware.length;
                        j++
                    ) {
                        switch (
                            this.configurationsService.conf.Current.Sashes[i].hardware[j].accessory
                                .type
                        ) {
                            case 'ventilator_hole':
                                ventilatorOption = 'ventilator_hole';
                                break;
                            case 'ventilator':
                                ventilatorOption = 'ventilator';
                                break;
                            default:
                                ventilatorOption = null;
                        }
                    }
                }
            }
        }
        return ventilatorOption;
    }

    /**
     * Funkcja usuwajaca
     * @param  {object} place     Miejsce
     * @param  {object} accessory Akcesoria
     */
    remove(place, accessory, removeDependencyAccessory=false) {
        const conf = this.configurationsService.conf.Current;
        let newAccessoriesArr;
        const sides = ['top', 'bottom', 'left', 'right'];
        // Disable ability to completely remove an accessory that is added from dependency
        if (!removeDependencyAccessory && accessory.accessory?.fromDependency) {
            accessory.count = 1;
            return true;
        }
        if (Common.isObject(place) && Common.isDefined(place.id)) {
            newAccessoriesArr = place.hardware.filter(el => {
                return (
                    String(el.amount) !== String(accessory.amount)
                    || el.accessory.id !== accessory.accessory.id
                    || (el.color !== accessory.color
                        && accessory.accessory.price_source === 'colors')
                    || el.colorOptions !== accessory.colorOptions
                );
            });
            place.hardware = newAccessoriesArr;
        } else if (
            Common.isString(place)
            && sides.indexOf(place) > -1
            && WindowActiveConfiguration.is(conf)
        ) {
            newAccessoriesArr = conf.SideAccessories[place].filter(el => {
                return (
                    String(el.amount) !== String(accessory.amount)
                    || el.accessory.id !== accessory.accessory.id
                    || (el.color !== accessory.color
                        && accessory.accessory.price_source === 'colors')
                    || el.colorOptions !== accessory.colorOptions
                );
            });
            conf.SideAccessories.sizes[place] -= accessory.count * accessory.accessory.height;

            conf.SideAccessories[place] = newAccessoriesArr;
        } else {
            newAccessoriesArr = conf.Accessories.filter(
                el =>
                    String(el.amount) !== String(accessory.amount)
                    || el.accessory.id !== accessory.accessory.id
                    || (el.color !== accessory.color
                        && accessory.accessory.price_source === 'colors')
                    || el.colorOptions !== accessory.colorOptions
            );
            conf.Accessories = newAccessoriesArr;
        }
        if (Common.isObject(place)) {
            this.selectedVentilatorType(place.index);
        }
        this.updateAccessories();

        this.priceService.count();
        if (conf.type !== 'accessory' && conf.type !== 'garage_door') {
            this.parametersService.count(conf);
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });

        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
    }

    /**
     * Funkcja odświeżajaca cenę
     */
    refreshPriceProp() {
        const conf = this.configurationsService.conf.Current;
        if (WindowActiveConfiguration.is(conf)) {
            this.priceProp = this.priceAccessoryService.getAccessoryPriceField(
                conf.Colors,
                conf.type
            );
        }
    }

    getAccessoryPrice(accessory: IccAccessory) {
        const offer = this.stateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = this.customPricesService.get(dealerId);
        let multipliers = this.discountsAndMultipliersService.getMultipliers();
        let multiplier = 1;
        multipliers = (multipliers && multipliers[dealerId]) || multipliers;

        const conf = this.configurationsService.conf.Current;
        if (multipliers) {
            multiplier = (multipliers.Supplement && multipliers.Supplement[0]) || 1;
            if (
                conf.type === 'accessory'
                //|| conf.type === 'complementary_goods'
            ) {
                multiplier = (multipliers.Commodity && multipliers.Commodity[0]) || 1;
            }
        }
        return this.priceBaseService.addMarginMarketFactor(
            this.priceAccessoryService.priceAccessory(
                accessory,
                (WindowActiveConfiguration.is(conf) && conf.Colors) || {},
                (customPrice || {}).WindowAccessory,
                WindowActiveConfiguration.is(conf) && conf.SideAccessories
                    ? conf.SideAccessories.sizes
                    : undefined,
                conf.type !== 'accessory' && conf.Width,
                conf.type !== 'accessory' && conf.Height,
                conf.type,
                multiplier
            )
        );
    }

    /**
     * Funkcja otwierajaca modal z akcesoriami
     * @param  {object} sash       Skrzydło
     * @param  {object} sashNumber Numer skrzydła
     */
    openModalAccessories(sash?, sashNumber?, callback?, confType?, conf = this.configurationsService.conf.Current): PromiseLike<void | { accessory: IccAccessoryAccessory; color?: IccAccessoryColor }> {
        this.findAccessories(conf, confType || conf.type);
        const sides = ['top', 'bottom', 'left', 'right'];
        let forIt = 'configuration';
        let sideIdx = '0';
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'accessories',
            });
        }

        if (Common.isDefined(sash) && Common.isDefined(sash.id)) {
            forIt = 'sash';
        } else if (Common.isString(sash) && sides.indexOf(sash) > -1) {
            forIt = 'side';

            if (sash === 'top') {
                sideIdx = '2';
            } else if (sash === 'bottom') {
                sideIdx = '3';
            } else if (sash === 'left') {
                sideIdx = '0';
            } else {
                sideIdx = '1';
            }
        } else if (
            Common.isString(sash)
            && sash === 'drive'
            && conf.type !== 'accessory'
            && conf.type !== 'garage_door'
        ) {
            forIt = 'drive';
            sideIdx = conf.RollerShutter.drive && conf.RollerShutter.drive.id;
        } else if (confType === 'roller_shutter') {
            forIt = 'roller_shutter';
        }

        if (
            forIt === 'side'
            && sash === 'top'
            && WindowActiveConfiguration.is(conf)
            && conf.hasRoller
        ) {
            return Promise.resolve() as PromiseLike<void>;
        }

        let modalAccessories = this.accessories;
        if (this.currentConfiguratorService.conf !== 'accessory') {
            switch (forIt) {
                case 'configuration':
                case 'roller_shutter':
                    modalAccessories = this.accessories.filter(
                        el => el.place.indexOf('conf') > -1
                    );
                    break;
                case 'sash':
                    let hardwares = [];
                    if (Common.isObject(sash)) {
                        hardwares = this.sashHardwares.filter(el => {
                            const availableSashTypesIds = el.sash_types_ids;
                            if (
                                !Common.isUndefined(sash.type)
                                && !Common.isUndefined(sash.type.id)
                            ) {
                                // sprawdź czy dodatek należy do 'sash'
                                if (
                                    el.place.indexOf('sash') > -1
                                    && availableSashTypesIds.indexOf(sash.type.id) > -1
                                ) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                    modalAccessories = hardwares;
                    break;
                case 'side':
                    const sideAccessories = this.accessories.filter(
                        el => el.place_side.indexOf(sideIdx) !== -1 && el.place.indexOf('side') > -1
                    );
                    modalAccessories = sideAccessories;
                    break;
                case 'drive':
                    const driveAccessories = this.accessories.filter(
                        el =>
                            el.roller_shutter_drives_id
                            && el.roller_shutter_drives_id.includes(sideIdx)
                            && el.type === 'roller_control'
                    );
                    modalAccessories = driveAccessories;
                    break;
            }
            modalAccessories = modalAccessories.map(accessory =>
                this.setAmountAccessory(
                    accessory,
                    forIt,
                    conf,
                    forIt === 'sash' ? sash : null
                )
            );
        } else {
            modalAccessories = this.accessories.filter(access => {
                const category = this.configuratorsDataService.data.windowAccessoriesCategories.filter(
                    el => el.id === access.window_accessories_category_id
                );
                return access.access_conf || !category.show;
            });
        }

        const categories = this.configuratorsDataService.data.windowAccessoriesCategories.filter(
            el => filterAccessories(modalAccessories, el.id).length > 0
        );

        const subcategoriesArr = this.subcategories.filter(
            el => filterAccessories(modalAccessories, el.parent_id, el.id).length > 0
        );

        const modalInstance = this.modalService.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            pageComponent: AccessoriesListPageComponent,
            resolve: {
                forIt: () => {
                    return forIt;
                },
                accessories: () => {
                    const accessories = modalAccessories.map(el => {
                        if (el.price_source === 'confColors') {
                            this.setDefaultColors(el);
                        }
                        if (isArray(el.colors_ids) && el.price_source === 'colors') {
                            const accessoryColor = this.colorsAll.find(
                                color =>
                                    el.colors_ids
                                    && el.colors_ids.some(id => Number(id) === Number(color.id))
                            );
                            if (accessoryColor) {
                                el.selectedColor = accessoryColor.id;
                            }
                        }
                        if (!el.blockAmountChange) {
                            el.amount = null;
                        }
                        return el;
                    });
                    this.colorsService.reloadColors();

                    const frameProfiles = WindowActiveConfiguration.is(conf)
                        ? conf.UsedProfiles.filter((profile) => profile.type === 'frame')
                        : false;
                        
                    if (frameProfiles) {
                        return this.matchAccessoriesForFrameProfile(frameProfiles, accessories);
                    } else {
                        return accessories;
                    }
                },
                categories: () => {
                    return categories;
                },
                subcategories: () => {
                    return subcategoriesArr.map(el => {
                        el.id *= 1;
                        el.parent_id *= 1;
                        return el;
                    });
                },
                sash: () => {
                    return sash;
                },
                sashNum: () => {
                    return sashNumber;
                },
                colors: () => {
                    return this.colorsAll;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                }
            },
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        return modalInstance.result.then(
            (result: { accessory: IccAccessoryAccessory; color?: IccAccessoryColor }) => {
                if (callback && typeof callback === 'function') {
                    callback(result);
                } else {
                    if (result) {
                        this.add(sash, result.accessory, result.color);
                    }
                    if (this.config().IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                        this.priceService.count();
                    }
                    this.eventBusService.post({
                        key: 'icc-redraw',
                        value: 'frame',
                    });
                    this.timeLimitService.count();
                }
                return result;
            }
        );
    }

    /**
     * Funkcja liczaca akcesoria (w górę)
     * @param  {object} accessory Akcesoria
     */
    countUp(accessory) {
        if (
            WindowActiveConfiguration.is(this.configurationsService.conf.Current)
            || AccessoriesActiveConfiguration.is(this.configurationsService.conf.Current)
        ) {
            this.configurationsService.conf.Current.SideAccessories.sizes[accessory.side] += Number(
                accessory.accessory.height
            );
        }
        accessory.count++;

        this.updateAccessories();
        this.priceService.count();

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Funkcja liczaca akcesoria (w dół)
     * @param  {object} accessory Akcesoria
     */
    countDown(accessory) {
        if (accessory.count > 1) {
            accessory.count--;
            if (WindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
                this.configurationsService.conf.Current.SideAccessories.sizes[
                    accessory.side
                ] -= Number(accessory.accessory.height);
            }
        }

        this.updateAccessories();
        this.priceService.count();

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Przelicz cenę
     */
    countPrice() {
        this.priceService.count();
    }

    /**
     * Otwieranie obrazka modala dóbr komplementarych
     * @param  {Object} good Dobro
     */
    openModalColors(accessory, type = 'accessory') {
        const material = accessory.material;
        this.modalService
            .open({
                template: colorsModalTemplate,
                controller: 'ModalColorsCtrl as $ctrl',
                pageComponent: AccessoriesColorsPageComponent,
                resolve: {
                    accessory: () => accessory,
                    type: () => type,
                    material: () => material,
                },
            })
            .result.then(selection => {
                accessory.selectedColor = core.copy(selection.colors);
                accessory.selectedWood = core.copy(selection.wood);
            });
    }

    /**
     * Ustawia domyślne kolory dodatku
     *
     * @param {any} accessory Dodatek
     */
    setDefaultColors(accessory) {
        const config = {
            Colors: accessory.selectedColor || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
            },
            HasAlushell: false,
            AlushellType: '',
            System: { type: accessory.material },
            ColorType: 'White',
            Wood: accessory.selectedWood || {},
            ColorsSashExt: false,
        };

        const configDefault = core.copy(config);
        this.colorsService.loadData();
        this.colorsService.loadColorsBySystem(
            color =>
                accessory.conf_colors_ids.map(Number).indexOf(Number(color.id)) > -1
                || (color.groups
                    && accessory.conf_color_groups_ids
                        .map(Number)
                        .some(c => color.groups.map(Number).indexOf(Number(c)) > -1))
        );

        this.colorsService.setDefaultColorTypeForSystem(
            config as WindowActiveConfiguration,
            configDefault as WindowActiveConfiguration
        );
        this.colorsService.setDefaultWood(
            config as WindowActiveConfiguration,
            configDefault as WindowActiveConfiguration
        );
        this.colorsService.loadColorsByWood(config as WindowActiveConfiguration);
        this.colorsService.setDefaultColors(
            config as WindowActiveConfiguration,
            configDefault as WindowActiveConfiguration
        );
        this.colorsService.setDefaultColorTypeForColors(
            config as WindowActiveConfiguration,
            configDefault as WindowActiveConfiguration
        );
        accessory.selectedColor = core.copy(config.Colors);
        accessory.selectedWood = core.copy(config.Wood);
    }

    /**
     * Funkcja liczaca otwory
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     */
    getCountHoles(sash, side) {
        let length = sash.rWidth;
        if (side === 'left' || side === 'right') {
            length = sash.rHeight;
        }
        const fromEdge = this.dowelHoleParams.fromEdge;
        const betweenO = this.dowelHoleParams.between;
        if (length - 2 * fromEdge > 0) {
            return Math.ceil((length - 2 * fromEdge) / betweenO) + 1;
        } else {
            return 0;
        }
    }

    /**
     * Funkcja wyznaczajaca pozycje otworów pod dyble
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     * @param  {object} n    N
     */
    placeDowelHole(sash, side, n) {
        let length = sash.rWidth;
        if (side === 'left' || side === 'right') {
            length = sash.rHeight;
        }
        const fromEdge = this.dowelHoleParams.fromEdge;
        const countSide = this.getCountHoles(sash, side) - 1;
        let between = 0;
        if (countSide > 0) {
            between = (length - 2 * fromEdge) / countSide;
        }

        return fromEdge + n * between;
    }

    setAmountAccessory(accessory, forIt, conf, sash = null) {
        let accessoryData = accessory;
        if (accessory.accessory) {
            accessoryData = accessory.accessory;
        }
        let amount;
        if (forIt === 'sash') {
            amount = this.calcAmountFromFormula(accessoryData.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration' || forIt === 'roller_shutter') {
            amount = this.calcAmountFromFormula(accessoryData.amount_conf_formula, conf, sash);
        }
        accessory.count = accessory.count || 1;
        if (amount) {
            if (accessoryData.price_type === 2) {
                accessory.amount = amount;
                accessory.blockAmountChange = true;
            } else if (accessoryData.price_type === 0) {
                accessory.count = amount;
                accessory.blockCountChange = true;
            }
        }
        return accessory;
    }

    getCountAccessory(accessory, forIt, conf, sash = null) {
        let amount;
        if (forIt === 'sash') {
            amount = this.calcAmountFromFormula(accessory.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration') {
            amount = this.calcAmountFromFormula(accessory.amount_conf_formula, conf, sash);
        }
        if (amount && accessory.price_type === 0) {
            return amount;
        }
        return 1;
    }

    calcAmountFromFormula(formula, conf, sash = null) {
        if (formula) {
            let value = 0;
            formula = core.parseJson(formula);
            let symbol = 'plus';
            formula.forEach(block => {
                if (['plus', 'minus'].indexOf(block.type) > -1) {
                    symbol = block.type;
                } else {
                    let valueToAdd = 0;
                    switch (block.type) {
                        case 'number':
                            valueToAdd = Number(block.value);
                            break;
                        case 'frameWidth':
                            valueToAdd = conf.Width;
                            break;
                        case 'frameHeight':
                            valueToAdd = conf.Height;
                            break;
                        case 'frameLightOuterWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId === sash.id
                                );
                                const frame = conf.Frames.find(f => f.id === sash.frameId);
                                const frameSides = this.profilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['left', 'right'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            p =>
                                                p.id
                                                === (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          m => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(s => s.side === side)
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val
                                                  + profile.width / profileRatio
                                                  - profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.width);
                                }
                            }
                            break;
                        case 'frameLightOuterHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId === sash.id
                                );
                                const frame = conf.Frames.find(f => f.id === sash.frameId);
                                const frameSides = this.profilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['top', 'bottom'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            p =>
                                                p.id
                                                === (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          m => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(s => s.side === side)
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val
                                                  + profile.width / profileRatio
                                                  - profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.height);
                                }
                            }
                            break;
                        case 'frameLightWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId === sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.width;
                                }
                            }
                            break;
                        case 'frameLightHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId === sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.height;
                                }
                            }
                            break;
                        case 'sashWidth':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    s => s.sashId === sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.width;
                                }
                            }
                            break;
                        case 'sashHeight':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    s => s.sashId === sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.height;
                                }
                            }
                            break;
                        case 'chamberGlazing':
                            if (sash) {
                                valueToAdd = Number(sash.glazing.glazing_count)
                                    ? Number(sash.glazing.glazing_count) - 1
                                    : 0;
                            }
                            break;
                    }
                    if (symbol === 'minus') {
                        value -= valueToAdd;
                    } else {
                        value += valueToAdd;
                    }
                }
            });
            return value;
        }
        return 0;
    }

    getAccessory(id) {
        return core.copy(this.allAccessories.find(el => Number(el.id) === Number(id)));
    }

    markAccessoryAsAddedWithDependency(accessoryId, conf, addedWithDependency = false) {
        let accessory = this.getConstructionAccessory(accessoryId, conf);
        if (accessory && accessory.accessory)
            accessory.accessory.addedWithDependency = addedWithDependency;
    }

    markAccessoryWithDependencyId(accessoryId, conf, dependencyId) {
        let accessory = this.getConstructionAccessory(accessoryId, conf);
        if (accessory && accessory.accessory)
            accessory.accessory.fromDependency = dependencyId;
    }

    markAccessoryWithDependencyAssociationIds(accessoryId, conf, dependencyAssociationIds) {
        let accessory = this.getConstructionAccessory(accessoryId, conf);
        if (accessory && accessory.accessory)
            accessory.accessory.dependencyAssociationIds = dependencyAssociationIds;
    }

    getConstructionAccessory(accessoryId, conf) {
        const accessory = Common.isArray(conf.Accessories)
        && conf.Accessories.find(el => el.accessory
            && el.accessory.id == accessoryId);

        if (accessory && accessory.accessory && accessory.accessory.id) {
            return accessory;
        }
        return undefined;
    }

    removeInvalidAccessories(frameProfileId: string | number, conf = this.configurationsService.conf.Current) {
        const filteredAccessories = conf.Accessories.filter((a: IccAccessory) => {
            if (a.accessory.dependent_on_frame_profile) {
                return a.accessory.frame_profiles_ids.map(Number).includes(Number(frameProfileId));
            }
            return a;
        });

        conf.Accessories = filteredAccessories;
    }

    matchAccessoriesForFrameProfile(frameProfile: Profile[], accessories: IccAccessoryAccessory[]) {
        const accessoriesArr = [];

        accessories.forEach((a) => {
            if(a.dependent_on_frame_profile !== true) {
                accessoriesArr.push(a);
            } else {
                if(frameProfile.some((frame) => a.frame_profiles_ids.map(Number).includes(frame.id))) {
                    accessoriesArr.push(a);
                }
            } 
        });

        return accessoriesArr;
    }
}

export function filterAccessories(
    accessories: IccAccessoryAccessory[],
    categoryId: string | number,
    subcatId?: string | number
): IccAccessoryAccessory[] {
    if (isUndefined(categoryId) || categoryId === null || categoryId === '') {
        return accessories;
    } else {
        return accessories.filter(gl => {
            if (gl.parent_id == categoryId || gl.window_accessories_category_id == categoryId) {
                if (isUndefined(subcatId) || subcatId === null || subcatId === '') {
                    return true;
                } else if (gl.window_accessories_category_id == subcatId) {
                    return true;
                }
            }
            return false;
        });
    }
}
