import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
} from '@angular/core';
import {
    PageComponent,
    _,
    ICC_PAGE_DATA,
    SharedFacade,
    ModalService,
} from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { Observable, Subject, merge, BehaviorSubject, Subscription } from 'rxjs';
import { iccListItem, iccListTab, IccSliderStep } from '@icc/configurator/ui';
import { map, startWith, distinctUntilChanged, tap } from 'rxjs/operators';
import {
    APP_CONFIG,
    TranslateService,
    AppConfigFactory,
    core,
    ConfiguratorsDataService,
} from '@icc/common';
import { IccFilling, IccGlassTypeVariant, IccGlassType, IccWarmEdge } from '@icc/common/data-types';
import { FormBuilder } from '@angular/forms';
import { GlassTypeVariantsPageComponent } from '../glass-type-variants-page/glass-type-variants-page.component';
import { FillingsFiltersPageComponent } from '../fillings-filters-page/fillings-filters-page.component';
import { GlassRwFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-rw.filter';
import { GlassSecurityFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-security.filter';
import { GlassUgFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-ug.filter';
import { GlassOrnamentFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-ornament.filter';
import { GlassTypeFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-type.filter';
import { GlassCountFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-count.filter';
import { GlassOftenFilter } from '@icc/legacy/configurator/steps/window/glazings/glass-often.filter';
import { Glass, InterPaneSpace, GlazingUnitElement, FillingsProducer } from '@icc/window';
import { GlazingUnitPageComponent } from '../glazing-unit-page/glazing-unit-page.component';
import { GlazingUnitsService } from '@icc/legacy/configurator/steps/window/glazings/glazing-units.service';
import { FillingOptionsPageComponent } from '../filling-options-page/filling-options-page.component';

interface ThermalTransmittanceLevel extends IccSliderStep {
    id: number;
    label: string;
    description: string;
    minValue: number | null;
    maxValue: number | null;
}

interface SecurityLevel extends IccSliderStep {
    id: number;
    label: string;
    description: string;
    levels: (string | null)[];
}

interface NoiseProtectionLevel extends IccSliderStep {
    id: number;
    label: string;
    description: string;
    minValue: number | null;
    maxValue: number | null;
}

@Component({
    selector: 'icc-fillings-list-page',
    template: require('./fillings-list-page.component.html'),
    styles: [require('./fillings-list-page.component.scss')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillingsListPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title = _('STEPS|Wypełnienie');
    public options = [
        {
            title: _('WINDOW|Lista pakietów szybowych'),
            callback: () => {
                this.mode = 'list';
                this.cdr.markForCheck();
            },
            icon: {
                ligature: 'view_list',
            },
            show: () => this.selectedTab === 'glazing' && this.mode !== 'list',
        },
        {
            title: _('WINDOW|Wybór pakietów szybowych'),
            callback: () => {
                this.mode = 'filters';
                this.cdr.markForCheck();
            },
            icon: {
                ligature: 'view_list',
            },
            show: () =>
                this.selectedTab === 'glazing'
                && this.mode === 'list'
                && !this.pageData.complementary,
        },
        {
            title: _('WINDOW|Filtry'),
            callback: () => {
                this.openClassicFilters();
            },
            icon: {
                ligature: 'tune',
            },
            show: () => this.mode === 'list',
        },
    ];
    isSummaryHidden = true;

    mode: 'filters' | 'list' = 'filters';

    fillings: iccListItem[] = [];
    mainTabs: iccListTab[] = [
        {
            id: 'glazing',
            name: this.translateService.instant('WINDOW|Pakiety szybowe'),
        },
        {
            id: 'pvc_panels',
            name: this.translateService.instant('WINDOW|Panele wypełnieniowe'),
        },
        {
            id: 'deco_panels',
            name: this.translateService.instant('WINDOW|Panele ozdobne'),
        },
    ];
    initTab = 0;
    selectedTab = 'glazing';

    classicFilters: any = null;

    thermalTransmittanceLevels: ThermalTransmittanceLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|≥0,9 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety dwuszybowe. Rozwiązanie ekonomiczne w zakupie. Ze względu na parametry termiczne polecane do budynków tradycyjnych o niższych parametrach izolacji lub wykorzystywanych sezonowo.'
            ),
            minValue: 0.9,
            maxValue: null,
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|0,6-0,8 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety trzyszybowe. Poziom izolacji cieplnej odpowiedni dla budynków termooszczędnych.'
            ),
            minValue: 0.61,
            maxValue: 0.89,
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|≤0,6 W/(m2·K)'),
            description: this.translateService.instant(
                'WINDOW|Pakiety trzyszybowe, poziom izolacji cieplnej odpowiedni dla tzw. budynków pasywnych. Bezkompromisowa oszczędność wydatków na ogrzewanie. '
            ),
            minValue: null,
            maxValue: 0.6,
        },
    ];

    securityLevels: SecurityLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|STD'),
            description: this.translateService.instant(
                'WINDOW|Szyba standardowa, rozwiązanie ekonomiczne w zakupie, nie posiada żadnych dodatkowych zabezpieczeń. W przypadku uszkodzenia może grozić poważnym zranieniem.'
            ),
            levels: [null],
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|ESG'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna hartowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia rozpada się na drobne kawałki, co zmniejsza ryzyko poważnych zranień.'
            ),
            levels: ['esg'],
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|O1/2'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna laminowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia szkło pozostaje na miejscu utrzymane na folii, co zmniejsza ryzyko jakichkolwiek zranień.'
            ),
            levels: ['o1', 'o2'],
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|P1/2'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa P1 lub P2, stanowi podstawowe zabezpieczenie przed włamaniem podjętym bez przygotowania'
            ),
            levels: ['p1a', 'p2a'],
        },
        {
            id: 4,
            label: this.translateService.instant('WINDOW|P3≤'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa klasy P3 lub wyższej, szyby utrudniające włamanie. Klasa P3 ma porównywalną skuteczność do kraty o oczku 15 cm z drutu o średnicy 1cm. Jeśli potrzebujesz pakietów szybowych o wyższych właściwościach (w tym Kuloodporne, odporne na wybuchy, itp.) – skontaktuj się z nami aby otrzymać indywidualną ofertę.'
            ),
            levels: ['p3a', 'p4a', 'p5a', 'p6b', 'p7b', 'p8b'],
        },
    ];

    noiseProtectionLevels: NoiseProtectionLevel[] = [
        {
            id: 0,
            label: this.translateService.instant('WINDOW|≤31 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie standardowe, odpowiednie dla budynków znajdujących się w cichej okolicy, oddalonej od ruchliwych ulic.'
            ),
            minValue: null,
            maxValue: 31,
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|32-36 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o umiarkowanych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się na osiedlach z umiarkowanym ruchem ulicznym, oddalonych od źródeł intensywniejszego hałasu. '
            ),
            minValue: 32,
            maxValue: 36,
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|37-42 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o podwyższonych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się w pobliżu ruchliwych ulic, klubów nocnych itp.'
            ),
            minValue: 37,
            maxValue: 42,
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|≥43 dB'),
            description: this.translateService.instant(
                'WINDOW|Szklenie o wyraźnych właściwościach dźwiękochłonnych, odpowiednie dla budynków znajdujących się w pobliżu dróg szybkiego ruchu, torów kolejowych i portów lotniczych.'
            ),
            minValue: 43,
            maxValue: null,
        },
    ];

    thermalTransmittanceDescription$ = new Observable<string>();
    securityLevelOutsideDescription$ = new Observable<string>();
    securityLevelInsideDescription$ = new Observable<string>();
    noiseProtectionDescription$ = new Observable<string>();

    fillingFilters = this.fb.group({
        thermalTransmittance: [this.thermalTransmittanceLevels[0]],
        securityLevelOutside: [this.securityLevels[0]],
        securityLevelInside: [this.securityLevels[0]],
        noiseProtection: [this.noiseProtectionLevels[0]],
        glassType: ['0'],
        glassTypeVariant: ['0'],
    });

    selectedFilling?: IccFilling;
    selectedGlassTypeVariant: IccGlassTypeVariant | null = null;

    glassTypes: IccGlassType[] = [];
    glassTypeVariants: IccGlassTypeVariant[] = [];
    filtersMap: Record<string, string[]> = {};
    filtersLevels: {
        thermalTransmittance: ThermalTransmittanceLevel[];
        securityLevelOutside: SecurityLevel[];
        securityLevelInside: SecurityLevel[];
        noiseProtection: NoiseProtectionLevel[];
        glassType: IccGlassType[];
        glassTypeVariant: IccGlassTypeVariant[];
    } = {
        thermalTransmittance: [],
        securityLevelOutside: [],
        securityLevelInside: [],
        noiseProtection: [],
        glassType: [],
        glassTypeVariant: [],
    };

    private filters = {
        thermalTransmittance: (thermalTransmittance: ThermalTransmittanceLevel) => (
            filling: IccFilling
        ) =>
            (thermalTransmittance.minValue === null || filling.u >= thermalTransmittance.minValue)
            && (thermalTransmittance.maxValue === null
                || thermalTransmittance.maxValue >= filling.u),
        securityLevelOutside: (securityLevelOutside: SecurityLevel) => (filling: IccFilling) =>
            securityLevelOutside.levels.includes(filling.security_level_outer || null),
        securityLevelInside: (securityLevelInside: SecurityLevel) => (filling: IccFilling) =>
            securityLevelInside.levels.includes(filling.security_level_inner || null),
        noiseProtection: (noiseProtection: NoiseProtectionLevel) => (filling: IccFilling) =>
            (noiseProtection.minValue === null || Number(filling.rw) >= noiseProtection.minValue)
            && (noiseProtection.maxValue === null
                || Number(filling.rw) <= noiseProtection.maxValue),
        glassType: (glassType: string) => (filling: IccFilling) =>
            ((!glassType || Number(glassType) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassType
                && filling.glass_types
                && filling.glass_types.some(gt => gt.glass_type_id === glassType)),
        glassTypeVariant: (glassType: string, glassTypeVariant: number) => (filling: IccFilling) =>
            ((!glassTypeVariant || Number(glassTypeVariant) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassTypeVariant
                && filling.glass_types
                && filling.glass_types.some(
                    gt =>
                        Number(gt.glass_type_id) === Number(glassType)
                        && Number(gt.glass_type_variant_id) === Number(glassTypeVariant)
                )),
    };

    private setAll = 0;
    private changedGlassTypeVariant = false;

    private subscriptions: Subscription[] = [];

    editGlazingUnit = this.config().IccConfig.Configurators.editGlazingUnit;
    hasGlazingUnit = true;

    constructor(
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            complementary: boolean;
            fillings: IccFilling[];
            selGlassType: IccFilling;
            glassTypes: IccGlassType[];
            glassTypeVariants: IccGlassTypeVariant[];
            modalData: {
                glassTab: 'glazing' | 'pvc_panels' | 'deco_panels';
                selectedSecurity: any;
                selectedOrnament: any;
                selectedUg: any;
                selectedRw: any;
                selectedCategory: any;
            };
            filters$: BehaviorSubject<{
                selectedFilling: string | null;
                filters: {
                    thermalTransmittance: ThermalTransmittanceLevel;
                    securityLevelOutside: SecurityLevel;
                    securityLevelInside: SecurityLevel;
                    noiseProtection: NoiseProtectionLevel;
                    glassType: string;
                    glassTypeVariant: number;
                };
                classicFilters: {
                    glassTab: 'glazing' | 'pvc_panels' | 'deco_panels';
                    selectedSecurity: any;
                    selectedOrnament: any;
                    selectedUg: any;
                    selectedRw: any;
                    selectedCategory: any;
                };
            }>;
            addGlazingUnit: any;
            onlyGlazingList: boolean;
            warmEdges: IccWarmEdge[];
        },
        private fb: FormBuilder,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.mainTabs = this.mainTabs.filter(
            tab =>
                (this.pageData.onlyGlazingList && tab.id === 'glazing')
                || (!this.pageData.onlyGlazingList
                    && this.pageData.fillings.some(f => f.type === tab.id))
        );
        this.initTab = this.mainTabs.findIndex(o => o.id === this.pageData.selGlassType.type) || 0;
        this.selectedTab = this.mainTabs.find(o => o.id === this.pageData.selGlassType.type)?.id as string || 'glazing';

        if (this.pageData.complementary || this.pageData.onlyGlazingList || !this.mainTabs.some(tab => tab.id === 'glazing')) {
            this.mode = 'list';
        }
        const thermalTransmittanceControl = this.fillingFilters.get('thermalTransmittance');
        if (thermalTransmittanceControl) {
            this.thermalTransmittanceDescription$ = thermalTransmittanceControl.valueChanges.pipe(
                startWith(this.thermalTransmittanceLevels[0]),
                map((value: ThermalTransmittanceLevel) => value.description)
            );
        }
        const securityLevelOutsideControl = this.fillingFilters.get('securityLevelOutside');
        if (securityLevelOutsideControl) {
            this.securityLevelOutsideDescription$ = securityLevelOutsideControl.valueChanges.pipe(
                startWith(this.securityLevels[0]),
                map((value: SecurityLevel) => value.description)
            );
        }
        const securityLevelInsideControl = this.fillingFilters.get('securityLevelInside');
        if (securityLevelInsideControl) {
            this.securityLevelInsideDescription$ = securityLevelInsideControl.valueChanges.pipe(
                startWith(this.securityLevels[0]),
                map((value: SecurityLevel) => value.description)
            );
        }
        const noiseProtectionControl = this.fillingFilters.get('noiseProtection');
        if (noiseProtectionControl) {
            this.noiseProtectionDescription$ = noiseProtectionControl.valueChanges.pipe(
                startWith(this.noiseProtectionLevels[0]),
                map((value: NoiseProtectionLevel) => value.description)
            );
        }
        const glassTypeControl = this.fillingFilters.get('glassType');
        const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
        this.glassTypes = [
            {
                id: '0',
                name: this.translateService.instant('WINDOW|Szyba standardowa'),
                image: null,
            },
            ...(this.pageData.glassTypes || []),
        ];
        this.glassTypeVariants = this.pageData.glassTypeVariants || [];
        this.loadFillings();
        let oldKey = '';
        if (
            thermalTransmittanceControl
            && securityLevelOutsideControl
            && securityLevelInsideControl
            && noiseProtectionControl
            && glassTypeControl
            && glassTypeVariantControl
        ) {
            this.subscriptions.push(
                merge(
                    thermalTransmittanceControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'thermalTransmittance', value }))
                    ),
                    securityLevelOutsideControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'securityLevelOutside', value }))
                    ),
                    securityLevelInsideControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'securityLevelInside', value }))
                    ),
                    noiseProtectionControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'noiseProtection', value }))
                    ),
                    glassTypeControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassType', value }))
                    ),
                    glassTypeVariantControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassTypeVariant', value }))
                    )
                ).subscribe(({ field, value }) => {
                    const filtersValue = this.fillingFilters.value;
                    filtersValue[field] = value;
                    if (--this.setAll > 0 && (!this.changedGlassTypeVariant || field !== 'glassTypeVariant')) {
                        return;
                    }
                    this.setAll = 0;
                    if (field === 'glassTypeVariant') {
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => Number(variant.id) === Number(value)
                            ) || null;
                    }

                    const filtersValues = {
                        thermalTransmittance: filtersValue.thermalTransmittance,
                        securityLevelOutside: filtersValue.securityLevelOutside,
                        securityLevelInside: filtersValue.securityLevelInside,
                        noiseProtection: filtersValue.noiseProtection,
                        glassType: filtersValue.glassType,
                        glassTypeVariant: filtersValue.glassTypeVariant,
                    };

                    const fieldIndexes = {
                        thermalTransmittance: 0,
                        securityLevelOutside: 1,
                        securityLevelInside: 2,
                        noiseProtection: 3,
                        glassType: 4,
                        glassTypeVariant: 5,
                    };
                    const key = `${filtersValues.thermalTransmittance.id}_${
                        filtersValues.securityLevelOutside.id
                    }_${filtersValues.securityLevelInside.id}_${filtersValues.noiseProtection.id}_${
                        filtersValues.glassType
                    }_${filtersValues.glassTypeVariant}`;
                    if (this.filtersMap[key] || oldKey === key) {
                        oldKey = key;
                        this.matchFilling(key);
                    } else {
                        oldKey = key;
                        const availableCombination = Object.keys(this.filtersMap)
                            .filter(k => k[1] === '_')
                            .reduce(
                                (max, k) => {
                                    const values = key.split('_');
                                    const values2 = k.split('_');
                                    if (
                                        values2[fieldIndexes[field]]
                                            === values[fieldIndexes[field]]
                                        && ((this.filtersLevels.glassType
                                            && this.filtersLevels.glassType.length === 1
                                            && this.filtersLevels.glassTypeVariant
                                            && this.filtersLevels.glassTypeVariant.length > 1)
                                            || (values2[fieldIndexes['glassType']]
                                                === values[fieldIndexes['glassType']]
                                                && (values2[fieldIndexes['glassTypeVariant']]
                                                    === values[fieldIndexes['glassTypeVariant']]
                                                    || field === 'glassType'
                                                    || values[fieldIndexes['glassTypeVariant']]
                                                        === '0'
                                                    || values[fieldIndexes['glassTypeVariant']]
                                                        === 'undefined')))
                                    ) {
                                        const matchCount = values.reduce(
                                            (prev, cur, index) =>
                                                values2[index] === cur ? ++prev : prev,
                                            0
                                        );
                                        const matchDiff = values.reduce(
                                            (prev, cur, index) =>
                                                Number(values2[index]) - Number(cur) + prev,
                                            0
                                        );
                                        if (
                                            matchCount > max.match
                                            || (matchCount === max.match
                                                && Math.abs(matchDiff) < max.matchDiff)
                                        ) {
                                            return {
                                                key: k,
                                                match: matchCount,
                                                matchDiff: Math.abs(matchDiff),
                                            };
                                        } else {
                                            return max;
                                        }
                                    } else {
                                        return max;
                                    }
                                },
                                { key: '', match: 0, matchDiff: 0 }
                            );
                        if (availableCombination.key) {
                            const newFiltersValues = availableCombination.key.split('_');
                            this.setAll = 6 - availableCombination.match;
                            this.fillingFilters.patchValue({
                                thermalTransmittance: this.thermalTransmittanceLevels.find(
                                    l => l.id === Number(newFiltersValues[0])
                                ),
                                securityLevelOutside: this.securityLevels.find(
                                    l => l.id === Number(newFiltersValues[1])
                                ),
                                securityLevelInside: this.securityLevels.find(
                                    l => l.id === Number(newFiltersValues[2])
                                ),
                                noiseProtection: this.noiseProtectionLevels.find(
                                    l => l.id === Number(newFiltersValues[3])
                                ),
                                glassType: String(newFiltersValues[4] || '0'),
                                glassTypeVariant: newFiltersValues[5] || 0,
                            });
                        } else {
                            this.matchFilling('');
                        }
                    }
                })
            );
        }

        this.selectedFilling = this.pageData.selGlassType;
        this.setStartFiltersValues();
        this.subscriptions.push(
            this.pageData.filters$.subscribe(filters => {
                if (filters.selectedFilling) {
                    this.selectedFilling = this.pageData.fillings.find(
                        f => f.id === filters.selectedFilling
                    );
                    if (this.selectedFilling) {
                        const match = Object.keys(this.fillingFilters.value).reduce(
                            (prev, cur) =>
                                prev
                                + (this.fillingFilters.value[cur] !== filters.filters[cur] ? 1 : 0),
                            0
                        );
                        this.changedGlassTypeVariant = this.fillingFilters.value.glassTypeVariant !== filters.filters.glassTypeVariant;
                        this.setAll = match;
                        const updateFilters = this.updateFilterRefs(filters.filters);
                        this.fillingFilters.setValue(updateFilters);
                    }
                }
                if (filters.classicFilters) {
                    this.mode = 'list';
                    this.filterFillings(filters.classicFilters);
                    this.classicFilters = filters.classicFilters;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    loadFillings(fillings = this.pageData.fillings) {
        this.filtersMap = {};
        this.fillings = fillings
            .filter(filling => filling.type === this.selectedTab)
            .map<iccListItem>(filling => {
                const {
                    thermalTransmittance,
                    securityLevelOutside,
                    securityLevelInside,
                    noiseProtection,
                    glassType,
                    glassTypeVariant,
                } = this.matchFiltersValues(filling);

                if (thermalTransmittance) {
                    if (!this.filtersMap['thermalTransmittance_' + thermalTransmittance.id]) {
                        this.filtersMap['thermalTransmittance_' + thermalTransmittance.id] = [];
                    }
                    this.filtersMap['thermalTransmittance_' + thermalTransmittance.id].push(
                        filling.id
                    );
                }
                if (securityLevelOutside) {
                    if (!this.filtersMap['securityLevelOutside_' + securityLevelOutside.id]) {
                        this.filtersMap['securityLevelOutside_' + securityLevelOutside.id] = [];
                    }
                    this.filtersMap['securityLevelOutside_' + securityLevelOutside.id].push(
                        filling.id
                    );
                }
                if (securityLevelInside) {
                    if (!this.filtersMap['securityLevelInside_' + securityLevelInside.id]) {
                        this.filtersMap['securityLevelInside_' + securityLevelInside.id] = [];
                    }
                    this.filtersMap['securityLevelInside_' + securityLevelInside.id].push(
                        filling.id
                    );
                }
                if (noiseProtection) {
                    if (!this.filtersMap['noiseProtection_' + noiseProtection.id]) {
                        this.filtersMap['noiseProtection_' + noiseProtection.id] = [];
                    }
                    this.filtersMap['noiseProtection_' + noiseProtection.id].push(filling.id);
                }
                if (glassType) {
                    if (!this.filtersMap['glassType_' + glassType.id]) {
                        this.filtersMap['glassType_' + glassType.id] = [];
                    }
                    this.filtersMap['glassType_' + glassType.id].push(filling.id);
                }
                if (glassTypeVariant) {
                    if (!this.filtersMap['glassTypeVariant_' + glassTypeVariant.id]) {
                        this.filtersMap['glassTypeVariant_' + glassTypeVariant.id] = [];
                    }
                    this.filtersMap['glassTypeVariant_' + glassTypeVariant.id].push(filling.id);
                }

                const key = `${thermalTransmittance.id}_${securityLevelOutside.id}_${
                    securityLevelInside.id
                }_${noiseProtection.id}_${(glassType && glassType.id) || '0'}_${(glassTypeVariant
                    && glassTypeVariant.id)
                    || '0'}`;
                if (!this.filtersMap[key]) {
                    this.filtersMap[key] = [];
                }
                this.filtersMap[key].push(filling.id);
                return {
                    id: filling.id,
                    title: filling.name,
                    imageUrl: `/files/filling/${filling.image}`,
                    description: `${filling.code || filling.name}`,
                    filters: {
                        thermalTransmittance,
                        securityLevelOutside,
                        securityLevelInside,
                        noiseProtection,
                        glassType: (glassType && glassType.id) || '0',
                        glassTypeVariant: (glassTypeVariant && glassTypeVariant.id) || 0,
                    },
                    editGlazing: filling.has_glazing_unit,
                };
            });
        Object.keys(this.filtersMap).forEach(key => {
            const [filter, id] = key.split('_');
            if (
                filter === 'thermalTransmittance'
                && !this.filtersLevels.thermalTransmittance.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.thermalTransmittance.push(
                    this.thermalTransmittanceLevels.find(l => l.id === Number(id))
                        || this.thermalTransmittanceLevels[0]
                );
            }
            if (
                filter === 'securityLevelOutside'
                && !this.filtersLevels.securityLevelOutside.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.securityLevelOutside.push(
                    this.securityLevels.find(l => l.id === Number(id)) || this.securityLevels[0]
                );
            }
            if (
                filter === 'securityLevelInside'
                && !this.filtersLevels.securityLevelInside.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.securityLevelInside.push(
                    this.securityLevels.find(l => l.id === Number(id)) || this.securityLevels[0]
                );
            }
            if (
                filter === 'noiseProtection'
                && !this.filtersLevels.noiseProtection.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.noiseProtection.push(
                    this.noiseProtectionLevels.find(l => l.id === Number(id))
                        || this.noiseProtectionLevels[0]
                );
            }
            if (
                filter === 'glassType'
                && !this.filtersLevels.glassType.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassType.push(
                    this.glassTypes.find(l => Number(l.id) === Number(id)) || this.glassTypes[0]
                );
            }
            if (
                filter === 'glassTypeVariant'
                && !this.filtersLevels.glassTypeVariant.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassTypeVariant.push(
                    this.glassTypeVariants.find(l => Number(l.id) === Number(id))
                        || this.glassTypeVariants[0]
                );
            }
        });
        this.filtersLevels.thermalTransmittance.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelOutside.sort((a, b) => a.id - b.id);
        this.filtersLevels.securityLevelInside.sort((a, b) => a.id - b.id);
        this.filtersLevels.noiseProtection.sort((a, b) => a.id - b.id);
        this.filtersLevels.glassType.sort((a, b) => Number(a.id) - Number(b.id));
        this.filtersLevels.glassTypeVariant.sort((a, b) => Number(a.id) - Number(b.id));
        this.cdr.markForCheck();
    }

    selectFilling(item: iccListItem) {
        const filling = this.pageData.fillings.find(f => f.id === item.id);
        if (this.pageData.complementary) {
            this.modalService
                .open({
                    pageComponent: FillingOptionsPageComponent,
                    resolve: {
                        filling,
                        warmEdges: this.pageData.warmEdges,
                    },
                })
                .result.then(result => {
                    this.sharedFacade.closePage({
                        glass: result.accessory,
                        selectedSecurity: null,
                        selectedOrnament: null,
                        selectedUg: null,
                        selectedRw: null,
                        selectedCategory: null,
                        glassTab: null,
                        bondedGlazing: null,
                    });
                });
        } else {
            this.sharedFacade.closePage({
                glass: filling,
                selectedSecurity: null,
                selectedOrnament: null,
                selectedUg: null,
                selectedRw: null,
                selectedCategory: null,
                glassTab: null,
                bondedGlazing: null,
            });
        }
    }

    select() {
        this.sharedFacade.closePage({
            glass: this.selectedFilling,
            selectedSecurity: null,
            selectedOrnament: null,
            selectedUg: null,
            selectedRw: null,
            selectedCategory: null,
            glassTab: null,
            bondedGlazing: null,
        });
    }

    changedTab(tab: iccListTab) {
        if (this.selectedTab !== String(tab.id)) {
            this.selectedTab = String(tab.id);
            if (
                tab.id === 'glazing'
                && this.mode !== 'filters'
                && !this.pageData.complementary
                && !this.pageData.onlyGlazingList
            ) {
                this.mode = 'filters';
            } else if (tab.id !== 'glazing' && this.mode !== 'list') {
                this.mode = 'list';
            }
            this.loadFillings();
        }
    }

    setDefaultFiltersValues() {}

    changeGlassType(glassType: IccGlassType) {
        this.fillingFilters.patchValue({
            glassType: glassType.id,
        });
    }

    matchFilling(key: string) {
        if (
            this.filtersMap[key]
            && (!this.selectedFilling || !this.filtersMap[key].includes(this.selectedFilling.id))
        ) {
            this.selectedFilling = this.pageData.fillings.find(filling => {
                return (
                    filling.type === 'glazing'
                    && this.filtersMap[key]
                    && filling.id === this.filtersMap[key][0]
                );
            });
        }
    }

    selectGlassTypeVariant() {
        this.modalService
            .open({
                pageComponent: GlassTypeVariantsPageComponent,
                resolve: {
                    glassTypeVariants: this.getMatchingVariants(),
                    selectedVariant: this.fillingFilters.value.glassTypeVariant,
                },
            })
            .result.then(result => {
                if (result) {
                    const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
                    if (glassTypeVariantControl) {
                        glassTypeVariantControl.setValue(result);
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => String(variant.id) === String(result)
                            ) || null;
                        this.pageData.filters$.next({
                            selectedFilling:
                                (this.selectedFilling && this.selectedFilling.id) || null,
                            filters: this.fillingFilters.value,
                            classicFilters: this.classicFilters,
                        });
                    }
                }
            });
    }

    getMatchingVariants() {
        return (this.pageData.glassTypeVariants || []).filter(
            variant =>
                Number(variant.glass_type_id) === Number(this.fillingFilters.value.glassType)
                && this.filtersLevels.glassTypeVariant.map(v => Number(v.id)).includes(Number(variant.id))
        );
    }

    openClassicFilters() {
        this.modalService
            .open({
                pageComponent: FillingsFiltersPageComponent,
                resolve: {
                    IccConfig: this.config().IccConfig,
                    filters: this.classicFilters,
                    areOrnaments: GlassOrnamentFilter()(this.pageData.fillings, true).length > 0,
                    glassTypes: this.glassTypes,
                },
                filters: true,
            })
            .result.then(result => {
                if (result) {
                    this.classicFilters = result.filters;
                    this.pageData.filters$.next({
                        selectedFilling: (this.selectedFilling && this.selectedFilling.id) || null,
                        filters: this.fillingFilters.value,
                        classicFilters: result.filters,
                    });
                }
            });
    }

    openGlazingUnitModal(item: iccListItem) {
        const filling = this.pageData.fillings.find(f => String(f.id) === String(item.id));
        if (this.editGlazingUnit && filling) {
            this.pageData.addGlazingUnit(filling).then(f => {
                if (f) {
                    if (f.custom) {
                        this.pageData.fillings.unshift(f);
                    }
                    this.selectFilling({
                        id: f.id,
                        title: f.code || f.name,
                        imageUrl: `/files/filling/${f.image}`,
                        description: `${f.name}`,
                        editGlazing: f.has_glazing_unit,
                    });
                } else {
                    this.selectFilling({
                        id: filling.id,
                        title: filling.code || f.name,
                        imageUrl: `/files/filling/${filling.image}`,
                        description: `${filling.name}`,
                        editGlazing: filling.has_glazing_unit,
                    });
                }
            });
        }
    }

    private matchFiltersValues(filling: IccFilling) {
        const thermalTransmittance =
            this.thermalTransmittanceLevels.find(level =>
                this.filters.thermalTransmittance(level)(filling)
            ) || this.thermalTransmittanceLevels[0];
        const securityLevelOutside =
            this.securityLevels.find(level => this.filters.securityLevelOutside(level)(filling))
            || this.securityLevels[0];
        const securityLevelInside =
            this.securityLevels.find(level => this.filters.securityLevelInside(level)(filling))
            || this.securityLevels[0];
        const noiseProtection =
            this.noiseProtectionLevels.find(level => this.filters.noiseProtection(level)(filling))
            || this.noiseProtectionLevels[0];
        const glassType = this.glassTypes.find(type => this.filters.glassType(type.id)(filling));
        let glassTypeVariant = null;
        if (glassType) {
            glassTypeVariant = this.glassTypeVariants.find(variant =>
                this.filters.glassTypeVariant(glassType.id, variant.id)(filling)
            );
        }
        return {
            thermalTransmittance,
            securityLevelOutside,
            securityLevelInside,
            noiseProtection,
            glassType,
            glassTypeVariant,
        };
    }

    private setStartFiltersValues() {
        if (this.selectedFilling) {
            const {
                thermalTransmittance,
                securityLevelOutside,
                securityLevelInside,
                noiseProtection,
                glassType,
                glassTypeVariant,
            } = this.matchFiltersValues(this.selectedFilling);
            const startValue = {
                thermalTransmittance,
                securityLevelOutside,
                securityLevelInside,
                noiseProtection,
                glassType: (glassType && String(glassType.id)) || '0',
                glassTypeVariant: (glassTypeVariant && glassTypeVariant.id) || 0,
            };
            const match = Object.keys(this.fillingFilters.value).reduce(
                (prev, cur) =>
                    prev
                    + (this.fillingFilters.value[cur] !== startValue[cur]
                    || this.fillingFilters.value[cur].id !== startValue[cur].id
                        ? 1
                        : 0),
                0
            );
            this.setAll = match;
            this.fillingFilters.patchValue(startValue);
        }
    }

    private filterFillings(filters) {
        let fillingsFiltered = this.pageData.fillings;
        fillingsFiltered = GlassSecurityFilter()(
            fillingsFiltered,
            filters.selectedSecurity,
            this.config().IccConfig.Configurators.glazingFilters
        );
        fillingsFiltered = GlassUgFilter()(fillingsFiltered, filters.selectedUg);
        fillingsFiltered = GlassRwFilter()(fillingsFiltered, filters.selectedRw);
        fillingsFiltered = GlassOrnamentFilter()(fillingsFiltered, filters.selectedOrnament);
        fillingsFiltered = GlassTypeFilter()(fillingsFiltered, filters.selectedColored);
        fillingsFiltered = GlassCountFilter()(fillingsFiltered, filters.glazingCount);
        fillingsFiltered = GlassOftenFilter()(fillingsFiltered, filters.selectedOften);
        this.loadFillings(fillingsFiltered);
    }

    private updateFilterRefs(filters: {
        thermalTransmittance: ThermalTransmittanceLevel;
        securityLevelOutside: SecurityLevel;
        securityLevelInside: SecurityLevel;
        noiseProtection: NoiseProtectionLevel;
        glassType: string;
        glassTypeVariant: number;
    }): {
        thermalTransmittance: ThermalTransmittanceLevel;
        securityLevelOutside: SecurityLevel;
        securityLevelInside: SecurityLevel;
        noiseProtection: NoiseProtectionLevel;
        glassType: string;
        glassTypeVariant: number;
    } {
        return {
            thermalTransmittance: this.filtersLevels.thermalTransmittance.find(level => level.id == filters.thermalTransmittance.id) || this.filtersLevels.thermalTransmittance[0],
            securityLevelOutside: this.filtersLevels.securityLevelOutside.find(level => level.id == filters.securityLevelOutside.id) || this.filtersLevels.securityLevelOutside[0],
            securityLevelInside: this.filtersLevels.securityLevelInside.find(level => level.id == filters.securityLevelInside.id) || this.filtersLevels.securityLevelInside[0],
            noiseProtection: this.filtersLevels.noiseProtection.find(level => level.id == filters.noiseProtection.id) || this.filtersLevels.noiseProtection[0],
            glassType: filters.glassType,
            glassTypeVariant: filters.glassTypeVariant
        };
    }
}
