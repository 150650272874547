import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { core } from '@icc/common/helpers';

@Injectable()
export class ConfiguratorsAvailabilityService {
    configs = [
        'window',
        'hs',
        'door',
        'folding_door',
        'sliding_door',
        'roller_shutter',
        'external_blind',
        'accessory',
        'garage_door',
        'awning',
        'complementary_goods',
        'winter_garden',
        'mosquito',
        'coupled_window',
    ];

    availableConfigs: Record<string, any> = {};

    private configsConditions = {
        window: ['windowLines'],
        hs: ['hsLines'],
        door: ['doorLines'],
        folding_door: ['foldingDoorLines'],
        sliding_door: ['slidingDoorLines'],
        roller_shutter: ['rollerShutterSystems', 'shutterLines'],
        accessory: [],
        garage_door: ['garageLines'],
        awning: ['awningLines'],
        complementary_goods: [],
        winter_garden: ['winterGardens'],
        mosquito: ['mosquitoLines'],
        coupled_window: [],
    };

    constructor(
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private eventBusService: EventBusService,
        private configuratorsDataService: ConfiguratorsDataService
    ) {
        this.getConfiguratorsAvailability();
        this.eventBusService.subscribeWithoutConfiguration('loadedConfiguratorsData', data =>
            this.getConfiguratorsAvailability(data.value)
        );
    }

    async getFilteredConfiguratorsAvailability(filter: Record<string, number[]>) {
        const data = await this.configuratorsDataService.dataAsync();
        const availableConfigs = core.copy(this.availableConfigs);
        Object.keys(availableConfigs).forEach(
            config =>
                (availableConfigs[config] =
                    availableConfigs[config]
                    && (!this.configsConditions[config]
                        || this.configsConditions[config].every(
                            el =>
                                data
                                && data[el]
                                && (
                                    !filter[el]
                                    || data[el].filter(e =>
                                        filter[el].map(Number).some(id => Number(e.id) === id)
                                    )
                                ).length > 0
                        )))
        );
        return availableConfigs;
    }

    private getConfiguratorsAvailability(data = null) {
        this.availableConfigs = {};
        Object.keys(this.configsConditions).forEach(config => {
            if (config === 'complementary_goods') {
                const glass = data && data.fillings != null && data.fillings.length > 0;
                const windowsill =
                    data && data.windowSillsGoods != null && data.windowSillsGoods.length > 0;
                const accessory =
                    data
                    && data.windowAccessories
                    && data.windowAccessories.some(el => el.access_conf);
                const profile =
                    data
                    && data.profiles != null
                    && data.profiles.some(el =>
                        ['coupling', 'extension', 'other'].includes(el.type)
                    );
                const cassonetto = data && data.cassonettos != null && data.cassonettos.length > 0;
                this.availableConfigs[config] =
                    this.config().IccConfig.Configurators.active.includes(config)
                    && (glass || windowsill || accessory || profile || cassonetto);
                this.availableConfigs.complementaryGoodsConfigs = {
                    glass,
                    windowsill,
                    accessory,
                    profile,
                    cassonetto,
                };
            } else if (config === 'accessory') {
                this.availableConfigs[config] =
                    this.config().IccConfig.Configurators.active.includes(config)
                    && (!data
                        || (data
                            && data.windowAccessories
                            && data.windowAccessories.some(el => el.access_conf)));
            } else {
                this.availableConfigs[config] =
                    this.config().IccConfig.Configurators.active.includes(config)
                    && (!data
                        || (data
                            && this.configsConditions[config].every(
                                el => data[el] && data[el].length
                            )));
            }
        });

        this.eventBusService.post({
            key: 'availableConfigs',
            value: this.availableConfigs,
        });
    }
}
