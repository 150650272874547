import { Common } from '@icc/common/Common';

import {core} from '@icc/common/helpers';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {EventBusService} from '@icc/common/event-bus.service';
import {DiscountsAndMultipliersService} from '@icc/price/b2b';;
import {DatabaseManagerToken, IccSimpleDatabase} from '@icc/helpers/browser';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class BrowserDiscountsAndMultipliersService extends DiscountsAndMultipliersService {

    loading = false;
    loaded$ = new BehaviorSubject(false);

    /**
     * Creates an instance of CustomPricesService.
     *
     * @param {DatabaseManager} DatabaseManager
     *
     * @memberOf CustomPricesService
     */
    constructor(
        private EventBusService: EventBusService,
        private http: HttpClient,
        @Inject(DatabaseManagerToken) private DatabaseManager,
        @Inject(APP_CONFIG) protected config: AppConfigFactory
    ) {
        super();
        this.load();

        this.EventBusService.subscribeWithoutConfiguration('syncedDiscountsAndMultipliers', data => {
            this.reload();
        });
    }


    async reload() {
        this.offerDiscounts = [];
        this.multipliers = {};
        this.buyDiscounts = {};
        this.saleDiscounts = {};
        this.unavailableSystems = [];

        this.load();
    }

    async load() {
        if (this.DatabaseManager.databasesStore.length === 0) {
            return;
        }
        const data: any = await this.DatabaseManager.get('DiscountsAndMultipliers').get();
        if (data && data.data) {
            const parsedData = core.parseJson(data.data);
            if (Common.isObject(parsedData)) {
                this.offerDiscounts = parsedData.offerDiscounts || [];
                this.multipliers = !core.isEmpty(parsedData.multipliers)
                    ? parsedData.multipliers
                    : {
                        Commodity: [1],
                        Service: [1],
                        Supplement: [1],
                    };
                this.buyDiscounts = parsedData.buyDiscounts || {};
                this.saleDiscounts = parsedData.saleDiscounts || {};
                this.unavailableSystems = parsedData.unavailableSystems || [];
                this.loaded = true;
            }
        }
    }

    loadDataFromServer() {
        const discountsDB = this.DatabaseManager.get('DiscountsAndMultipliers') as IccSimpleDatabase;
        return new Promise(resolve => {
            if (!this.loading) {
                this.loading = true;
                this.http
                    .get((this.config().EnvConfig.remoteHost || window.location.origin) + '/sync/sync/get_discounts_and_multipliers.json')
                    .pipe(take(1))
                    .subscribe((data: any) => {
                        discountsDB.put({ data: data }).then(() => {
                            this.loading = false;
                            this.load().then(() => {
                                resolve();
                            });
                        });
                    });
            }
        });
    }
}
