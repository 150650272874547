import { Component, OnInit, Inject, HostListener, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    DrawService,
    TranslateService,
    core,
    Common,
    WindowActiveConfiguration,
} from '@icc/common';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { EqualDivisionService } from '@icc/legacy/configurator/layout/equal-division.service';
import { ResizeSashService } from '@icc/legacy/configurator/layout/resize-sash.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { AlignmentsService } from '@icc/legacy/configurator/layout/alignments.service';
import { SashesService } from '@icc/legacy/configurator/steps/window/sashes/sashes.service';
import { SashesTypesService } from '@icc/legacy/configurator/steps/window/sashes/sashes-types.service';
import { RemoveSashService } from '@icc/legacy/configurator/layout/remove-sash.service';
import { SplitSashService } from '@icc/legacy/configurator/layout/split-sash.service';
import { BehaviorSubject } from 'rxjs';
import { WindowFacade } from '../+state/window.facade';

@Component({
    selector: 'icc-sash-layout-page',
    template: require('./sash-layout-page.component.html'),
    styles: [require('./sash-layout-page.component.scss')],
})
export class SashLayoutPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Edycja konstrukcji');
    fullscreen = true;

    changeIndividualProfiles = false;

    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            const defaultConf = this.configurationsService.conf.Default;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    dimensions: {
                        multiplier: 1,
                        type: this.drawButtons.fullscreen
                            ? 'extended'
                            : this.drawService.options.dimensions.type,
                    },

                    frame: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(conf, defaultConf, 'frame', {
                                              position: response.index,
                                              frame: response.frame,
                                          }),
                                      onHover: this.drawButtons.profiles
                                          ? 'single-hover'
                                          : 'group-hover',
                                  }
                                : {},
                    },
                    sashFrame: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(
                                              conf,
                                              defaultConf,
                                              'sashFrame',
                                              {
                                                  position: response.index,
                                                  sash: response.sash,
                                              }
                                          ),
                                      onHover: this.drawButtons.profiles
                                          ? 'single-hover'
                                          : 'group-hover',
                                  }
                                : {},
                    },
                    extensions: {
                        actions: {
                            onClick: (response: any) =>
                                ((response.extensions[response.index].side === 'bottom'
                                    && !conf.foundationProfile)
                                || response.extensions[response.index].side !== 'bottom')
                                && this.profilesModalService.changeProfile(conf, defaultConf, 'extension', {
                                    extension: response.extensions[response.index].extension,
                                    position: response.extensions[response.index].side,
                                }),
                            onHover: 'single-hover',
                        },
                        buttons: [
                            {
                                class: 'ico-sides',
                                positions: ['top', 'bottom', 'left', 'right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Edytuj poszerzenia'
                                ),
                                condition: (response: any) =>
                                        (response.index === 'bottom'
                                            && !conf.foundationProfile)
                                        || response.index !== 'bottom',
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        condition: (response: any) =>
                                            (response.index === 'bottom'
                                                && conf.Shape.shape !== 'circle')
                                            || (response.index !== 'bottom'
                                                && conf.Shape.shape === 'rect'
                                                && !conf.hasRoller),
                                        onClick: (response: any) =>
                                            this.profilesModalService.selectExtensionAndPut(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom', 'left', 'right', 'top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Dodaj poszerzenie'
                                        ),
                                    },
                                    {
                                        class: 'ico-remove',
                                        condition: (response: any) => {
                                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                                response.index,
                                                conf
                                            );
                                            return (
                                                ((response.index === 'bottom'
                                                    && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller))
                                                && frameEdges
                                                && conf.SideProfiles.filter(sp =>
                                                    sp.framesId.some(f =>
                                                        frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                    )
                                                ).length
                                            );
                                        },
                                        onClick: (response: any) =>
                                            this.extensionsService.removeExtensionFromSide(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom', 'left', 'right', 'top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń poszerzenie'
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    mullion: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(conf, defaultConf, 'mullion', {
                                              mullion: response.mullion,
                                          }),
                                      condition: (response: any) => response.mullion.profileId,
                                      onHover: 'group-hover',
                                      title: this.translateService.instant(
                                          'INTERFACE|Edytuj słupek'
                                      ),
                                  }
                                : {},
                        buttons:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? [
                                      {
                                          class: 'ico-move',
                                          title: this.translateService.instant(
                                              'INTERFACE|Edytuj słupek'
                                          ),
                                          buttons: [
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  onClick: (response: any) => {
                                                      const sash = response.sash
                                                          ? core.fIdO(conf.Sashes, response.sash.id)
                                                          : null;
                                                      const mullion = sash
                                                          ? core.fIdO(
                                                                sash.intMullions,
                                                                response.mullion.id
                                                            )
                                                          : core.fIdO(
                                                                conf.Mullions,
                                                                response.mullion.id
                                                            );
                                                      if (mullion) {
                                                          this.equalDivisionService.equalDivision(
                                                              mullion,
                                                              conf,
                                                              'glazing'
                                                          );
                                                      }
                                                  },
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  onClick: (response: any) => {
                                                      const sash = response.sash
                                                          ? core.fIdO(conf.Sashes, response.sash.id)
                                                          : null;
                                                      const mullion = sash
                                                          ? core.fIdO(
                                                                sash.intMullions,
                                                                response.mullion.id
                                                            )
                                                          : core.fIdO(
                                                                conf.Mullions,
                                                                response.mullion.id
                                                            );
                                                      if (mullion) {
                                                          this.equalDivisionService.equalDivision(
                                                              mullion,
                                                              conf,
                                                              'axis'
                                                          );
                                                      }
                                                  },
                                              },
                                              {
                                                  class: 'ico-move-submit',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Edytuj słupek'
                                                  ),
                                                  condition: (response: any) =>
                                                      response.mullion.profileId,
                                                  onClick: (response: any) =>
                                                      this.profilesModalService.changeProfile(
                                                          conf,
                                                          defaultConf,
                                                          'mullion',
                                                          { mullion: response.mullion }
                                                      ),
                                              },
                                              {
                                                  class: 'ico-move',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Przesuń słupek'
                                                  ),
                                                  onMove: (response: any) => {
                                                      const sash = response.sash
                                                          ? core.fIdO(conf.Sashes, response.sash.id)
                                                          : null;
                                                      const mullion = sash
                                                          ? core.fIdO(
                                                                sash.intMullions,
                                                                response.mullion.id
                                                            )
                                                          : core.fIdO(
                                                                conf.Mullions,
                                                                response.mullion.id
                                                            );
                                                      if (mullion) {
                                                          const diff =
                                                              mullion[
                                                                  mullion.direction === 'vertical'
                                                                      ? 'rx'
                                                                      : 'ry'
                                                              ] - response.position;
                                                          this.resizeSashService.changeSashWidth(
                                                              mullion,
                                                              diff,
                                                              response.direction,
                                                              conf
                                                          );
                                                      }
                                                  },
                                              },
                                          ],
                                      },
                                  ]
                                : [],
                        minPosition: this.config().IccConfig.Configurators.minWidth || 0,
                        maxPosition: null,
                    },
                    glazing: {
                        buttons: [
                            {
                                class: 'ico-gear',
                                title: this.translateService.instant('INTERFACE|Edytuj kwaterę'),
                                buttons: [
                                    {
                                        class: 'ico-split-h',
                                        title: this.translateService.instant(
                                            'INTERFACE|Podziel w poziomie'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return sash && this.canDivIntHorizontal(sash, conf);
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return sash && this.canDivHorizontal(sash, conf);
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                const intSash =
                                                    sash
                                                    && core.fIdO(sash.intSashes, response.sash.id);
                                                if (intSash && sash) {
                                                    this.splitSashService.splitInternalSash(
                                                        sash,
                                                        intSash,
                                                        'horizontal',
                                                        conf
                                                    );
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.splitSashService.splitSash(
                                                        sash,
                                                        'horizontal',
                                                        conf
                                                    );
                                                }
                                            }
                                        },
                                    },
                                    {
                                        class: 'ico-split-v',
                                        title: this.translateService.instant(
                                            'INTERFACE|Podziel w pionie'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return sash && this.canDivIntVertical(sash, conf);
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return sash && this.canDivVertical(sash, conf);
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                const intSash =
                                                    sash
                                                    && core.fIdO(sash.intSashes, response.sash.id);
                                                if (intSash && sash) {
                                                    this.splitSashService.splitInternalSash(
                                                        sash,
                                                        intSash,
                                                        'vertical',
                                                        conf
                                                    );
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.splitSashService.splitSash(
                                                        sash,
                                                        'vertical',
                                                        conf
                                                    );
                                                }
                                            }
                                        },
                                    },
                                    {
                                        class: 'ico-remove',
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń kwaterę'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return (
                                                    sash
                                                    && (['window', 'door'].includes(conf.type)
                                                        || (conf.System.confType === 'hs'
                                                            && this.config().IccConfig.Configurators
                                                                .hs.layoutEditing)
                                                        || sash.intSashes.length > 1)
                                                );
                                            } else {
                                                return conf.Sashes.length > 1;
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                if (sash) {
                                                    const intSash = core.fIdO(
                                                        sash.intSashes,
                                                        response.sash.id
                                                    );
                                                    if (intSash) {
                                                        this.removeSashService.removeSash(
                                                            intSash,
                                                            conf
                                                        );
                                                    }
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.removeSashService.removeSash(sash, conf);
                                                }
                                            }
                                        },
                                    },
                                    {
                                        class: 'ico-sash',
                                        title: this.translateService.instant(
                                            'INTERFACE|Funkcja kwatery'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                return (
                                                    conf.type !== 'hs'
                                                    || this.config().IccConfig.Configurators.hs
                                                        .layoutEditing
                                                );
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return (
                                                    sash
                                                    && ((conf.type !== 'hs'
                                                        || this.config().IccConfig.Configurators.hs
                                                            .layoutEditing)
                                                        && (conf.type !== 'door'
                                                            || (sash.type
                                                                && sash.type.type !== 'F')
                                                            || sash.nearMullions.top !== -1
                                                            || sash.nearMullions.bottom === -1))
                                                );
                                            }
                                        },
                                        onClick: (response: any) => {
                                            // TODO: this.sashesService.openSashTypeModal(response.sash);
                                            const sashId = Common.isDefined(response.sash.parentId)
                                                ? response.sash.parentId
                                                : response.sash.id;
                                            const sash = core.fIdO(conf.Sashes, sashId);
                                            this.sashesTypesService.openSashTypeModal(sash);
                                        },
                                    },
                                    {
                                        class: 'ico-sides',
                                        title: this.translateService.instant(
                                            'INTERFACE|Edytuj wyrównania'
                                        ),
                                        condition: (response: any) => {
                                            const sash = core.fIdO(
                                                conf.Sashes,
                                                response.sash.parentId
                                            );
                                            const field = sash
                                                ? core.fIdO(sash.intSashes, response.sash.id)
                                                : core.fIdO(conf.Sashes, response.sash.id);
                                            return this.alignmentsService.isAvailableAlignments(
                                                field,
                                                conf
                                            );
                                        },
                                        buttons: [
                                            {
                                                class: 'ico-plus',
                                                title: this.translateService.instant(
                                                    'INTERFACE|Dodaj wyrównanie'
                                                ),
                                                condition: (response: any) =>
                                                    (response.index === 'bottom'
                                                        && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller),
                                                onClick: (response: any) => {
                                                    const sash = core.fIdO(
                                                        conf.Sashes,
                                                        response.sash.parentId
                                                    );
                                                    const field = sash
                                                        ? core.fIdO(
                                                              sash.intSashes,
                                                              response.sash.id
                                                          )
                                                        : core.fIdO(conf.Sashes, response.sash.id);
                                                    this.profilesModalService.selectAlignmentAndPut(
                                                        field,
                                                        conf,
                                                        response.index
                                                    );
                                                },
                                                positions: ['bottom', 'left', 'right', 'top'],
                                            },
                                            {
                                                class: 'ico-remove',
                                                title: this.translateService.instant(
                                                    'INTERFACE|Usuń wyrównanie'
                                                ),
                                                condition: (response: any) =>
                                                    ((response.index === 'bottom'
                                                        && conf.Shape.shape !== 'circle')
                                                        || (response.index !== 'bottom'
                                                            && conf.Shape.shape === 'rect'
                                                            && !conf.hasRoller))
                                                    && response.sash.nearAlignments[response.index]
                                                        !== -1,
                                                onClick: (response: any) => {
                                                    const sash = core.fIdO(
                                                        conf.Sashes,
                                                        response.sash.parentId
                                                    );
                                                    const field = sash
                                                        ? core.fIdO(
                                                              sash.intSashes,
                                                              response.sash.id
                                                          )
                                                        : core.fIdO(conf.Sashes, response.sash.id);
                                                    this.alignmentsService.removeAlignmentInField(
                                                        field,
                                                        conf,
                                                        response.index
                                                    );
                                                },
                                                positions: ['bottom', 'left', 'right', 'top'],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    coupling: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(
                                              conf,
                                              defaultConf,
                                              'coupling',
                                              {
                                                  coupling: response.coupling,
                                              }
                                          ),
                                      condition: (response: any) => response.coupling.profileId,
                                      onHover: 'group-hover',
                                      title: this.translateService.instant(
                                          'INTERFACE|Edytuj łącznik'
                                      ),
                                  }
                                : {},
                        buttons:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? [
                                      {
                                          class: 'ico-move',
                                          title: this.translateService.instant(
                                              'INTERFACE|Edytuj łącznik'
                                          ),
                                          buttons: [
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  onClick: (response: any) => {
                                                      const coupling = core.fIdO(
                                                          conf.couplings,
                                                          response.coupling.id
                                                      );
                                                      if (coupling) {
                                                          this.equalDivisionService.equalDivision(
                                                              coupling,
                                                              conf,
                                                              'glazing'
                                                          );
                                                      }
                                                  },
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  onClick: (response: any) => {
                                                      const coupling = core.fIdO(
                                                          conf.couplings,
                                                          response.coupling.id
                                                      );
                                                      if (coupling) {
                                                          this.equalDivisionService.equalDivision(
                                                              coupling,
                                                              conf,
                                                              'axis'
                                                          );
                                                      }
                                                  },
                                              },
                                              {
                                                  class: 'ico-move-submit',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Edytuj łącznik'
                                                  ),
                                                  condition: (response: any) =>
                                                      response.coupling.profileId,
                                                  onClick: (response: any) =>
                                                      this.profilesModalService.changeProfile(
                                                          conf,
                                                          defaultConf,
                                                          'coupling',
                                                          { coupling: response.coupling }
                                                      ),
                                              },
                                              {
                                                  class: 'ico-move',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Przesuń łącznik'
                                                  ),
                                                  onMove: (response: any) => {
                                                      const coupling = conf.couplings.find(
                                                          c => c.id === response.coupling.id
                                                      );
                                                      if (coupling) {
                                                          this.framesService.moveCoupling(
                                                              coupling,
                                                              response.position,
                                                              conf
                                                          );
                                                      }
                                                  },
                                              },
                                          ],
                                      },
                                  ]
                                : [],
                        minPosition: this.config().IccConfig.Configurators.minWidth || 0,
                        maxPosition: null,
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private sashesTypesService: SashesTypesService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private framesService: BrowserFramesService,
        private extensionsService: ExtensionsService,
        private equalDivisionService: EqualDivisionService,
        private resizeSashService: ResizeSashService,
        private alignmentsService: AlignmentsService,
        private removeSashService: RemoveSashService,
        private splitSashService: SplitSashService,
        private windowFacade: WindowFacade
    ) {
        super();
    }

    ngOnInit() {
        this.changeIndividualProfiles = this.config().IccConfig.Configurators.changeIndividualProfiles;
        this.drawButtons.update();
    }

    canDivVertical(sash: ActiveSash, conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && conf.Sashes.length == 2)
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_vertical)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivHorizontal(sash: ActiveSash, conf: WindowActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && conf.Sashes.length == 2)
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_horizontal)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type === 'deco_panels')
                    || conf.System.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivIntVertical(sash: ActiveSash, conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_vertical)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivIntHorizontal(
        sash: ActiveSash,
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_horizontal)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }
}
