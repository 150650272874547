import { IIccConfig } from '@icc/config';
import  { core } from './Core'

export class ParserService {
    static translate(s: string): string {
        return s.split('|')[1];
    }

    static getChangedName(o, extra = null, translate = ParserService.translate) {
        return o
            ? o.changed
                ? o.name
                  + (extra ? ' ' + extra : '')
                  + ' '
                  + translate('OFFER|Zmienione z')
                  + ' '
                  + o.currentConfigName
                : o.name + (extra ? ' ' + extra : '')
            : '';
    }

    static dimensionUnit(s) {
        return Math.round(s) + ' mm';
    }

    static pushChild(obj, name, value, price = null, children = [], editable: 'description' | 'attachments' | null = null) {
        const pushed = { name, value, $$price: price, $$children: children, $$editable: editable };
        obj['$$children'].push(pushed);
        return pushed;
    }

    static parseConfiguration(
        conf,
        IccConfig: IIccConfig,
        pdf,
        translate = ParserService.translate,
        dimensionUnit = ParserService.dimensionUnit,
        editable = false,
        market_time_limits_offer_positions = false,
        market_time_limits_pdf_positions = false,
        client_split_transport_cost = false,
        split_transport_cost = false,
        transport_from_producent = false,
        order = false
    ) {
        const offersView = {
            pdf,
        };
        const details = { $$children: [] };

        if (conf.showDetails) {

            ParserService.setConstruction(conf, IccConfig, details, translate, dimensionUnit);

            ParserService.setFilling(conf, details, translate, IccConfig, dimensionUnit, offersView);

            ParserService.setFitting(conf, IccConfig, details, translate, dimensionUnit);

            ParserService.setAccessories(conf, details, translate, dimensionUnit, IccConfig);

            if (
                !IccConfig.Offer.groupsOfPositions
                && conf.sashes
                && conf.sashes?.has
                && !IccConfig.Offer.colorInPositionName
                && !IccConfig.Offer.colorBasicInfo
            ) {
                ParserService.setWindowColor(details, translate, conf);
            }

            if (conf.type === 'door') {
                ParserService.setDoorPortal(details, translate, conf)
            }

            if (conf.type === 'pleated_blind') {
                ParserService.setPleatedBlind(details, translate, conf);
            }

            if (conf.type === 'roller_shutter' || conf.type === 'external_blind' || conf.rollerShutter) {
                ParserService.setRollerShutter(details, translate, conf, dimensionUnit, IccConfig);
            }

            if (conf.type === 'mosquito') {
                ParserService.getMosquito(details, translate, conf, dimensionUnit);
            }

            if (conf.type === 'garage_door') {
                ParserService.getGarageDoor(details, translate, conf, dimensionUnit);
            }

            if (conf.type === 'winter_garden') {
                ParserService.getWinterGarden(details, translate, conf, dimensionUnit);
            }

            if (conf.type === 'awning') {
                ParserService.getAwning(details, translate, conf, dimensionUnit);
                ParserService.getAwningDrive(details, translate, conf);
            }

            if (conf.fillings && (!conf.sashes || !conf.sashes?.has)) {
                ParserService.setComplementaryFilling(details, translate, conf, dimensionUnit);
            }

            if (
                IccConfig.Configurators.timeLimits
                && conf.timeLimit
                && ((!offersView.pdf && market_time_limits_offer_positions)
                    || (offersView.pdf && market_time_limits_pdf_positions))
            ) {
                ParserService.pushChild(
                    details,
                    translate(`OFFER|Termin realizacji`),
                    conf.timeLimit + ' ' + translate(`OFFER|dni`)
                );
            }

            if (
                transport_from_producent
                && split_transport_cost
                && conf.transportCost > 0
                && conf.prices?.show
            ) {
                ParserService.pushChild(
                    details,
                    translate(`OFFER|Koszt transportu`),
                    null,
                    conf.transportCost
                );
            }

            if (
                !order
                && client_split_transport_cost
                && conf.clientTransportCost > 0
                && conf.prices?.show
            ) {
                ParserService.pushChild(
                    details,
                    translate(`OFFER|Koszt transportu do klienta`),
                    null,
                    conf.clientTransportCost
                );
            }

            if (conf.description && (pdf || !(editable && (conf.standard || !order)))) {
                ParserService.pushChild(details, translate(`INTERFACE|Opis`), conf.description);
            }

            if (!pdf && editable && (conf.standard || !order)) {
                ParserService.pushChild(details, translate(`INTERFACE|Opis`), conf.description, null, [], 'description');
            }
        }

        if (conf.attributes?.length) {
            ParserService.getAttributesTree(details, translate, conf, dimensionUnit);
        }

        return details;
    }

    private static setWindowColor(details: { $$children: any[]; }, translate: (s: string) => string, conf: any) {
        const color = ParserService.pushChild(details, conf.system?.doorType ? translate('COLOR|Kolor ościeżnicy') : translate('COLOR|Kolor'), null);

        if (conf.colors?.frame?.outer && conf.colors?.frame?.outer?.name) {
            ParserService.pushChild(
                color,
                conf.system?.doorType ? translate('COLOR|Kolor zewnętrzny ościeżnicy') : translate('COLOR|Kolor zewnętrzny'),
                ParserService.getChangedName(
                    conf.colors?.frame?.outer,
                    conf.colors?.frame?.outer?.code
                )
            );
        }

        if ((!conf.colors?.frame?.outer || !conf.colors?.frame?.outer?.name)
            && conf.colors?.frame?.core
            && conf.colors?.frame?.core?.name) {
            ParserService.pushChild(
                color,
                conf.system?.doorType ? translate('COLOR|Kolor zewnętrzny ościeżnicy') : translate('COLOR|Kolor zewnętrzny'),
                ParserService.getChangedName(
                    conf.colors?.frame?.core,
                    conf.colors?.frame?.core?.code
                )
            );
        }

        if (conf.colors?.frame?.inner && conf.colors?.frame?.inner?.name) {
            ParserService.pushChild(
                color,
                conf.system?.doorType ? translate('COLOR|Kolor wewnętrzny ościeżnicy') : translate('COLOR|Kolor wewnętrzny'),
                ParserService.getChangedName(
                    conf.colors?.frame?.inner,
                    conf.colors?.frame?.inner?.code
                )
            );
        }

        if ((!conf.colors?.frame?.inner || !conf.colors?.frame?.inner?.name)
            && conf.colors?.frame?.core
            && conf.colors?.frame?.core?.name) {
            ParserService.pushChild(
                color,
                conf.system?.doorType ? translate('COLOR|Kolor wewnętrzny ościeżnicy') : translate('COLOR|Kolor wewnętrzny'),
                ParserService.getChangedName(
                    conf.colors?.frame?.core,
                    conf.colors?.frame?.core?.code
                )
            );
        }

        if (conf.alushell?.has && conf.colors?.frame?.alushell) {
            if (conf.colors?.frame?.alushell?.code) {
                ParserService.pushChild(
                    color,
                    translate('WINDOW|Kolor nakładki'),
                    ParserService.getChangedName(
                        conf.colors?.frame?.alushell,
                        conf.colors?.frame?.alushell?.code
                    )
                );
            }
            else {
                ParserService.pushChild(
                    color,
                    translate('WINDOW|Kolor nakładki'),
                    ParserService.getChangedName(conf.colors?.frame?.alushell)
                );
            }
        }

        if (conf.lowThreshold && conf.lowThreshold?.color) {
            ParserService.pushChild(
                color,
                translate('WINDOW|Kolor progu'),
                ParserService.getChangedName(
                    conf.lowThreshold?.color,
                    conf.lowThreshold?.color?.code
                )
            );
        }

        if (conf.lippingColor) {
            ParserService.pushChild(
                color,
                conf.innerLippingColor ? translate('WINDOW|Kolor zewnętrzny listwy mocującej'): translate('WINDOW|Kolor listwy mocującej'),
                ParserService.getChangedName(
                    conf.lippingColor,
                    conf.lippingColor?.code
                )
            );
        }

        if (conf.innerLippingColor) {
            ParserService.pushChild(
                color,
                translate('WINDOW|Kolor wewnętrzny listwy mocującej'),
                ParserService.getChangedName(
                    conf.innerLippingColor,
                    conf.innerLippingColor?.code
                )
            );
        }
    }

    private static setComplementaryFilling(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const goods_fillings = ParserService.pushChild(
            details,
            translate(`GOODS|Szyby`),
            conf.description,
            conf.fillings?.price && conf.prices?.show ? conf.filling?.price : null
        );

        conf.fillings?.elems?.forEach((filling, index) => {
            const this_filling = ParserService.pushChild(
                goods_fillings,
                ParserService.getChangedName(filling.filling),
                filling.price && conf.prices?.show ? conf.prices?.show : null
            );

            ParserService.pushChild(this_filling, translate('INTERFACE|Ilość'), filling.count);
            ParserService.pushChild(
                this_filling,
                translate('DIMENSION|Wymiar'),
                dimensionUnit(filling.width) + ' × ' + dimensionUnit(filling.height)
            );

            if (filling.glazingSpacer?.name) {
                ParserService.pushChild(
                    this_filling,
                    translate('GOODS|Ciepła ramka'),
                    ParserService.getChangedName(filling.glazingSpacer)
                );
            };

            if (filling.filling?.comment) {
                ParserService.pushChild(
                    this_filling,
                    translate('INTERFACE|Komentarz'),
                    filling.filling?.comment
                );
            };
        });
    }

    private static getAwningDrive(details: { $$children: any[]; }, translate: (s: string) => string, conf: any) {
        const drive = ParserService.pushChild(details, translate(`OFFER|Napęd`), null);

        if (conf.drive?.type === 'crank') {
            ParserService.pushChild(drive, translate(`OFFER|Napęd`), translate(`OFFER|Korba`));
        }
        else if (conf.drive?.type === 'motor') {
            ParserService.pushChild(
                drive,
                translate(`OFFER|Napęd`),
                translate(`OFFER|Silnik elektryczny`)
            );
        }

        if (conf.drive?.side === 'L') {
            ParserService.pushChild(
                drive,
                translate(`OFFER|Strona pracy`),
                translate(`OFFER|od lewej`)
            );
        }
        else if (conf.drive?.side === 'R') {
            ParserService.pushChild(
                drive,
                translate(`OFFER|Strona pracy`),
                translate(`OFFER|od prawej`)
            );
        }

        if (conf.drive?.type === 'crank') {
            ParserService.pushChild(
                drive,
                translate('OFFER|Długość korby'),
                ParserService.getChangedName(conf.drive)
            );
        }
        else if (conf.drive?.type === 'motor') {
            ParserService.pushChild(
                drive,
                translate('OFFER|Rodzaj wyłącznika'),
                ParserService.getChangedName(conf.drive)
            );
        }

        if (conf.drive?.type === 'motor' && conf.drive?.options?.length > 0) {
            const driving = ParserService.pushChild(drive, translate(`OFFER|Sterowanie`), null);

            conf.drive?.options?.forEach((option, index) => {
                ParserService.pushChild(driving, null, ParserService.getChangedName(option));
            });
        }
    }

    private static getAwning(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const awning = ParserService.pushChild(
            details,
            translate(`CONFIGURATOR|Szczegóły konstrukcji`),
            null
        );

        ParserService.pushChild(
            awning,
            translate(`CONFIGURATOR|Linia`),
            ParserService.getChangedName(conf.system)
        );

        const dimensions = ParserService.pushChild(
            awning,
            translate(`DIMENSION|Wymiary`),
            null
        );

        ParserService.pushChild(
            dimensions,
            translate(`OFFER|Wysięg`),
            dimensionUnit(conf.outreach)
        );
        ParserService.pushChild(
            dimensions,
            translate(`DIMENSION|Szerokość`),
            dimensionUnit(conf.width)
        );

        ParserService.pushChild(
            awning,
            translate(`COLOR|Kolor`),
            ParserService.getChangedName(conf.color)
        );

        ParserService.pushChild(
            awning,
            translate(`OFFER|Poszycie`),
            ParserService.getChangedName(
                conf.fabric,
                ', ' + ParserService.getChangedName(conf.fabric?.style)
            )
        );

        ParserService.pushChild(
            awning,
            translate(`OFFER|Falbana`),
            ParserService.getChangedName(conf.valance)
        );

        if (conf.valance?.inscription) {
            ParserService.pushChild(
                awning,
                translate(`OFFER|Napis na falbanie`),
                conf.valance?.inscription
            );
        }

        if (conf.valance?.rollUp) {
            ParserService.pushChild(awning, null, translate(`OFFER|Falbana zwijana`));
        }

        ParserService.pushChild(
            awning,
            translate(`OFFER|Montaż`),
            ParserService.getChangedName(conf.mounting)
        );
    }

    private static getWinterGarden(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const winter_garden = ParserService.pushChild(
            details,
            translate(`CONFIGURATOR|Ogród zimowy`),
            null
        );

        const dimensions = ParserService.pushChild(
            winter_garden,
            translate(`WINTERGARDEN|Wymiary konstrukcji`),
            null
        );

        for (const label in conf.dimensions) {
            if (conf.dimensions?.hasOwnProperty(label)) {
                const dimension = conf.dimensions?.[label];

                ParserService.pushChild(dimensions, label, dimensionUnit(dimension));
            }
        }

        ParserService.pushChild(
            winter_garden,
            translate(`WINTERGARDEN|Rodzaj ogrodu`),
            ParserService.getChangedName(conf.garden)
        );
        ParserService.pushChild(
            winter_garden,
            translate(`WINTERGARDEN|Ściana z klamką`),
            conf.handleFacet
        );
    }

    private static getGarageDoor(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const garageDoor = ParserService.pushChild(
            details,
            translate(`CONFIGURATOR|Brama garażowa`),
            null
        );

        // Linia
        ParserService.pushChild(
            garageDoor,
            translate(`CONFIGURATOR|Linia`),
            ParserService.getChangedName(conf.system)
        );

        // Wymiary
        const garageDoorDimensions = ParserService.pushChild(garageDoor, translate(`DIMENSION|Wymiary`), conf.dimensions?.width + ' x ' + conf.dimensions?.height);
        ParserService.pushChild(garageDoorDimensions, translate(`GARAGEDOOR|Wysokość nadproża`), conf.dimensions?.lintelHeight);

        if (conf.dimensions?.leftJambWidth
            && conf.dimensions?.rightJambWidth) {
            ParserService.pushChild(garageDoorDimensions, translate(`GARAGEDOOR|Szerokość lewego węgarka`), conf.dimensions?.leftJambWidth);
            ParserService.pushChild(garageDoorDimensions, translate(`GARAGEDOOR|Szerokość prawego węgarka`), conf.dimensions?.rightJambWidth);
        }

        // Mechanizm
        const garageDoorMechanisms = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Mechanizm`), '');
        if (conf.mechanism && conf.mechanism?.name) {
            ParserService.pushChild(
                garageDoorMechanisms,
                translate(`GARAGEDOOR|Rodzaj sprężyn`),
                ParserService.getChangedName(conf.mechanism)
            );
        }
        if (conf.guide && conf.guide?.name) {
            ParserService.pushChild(
                garageDoorMechanisms,
                translate(`GARAGEDOOR|Prowadzenie`),
                ParserService.getChangedName(conf.guide)
            );
        }

        // Panele
        const garageDoorPanels = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Panele`), '');
        if (conf.panel && conf.panel?.name) {
            ParserService.pushChild(
                garageDoorPanels,
                translate(`GARAGEDOOR|Układ przetłoczeń paneli`),
                ParserService.getChangedName(conf.panel)
            );
        }

        if (conf.panelStructure && conf.panelStructure?.name) {
            ParserService.pushChild(
                garageDoorPanels,
                translate(`GARAGEDOOR|Zewnętrzna struktura powierzchni paneli`),
                ParserService.getChangedName(conf.panelStructure)
            );
        }

        if (conf.panelInternalStructure && conf.panelInternalStructure?.name) {
            ParserService.pushChild(
                garageDoorPanels,
                translate(`GARAGEDOOR|Wewnętrzna struktura powierzchni paneli`),
                ParserService.getChangedName(conf.panelInternalStructure)
            );
        }

        if (conf.system?.plinth_panel) {
            if (conf.plinthPanel && conf.plinthPanel?.name) {
                ParserService.pushChild(
                    garageDoorPanels,
                    translate(`GARAGEDOOR|Układ przetłoczeń panela cokołu`),
                    ParserService.getChangedName(conf.plinthPanel)
                );
            }

            if (conf.plinthPanelStructure && conf.plinthPanelStructure?.name) {
                ParserService.pushChild(
                    garageDoorPanels,
                    translate(`GARAGEDOOR|Struktura powierzchni panela cokołu`),
                    ParserService.getChangedName(conf.plinthPanelStructure)
                );
            }
        }

        // Kolory
        const garageDoorColors = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Kolory`), '');
        if (conf.panelColor && conf.panelColor?.name) {
            ParserService.pushChild(
                garageDoorColors,
                translate(`GARAGEDOOR|Kolor zewnętrzny paneli`),
                ParserService.getChangedName(conf.panelColor)
            );
        }
        if (conf.panelInternalColor && conf.panelInternalColor?.name) {
            ParserService.pushChild(
                garageDoorColors,
                translate(`GARAGEDOOR|Kolor wewnętrzny paneli`),
                ParserService.getChangedName(conf.panelInternalColor)
            );
        }

        // Sterowanie
        const garageDoorDrives = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Sterowanie`), '');
        if (conf.drive && conf.drive?.name) {
            ParserService.pushChild(
                garageDoorDrives,
                translate(`GARAGEDOOR|Typ sterowania`),
                conf.driveType
            );
            ParserService.pushChild(
                garageDoorDrives,
                translate(`GARAGEDOOR|Sterowanie`),
                ParserService.getChangedName(conf.drive)
            );
            ParserService.pushChild(
                garageDoorDrives,
                translate(`GARAGEDOOR|Strona sterowania`),
                conf.driveSide
            );
        }

        // Furtka
        if (conf.wicketDoor && conf.wicketDoor?.name) {
            const garageDoorWicketDoor = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Furtka`), ParserService.getChangedName(conf.wicketDoor));

            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Szerokość`),
                dimensionUnit(conf.wicketDoor?.width)
            );

            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Wysokość`),
                dimensionUnit(conf.wicketDoor?.height)
            );

            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Wysokość progu`),
                dimensionUnit(conf.wicketDoor?.threshold_height)
            );


            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Strona otwierania`),
                conf.wicketDoorDirection
            );

            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Kolor profili ramy`),
                ParserService.getChangedName(conf.wicketDoorFrameProfilesColor)
            );

            ParserService.pushChild(
                garageDoorWicketDoor,
                translate(`GARAGEDOOR|Kolor klamki`),
                ParserService.getChangedName(conf.wicketDoorHandleColor)
            );
        }

        // Wypełnienia paneli
        if (conf.panelsData && conf.panelsData?.panels) {
            const garageDoorInserts = ParserService.pushChild(garageDoor, translate(`GARAGEDOOR|Wypełnienia paneli`), '');
            conf.panelsData?.panels?.forEach(p => {
                if (p.panelInserts && p.panelInserts.length > 0) {
                    const garageDoorPanel = ParserService.pushChild(
                        garageDoorInserts,
                        translate(`GARAGEDOOR|Panel`) + ' ' + p.index,
                        (p.panelInsertsType !== 'glazing' ? p.panelInserts.length + 'x ' : '') + ParserService.getChangedName(p.panelInserts?.[0])
                    );

                    if (p.panelInsertsType === 'glazing') {
                        ParserService.pushChild(
                            garageDoorPanel,
                            translate(`GARAGEDOOR|Ilość impostów`),
                            p.panelInserts?.[0]?.impostAmount || '0'
                        );
                    }
                    else {
                        if (p.panelInserts?.[0]?.shape === 'circle') {
                            ParserService.pushChild(
                                garageDoorPanel,
                                translate(`GARAGEDOOR|Średnica`),
                                p.panelInserts?.[0]?.diameter
                            );
                        }
                        else {
                            ParserService.pushChild(
                                garageDoorPanel,
                                translate(`GARAGEDOOR|Wysokość`),
                                p.panelInserts?.[0]?.height
                            );

                            ParserService.pushChild(
                                garageDoorPanel,
                                translate(`GARAGEDOOR|Szerokość`),
                                p.panelInserts?.[0]?.width
                            );
                        }

                        ParserService.pushChild(
                            garageDoorPanel,
                            translate(`GARAGEDOOR|Szerokość ramki`),
                            p.panelInserts?.[0]?.frameWidth
                        );
                    }

                }
            });
        }
    }

    private static getMosquito(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const mosquito = ParserService.pushChild(
            details,
            translate(`CONFIGURATOR|Siatka`),
            null,
            null
        );

        ParserService.pushChild(
            mosquito,
            translate('CONFIGURATOR|Linia'),
            ParserService.getChangedName(conf.system)
        );

        ParserService.pushChild(mosquito, translate('DIMENSION|Wymiary'), conf.size);
        if (conf.variant?.hasSashDimensions) {
            ParserService.pushChild(
                mosquito,
                translate('MOSQUITO|Wymiary skrzydła'),
                conf.sashSize
            );
        }

        const colors = ParserService.pushChild(
            mosquito,
            translate('COLOR|Kolory'),
            null,
            conf.colors?.price && conf.prices?.show ? conf.colors?.price : null
        );

        ParserService.pushChild(
            colors,
            translate(`MOSQUITO|Ramka`),
            ParserService.getChangedName(conf.colors?.frame)
        );

        ParserService.pushChild(
            colors,
            translate(`MOSQUITO|Siatka`),
            ParserService.getChangedName(conf.colors?.net)
        );

        if (conf.variant?.extraColor) {
            ParserService.pushChild(
                colors,
                translate(`MOSQUITO|Elementy z tworzywa`),
                ParserService.getChangedName(conf.colors?.panel)
            );
        }

        if (conf.variant) {
            ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|Typ siatki`),
                ParserService.getChangedName(conf.variant)
            );
        }

        if (conf.netType) {
            ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|Rodzaj siatki`),
                ParserService.getChangedName(conf.netType)
            );
        }

        if (conf.variant?.hasDirection) {
            const direction = ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|Strona zawiasów`),
                null
            );

            if (conf.direction === 'L') {
                direction['value'] = translate(`INTERFACE|Lewa`);
            }
            if (conf.direction === 'R') {
                direction['value'] = translate(`INTERFACE|Prawa`);
            }
        }

        if (conf.montage && conf.montage?.name) {
            ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|System montażu`),
                ParserService.getChangedName(conf.montage) || '-'
            );
        }

        if (conf.variant?.profilePosition
            && (
                conf.profilePositions?.up
                || conf.profilePositions?.down
                || conf.profilePositions?.left
                || conf.profilePositions?.right
            )) {
            const profiles = ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|Profil dodatkowy`),
                ''
            );

            if (conf.profilePositions?.up)
                profiles['value'] += translate(`INTERFACE|Góra`) + ', ';
            if (conf.profilePositions?.down)
                profiles['value'] += translate(`INTERFACE|Dół'`) + ', ';
            if (conf.profilePositions?.left)
                profiles['value'] += translate(`INTERFACE|Lewa`) + ', ';
            if (conf.profilePositions?.right)
                profiles['value'] += translate(`INTERFACE|Prawa`) + ', ';
        }

        if ((conf.windowLine && conf.windowLine?.id) || conf.holdersWidth) {
            const profiles = ParserService.pushChild(
                mosquito,
                translate(`WINDOW|Rozmiar uchwytów`),
                ''
            );
            if (conf.windowLine?.id)
                profiles['value'] += ParserService.getChangedName(conf.windowLine) + ' ';
            if (conf.holdersWidth)
                profiles['value'] += dimensionUnit(conf.holdersWidth);
        }

        if (conf.muntinPosition?.type === 'custom') {
            ParserService.pushChild(
                mosquito,
                translate(`WINDOW|Pozycja szprosa`),
                dimensionUnit(conf.muntinPosition?.position)
            );
        }

        if (conf.variant?.hasThickSeal && conf.hasThickSeal) {
            ParserService.pushChild(
                mosquito,
                translate(`MOSQUITO|Grubsza uszczelka`),
                dimensionUnit(conf.sealThickness)
            );
        }
    }

    private static setRollerShutter(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string, IccConfig: IIccConfig) {
        const rollerShutter = ParserService.pushChild(
            details,
            conf.type === 'roller_shutter' ? translate('ROLLERSHUTTER|Roleta') : translate('EXTERNALBLIND|Żaluzja zewnętrzna'),
            conf.rollerShutter?.price?.all && conf.prices?.show
                ? conf.rollerShutter?.price?.all
                : null
        );

        ParserService.pushChild(
            rollerShutter,
            translate(`ROLLERSHUTTER|System`),
            ParserService.getChangedName(conf.rollerShutter?.system)
        );

        ParserService.pushChild(
            rollerShutter,
            translate(`ROLLERSHUTTER|Typ pancerza`),
            ParserService.getChangedName(conf.rollerShutter?.profile)
        );
        
        if (conf.rollerShutter?.price?.rollerSlatDivision && conf.prices?.show)
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Dopłata za podział pancerza`),
                null,
                conf.rollerShutter?.price?.rollerSlatDivision
            );

        ParserService.pushChild(
            rollerShutter,
            translate(`ROLLERSHUTTER|Wysokość skrzynki`),
            dimensionUnit(conf.rollerShutter?.boxHeight),
            (conf.rollerShutter?.price?.changedSize || conf.rollerShutter?.price?.boxSize)
                && conf.prices?.show
                ? conf.rollerShutter?.price?.changedSize + conf.rollerShutter?.price?.boxSize
                : null
        );

        if (conf.rollerShutter?.shutters?.length) {
            const dimensions = ParserService.pushChild(
                rollerShutter,
                translate(`DIMENSION|Wymiary`),
                null,
                conf.rollerShutter?.price?.boxes && conf.prices?.show
                    ? conf.rollerShutter?.price?.boxes
                    : null
            );

            if (conf.rollerShutter?.shutters?.length === 1
                && !IccConfig.Configurators.price.shutterHeightWithBox) {
                dimensions['value'] =
                    dimensionUnit(conf.rollerShutter?.shutters?.[0]?.width)
                    + ' x '
                    + dimensionUnit(conf.rollerShutter?.shutters?.[0]?.height);
            }

            if (conf.rollerShutter?.shutters?.length === 1
                && IccConfig.Configurators.price.shutterHeightWithBox) {
                dimensions['value'] =
                    dimensionUnit(conf.rollerShutter?.shutters?.[0]?.width)
                    + ' x '
                    + dimensionUnit(
                        conf.rollerShutter?.shutters?.[0]?.height + conf.rollerShutter?.boxHeight
                    );
            }

            if (conf.rollerShutter?.shutters?.length > 1) {
                conf.rollerShutter?.shutters?.forEach((shutter, index) => {
                    if (!IccConfig.Configurators.price.shutterHeightWithBox) {
                        ParserService.pushChild(
                            dimensions,
                            null,
                            dimensionUnit(shutter.width)
                            + ' x '
                            + dimensionUnit(shutter.height),
                            null
                        );
                    }

                    if (IccConfig.Configurators.price.shutterHeightWithBox) {
                        ParserService.pushChild(
                            dimensions,
                            null,
                            dimensionUnit(shutter.width)
                            + ' x '
                            + dimensionUnit(shutter.height + conf.rollerShutter?.boxHeight),
                            null
                        );
                    }
                });
            }
        }

        const colors = ParserService.pushChild(
            rollerShutter,
            translate(`COLOR|Kolory`),
            null,
            conf.rollerShutter?.colors?.price && conf.prices?.show
                ? conf.rollerShutter?.colors?.price
                : null
        );

        if (conf.rollerShutter?.type?.type === 'N'
            && (conf.type === 'roller_shutter'
                || IccConfig.Configurators.extendedRollerInWindow !== 'none'
                || IccConfig.Offer.showAllRollerShutterColorsInWindowInOfferSummary)
            && !conf.rollerShutter?.inInsulation) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Skrzynka od wewnątrz'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.boxInner)
            );
        }

        if (conf.rollerShutter?.type?.type === 'N'
            && (conf.type === 'roller_shutter'
                || IccConfig.Configurators.extendedRollerInWindow !== 'none'
                || IccConfig.Offer.showAllRollerShutterColorsInWindowInOfferSummary)
            && !conf.rollerShutter?.inInsulation) {
            if (!IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                ParserService.pushChild(
                    colors,
                    translate('ROLLERSHUTTER|Skrzynka i prowadnice od zewnątrz'),
                    ParserService.getChangedName(conf.rollerShutter?.colors?.boxGuideOuter)
                );
            }
            if (IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                ParserService.pushChild(
                    colors,
                    translate('ROLLERSHUTTER|Skrzynka od zewnątrz'),
                    ParserService.getChangedName(conf.rollerShutter?.colors?.boxGuideOuter)
                );
            }
        }

        ParserService.pushChild(
            colors,
            translate('ROLLERSHUTTER|Pancerz'),
            ParserService.getChangedName(conf.rollerShutter?.colors?.profile)
        );

        if (conf.type === 'roller_shutter'
            && (conf.rollerShutter?.type?.type !== 'I'
                || IccConfig.Configurators.roller_shutter.endSlatColor)) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Listwa końcowa'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.endslat)
            );
        }

        if (conf.rollerShutter?.type?.type !== 'N'
            || IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Prowadnice'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.guideRail)
            );
        }

        if (conf.rollerShutter?.type?.type === 'Z' || conf.type === 'external_blind' && conf.rollerShutter?.system?.hasBox) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Skrzynka'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.box)
            );
        }

        if (conf.type === 'roller_shutter'
            && (conf.rollerShutter?.type?.type === 'I'
                || (conf.rollerShutter?.type?.type === 'N'
                    && IccConfig.Configurators.roller_shutter.additionalColors
                    && conf.rollerShutter?.system?.availableRevisionColor)
                || (conf.rollerShutter?.type?.type === 'Z'
                    && IccConfig.Configurators.roller_shutter.revisionColorInZ
                    && conf.rollerShutter?.system?.availableRevisionColor))) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Klapa rewizyjna'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.revision)
            );
        }

        if (conf.type === 'roller_shutter'
            && conf.rollerShutter?.type?.type === 'N'
            && IccConfig.Configurators.roller_shutter.additionalColors
            && IccConfig.Configurators.roller_shutter.boxSideSurfaceColor) {
            ParserService.pushChild(
                colors,
                translate('ROLLERSHUTTER|Boczki skrzynki'),
                ParserService.getChangedName(conf.rollerShutter?.colors?.boxSideSurface)
            );
        }

        const respect_hidden = false;

        if (respect_hidden) {
            const guides = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Prowadnice`),
                null
            );

            if (conf.rollerShutter?.guideType === 'alu')
                guides['value'] = translate('ROLLERSHUTTER|Aluminiowe');
            if (conf.rollerShutter?.guideType === 'pvc')
                guides['value'] = translate('ROLLERSHUTTER|PVC');
        }

        if (!IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
            const drive_side = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Strona sterowania`),
                null
            );

            if (conf.rollerShutter?.driveSide === 'L') {
                drive_side['value'] = translate(`INTERFACE|Lewa`);
            }
            else if (conf.rollerShutter?.driveSide === 'R') {
                drive_side['value'] = translate(`INTERFACE|Prawa`);
            }
        }

        if (IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
            if (conf.rollerShutter?.shutters?.length === 1) {
                const drive_side = ParserService.pushChild(
                    rollerShutter,
                    translate(`ROLLERSHUTTER|Strona sterowania`),
                    null
                );
                if (conf.rollerShutter?.shutters?.[0]?.controlSide === 'L'
                    && !conf.rollerShutter?.shutters?.[0]?.commonRail) {
                    drive_side['value'] = translate(`INTERFACE|Lewa`);
                }
                if (conf.rollerShutter?.shutters?.[0]?.controlSide !== 'L'
                    && !conf.rollerShutter?.shutters?.[0]?.commonRail) {
                    drive_side['value'] = translate(`INTERFACE|Prawa`);
                }
            }
            else if (conf.rollerShutter?.shutters?.length > 1) {
                const driving = ParserService.pushChild(
                    rollerShutter,
                    translate(`ROLLERSHUTTER|Prowadzenie`),
                    null
                );

                for (const key in conf.rollerShutter?.shutters) {
                    if (conf.rollerShutter?.shutters?.hasOwnProperty(key)) {
                        const shutter = conf.rollerShutter?.shutters?.[key];
                        if (shutter.controlSide === 'L' && !shutter.commonRail) {
                            ParserService.pushChild(
                                driving,
                                translate(`ROLLERSHUTTER|Strona sterowania`),
                                translate(`INTERFACE|Lewa`)
                            );
                        }
                        if (shutter.controlSide !== 'L' && !shutter.commonRail) {
                            ParserService.pushChild(
                                driving,
                                translate(`ROLLERSHUTTER|Strona sterowania`),
                                translate(`INTERFACE|Prawa`)
                            );
                        }
                        if (shutter.commonRail) {
                            ParserService.pushChild(
                                driving,
                                null,
                                translate(`ROLLERSHUTTER|Wspólna prowadnica`)
                            );
                        }
                    }
                }
            }
        }

        if (IccConfig.Configurators.roller_shutter.guides
            && conf.rollerShutter?.guideRails?.length) {
            if (conf.rollerShutter?.guideRails?.length) {
                const selected_guides = ParserService.pushChild(
                    rollerShutter,
                    translate(`ROLLERSHUTTER|Wybrane prowadnice`),
                    null,
                    conf.rollerShutter?.price?.guides
                        && conf.prices?.show
                        && conf.rollerShutter?.guideRails?.length
                        ? conf.rollerShutter?.price?.guides
                        : null
                );

                for (const key in conf.rollerShutter?.guideRailsArr) {
                    if (conf.rollerShutter?.guideRailsArr?.hasOwnProperty(key)) {
                        const guide = conf.rollerShutter?.guideRailsArr?.[key];

                        ParserService.pushChild(
                            selected_guides,
                            ParserService.getChangedName(guide)
                            + ' '
                            + guide.shutterNumber
                            + ' '
                            + guide.side,
                            guide.profile
                                ? guide.profile
                                : translate('ROLLERSHUTTER|Brak profilu')
                        );
                    }
                }
            }
        }

        if (IccConfig.Configurators.roller_shutter.slats && conf.rollerShutter?.slats?.length) {
            const slats = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Wybrane listwy końcowe`),
                null,
                conf.rollerShutter?.price?.slats && conf.prices?.show
                    ? conf.rollerShutter?.price?.slats
                    : null
            );

            for (const key in conf.rollerShutter?.slats) {
                if (conf.rollerShutter?.slats?.hasOwnProperty(key)) {
                    const slat = conf.rollerShutter?.slats?.[key];

                    ParserService.pushChild(slats, null, ParserService.getChangedName(slat));
                }
            }
        }

        ParserService.pushChild(
            rollerShutter,
            translate(`ROLLERSHUTTER|Sterowanie`),
            ParserService.getChangedName(conf.rollerShutter?.drive),
            conf.rollerShutter?.drive?.price && conf.prices?.show
                ? conf.rollerShutter?.drive?.price
                : null
        );
        
        if (conf.rollerShutter?.drive?.driveEngine?.length>1){
            const engineName = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Nazwa silnika`),
                null,
            );
            conf.rollerShutter?.drive?.driveEngine.forEach((engine,index)=>{
                if (engine?.name){
                    ParserService.pushChild(
                        engineName,
                        translate('OFFER|Kwatera') + ' ' + ['A', 'B', 'C'][index],
                        engine?.name,
                    );
                }
            })
        }
        if (conf.rollerShutter?.drive?.driveEngine?.length===1 && conf.rollerShutter?.drive?.driveEngine[0]?.name){
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Nazwa silnika`),
                conf.rollerShutter?.drive?.driveEngine[0]?.name,
                null
            );
        }
        if (conf.rollerShutter?.drive?.type === 'manual'
            && conf.type === 'external_blind') {
            const crankParameters = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Korba`),
                null,
                conf.rollerShutter?.colors?.price && conf.prices?.show
                    ? conf.rollerShutter?.colors?.price
                    : null
            );

            ParserService.pushChild(
                crankParameters,
                translate(`ROLLERSHUTTER|Długość korby`),
                dimensionUnit(conf.rollerShutter?.crankLength),
                conf.rollerShutter?.price?.driveManual && conf.prices?.show
                    ? conf.rollerShutter?.price?.driveManual
                    : null
            );
            ParserService.pushChild(
                crankParameters,
                translate(`ROLLERSHUTTER|Przesunięcie korby`),
                dimensionUnit(conf.rollerShutter?.crankOffset),
                conf.rollerShutter?.price?.driveManual && conf.prices?.show
                    ? conf.rollerShutter?.price?.driveManual
                    : null
            );
            ParserService.pushChild(
                crankParameters,
                translate(`ROLLERSHUTTER|Nachylenie korby`),
                dimensionUnit(conf.rollerShutter?.crankSlope),
                conf.rollerShutter?.price?.driveManual && conf.prices?.show
                    ? conf.rollerShutter?.price?.driveManual
                    : null
            );
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Długość uchwytów`),
                dimensionUnit(conf.rollerShutter?.mountingLength)
            );
        }

        if (conf.rollerShutter?.drive?.type === 'manual'
            && conf.type === 'roller_shutter'
            && conf.rollerShutter?.driveAccessories?.length) {
            const drive_type = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Sterowanie ręczne`),
                ParserService.getChangedName(conf.rollerShutter?.driveAccessories?.[0]),
                conf.rollerShutter?.price?.driveManual && conf.prices?.show
                    ? conf.rollerShutter?.price?.driveManual
                    : null
            );

            if (conf.rollerShutter?.driveAccessories?.[0]?.color
                && ['white_retractor', 'brown_retractor'].indexOf(
                    conf.rollerShutter?.driveAccessories?.[0]?.color?.type
                ) !== -1) {
                drive_type['value'] += translate('COLOR|kolor') + ': ';
                if (conf.rollerShutter?.driveAccessories?.[0]?.color?.type === 'white_retractor') {
                    drive_type['value'] += translate('COLOR|Biały');
                }
                if (conf.rollerShutter?.driveAccessories?.[0]?.color?.type === 'brown_retractor') {
                    drive_type['value'] += translate('COLOR|Brązowy');
                }
            }
        }

        if (conf.rollerShutter?.drive?.type !== 'manual'){
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Sterowanie`),
                translate(`ROLLERSHUTTER|Sterowanie elektryczne`),
            );
        }

        if (conf.rollerShutter?.drive?.type !== 'manual'
            && conf.rollerShutter?.driveAccessories?.length) {
            const driveAccessories = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Dodatki do sterowania`),
                null,
                conf.rollerShutter?.price?.driveElems && conf.prices?.show
                    ? conf.rollerShutter?.price?.driveElems
                    : null
            );
            for (const key in conf.rollerShutter?.driveAccessories) {
                if (conf.rollerShutter?.driveAccessories?.hasOwnProperty(key)) {
                    const el = conf.rollerShutter?.driveAccessories?.[key];
                    ParserService.pushChild(
                        driveAccessories,
                        null,
                        ParserService.getChangedName(el)
                    );
                }
            }
        }

        if (conf.rollerShutter?.drive?.type !== 'manual'
            && IccConfig.Configurators.roller_shutter.overload) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Przeciążenie`),
                conf.rollerShutter?.hasOverride
                    ? translate(`ROLLERSHUTTER|Z przeciążeniem`)
                    : translate(`ROLLERSHUTTER|Z przeciążeniem`),
                conf.rollerShutter?.price?.overload && conf.prices?.show
                    ? conf.rollerShutter?.price?.overload
                    : null
            );
        }

        if (conf.type !== 'external_blind' && conf.rollerShutter?.drive?.type !== 'manual') {
            const driveOutputSide = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Wyjście sterowania`),
                null
            );

            switch (conf.rollerShutter?.driveOutputSide) {
                case 'rear-up':
                    driveOutputSide['value'] = translate(`ROLLERSHUTTER|Tył - góra`);
                    break;
                case 'rear-down':
                    driveOutputSide['value'] = translate(`ROLLERSHUTTER|Tył - dół`);
                    break;
                case 'front-up':
                    driveOutputSide['value'] = translate(`ROLLERSHUTTER|Przód - góra`);
                    break;
                case 'front-down':
                    driveOutputSide['value'] = translate(`ROLLERSHUTTER|Przód - dół`);
                    break;
                case 'side':
                    driveOutputSide['value'] = translate(`ROLLERSHUTTER|Bok`);
                    break;
            }
        }

        if (conf.rollerShutter?.openingLock) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Blokada otwarcia`),
                translate(`INTERFACE|Tak`),
            );
        }

        if (conf.type === 'roller_shutter' && conf.rollerShutter?.hanger && IccConfig.Configurators.roller_shutter.hangerLock) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Wieszaki`),
                ParserService.getChangedName(conf.rollerShutter?.hanger)
            );
        }

        if (conf.type === 'roller_shutter' && conf.rollerShutter?.schemaId) {
            const schema = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Schemat`),
                null
            );

            if (conf.rollerShutter?.schemaId === '1')
                schema['value'] = translate('ROLLERSHUTTER|Pojedyncza');
            if (conf.rollerShutter?.schemaId === '2a')
                schema['value'] = translate('ROLLERSHUTTER|Podwójna ze wspólną prowadnicą');
            if (conf.rollerShutter?.schemaId === '2b')
                schema['value'] = translate('ROLLERSHUTTER|Podwójna z osobnymi prowadnicami');
            if (conf.rollerShutter?.schemaId === '3')
                schema['value'] = translate('ROLLERSHUTTER|Potrójna z osobnymi prowadnicami');
        }
        if (conf.type === 'roller_shutter'
            && (IccConfig.Configurators.extendedRollerInWindow === 'full'
                || IccConfig.Configurators.roller_shutter.mosquito)
            && conf.rollerShutter?.shutters?.filter(e => e.withMosquito).length > 0) {
            const shutters = ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Siatka antyinsektowa:`),
                null
            );
            for (const lp in conf.rollerShutter?.shutters) {
                if (conf.rollerShutter?.shutters?.hasOwnProperty(lp)) {
                    ParserService.pushChild(
                        shutters,
                        translate('OFFER|Kwatera'),
                        ['A', 'B', 'C'][lp],
                    );
                    const element = conf.RollerShutter?.shutters?.[lp];

                }
            }
        }

        if (conf.type === 'roller_shutter'
            && IccConfig.Configurators.roller_shutter
            && conf.rollerShutter?.shutters?.filter(e => e.withMosquito).length > 0
            && conf.rollerShutter?.price?.mosquito
            && conf.prices?.show) {
            ParserService.pushChild(
                rollerShutter,
                null,
                translate(`ROLLERSHUTTER|Dopłata za siatkę antyinsektową`),
                conf.rollerShutter?.price?.mosquito && conf.prices?.show
                    ? conf.rollerShutter?.price?.mosquito
                    : null
            );
        }

        if (conf.type === 'roller_shutter' && IccConfig.Configurators.roller_shutter.railsBottomCut) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Zacięcie prowadnic`),
                conf.rollerShutter?.railsBottomCut === '0'
                    ? translate('ROLLERSHUTTER|Proste')
                    : translate('ROLLERSHUTTER|Skośne')
                    + ' - '
                    + conf.rollerShutter?.railsBottomCut
                    + '°'
            );
        }

        if (conf.rollerShutter?.railsHeightModify !== 0) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Długość przedłużenia`),
                conf.rollerShutter?.railsHeightModify
            );
        }
        if (conf.rollerShutter?.type?.offerInformation) {
            ParserService.pushChild(
                rollerShutter,
                translate(`ROLLERSHUTTER|Informacja`),
                conf.rollerShutter?.type?.offerInformation
            );
        }
    }

    private static setConstruction(conf: any, IccConfig: any, details: any, translate: any, dimensionUnit: any) {
        if (((conf.sashes && conf.sashes?.has) /* TO REV: conf.sashes?.has */
            || conf.type === 'coupled_window')
            && (IccConfig.Offer.alwaysShowConstructInfo
                || IccConfig.Offer.windowLineInfoInPositionDetailsConstruction
                || IccConfig.Offer.alwaysShowDividersInfo
                || (conf.system && conf.system?.price)
                || conf.mullions?.price
                || conf.couplings?.price
                || conf.couplings?.length > 0
                || conf.extensions?.extensions?.length
                || (conf.sashFrames && conf.sashFrames?.price)
                || conf.frames?.price
                || !conf.mullions?.isDefault
                || (conf.sashFrames && !conf.sashFrames?.isDefault)
                || !conf.frames?.isDefault
                || !conf.profileSet?.isDefault
                || conf.lowThreshold
                || conf.reno
                || conf.monoblock
                || conf.alushell?.has
                || conf.mullions?.intMullions?.length
                || conf.alignments?.alignments?.length
                || (IccConfig.Offer.showColorPrice && conf.colors?.price)
                || conf.manySystemAndColors
                || conf.wood
                || (!IccConfig.Offer.detailsOfPositions
                    && (conf.colors?.sashExt
                        || conf.colors?.type === 'Bicolor'
                        || (conf.steel && conf.steel?.type)))
                || (conf.sashes
                    && conf.sashes?.has /* TO REV: conf.sashes?.has */
                    && conf.layout
                    && !conf.layout?.changed
                    && IccConfig.Offer.layoutNameInPositionDetails)
                || conf.dependencies?.price
                || conf.dependencies?.sashes?.length
                || conf.bondedGlazings?.has)) {
            const construction = ParserService.pushChild(
                details,
                translate('WINDOW|Konstrukcja'),
                null
            );

            //WINDOW|Konstrukcja.$$price
            if ((conf.system && IccConfig.Offer.showSystemPrice ? conf.system?.price : 0)
                + conf.mullions?.price
                + conf.couplings?.price
                + conf.couplings?.length
                > 0 + conf.extensions?.price + conf.frames?.price + conf.sashFrames
                && conf.sashFrames?.price
                + conf.lowThreshold?.price
                + conf.frames?.renoPrice
                + (conf.monoblock && conf.monoblock?.price
                    ? conf.monoblock?.price
                    : 0) /* TO REV: conf.monoblock?.price */
                + conf.alushell?.price
                + conf.alignments?.price
                + (IccConfig.Offer.showColorPrice ? conf.colors?.price : 0)
                && conf.prices?.show) {
                construction['$$price'] =
                    (conf.system && IccConfig.Offer.showSystemPrice ? conf.system?.price : 0)
                    + conf.mullions?.price
                    + conf.couplings?.price
                    + conf.extensions?.price
                    + conf.frames?.price
                    + conf.sashFrames?.price
                    + conf.lowThreshold?.price
                    + conf.frames?.renoPrice
                    + conf.monoblock?.price
                    + conf.alushell?.price
                    + conf.alignments?.price
                    + (IccConfig.Offer.showColorPrice ? conf.colors?.price : 0);
            }

            //WINDOW|Konstrukcja.WINDOW|System
            if (conf.system
                && (
                    IccConfig.Offer.alwaysShowConstructInfo
                    || conf.system?.price > 0
                    || IccConfig.Offer.windowLineInfoInPositionDetailsConstruction
                )
            ) {
                ParserService.pushChild(
                    construction,
                    translate('WINDOW|System'),
                    ParserService.getChangedName(conf.system),
                    conf.system?.price
                );
            }

            if (conf.sashes?.has && conf.layout && !conf.layout?.changed && IccConfig.Offer.layoutNameInPositionDetails) {
                ParserService.pushChild(
                    construction,
                    translate('WINDOW|Układ'),
                    ParserService.getChangedName(conf.layout)
                );
            }

            if (conf.type === 'door' && conf.doorSide) {
                let side;
                if (conf.doorSide === 'W_L') {
                    side = translate('DOOR|Drzwi lewe otwierane do wewnątrz');
                } else if (conf.doorSide === 'W_R') {
                    side = translate('DOOR|Drzwi prawe otwierane do wewnątrz');
                } else if (conf.doorSide === 'Z_L') {
                    side = translate('DOOR|Drzwi lewe otwierane do zewnątrz');
                } else if (conf.doorSide === 'Z_R') {
                    side = translate('DOOR|Drzwi prawe otwierane do zewnątrz');
                }
                ParserService.pushChild(
                    construction,
                    translate('DOOR|Strona otwierania'),
                    side
                );
            }

            //WINDOW|Konstrukcja.OFFER|Wymiary bez dodatków
            if (IccConfig.Offer.dimensionsWithoutAccessories) {
                ParserService.pushChild(
                    construction,
                    translate(`OFFER|Wymiary bez dodatków`),
                    dimensionUnit(conf.width) + ' × ' + dimensionUnit(conf.height)
                );
            }

            //WINDOW|Konstrukcja.OFFER|Wymiary całkowite
            ParserService.pushChild(
                construction,
                translate(`OFFER|Wymiary całkowite`),
                dimensionUnit(conf.width) + ' × ' + dimensionUnit(conf.height)
            );

            if (conf.system?.doorType) {
                ParserService.pushChild(
                    construction,
                    translate(`DIMENSION|Rozmiar skrzydła`),
                    ParserService.getChangedName(conf.doorSizes.sash) + (conf.doorSizes.customSize ? ` (${translate('DIMENSION|Wymiar niestandardowy')})` : '')
                );

                if (conf.doorSizes.customWidth) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Szerokość drzwi`),
                        dimensionUnit(conf.doorSizes.customWidth)
                    );
                }
                
                if (conf.doorSizes.customHeight) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Wysokość drzwi`),
                        dimensionUnit(conf.doorSizes.customHeight)
                    );
                }

                if (conf.doorSizes.passiveSash) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Rozmiar skrzydła biernego`),
                        ParserService.getChangedName(conf.doorSizes.passiveSash)
                    );
                }

                if (conf.doorSizes.topLight) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Wysokość doświetla górnego`),
                        dimensionUnit(conf.doorSizes.topLight)
                    );
                }

                if (conf.doorSizes.leftLight) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Wysokość doświetla lewego`),
                        dimensionUnit(conf.doorSizes.leftLight)
                    );
                }

                if (conf.doorSizes.rightLight) {
                    ParserService.pushChild(
                        construction,
                        translate(`DIMENSION|Wysokość doświetla prawego`),
                        dimensionUnit(conf.doorSizes.rightLight)
                    );
                }

                if (conf.doorSizes.shortening) {
                    ParserService.pushChild(
                        construction,
                        conf.system?.seperateFrameAndSashShortening ? translate(`DIMENSION|Skracanie skrzydła`) : translate(`DIMENSION|Skracanie`),
                        dimensionUnit(conf.doorSizes.shortening)
                    );
                    if (conf.system?.seperateFrameAndSashShortening) {
                        ParserService.pushChild(
                            construction,
                            translate(`DIMENSION|Skracanie ościeżnicy`),
                            dimensionUnit(conf.doorSizes.frameShortening)
                        );
                    }
                }
            }

            //WINDOW|Shop|showWeightInDetails|Waga okna
            if (IccConfig.Shop.showWeightInDetails && !isNaN(conf.parameters.weight)) {
                ParserService.pushChild(
                    construction,
                    translate('WINDOW|Waga'),
                    core.round(conf.parameters.weight) + ' kg'
                );
            }

            //WINDOW|Konstrukcja.COLOR|Kolor
            if ((!IccConfig.Offer.detailsOfPositions
                && IccConfig.Offer.colorBasicInfo
                && conf.type !== 'coupled_window')
                || conf.manySystemAndColors) {
                const color = ParserService.pushChild(construction, translate(`COLOR|Kolor`), null);

                //WINDOW|Konstrukcja.COLOR|Kolor.$$price
                if (conf.colors?.price && conf.prices?.show) {
                    color[`$$price`] = conf.colors?.price;
                }

                //WINDOW|Konstrukcja.COLOR|Kolor.$$children
                if (!conf.colors?.sashExt) {
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children[]
                    if (['White', 'Cream'].includes(conf.colors?.type)) {
                        color['value'] = ParserService.getChangedName(conf.colors?.frame?.core);
                    }

                    if (conf.colors?.type === 'Outer') {
                        (color['value'] = translate('COLOR|Jednostronny zewnętrzny') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }

                    if (conf.colors?.type === 'Inner') {
                        (color['value'] = translate('COLOR|Jednostronny wewnętrzny') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }

                    if (conf.colors?.type === 'Bilateral') {
                        (color['value'] = translate('COLOR|Obustronny') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }

                    if (conf.colors?.type === 'Transparent') {
                        (color['value'] = translate('COLOR|Lakierowanie transparentne') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }

                    if (conf.colors?.type === 'Opaque') {
                        (color['value'] = translate('COLOR|Lakierowanie kryjące') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }

                    if (conf.colors?.type === '3D') {
                        (color['value'] = translate('COLOR|Kolor 3D') + ' ' + ParserService.getChangedName(conf.colors?.frame?.outer));
                    }
                }

                //WINDOW|Konstrukcja.COLOR|Kolor.$$children
                if (conf.colors?.type === 'Bicolor') {
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['COLOR|Kolor zewnętrzny']
                    if (conf.colors?.frame?.outer?.name) {
                        if (conf.colors?.frame?.outer?.code) {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.outer,
                                    conf.colors?.frame?.outer?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(conf.colors?.frame?.outer)
                            );
                        }
                    }

                    if (!conf.colors?.frame?.outer?.name && conf.colors?.frame?.core?.name) {
                        if (conf.colors?.frame?.core?.code) {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.core,
                                    conf.colors?.frame?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(conf.colors?.frame?.core)
                            );
                        }
                    }

                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['COLOR|Kolor wewnętrzny']
                    if (conf.colors?.frame?.inner?.name) {
                        if (conf.colors?.frame?.inner?.code) {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.inner,
                                    conf.colors?.frame?.inner?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(conf.colors?.frame?.inner)
                            );
                        }
                    }

                    if (!conf.colors?.frame?.inner?.name && conf.colors?.frame?.core?.name) {
                        if (conf.colors?.frame?.core?.code) {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.core,
                                    conf.colors?.frame?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(conf.colors?.frame?.core)
                            );
                        }
                    }

                    if (conf.colors?.frame?.core && conf.colors?.frame?.core?.name && !conf.wood) {
                        if (conf.colors?.frame?.core?.code) {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Barwienie w masie'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.core,
                                    conf.colors?.frame?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('COLOR|Barwienie w masie'),
                                ParserService.getChangedName(conf.colors?.frame?.core)
                            );
                        }
                    }
                }

                //WINDOW|Konstrukcja.COLOR|Kolor.$$children
                if (conf.colors?.sashExt) {
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor wew. rama']
                    if (conf.colors?.frame?.inner && conf.colors?.frame?.inner?.name) {
                        if (conf.colors?.frame?.inner?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor wew. rama'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.inner,
                                    conf.colors?.frame?.inner?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor wew. rama'),
                                ParserService.getChangedName(conf.colors?.frame?.inner)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor zew. rama']
                    if (conf.colors?.frame?.outer && conf.colors?.frame?.outer?.name) {
                        if (conf.colors?.frame?.outer?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor zew. rama'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.outer,
                                    conf.colors?.frame?.outer?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor zew. rama'),
                                ParserService.getChangedName(conf.colors?.frame?.outer)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Barwienie w masie rama']
                    if (conf.colors?.frame?.core && conf.colors?.frame?.core?.name) {
                        if (conf.colors?.frame?.core?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Barwienie w masie rama'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.core,
                                    conf.colors?.frame?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Barwienie w masie rama'),
                                ParserService.getChangedName(conf.colors?.frame?.core)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor nakładki rama']
                    if (conf.alushell?.has && conf.colors?.frame?.alushell) {
                        if (conf.colors?.frame?.alushell?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki rama'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.alushell,
                                    conf.colors?.frame?.alushell?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki rama'),
                                ParserService.getChangedName(conf.colors?.frame?.alushell)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor wew. skrz.']
                    if (conf.colors?.sash?.inner && conf.colors?.sash?.inner?.name) {
                        if (conf.colors?.sash?.inner?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor wew. skrz.'),
                                ParserService.getChangedName(
                                    conf.colors?.sash?.inner,
                                    conf.colors?.sash?.inner?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor wew. skrz.'),
                                ParserService.getChangedName(conf.colors?.sash?.inner)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor zew. skrz.']
                    if (conf.colors?.sash?.outer && conf.colors?.sash?.outer?.name) {
                        if (conf.colors?.sash?.outer?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor zew. skrz.'),
                                ParserService.getChangedName(
                                    conf.colors?.sash?.outer,
                                    conf.colors?.sash?.outer?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor zew. skrz.'),
                                ParserService.getChangedName(conf.colors?.sash?.outer)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Barwienie w masie skrz.']
                    if (conf.colors?.sash?.core && conf.colors?.sash?.core?.name) {
                        if (conf.colors?.sash?.core?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Barwienie w masie skrz.'),
                                ParserService.getChangedName(
                                    conf.colors?.sash?.core,
                                    conf.colors?.sash?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Barwienie w masie skrz.'),
                                ParserService.getChangedName(conf.colors?.sash?.core)
                            );
                        }
                    }
                    //WINDOW|Konstrukcja.COLOR|Kolor.$$children['WINDOW|Kolor nakładki skrz.']
                    if (conf.alushell?.has && conf.colors?.sash?.alushell) {
                        if (conf.Colors?.sash?.alushell?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki skrz.'),
                                ParserService.getChangedName(
                                    conf.colors?.sash?.alushell,
                                    conf.Colors?.sash?.alushell?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki skrz.'),
                                ParserService.getChangedName(conf.colors?.sash?.alushell)
                            );
                        }
                    }
                }
                //WINDOW|Konstrukcja.COLOR|Kolor.$$children
                if (conf.alushell?.has && conf.colors?.frame?.alushell) {
                    color[`$$children`] = color[`$$children`] || [];

                    if (conf.alushell?.type === 'painted') {
                        if (conf.colors?.frame?.alushell?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki'),
                                ParserService.getChangedName(
                                    conf.colors?.frame?.alushell,
                                    conf.colors?.frame?.alushell?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Kolor nakładki'),
                                ParserService.getChangedName(conf.colors?.frame?.alushell)
                            );
                        }
                    }

                    if (conf.alushell?.type === 'brushed') {
                        if (conf.colors?.frame?.alushell?.code) {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Nakładka'),
                                translate('WINDOW|Aluminium szczotkowane')
                                + ' '
                                + conf.colors?.frame?.alushell?.code
                            );
                        }
                        else {
                            ParserService.pushChild(
                                color,
                                translate('WINDOW|Nakładka'),
                                translate('WINDOW|Aluminium szczotkowane')
                            );
                        }
                    }
                }

                if (conf.lowThreshold && conf.lowThreshold?.color) {
                    ParserService.pushChild(
                        color,
                        translate('WINDOW|Kolor progu'),
                        ParserService.getChangedName(
                            conf.lowThreshold?.color,
                            conf.lowThreshold?.color?.code
                        )
                    );
                }

                if (conf.lippingColor) {
                    ParserService.pushChild(
                        color,
                        translate('WINDOW|Kolor listwy mocującej'),
                        ParserService.getChangedName(
                            conf.lippingColor,
                            conf.lippingColor?.code
                        )
                    );
                }
            }

            //WINDOW|Konstrukcja.WINDOW|Rama
            if (conf.frames?.frames?.length
                && (IccConfig.Offer.alwaysShowConstructInfo
                    || conf.frames?.price > 0
                    || !conf.frames?.isDefault
                    || !conf.profileSet?.isDefault)) {
                const frameName = conf.system?.doorType ? translate(`DOOR|Ościeżnica`) : translate(`WINDOW|Rama`);
                const frame = ParserService.pushChild(construction, frameName, null);

                if (conf.frames?.frames?.length === 1 || conf.frames?.hasOne) {
                    if (conf.frames?.frames?.[0]?.price && conf.prices?.show) {
                        frame[`$$price`] = conf.frames?.frames?.[0]?.price;
                    }

                    if (conf.frames?.frames?.[0]?.hasOne) {
                        frame['value'] = ParserService.getChangedName(conf.frames?.frames?.[0]);
                    }
                    if (!conf.frames?.frames?.[0]?.hasOne) {
                        conf.frames?.frames?.[0]?.profiles.forEach((profile, index) => {
                            let edge = translate('WINDOW|Krawędź') + ' ' + (index + 1) + ' ';
                            if (profile.side === 'left') {
                                edge += translate('WINDOW|lewo');
                            }
                            else if (profile.side === 'top') {
                                edge += translate('WINDOW|góra');
                            }
                            else if (profile.side === 'right') {
                                edge += translate('WINDOW|prawo');
                            }
                            else if (profile.side === 'bottom') {
                                edge += translate('WINDOW|dół');
                            }

                            ParserService.pushChild(
                                frame,
                                edge,
                                ParserService.getChangedName(profile),
                                profile.price
                            );
                        });
                    }
                }

                /* TO REV */
                if (conf.frames?.frames?.length > 1 && !conf.frames?.hasOne) {
                    conf.frames?.frames?.forEach((f, index) => {
                        const this_frame = ParserService.pushChild(
                            frame,
                            f.frameIndex + '.',
                            {}
                        );

                        if (f.price && conf.prices?.show) {
                            this_frame['$$price'] = f.price;
                        }

                        if (f.hasOne) {
                            this_frame['value'] = ParserService.getChangedName(f);
                        }

                        if (!f.hasOne) {
                            f.profiles.forEach((profile, p_index) => {
                                let edge = translate('WINDOW|Krawędź') + ' ' + (p_index + 1) + ' ';
                                if (f.side === 'left') {
                                    edge += translate('WINDOW|lewo');
                                }
                                else if (profile.side === 'top') {
                                    edge += translate('WINDOW|góra');
                                }
                                else if (profile.side === 'right') {
                                    edge += translate('WINDOW|prawo');
                                }
                                else if (profile.side === 'bottom') {
                                    edge += translate('WINDOW|dół');
                                }

                                this_frame[`$$children`].push({
                                    edge,
                                    value: ParserService.getChangedName(profile),
                                    $price: profile.price,
                                });
                            });
                        }
                    });
                }
            }

            //WINDOW|Konstrukcja.WINDOW|Skrzydła
            if (conf.sashFrames
                && conf.sashFrames?.sashes?.length
                && (IccConfig.Offer.alwaysShowConstructInfo
                    || conf.sashFrames?.price > 0
                    || !conf.sashFrames?.isDefault
                    || !conf.profileSet?.isDefault)) {
                const sashes = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Skrzydła`),
                    null
                );

                if (conf.sashFrames?.hasOne) {
                    if (conf.sashFrames?.reinforcement && conf.sashFrames?.reinforcement?.id) {
                        sashes['value'] = ParserService.getChangedName(
                            conf.sashFrames,
                            ParserService.getChangedName(conf.sashFrames?.reinforcement)
                        );
                    }
                    else {
                        sashes['value'] = ParserService.getChangedName(conf.sashFrames);
                    }

                    if (conf.sashFrames?.price && conf.prices?.show) {
                        sashes['$$price'] = conf.sashFrames?.price;
                    }
                }

                if (!conf.sashFrames?.hasOne) {
                    sashes[`$$children`] = [];

                    conf.sashFrames?.sashes?.forEach((sash, index) => {
                        if (sash.hasOne) {
                            if (conf.sash
                                && conf.sash?.reinforcement
                                && conf.sash?.reinforcement?.id) {
                                ParserService.pushChild(
                                    sashes,
                                    translate('WINDOW|Kwatera') + ' ' + sash.sashIndex,
                                    ParserService.getChangedName(
                                        conf.sash,
                                        ParserService.getChangedName(conf.sash?.reinforcement)
                                    ),
                                    sash.price
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    sashes,
                                    translate('WINDOW|Kwatera') + ' ' + sash.sashIndex,
                                    ParserService.getChangedName(conf.sashFrames),
                                    sash.price
                                );
                            }
                        }

                        if (!sash.hasOne) {
                            ['left', 'top', 'right', 'bottom'].forEach((side, index) => {
                                let sidename = '';
                                if (side === 'left') {
                                    sidename = translate('WINDOW|lewo');
                                }
                                if (side === 'top') {
                                    sidename = translate('WINDOW|góra');
                                }
                                if (side === 'right') {
                                    sidename = translate('WINDOW|prawo');
                                }
                                if (side === 'bottom') {
                                    sidename = translate('WINDOW|dół');
                                }
                                if (sash.profiles?.[side]?.reinforcement?.id) {
                                    ParserService.pushChild(
                                        sashes,
                                        translate('WINDOW|Kwatera')
                                        + ' '
                                        + sash.sashIndex
                                        + ', '
                                        + sidename,
                                        ParserService.getChangedName(
                                            sash.profiles?.[side],
                                            ParserService.getChangedName(
                                                sash.profiles?.[side]?.reinforcement
                                            )
                                        ),
                                        sash.profiles?.[side]?.price
                                    );
                                }
                                else {
                                    ParserService.pushChild(
                                        sashes,
                                        translate('WINDOW|Kwatera')
                                        + ' '
                                        + sash.sashIndex
                                        + ', '
                                        + sidename,
                                        ParserService.getChangedName(sash.profiles?.[side]),
                                        sash.profiles?.[side]?.price
                                    );
                                }
                            });
                        }
                    });
                }
            }

            //WINDOW|Konstrukcja.WINDOW|Dopłata z zależności do konstrukcji
            if (conf.dependencies?.price) {
                const construction_dependency = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Dopłata z zależności do konstrukcji`),
                    null
                );

                if (conf.prices?.show) {
                    construction_dependency['$$price'] = conf.dependencies?.price;
                }
            }

            //WINDOW|Konstrukcja.WINDOW|Dopłata z zależności do kwater
            if (conf.dependencies?.price) {
                const sashes_dependencies = ParserService.pushChild(
                    construction,
                    translate('WINDOW|Dopłata z zależności do kwater'),
                    null
                );

                if (conf.dependencies?.sashes?.length > 0) {
                    conf.dependencies?.sashes?.forEach((dependency, index) => {
                        if (dependency.price && conf.prices?.show) {
                            ParserService.pushChild(
                                sashes_dependencies,
                                translate('WINDOW|Kwatera') + ' ' + dependency.sashIndex,
                                null,
                                dependency.price
                            );
                        }
                        else {
                            ParserService.pushChild(
                                sashes_dependencies,
                                translate('WINDOW|Kwatera') + ' ' + dependency.sashIndex,
                                null,
                                null
                            );
                        }
                    });
                }
            }
            //WINDOW|Konstrukcja.WINDOW|Wyrównania
            if (conf.alignments?.show) {
                const alignments = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Wyrównania`),
                    null
                );

                if (conf.alignments?.price
                    && conf.prices?.show
                    && conf.alignments?.alignments?.length > 1) {
                    alignments['$$price'] = conf.alignments?.price;
                }
                conf.alignments?.alignments?.forEach((alignment, index) => {
                    let key = '';
                    if (alignment.type === 'frame') {
                        key += translate('WINDOW|Rama') + ', ';
                    }
                    if (alignment.type === 'sashFrame') {
                        key += translate('WINDOW|Skrzydło') + ', ';
                    }
                    if (alignment.sash !== undefined) {
                        key += translate('WINDOW|Kwatera') + ' ' + alignment.sash + ', ';
                    }

                    if (alignment.side === 'left') {
                        key += translate('WINDOW|lewo') + ': ';
                    }
                    if (alignment.side === 'top') {
                        key += translate('WINDOW|góra') + ': ';
                    }
                    if (alignment.side === 'right') {
                        key += translate('WINDOW|prawo') + ': ';
                    }
                    if (alignment.side === 'bottom') {
                        key += translate('WINDOW|dół') + ': ';
                    }

                    if (alignment.price && conf.prices?.show) {
                        ParserService.pushChild(
                            alignments,
                            key,
                            ParserService.getChangedName(alignment),
                            alignment.price
                        );
                    }
                    else {
                        ParserService.pushChild(
                            alignments,
                            key,
                            ParserService.getChangedName(alignment)
                        );
                    }
                });
            }

            //WINDOW|Konstrukcja.WINDOW|Poszerzenia
            if (conf.extensions?.show) {
                const extensions = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Poszerzenia`),
                    null
                );

                if (conf.extensions?.price
                    && conf.prices?.show
                    && IccConfig.Offer.extensionsPriceShow) {
                    extensions['$$price'] = conf.extensions?.price;
                }

                conf.extensions?.extensions?.forEach((extension, index) => {
                    let key = '';
                    let price = null;
                    const children = [];

                    if (extension.frameIndex) {
                        key +=
                            translate('WINDOW|Rama')
                            + ' '
                            + extension.frameIndex
                            + ' '
                            + translate('WINDOW|Krawędź')
                            + ' '
                            + extension.frameEdgeIndex;
                    }

                    if (extension.side === 'left') {
                        key += ' ' + translate('WINDOW|lewo');
                    }
                    if (extension.side === 'top') {
                        key += ' ' + translate('WINDOW|góra');
                    }
                    if (extension.side === 'right') {
                        key += ' ' + translate('WINDOW|prawo');
                    }
                    if (extension.side === 'bottom') {
                        key += ' ' + translate('WINDOW|dół');
                    }

                    let value = ParserService.getChangedName(extension, extension.code);

                    if (extension.type === 'sandwich') {
                        value += ` (${extension.width}x${extension.height})`;
                    }

                    if (extension.price && conf.prices?.show) {
                        price = extension.price;
                    }

                    const this_extension = ParserService.pushChild(extensions, key, value, price);
                    if (extension.color?.core?.type==='white' && !extension.color?.inner) {
                        ParserService.pushChild(
                            this_extension,
                            translate('COLOR|Kolor'),
                            translate('WINDOW|Biały')
                        );
                    }
                    if (!extension.color?.core && !extension.color?.outer && !extension.color?.inner) {
                        ParserService.pushChild(
                            this_extension,
                            translate('COLOR|Kolor'),
                            translate('WINDOW|W kolorze konstrukcji')
                        );
                    }

                    if (extension.color?.core
                        && IccConfig.Configurators.showCoreColorInAdditionalProfilesAndAccessories) {
                        if (extension.color?.core?.code) {
                            ParserService.pushChild(
                                this_extension,
                                translate('WINDOW|Rdzeń'),
                                ParserService.getChangedName(
                                    extension.color?.core,
                                    extension.color?.core?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                this_extension,
                                translate('WINDOW|Rdzeń'),
                                ParserService.getChangedName(extension.color?.core)
                            );
                        }
                    }

                    if (extension.color?.outer) {
                        if (extension.color?.core?.code) {
                            ParserService.pushChild(
                                this_extension,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(
                                    extension.color?.outer,
                                    extension.color?.outer?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                this_extension,
                                translate('COLOR|Kolor zewnętrzny'),
                                ParserService.getChangedName(extension.color?.outer)
                            );
                        }
                    }

                    if (IccConfig.Configurators.hs.doubleExtensions) {
                        ParserService.pushChild(
                            this_extension,
                            translate('INTERFACE|Ilość'),
                            extension.count
                        );
                    }
                    if (IccConfig.Configurators.extensionsReinforcements && extension.type !== 'sandwich') {
                        ParserService.pushChild(
                            this_extension,
                            translate('INTERFACE|Wzmocnienie'),
                            !extension.reinforcement
                                ? translate('INTERFACE|Brak')
                                : ParserService.getChangedName(extension.reinforcement)
                        );
                    }

                    if (extension.color?.inner) {
                        if (extension.color?.core?.code) {
                            ParserService.pushChild(
                                this_extension,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(
                                    extension.color?.inner,
                                    extension.color?.inner?.code
                                )
                            );
                        }
                        else {
                            ParserService.pushChild(
                                this_extension,
                                translate('COLOR|Kolor wewnętrzny'),
                                ParserService.getChangedName(extension.color?.inner)
                            );
                        }
                    }
                });
            }

            for (const key in conf.mullions) {
                if (conf.mullions?.hasOwnProperty(key)) {
                    const divs = conf.mullions?.[key];
                    if ((IccConfig.Offer.alwaysShowConstructInfo
                        || IccConfig.Offer.alwaysShowDividersInfo
                        || conf.mullions?.price > 0
                        || !conf.mullions?.isDefault
                        || !conf.profileSet?.isDefault)
                        && conf.mullions?.[key]?.length) {
                        let type = '';
                        if (key === 'static') {
                            type = translate('WINDOW|Słupki stałe');
                        }

                        if (key === 'movable') {
                            type = translate('WINDOW|Słupek ruchomy');
                        }

                        if (key === 'movableCentral') {
                            type = translate('WINDOW|Słupek ruchomy z centralną klamką');
                        }

                        if (key === 'movableDouble') {
                            type = translate('WINDOW|Słupek ruchomy z dwoma klamkami');
                        }

                        if (key === 'astragalFrame') {
                            type = translate('WINDOW|Szczebliny/szprosy konstrukcyjne w ramie');
                        }

                        if (key === 'intMullions') {
                            type = translate('WINDOW|Szczebliny/szprosy konstrukcyjne');
                        }

                        //conf.mullions?.staticOne = false;
                        if (conf.mullions?.[key + 'One']) {
                            ParserService.pushChild(
                                construction,
                                type,
                                ParserService.getChangedName(divs?.[0])
                                + (divs?.[0]?.outerWidth
                                    ? ' (' + dimensionUnit(divs?.[0]?.outerWidth) + ')'
                                    : '')
                                + (divs?.[0]?.reinforcement && divs?.[0]?.reinforcement.id
                                    ? ' ' + ParserService.getChangedName(divs?.[0]?.reinforcement)
                                    : '')
                            );
                        }
                        else if (!conf.mullions?.[key + 'One']) {
                            const mullion = ParserService.pushChild(construction, type, null);
                            divs.forEach((div, index) => {
                                if (key !== 'intMullions') {
                                    ParserService.pushChild(
                                        mullion,
                                        translate('WINDOW|Kwatera')
                                        + ' '
                                        + div.topLeft
                                        + '/'
                                        + div.bottomRight,
                                        ParserService.getChangedName(div)
                                        + (div.outerWidth
                                            ? ' ' + dimensionUnit(div.outerWidth)
                                            : '')
                                    );
                                }
                                if (key === 'intMullions') {
                                    ParserService.pushChild(
                                        mullion,
                                        translate('WINDOW|Kwatera')
                                        + ' '
                                        + div.sashIndex
                                        + ', '
                                        + translate('WINDOW|Pole')
                                        + ' '
                                        + div.topLeft
                                        + '/'
                                        + div.bottomRight,
                                        ParserService.getChangedName(div)
                                        + (div.outerWidth
                                            ? ' ' + dimensionUnit(div.outerWidth)
                                            : '')
                                    );
                                }
                            });
                        }
                    }
                }
            }

            if (conf.couplings?.couplings?.length && !conf.system?.doorType) {
                const couplings = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Łączniki`),
                    null
                );

                conf.couplings?.couplings?.forEach((coupling, index) => {
                    const key = translate('WINDOW|Rama')
                        + ' '
                        + coupling.topLef
                        + '/'
                        + coupling.bottomRight;
                    const value = ParserService.getChangedName(coupling)
                        + (coupling.outerWidth ? ' ' + dimensionUnit(coupling.outerWidth) : '');

                    const this_coupling = ParserService.pushChild(couplings, key, value);

                    // construction[`WINDOW|Łączniki`][key] = {"$$value": value};
                    if (coupling.price && conf.prices?.show) {
                        this_coupling['$$price'] = coupling.price;
                    }

                    if (!coupling.color?.core && !coupling.color?.outer && !coupling.color?.inner) {
                        ParserService.pushChild(
                            this_coupling,
                            translate('COLOR|Kolor'),
                            translate('WINDOW|W kolorze konstrukcji')
                        );
                    }

                    if (coupling.color?.core
                        && IccConfig.Configurators.showCoreColorInAdditionalProfilesAndAccessories) {
                        ParserService.pushChild(
                            this_coupling,
                            translate('WINDOW|Rdzeń'),
                            ParserService.getChangedName(coupling.color?.core)
                        );
                    }

                    if (coupling.color?.outer) {
                        ParserService.pushChild(
                            this_coupling,
                            translate('COLOR|Kolor zewnętrzny'),
                            ParserService.getChangedName(
                                coupling.color?.core,
                                coupling.color?.outer?.code ? coupling.color?.outer?.code : null
                            )
                        );
                    }

                    if (coupling.color?.inner) {
                        ParserService.pushChild(
                            this_coupling,
                            translate('COLOR|Kolor wewnętrzny'),
                            ParserService.getChangedName(
                                coupling.color?.core,
                                coupling.color?.inner?.code ? coupling.color?.inner?.code : null
                            )
                        );
                    }
                });
            }

            if (conf.lowThreshold) {
                const childName = conf.system?.doorType ? translate(`DOOR|Próg`) : translate(`DOOR|Niski próg`);
                if (conf.lowThreshold?.price && conf.prices?.show) {
                    ParserService.pushChild(
                        construction,
                        childName,
                        ParserService.getChangedName(conf.lowThreshold),
                        conf.lowThreshold?.price
                    );
                }
                else {
                    ParserService.pushChild(
                        construction,
                        childName,
                        ParserService.getChangedName(conf.lowThreshold)
                    );
                }
            }

            if (conf.system && conf.system?.frameType === 'reno' && conf.frame?.hasOneFinWidth) {
                if (conf.frame?.renoPrice && conf.prices?.show) {
                    ParserService.pushChild(
                        construction,
                        translate(`WINDOW|Rama renowacyjna`),
                        translate('DIMENSION|Wszystkie boki przycięte')
                        + ': '
                        + dimensionUnit(conf.frame?.hasOneFinWidth),
                        conf.frame?.renoPrice
                    );
                }
                else {
                    ParserService.pushChild(
                        construction,
                        translate(`WINDOW|Rama renowacyjna`),
                        translate('DIMENSION|Wszystkie boki przycięte')
                        + ': '
                        + dimensionUnit(conf.frame?.hasOneFinWidth)
                    );
                }
            }

            if (conf.system && conf.system?.frameType === 'reno' && !conf.frame?.hasOneFinWidt) {
                const reno_frame = ParserService.pushChild(
                    construction,
                    translate(`WINDOW|Rama renowacyjna`),
                    null
                );

                if (conf.frame?.renoPrice && conf.prices?.show) {
                    reno_frame['$$price'] = conf.frame?.renoPrice;
                }
                conf.frame?.profile?.forEach((profile, index) => {
                    if (profile.side === 'bottom') {
                        ParserService.pushChild(
                            reno_frame,
                            translate('DIMENSION|Szerokość z dołu'),
                            dimensionUnit(profile.finWidth)
                        );
                    }

                    if (profile.side === 'left') {
                        ParserService.pushChild(
                            reno_frame,
                            translate('DIMENSION|Szerokość z lewej'),
                            dimensionUnit(profile.finWidth)
                        );
                    }

                    if (profile.side === 'right') {
                        ParserService.pushChild(
                            reno_frame,
                            translate('DIMENSION|Szerokość z prawej'),
                            dimensionUnit(profile.finWidth)
                        );
                    }

                    if (profile.side === 'top') {
                        ParserService.pushChild(
                            reno_frame,
                            translate('DIMENSION|Szerokość z góry'),
                            dimensionUnit(profile.finWidth)
                        );
                    }
                });
            }

            if (conf.alushell?.has && !IccConfig.Offer.colorBasicInfo) {
                if (conf.alushell?.price && conf.prices?.show) {
                    ParserService.pushChild(
                        construction,
                        translate('WINDOW|Nakładka aluminiowa'),
                        translate('INTERFACE|Tak'),
                        conf.alushell?.price
                    );
                }
                else {
                    ParserService.pushChild(
                        construction,
                        translate('WINDOW|Nakładka aluminiowa'),
                        translate('INTERFACE|Tak')
                    );
                }
            }
            if (IccConfig.Offer.showColorPrice
                && conf.sashes?.has
                && conf.colors?.price
                && !IccConfig.Offer.colorBasicInfo
                && !conf.manySystemAndColors) {
                if (conf.colors?.price && conf.prices?.show) {
                    ParserService.pushChild(
                        construction,
                        translate('WINDOW|Dopłata do koloru'),
                        null,
                        conf.alushell?.price
                    ); //TODO SPRAWDŹ
                }
                else {
                    ParserService.pushChild(
                        construction,
                        translate('WINDOW|Dopłata do koloru'),
                        null
                    );
                }
            }

            if (!IccConfig.Offer.groupsOfPositions
                && IccConfig.Offer.colorInPositionName
                && !IccConfig.Offer.colorBasicInfo
                && (conf.colors?.sashExt || conf.colors?.type === 'Bicolor')
                && conf.type !== 'coupled_window') {
                const construction_color = ParserService.pushChild(
                    construction,
                    translate('COLOR|Kolor'),
                    null
                );

                if (conf.colors?.frame?.inner && conf.colors?.frame?.inner?.name) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor wew. rama'),
                        ParserService.getChangedName(
                            conf.colors?.frame?.inner,
                            conf.colors?.frame?.inner?.cod ? conf.colors?.frame?.inner?.cod : null
                        )
                    );
                }
                if (conf.colors?.frame?.outer && conf.colors?.frame?.outer?.name) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor zew. rama'),
                        ParserService.getChangedName(
                            conf.colors?.frame?.outer,
                            conf.colors?.frame?.outer?.code ? conf.colors?.frame?.outer?.code : null
                        )
                    );
                }

                if (conf.colors?.frame?.core && conf.colors?.frame?.core?.name) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Barwienie w masie rama'),
                        ParserService.getChangedName(
                            conf.colors?.frame?.core,
                            conf.colors?.frame?.core?.code ? conf.colors?.frame?.core?.code : null
                        )
                    );
                }

                if (conf.alushell?.has && conf.colors?.frame?.alushell) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor nakładki rama'),
                        ParserService.getChangedName(
                            conf.colors?.frame?.alushell,
                            conf.colors?.frame?.alushell?.name ? conf.colors?.frame?.alushell?.name : null
                        )
                    );
                }

                if (conf.colors?.sash?.inner && conf.colors?.sash?.inner?.name && conf.colors?.sashExt) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor wew. skrz.'),
                        ParserService.getChangedName(
                            conf.colors?.sash?.inner,
                            conf.colors?.sash?.inner?.code ? conf.colors?.sash?.inner?.code : null
                        )
                    );
                }
                if (conf.colors?.sash?.outer && conf.colors?.sash?.outer?.name && conf.colors?.sashExt) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor zew. skrz.'),
                        ParserService.getChangedName(
                            conf.colors?.sash?.outer,
                            conf.colors?.sash?.outer?.code ? conf.colors?.sash?.outer?.code : null
                        )
                    );
                }

                if (conf.colors?.sash?.core && conf.colors?.sash?.core?.name && conf.colors?.sashExt) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Barwienie w masie skrz.'),
                        ParserService.getChangedName(
                            conf.colors?.sash?.core,
                            conf.colors?.sash?.core?.code ? conf.colors?.sash?.core?.code : null
                        )
                    );
                }

                if (conf.alushell?.has && conf.colors?.sash?.alushell && conf.colors?.sashExt) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor nakładki skrz.'),
                        ParserService.getChangedName(
                            conf.colors?.sash?.alushell,
                            conf.Colors?.sash?.alushell?.code ? conf.Colors?.sash?.alushell?.code : null
                        )
                    );
                }

                if (conf.lowThreshold && conf.lowThreshold?.color) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor progu'),
                        ParserService.getChangedName(
                            conf.lowThreshold?.color,
                            conf.lowThreshold?.color?.code
                        )
                    );
                }

                if (conf.lippingColor) {
                    ParserService.pushChild(
                        construction_color,
                        translate('WINDOW|Kolor listwy mocującej'),
                        ParserService.getChangedName(
                            conf.lippingColor,
                            conf.lippingColor?.code
                        )
                    );
                }
            }

            if (!IccConfig.Offer.groupsOfPositions
                && IccConfig.Offer.colorInPositionName
                && !IccConfig.Offer.colorBasicInfo
                && conf.colors?.type === 'Bilateral'
                && !conf.colors?.sashExt
                && IccConfig.Configurators.showCoreColorInAdditionalProfilesAndAccessories) {
                ParserService.pushChild(
                    construction,
                    translate('WINDOW|Rdzeń'),
                    ParserService.getChangedName(conf.colors?.frame?.core)
                );
            }

            if (conf.sashes?.has && conf.wood && !IccConfig.Offer.groupsOfPositions) {
                ParserService.pushChild(
                    construction,
                    translate('WINDOW|Rodzaj drewna'),
                    ParserService.getChangedName(conf.wood)
                );
            }

            if (conf.steel && conf.steel?.type) {
                if (conf.steel?.type === 'Opened') {
                    ParserService.pushChild(construction, translate('WINDOW|Stal otwarta'), null);
                }
                if (conf.steel?.type === 'Closed') {
                    ParserService.pushChild(construction, translate('WINDOW|Stal zamknięta'), null);
                }
            }

            if (conf.bondedGlazings && conf.bondedGlazings?.has) {
                const bonded_glazings = ParserService.pushChild(
                    construction,
                    translate('WINDOW|Wklejane szyby'),
                    null
                );
                if (!conf.bondedGlazings?.hasOne) {
                    conf.bondedGlazings?.sashes?.forEach((bondedGlazingSash, index) => {
                        if (bondedGlazingSash.hasOne && bondedGlazingSash.has) {
                            ParserService.pushChild(
                                bonded_glazings,
                                translate('WINDOW|Kwatera'),
                                bondedGlazingSash.sashIndex
                            );
                        }
                        bondedGlazingSash.intSashes.forEach((bondedGlazingField, index) => {
                            if (!bondedGlazingSash.hasOne && bondedGlazingField.has) {
                                ParserService.pushChild(
                                    bonded_glazings,
                                    translate('WINDOW|Kwatera')
                                    + ': '
                                    + bondedGlazingSash.sashIndex
                                    + ' '
                                    + translate('WINDOW|pole')
                                    + ' '
                                    + bondedGlazingField.isashIndex,
                                    null
                                );
                            }
                        });
                    });
                }
            }

            if (IccConfig.Configurators.window.additionalOptionsInAccessory
                && conf.type !== 'coupled_window') {
                const options = ParserService.pushChild(
                    construction,
                    translate('WINDOW|Opcje'),
                    null
                );

                if (conf.system && conf.system?.type === 'pvc') {
                    if (conf.weldFinishType === 'thin') {
                        ParserService.pushChild(
                            options,
                            translate('OFFER|Typ zgrzewu'),
                            translate('WINDOW|Zgrzew nitkowy')
                        );
                    }
                    if (conf.weldFinishType === 'V') {
                        ParserService.pushChild(
                            options,
                            translate('OFFER|Typ zgrzewu'),
                            translate('WINDOW|Zgrzew typu "V"')
                        );
                    }
                }

                if (conf.drainagePosition === 'bottom') {
                    ParserService.pushChild(
                        options,
                        translate('OFFER|Odwodnienie'),
                        translate('WINDOW|Od dołu')
                    );
                }
                if (conf.drainagePosition === 'front') {
                    ParserService.pushChild(
                        options,
                        translate('OFFER|Odwodnienie'),
                        translate('WINDOW|Od czoła')
                    );
                }
            }
        }
    }

    private static setFilling(conf: any, details: { $$children: any[]; }, translate: (s: string) => string, IccConfig: IIccConfig, dimensionUnit: (s: any) => string, offersView: { pdf: any; }) {
        if (conf.sashes && conf.sashes?.has) {
            const groupKey = '';
            let fillingHeader = translate('WINDOW|Szyby');
            if (conf.type === 'door') {
                fillingHeader = conf.system?.doorType ? translate('DOOR|Wzór') : translate('DOOR|Wypełnienia');
            }
            const group = ParserService.pushChild(details, fillingHeader, null);

            if (conf.prices?.show
                && (conf.fillings ? conf.fillings?.price : 0)
                + (conf.fillings?.pricePanel?.all || 0)
                + (conf.glazingSpacer ? conf.glazingSpacer?.price : 0)
                + (conf.muntins ? conf.muntins?.price : 0)
                + (conf.sealColor ? conf.sealColor?.price : 0)
                + (conf.siliconeColor ? conf.siliconeColor?.price : 0)) {
                group['$$price'] =
                    (conf.fillings?.price || 0)
                    + (conf.fillings?.pricePanel?.all || 0)
                    + (conf.glazingSpacer ? conf.glazingSpacer?.price : 0)
                    + ((conf.muntins && conf.muntins?.price) || 0)
                    + ((conf.sealColor && conf.sealColor?.price) || 0)
                    + ((conf.siliconeColor && conf.siliconeColor?.price) || 0);
            }

            if ((conf.type !== 'door' || conf.fillings?.hasOne) && !conf.system?.doorType) {
                let value = '';
                let price = false;
                let oneFilling = null;

                if (conf.type !== 'door') {
                    oneFilling = ParserService.pushChild(group, translate('WINDOW|Szyby'), null);
                }

                if (conf.fillings?.hasOne) {
                    if (conf.type === 'door') {
                        oneFilling = ParserService.pushChild(group, conf.system?.doorType ? translate('DOOR|Wzór') : translate('DOOR|Wypełnienia'), null);
                    }

                    value +=
                        ' '
                        + ParserService.getChangedName(
                            conf.fillings,
                            IccConfig.Configurators.fillingsProducers
                                && conf.fillings?.producer
                                && IccConfig.Configurators.showFillingsProducers
                                ? conf.fillings?.producer
                                : null
                        );

                    if (conf.fillings?.type !== 'deco_panels' && conf.fillings?.type !== 'door_panels') {
                        if (IccConfig.Offer.warmEdgeInGlassName && conf.glazingSpacer) {
                            value += ' (' + ParserService.getChangedName(conf.glazingSpacer) + ')';
                        }

                        value += conf.fillings?.u ? ' Ug = ' + conf.fillings?.u + ' W/m2*K' : '';

                        if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                            value +=
                                ' ('
                                + dimensionUnit(conf.fillings?.fillingSizes?.width)
                                + ' x '
                                + dimensionUnit(conf.fillings?.fillingSizes?.height)
                                + ')';
                        }
                    }

                    oneFilling['value'] = value;

                    if (IccConfig.Offer.warmEdgeInGlassName
                        && (conf.fillings?.price
                            || conf.glazingSpacer && conf.glazingSpacer?.price
                            || (conf.fillings?.pricePanel?.all
                                && (conf.fillings?.type === 'deco_panels' || conf.fillings?.type === 'door_panels')))
                        && conf.prices?.show) {
                        price =
                            conf.fillings?.price
                            + (conf.glazingSpacer && conf.glazingSpacer?.price || 0)
                            + conf.fillings?.pricePanel?.all;
                    }

                    if (!IccConfig.Offer.warmEdgeInGlassName
                        && (conf.fillings?.price
                            || (conf.fillings?.pricePanel?.all
                                && (conf.fillings?.type === 'deco_panels' || conf.fillings?.type === 'door_panels')))
                        && conf.prices?.show) {
                        price = conf.fillings?.price + conf.fillings?.pricePanel?.all;
                    }

                    if (price) {
                        oneFilling['$$price'] = price;
                    }

                    if (IccConfig.Configurators.pvcPanelsDifferentColors
                        && conf.fillings?.type === 'pvc_panels') {
                        if (!conf.fillings?.sashes?.[0]?.singleColor) {
                            if (conf.fillings?.color?.inner?.name) {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Wewnetrzny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.inner)
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Wewnetrzny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.core)
                                );
                            }
                            if (conf.fillings?.color?.outer?.name) {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Zewnetrzny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.outer)
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Zewnetrzny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.core)
                                );
                            }
                        }
                        else {
                            if (conf.fillings?.color?.inner?.name) {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Obustronny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.inner)
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Obustronny kolor'),
                                    ParserService.getChangedName(conf.fillings?.color?.core)
                                );
                            }
                        }
                    }

                    if (conf.fillings?.type === 'deco_panels' || conf.fillings?.type === 'door_panels') {
                        if (!conf.fillings?.sashes?.[0]?.singleColor) {
                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Wewnętrzny kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.color?.inner
                                        ? conf.fillings?.sashes?.[0]?.color?.inner
                                        : conf.fillings?.sashes?.[0]?.color?.core
                                )
                            );

                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Zewnętrzny kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.color?.outer
                                        ? conf.fillings?.sashes?.[0]?.color?.outer
                                        : conf.fillings?.sashes?.[0]?.color?.core
                                )
                            );

                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Obustronny kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.color?.inner
                                        ? conf.fillings?.sashes?.[0]?.color?.inner
                                        : conf.fillings?.sashes?.[0]?.color?.core
                                )
                            );
                        }
                        if (conf.fillings?.sashes?.[0]?.overlayColor
                            && (conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                                || conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name)
                            && conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                            !== conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name) {
                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Wewnętrzny drugi kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.overlayColor?.inner
                                        ? conf.fillings?.sashes?.[0]?.overlayColor?.inner
                                        : conf.colors?.frame?.core
                                )
                            );
                        }
                        if (conf.fillings?.sashes?.[0]?.overlayColor
                            && (conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                                || conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name)
                            && conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                            !== conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name) {
                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Zewnętrzny drugi kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.overlayColor?.outer
                                        ? conf.fillings?.sashes?.[0]?.overlayColor?.outer
                                        : conf.colors?.frame?.core
                                )
                            );
                        }
                        if (conf.fillings?.sashes?.[0]?.overlayColor
                            && (conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                                || conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name)
                            && conf.fillings?.sashes?.[0]?.overlayColor?.inner?.name
                            === conf.fillings?.sashes?.[0]?.overlayColor?.outer?.name) {
                            ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Obustronny drugi kolor'),
                                ParserService.getChangedName(
                                    conf.fillings?.sashes?.[0]?.overlayColor?.inner
                                        ? conf.fillings?.sashes?.[0]?.overlayColor?.inner
                                        : conf.colors?.frame?.core
                                )
                            );
                        }
                        if (conf.fillings?.sashes?.[0]?.panelType) {
                            if (conf.fillings?.sashes?.[0]?.panelType === 'Inset') {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('DOOR|Wypełnienie wsadowe'),
                                    dimensionUnit(conf.fillings?.sashes?.[0]?.panelThickness)
                                );
                            }
                            if (conf.fillings?.sashes?.[0]?.panelType === 'Outer') {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('DOOR|Nakładka zewnętrzna'),
                                    dimensionUnit(conf.fillings?.sashes?.[0]?.panelThickness)
                                );
                            }
                            if (conf.fillings?.sashes?.[0]?.panelType === 'Double') {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('DOOR|Nakładka obustronna'),
                                    dimensionUnit(conf.fillings?.sashes?.[0]?.panelThickness)
                                );
                            }
                            if (conf.fillings?.sashes?.[0]?.panelType === 'Inner') {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('DOOR|Nakładka wewnętrzna'),
                                    dimensionUnit(conf.fillings?.sashes?.[0]?.panelThickness)
                                );
                            }
                        }
                        if (conf.fillings?.sashes?.[0]?.panelGlazing
                            && conf.fillings?.sashes?.[0]?.panelGlazing?.name) {
                            if (conf.fillings?.sashes?.[0]?.pricePanel?.glazing && conf.prices?.show) {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Szklenie'),
                                    ParserService.getChangedName(
                                        conf.fillings?.sashes?.[0]?.panelGlazing,
                                        IccConfig.Configurators.fillingsProducers
                                            && conf.fillings?.sashes?.[0]?.panelGlazing?.producer
                                            && IccConfig.Configurators.showFillingsProducers
                                            ? ' ' + conf.fillings?.sashes?.[0]?.panelGlazing?.producer
                                            : null
                                    ),
                                    conf.fillings?.sashes?.[0]?.pricePanel?.glazing
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Szklenie'),
                                    ParserService.getChangedName(
                                        conf.fillings?.sashes?.[0]?.panelGlazing,
                                        IccConfig.Configurators.fillingsProducers
                                            && conf.fillings?.sashes?.[0]?.panelGlazing?.producer
                                            && IccConfig.Configurators.showFillingsProducers
                                            ? ' ' + conf.fillings?.sashes?.[0]?.panelGlazing?.producer
                                            : null
                                    )
                                );
                            }
                        }
                    }
                }

                if (!conf.fillings?.hasOne) {
                    conf.fillings?.sashes?.forEach((filling, index) => {
                        if (filling.hasOne) {
                            const filling_child = ParserService.pushChild(
                                oneFilling,
                                translate('WINDOW|Kwatera') + ' ' + filling.sashIndex,
                                null
                            );

                            filling_child['value'] = ParserService.getChangedName(
                                filling,
                                IccConfig.Configurators.fillingsProducers
                                    && filling.producer
                                    && IccConfig.Configurators.showFillingsProducers
                                    ? filling.producer
                                    : null
                            );

                            if (IccConfig.Offer.warmEdgeInGlassName
                                && conf.glazingSpacer
                                && filling.glazingCount > 1) {
                                filling_child['value'] +=
                                    ' ' + ParserService.getChangedName(conf.glazingSpacer);
                            }

                            filling_child['value'] +=
                                'Ug = ' + (filling.u ? filling.u : '') + 'W/m2*K';

                            if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                                filling_child['value'] +=
                                    ' '
                                    + dimensionUnit(
                                        (filling.intSashes?.[0] || filling).fillingSizes.width
                                    )
                                    + ' x '
                                    + dimensionUnit(
                                        (filling.intSashes?.[0] || filling).fillingSizes.height
                                    );
                            }

                            if (!IccConfig.Offer.warmEdgeInGlassName
                                && filling.price
                                && conf.prices?.show) {
                                filling_child['$$price'] = filling.price;
                            }

                            if (IccConfig.Offer.warmEdgeInGlassName
                                && (filling.price || filling.priceGlazingSpacer)
                                && conf.prices?.show) {
                                filling_child['$$price'] =
                                    filling.price + filling.priceGlazingSpacer;
                            }

                            if (IccConfig.Configurators.pvcPanelsDifferentColors
                                && filling.type === 'pvc_panels') {
                                if (!filling.singleColor) {
                                    ParserService.pushChild(
                                        filling_child,
                                        translate('WINDOW|Wewnetrzny kolor'),
                                        ParserService.getChangedName(
                                            filling.color?.inner
                                                ? filling.color?.inner
                                                : filling.color?.core
                                        )
                                    );
                                }
                                if (!filling.singleColor) {
                                    ParserService.pushChild(
                                        filling_child,
                                        translate('WINDOW|Zewnetrzny kolor'),
                                        ParserService.getChangedName(
                                            filling.color?.outer
                                                ? filling.color?.outer
                                                : filling.color?.core
                                        )
                                    );
                                }
                                if (filling.singleColor) {
                                    ParserService.pushChild(
                                        filling_child,
                                        translate('WINDOW|Obustronny kolor'),
                                        ParserService.getChangedName(
                                            filling.color?.inner
                                                ? filling.color?.outer
                                                : filling.color?.core
                                        )
                                    );
                                }
                            }
                        }

                        if (!filling.hasOne) {
                            filling.intSashes?.forEach((intFilling, index) => {
                                const filling_child = ParserService.pushChild(
                                    oneFilling,
                                    translate('WINDOW|Kwatera')
                                    + ' '
                                    + intFilling.sashIndex
                                    + ', '
                                    + translate('WINDOW|Pole')
                                    + ' '
                                    + intFilling.isashIndex,
                                    ParserService.getChangedName(
                                        intFilling,
                                        IccConfig.Configurators.fillingsProducers
                                            && intFilling.producer
                                            && IccConfig.Configurators.showFillingsProducers
                                            ? intFilling.producer
                                            : null
                                    )
                                );

                                if (IccConfig.Offer.warmEdgeInGlassName
                                    && conf.glazingSpacer
                                    && !['pvc_panels', 'deco_panels', 'door_panels'].includes(intFilling.type)) {
                                    filling_child['value'] +=
                                        ' ' + ParserService.getChangedName(conf.glazingSpacer);
                                }

                                filling_child['value'] += ' Ug=' + intFilling.u + 'W/m2*K';

                                if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                                    filling_child['value'] +=
                                        ' '
                                        + dimensionUnit(intFilling.fillingSizes?.width)
                                        + ' x '
                                        + dimensionUnit(intFilling.fillingSizes?.width);
                                }

                                if (IccConfig.Offer.warmEdgeInGlassName
                                    && (intFilling.price || intFilling.priceGlazingSpacer)
                                    && conf.prices?.show) {
                                    filling_child['$$price'] =
                                        intFilling.price + intFilling.priceGlazingSpacer;
                                }

                                if (!IccConfig.Offer.warmEdgeInGlassName
                                    && intFilling.price
                                    && conf.prices?.show) {
                                    filling_child['$$price'] = intFilling.price;
                                }

                                if (IccConfig.Configurators.pvcPanelsDifferentColors
                                    && intFilling.type === 'pvc_panels') {
                                    if (!filling.singleColor) {
                                        ParserService.pushChild(
                                            filling_child,
                                            translate('WINDOW|Wewnetrzny kolor'),
                                            ParserService.getChangedName(
                                                intFilling.color?.inner
                                                    ? intFilling.color?.inner
                                                    : intFilling.color?.core
                                            )
                                        );
                                    }
                                    if (!filling.singleColor) {
                                        ParserService.pushChild(
                                            filling_child,
                                            translate('WINDOW|Zewnetrzny kolor'),
                                            ParserService.getChangedName(
                                                intFilling.color?.outer
                                                    ? intFilling.color?.outer
                                                    : intFilling.color?.core
                                            )
                                        );
                                    }
                                    if (filling.singleColor) {
                                        ParserService.pushChild(
                                            filling_child,
                                            translate('WINDOW|Obustronny kolor'),
                                            ParserService.getChangedName(
                                                intFilling.color?.inner
                                                    ? intFilling.color?.outer
                                                    : intFilling.color?.core
                                            )
                                        );
                                    }
                                }
                            });
                        }
                    });
                }
            }

            if (conf.type === 'door' && !conf.fillings?.hasOne && !conf.system?.doorType) {
                const fillings = ParserService.pushChild(group, null, null);

                conf.fillings?.sashes?.forEach((filling, index) => {
                    if (filling.hasOne) {
                        const this_filling = ParserService.pushChild(
                            fillings,
                            translate('WINDOW|Kwatera') + ' ' + filling.sashIndex,
                            ParserService.getChangedName(
                                filling,
                                IccConfig.Configurators.fillingsProducers
                                    && filling.producer
                                    && IccConfig.Configurators.showFillingsProducers
                                    ? filling.producer
                                    : null
                            )
                        );

                        if (filling.pricePanel?.panel && conf.prices?.show) {
                            this_filling['$$price'] = filling.pricePanel?.panel;
                        }

                        if (IccConfig.Offer.warmEdgeInGlassName
                            && (filling.price || filling.priceGlazingSpacer)
                            && conf.prices?.show) {
                            this_filling['$$price'] = filling.price + filling.priceGlazingSpacer;
                        }

                        if (!IccConfig.Offer.warmEdgeInGlassName
                            && filling.price
                            && conf.prices?.show) {
                            this_filling['$$price'] = filling.price;
                        }

                        if (filling.type !== 'deco_panels' && filling.type !== 'door_panels') {
                            if (IccConfig.Offer.warmEdgeInGlassName
                                && conf.glazingSpacer
                                && filling.glazingCount > 1) {
                                this_filling['value'] += conf.glazingSpacer?.name;
                            }

                            this_filling['value'] += ' Ug=' + filling.u + 'W/m2*K';

                            if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                                this_filling['value'] +=
                                    ' '
                                    + dimensionUnit(
                                        (filling.intSashes?.[0] || filling).fillingSizes.width
                                    )
                                    + ' x '
                                    + dimensionUnit(
                                        (filling.intSashes?.[0] || filling).fillingSizes.height
                                    );
                            }
                        }

                        if (filling.type === 'deco_panels' || filling.type === 'door_panels' || filling.type === 'pvc_panels') {
                            if (!filling.singleColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Wewnetrzny kolor'),
                                    ParserService.getChangedName(
                                        filling.color?.inner
                                            ? filling.color?.inner
                                            : filling.color?.core
                                    )
                                );
                            }
                            if (!filling.singleColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Zewnetrzny kolor'),
                                    ParserService.getChangedName(
                                        filling.color?.outer
                                            ? filling.color?.outer
                                            : filling.color?.core
                                    )
                                );
                            }
                            if (filling.singleColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Obustronny kolor'),
                                    ParserService.getChangedName(
                                        filling.color?.inner
                                            ? filling.color?.inner
                                            : filling.color?.core
                                    )
                                );
                            }
                            if (filling.overlayColor
                                && (filling.overlayColor?.inner?.name
                                    || filling.overlayColor?.outer?.name)
                                && filling.overlayColor?.inner?.name
                                !== filling.overlayColor?.outer?.name
                                && filling.overlayColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Wewnetrzny drugi kolor'),
                                    ParserService.getChangedName(
                                        filling.overlayColor?.inner
                                            ? filling.overlayColor?.inner
                                            : conf.colors?.frame?.core
                                    )
                                );
                            }
                            if (filling.overlayColor
                                && (filling.overlayColor?.inner?.name
                                    || filling.overlayColor?.outer?.name)
                                && filling.overlayColor?.inner?.name
                                !== filling.overlayColor?.outer?.name
                                && filling.overlayColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Zewnetrzny drugi kolor'),
                                    ParserService.getChangedName(
                                        filling.overlayColor?.outer
                                            ? filling.overlayColor?.outer
                                            : conf.colors?.frame?.core
                                    )
                                );
                            }
                            if (filling.overlayColor
                                && (filling.overlayColor?.inner?.name
                                    || filling.overlayColor?.outer?.name)
                                && filling.overlayColor?.inner?.name
                                === filling.overlayColor?.outer?.name
                                && filling.overlayColor) {
                                ParserService.pushChild(
                                    this_filling,
                                    translate('WINDOW|Obustronny drugi kolor'),
                                    ParserService.getChangedName(
                                        filling.overlayColor?.inner
                                            ? filling.overlayColor?.inner
                                            : conf.colors?.frame?.core
                                    )
                                );
                            }
                            if (filling.panelType) {
                                if (filling.panelType === 'Inset') {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('DOOR|Wypełnienie wsadowe'),
                                        dimensionUnit(filling.panelThickness)
                                    );
                                }
                                if (filling.panelType === 'Outer') {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('DOOR|Nakładka zewnętrzna'),
                                        dimensionUnit(filling.panelThickness)
                                    );
                                }
                                if (filling.panelType === 'Double') {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('DOOR|Nakładka obustronna'),
                                        dimensionUnit(filling.panelThickness)
                                    );
                                }
                                if (filling.panelType === 'Inner') {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('DOOR|Nakładka wewnętrzna'),
                                        dimensionUnit(filling.panelThickness)
                                    );
                                }
                            }
                            if (filling.panelGlazing && filling.panelGlazing?.name) {
                                if (filling.pricePanel?.glazing && conf.prices?.show) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Szklenie'),
                                        ParserService.getChangedName(
                                            filling.panelGlazing,
                                            IccConfig.Configurators.fillingsProducers
                                                && filling.panelGlazing?.producer
                                                && IccConfig.Configurators.showFillingsProducers
                                                ? ' ' + filling.panelGlazing?.producer
                                                : null
                                        ),
                                        filling.pricePanel?.glazing
                                    );
                                }
                                else {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Szklenie'),
                                        ParserService.getChangedName(
                                            filling.panelGlazing,
                                            IccConfig.Configurators.fillingsProducers
                                                && filling.panelGlazing?.producer
                                                && IccConfig.Configurators.showFillingsProducers
                                                ? ' ' + filling.panelGlazing?.producer
                                                : null
                                        )
                                    );
                                }
                            }
                        }
                    }

                    if (!filling.hasOne) {
                        filling.intSashes?.forEach((intFilling, index) => {
                            const this_filling = ParserService.pushChild(
                                fillings,
                                translate('WINDOW|Kwatera')
                                + ' '
                                + filling.sashIndex
                                + ', '
                                + translate('WINDOW|Pole')
                                + ' '
                                + intFilling.isashIndex,
                                ParserService.getChangedName(
                                    intFilling,
                                    IccConfig.Configurators.fillingsProducers
                                        && filling.producer
                                        && IccConfig.Configurators.showFillingsProducers
                                        ? filling.producer
                                        : null
                                )
                            );

                            if (filling.pricePanel?.panel && conf.prices?.show) {
                                this_filling['$$price'] = intFilling.pricePanel?.panel;
                            }

                            if (IccConfig.Offer.warmEdgeInGlassName
                                && (intFilling.price || intFilling.priceGlazingSpacer)
                                && conf.prices?.show) {
                                this_filling['$$price'] =
                                    intFilling.price + intFilling.priceGlazingSpacer;
                            }

                            if (!IccConfig.Offer.warmEdgeInGlassName
                                && intFilling.price
                                && conf.prices?.show) {
                                this_filling['$$price'] = intFilling.price;
                            }

                            if (intFilling.type !== 'deco_panels' && intFilling.type !== 'door_panels') {
                                if (IccConfig.Offer.warmEdgeInGlassName
                                    && conf.glazingSpacer
                                    && intFilling.glazingCount > 1) {
                                    this_filling['value'] += conf.glazingSpacer?.name;
                                }

                                this_filling['value'] += ' Ug=' + intFilling.u + 'W/m2*K';

                                if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                                    this_filling['value'] +=
                                        ' '
                                        + dimensionUnit(
                                            (intFilling.intSashes?.[0] || filling).fillingSizes.width
                                        )
                                        + ' x '
                                        + dimensionUnit(
                                            (intFilling.intSashes?.[0] || filling).fillingSizes.height
                                        );
                                }
                            }
                            if (intFilling.type === 'deco_panels'
                                || intFilling.type === 'door_panels'
                                || intFilling.type === 'pvc_panels') {
                                if (!intFilling.singleColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Wewnetrzny kolor'),
                                        ParserService.getChangedName(
                                            intFilling.color?.inner
                                                ? intFilling.color?.inner
                                                : intFilling.color?.core
                                        )
                                    );
                                }
                                if (!intFilling.singleColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Zewnetrzny kolor'),
                                        ParserService.getChangedName(
                                            intFilling.color?.outer
                                                ? intFilling.color?.outer
                                                : intFilling.color?.core
                                        )
                                    );
                                }
                                if (intFilling.singleColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Obustronny kolor'),
                                        ParserService.getChangedName(
                                            intFilling.color?.inner
                                                ? intFilling.color?.inner
                                                : intFilling.color?.core
                                        )
                                    );
                                }
                                if (intFilling.overlayColor
                                    && (intFilling.overlayColor?.inner?.name
                                        || intFilling.overlayColor?.outer?.name)
                                    && intFilling.overlayColor?.inner?.name
                                    !== intFilling.overlayColor?.outer?.name
                                    && intFilling.overlayColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Wewnetrzny drugi kolor'),
                                        ParserService.getChangedName(
                                            intFilling.overlayColor?.inner
                                                ? intFilling.overlayColor?.inner
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                                if (intFilling.overlayColor
                                    && (intFilling.overlayColor?.inner?.name
                                        || intFilling.overlayColor?.outer?.name)
                                    && intFilling.overlayColor?.inner?.name
                                    !== intFilling.overlayColor?.outer?.name
                                    && intFilling.overlayColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Zewnetrzny drugi kolor'),
                                        ParserService.getChangedName(
                                            intFilling.overlayColor?.outer
                                                ? intFilling.overlayColor?.outer
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                                if (intFilling.overlayColor
                                    && (intFilling.overlayColor?.inner?.name
                                        || intFilling.overlayColor?.outer?.name)
                                    && intFilling.overlayColor?.inner?.name
                                    === intFilling.overlayColor?.outer?.name
                                    && intFilling.overlayColor) {
                                    ParserService.pushChild(
                                        this_filling,
                                        translate('WINDOW|Obustronny drugi kolor'),
                                        ParserService.getChangedName(
                                            intFilling.overlayColor?.inner
                                                ? intFilling.overlayColor?.inner
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                                if (intFilling.panelType) {
                                    if (intFilling.panelType === 'Inset') {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('DOOR|Wypełnienie wsadowe'),
                                            dimensionUnit(intFilling.panelThickness)
                                        );
                                    }
                                    if (intFilling.panelType === 'Outer') {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('DOOR|Nakładka zewnętrzna'),
                                            dimensionUnit(intFilling.panelThickness)
                                        );
                                    }
                                    if (intFilling.panelType === 'Double') {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('DOOR|Nakładka obustronna'),
                                            dimensionUnit(intFilling.panelThickness)
                                        );
                                    }
                                    if (intFilling.panelType === 'Inner') {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('DOOR|Nakładka wewnętrzna'),
                                            dimensionUnit(intFilling.panelThickness)
                                        );
                                    }
                                }
                                if (intFilling.panelGlazing && intFilling.panelGlazing?.name) {
                                    if (intFilling.pricePanel?.glazing && conf.prices?.show) {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('WINDOW|Szklenie'),
                                            ParserService.getChangedName(
                                                intFilling.panelGlazing,
                                                IccConfig.Configurators.fillingsProducers
                                                    && intFilling.panelGlazing?.producer
                                                    && IccConfig.Configurators.showFillingsProducers
                                                    ? ' ' + intFilling.panelGlazing?.producer
                                                    : null
                                            ),
                                            intFilling.pricePanel?.glazing
                                        );
                                    }
                                    else {
                                        ParserService.pushChild(
                                            this_filling,
                                            translate('WINDOW|Szklenie'),
                                            ParserService.getChangedName(
                                                intFilling.panelGlazing,
                                                IccConfig.Configurators.fillingsProducers
                                                    && intFilling.panelGlazing?.producer
                                                    && IccConfig.Configurators.showFillingsProducers
                                                    ? ' ' + intFilling.panelGlazing?.producer
                                                    : null
                                            )
                                        );
                                    }
                                }
                            }
                        });
                    }
                });
            }

            if (conf.type === 'door' && conf.system?.doorType) {
                Object.keys(conf.doorFillings).filter(key => conf.doorFillings[key]).forEach(key => {
                    const filling = conf.doorFillings[key];
                    let header = translate('DOOR|Wzór');
                    if (key === 'active') {
                        header = translate('WINDOW|Model skrzydła aktywnego');
                    } else if (key === 'activeInner') {
                        header = translate('WINDOW|Model wewnętrzny skrzydła aktywnego');
                    } else if (key === 'passive') {
                        header = translate('WINDOW|Model skrzydła biernego');
                    } else if (key === 'window') {
                        header = translate('WINDOW|Wypełnienie naświetli');
                    } else if (key === 'topLight') {
                        header = translate('WINDOW|Wypełnienie doświetla górnego');
                    } else if (key === 'leftLight') {
                        header = translate('WINDOW|Wypełnienie doświetla lewego');
                    } else if (key === 'rightLight') {
                        header = translate('WINDOW|Wypełnienie doświetla prawego');
                    }
                    const branch = ParserService.pushChild(group, header, null);
                    let price = false;

                    let value =
                        ' '
                        + ParserService.getChangedName(
                            filling,
                            IccConfig.Configurators.fillingsProducers
                                && filling?.producer
                                && IccConfig.Configurators.showFillingsProducers
                                ? filling?.producer
                                : null
                        );

                    if (filling?.type !== 'deco_panels' && filling?.type !== 'door_panels') {
                        value += filling?.u ? ' Ug = ' + filling?.u + ' W/m2*K' : '';

                        if (IccConfig.Offer.fillingSizes && conf.shape?.shape === 'rect') {
                            value +=
                                ' ('
                                + dimensionUnit(filling?.fillingSizes?.width)
                                + ' x '
                                + dimensionUnit(filling?.fillingSizes?.height)
                                + ')';
                        }
                    }

                    branch['value'] = value;


                    if (filling?.price
                            || (filling?.pricePanel?.all
                                && (filling?.type === 'deco_panels' || filling?.type === 'door_panels'))
                        && conf.prices?.show) {
                        price = filling?.price;
                    }

                    if (price) {
                        branch['$$price'] = price;
                    }

                    if (filling?.type === 'door_panels') {
                        if (!filling?.singleColor || key === 'active' || key === 'activeInner') {
                            if (key !== 'active') {
                                ParserService.pushChild(
                                    branch,
                                    key === 'activeInner' ? translate('WINDOW|Kolor') : translate('WINDOW|Wewnętrzny kolor'),
                                    ParserService.getChangedName(
                                        filling?.color?.inner
                                            ? filling?.color?.inner
                                            : filling?.color?.core
                                    )
                                );
                            }
                            if (key !== 'activeInner') {
                                ParserService.pushChild(
                                    branch,
                                    key === 'active' ? translate('WINDOW|Kolor') : translate('WINDOW|Zewnętrzny kolor'),
                                    ParserService.getChangedName(
                                        filling?.color?.outer
                                            ? filling?.color?.outer
                                            : filling?.color?.core
                                    )
                                );
                            }
                        } else {
                            ParserService.pushChild(
                                branch,
                                translate('WINDOW|Obustronny kolor'),
                                ParserService.getChangedName(
                                    filling?.color?.inner
                                        ? filling?.color?.inner
                                        : filling?.color?.core
                                )
                            );
                        }
                        if (filling?.overlayColor) {
                            if (!filling?.singleOverlayColor || key === 'active' || key === 'activeInner') {
                                if (key !== 'active' && filling?.overlayColor?.inner?.name) {
                                    ParserService.pushChild(
                                        branch,
                                        key === 'activeInner' ? translate('WINDOW|Kolor aplikacji') : translate('WINDOW|Wewnętrzny kolor aplikacji'),
                                        ParserService.getChangedName(
                                            filling?.overlayColor?.inner
                                                ? filling?.overlayColor?.inner
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                                if (key !== 'activeInner' && filling?.overlayColor?.outer?.name) {
                                    ParserService.pushChild(
                                        branch,
                                        key === 'active' ? translate('WINDOW|Kolor aplikacji') : translate('WINDOW|Zewnętrzny kolor aplikacji'),
                                        ParserService.getChangedName(
                                            filling?.overlayColor?.outer
                                                ? filling?.overlayColor?.outer
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                            } else {
                                if (filling?.overlayColor?.inner?.name) {
                                    ParserService.pushChild(
                                        branch,
                                        translate('WINDOW|Obustronny kolor aplikacji'),
                                        ParserService.getChangedName(
                                            filling?.overlayColor?.inner
                                                ? filling?.overlayColor?.inner
                                                : conf.colors?.frame?.core
                                        )
                                    );
                                }
                            }
                        }

                        if (filling?.panelGlazing
                            && filling?.panelGlazing?.name) {
                            if (filling?.pricePanel?.glazing && conf.prices?.show) {
                                ParserService.pushChild(
                                    branch,
                                    translate('WINDOW|Szklenie'),
                                    ParserService.getChangedName(
                                        filling?.panelGlazing,
                                        IccConfig.Configurators.fillingsProducers
                                            && filling?.panelGlazing?.producer
                                            && IccConfig.Configurators.showFillingsProducers
                                            ? ' ' + filling?.panelGlazing?.producer
                                            : null
                                    ),
                                    filling?.pricePanel?.glazing
                                );
                            }
                            else {
                                ParserService.pushChild(
                                    branch,
                                    translate('WINDOW|Szklenie'),
                                    ParserService.getChangedName(
                                        filling?.panelGlazing,
                                        IccConfig.Configurators.fillingsProducers
                                            && filling?.panelGlazing?.producer
                                            && IccConfig.Configurators.showFillingsProducers
                                            ? ' ' + filling?.panelGlazing?.producer
                                            : null
                                    )
                                );
                            }
                        }
                    }
                })

            }

            if (!IccConfig.Offer.warmEdgeInGlassName && conf.glazingSpacer) {
                const glazing_spacer = ParserService.pushChild(
                    group,
                    translate('WINDOW|Ramka'),
                    ParserService.getChangedName(conf.glazingSpacer),
                    conf.glazingSpacer?.price && conf.prices?.show ? conf.glazingSpacer?.price : null
                );
            }

            if (IccConfig.Offer.showGlazingBead && conf.glazingBead) {
                const glazing_spacer = ParserService.pushChild(
                    group,
                    translate('WINDOW|Listwa przyszybowa'),
                    conf.glazingBead?.hasOne
                        ? ParserService.getChangedName(
                            conf.glazingBead,
                            conf.glazingBead?.shape ? '(' + conf.glazingBead?.shape + ')' : null
                        )
                        : null,
                    conf.glazingBead?.price && conf.prices?.show ? conf.glazingBead?.price : null
                );

                if (!conf.glazingBead?.hasOne) {
                    conf.glazingBead?.sashes?.forEach((glazingBead, index) => {
                        if (glazingBead.hasOne) {
                            ParserService.pushChild(
                                glazing_spacer,
                                translate('WINDOW|Kwatera') + ' ' + glazingBead.sashIndex,
                                ParserService.getChangedName(
                                    glazingBead,
                                    '(' + glazingBead.shape + ')'
                                )
                            );
                        }
                        if (!glazingBead.hasOne) {
                            glazingBead.intSashes.forEach((iglazingBead, index) => {
                                ParserService.pushChild(
                                    glazing_spacer,
                                    translate('WINDOW|Kwatera')
                                    + ' '
                                    + iglazingBead.sashIndex
                                    + ' '
                                    + translate('WINDOW|Pole')
                                    + ' '
                                    + iglazingBead.isashIndex,
                                    ParserService.getChangedName(
                                        iglazingBead,
                                        '(' + iglazingBead.shape + ')'
                                    )
                                );
                            });
                        }
                    });
                }
            }

            if ((IccConfig.Configurators.sealColorSelect
                && IccConfig.Offer.showSeal
                && (conf.type !== 'door'
                    || (conf.type === 'door' && IccConfig.Offer.showSealInDoor)))
                || conf.sealColor?.price) {
                const seal = ParserService.pushChild(
                    group,
                    translate('WINDOW|Uszczelka'),
                    ParserService.getChangedName(conf.sealColor),
                    conf.sealColor && conf.sealColor?.price && conf.prices?.show
                        ? conf.sealColor?.price
                        : null
                );
            }

            if (IccConfig.Configurators.siliconeColor && conf.system?.type === 'wood') {
                const silicone = ParserService.pushChild(
                    group,
                    translate('WINDOW|Kolor silikonu'),
                    ParserService.getChangedName(conf.siliconeColor),
                    conf.siliconeColor && conf.siliconeColor?.price && conf.prices?.show
                        ? conf.siliconeColor?.price
                        : null
                );
            }

            if (conf.muntins && conf.muntins?.type) {
                const muntins = ParserService.pushChild(
                    group,
                    conf.muntins?.type?.name
                        ? ParserService.getChangedName(conf.muntins?.type)
                        : null,
                    dimensionUnit(conf.muntins?.size)
                );

                if (conf.muntins?.duplex && conf.muntins?.type?.duplex !== '-1') {
                    muntins['value'] += translate(`COLOR|z dupleksem`);
                }
                if (!conf.muntins?.duplex && conf.muntins?.type?.duplex !== '-1') {
                    muntins['value'] += translate(`COLOR|bez dupleksu`);
                }

                if (conf.colors?.type === 'Inner'
                    || conf.colors?.type === 'Outer'
                    || conf.colors?.type === 'Bicolor'
                    || conf.alushell?.has) {
                    const muntin_pos = ParserService.pushChild(
                        muntins,
                        translate('WINDOW|Kolor szprosów wew.'),
                        ''
                    );

                    if (conf.muntins?.colorInner?.name) {
                        muntin_pos['value'] += ParserService.getChangedName(
                            conf.muntins?.colorInner,
                            conf.muntins?.colorInner?.code || null
                        );
                    }
                    if (!conf.muntins?.colorInner?.name) {
                        muntin_pos['value'] += translate(`COLOR|Kolor zgodny z kolorem okna`);
                    }

                    if (conf.muntins?.price && conf.prices?.show) {
                        muntin_pos['$$price'] = conf.muntins?.price;
                    }
                }

                if (conf.colors?.type === 'Inner'
                    || conf.colors?.type === 'Outer'
                    || conf.colors?.type === 'Bicolor'
                    || conf.alushell?.has) {
                    const muntin_pos = ParserService.pushChild(
                        muntins,
                        translate('WINDOW|Kolor szprosów zew.'),
                        ''
                    );

                    if (conf.muntins?.colorOuter?.name) {
                        muntin_pos['value'] += ParserService.getChangedName(
                            conf.muntins?.colorOuter,
                            conf.muntins?.colorOuter?.code || null
                        );
                    }
                    if (!conf.muntins?.colorOuter?.name) {
                        muntin_pos['value'] += translate(`COLOR|Kolor zgodny z kolorem okna`);
                    }

                    if (conf.muntins?.price && conf.prices?.show) {
                        muntin_pos['$$price'] = conf.muntins?.price;
                    }
                }

                if (conf.colors?.type !== 'Inner'
                    && conf.colors?.type !== 'Outer'
                    && conf.colors?.type !== 'Bicolor'
                    && !conf.alushell?.has) {
                    const muntin_pos = ParserService.pushChild(
                        muntins,
                        translate('WINDOW|Kolor szprosów'),
                        null
                    );

                    if (conf.muntins?.colorInner?.name) {
                        muntin_pos['value'] = ParserService.getChangedName(
                            conf.muntins?.colorInner,
                            conf.muntins?.colorInner?.code || null
                        );
                    }

                    if (!conf.muntins?.colorInner?.name && conf.muntins?.colorOuter?.name) {
                        muntin_pos['value'] = ParserService.getChangedName(
                            conf.muntins?.colorOuter,
                            conf.muntins?.colorOuter?.code || null
                        );
                    }

                    if (!conf.muntins?.colorInner?.name && !conf.muntins?.colorOuter?.name) {
                        muntin_pos['value'] = translate('COLOR|Kolor zgodny z kolorem okna');
                    }

                    if (conf.muntins?.price && conf.prices?.show) {
                        muntin_pos['$$price'] = conf.muntins?.price;
                    }
                }

                if (!offersView.pdf || IccConfig.PDF.showMuntinsPositions) {
                    const muntin_pos = muntins;

                    conf.muntins?.sashes?.forEach((muntins, index) => {
                        if (!muntins.intSashes.length
                            && (muntins.vertical.length || muntins.horizontal.length)) {
                            const imuntin = ParserService.pushChild(
                                muntin_pos,
                                translate('WINDOW|Kwatera') + ' ' + muntins.sashIndex,
                                null
                            );

                            if (muntins.vertical.length) {
                                let imuntin_pos = ParserService.pushChild(
                                    imuntin,
                                    translate(`WINDOW|pionowe:`),
                                    ''
                                );;

                                muntins.vertical.forEach((muntin, mindex) => {
                                    if (mindex > 0) {
                                        imuntin_pos['value'] += ', ';
                                    }
                                    imuntin_pos['value'] += dimensionUnit(muntin.start?.x);
                                });


                            }

                            if (muntins.horizontal.length) {
                                let imuntin_pos = ParserService.pushChild(
                                    imuntin,
                                    translate(`WINDOW|poziome:`),
                                    ''
                                );

                                muntins.horizontal.forEach((muntin, mindex) => {
                                    if (mindex > 0) {
                                        imuntin_pos['value'] += ', ';
                                    }
                                    imuntin_pos['value'] += dimensionUnit(muntin.start?.y);
                                });
                            }
                        }

                        muntins.intSashes.forEach((imuntins, index) => {
                            if (muntins.intSashes.length
                                && (imuntins.vertical.length || imuntins.horizontal.length)) {
                                const imuntin = ParserService.pushChild(
                                    muntin_pos,
                                    translate('WINDOW|Kwatera') + ' ' + muntins.sashIndex,
                                    ''
                                );

                                if (muntins.intSashes.length > 1) {
                                    imuntin['value'] +=
                                        translate(`WINDOW|pole`) + ' ' + imuntins.isashIndex;
                                }

                                if (imuntins.vertical.length) {
                                    const iimuntin = ParserService.pushChild(
                                        imuntin,
                                        translate(`WINDOW|pionowe:`),
                                        ''
                                    );

                                    imuntins.vertical.forEach((muntin, mindex) => {
                                        if (mindex > 0) {
                                            iimuntin['value'] += ', ';
                                        }
                                        iimuntin['value'] += dimensionUnit(muntin.start?.x);
                                    });
                                }

                                if (imuntins.horizontal.length) {
                                    const iimuntin = ParserService.pushChild(
                                        imuntin,
                                        translate(`WINDOW|poziome:`),
                                        ''
                                    );
                                    imuntins.horizontal.forEach((muntin, mindex) => {
                                        if (mindex > 0) {
                                            iimuntin['value'] += ', ';
                                        }
                                        iimuntin['value'] += dimensionUnit(muntin.start?.y);
                                    });
                                }
                            }
                        });
                    });
                }
            }
        }
    }

    private static setFitting(conf: any, IccConfig: IIccConfig, details: { $$children: any[]; }, translate: (s: string) => string, dimensionUnit: (s: any) => string) {
        if ((conf.type === 'door'
            || conf.handles
            || (conf.sashesTypes
                && conf.sashesTypes?.type !== 'Fix'
                && conf.sashesTypes?.type !== 'FixSash')
            || (conf.sashesTypes && conf.sashesTypes?.price && conf.prices?.show)
            || (IccConfig.Configurators.constructionLimitation
                && conf.reinforcement
                && (!conf.reinforcement?.hasOne || conf.reinforcement?.show)))
            && conf.sashes?.has) {
            const fittings_group = ParserService.pushChild(
                details,
                conf.type !== 'door'
                    ? translate('WINDOW|Okucia i klamki')
                    : conf.system?.doorType ? translate(`DOOR|Osprzęt`) : translate(`WINDOW|Okucia`),
                null
            );

            if (conf.type !== 'door') {
                if (conf.handles) {
                    const handles = ParserService.pushChild(
                        fittings_group,
                        translate(`WINDOW|Klamki`),
                        conf.handles?.hasOne && conf.handles?.oneHeight
                            ? ParserService.getChangedName(conf.handles?.inner)
                            + ', '
                            + ParserService.getChangedName(conf.handles?.inner?.color)
                            + (conf.handles?.height
                                ? ', '
                                + dimensionUnit(conf.handles?.height)
                                + ' '
                                + translate('WINDOW|od dołu ramy')
                                : '')
                            : '',
                        conf.handles?.price?.all && conf.prices?.show ? conf.handles?.price?.all : null
                    );

                    if (!conf.handles?.hasOne || !conf.handles?.oneHeight) {
                        conf.handles?.sashes?.forEach((handle, index) => {
                            const ihandle = ParserService.pushChild(
                                handles,
                                translate('WINDOW|Kwatera') + ' ' + handle.sashIndex,
                                ParserService.getChangedName(handle.inner)
                                + ' '
                                + ParserService.getChangedName(handle.inner.color)
                            );

                            if (handle.height) {
                                ihandle['value'] +=
                                    ' '
                                    + dimensionUnit(handle.height)
                                    + ' '
                                    + translate('WINDOW|od dołu ramy');
                            }

                            if (handle.price.all && conf.prices?.show) {
                                ihandle['$$price'] = handle.price.all;
                            }
                        });
                    }
                }

                if (conf.sashesTypes?.type !== 'Fix' && conf.sashesTypes?.type !== 'FixSash') {
                    ParserService.pushChild(
                        fittings_group,
                        translate(`WINDOW|Okucia`),
                        ParserService.getChangedName(conf.fitting),
                        conf.fitting?.price && conf.prices?.show ? conf.fitting?.price : null
                    );
                }

                conf.sashesTypes?.elems?.forEach(sashType => {
                    if (conf.sashesTypes?.elems?.length && sashType.price && conf.prices?.show) {
                        ParserService.pushChild(
                            fittings_group,
                            sashType.name,
                            null,
                            sashType.price
                        );
                    }
                });

                if (IccConfig.Configurators.constructionLimitation
                    && conf.reinforcement
                    && (!conf.reinforcement?.hasOne || conf.reinforcement?.show)) {
                    const reinforcements = ParserService.pushChild(
                        fittings_group,
                        translate(`WINDOW|Wzmocnienia konstrukcji`),
                        ''
                    );

                    if (conf.reinforcement?.hasOne && conf.reinforcement?.name) {
                        reinforcements['value'] += conf.reinforcement?.name;
                    }

                    if (conf.reinforcement?.hasOne
                        && conf.reinforcement
                        && !conf.reinforcement?.name) {
                        reinforcements['value'] += translate(
                            `WINDOW|Konstrukcja niemożliwa do wykonania ze względu na niewłaściwe wymiary`
                        );
                    }

                    if (conf.reinforcement?.hasOne && !conf.reinforcement) {
                        reinforcements['value'] += translate(
                            `WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji`
                        );
                    }

                    if (!conf.reinforcement?.hasOne) {
                        conf.reinforcement?.sashes?.forEach((reinforcement, index) => {
                            const this_reinforcement = ParserService.pushChild(
                                reinforcements,
                                translate(`WINDOW|Kwatera`) + reinforcement.sashIndex,
                                ''
                            );

                            if (reinforcement.name) {
                                this_reinforcement['value'] += reinforcement.name;
                            }

                            if (reinforcement && !reinforcement.name) {
                                this_reinforcement['value'] += translate(
                                    `WINDOW|Konstrukcja niemożliwa do wykonania ze względu na niewłaściwe wymiary`
                                );
                            }

                            if (!reinforcement) {
                                this_reinforcement['value'] += translate(
                                    `WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji`
                                );
                            }
                        });
                    }
                }
            }
            else if (conf.type === 'door') {
                if (conf.handles && (!IccConfig.Configurators.door.advancedHardware || conf.doorHardware?.type)) {
                    const handles = ParserService.pushChild(fittings_group, null, '');

                    if (conf.handles?.type === 'DoubleLever') {
                        handles['name'] = translate(`DOOR|Klamka dwustronna`);
                    }
                    if (conf.handles?.type === 'LeverPull') {
                        handles['name'] = translate(`DOOR|Klamka wewnętrzna/antaba na zewnątrz`);
                    }
                    if (conf.handles?.type === 'DoublePull') {
                        handles['name'] = translate(`DOOR|Antaba obustronna`);
                    }

                    conf.handles.hasOne = false;

                    if (conf.handles?.hasOne) {
                        if (conf.handles?.price?.all && conf.prices?.show) {
                            handles['price'] = conf.handles?.price?.all;
                        }

                        if (conf.handles?.type === 'DoubleLever') {
                            handles['value'] += ParserService.getChangedName(conf.handles?.inner);

                            ParserService.pushChild(
                                handles,
                                translate(`COLOR|kolor zewn.`),
                                ParserService.getChangedName(conf.handles?.outer?.color)
                            );
                            ParserService.pushChild(
                                handles,
                                translate(`COLOR|kolor wewn.`),
                                ParserService.getChangedName(conf.handles?.inner?.color)
                            );
                        }
                        if (['LeverPull', 'DoublePull'].includes(conf.handles?.type)) {
                            ParserService.pushChild(
                                handles,
                                translate(`COLOR|kolor`),
                                ParserService.getChangedName(conf.handles?.outer?.color)
                            );
                            ParserService.pushChild(
                                handles,
                                translate(`COLOR|kolor`),
                                ParserService.getChangedName(conf.handles?.inner?.color)
                            );
                        }
                    }
                    else if (!conf.handles?.hasOne) {
                        conf.handles?.sashes?.forEach((handle, index) => {
                            const this_handle = ParserService.pushChild(handles, null, '');

                            if (handle.price.all && conf.prices?.show) {
                                this_handle['price'] = handle.price.all;
                            }

                            this_handle['name'] =
                                translate('WINDOW|Kwatera') + ' ' + handle.sashIndex;

                            if (handle.type === 'DoubleLever') {
                                this_handle['value'] += ParserService.getChangedName(handle.inner);

                                ParserService.pushChild(
                                    this_handle,
                                    translate(`COLOR|kolor zewn.`),
                                    ParserService.getChangedName(handle.outer.color)
                                );
                                ParserService.pushChild(
                                    this_handle,
                                    translate(`COLOR|kolor wewn.`),
                                    ParserService.getChangedName(handle.inner.color)
                                );
                            }
                            if (['LeverPull', 'DoublePull'].includes(handle.type)) {
                                ParserService.pushChild(
                                    this_handle,
                                    translate(`COLOR|kolor`),
                                    ParserService.getChangedName(handle.outer.color)
                                );
                                ParserService.pushChild(
                                    this_handle,
                                    translate(`COLOR|kolor`),
                                    ParserService.getChangedName(handle.inner.color)
                                );
                            }
                        });
                    }
                }

                if (conf.hinge && !conf.system?.doorType) {
                    //TODO SPRAWDŹ
                    ParserService.pushChild(
                        fittings_group,
                        translate(`DOOR|Zawias`),
                        ParserService.getChangedName(conf.hinge)
                        + ', '
                        + translate(`COLOR|kolor`)
                        + ' '
                        + ParserService.getChangedName(conf.hinge?.color),
                        conf.hinge?.price && conf.prices?.show ? conf.hinge?.price : null
                    );
                }

                if (conf.lock && !conf.system?.doorType) {
                    //TODO SPRAWDŹ
                    ParserService.pushChild(
                        fittings_group,
                        translate(`DOOR|Zamek`),
                        ParserService.getChangedName(conf.lock),
                        conf.lock?.price && conf.prices?.show ? conf.lock?.price : null
                    );
                }

                if (conf.hinge) {
                    ParserService.pushChild(
                        fittings_group,
                        translate(`DOOR|Zawias`),
                        ParserService.getChangedName(conf.hinge),
                        conf.hinge?.price && conf.hinge?.show ? conf.hinge?.price : null
                    );
                }

                if(conf.hinge?.color) {
                    ParserService.pushChild(
                        fittings_group,
                        translate('DOOR|Kolor zawiasu'),
                        ParserService.getChangedName(conf.hinge?.color),
                        null
                    );
                }

                if (conf.lock) {
                    ParserService.pushChild(
                        fittings_group,
                        translate(`DOOR|Zamek`),
                        ParserService.getChangedName(conf.lock),
                        conf.lock?.price && conf.prices?.show ? conf.lock?.price : null
                    );
                }

                if (conf.doorHardware) {
                    if (conf.doorHardware?.doorHandleAdjustment) {
                        ParserService.pushChild(
                            fittings_group,
                            translate(`DOOR|Przystosowanie pod pochwyt`),
                            ParserService.getChangedName(conf.doorHardware?.doorHandleAdjustment),
                            conf.doorHardware?.doorHandleAdjustment?.price && conf.prices?.show ? conf.doorHardware?.doorHandleAdjustment?.price : null
                        );
                    }

                    if (conf.doorHardware?.type) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ osprzętu'),
                            ParserService.getChangedName(conf.doorHardware?.type),
                            conf.doorHardware?.type?.price && conf.prices?.show ? conf.doorHardware?.type?.price : null
                        );
                    }

                    if (conf.doorHardware?.set) {
                        const hardwareSetGroup = ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Zestaw osprzętu'),
                            ''
                        );

                        switch (conf.doorHardware?.set) {
                            case 'doubleLever':
                                hardwareSetGroup['value'] = translate('DOOR|Klamka - klamka');
                                break;
                            case 'leverKnob':
                                hardwareSetGroup['value'] = translate('DOOR|Klamka - gałka');
                                break;
                            case 'leverPull':
                                hardwareSetGroup['value'] = translate('DOOR|Klamka - pochwyt');
                                break;
                            default:
                                hardwareSetGroup['value'] = translate('INTERFACE|Brak');
                                break;
                        }
                    }

                    if (conf.doorHardware?.pull) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ pochwytu'),
                            ParserService.getChangedName(conf.doorHardware?.pull),
                            conf.doorHardware?.pull?.price && conf.prices?.show ? conf.doorHardware?.pull?.price : null
                        );
                    }

                    if (conf.doorHardware.pull?.color) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Kolor pochwytu'),
                            ParserService.getChangedName(conf.doorHardware.pull?.color),
                            null
                        );
                    }

                    if (conf.doorHardware?.electronicLock) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ elektronicznej wkładki'),
                            ParserService.getChangedName(conf.doorHardware?.electronicLock),
                            conf.doorHardware?.electronicLock?.price && conf.prices?.show ? conf.doorHardware?.electronicLock?.price : null
                        );
                    }

                    if (conf.doorHardware?.color) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Kolor osprzętu:'),
                            ParserService.getChangedName(conf.doorHardware?.color),
                            null
                        );
                    }

                    if (conf.doorHardware?.plateType) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ oszyldowania'),
                            ParserService.getChangedName(conf.doorHardware?.plateType),
                            conf.doorHardware?.plateType?.price && conf.prices?.show ? conf.doorHardware?.plateType?.price : null
                        );
                    }

                    if (conf.doorHardware?.doorViewer) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Wizjer'),
                            ParserService.getChangedName(conf.doorHardware?.doorViewer),
                            conf.doorHardware?.doorViewer?.price && conf.prices?.show ? conf.doorHardware?.doorViewer?.price : null
                        );
                    }

                    if (conf.doorHardware?.doorViewerPunching) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Otworowanie pod wizjer'),
                            ''
                        );
                    }

                    if (conf.doorHardware?.plates?.mainLock) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ szyldu zamka głównego'),
                            ParserService.getChangedName(conf.doorHardware?.plates?.mainLock),
                            conf.doorHardware?.plates?.mainLock?.price && conf.prices?.show ? conf.doorHardware?.plates?.mainLock?.price : null
                        );
                    }

                    if (conf.doorHardware?.plates?.additionalLock) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Typ szyldu zamka dodatkowego'),
                            ParserService.getChangedName(conf.doorHardware?.plates?.additionalLock),
                            conf.doorHardware?.plates?.additionalLock?.price && conf.prices?.show ? conf.doorHardware?.plates?.additionalLock?.price : null
                        );
                    }

                    if (conf.doorHardware?.plates?.color) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Kolor szyldu'),
                            ParserService.getChangedName(conf.doorHardware?.plates?.color),
                            null
                        );
                    }

                    if (conf.doorHardware?.cylinders?.mainLock) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Rodzaj wkładki zamka głównego'),
                            ParserService.getChangedName(conf.doorHardware?.cylinders?.mainLock),
                            conf.doorHardware?.cylinders?.mainLock?.price && conf.prices?.show ? conf.doorHardware?.cylinders?.mainLock?.price : null
                        );
                    }

                    if (
                        conf.doorHardware?.cylinders?.mainLock?.isKeyAvailableForMainLock &&
                        conf.doorHardware?.cylinders?.mainLock?.keyQuantityForMainLock > 0
                    ) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Ilość kluczy dodatkowych do zamka głównego'),
                            conf.doorHardware?.cylinders?.mainLock?.keyQuantityForMainLock
                        );
                    }
                    
                    if (conf.doorHardware?.cylinders?.additionalLock) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Rodzaj wkładki zamka dodatkowego'),
                            ParserService.getChangedName(conf.doorHardware?.cylinders?.additionalLock),
                            conf.doorHardware?.cylinders?.additionalLock?.price && conf.prices?.show ? conf.doorHardware?.cylinders?.additionalLock?.price : null
                        );
                    }

                    if (
                        conf.doorHardware?.cylinders?.additionalLock?.isKeyAvailableForAdditionalLock &&
                        conf.doorHardware?.cylinders?.additionalLock?.keyQuantityForAdditionalLock > 0
                    ) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Ilość kluczy dodatkowych do zamka dodatkowego'),
                            conf.doorHardware?.cylinders?.additionalLock?.keyQuantityForAdditionalLock
                        );
                    }
                        
                    if (conf.doorHardware?.cylinders?.system) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|System wkładek'),
                            ParserService.getChangedName(conf.doorHardware?.cylinders?.system),
                            conf.doorHardware?.cylinders?.system?.price && conf.prices?.show ? conf.doorHardware?.cylinders?.system?.price : null
                        );
                    }

                    if (conf.doorHardware?.decorativeHingeCovers) {
                        ParserService.pushChild(
                            fittings_group,
                            translate('DOOR|Ozdobne nakładki zawiasów'),
                            ParserService.getChangedName(conf.doorHardware?.decorativeHingeCovers),
                            conf.doorHardware?.decorativeHingeCovers?.price && conf.prices?.show ? conf.doorHardware?.decorativeHingeCovers?.price : null
                        );
                    }
                }

                if (IccConfig.Configurators.constructionLimitation
                    && conf.reinforcement
                    && (!conf.reinforcement?.hasOne || conf.reinforcement?.show)) {
                    const reinforcements = ParserService.pushChild(
                        fittings_group,
                        translate(`WINDOW|Wzmocnienia konstrukcji`),
                        ''
                    );

                    if (conf.reinforcement?.hasOne && conf.reinforcement?.name) {
                        reinforcements['value'] += conf.reinforcement?.name;
                    }

                    if (conf.reinforcement?.hasOne
                        && conf.reinforcement
                        && !conf.reinforcement?.name) {
                        reinforcements['value'] += translate(
                            `WINDOW|Konstrukcja niemożliwa do wykonania ze względu na niewłaściwe wymiary`
                        );
                    }

                    if (conf.reinforcement?.hasOne && !conf.reinforcement) {
                        reinforcements['value'] += translate(
                            `WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji`
                        );
                    }

                    if (!conf.reinforcement?.hasOne) {
                        conf.reinforcement?.sashes?.forEach((reinforcement, index) => {
                            const this_reinforcement = ParserService.pushChild(
                                reinforcements,
                                translate(`WINDOW|Kwatera`) + reinforcement.sashIndex,
                                ''
                            );

                            if (reinforcement.name) {
                                this_reinforcement['value'] += reinforcement.name;
                            }

                            if (reinforcement && !reinforcement.name) {
                                this_reinforcement['value'] += translate(
                                    `WINDOW|Konstrukcja niemożliwa do wykonania ze względu na niewłaściwe wymiary`
                                );
                            }

                            if (!reinforcement) {
                                this_reinforcement['value'] += translate(
                                    `WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji`
                                );
                            }
                        });
                    }
                }
            }
        }
    }

    private static setAccessories(conf: any, details: { $$children: any[]; }, translate: (s: string) => string, dimensionUnit: (s: any) => string, IccConfig: IIccConfig) {
        if (conf.accessories?.sashes?.length
            || conf.accessories?.construction?.length
            || conf.accessories?.side?.top?.length
            || conf.accessories?.side?.right?.length
            || conf.accessories?.side?.bottom?.length
            || conf.accessories?.side?.left?.length
            || (conf.kicker && conf.kicker?.has)
            || conf.mosquitos
            || conf.windowSills
            || conf.profiles
            || conf.cassonettos) {
            const accessories = ParserService.pushChild(
                details,
                translate(`ACCESSORY|Dodatki`),
                null,
                conf.accessories?.price
                    + (conf.kicker ? conf.kicker?.price : 0)
                    + (conf.mosquitos ? conf.mosquitos?.price : 0)
                    + (conf.windowSills ? conf.windowSills?.price : 0)
                    + (conf.profiles ? conf.profiles?.price : 0)
                    + (conf.cassonettos ? conf.cassonettos?.price : 0) && conf.prices?.show
                    ? conf.accessories?.price
                    + (conf.kicker ? conf.kicker?.price : 0)
                    + (conf.mosquitos ? conf.mosquitos?.price : 0)
                    + (conf.windowSills ? conf.windowSills?.price : 0)
                    + (conf.profiles ? conf.profiles?.price : 0)
                    + (conf.cassonettos ? conf.cassonettos?.price : 0)
                    : null
            );

            if (conf.windowSills) {
                const window_sills = ParserService.pushChild(
                    accessories,
                    translate(`GOODS|Parapety`),
                    null
                );

                conf.windowSills?.elems?.forEach((windowSill, index) => {
                    const sill = ParserService.pushChild(
                        window_sills,
                        ParserService.getChangedName(windowSill),
                        windowSill.count + translate(`DIMENSION|szt.`)
                    );

                    if (windowSill.width) {
                        sill['value'] +=
                            ' '
                            + translate('DIMENSION|Szerokość')
                            + ': '
                            + dimensionUnit(windowSill.width);
                    }

                    if (windowSill.length) {
                        sill['value'] +=
                            ' '
                            + translate('DIMENSION|Długość')
                            + ': '
                            + dimensionUnit(windowSill.length);
                    }

                    if (windowSill.plugs) {
                        sill['value'] +=
                            ' '
                            + translate('GOODS|Zaślepki PCV')
                            + ': '
                            + translate('GOODS|Standard');
                    }

                    if (windowSill.color.name) {
                        sill['value'] +=
                            ' '
                            + translate('COLOR|Kolor')
                            + ': '
                            + ParserService.getChangedName(windowSill.color);
                    }

                    if (windowSill.comment) {
                        sill['value'] +=
                            ' ' + translate('INTERFACE|Komentarz') + ': ' + windowSill.comment;
                    }

                    if (windowSill.price && conf.prices?.show) {
                        sill['$$price'] = windowSill.price;
                    }
                });
            }

            if (conf.cassonettos) {
                const cassonettos = ParserService.pushChild(
                    accessories,
                    translate(`GOODS|Kasonetki`),
                    null
                );

                conf.cassonettos?.elems?.forEach((cassonetto, index) => {
                    const this_cassonetto = ParserService.pushChild(
                        cassonettos,
                        ParserService.getChangedName(cassonetto),
                        cassonetto.count + translate(`DIMENSION|szt.`)
                    );

                    if (cassonetto.color.name) {
                        this_cassonetto['value'] +=
                            ' '
                            + translate('COLOR|Kolor')
                            + ': '
                            + ParserService.getChangedName(cassonetto.color);
                    }

                    if (cassonetto.price && conf.prices?.show) {
                        this_cassonetto['$$price'] = cassonetto.price;
                    }

                    if (cassonetto.width) {
                        ParserService.pushChild(
                            this_cassonetto,
                            translate('DIMENSION|Szerokość')
                            + ': '
                            + dimensionUnit(cassonetto.width)
                            + ' '
                            + translate('DIMENSION|Wysokość')
                            + ': '
                            + dimensionUnit(cassonetto.height),
                            null
                        );
                    }

                    if (cassonetto.l1 || cassonetto.p1 || cassonetto.p2) {
                        ParserService.pushChild(
                            this_cassonetto,
                            cassonetto.l1
                                ? 'L1: ' + dimensionUnit(cassonetto.l1)
                                : '' + cassonetto.p1
                                    ? 'P1: ' + dimensionUnit(cassonetto.p1)
                                    : '' + cassonetto.p2
                                        ? 'P2: ' + dimensionUnit(cassonetto.p2)
                                        : '',
                            null
                        );
                    }
                });
            }

            if (conf.profiles) {
                const profiles = ParserService.pushChild(
                    accessories,
                    translate(`WINDOW|Profile dodatkowe`),
                    null
                );

                conf.profiles?.elems?.forEach((access, index) => {
                    const profile = ParserService.pushChild(
                        profiles,
                        (IccConfig.Configurators.windowAccessoriesManufacturerCode
                            ? access.code
                            : '')
                        + ParserService.getChangedName(access)
                        + access.count
                        + translate('DIMENSION|szt.')
                        + '×'
                        + dimensionUnit(access.length),
                        ''
                    );

                    if (access.wood) {
                        profile['value'] +=
                            translate('COLOR|Drewno')
                            + ' '
                            + ParserService.getChangedName(access.wood)
                            + ' ';
                    }

                    if (access.comment) {
                        profile['value'] +=
                            translate('INTERFACE|Komentarz') + ': ' + access.comment + ' ';
                    }

                    if (access.color?.profile) {
                        if (access.color?.profile?.outer && access.color?.profile?.outer?.name) {
                            ParserService.pushChild(
                                profile,
                                translate('COLOR|Kolor zewn.'),
                                ParserService.getChangedName(access.color?.profile?.outer)
                            );
                        }
                        if (access.color?.profile?.inner && access.color?.profile?.inner?.name) {
                            ParserService.pushChild(
                                profile,
                                translate('COLOR|Kolor wewn.'),
                                ParserService.getChangedName(access.color?.profile?.inner)
                            );
                        }
                        if (access.color?.profile?.core
                            && access.color?.profile?.core?.name
                            && IccConfig.Configurators
                                .showCoreColorInAdditionalProfilesAndAccessories) {
                            ParserService.pushChild(
                                profile,
                                translate('WINDOW|Rdzeń'),
                                ParserService.getChangedName(access.color?.profile?.core)
                            );
                        }
                    }
                });
            }

            if (conf.accessories?.construction?.length) {
                const selected = ParserService.pushChild(
                    accessories,
                    translate(`ACCESSORY|Wybrane akcesoria`),
                    null
                );

                conf.accessories?.construction?.forEach((access, index) => {
                    const this_access = ParserService.pushChild(
                        selected,
                        (IccConfig.Configurators.windowAccessoriesManufacturerCode
                            ? access.code
                            : '') + ParserService.getChangedName(access),
                        '',
                        access.price && conf.prices?.show ? access.price : null
                    );

                    this_access['value'] += access.count || 1 + translate(`DIMENSION|szt.`);

                    if (access.wood === 'colors') {
                        this_access['value'] +=
                            translate('COLOR|Drewno')
                            + ' '
                            + ParserService.getChangedName(access.wood)
                            + ' ';
                    }

                    if (access.comment) {
                        this_access['value'] +=
                            translate('INTERFACE|Komentarz') + ': ' + access.comment + ' ';
                    }

                    if (access.priceType === 1) {
                        this_access['value'] +=
                            ' × '
                            + dimensionUnit(access.amount)
                            + ' '
                            + dimensionUnit(access.width)
                            + ' x '
                            + dimensionUnit(access.height);
                    }
                    if (access.priceType === 2) {
                        this_access['value'] += ' × ' + dimensionUnit(access.amount);
                    }
                    if (access.priceSource === 'table'
                        && access.colorSet
                        && access.type !== 'windowsill') {
                        this_access['value'] += ' ' + translate('COLOR|Kolor') + ':';

                        if (access.colorSet === 'white') {
                            this_access['value'] += ' ' + translate(`COLOR|Biały`);
                        }
                        if (access.colorSet === 'color-white') {
                            this_access['value'] +=
                                ' ' + translate(`COLOR|Kolor`) + '\\' + translate(`COLOR|Biały`);
                        }
                        if (access.colorSet === 'color-color') {
                            this_access['value'] +=
                                ' ' + translate(`COLOR|Kolor`) + '\\' + translate(`COLOR|Kolor`);
                        }
                        if (access.colorSet === 'ral') {
                            this_access['value'] += ' ' + translate(`COLOR|RAL`);
                        }
                    }
                });
            }

            conf.accessories?.sashes?.forEach((sash, index) => {
                const sash_accessories = ParserService.pushChild(
                    accessories,
                    translate(`ACCESSORY|Dodatki do kwatery ID`) + ' ' + sash.sashIndex,
                    null
                );

                sash.accessories.forEach((access, index) => {
                    const this_access = ParserService.pushChild(
                        sash_accessories,
                        (IccConfig.Configurators.windowAccessoriesManufacturerCode
                            ? access.code
                            : '') + ParserService.getChangedName(access),
                        '',
                        access.price && conf.prices?.show ? access.price : null
                    );

                    this_access['value'] += access.count + translate(`DIMENSION|szt.`);

                    if (access.wood === 'colors') {
                        this_access['value'] +=
                            translate('COLOR|Drewno')
                            + ' '
                            + ParserService.getChangedName(access.wood)
                            + ' ';
                    }

                    if (access.comment) {
                        this_access['value'] +=
                            translate('INTERFACE|Komentarz') + ': ' + access.comment + ' ';
                    }

                    if (access.priceType === 1) {
                        this_access['value'] +=
                            ' × '
                            + dimensionUnit(access.amount)
                            + ' '
                            + dimensionUnit(access.width)
                            + ' x '
                            + dimensionUnit(access.height);
                    }
                    if (access.priceType === 2) {
                        this_access['value'] += ' × ' + dimensionUnit(access.amount);
                    }
                    if (access.priceSource === 'table'
                        && access.colorSet
                        && access.type !== 'windowsill') {
                        this_access['value'] += ' ' + translate('COLOR|Kolor') + ':';

                        if (access.colorSet === 'white') {
                            this_access['value'] += ' ' + translate(`COLOR|Biały`);
                        }
                        if (access.colorSet === 'color-white') {
                            this_access['value'] +=
                                ' ' + translate(`COLOR|Kolor`) + '\\' + translate(`COLOR|Biały`);
                        }
                        if (access.colorSet === 'color-color') {
                            this_access['value'] +=
                                ' ' + translate(`COLOR|Kolor`) + '\\' + translate(`COLOR|Kolor`);
                        }
                        if (access.colorSet === 'ral') {
                            this_access['value'] += ' ' + translate(`COLOR|RAL`);
                        }
                    }
                    if (access.priceSource === 'colors') {
                        this_access['value'] +=
                            ' '
                            + translate('COLOR|Kolor')
                            + ': '
                            + ParserService.getChangedName(access.color?.accessory);
                    }

                    if (access.priceSource === 'confColors') {
                        if (access.color?.profile?.outer?.name) {
                            ParserService.pushChild(
                                this_access,
                                translate('COLOR|Kolor zewn.'),
                                ParserService.getChangedName(access.color?.profile?.outer)
                            );
                        }
                        if (access.color?.profile?.inner?.name) {
                            ParserService.pushChild(
                                this_access,
                                translate('COLOR|Kolor wewn.'),
                                ParserService.getChangedName(access.color?.profile?.inner)
                            );
                        }
                        if (access.color?.profile?.core?.name
                            && IccConfig.Configurators
                                .showCoreColorInAdditionalProfilesAndAccessories) {
                            ParserService.pushChild(
                                this_access,
                                translate('WINDOW|Rdzeń'),
                                ParserService.getChangedName(access.color?.profile?.core)
                            );
                        }
                        if (access.color?.profile?.core?.name
                            && IccConfig.Configurators
                                .showCoreColorInAdditionalProfilesAndAccessories) {
                            ParserService.pushChild(
                                this_access,
                                translate('WINDOW|Rdzeń'),
                                ParserService.getChangedName(access.color?.profile?.core)
                            );
                        }
                        if (access.wood === 'colors') {
                            ParserService.pushChild(
                                this_access,
                                translate('COLOR|Drewno'),
                                ParserService.getChangedName(access.wood)
                            );
                        }
                    }
                });
            });

            for (const key in conf.accessories?.side) {
                if (conf.accessories?.side?.hasOwnProperty(key)) {
                    const element = conf.accessories?.side?.[key];

                    if (element.length > 0) {
                        const side_accessories = ParserService.pushChild(accessories, null, null);

                        if (key === 'left')
                            side_accessories['name'] = translate(
                                'ACCESSORY|Dodatki do krawędzi lewej'
                            );
                        if (key === 'right')
                            side_accessories['name'] = translate(
                                'ACCESSORY|Dodatki do krawędzi prawej'
                            );
                        if (key === 'top')
                            side_accessories['name'] = translate(
                                'ACCESSORY|Dodatki do krawędzi górnej'
                            );
                        if (key === 'bottom')
                            side_accessories['name'] = translate(
                                'ACCESSORY|Dodatki do krawędzi dolnej'
                            );

                        element.forEach((access, index) => {
                            const this_access = ParserService.pushChild(
                                side_accessories,
                                (IccConfig.Configurators.windowAccessoriesManufacturerCode
                                    ? access.code
                                    : '') + ParserService.getChangedName(access),
                                '',
                                access.price && conf.prices?.show ? access.price : null
                            );

                            this_access['value'] += access.count + translate(`DIMENSION|szt.`);

                            if (access.wood === 'colors') {
                                this_access['value'] +=
                                    translate('COLOR|Drewno')
                                    + ' '
                                    + ParserService.getChangedName(access.wood)
                                    + ' ';
                            }

                            if (access.comment) {
                                this_access['value'] +=
                                    translate('INTERFACE|Komentarz') + ': ' + access.comment + ' ';
                            }

                            if (access.priceType === 1) {
                                this_access['value'] +=
                                    ' × '
                                    + dimensionUnit(access.amount)
                                    + ' '
                                    + dimensionUnit(access.width)
                                    + ' x '
                                    + dimensionUnit(access.height);
                            }
                            if (access.priceType === 2) {
                                this_access['value'] += ' × ' + dimensionUnit(access.amount);
                            }
                            if (access.priceSource === 'table'
                                && access.colorSet
                                && access.type !== 'windowsill') {
                                this_access['value'] += ' ' + translate('COLOR|Kolor') + ':';

                                if (access.colorSet === 'white') {
                                    this_access['value'] += ' ' + translate(`COLOR|Biały`);
                                }
                                if (access.colorSet === 'color-white') {
                                    this_access['value'] +=
                                        ' '
                                        + translate(`COLOR|Kolor`)
                                        + '\\'
                                        + translate(`COLOR|Biały`);
                                }
                                if (access.colorSet === 'color-color') {
                                    this_access['value'] +=
                                        ' '
                                        + translate(`COLOR|Kolor`)
                                        + '\\'
                                        + translate(`COLOR|Kolor`);
                                }
                                if (access.colorSet === 'ral') {
                                    this_access['value'] += ' ' + translate(`COLOR|RAL`);
                                }
                            }
                            if (access.priceSource === 'colors') {
                                this_access['value'] +=
                                    ' '
                                    + translate('COLOR|Kolor')
                                    + ': '
                                    + ParserService.getChangedName(access.color?.accessory);
                            }

                            if (access.priceSource === 'confColors') {
                                if (access.color?.profile?.outer?.name) {
                                    ParserService.pushChild(
                                        this_access,
                                        translate('COLOR|Kolor zewn.'),
                                        ParserService.getChangedName(access.color?.profile?.outer)
                                    );
                                }
                                if (access.color?.profile?.inner?.name) {
                                    ParserService.pushChild(
                                        this_access,
                                        translate('COLOR|Kolor wewn.'),
                                        ParserService.getChangedName(access.color?.profile?.inner)
                                    );
                                }
                                if (access.color?.profile?.core?.name
                                    && IccConfig.Configurators
                                        .showCoreColorInAdditionalProfilesAndAccessories) {
                                    ParserService.pushChild(
                                        this_access,
                                        translate('WINDOW|Rdzeń'),
                                        ParserService.getChangedName(access.color?.profile?.core)
                                    );
                                }
                                if (access.color?.profile?.core?.name
                                    && IccConfig.Configurators
                                        .showCoreColorInAdditionalProfilesAndAccessories) {
                                    ParserService.pushChild(
                                        this_access,
                                        translate('WINDOW|Rdzeń'),
                                        ParserService.getChangedName(access.color?.profile?.core)
                                    );
                                }
                                if (access.wood === 'colors') {
                                    ParserService.pushChild(
                                        this_access,
                                        translate('COLOR|Drewno'),
                                        ParserService.getChangedName(access.wood)
                                    );
                                }
                            }
                        });
                    }
                }
            }

            if (conf.kicker && conf.kicker?.has) {
                ParserService.pushChild(
                    accessories,
                    translate(`DOOR|Kopacz`),
                    translate(`INTERFACE|Tak`),
                    conf.kicker?.price && conf.prices?.show ? conf.kicker?.price : null
                );
            }

            if (conf.mosquitos) {
                const mosquitos = ParserService.pushChild(
                    accessories,
                    translate(`MOSQUITO|Siatki`),
                    null
                );

                conf.mosquitos?.mosquitos?.forEach((mosquito, index) => {
                    const this_mosquito = ParserService.pushChild(
                        mosquitos,
                        translate(`MOSQUITO|Siatka`) + ' ' + (index + 1),
                        null,
                        mosquito.price && conf.prices?.show ? mosquito.price : null
                    );

                    ParserService.pushChild(
                        this_mosquito,
                        translate(`CONFIGURATOR|Linia`),
                        ParserService.getChangedName(mosquito.system)
                    );

                    ParserService.pushChild(
                        this_mosquito,
                        translate(`DIMENSION|Wymiary`),
                        dimensionUnit(mosquito.width) + ' x ' + dimensionUnit(mosquito.height)
                    );

                    const mosquito_colors = ParserService.pushChild(
                        this_mosquito,
                        translate(`COLOR|Kolory`),
                        null
                    );

                    if (mosquito.rollerShutter
                        && mosquito.rollerShutter?.colors?.price
                        && mosquito.prices?.show) {
                        mosquito_colors['$$price'] = mosquito.rollerShutter?.colors?.price;
                    }

                    ParserService.pushChild(
                        mosquito_colors,
                        translate(`MOSQUITO|Ramka`),
                        ParserService.getChangedName(mosquito.colors?.frame)
                    );
                    ParserService.pushChild(
                        mosquito_colors,
                        translate(`MOSQUITO|Siatka`),
                        ParserService.getChangedName(mosquito.colors?.net)
                    );

                    if (mosquito.variant?.extraColor)
                        ParserService.pushChild(
                            mosquito_colors,
                            translate(`MOSQUITO|Elementy z tworzywa`),
                            ParserService.getChangedName(mosquito.colors?.panel)
                        );

                    if (mosquito.variant) {
                        const variant = ParserService.pushChild(
                            this_mosquito,
                            translate(`MOSQUITO|Typ siatki`),
                            ParserService.getChangedName(mosquito.variant)
                        );

                        if (mosquito.variant?.hasDirection) {
                            if (mosquito.direction === 'L')
                                variant['value'] += ' ' + translate('INTERFACE|Lewa');
                            if (mosquito.direction === 'R')
                                variant['value'] += ' ' + translate('INTERFACE|Prawa');
                        }
                    }

                    if (mosquito.montage?.name)
                        ParserService.pushChild(
                            this_mosquito,
                            translate(`MOSQUITO|System montażu`),
                            ParserService.getChangedName(mosquito.montage)
                        );
                });
            }
        }
    }

    private static setDoorPortal(details: { $$children: any[]; }, translate: (s: string) => string, conf: any) {
        if (conf.doorPortal) {
            const doorPortal = ParserService.pushChild(
                details,
                translate('DOOR|Portal'),
                ''
            );

            ParserService.pushChild(
                doorPortal,
                translate(`DOOR|Portal`),
                conf.doorPortal?.name
            );

            ParserService.pushChild(
                doorPortal,
                translate(`DOOR|Kolor portalu`),
                conf.doorPortal?.selectedColor?.name
            );
        }
    }

    private static setPleatedBlind(details: { $$children: any[]; }, translate: (s: string) => string, conf: any) {
        const pleatedBlind = ParserService.pushChild(
            details,
            translate('PLEATEDBLIND|Plisa'),
            ''
        );

        ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Typ okna`),
            conf.pleatedBlind?.System?.name
        );

        const shape = ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Wymiary`),
            ''
        );


        ParserService.pushChild(
            shape,
            translate(`PLEATEDBLIND|Szerokość`),
            conf.pleatedBlind?.shape?.width
        );

        ParserService.pushChild(
            shape,
            translate(`PLEATEDBLIND|Wysokość`),
            conf.pleatedBlind?.shape?.height
        );

        ParserService.pushChild(
            pleatedBlind,
            translate(`DIMENSION|Wariant`),
            conf.pleatedBlind?.variant?.name
        );

        const fabrics = ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Tkaniny`),
            ''
        );

        ParserService.pushChild(
            fabrics,
            translate(`PLEATEDBLIND|Tkanina głowna`),
            conf.pleatedBlind?.mainFabric?.name
        );

        if (conf.pleatedBlind?.variant?.double_fabric) {
            ParserService.pushChild(
                fabrics,
                translate(`PLEATEDBLIND|Tkanina dodatkowa`),
                conf.pleatedBlind?.additionalFabric?.name
            );
        }

        ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Typ sterowania`),
            conf.pleatedBlind?.drive?.name
        );

        ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Strona sterowania`),
            conf.pleatedBlind?.driveSide
        );

        ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Prowadzenie`),
            conf.pleatedBlind?.guidanceType?.name
        );

        ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Typ domykania`),
            conf.pleatedBlind?.closureType?.name
        );


        const colors = ParserService.pushChild(
            pleatedBlind,
            translate(`PLEATEDBLIND|Kolory`),
            ''
        );

        ParserService.pushChild(
            colors,
            translate(`PLEATEDBLIND|Kolor tkaniny głównej`),
            conf.pleatedBlind?.mainFabric?.color?.name
        );

        ParserService.pushChild(
            colors,
            translate(`PLEATEDBLIND|Kolor tkaniny dodatkowej`),
            conf.pleatedBlind?.additionalFabric?.color?.name
        );

        if (conf.pleatedBlind?.drive?.color && conf.pleatedBlind?.drive?.color?.name) {
            ParserService.pushChild(
                colors,
                translate(`PLEATEDBLIND|Kolor uchwytu`),
                conf.pleatedBlind?.drive?.color?.name
            );
        }

        if (conf.pleatedBlind?.guidanceType?.color && conf.pleatedBlind?.guidanceType?.color?.name) {
            ParserService.pushChild(
                colors,
                translate(`PLEATEDBLIND|Kolor prowadnicy`),
                conf.pleatedBlind?.guidanceType?.color?.name
            );
        }
    }

    private static getAttributesTree(details: { $$children: any[]; }, translate: (s: string) => string, conf: any, dimensionUnit: (s: any) => string) {
        const parent = ParserService.pushChild(
            details,
            translate(`CONFIGURATOR|Specyfikacja`),
            null
        );
        if (conf.attributes && conf.attributes.length) {
            conf.attributes.forEach(attribute => {
                ParserService.pushChild(
                    parent,
                    attribute.name,
                    attribute.value
                );
            })
        }
    }
}
