import {NgModule} from '@angular/core';

import { BrowserGlazingBeadsService } from './glazing-beads.service';
import { GlazingBeadsService } from '@icc/common/glazing-beads.service';
import {GlazingBeadColorsService} from './glazing-bead-colors.service';
import {SealColorsService} from './seal-colors.service';
import {SiliconeColorsService} from './silicone-colors.service';
import {WarmEdgesService} from './warm-edges.service';
import {FillingsService} from './fillings.service';
import { GlazingUnitsService } from './glazing-units.service';
import {BondedGlazingService} from './bonded-glazing.service';
import { GlazingSizesService } from '@icc/common/configurators/glazing-sizes.service';
import { FillingsColorsService } from './fillings-colors.service';
import { FillingValidationService } from './filling-validation.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        BrowserGlazingBeadsService,
        {provide: GlazingBeadsService, useExisting: BrowserGlazingBeadsService},
        GlazingBeadColorsService,
        SealColorsService,
        SiliconeColorsService,
        WarmEdgesService,
        FillingsService,
        GlazingSizesService,
        GlazingUnitsService,
        BondedGlazingService,
        FillingsColorsService,
        FillingValidationService
    ],
})
export class GlazingsModule {
    constructor() {}
}
