import {NgModule} from '@angular/core';

import {MuntinsLayoutService} from './muntins-layout.service';
import {MuntinColorsService} from './muntin-colors.service';
import { MuntinsService } from './muntins.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        MuntinsLayoutService,
        MuntinColorsService,
        MuntinsService,
    ],
})
export class MuntinsModule { }
