import angular from 'angular';
import $ from 'jquery';
import {logger} from '@icc/common/helpers';

/**
 * Obsługa produktw podobnych
 * @param {object} $rootScope            RoorScope
 * @param {object} $http                 Obsługa zapytań HTTP
 * @param {object} Core                  Biblioteka podstawowych funkcji
 * @param {object} ConfigurationsService Bierząca konfiguracja
 */
export default function ProductMistakesFactory($rootScope, $http, Core, ConfigurationsService, EventBusService, EnvConfig) {
    'ngInject';

    var factory = {
        searchForSimillarProducts: searchForSimillarProducts,
        addToProductMistakes     : addToProductMistakes
    };

    $rootScope.$on('changedStep', function() {
        if (($rootScope.user.access == 'klient' || $rootScope.user.access == 'dealer' || !$rootScope.user.access)
            && $rootScope.mistakeProductLoaded !== true) {
            searchForSimillarProducts();
        }
    });

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        $rootScope.mistakeProductExcepts = [];
    });

    return factory;

    function searchForSimillarProducts() {
        if (ConfigurationsService.conf.Current.Price !== undefined
            && ConfigurationsService.conf.Current.Price
            && (
                angular.isArray(ConfigurationsService.conf.Current.Sashes) && ConfigurationsService.conf.Current.Sashes.length
                || (
                    !angular.isArray(ConfigurationsService.conf.Current.Sashes)
                    && ConfigurationsService.conf.Current.type == 'complementary_goods'
                    && angular.isDefined(ConfigurationsService.conf.Current.ComplementaryGoods[ConfigurationsService.conf.stepCode][0])
                )
                || ConfigurationsService.conf.Current.type == 'garage_door'
            )
        ) {
            var data;

            if (ConfigurationsService.conf.Current.type == 'complementary_goods') {
                var access = ConfigurationsService.conf.Current.ComplementaryGoods[ConfigurationsService.conf.stepCode][0].accessory;

                data = {
                    type    : ConfigurationsService.conf.Current.type,
                    quantity: ConfigurationsService.conf.Current.ComplementaryGoods[ConfigurationsService.conf.stepCode][0].count,
                    price   : ConfigurationsService.conf.Current.Price,
                    shape   : {width: access.sel_height, height: access.sel_width, h1: access.sel_height, s1: access.sel_width, circuit: (access.sel_height * 2 + access.sel_width * 2), 'shape': 'rect'}, // jscs:ignore
                    sub_type: ConfigurationsService.conf.stepCode
                };
            } else {
                data = {
                    type    : ConfigurationsService.conf.Current.type,
                    quantity: ConfigurationsService.conf.Current.Quantity,
                    price   : ConfigurationsService.conf.Current.Price,
                    shape   : ConfigurationsService.conf.Current.Shape,
                    colors  : ConfigurationsService.conf.Current.Colors
                };
            }

            // $http.post(`${EnvConfig.remoteHost || window.location.origin}/product/product_mistakes/search_for_simillar_products`, {conf: data, excepts: $rootScope.mistakeProductExcepts || []}).then(function(res) {
            //     if (res.data.product_mistake_id !== 0) {
            //         $rootScope.mistakeProductsMessage = res.data.message;
            //         $rootScope.mistakeProductConfig   = res.data.configuration;
            //         $rootScope.mistakeProductId       = res.data.product_mistake_id;
            //     } else {
            //         $rootScope.mistakeProductMessage = false;
            //     }
            // }, 'JSON');
        } else {
            $rootScope.mistakeProductsMessage = false;
        }
    }

    function addToProductMistakes() {
        $rootScope.loader = true;
        var image = Core.svgToPngData($('.smallWindowPreview')[0]);
        var extraElem = {};

        if (ConfigurationsService.conf.Current.type == 'complementary_goods') {
            var access = ConfigurationsService.conf.Current.ComplementaryGoods[ConfigurationsService.conf.stepCode][0].accessory;

            extraElem = {
                Width    : access.sel_width,
                Height   : access.sel_height,
                Thickness: access.thickness_mm,
                Shape    : {h1: access.sel_height, s1: access.sel_width, circuit: (access.sel_height * 2 + access.sel_width * 2), 'shape': 'rect'}, // jscs:ignore
                Weight   : access.sel_width * access.sel_height * access.weight / 1000000,
                SubType  : ConfigurationsService.conf.stepCode,
                Quantity : ConfigurationsService.conf.Current.ComplementaryGoods[ConfigurationsService.conf.stepCode][0].count
            };
        }

        $http.post(`${EnvConfig.remoteHost || window.location.origin}/product/product_mistakes/add_to_product_mistakes.json`, {
            conf : angular.extend(ConfigurationsService.conf.Current, extraElem),
            image: image
        }).then(function() {
            window.location.pathname = 'admin/product/product_mistakes';
        }).catch(function(err) {
            logger.error(err);
        });
    }
}
