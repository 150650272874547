import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { PageComponent, _, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { UnitConverterService } from '@icc/configurator/ui';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';

@Component({
    selector: 'icc-mounting-clearance',
    template: require('./mounting-clearance.component.html'),
    styles: [require('./mounting-clearance.component.scss')],
})
export class MountingClearanceComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {
    public title = _('WINDOW|Luzy montażowe');
    currentMeasurement: number;
    selectedMountingMethod: boolean = true;
    fit: 'frame' | 'construction';
    clearanceInputsForm = new FormGroup({
        distanceOut: new FormGroup({
            top: new FormControl(''),
            bottom: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
        enlargement: new FormGroup({
            top: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
        distanceIn: new FormGroup({
            top: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
    });

    callback() {
        console.log("callback");
    }

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: any,
        private unitConverterService: UnitConverterService, 
        private measurementsService: MeasurementsService,
    ) {
        super();
        this.currentMeasurement = this.pageData.currentMeasurement;
        this.fit = this.pageData.fit;
    }

    checkField(field) {
        return this.pageData.checkField(field);
    }

    ngOnInit() {
        this.selectedMountingMethod = this.pageData.selectedMountingMethod;
    }

    ngAfterViewInit() {
        this.clearanceInputsForm.setValue({
            distanceIn: {...this.measurementsService.measurements[this.currentMeasurement].distanceIn},
            distanceOut: {...this.measurementsService.measurements[this.currentMeasurement].distanceOut},
            enlargement: {...this.measurementsService.measurements[this.currentMeasurement].enlargement},
        })
    }

    ngOnDestroy() {
        let measurements = this.clearanceInputsForm.value;
        this.measurementsService.measurements[this.currentMeasurement].distanceIn = measurements.distanceIn;
        this.measurementsService.measurements[this.currentMeasurement].distanceOut = measurements.distanceOut;
        this.measurementsService.measurements[this.currentMeasurement].enlargement = measurements.enlargement;
    }
}
