import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icc-button',
  template: require('./button.component.html'),
  styles: [require('./button.component.scss')]
})
export class ButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
