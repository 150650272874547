import { Injectable, Inject } from '@angular/core';
import { Profile, FrameProfile, ProfileType, Frame, Coupling } from '@icc/window';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { core, logger } from '@icc/common/helpers';
import { MullionsService } from '@icc/common/profiles/mullions.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { ProfilesService } from '@icc/common/profiles.service';
import { TranslateService } from '@icc/common/translate.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { DrawService } from '@icc/common/configurators/draw.service';
import { Common } from '@icc/common/Common';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { IccSideColors, IccColor, IccSystem, IccAccessory } from '@icc/common/data-types';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { SideColors } from '@icc/common/configurations/parts/common';
import { ModalService } from '@icc/helpers';
import { ProfilesShapesPageComponent } from './profiles-shapes-page/profiles-shapes-page.component';
import { DrawData } from '@icc/draw';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';

@Injectable()
export class BrowserProfilesService extends ProfilesService {
    constructor(
        private modalService: ModalService,
        private configurationsService: ConfigurationsService,
        protected eventBusService: EventBusService,
        protected mullionsService: MullionsService,
        private issuesService: IssuesService,
        protected validationService: ValidationService,
        protected profilesPriceService: ProfilesPriceService,
        private translateService: TranslateService,
        private drawService: DrawService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private colorMappingService: ColorMappingService,
        protected colorsDefaultsService: ColorsDefaultsService
    ) {
        super(profilesPriceService, eventBusService, mullionsService, validationService, colorsDefaultsService, config);
        this.drawService.init();
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', data => {
            this.validateLoadedProfiles(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe('setProfileSet', data => {
            try {
                this.setDefaultsFromSet(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('setFrameProfile', data => {
            try {
                this.validateFrames(data.activeConfiguration as WindowActiveConfiguration);
                this.validFinWidths(data.activeConfiguration as WindowActiveConfiguration);
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setSashProfile', data => {
            try {
                this.validateSashes(data.activeConfiguration as WindowActiveConfiguration);
                this.validateMullionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setMullionProfile', data => {
            try {
                this.validateMullions(data.activeConfiguration as WindowActiveConfiguration);
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setGlazingBeadInSash', data => {
            try {
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
                this.setUsedProfileShapes(
                    data.activeConfiguration as WindowActiveConfiguration,
                    this.getProfileShape(data.value.profile.profileShapeId)
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('putAlignmentInField', data => {
            try {
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('putExtensionOnSide', data => {
            try {
                this.validFinWidths(data.activeConfiguration as WindowActiveConfiguration);
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setExtensionProfile', data => {
            try {
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('setCouplingProfile', data => {
            try {
                this.setUsedProfiles(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value.profile
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedProfiles', data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validateSashesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateMullionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.setUsedProfiles(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('validProfileSet', data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validateSashesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateMullionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.setUsedProfiles(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedFrames', data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validateSashesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateMullionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validFinWidths(data.activeConfiguration as WindowActiveConfiguration);
                this.setUsedProfiles(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedSashes', data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validateSashesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateMullionsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.setUsedProfiles(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setShape', data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.validateSashesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe(['setLowThreshold', 'unsetLowThreshold'], data => {
            try {
                this.validateFramesAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.eventBusService.post({ key: 'setFrameProfile', value: {} });
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>(['setConstructionColor', 'setShape'], data => {
            try {
                if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                    this.setUsedProfiles(data.activeConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });
    }

    /**
     * Tworzy listę możliwych szerokości do odcięcia
     */
    getFinWidths(profile) {
        const finWidths = [];
        if (profile.finWidth && !isNaN(parseFloat(profile.finWidth))) {
            finWidths.push(parseFloat(profile.finWidth));
        }

        if (parseFloat(profile.finWidth) > 0 && parseFloat(profile.finWidthInterval) > 0) {
            for (
                let i = profile.finWidth - parseFloat(profile.finMinWidth);
                i >= 0;
                i -= parseFloat(profile.finWidthInterval)
            ) {
                if (finWidths.indexOf(i) < 0) {
                    finWidths.push(i);
                }
            }
        }

        if (finWidths.indexOf(0) < 0) {
            finWidths.push(0);
        }
        return finWidths;
    }

    getMonoblockFrames(side, conf: WindowActiveConfiguration) {
        const systemId = Number(conf.System.id) || 0;
        const profiles = this.profiles.filter(
            profile =>
                profile.type === 'frame' &&
                // && profile.options.indexOf('monoblock_frame') > -1
                profile.systems.indexOf(systemId) > -1
                && profile.options.indexOf(side) > -1
        );
        return profiles;
    }

    setFrameFinWidths(conf: WindowActiveConfiguration, finWidths: number[]) {
        const sides = this.getFrameSidesOnEdge(conf);
        sides.forEach((side, index) => {
            if (finWidths[index] !== null) {
                side.frameEdges.forEach(edge => {
                    const frame = conf.Frames.find(f => f.id === edge.frameId);
                    if (frame) {
                        frame.frame[edge.frameEdgeIndex].finWidth = finWidths[index] || 0;
                        frame.frame[edge.frameEdgeIndex].side = side.side;
                    }
                });
            }
        });
        this.eventBusService.post({
            key: 'changedFinWidths',
            value: null,
        });
    }

    setFrameFromIds(conf: WindowActiveConfiguration, profileIds: number[]) {
        const sides = this.getFrameSidesOnEdge(conf);
        sides.forEach((side, index) => {
            side.frameEdges.forEach(edge => {
                const frame = conf.Frames.find(f => f.id === edge.frameId);
                if (
                    frame
                    && profileIds[index]
                    && profileIds[index] !== frame.frame[edge.frameEdgeIndex].profileId
                ) {
                    const profile = this.getProfile(profileIds[index]);
                    this.setFrameProfile(conf, profile, frame, edge.frameEdgeIndex, {
                        isDefault: false,
                        finWidth: profile.finWidth,
                        side: side.side,
                    });
                }
            });
        });
    }

    getUsedThresholdId(conf: WindowActiveConfiguration) {
        const frameWithLowThreshold =
            conf.Frames.length && conf.Frames.find(frame => frame.lowThreshold);
        if (frameWithLowThreshold && frameWithLowThreshold.frame[0]) {
            return frameWithLowThreshold.frame[0].profileId;
        }
    }

    setFrameShape(conf: WindowActiveConfiguration, frame: Frame) {
        const sides = this.getFrameSides(frame, conf);
        if (frame.frame.length && sides.length !== frame.frame.length) {
            frame.frame.length = sides.length;
            let startIndex = 0;
            if (frame.frame[1]) {
                startIndex = 1;
            }
            let profile = this.getProfile(frame.frame[startIndex].profileId);
            const pauseId = this.eventBusService.pause(['setFrameProfile']);
            try {
                for (let i = startIndex + 1; i < frame.frame.length; i++) {
                    if (!this.isFrameOrThreshold(profile.type, sides[i].sideSimple)) {
                        const type = this.getFrameTypeForSide(sides[i].sideSimple, frame);
                        profile = this.getProfile(conf.ProfileSet[type]);
                    }
                    this.setFrameProfile(conf, profile, frame, i, {
                        isDefault: frame.frame[startIndex].isDefault,
                        finWidth: frame.frame[startIndex].finWidth,
                    });
                }
            } finally {
                this.eventBusService.resume(['setFrameProfile'], pauseId);
            }
        }
    }

    setExtensionProfile(extension, profile) {
        if (!profile) {
            throw new Error('Brak profilu!');
        }

        if (profile.type === 'sandwich') {
            profile.widthOut = profile.width;
        }

        extension.profileId = profile.id;
        extension.width = profile.width;
        extension.widthOut = profile.widthOut;
        extension.reinforcement = profile.reinforcement ? core.copy(profile.reinforcement) : null;

        extension.color = profile.selectedColor ? core.copy(profile.selectedColor.frame) : {};
        extension.wood = profile.selectedWood ? core.copy(profile.selectedWood) : {};

        if (profile.type === 'sandwich') {
            profile.width = null;
            profile.widthOut = null;
        }

        this.eventBusService.post({
            key: 'setExtensionProfile',
            value: {
                extension,
                profile,
            },
        });
    }

    validateMullionsAndFixIssues(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'mullionsProfiles');
        if (this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            const pauseId = this.eventBusService.pause(['setMullionProfile']);
            let valid = true;
            try {
                conf.Mullions.forEach(mullion => {
                    if (!this.validMullion(conf.type, mullion, conf.Mullions)) {
                        this.setDefaultMullion(conf, mullion);
                        valid = false;
                    }
                });
                conf.Sashes.forEach(sash => {
                    sash.intMullions.forEach(intMullion => {
                        if (!this.validMullion(conf.type, intMullion)) {
                            this.setDefaultMullion(conf, intMullion);
                            valid = false;
                        }
                    });
                });
            } finally {
                this.eventBusService.resume(['setMullionProfile'], pauseId);
            }
            if (valid) {
                Object.assign(conf, this.validationService.valid(conf, 'mullionsProfiles'));
                this.issuesService.unregister('invalid-mullions', conf);
            } else {
                this.validationService.invalid(conf, 'mullionsProfiles');
            }
            this.eventBusService.post({
                key: 'validatedProfiles',
                value: valid,
            });
        }
    }

    validateMullions(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'mullionsProfiles');
        if (this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            const valid =
                conf.Mullions.every(mullion =>
                    this.validMullion(conf.type, mullion, conf.Mullions)
                )
                && conf.Sashes.every(sash =>
                    sash.intMullions.every(intMullion => this.validMullion(conf.type, intMullion))
                );
            if (!valid) {
                this.validationService.invalid(conf, 'mullionsProfiles');
                this.issuesService.simpleRegister(
                    'invalid-mullions',
                    'Niepoprawne profile słupków',
                    this.translateService.instant('INTERFACE|Niepoprawne profile słupków'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.WARN,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'mullionsProfiles'));
                this.issuesService.unregister('invalid-mullions', conf);
            }
            this.eventBusService.post({
                key: 'validatedProfiles',
                value: valid,
            });
        }
    }

    validMullion(confType, mullion, mullions?) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const falseMullionSashes = this.getFalseMullionSashes().map(p => p.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            mullion.direction,
            falseMullionSashes,
            confType,
            mullions
        );
        const neededWiderMullion = this.mullionsService.neededWiderMullion(
            fields1,
            fields2,
            mullion.direction
        );

        if (mullionType === 'no_mullion' && !mullion.profileId) {
            return true;
        }

        if (!mullion.profileId) {
            return false;
        }
        const profile = this.getProfile(mullion.profileId);
        if (!profile) {
            return false;
        }
        const profileOptions = this.mullionsService.mullionTypes[mullionType][mullion.direction];
        return (
            profile.type === this.mullionsService.mullionTypes[mullionType].type
            && (profileOptions.length || !profile.options.length)
            && (!profileOptions.length
                || profileOptions.every(
                    option => profile.options && profile.options.indexOf(option) > -1
                ))
            && (!neededWiderMullion || (neededWiderMullion && profile.width >= 60))
        );
    }

    validateSashesAndFixIssues(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration
    ) {
        this.validationService.indeterminate(conf, 'sashesProfiles');
        if (this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            const pauseId = this.eventBusService.pause(['setSashProfile']);
            let valid = true;
            try {
                conf.Sashes.forEach(sash => {
                    if (
                        ['left', 'right', 'top', 'bottom'].some(
                            side => !this.validSashProfile(sash.frame[side], sash, conf, side)
                        )
                    ) {
                        this.setDefaultSash(sash, conf, defaultConf);
                        valid = false;
                    }
                });
            } finally {
                this.eventBusService.resume(['setSashProfile'], pauseId);
            }
            if (valid) {
                Object.assign(conf, this.validationService.valid(conf, 'sashesProfiles'));
                this.issuesService.unregister('invalid-sashes-profiles', conf);
            }
            this.eventBusService.post({
                key: 'validatedProfiles',
                value: valid,
            });
        }
    }

    validateSashes(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'sashesProfiles');
        if (this.validationService.isValidElements(conf, ['system', 'sashes', 'loadedProfiles'])) {
            const valid = conf.Sashes.every(sash =>
                ['left', 'right', 'top', 'bottom'].every(side =>
                    this.validSashProfile(sash.frame[side], sash, conf, side)
                )
            );
            if (!valid) {
                this.validationService.invalid(conf, 'sashesProfiles');
                this.issuesService.simpleRegister(
                    'invalid-sashes-profiles',
                    'Niepoprawne profile skrzydeł',
                    this.translateService.instant('INTERFACE|Niepoprawne profile skrzydeł'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.WARN,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'sashesProfiles'));
                this.issuesService.unregister('invalid-sashes-profiles', conf);
            }
            this.eventBusService.post({
                key: 'validatedProfiles',
                value: valid,
            });
        }
    }

    validateFramesAndFixIssues(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'frameProfiles');
        if (
            this.validationService.isValidElements(conf, [
                'system',
                'shape',
                'loadedProfiles',
                'profileSet',
            ])
        ) {
            const pauseId = this.eventBusService.pause(['setFrameProfile']);
            try {
                conf.Frames.forEach(frame => {
                    const sides = this.getFrameSides(frame, conf);
                    if (frame.frame.length === 0) {
                        this.setDefaultFrame(frame, conf);
                    }
                    if (frame.frame.length > 0 && sides.length !== frame.frame.length) {
                        this.setFrameShape(conf, frame);
                    }
                    const invalidProfiles = frame.frame
                        .map((frameProfile, index) => ({ frameProfile, position: index }))
                        .filter(
                            el => !this.validFrameProfile(conf, frame, el.frameProfile, el.position)
                        );
                    if (invalidProfiles.length > 0) {
                        this.validationService.invalid(conf, 'frameProfiles');
                        invalidProfiles.forEach(el => {
                            this.setDefaultFrameProfile(
                                conf,
                                frame,
                                sides[el.position].side,
                                sides[el.position].sideSimple,
                                el.position
                            );
                        });
                    } else {
                        Object.assign(conf, this.validationService.valid(conf, 'frameProfiles'));
                        this.issuesService.unregister('invalid-frame-profiles', conf);
                    }
                });
            } finally {
                this.eventBusService.resume(['setFrameProfile'], pauseId);
                this.eventBusService.post({
                    key: 'validatedProfiles',
                    value: null,
                });
            }
        }
    }

    validateFrames(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'frameProfiles');
        if (
            this.validationService.isValidElements(conf, [
                'system',
                'shape',
                'loadedProfiles',
                'profileSet',
            ])
        ) {
            const valid = conf.Frames.every(frame =>
                frame.frame.every((frameProfile, index) =>
                    this.validFrameProfile(conf, frame, frameProfile, index)
                )
            );
            if (!valid) {
                this.validationService.invalid(conf, 'frameProfiles');
                this.issuesService.simpleRegister(
                    'invalid-frame-profiles',
                    'Niepoprawne profile ramy',
                    this.translateService.instant('INTERFACE|Niepoprawne profile ramy'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.WARN,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'frameProfiles'));
                this.issuesService.unregister('invalid-frame-profiles', conf);
            }
            this.eventBusService.post({
                key: 'validatedProfiles',
                value: valid,
            });
        }
    }

    validFinWidths(conf: WindowActiveConfiguration) {
        conf.Frames.forEach(frame => {
            const sides = this.getFrameSides(frame, conf);
            frame.frame.forEach((frameProfile, index) => {
                const side = sides[index];
                if (side.side === 'top' && conf.hasRoller && frameProfile.finWidth > 0) {
                    this.removeFinFromSide(side.side, frame, conf);
                }
                if (
                    conf.SideProfiles.some(s =>
                        s.framesId.some(f => f.id === frame.id && f.edges.includes(index))
                    )
                    && frameProfile.finWidth > 0
                ) {
                    this.removeFinFromSide(side.side, frame, conf);
                }
                if (
                    (conf.couplings.some(
                        c =>
                            c.framesId.some(f => f.id === frame.id)
                            && c.direction === 'vertical'
                            && side.side === 'right'
                    )
                        || conf.couplings.some(
                            c =>
                                c.otherFramesId.some(f => f.id === frame.id)
                                && c.direction === 'vertical'
                                && side.side === 'left'
                        ))
                    && frameProfile.finWidth > 0
                ) {
                    this.removeFinFromSide(side.side, frame, conf);
                }

                if (
                    (conf.couplings.some(
                        c =>
                            c.framesId.some(f => f.id === frame.id)
                            && c.direction === 'horizontal'
                            && side.side === 'bottom'
                    )
                        || conf.couplings.some(
                            c =>
                                c.otherFramesId.some(f => f.id === frame.id)
                                && c.direction === 'horizontal'
                                && side.side === 'top'
                        ))
                    && frameProfile.finWidth > 0
                ) {
                    this.removeFinFromSide(side.side, frame, conf);
                }
            });
        });
    }

    removeFinFromSide(side: string, frame: Frame, conf: WindowActiveConfiguration) {
        const sides = this.getFrameSides(frame, conf);
        const position = sides.findIndex(s => s.side === side);
        const frameProfile = frame.frame[position];
        if (frameProfile) {
            const profile = this.getProfile(frameProfile.profileId);
            this.setFrameProfile(conf, profile, frame, position, {
                isDefault: false,
                finWidth: 0,
                side: frameProfile.side,
            });
        }
    }

    getProfileColors(profile: Profile, system: IccSystem) {
        return this.colors.filter(color => {
            return (
                profile.priceLevelColorGroups.some(g => color.groups && (color.groups.map(Number).includes(Number(g))))
                || profile.priceLevelColorGroupsOut.some(g => color.groups && (color.groups.map(Number).includes(Number(g))))
            );
        });
    }

    getDefaultProfileColorSet(
        profile: Profile,
        sideColors: Partial<IccSideColors>,
        system: IccSystem
    ): SideColors {
        const colorType = this.colorsDefaultsService.getColorTypeForColors({
            frame: sideColors,
            sash: sideColors,
        });
        const availColors = this.getProfileColors(profile, system);
        return {
            outer: this.getDefaultProfileColor(
                availColors,
                'outer',
                colorType,
                sideColors && sideColors.outer && Number(sideColors.outer.id),
                sideColors && sideColors.outer && sideColors.outer.RAL
            ),
            inner: this.getDefaultProfileColor(
                availColors,
                'inner',
                colorType,
                sideColors && sideColors.inner && Number(sideColors.inner.id),
                sideColors && sideColors.inner && sideColors.inner.RAL
            ),
            core: this.getDefaultProfileColor(
                availColors,
                'core',
                colorType,
                sideColors && sideColors.core && Number(sideColors.core.id),
                sideColors && sideColors.core && sideColors.core.RAL
            ),
        };
    }

    async openProfilesShapesModal(selectedShape, shapes) {
        const modalInstance = this.modalService.open({
            component: 'profilesShapesModal',
            pageComponent: ProfilesShapesPageComponent,
            resolve: {
                shapes: () => shapes,
                selectedShape: () => selectedShape,
            },
        });
        return modalInstance.result;
    }

    setProfileDefaultColors(
        profile: Profile,
        selectedProfile: Profile | null,
        selectedColor: Partial<IccSideColors>,
        selectedWood: any,
        system: IccSystem
    ) {
        delete profile.selectedColor;
        delete profile.selectedWood;

        if (selectedProfile && selectedProfile.id === profile.id) {
            profile.selectedColor = {
                frame: selectedColor,
                sash: selectedColor,
            };
            profile.selectedWood = selectedWood;
        } else {
            profile.selectedColor = {
                frame: this.getDefaultProfileColorSet(profile, selectedColor, system),
                sash: this.getDefaultProfileColorSet(profile, selectedColor, system),
            };
        }
    }

    private getDefaultProfileColor(
        availColors: IccColor[],
        side: string,
        colorType: string,
        preferredColorId: number,
        isRal = false
    ) {
        const coreColorInBilateralAlwaysAsDefault = this.config().IccConfig.Configurators.window
            .coreColorInBilateralAlwaysAsDefault;

        const matchedColorsIds = this.colorMappingService.getColors(
            preferredColorId,
            'window',
            'window'
        );
        const defaultValue = this.colorsDefaultsService.getDefaultColorType(
            colorType,
            side,
            coreColorInBilateralAlwaysAsDefault
        );
        let colorSide = ['F|' + side[0].toUpperCase()];
        if (
            ['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(colorType) > -1
            && ['inner', 'outer'].indexOf(side) > -1
        ) {
            colorSide = ['F|B'];
        }
        if (colorType === 'Bicolor' && ['inner', 'outer'].indexOf(side) > -1) {
            colorSide = ['F|D' + side[0].toUpperCase()];
        }

        const whites = [];
        const creams = [];
        const colors = [];
        const matchedColors = [];

        for (let i = 0; i < availColors.length; i++) {
            if (
                (Common.isArray(availColors[i].sides)
                    && colorSide.every(s => availColors[i].sides.indexOf(s) > -1))
                || availColors[i].RAL
            ) {
                if (availColors[i].type === 'white') {
                    whites.push(availColors[i]);
                } else if (availColors[i].type === 'cream') {
                    creams.push(availColors[i]);
                } else {
                    colors.push(availColors[i]);
                    if (
                        matchedColorsIds.some(
                            m => Number(m) === Number(availColors[i].id) && !availColors[i].RAL
                        )
                    ) {
                        matchedColors.push(availColors[i]);
                    }
                }
            }
        }
        let color;
        if (defaultValue === 'WHITE' && whites.length > 0) {
            color = core.copy(whites[0]);
        } else if (defaultValue === 'CREAM' && creams.length > 0) {
            color = core.copy(creams[0]);
        } else if (defaultValue !== 'NONE' && matchedColors.length > 0) {
            color = core.copy(matchedColors[0]);
        } else if (defaultValue !== 'NONE' && colors.length > 0) {
            color = core.copy(colors[0]);
        } else {
            return {};
        }
        color.isDefault = true;
        return color;
    }

    private validateLoadedProfiles(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'loadedProfiles');
        if (this.profiles && this.profiles.length > 0) {
            Object.assign(conf, this.validationService.valid(conf, 'loadedProfiles'));
            this.eventBusService.post({
                key: 'loadedProfiles',
                value: {
                    profiles: this.profiles,
                    profileShapes: this.profileShapes,
                },
            });
        } else {
            this.validationService.invalid(conf, 'loadedProfiles');
        }
    }

    getThresholdLength(frameId?: number, conf=this.configurationsService.conf.Current) {
        const drawData = conf.drawData;
        const frameData = drawData && drawData.frame && (frameId ? drawData.frame.find(f => f.frameId == frameId) : drawData.frame[0]);
        return Array.isArray(frameData?.sides) && frameData.sides[0].length;
    }

    getExtensionLength(type: 'vertical' | 'horizontal', conf:any=this.configurationsService.conf.Current) {
        let extensionLength = 0;
        if (type === 'vertical') {
            extensionLength = Number(conf.Height);
        } else if (type === 'horizontal') {
            extensionLength = Number(conf.Width);
            if(Array.isArray(conf.SideProfiles)) {
                conf.SideProfiles.forEach(p => {
                    if (['left', 'right'].includes(p.side))
                        extensionLength += Number(p.width);
                })
            }
        }

        return extensionLength;
    }

    checkIfAccessoryMatchFrameProfile(frameProfileId: number) {
        const doorFrameAccessory: string[] = [];

        const accessories = this.configurationsService.conf.Current as DoorActiveConfiguration;

        accessories.Accessories.forEach((a: IccAccessory) => {
            const isDependent = !a.accessory.dependent_on_frame_profile || a.accessory.frame_profiles_ids.map(Number).includes(frameProfileId);
            if (!isDependent) { 
                doorFrameAccessory.push(a.accessory.name);
            }
        })

        return doorFrameAccessory;
    }
}
