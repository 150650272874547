import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { PageComponent, _ } from '@icc/configurator/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { MountingClearanceComponent } from '../mounting-clearance/mounting-clearance.component';
import { DimensionsAdditionalDataPageComponent } from '../dimensions-additional-data-page/dimensions-additional-data-page.component';
import { UnitConverterService } from '@icc/configurator/ui';
import { TranslateService } from '@icc/common/translate.service';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';
import { InfoService, ICC_PAGE_DATA } from '@icc/helpers';
import { core } from '@icc/common/helpers';
import { MountingMethod, Measurement } from '@icc/window';

@Component({
    selector: 'icc-measurements-page',
    template: require('./measurements-page.component.html'),
    styles: [require('./measurements-page.component.scss')],
})
export class MeasurementsPageComponent extends PageComponent implements OnInit,AfterViewInit,OnDestroy {
    public title: string = _('WINDOW|Uzupełnij pomiary z budowy');

    mode: string = 'advanced';
    selectedMountingMethod: MountingMethod;
    areMeasurementInsideInputsVisible: boolean = true;
    dimensionInputsInitialValue: number = 0;
    currentMeasurement: number = 0;
    @ViewChildren('measurementsinput') inputs?: QueryList<any>;
    public options = [
        {
            title: _('WINDOW|Luzy montażowe'),
            component: MountingClearanceComponent,
            componentData: {
                mountingMethod: () => this.selectedMountingMethod,
                checkField: () => this.checkField.bind(this),
                fit: () => this.measurements.value.fit,
                currentMeasurement: () => this.currentMeasurement
            },
        },
        {
            title: _('WINDOW|Zdjęcia i notatki'),
            component: DimensionsAdditionalDataPageComponent,
            componentData: {
                currentMeasurement: () => this.currentMeasurement
            }
        },
    ];

    mounting = {
        mountingMethod: {}
    }

    measurements = new FormGroup({
        hightInside: new FormControl(),
        hightOutside: new FormControl(),
        widthInside: new FormControl(),
        widthOutside: new FormControl(),
        fit: new FormControl('frame'),
    });

    clearanceMeasurements = {};
    
    attachementsMeasurements = {}

    montageTypeOptions: Array<MountingMethod> = this.measurementsService.mountingMethods;

    dimensionUnit = this.unitConverterService.getUnit();
    
    constructor(
        private unitConverterService: UnitConverterService, 
        private translateService: TranslateService,
        private measurementsService: MeasurementsService,
        private infoService: InfoService,
        @Inject(ICC_PAGE_DATA) private pageData: any,
    ) {
        super();
        this.selectedMountingMethod = this.measurementsService.mountingMethods[0];
        this.mounting.mountingMethod = this.selectedMountingMethod
    }

    toggleList() {
        if (this.mode === 'advanced') {
            this.mode = 'list';
        } else if (this.mode === 'list') {
            this.mode = 'advanced';
        } else {
            throw new Error('error in switching between advanced and list mode');
        }
    }

    onChangeListItemClick(event: any) {
        this.selectedMountingMethod = event;
        this.measurements.setValue({
            hightInside: this.dimensionInputsInitialValue,
            hightOutside: this.dimensionInputsInitialValue,
            widthInside: this.dimensionInputsInitialValue,
            widthOutside: this.dimensionInputsInitialValue,
            fit: 'frame'
        });
        this.mounting.mountingMethod = this.selectedMountingMethod
        this.toggleList();
    }

    ngAfterViewInit() {
        //this.getDevice();
        if(this.measurementsService.measurements.length === 0) {
            this.measurementsService.initMeasurements();
        }
        this.clearanceMeasurements = {
            distanceOut: this.measurementsService.measurements[this.currentMeasurement].distanceOut,
            enlargement: this.measurementsService.measurements[this.currentMeasurement].enlargement,
            distanceIn: this.measurementsService.measurements[this.currentMeasurement].distanceIn
        }
        this.attachementsMeasurements = {
            notes: this.measurementsService.measurements[this.currentMeasurement].notes,
            attachments: this.measurementsService.measurements[this.currentMeasurement].attachments,
        }
        this.measurements.controls.hightInside.setValue(this.measurementsService.measurements[this.currentMeasurement].hightInside)
        this.measurements.controls.hightOutside.setValue(this.measurementsService.measurements[this.currentMeasurement].hightOutside)
        this.measurements.controls.widthInside.setValue(this.measurementsService.measurements[this.currentMeasurement].widthInside)
        this.measurements.controls.widthOutside.setValue(this.measurementsService.measurements[this.currentMeasurement].widthOutside)
        this.measurements.controls.fit.setValue(this.measurementsService.measurements[this.currentMeasurement].fit)
    }

    // async getDevice() {
    //     try {
    //         const device = await (navigator as any).bluetooth.requestDevice({
    //             filters: [{services: ['3ab10100-f831-4395-b29d-570977d5bf94']}]
    //         });
    //         this.infoService.openInfoModal(
    //             {
    //                 message: this.translateService.instant('INTERFACE|Parowanie z urządzeniem'),
    //                 title: ''
    //             }
    //         );
    //         const server = await device.gatt.connect();
    //         const service = await server.getPrimaryService('3ab10100-f831-4395-b29d-570977d5bf94');
    //         const characteristic = await service.getCharacteristic('3ab10101-f831-4395-b29d-570977d5bf94');
    //         await characteristic.startNotifications();
    //         this.infoService.openInfoModal(
    //             {
    //                 message: this.translateService.instant('INTERFACE|Sparowano z urządzeniem'),
    //                 title: ''
    //             }
    //         );
    //         this.findNextTabStop(null).focus();
    //         characteristic.addEventListener('characteristicvaluechanged',
    //             this.handleNotifications.bind(this)
    //         );
    //     } catch (error) {
    //         this.infoService.openInfoModal(
    //             {
    //                 message: this.translateService.instant('INTERFACE|Nie można połączyć się z urządzeniem'),
    //                 title: ''
    //             }
    //         );
    //     }
    // }

    // handleNotifications(event: { target: { value: any; }; }) {
    //     let value = event.target.value;
    //     let a = [];
    //     for (let i = 0; i < value.byteLength; i++) {
    //         a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    //     }
    //     let measurement = core.hexToFloat(core.flipHexString('0x' + (a.join('').replace(/0x/g, '')), 8));
    //     measurement = Math.round(measurement * 1000);
    //     const id = document.activeElement.id;
    //     if (id.includes('-')) {
    //         const keys = id.split('-');
    //         //this.measurementsService.measurements[this.measurementsForm.value.constructionNumber][keys[0]][keys[1]] = measurement;
    //     } else {
    //         //this.measurementsService.measurements[this.measurementsForm.value.constructionNumber][id] = measurement;
    //     }
    //     const nextElem = this.findNextTabStop(document.activeElement);
    //     nextElem.focus();
    // }

    // findNextTabStop(el: Element | null) {
    //     const list = this.inputs.toArray().map((e: { nativeElement: any; }) => e.nativeElement);
    //     const index = list.indexOf(el);
    //     return list[index + 1] || list[0];
    // }

    saveMeasurements() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
        if(this.measurementsService.checkMeasurementsValues(this.measurementsService.measurements)){
            if (this.measurementsService.saveMeasurements(this.measurementsService.measurements)) {
                this.pageData.setDimensions(this.measurementsService.shapeData);
            }
        } else {
            this.infoService.openInfoModal(
                {
                    message: this.translateService.instant('INTERFACE|Pola luzów montażowych/powiększeń konstrukcji nie zostały wypełnione'),
                    title: this.translateService.instant('INTERFACE|Wypełnij pola luzów montażowych/powiększeń konstrukcji')
                }
            );
        }
    }

    checkField(field: 'hightInside' | 'widthInside' | 'hightOutside' | 'widthOutside' | 'fit' | 'distanceOutTop' | 'distanceInTop' | 'distanceOutBottom' | 'distanceInBottom' | 'distanceOutLeft' | 'distanceInLeft' | 'distanceOutRight' | 'distanceInRight' | 'hightWarmingLayer' |'widthWarmingLayer' | 'crossHightInside' | 'crossHightOutside') {
        return (
            this.selectedMountingMethod.fields.indexOf(field) > -1
        );
    }

    saveAndCalcMeasurements() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
        if(this.measurementsService.checkMeasurementsValues(this.measurementsService.measurements)){
            this.measurementsService.saveAndCalcMeasurements(this.measurementsService.measurements).then((shapeData) => {
                if (shapeData) {
                    this.pageData.setDimensions(shapeData);
                }
            });
        } else {
            this.infoService.openInfoModal(
                {
                    message: this.translateService.instant('INTERFACE|Pola luzów montażowych/powiększeń konstrukcji nie zostały wypełnione'),
                    title: this.translateService.instant('INTERFACE|Wypełnij pola luzów montażowych/powiększeń konstrukcji')
                }
            );
        }
    }

    discard() {
        this.measurementsService.discard();
    }

    downloadFile(file: any) {
        this.measurementsService.downloadFile(file);
    }

    removeFile(index: any) {
        //this.measurementsService.removeFile(index, this.measurementNumber);
    }

    onSave = () => {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
        this.saveMeasurements();
    }

    onCalc = () => {
        this.saveAndCalcMeasurements();
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
    }
}
