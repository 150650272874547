import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-mounting-method-page',
  template: require('./mounting-method-page.component.html'),
  styles: [require('./mounting-method-page.component.scss')]
})
export class MountingMethodPageComponent extends PageComponent implements OnInit {
  public title: string = _('WINDOW|Wybierz sposób montażu');
  public options = [];

  constructor() {
    super()
  }

  ngOnInit() {
  }

}
