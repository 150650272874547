import * as Raven from 'raven-js';
import { DEBUG }from './debug';
import { isWorker, isNode } from './environments';
import { Common } from './Common';
import { core } from './helpers';
import format from 'date-fns/format';

export class Logger {

    private offlineStorageKey = 'offline-errors-queue';
    private debugLevel: number;

    offlineQueue = [];
    userLogs = [];
    isOffline = false;

    constructor(debug: number) {
        'ngInject';
        this.debugLevel = debug;
    }

    saveOfflineError(error) {
        if (!isWorker() && !isNode()) {
            const queue = JSON.parse(localStorage.getItem(this.offlineStorageKey)) || [];
            if (queue.length > 10) {
                queue.shift();
            }
            queue.push(error);
            localStorage.setItem(this.offlineStorageKey, JSON.stringify(queue));
        } else {
            if (this.offlineQueue.length > 10) {
                this.offlineQueue.shift();
            }
            this.offlineQueue.push(error);
        }
    }

    onOnline() {
        let queue = [];
        if (!isWorker() && !isNode()) {
            queue = JSON.parse(localStorage.getItem(this.offlineStorageKey)) || [];
            localStorage.removeItem(this.offlineStorageKey);
        } else {
            queue = this.offlineQueue;
            this.offlineQueue = [];
        }
        this.sendToSentry(queue);
    }

    async sendToSentry(queue) {
        for (const e of queue) {
            await new Promise(resolve => {
                setTimeout(() => {
                    Raven.captureException(e);
                    resolve();
                }, 1500);
            });
        }
    }

    log(...args: any[]) {
        if (this.debugLevel > 0) {
            this.log = Function.prototype.bind.call(console.log, console);
            this.log.apply(console, args);
        }
    }

    debug(...args: any[]) {
        if (this.debugLevel > 1) {
            this.debug = Function.prototype.bind.call(console.debug, console);
            this.debug.apply(console, args);
        }
    }

    info(...args: any[]) {
        if (this.debugLevel > 0) {
            this.info = Function.prototype.bind.call(console.info, console);
            this.info.apply(console, args);
        }
    }

    warn(...args: any[]) {
        if (this.debugLevel > 0) {
            this.warn = Function.prototype.bind.call(console.warn, console);
            this.warn.apply(console, args);
        }
    }

    err(...args: any[]) {
        if (this.debugLevel > 0) {
            console.error.apply(console, args);
        }
    }

    error(error: any, ...args: any[]) {
        if (!isNode()) {
            if (this.isOffline) {
                this.saveOfflineError(error);
            } else {
                Raven.context(function () {
                    Raven.setExtraContext({
                        extra: args
                    });
                    Raven.captureException(
                        Common.isError(error)
                            ? error
                            : error && error.message
                            ? error.message
                            : core.stringJson(error)
                    );
                });
            }
        }
        if (this.debugLevel > 0) {
            if (args && args.length) {
                console.error(error, args);
            } else {
                console.error(error);
            }
        }
    }

    group(...args: any[]) {
        if (this.debugLevel > 0) {
            console.group.apply(console, args);
        }
    }

    groupCollapsed(...args: any[]) {
        if (this.debugLevel > 0) {
            console.groupCollapsed.apply(console, args);
        }
    }

    groupEnd(...args: any[]) {
        if (this.debugLevel > 0) {
            console.groupEnd.apply(console, args);
        }
    }

    userLog(msg) {
        if (this.userLogs.length == 100) {
            this.userLogs.shift();
        }
        this.userLogs.push({
            time: format(new Date(), 'HH:mm:ss'),
            msg
        });
    }

    /**
     * Ustawia kontekst użytkownika
     *
     * @param {any} user Użytkownik
     *
     * @memberOf Logger
     */
    setUser(user) {
        Raven.setUserContext({
            id: user.id,
        });
        Raven.setExtraContext({
            language: navigator.language,
            isPhonegap: !!(<any>window).cordova,
            isElectron: (<any>window).isElectron,
        });
    }
}

export const logger = new Logger(DEBUG);
