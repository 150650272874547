import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';

import { iccListTab } from '../list/item';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MdcTabScroller } from '@angular-mdc/web/tab-scroller';
import { MdcTabBar } from '@angular-mdc/web/tab-bar';

@Component({
    selector: 'icc-tabs',
    template: require('./tabs.component.html'),
    styles: [require('./tabs.component.scss')],
})
export class TabsComponent implements OnInit, OnDestroy {
    @ViewChild('tabsscroller') tabsscroller?: MdcTabScroller;
    @ViewChild('tabbar') tabbar?: MdcTabBar;
    @Input() tabs: iccListTab[] = [];
    @Input() activeTabIndex = 0;
    @Output() activated = new EventEmitter<iccListTab>();

    resizeSubscribtion?: Subscription;

    constructor() {}

    ngOnInit() {
        // on window rezise
        this.resizeSubscribtion = fromEvent(window, 'resize')
            // debounced
            .pipe(debounceTime(500))
            // trigger arrows change
            .subscribe(() => {
                (this.tabsscroller as MdcTabScroller).incrementScroll(1);
            });
    }

    ngOnDestroy() {
        if (this.resizeSubscribtion) {
            this.resizeSubscribtion.unsubscribe();
        }
    }

    incrementScroll(delta: number, event?: any) {
        if (event && event.shiftKey) {
            event.preventDefault();
        }
        (this.tabsscroller as MdcTabScroller).incrementScroll(delta * 2);
    }

    isArrowHidden() {
        const tabBarElem = document.querySelector('.mdc-tab-scroller__scroll-area');
        if (!tabBarElem) return false;
        
        const w1 = tabBarElem.getBoundingClientRect().width;
        const w2 = Math.floor(
            Array.from(document.querySelectorAll('.nav-tab')).reduce(
                (prev, curr) => (prev += curr.getBoundingClientRect().width),
                0
            )
        );

        return w1 >= w2;
    }

    selectTab({ index: tabIndex }: { index: number }) {
        const tab = this.tabs[tabIndex];
        this.activated.emit(tab);
    }
}
